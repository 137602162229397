var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "crewexisting" } },
    [
      _c(
        "v-slide-x-reverse-transition",
        [
          _c(
            "v-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.slidein,
                  expression: "slidein"
                }
              ]
            },
            [
              _c(
                "v-toolbar",
                {
                  class: [
                    _vm.processing
                      ? "view-card-top-color-processing"
                      : "view-card-top-color"
                  ]
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c("v-breadcrumbs", {
                                staticClass: "d-none d-sm-flex",
                                class: [
                                  _vm.processing
                                    ? "breadcrumb-processing-opacity"
                                    : "",
                                  "pl-3"
                                ],
                                attrs: {
                                  items: _vm.$store.state.currentBreadcrumbs,
                                  large: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "divider",
                                    fn: function() {
                                      return [
                                        _c("v-icon", [
                                          _vm._v("fa-chevron-right")
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("fd-back-button", {
                                attrs: {
                                  route: item =>
                                    _vm.$router.push(
                                      `/contractors/${_vm.crew.contractorID}`
                                    )
                                }
                              }),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _vm.processing
                                ? _c("v-progress-circular", {
                                    staticClass: "mr-3",
                                    attrs: {
                                      indeterminate: true,
                                      rotate: 0,
                                      size: 32,
                                      width: 4,
                                      color: "white"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                { staticClass: "pb-0 px-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-tabs",
                    {
                      ref: "tabs",
                      attrs: { "icons-and-text": "" },
                      model: {
                        value: _vm.active_tab,
                        callback: function($$v) {
                          _vm.active_tab = $$v
                        },
                        expression: "active_tab"
                      }
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          key: _vm.detailsTab.key,
                          ref: "tab",
                          attrs: { href: `#tab-${_vm.detailsTab.key}` },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected(_vm.detailsTab)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.detailsTab.tabname) +
                              "\n            "
                          ),
                          _c("v-icon", [_vm._v("fas fa-edit")])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab-item",
                        {
                          key: _vm.detailsTab.key,
                          attrs: { value: `tab-${_vm.detailsTab.key}` }
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "detailsform",
                              on: { submit: _vm.preventSubmit }
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "pl-3 pr-3 pt-3" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-1 pb-md-0 pt-5",
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              label: _vm.$t("common.name"),
                                              disabled: _vm.processing,
                                              rules: [_vm.rules.required]
                                            },
                                            model: {
                                              value: _vm.crew.name,
                                              callback: function($$v) {
                                                _vm.$set(_vm.crew, "name", $$v)
                                              },
                                              expression: "crew.name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-1 pb-md-0 pt-5",
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              outlined: "",
                                              "data-cy": "ownerselect",
                                              label: _vm.$t(
                                                "contractors.crews.owner"
                                              ),
                                              items: _vm.visiblePeople,
                                              "item-text": "name",
                                              "item-value": "id",
                                              disabled: _vm.processing,
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.crew.ownerID,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.crew,
                                                  "ownerID",
                                                  $$v
                                                )
                                              },
                                              expression: "crew.ownerID"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("fd-rich-textarea", {
                                            attrs: {
                                              disabled: _vm.processing,
                                              label: _vm.$t(
                                                "common.description"
                                              ),
                                              allowImages: false
                                            },
                                            model: {
                                              value: _vm.crew.description,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.crew,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression: "crew.description"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              "data-cy": "archive",
                                              label: _vm.$t("common.archived"),
                                              disabled: _vm.processing
                                            },
                                            model: {
                                              value: _vm.crew.isArchived,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.crew,
                                                  "isArchived",
                                                  $$v
                                                )
                                              },
                                              expression: "crew.isArchived"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab",
                        {
                          key: _vm.employeesTab.key,
                          attrs: { href: `#tab-${_vm.employeesTab.key}` },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected(_vm.employeesTab)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.employeesTab.tabname) +
                              "\n            "
                          ),
                          _c("v-icon", [_vm._v("fas fa-users")])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab-item",
                        {
                          key: _vm.employeesTab.key,
                          attrs: { value: `tab-${_vm.employeesTab.key}` }
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "", tile: "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "ml-0 mr-0" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "12" } },
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              staticClass: "pl-3 pr-0 pt-0 pb-0"
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "contractors.crews.employees.only-included-employees-label"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.showOnlyIncludedEmployees,
                                                  callback: function($$v) {
                                                    _vm.showOnlyIncludedEmployees = $$v
                                                  },
                                                  expression:
                                                    "showOnlyIncludedEmployees"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function({ on }) {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-4",
                                                                attrs: {
                                                                  color:
                                                                    "black",
                                                                  dark: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v("info")]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "contractors.crews.employees.employees-selection-sub-menu-information"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "mr-0 ml-0" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "append-icon": "search",
                                              label: "Search",
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.tablesearchemployees,
                                              callback: function($$v) {
                                                _vm.tablesearchemployees = $$v
                                              },
                                              expression: "tablesearchemployees"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-data-table", {
                                directives: [
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:name.hidden",
                                    arg: "name",
                                    modifiers: { hidden: true }
                                  },
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:order.hidden",
                                    arg: "order",
                                    modifiers: { hidden: true }
                                  },
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:firstName",
                                    value: _vm.$t("common.firstname"),
                                    expression: "$t('common.firstname')",
                                    arg: "firstName"
                                  },
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:lastName",
                                    value: _vm.$t("common.lastname"),
                                    expression: "$t('common.lastname')",
                                    arg: "lastName"
                                  },
                                  {
                                    name: "fd-column",
                                    rawName:
                                      "v-fd-column:employeeCode.hide-when-small",
                                    value: _vm.$t("users.employee-code"),
                                    expression: "$t('users.employee-code')",
                                    arg: "employeeCode",
                                    modifiers: { "hide-when-small": true }
                                  },
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:include.no-sort",
                                    value: _vm.$t("common.include"),
                                    expression: "$t('common.include')",
                                    arg: "include",
                                    modifiers: { "no-sort": true }
                                  }
                                ],
                                ref: "employeesDataTable",
                                staticClass: "fixed-header v-table__overflow",
                                staticStyle: {
                                  "max-height": "calc(50vh - 100px)",
                                  "backface-visibility": "hidden"
                                },
                                attrs: {
                                  items: _vm.employees,
                                  search: _vm.tablesearchemployees,
                                  loading: _vm.processing,
                                  "loading-text": _vm.$t(
                                    "common.table-loading-message"
                                  ),
                                  "sort-by": ["lastName"],
                                  "mobile-breakpoint": "0"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item.order",
                                    fn: function({ item }) {
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.orderForItem(item)) +
                                            "\n                "
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "header.include",
                                    fn: function({ header }) {
                                      return [
                                        _c("v-checkbox", {
                                          attrs: {
                                            value:
                                              _vm.allSearchedEmployeesSelected,
                                            indeterminate:
                                              _vm.someSearchedEmployeesSelected,
                                            label: _vm.$t(
                                              "common." + header.value
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.flipSearchedEmployeeselected()
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.include",
                                    fn: function({ item }) {
                                      return [
                                        _c("v-simple-checkbox", {
                                          attrs: {
                                            value: _vm.itemIsSelected(item),
                                            disabled: _vm.processing
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.flipEmployeeSelected(
                                                item
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab",
                        {
                          key: _vm.employeeOrderingTab.key,
                          attrs: {
                            href: `#tab-${_vm.employeeOrderingTab.key}`
                          },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected(_vm.employeeOrderingTab)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.employeeOrderingTab.tabname) +
                              "\n            "
                          ),
                          _c("v-icon", [_vm._v("fas fa-arrow-down-arrow-up")])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab-item",
                        {
                          key: _vm.employeeOrderingTab.key,
                          attrs: { value: `tab-${_vm.employeeOrderingTab.key}` }
                        },
                        [
                          _c("v-data-table", {
                            directives: [
                              {
                                name: "fd-table-sortable",
                                rawName: "v-fd-table-sortable"
                              },
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:name",
                                arg: "name"
                              },
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:employeeCode",
                                value: _vm.$t("users.employee-code"),
                                expression: "$t('users.employee-code')",
                                arg: "employeeCode"
                              },
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:classificationName",
                                value: _vm.$t(
                                  "timesheets.classification-label"
                                ),
                                expression:
                                  "$t('timesheets.classification-label')",
                                arg: "classificationName"
                              }
                            ],
                            attrs: {
                              items: _vm.selectedEmployees,
                              loading: _vm.processing,
                              "loading-text": _vm.$t(
                                "common.table-loading-message"
                              ),
                              "mobile-breakpoint": "0",
                              "disable-sort": "",
                              "disable-pagination": true,
                              "hide-default-footer": true
                            },
                            on: { "sort:end": _vm.dragEnded },
                            scopedSlots: _vm._u([
                              {
                                key: "item.fd-drag",
                                fn: function() {
                                  return [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("far fa-grip-lines")
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-form",
                    { ref: "form", on: { submit: _vm.onSubmit } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "mr-0",
                                      attrs: { type: _vm.inlineMessage.type }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-line"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.inlineMessage.message)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                link: "",
                                disabled: _vm.processing,
                                loading: _vm.deleting,
                                color: "error",
                                outlined: "",
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.deleteItem }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("common.delete")) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                text: "",
                                link: "",
                                disabled: _vm.processing
                              },
                              on: { click: _vm.cancel }
                            },
                            [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                          ),
                          _vm._v(" "),
                          _c("fd-menu-save", {
                            attrs: {
                              disabled:
                                _vm.processing ||
                                !_vm.currentUserCanConfigureSettings,
                              loading: _vm.saving,
                              small: _vm.$vuetify.breakpoint.xsOnly
                            },
                            on: {
                              "click:save": function($event) {
                                return _vm.save(false)
                              },
                              "click:save-and-close": function($event) {
                                return _vm.save(true)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }