import { personService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: personService,
  singularStoreName: "USER",
  pluralStoreName: "USERS",
  localizationPrefix: "users",
  consumerRelatedIDProperty: {
    name: "userID",
    type: "string"
  },
  nameCallback: user => user.firstName + " " + user.lastName
});

export default storeModule;

