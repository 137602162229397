import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { PaintCode } from "../../../services";

const PaintCodeNewDialog = FDVue.extend({
  name: "sp-paint-code-new-dialog",

  mixins: [dialogSupport, rules],

  components: {},

  data: function() {
    return {
      saving: false,
      paintCode: {
        name: "",
        description: ""
      } as PaintCode
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return ["open"];
    }
  },

  methods: {
    async open() {
      return await this.showDialog!();
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        await this.addPaintCode({
          ...this.paintCode
        });
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    ...mapActions({
      addPaintCode: "ADD_PAINT_CODE"
    })
  }
});

export default PaintCodeNewDialog;

export async function createNewPaintCode(): Promise<boolean> {
  let dialog = createDialog(PaintCodeNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open();
}

