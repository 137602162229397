import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { TranslateResult } from "vue-i18n";
import { compareStringArrays } from "@fd/lib/client-util/array";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";

type MultipleItemSelectionDialogResult = Array<string> | undefined;

const MultipleItemSelectionDialog = FDVue.extend({
  name: "fd-multi-item-selection-dialog",

  mixins: [dialogSupport],

  directives: {
    fdColumn: FDColumnDirective
  },

  data: function() {
    return {
      tablesearch: "",
      title: this.$t("common.select-items") as string | TranslateResult,
      details: this.$t("common.select-items") as string | TranslateResult,
      dataLabel: this.$t("common.additional-details") as string | TranslateResult,

      items: [] as any,
      itemText: "name" as string,
      itemValue: "id" as string,

      selectedValues: [] as Array<string>
    };
  },

  computed: {
    unwatchedMethodNames() {
      return ["open", "itemIsSelected"];
    },
    searchedItems(): Array<any> {
      // This is a hack because the data table won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .datatable as any)?.customFilter;
      if (this.tablesearch && customFilter) {
        return this.items.filter(
          (x: any) =>
            customFilter(x.name!, this.tablesearch, x) ||
            customFilter(x.description!, this.tablesearch, x)
        );
      } else {
        return this.items;
      }
    },
    allItemsSelected(): boolean {
      return compareStringArrays(
        this.selectedValues,
        this.items.map((x: any) => x[this.itemValue])
      );
    },
    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedItemsSelected(): boolean {
      console.log(`allSearchedItemsSelected`);
      if (this.allItemsSelected) {
        console.log(` true (allItemsSelected)`);
        return true;
      }
      let val =
        this.searchedItems.findIndex(
          (x: any) => !this.selectedValues.includes(x[this.itemValue])
        ) === -1;
      console.log(` ${val}`);
      return val;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedItemsSelected(): boolean {
      var searchedItems = this.searchedItems;
      return (
        searchedItems.findIndex((x: any) => !this.itemIsSelected(x)) !== -1 &&
        searchedItems.findIndex((x: any) => this.itemIsSelected(x)) !== -1
      );
    }
  },

  mounted: function() {},

  methods: {
    itemIsSelected(item: any): boolean {
      return this.selectedValues.includes(item[this.itemValue]);
    },
    flipItemSelected(item: any) {
      let isSelected = this.itemIsSelected(item);
      if (isSelected) {
        let index = this.selectedValues.indexOf(item.id!);
        if (index > -1) {
          this.selectedValues.splice(index, 1);
        }
      } else {
        this.selectedValues.push(item[this.itemValue]);
      }
    },

    flipSearchedItemsSelected() {
      let selected = !this.allSearchedItemsSelected;
      for (let item of this.searchedItems) {
        let isSelected = this.itemIsSelected(item);
        if (isSelected !== selected) {
          if (isSelected) {
            let index = this.selectedValues.indexOf(item.id!);
            if (index > -1) {
              this.selectedValues.splice(index, 1);
            }
          } else {
            this.selectedValues.push(item[this.itemValue]);
          }
        }
      }
    },
    async open(
      title?: string | TranslateResult,
      details?: string | TranslateResult,
      label?: string | TranslateResult,
      items?: any[],
      itemText?: string,
      itemValue?: string,
      defaultSelectedItems?: Array<string>
    ): Promise<MultipleItemSelectionDialogResult> {
      if (!!title) this.title = title;
      if (!!details) this.details = details;
      if (!!label) this.dataLabel = label;
      if (!!items) this.items = items;
      if (!!itemText) this.itemText = itemText;
      if (!!itemValue) this.itemValue = itemValue;

      if (!!defaultSelectedItems) {
        // There's an issue where the include header checkbox isn't correctly loading the value
        // This delay lets the checkbox be mounted correctly before updating the selected values, which in turn toggles the checkbox state
        setTimeout(() => {
          this.selectedValues = defaultSelectedItems;
        }, 200);
      }

      if (await this.showDialog()) return this.selectedValues;
      else return undefined;
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    acceptDialog() {
      if (!(this.$refs.form as HTMLFormElement).validate()) return;

      this.closeDialog(true);
    }
  }
});

export default MultipleItemSelectionDialog;

export async function showMultipleItemSelectionDialog(
  title?: string | TranslateResult,
  details?: string | TranslateResult,
  label?: string | TranslateResult,
  items?: any[],
  itemText?: string,
  itemValue?: string,
  defaultSelectedItems?: Array<string>
): Promise<MultipleItemSelectionDialogResult> {
  let dialog = createDialog(MultipleItemSelectionDialog);
  dialog.optOutOfErrorHandling();
  let result = await dialog.open(
    title,
    details,
    label,
    items,
    itemText,
    itemValue,
    defaultSelectedItems
  );
  return result;
}

