var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.dialogVisible,
        callback: function($$v) {
          _vm.dialogVisible = $$v
        },
        expression: "dialogVisible"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [_vm._v(_vm._s(_vm.$t("modifiers.new-dialog-title")))]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-2" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "align-center justify-space-between",
                          attrs: { cols: "12" }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mr-0 ml-0",
                              attrs: { align: "center" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t("common.name"),
                                  disabled: _vm.processing,
                                  rules: [_vm.rules.required]
                                },
                                model: {
                                  value: _vm.modifier.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.modifier, "name", $$v)
                                  },
                                  expression: "modifier.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mr-0 ml-0",
                              attrs: { align: "center" }
                            },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  label: _vm.$t("common.description"),
                                  disabled: _vm.processing
                                },
                                model: {
                                  value: _vm.modifier.description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.modifier, "description", $$v)
                                  },
                                  expression: "modifier.description"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.inlineMessage.message
                            ? _c(
                                "v-alert",
                                { attrs: { type: _vm.inlineMessage.type } },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.inlineMessage.message) +
                                      "\n                        "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", disabled: _vm.processing },
                      on: { click: _vm.cancelDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.saving,
                        disabled: _vm.processing
                      },
                      on: { click: _vm.saveDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.save")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }