import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import userAccess from "../dataMixins/userAccess";
import { mapMutations, mapActions } from "vuex";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { createNewClassification } from "./components/dialogs/ClassificationNewDialog.vue";
import { Classification } from "../services";
import { stripHtml, truncateWithEllipsis } from "@fd/lib/vue/utility/helper";
import archivedDataList from "../dataMixins/archivedDataList";

type ClassificationWithArchived = Classification & {
  archived: boolean;
};

export default FDVue.extend({
  name: "fd-classifications",

  mixins: [errorHandling, userAccess, archivedDataList],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {},

  data: function() {
    return {
      archivedLoading: false,
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5
    };
  },

  computed: {
    classifications(): ClassificationWithArchived[] {
      return (this.$store.state.classifications.fullList as Classification[]).map(x => {
        return {
          ...x,
          description: truncateWithEllipsis(stripHtml(x.description)),
          archived: !!x.archivedDate
        } as ClassificationWithArchived;
      });
    },

    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    async openNewDialog() {
      this.optOutOfErrorHandling();
      await createNewClassification();
    },

    // the following works with the delete "Action" button in the Datatable.
    async deleteTableItem(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteClassification({ id: item.id, name: item.name });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async flipArchived(item: ClassificationWithArchived) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateClassification({
          id: item.id,
          archivedDate: archivedDate,
          name: item.name
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async reloadTableData() {
      this.processing = true;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadData() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      await this.loadClassifications({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },

    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    ...mapActions({
      loadClassifications: "LOAD_CLASSIFICATIONS",
      updateClassification: "UPDATE_CLASSIFICATION",
      deleteClassification: "DELETE_CLASSIFICATION"
    })
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "classifications",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: new Date(0),
      showArchivedForFilteringToDate: new Date(),
      searchStringForFiltering: ""
    });

    this.notifyNewBreadcrumb({
      text: this.$t("classifications.list.title"),
      to: "/classifications",
      resetHistory: true
    });

    this.processing = true;
    try {
      await this.loadData();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

