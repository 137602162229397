import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { ProjectCostCode } from "../../services";

const CostCodeNewDialog = FDVue.extend({
  name: "fd-project-cost-code-new-dialog",

  mixins: [dialogSupport, errorHandling, rules],

  components: {},

  data: function() {
    return {
      costcode: {
        name: "",
        code: ""
      } as ProjectCostCode
    };
  },

  computed: {
    availableTags(): any[] {
      return this.$store.getters.sortedEnabledTags;
    }
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        await this.addProjectCostCode({
          ...this.costcode
        });
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    ...mapActions({
      addProjectCostCode: "ADD_PROJECT_COST_CODE"
    })
  }
});

export default CostCodeNewDialog;

export async function createNewCostCode(projectID: string): Promise<boolean> {
  let dialog = createDialog(CostCodeNewDialog);
  dialog.costcode.projectID = projectID;
  return await dialog.showDialog!();
}

