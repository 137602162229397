import FDVue from "./index";
import VueI18n from "vue-i18n";

type Rule = (value: any) => boolean | VueI18n.TranslateResult;

export default FDVue.extend({
  data() {
    var vue = this;
    var emailPattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var phonePattern = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
    var identifierPattern = /^[a-zA-Z\.]{6}[0-9]{4}/;
    var lowercasePattern = /(?=.*?[a-z])/;
    var uppercasePattern = /(?=.*?[A-Z])/;
    var numberPresentPattern = /(?=.*?[0-9])/;
    var specialCharacterPattern = /(?=.*?[^A-Za-z0-9])/;
    var characterLimitPattern = /.{8,}/;
    return {
      patterns: {
        lowercasePattern: lowercasePattern,
        uppercasePattern: uppercasePattern,
        numberPresentPattern: numberPresentPattern,
        specialCharacterPattern: specialCharacterPattern,
        characterLimitPattern: characterLimitPattern
      },
      rules: {
        required(value: any): boolean | VueI18n.TranslateResult {
          return (
            ((!!value || value === 0) && (value.length === undefined || !!value.length)) ||
            vue.$t("common.rule-required")
          );
        },
        max20Characters(value: any): boolean | VueI18n.TranslateResult {
          return !value || value.length <= 20 || vue.$t("common.rule-alias-counter", ["20"]);
        },
        validEmail(value: any): boolean | VueI18n.TranslateResult {
          return !value || emailPattern.test(value) || vue.$t("common.rule-email-not-valid");
        },
        validPhoneNumber(value: any): boolean | VueI18n.TranslateResult {
          return !value || phonePattern.test(value) || vue.$t("common.rule-phone-number-not-valid");
        },
        validEmailOrPhoneNumber(value: any): boolean | VueI18n.TranslateResult {
          return (
            !value ||
            emailPattern.test(value) ||
            phonePattern.test(value) ||
            vue.$t("common.rule-email-or-phone-number-not-valid")
          );
        },
        validEmailOrPhoneNumberOrIdentifier(value: any): boolean | VueI18n.TranslateResult {
          return (
            !value ||
            emailPattern.test(value) ||
            phonePattern.test(value) ||
            identifierPattern.test(value) ||
            vue.$t("common.rule-email-or-phone-number-not-valid")
          );
        },
        numeric(value: any): boolean | VueI18n.TranslateResult {
          return !(value && isNaN(value)) || vue.$t("common.rule-numbers-only");
        },
        /// Max file size is 1MB, as determined by the express HTTP server
        limitedFileSize(value: any): boolean | VueI18n.TranslateResult {
          return !value || value.size < 1000000 || vue.$t("common.file-too-large");
        },
        lowercaseLetter: (value: any) =>
          lowercasePattern.test(value) ||
          vue.$t("password.update-password-lowercase-letter-required"),
        uppercaseLetter: (value: any) =>
          uppercasePattern.test(value) ||
          vue.$t("password.update-password-uppercase-letter-required"),
        numberPresent: (value: any) =>
          numberPresentPattern.test(value) || vue.$t("password.update-password-number-required"),
        specialCharacter: (value: any) =>
          specialCharacterPattern.test(value) ||
          vue.$t("password.update-password-special-character-required"),
        atLeast8Characters: (value: any) =>
          characterLimitPattern.test(value) ||
          vue.$t("password.update-password-at-least-eight-characters-required"),
        password(value: any): boolean | VueI18n.TranslateResult {
          return (
            !value ||
            (lowercasePattern.test(value) &&
              uppercasePattern.test(value) &&
              numberPresentPattern.test(value) &&
              specialCharacterPattern.test(value) &&
              characterLimitPattern.test(value)) ||
            vue.$t("common.file-too-large")
          );
        }
      }
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return ["getGreaterThanNumberValueRule", "getMinNumberValueRule", "getMaxNumberValueRule"];
    }
  },

  methods: {
    getMinNumberValueRule(min: number): Rule {
      return (value: any) => {
        return (value == null ||
          value == undefined ||
          value >= min ||
          this.$t("common.rule-value-too-small", [min])) as boolean | VueI18n.TranslateResult;
      };
    },
    getGreaterThanNumberValueRule(min: number): Rule {
      return (value: any) => {
        return (value == null ||
          value == undefined ||
          value > min ||
          this.$t("common.rule-value-must-be-greater", [min])) as boolean | VueI18n.TranslateResult;
      };
    },
    getMaxNumberValueRule(max: number): Rule {
      return (value: any) => {
        return (value == null ||
          value == undefined ||
          value <= max ||
          this.$t("common.rule-value-too-large", [max])) as boolean | VueI18n.TranslateResult;
      };
    },
    getRequiredRuleWithCustomText(key: string): Rule {
      return (value: any) => {
        return (!!value || this.$t(key)) as boolean | VueI18n.TranslateResult;
      };
    }
  }
});

// validEmail(value: any): boolean | VueI18n.TranslateResult { return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Email Address not valid' },

