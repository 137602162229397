import { PropType } from "vue";
import FDVue from "@fd/lib/vue";
import {
  ApplicationType,
  ContractorNorm,
  PaintCode,
  SetUpType,
  Tsa,
  WorkCategory
} from "../../../services";

type NormWithResponse = ContractorNorm & {
  answeredDescription: string | undefined;
  quantity: number | null | undefined;
  loadedQuantity: number | null | undefined;
  squareFeet: number | null | undefined;
  loadedSquareFeet: number | null | undefined;
  hours: number | null | undefined;
  loadedHours: number | null | undefined;
  text: string | null | undefined;
  loadedText: string | null | undefined;
};
export default FDVue.extend({
  name: "sp-build-sheet-form-paint",

  components: {
    "fp-add-remove-field": () => import("@fd/lib/vue/components/FP.AddRemoveField.vue")
  },
  props: {
    processing: { type: Boolean },
    canEditBuildSheetResponses: { type: Boolean },

    applicationTypes: { type: Array as PropType<Array<ApplicationType>> },
    paintCodes: { type: Array as PropType<Array<PaintCode>> },
    setUpTypes: { type: Array as PropType<Array<SetUpType>> },
    tsas: { type: Array as PropType<Array<Tsa>> },
    workCategories: { type: Array as PropType<Array<WorkCategory>> },

    normsWithResponses: { type: Array as PropType<Array<NormWithResponse>> },
    notes: { type: String },
    equipmentNumber: { type: String },
    diameter: { type: String },
    squareFeet: { type: String },
    linearFeet: { type: String },
    paintReason: { type: String },
    coatNumber: { type: Number },
    applicationTypeID: { type: String },
    paintCodeID: { type: String },
    setUpTypeID: { type: String },
    tsaID: { type: String },
    workCategoryID: { type: String },
  },
  data: function () {
    
    return {
      //FOR MOCKUP PURPOSES
      paintreason: {
        prep: false,
        touchup: false,
        prime: false,
        damage: false,
        paint: false,
        rework: false
      }
    };
  },
  computed: {
    canEditBuildSheet(): boolean {
      return this.canEditBuildSheetResponses ?? false;
    },
    paintReasonValue: {
      get(): string {
        return this.paintReason;
      },
      set(val: string) {
        this.$emit("update:paintReason", val);
      }
    },
    coatNumberValue: {
      get(): number {
        return this.coatNumber;
    },
      set(val: number) {
        console.log(`coatNumberValue.set ${val}`);
        this.$emit("update:coatNumber", val);
      }
    }
    },
  watch: {
    coatNumber(newvalue) {
      console.log(`coatNumber newValue: ${newvalue}`);
    }
  },
  methods: {
    clearNormResponses(item: NormWithResponse) {
      item.quantity = null;
      item.squareFeet = null;
      item.hours = null;
      item.text = null;
      this.$emit("update:normsWithResponses", this.normsWithResponses);
    },
    normQuantityUpdated(norm: NormWithResponse, quantity: any) {
      norm.quantity = this.$parse.sanitizedNumber(quantity);
      this.$emit("update:normsWithResponses", this.normsWithResponses);
    },
    normSquareFeetUpdated(norm: NormWithResponse, squareFeet: any) {
      norm.squareFeet = this.$parse.sanitizedNumber(squareFeet);
      this.$emit("update:normsWithResponses", this.normsWithResponses);
    },
    normHoursUpdated(norm: NormWithResponse, hours: any) {
      norm.hours = this.$parse.sanitizedNumber(hours);
      this.$emit("update:normsWithResponses", this.normsWithResponses);
    },

    //#region *** DATA LOADING

    //#endregion

    //#region *** INLINE NAVIGATION ***
    getFieldRef(fieldName: string, item: NormWithResponse) {
      let field = fieldName!.replace("-", "").replace("-", "");
      let id = item.id!.replace("-", "").replace("-", "");
      let fieldRef = `${field}_${id}`;
      // console.log(`\t getFieldRef: ${fieldRef}`);
      return fieldRef;
    },
    focusFieldForVisibleItemAtIndex(fieldName: string, index: number) {
      // console.log(`focusFieldForVisibleItemAtIndex: ${fieldName}, index: ${index}`);
      if (!this.normsWithResponses.length) return;

      if (index < 0) index = 0;
      if (index >= this.normsWithResponses.length) index = this.normsWithResponses.length - 1;
      let item = this.normsWithResponses[index];

      let itemFieldRef = this.getFieldRef(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      itemField = itemField;
      this.$nextTick(() => {
        // console.log(
        //   `\t itemField: ${itemField} element: ${itemField?.$el} textField: ${itemField?.textField}`
        // );
        itemField?.focus();
        itemField?.$el?.focus();
        itemField?.$textField?.focus();
      });
    },
    async selectPreviousField(fieldName: string, item: NormWithResponse) {
      let currentItemIndex = this.normsWithResponses.indexOf(item);
      if (currentItemIndex <= 0) {
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, previousIndex);
    },
    async selectNextField(fieldName: string, item: NormWithResponse) {
      let currentItemIndex = this.normsWithResponses.indexOf(item);
      if (currentItemIndex >= this.normsWithResponses.length - 1) {
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, nextIndex);
    },
    async enterPressed(e: KeyboardEvent, fieldName: string, item: NormWithResponse) {
      if (e.shiftKey) await this.selectPreviousField(fieldName, item);
      else await this.selectNextField(fieldName, item);
    }
    // #endregion
  }
});


