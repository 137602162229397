import { ProjectCostCode, projectCostCodeService, projectLocationService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

export type LoadProjectCostCodesByProjectIDParameter = {
  projectID: string;
  forcedArchivedState: boolean;
  archivedFromDate: Date | null;
  archivedToDate: Date | null;
};

var storeModule = createAutomaticCrudStoreModule({
  crudService: projectCostCodeService,
  singularStoreName: "PROJECT_COST_CODE",
  pluralStoreName: "PROJECT_COST_CODES",
  localizationPrefix: "projects.cost-codes",
  consumerRelatedIDProperty: {
    name: "projectCostCodeID",
    type: "string"
  },
  storeExtensions: {
    actions: {
      async LOAD_PROJECT_COST_CODES_BY_PROJECT_ID(
        context: any,
        payload: LoadProjectCostCodesByProjectIDParameter
      ): Promise<ProjectCostCode[]> {
        var response = await projectCostCodeService.getByProjectID(
          payload.projectID,
          payload.forcedArchivedState,
          payload.archivedFromDate,
          payload.archivedToDate
        );
        context.commit("SET_PROJECT_COST_CODES", response);
        return response;
      }
    }
  }
});

export default storeModule;

