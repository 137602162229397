import { PropType } from "vue";
import FDVue from "../../../../lib/vue";
import { TimesheetRowTimeValues } from "../../utils/timesheet";

export default FDVue.extend({
  name: "sp-timesheet-time-display",
  props: {
    times: { type: Object as PropType<TimesheetRowTimeValues> },
    singleLine: { type: Boolean, default: false },
    textAlign: { type: String, default: "auto" },
    displayZeroValue: { type: Boolean, default: false },

    noValueText: { type: String },
    noValueKey: { type: String, default: "timesheets.list.no-time-entry-value" },
    noValueTextAlign: { type: String, default: "center" },
    noValueStyle: { type: String },
    noValueClass: { type: String }
  },
  computed: {
    computedNoValueStyle(): string {
      let computedNoValueStyle = this.noValueStyle ?? "";
      if (!!computedNoValueStyle.length) computedNoValueStyle += ";";
      computedNoValueStyle += `text-align: ${this.noValueTextAlign};`;
      return computedNoValueStyle;
    },
    computedStyle(): string {
      let computedStyle = this.$attrs.style ?? "";
      if (!!computedStyle.length) computedStyle += ";";
      computedStyle += `text-align: ${this.textAlign};`;
      return computedStyle;
    }
  }
});

