import FDVue from "@fd/lib/vue";
import userAccess from "../dataMixins/userAccess";
import {
  FDColumnDirective,
  FDRowNavigateDirective,
  FDTableSortableDirective,
  SortableEvent
} from "@fd/lib/vue/utility/dataTable";
import { mapMutations, mapActions } from "vuex";
import { Yard, yardService, YardType } from "../services";
import archivedDataList from "../dataMixins/archivedDataList";
import { showYardNewDialog } from "./components/dialogs/YardNewDialog.vue";

type FormattedYard = Yard & {
  yardType: string;
  archived: boolean;
};

export default FDVue.extend({
  name: "fd-yards",

  // archivedDataList already uses the serviceErrorHandling
  mixins: [archivedDataList, userAccess],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    fdTableSortable: FDTableSortableDirective
  },

  data: function() {
    return {
      // yards: [] as FormattedYard[],
      deleting: false,
      // Table Footer page size options
      itemsPerPage: 100,
      itemsPerPageOptions: [5, 25, 50, 100, -1]
    };
  },

  computed: {
    yards(): FormattedYard[] {
      var storeYards = this.$store.state.yards.fullList as Yard[];
      return storeYards
        .map(
          x =>
            ({
              ...x,
              yardType: this.yardTypeForYard(x),
              archived: !!x.archivedDate
            } as FormattedYard)
        )
        .sort((a, b) => {
          let aOrder = a.order ?? 0;
          let bOrder = b.order ?? 0;
          if (aOrder != bOrder) return aOrder - bOrder;

          let aName = a.name!.toLocaleLowerCase();
          let bName = b.name!.toLocaleLowerCase();
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        });
    },
    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    /*** GLOBAL ***/
    ...mapActions({
      loadStoreYards: "LOAD_YARDS",
      updateYard: "UPDATE_YARD",
      deleteYard: "DELETE_YARD"
    }),
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    async openNewDialog() {
      let lastOrder = 0;
      if (this.yards.length) lastOrder = this.yards.slice(-1)[0].order ?? 0;
      await showYardNewDialog(lastOrder);
    },
    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async loadData() {
      await this.loadStoreYards({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });
    },
    yardTypeForYard(yard: Yard) {
      return this.$t(`yards.type.${YardType[yard.yardTypeID ?? 0].toLocaleLowerCase()}`);
    },

    async deleteTableItem(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      this.deleting = true;
      try {
        await this.deleteYard(item);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    },

    async flipArchived(item: FormattedYard) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateYard({
          id: item.id,
          archivedDate: archivedDate,
          name: item.name
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async confirmItemOrdering() {
      console.log(`confirmItemOrdering totalYards: ${this.yards.length}`);

      let itemsToUpdate = [] as FormattedYard[];

      this.yards.forEach((yard, index) => {
        let newOrder = index + 1;
        // console.log(`  ${yard.name}: ${yard.order} vs ${newOrder}`);
        if (!yard.order || yard.order != newOrder) {
          console.log(`    ${yard.order} --> ${newOrder}`);
          yard.order = newOrder;
          itemsToUpdate.push(yard);
        }
      });

      if (itemsToUpdate.length > 0) {
        console.log(` updating ${itemsToUpdate.length} yards' ordering`);
        await yardService.updateYardOrders(itemsToUpdate);

        var snackbarPayload = {
          text: this.$t("yards.snack-bar-order-updated-message"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      }
    },
    async dragEnded(e: SortableEvent) {
      console.log(`dragEnded`);
      let oldIndex = e.oldIndex ?? 0;
      let newIndex = e.newIndex ?? 0;
      if (oldIndex == newIndex) return;

      this.yards.splice(newIndex, 0, ...this.yards.splice(oldIndex, 1));

      this.processing = true;
      try {
        await this.confirmItemOrdering();
      } catch (error) {
        this.handleError(error as Error);
        await this.reloadTableData();
      } finally {
        this.processing = false;
      }
    }
  },

  created: async function() {
    this.setFilteringContext({
      context: "yards",
      parentalContext: null,
      searchStringForFiltering: "",
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: new Date(0),
      showArchivedForFilteringToDate: new Date()
    });

    this.notifyNewBreadcrumb({
      text: this.$t("yards.list.title"),
      to: "/yards",
      resetHistory: true
    });

    this.processing = true;
    try {
      await this.loadData();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

