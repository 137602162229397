import FDVue from "../";

export default FDVue.extend({
  name: "fd-text-field",

  inheritAttrs: false,

  props: {
    value: {},
    cy: { type: String, default: undefined },
    dataCy: { type: String, default: "fd-text-field" },
    disabled: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
    autoTriggerChange: { type: Boolean, default: false },
    changeTriggerDelay: { type: Number, default: 1.5 },
    highlightOnFocus: { type: Boolean, default: false }
  },

  data: function() {
    return {
      timer: null as NodeJS.Timeout | null
    };
  },

  methods: {
    focus() {
      this.$nextTick(() => {
        this.textField.focus();
      });
    },
    onFocus(e: Event) {
      if (this.highlightOnFocus) this.select();
      this.$emit("focus", e);
    },
    reset() {
      this.textField.lazyValue = this.textField.initialValue;
    },
    select() {
      this.textField.$refs.input.select();
    },
    errorUpdated(e: any) {
      if (e === true) {
        var errorMessage = !!this.textField.errorBucket?.length
          ? this.textField.errorBucket[0]
          : undefined;
        this.$emit("error", errorMessage, this);
      }
    },
    change(v: any) {
      if (this.timer) clearTimeout(this.timer);

      if (this.textField.hasError) {
        console.log(`Text field has error.  Don't send change event.`);
        return;
      }

      let value = v;
      if (this.isNumeric) value = parseFloat(v);
      this.$emit("change", value);
    },
    input(v: any) {
      if (this.timer) clearTimeout(this.timer);

      if (this.autoTriggerChange && this.isDirty) {
        var obj = this;
        // Delay the change event to allow the user to keep typing if they choose before making a server call
        this.timer = setTimeout(function() {
          if (obj.textField.hasError) {
            console.log(`Text field has error.  Don't send change event.`);
            return;
          }
          let value = v;
          if (obj.isNumeric) value = parseFloat(v);
          obj.$emit("change", value);

          // Set field values to current value to prevent additional "change" event from firing if the user leaves the field without changing anything
          obj.textField.initialValue = value;
          obj.textField.value = value;
        }, this.changeTriggerDelay * 1000);
      }

      this.$emit("input", v);
    }
  },

  computed: {
    textField(): any {
      return this.$refs.textfield;
    },
    isDirty(): boolean {
      return this.textField.lazyValue !== this.textField.initialValue;
    },
    isNumeric(): boolean {
      let numeric = this.$attrs.numeric as any;
      let isNumeric = !(numeric == undefined || numeric == "false" || numeric === false);
      return isNumeric;
    }
  },

  watch: {},

  beforeDestroy: function() {
    if (this.timer) clearTimeout(this.timer);
  }
});

