var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-autocomplete",
    _vm._b(
      {
        class: _vm.computedClass,
        attrs: {
          "data-cy": _vm.cy,
          "hide-selected": "",
          multiple: _vm.multiple,
          outlined: _vm.outlined,
          dense: _vm.dense,
          "persistent-hint": !!_vm.hint.length,
          "item-text": _vm.itemText,
          items: _vm.items,
          "hide-no-data": !_vm.hasLoaded,
          "search-input": _vm.search,
          loading: _vm.loading,
          label: _vm.label,
          hint: _vm.hint,
          rules: _vm.rules,
          disabled: _vm.disabled,
          clearable: !!_vm.clearable && !_vm.useChips && !_vm.multiple,
          placeholder: _vm.placeholder,
          "cache-items": _vm.cacheItems,
          "return-object": "",
          readonly: _vm.$attrs.readonly
        },
        on: {
          "update:searchInput": function($event) {
            _vm.search = $event
          },
          "update:search-input": function($event) {
            _vm.search = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "selection",
              fn: function({
                parent,
                item,
                index,
                select,
                selected,
                disabled,
                attrs
              }) {
                return [
                  _vm._t(
                    "selection",
                    function() {
                      return [
                        _vm.useChips
                          ? _c(
                              "div",
                              [
                                item === Object(item)
                                  ? _c(
                                      "v-chip",
                                      _vm._b(
                                        {
                                          class: _vm.chipClass,
                                          attrs: {
                                            "input-value": selected,
                                            dark: "",
                                            small: ""
                                          }
                                        },
                                        "v-chip",
                                        attrs,
                                        false
                                      ),
                                      [
                                        _c("span", { staticClass: "pr-2" }, [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(item[_vm.itemText]) +
                                              "\n          "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { small: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeItem(item)
                                              }
                                            }
                                          },
                                          [_vm._v("close")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : !_vm.useChips && _vm.multiple
                          ? _c(
                              "div",
                              {
                                staticClass: "py-2",
                                staticStyle: { width: "100%" }
                              },
                              [
                                _vm._v(
                                  "\n        " +
                                    _vm._s(item[_vm.itemText]) +
                                    "\n        "
                                ),
                                !disabled
                                  ? _c(
                                      "v-icon",
                                      {
                                        staticClass: "fd-list-item-icon",
                                        on: {
                                          click: function($event) {
                                            return _vm.removeItem(item)
                                          }
                                        }
                                      },
                                      [_vm._v("mdi-close-circle-outline")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _c("div", [
                              _vm._v(
                                "\n        " +
                                  _vm._s(item[_vm.itemText]) +
                                  "\n      "
                              )
                            ])
                      ]
                    },
                    {
                      parent: parent,
                      item: item,
                      index: index,
                      select: select,
                      selected: selected,
                      disabled: disabled,
                      attrs: attrs
                    }
                  )
                ]
              }
            },
            {
              key: "item",
              fn: function({ parent, item, on, attrs }) {
                return [
                  _vm._t(
                    "item",
                    function() {
                      return [
                        _vm.useChips
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-chip",
                                  {
                                    class: _vm.chipClass,
                                    attrs: { dark: "", small: "" }
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(item[_vm.itemText]) +
                                        "\n        "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _c("div", [
                              _vm._v(
                                "\n        " +
                                  _vm._s(item[_vm.itemText]) +
                                  "\n        "
                              ),
                              _vm.itemDetail
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "fd-item-detail-light pb-2"
                                    },
                                    [_vm._v(_vm._s(item[_vm.itemDetail]))]
                                  )
                                : _vm._e()
                            ])
                      ]
                    },
                    { parent: parent, item: item, on: on, attrs: attrs }
                  )
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.twoWayProps.selectedItems,
          callback: function($$v) {
            _vm.$set(_vm.twoWayProps, "selectedItems", $$v)
          },
          expression: "twoWayProps.selectedItems"
        }
      },
      "v-autocomplete",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }