var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.value !== undefined || _vm.loading
    ? _c(
        "v-row",
        { staticClass: "mx-0 px-0" },
        [
          _c("v-col", { attrs: { cols: _vm.labelCols, sm: _vm.labelSmCols } }, [
            _c("strong", [_vm._v(_vm._s(_vm.label))])
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: _vm.valueCols, sm: _vm.valueSmCols } },
            [
              _c("fd-value-display", {
                style: _vm.valueQualifier ? "display:inline" : "",
                attrs: {
                  value: _vm.value,
                  valueStyle: _vm.valueStyle,
                  valueClass: _vm.valueClass,
                  hideNoValuePlaceholder: _vm.hideNoValuePlaceholder,
                  noValueKey: _vm.noValueKey,
                  noValueStyle: _vm.noValueStyle,
                  noValueClass: _vm.noValueClass
                }
              }),
              _vm._v(" "),
              _vm.valueQualifier
                ? _c("strong", [_vm._v(_vm._s(_vm.valueQualifier))])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }