<template>
    <v-container fluid>
        <v-alert v-if="inlineMessage.message" v-bind:type="inlineMessage.type" class="mt-0 mb-3">
            {{ inlineMessage.message }}
        </v-alert>
        <v-card class="fd-view no-tag-searching">
            <v-toolbar v-bind:class="[processing ? 'view-card-top-color-processing' : 'view-card-top-color']">
                <!--View Title-->
                <v-row>
                    <v-col cols="12">
                        <v-breadcrumbs
                                :items="$store.state.currentBreadcrumbs"
                                large
                                v-bind:class="[processing ? 'breadcrumb-processing-opacity' : '', 'pl-0']"
                        >
                            <template v-slot:divider>
                                <v-icon>fa-chevron-right</v-icon>
                            </template>
                        </v-breadcrumbs>
                    </v-col>
                </v-row>
                <!--Add Button-->
                <template v-slot:extension>
                    <v-btn
                            dark
                            absolute
                            bottom
                            left
                            fab
                            @click="openNewDialog()"
                            class="add-fab-button-primary"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
            </v-toolbar>

            <!--Search bar for the view table-->
            <v-card-subtitle style="margin-top: 15px;">
                <v-text-field
                        v-model="tablesearch"
                        append-icon="search"
                        :label="$t('common.search')"
                        single-line
                        hide-details
                        clearable
                />
            </v-card-subtitle>

            <!--Datatable for the view-->
            <v-data-table
                    v-fd-column:name.hide-when-extra-small
                    v-fd-column:alias
                    v-fd-column:description.hide-when-small
                    v-fd-column:enabled.hide-when-extra-small
                    v-fd-column:actions.no-sort.hide-when-extra-small
                    v-fd-row-navigate.show-when-extra-small="item => $router.push(`/owners/${item.id}`)"
                    :items="owners"
                    :search="tablesearch"
                    :loading="processing"
                    :loading-text="$t('common.table-loading-message')"
                    :sort-by="['alias']"
                    mobile-breakpoint="0"
                    class="fd-actions-table fd-enabling-table"
            >
                <!-- Template for the simple check box on screen that will control whether the record is available.-->
                <template v-slot:item.enabled="{ item }">
                    <v-simple-checkbox
                            v-bind:value="item.enabled"
                            v-bind:disabled="processing"
                            v-on:input="flipEnabled(item)"
                    />
                </template>
                <!--Action buttons for the datatable-->
                <template v-slot:item.actions="{ item }">
                    <div class="table-actions-column-min-width">
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        icon
                                        link
                                        v-bind:disabled="processing"
                                        v-bind:to="`/owners/${item.id}`"
                                        v-on="on"
                                >
                                    <v-icon
                                            small
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('common.edit') }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        icon
                                        v-bind:disabled="processing"
                                        v-on="on"
                                >
                                    <v-icon
                                            small
                                            @click="deleteTableItem(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('common.remove') }}</span>
                        </v-tooltip>
                    </div>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script lang="ts">
    import {mapMutations, mapState, mapActions} from "vuex";
    import {filterByTags} from "../services/taggableItems";
    import { createNewOwner } from "./components/OwnerNewDialog.vue";
    import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
    import FDVue from "@fd/lib/vue";
    import errorHandling from "@fd/lib/vue/mixins/errorHandling";

    export default FDVue.extend({
        mixins: [errorHandling],    

        name: 'fd-Owners',

        directives: {
          fdColumn: FDColumnDirective,
          fdRowNavigate: FDRowNavigateDirective
        },

        data: () => ({

            // The following is responsible for the inline messages that may be presented to the user.
            inlineMessage: {
                message: null,
                type: 'error',
            },

            // The following will control whether the controls on screen are disabled while we are processing.
            processing: false,


            // The following is used to define the breadcrumbs navigation for the view.
            breadcrumbs: [
                {
                    text: 'Owners',
                    disabled: true,
                },
            ],

        }),

        computed: {
            owners () { return this.$store.state.owners.fullList; },

            // Used to store the value of the search bar in the store so that if you go to the existing screen and back,
            // that the users filtering setup they have chosen will be preserved.
            tablesearch: {
                get () {
                    return this.$store.state.filters.find(x => x.context == this.$store.state.filteringContext)!.searchStringForFiltering;
                },
                set (val) {
                    this.$store.commit('SET_SEARCH_STRING_FOR_FILTERING', val)
                },
            },
        },

        methods: {
            async openNewDialog() {
                await createNewOwner();
            },

            // the following works with the delete "Action" button in the Datatable.
            async deleteTableItem (item) {
                this.inlineMessage.message = null;
                this.processing = true
                try {
                    await this.deleteOwner({ id: item.id, name: item.name });
                } catch (error) {
                    this.inlineMessage.message = this.$t("unexpected-network-error");
                    this.inlineMessage.type = "error";
                } finally {
                    this.processing = false;
                }
            },
            async flipEnabled (item) {
                this.inlineMessage.message = null;
                this.processing = true
                try {
                    await this.updateOwner({ id: item.id, enabled: !item.enabled, name: item.name });
                } catch (error) {
                    this.inlineMessage.message = this.$t("unexpected-network-error");
                    this.inlineMessage.type = "error";
                } finally {
                    this.processing = false;
                }
            },
            ...mapMutations({
                notifyNewBreadcrumb: 'NOTIFY_NEW_BREADCRUMB',
                setFilteringContext: 'SET_FILTERING_CONTEXT'
            }),
            ...mapActions({
                loadOwners: 'LOAD_OWNERS',
                updateOwner: 'UPDATE_OWNER',
                deleteOwner: 'DELETE_OWNER'
            })
        },

        created: async function () {

            // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
            // a sub screen of something that is currently filtered by their choices that those choices will be
            // preserved as they move between the two screens.
            this.setFilteringContext({
                context: "owners",
                parentalContext: null,
                searchStringForFiltering: "",
                tagsForFiltering: [],
                suppliersForFiltering: []
            });

            this.notifyNewBreadcrumb({
                text: "Owners",
                to: "/owners",
                resetHistory: true
            });
            this.processing = true;
            try {
                await this.loadOwners();
            } catch (error) {
                this.inlineMessage.message = this.$t("unexpected-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
            }
        }
    })
</script>
