import { mapMutations, mapActions } from "vuex";
import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { Tsa } from "../services";
import rules from "@fd/lib/vue/rules";

type TsaWithDetails = Tsa & { archived: boolean };

export default FDVue.extend({
  name: "sp-tsa-existing",
  mixins: [serviceErrorHandling, rules],
  data: function() {
    return {
      saving: false,
      slidein: false,
      tsa: {} as TsaWithDetails
    };
  },
  computed: {},
  watch: {
    tsa(newValue) {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/buildsheetsetup") {
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        this.notifyNewBreadcrumb({
          text: this.$t("build-sheet-setup.title"),
          to: "/buildsheetsetup",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      this.notifyNewBreadcrumb({
        text: this.tsa.name,
        to: `/buildsheetsetup/tsas/${this.$route.params.id}`,
        history: [
          {
            text: this.$t("build-sheet-setup.tabs.paint.tsas.title"),
            disabled: true
          }
        ]
      });
    }
  },
  methods: {
    backButtonClicked() {
      this.close();
    },
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.tsa.archived) {
          this.tsa.archivedDate = null;
        } else if (this.tsa.archived && !this.tsa.archivedDate) {
          this.tsa.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateTsa({
          ...this.tsa,
          id: this.$route.params.id
        });

        if (closeOnComplete) {
          this.close();
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteTsa({
          id: this.$route.params.id,
          name: this.tsa.name
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.close();
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.close();
    },
    close() {
      this.$router.push(`/buildsheetsetup/`);
    },
    ...mapMutations({
      setTsa: "SET_TSA",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadTsa: "LOAD_TSA",
      updateTsa: "UPDATE_TSA",
      deleteTsa: "DELETE_TSA"
    })
  },
  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/buildsheetsetup") {
      this.notifyNewBreadcrumb({
        text: this.$t("build-sheet-setup.title"),
        to: "/buildsheetsetup",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }

    this.notifyNewBreadcrumb({
      text: this.$t("loading-dot-dot-dot"),
      disabled: true,
      history: [
        {
          text: this.$t("build-sheet-setup.tabs.paint.tsas.title"),
          disabled: true
        }
      ]
    });

    this.processing = true;
    try {
      await this.loadTsa(this.$route.params.id);
      var tsa = this.$store.state.tsas.fullList.find((x: Tsa) => x.id == this.$route.params.id);
      this.tsa = {
        ...tsa,
        archived: !!tsa.archivedDate
      } as TsaWithDetails;
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

