var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [_vm._v(_vm._s(_vm.$t("timesheets.new.dialog-title")))]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                { staticClass: "pt-0" },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-2 pt-3" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "align-center justify-space-between pb-1",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("fd-date-picker", {
                            attrs: {
                              label: _vm.$t("timesheets.day-label"),
                              disabled: _vm.processing,
                              rules: _vm.timesheetRules.day,
                              max: new Date()
                            },
                            model: {
                              value: _vm.timesheet.day,
                              callback: function($$v) {
                                _vm.$set(_vm.timesheet, "day", $$v)
                              },
                              expression: "timesheet.day"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "align-center justify-space-between pb-1",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              "data-cy": "timesheettypeselect",
                              label: _vm.$t("timesheets.timesheet-type-label"),
                              items: _vm.timesheetTypeOptions
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function({ item, on, attrs }) {
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        _vm._b({}, "v-list-item", attrs, false),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("div", [
                                              _vm._v(_vm._s(item.text))
                                            ]),
                                            _vm._v(" "),
                                            item.disabled
                                              ? _c("fp-item-disabled-message", {
                                                  attrs: {
                                                    message: _vm.$t(
                                                      "timesheets.new.selected-contractor-not-allowed-timesheet-type-message"
                                                    )
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.timesheet.timesheetTypeID,
                              callback: function($$v) {
                                _vm.$set(_vm.timesheet, "timesheetTypeID", $$v)
                              },
                              expression: "timesheet.timesheetTypeID"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.selectableContractors.length > 1
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "align-center justify-space-between pb-1",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  "data-cy": "contractorselect",
                                  label: _vm.$t("timesheets.contractor-label"),
                                  items: _vm.selectableContractors,
                                  "item-text": "name",
                                  "item-value": "id",
                                  rules: _vm.timesheetRules.contractor,
                                  disabled:
                                    _vm.processing || !_vm.canChangeContractor,
                                  clearable: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function({ item, on, attrs }) {
                                        return [
                                          _c(
                                            "v-list-item",
                                            _vm._g(
                                              _vm._b(
                                                {},
                                                "v-list-item",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("div", [
                                                    _vm._v(_vm._s(item.name))
                                                  ]),
                                                  _vm._v(" "),
                                                  item.disabled
                                                    ? _c(
                                                        "fp-item-disabled-message",
                                                        {
                                                          attrs: {
                                                            message: _vm.$t(
                                                              "timesheets.new.contractor-not-allowed-selected-timesheet-type-message"
                                                            )
                                                          }
                                                        }
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1831889576
                                ),
                                model: {
                                  value: _vm.timesheet.contractorID,
                                  callback: function($$v) {
                                    _vm.$set(_vm.timesheet, "contractorID", $$v)
                                  },
                                  expression: "timesheet.contractorID"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.allForemen.length > 1 ||
                      _vm.allIndirectTimesheetCreators.length > 0
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "align-center justify-space-between pb-1",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  outlined: "",
                                  "data-cy": "foremanselect",
                                  label: _vm.$t("timesheets.owner-label"),
                                  items: _vm.visibleOwners,
                                  "item-text": "name",
                                  "item-value": "id",
                                  rules: _vm.timesheetRules.owner,
                                  disabled:
                                    _vm.processing || !_vm.canChangeOwner
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend-item",
                                      fn: function() {
                                        return [
                                          !!_vm.visibleOwners.length
                                            ? _c(
                                                "v-row",
                                                {
                                                  staticClass: "mx-4",
                                                  attrs: { "no-gutters": "" }
                                                },
                                                [
                                                  _c("v-col", {
                                                    attrs: { cols: "5" }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "3" } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fd-item-detail-light"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "timesheets.existing.employee-code-column-label"
                                                                )
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "4" } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fd-item-detail-light"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "timesheets.existing.classification-column-label"
                                                                )
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "item",
                                      fn: function({ item, on, attrs }) {
                                        return [
                                          _c(
                                            "v-list-item",
                                            _vm._g(
                                              _vm._b(
                                                {},
                                                "v-list-item",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "5" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.name)
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "3" }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "fd-item-detail"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.employeeCode
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "4" }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "fd-item-detail"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.classificationNameForPerson(
                                                                    item
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2912877745
                                ),
                                model: {
                                  value: _vm.timesheet.ownerID,
                                  callback: function($$v) {
                                    _vm.$set(_vm.timesheet, "ownerID", $$v)
                                  },
                                  expression: "timesheet.ownerID"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "align-center justify-space-between pb-1",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("v-switch", {
                            attrs: {
                              label: _vm.$t("timesheets.is-night-shift-label"),
                              disabled: _vm.processing
                            },
                            model: {
                              value: _vm.timesheet.isNightShift,
                              callback: function($$v) {
                                _vm.$set(_vm.timesheet, "isNightShift", $$v)
                              },
                              expression: "timesheet.isNightShift"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.inlineMessage.message
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mx-3",
                      attrs: { type: _vm.inlineMessage.type }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.inlineMessage.message) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ownerHasTimesheetOnDay
                ? _c(
                    "v-alert",
                    { staticClass: "mx-3", attrs: { type: "info" } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(
                              "timesheets.new.duplicate-timesheet-error-message"
                            )
                          ) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.cancelDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.processing,
                        disabled: _vm.processing,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.saveDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.save")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }