import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";
import { compareStringArrays } from "../../../../../lib/client-util/array";
import { ScaffoldRequestTypes, WorkOrderStatuses } from "../../../services";
import { workOrderService } from "../../../services/baseServices.generated";

type WorkorderFastForwardDialogResult = Date | undefined;
type WorkOrderLike = {
  id: string | undefined;
  internalNumber: number | undefined;
  requiredDate: Date | null | undefined;
  requestType: ScaffoldRequestTypes | undefined;
  workOrderStatus: WorkOrderStatuses | undefined;
  scaffoldNumber: number | null | undefined;
  progress: number | undefined;
};

const WorkorderFastForwardDialog = FDVue.extend({
  name: "sp-work-order-fast-forward-dialog",

  mixins: [dialogSupport],

  components: {},
  directives: {
    fdColumn: FDColumnDirective
  },

  data() {
    return {
      workOrders: [] as WorkOrderLike[],
      selectedValues: [] as string[],
      date: new Date(),
      tablesearch: ""
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return ["open", "searchFilter", "itemIsSelected"];
    },
    searchedItems(): Array<WorkOrderLike> {
      // This is a hack because the employees list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .datatable as any)?.customFilter;
      if (this.tablesearch && customFilter) {
        let searchedItems = this.workOrders.filter(
          (item: WorkOrderLike) =>
            customFilter(item.id, this.tablesearch, item) ||
            customFilter(item.internalNumber, this.tablesearch, item) ||
            customFilter(item.scaffoldNumber, this.tablesearch, item) ||
            customFilter(item.workOrderStatus, this.tablesearch, item) ||
            customFilter(item.requestType, this.tablesearch, item)
        );
        return searchedItems;
      } else {
        return this.workOrders;
      }
    },
    allItemsSelected(): boolean {
      return compareStringArrays(
        this.selectedValues,
        this.workOrders.map(x => x.id!)
      );
    },
    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedItemsSelected(): boolean {
      console.log(`allSearchedItemsSelected`);
      if (this.allItemsSelected) {
        console.log(` true (allItemsSelected)`);
        return true;
      }
      let val =
        this.searchedItems.findIndex((x: WorkOrderLike) => !this.selectedValues.includes(x.id!)) ===
        -1;
      console.log(` ${val}`);
      return val;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedItemsSelected(): boolean {
      var searchedItems = this.searchedItems;
      return (
        searchedItems.findIndex((x: WorkOrderLike) => !this.itemIsSelected(x)) !== -1 &&
        searchedItems.findIndex((x: WorkOrderLike) => this.itemIsSelected(x)) !== -1
      );
    }
  },

  watch: {},

  mounted: async function() {},

  methods: {
    searchFilter(value: any, search: string | null, item: WorkOrderLike): boolean {
      // HACK: Search customization in Vuetify 2 is byzantine at best...
      // This gets called once per column but we have no idea which column we're acting on so we
      // can't figure out what we should do to convert the value; we'll basically just check to
      // see if this search is for the ID field and if it is we'll do the full check, if it's not
      // we'll return "not found" until we get to our field
      if (item.id !== value) return false;
      if (!search) return true;
      search = search.toLocaleLowerCase();

      let searchStrings = [
        item.id?.toString(),
        item.internalNumber?.toString(),
        item.scaffoldNumber?.toString()
        // item.clientWorkOrderReferenceNumber,
        // ScaffoldRequestTypeLabels[item.requestType],
        // ScaffoldRequestSubTypeLabels[item.requestSubType],
        // WorkOrderStatusLabels[item.workOrderStatus],
        // personDataStore.lookupCaption(item.coordinatorID),
        // personDataStore.lookupCaption(item.generalForemanID),
        // personDataStore.lookupCaption(item.foremanID),
        // contractorDataStore.lookupCaption(item.assignedContractorID)
      ];
      console.log(
        `searchFilter value: ${value}, search: ${search}, searchStrings: ${searchStrings}`
      );
      for (let searchString of searchStrings) {
        if (!searchString) continue;
        if (searchString.toLocaleLowerCase().includes(search)) {
          console.log("\t return true");
          return true;
        }
      }
      console.log("\t return false");
      return false;
    },
    async open(forgottenWorkOrders: WorkOrderLike[]): Promise<WorkorderFastForwardDialogResult> {
      this.workOrders = forgottenWorkOrders;
      return await this.showDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    async acceptDialog() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await workOrderService.updateWorkOrderRequiredDates(this.selectedValues, this.date);
        this.closeDialog(true);
      } catch (error) {
      } finally {
        this.processing = false;
      }
    },
    itemIsSelected(item: WorkOrderLike): boolean {
      return this.selectedValues.includes(item.id!);
    },
    flipItemSelected(item: WorkOrderLike) {
      let isSelected = this.itemIsSelected(item);
      if (isSelected) {
        let index = this.selectedValues.indexOf(item.id!);
        if (index > -1) {
          this.selectedValues.splice(index, 1);
        }
      } else {
        this.selectedValues.push(item.id!);
      }
    },

    flipSearchedItemsSelected() {
      let selected = !this.allSearchedItemsSelected;
      console.log(`flipSearchedItemsSelected searchedItems: ${this.searchedItems.length}`);
      for (let item of this.searchedItems) {
        let isSelected = this.itemIsSelected(item);
        if (isSelected !== selected) {
          if (isSelected) {
            let index = this.selectedValues.indexOf(item.id!);
            if (index > -1) {
              this.selectedValues.splice(index, 1);
            }
          } else {
            this.selectedValues.push(item.id!);
          }
        }
      }
    }
  }
});

export default WorkorderFastForwardDialog;

export async function showWorkorderFastForwardDialog(
  forgottenWorkOrders: WorkOrderLike[]
): Promise<WorkorderFastForwardDialogResult> {
  let dialog = createDialog(WorkorderFastForwardDialog);
  dialog.optOutOfErrorHandling();
  let result = await dialog.open(forgottenWorkOrders);
  console.log("showWorkorderFastForwardDialog.result: " + JSON.stringify(result));
  return result;
}

