import { ISO, isoService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

export type LoadIsosByTestPackageIDParameter = {
  testPackageID: string;
  forcedArchivedState: boolean;
  archivedFromDate: Date | null;
  archivedToDate: Date | null;
};

var storeModule = createAutomaticCrudStoreModule({
  crudService: isoService,
  singularStoreName: "ISO",
  pluralStoreName: "ISOS",
  localizationPrefix: "systems.test-packages.isos",
  consumerRelatedIDProperty: {
    name: "isoID",
    type: "string"
  },
  storeExtensions: {
    actions: {
      async LOAD_ISOS_BY_TEST_PACKAGE_ID(
        context: any,
        payload: LoadIsosByTestPackageIDParameter
      ): Promise<ISO[]> {
        var response = await isoService.getByTestPackageID(
          payload.testPackageID,
          payload.forcedArchivedState,
          payload.archivedFromDate,
          payload.archivedToDate
        );
        context.commit("SET_ISOS", response);
        return response;
      }
    }
  }
});

export default storeModule;

