import FDVue from "..";

export default FDVue.extend({
  name: "fp-item-disabled-message",
  props: {
    message: { type: String, default: "" },
    icon: { type: String, default: "info" },
    cy: { type: String, default: undefined },
    dataCy: { type: String, default: "fp-item-disabled-message" },
    dense: { type: [Boolean, String], default: false }
  },
  computed: {
    isDense() {
      return !(this.dense == undefined || this.dense == "false" || this.dense === false);
    }
  }
});

