import FDVue from "@fd/lib/vue";
import { PropValidator } from "vue/types/options";

export interface WorkOrderWithNotes {
  notes: string | null;
}

export default FDVue.extend({
  name: "fd-work-order-notes",

  components: {
    "fd-value-display": () => import("@fd/lib/vue/components/FP.ValueDisplay.vue")
  },

  props: {
    workOrder: { type: [Object] } as PropValidator<WorkOrderWithNotes>,
    cy: { type: String, default: "fd-work-order-notes" }
  }
});

