var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "timesheetsummary" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            { attrs: { loading: _vm.processing } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tabs",
            {
              ref: "tabs",
              staticClass: "mt-2",
              attrs: {
                "icons-and-text": "",
                "show-arrows": _vm.$vuetify.breakpoint.smAndUp,
                "next-icon": "fa-arrow-circle-right",
                "prev-icon": "fa-arrow-circle-left"
              },
              model: {
                value: _vm.active_tab,
                callback: function($$v) {
                  _vm.active_tab = $$v
                },
                expression: "active_tab"
              }
            },
            [
              _vm.maintenanceTab.visible || _vm.$vuetify.breakpoint.smAndUp
                ? _c(
                    "v-tab",
                    {
                      key: _vm.maintenanceTab.key,
                      attrs: { href: `#tab-${_vm.maintenanceTab.key}` },
                      on: {
                        click: function($event) {
                          return _vm.tabSelected(_vm.maintenanceTab)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.maintenanceTab.tabname) +
                          "\n        "
                      ),
                      _c("v-icon", [
                        _vm._v(
                          "fas " +
                            _vm._s(_vm.$store.getters.icon("maintenance"))
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-tab-item", {
                key: _vm.maintenanceTab.key,
                attrs: { value: `tab-${_vm.maintenanceTab.key}` }
              }),
              _vm._v(" "),
              _vm.paintTab.visible || _vm.$vuetify.breakpoint.smAndUp
                ? _c(
                    "v-tab",
                    {
                      key: _vm.paintTab.key,
                      attrs: { href: `#tab-${_vm.paintTab.key}` },
                      on: {
                        click: function($event) {
                          return _vm.tabSelected(_vm.paintTab)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.paintTab.tabname) +
                          "\n        "
                      ),
                      _c("v-icon", [
                        _vm._v(
                          "fas " + _vm._s(_vm.$store.getters.icon("paint"))
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-tab-item",
                {
                  key: _vm.paintTab.key,
                  attrs: { value: `tab-${_vm.paintTab.key}` }
                },
                [
                  _c(
                    "v-expansion-panels",
                    {
                      attrs: { accordion: "" },
                      model: {
                        value: _vm.paintPanel,
                        callback: function($$v) {
                          _vm.paintPanel = $$v
                        },
                        expression: "paintPanel"
                      }
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        { staticClass: "secondary-section-style" },
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .$t(
                                    "build-sheet-setup.tabs.paint.application-types.title"
                                  )
                                  .toUpperCase()
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("sp-application-type-list-form", {
                                attrs: { "parent-context": "buildsheetsetup" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel",
                        { staticClass: "secondary-section-style" },
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .$t(
                                    "build-sheet-setup.tabs.paint.paint-codes.title"
                                  )
                                  .toUpperCase()
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("sp-paint-code-list-form", {
                                attrs: { "parent-context": "buildsheetsetup" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel",
                        { staticClass: "secondary-section-style" },
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .$t(
                                    "build-sheet-setup.tabs.paint.set-up-types.title"
                                  )
                                  .toUpperCase()
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("sp-set-up-type-list-form", {
                                attrs: { "parent-context": "buildsheetsetup" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel",
                        { staticClass: "secondary-section-style" },
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .$t("build-sheet-setup.tabs.paint.tsas.title")
                                  .toUpperCase()
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("sp-tsa-list-form", {
                                attrs: { "parent-context": "buildsheetsetup" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel",
                        { staticClass: "secondary-section-style" },
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .$t(
                                    "build-sheet-setup.tabs.paint.work-categories.title"
                                  )
                                  .toUpperCase()
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("sp-work-category-list-form", {
                                attrs: { "parent-context": "buildsheetsetup" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.insulationTab.visible || _vm.$vuetify.breakpoint.smAndUp
                ? _c(
                    "v-tab",
                    {
                      key: _vm.insulationTab.key,
                      attrs: { href: `#tab-${_vm.insulationTab.key}` },
                      on: {
                        click: function($event) {
                          return _vm.tabSelected(_vm.insulationTab)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.insulationTab.tabname) +
                          "\n        "
                      ),
                      _c("v-icon", [
                        _vm._v(
                          "fas " + _vm._s(_vm.$store.getters.icon("insulation"))
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-tab-item", {
                key: _vm.insulationTab.key,
                attrs: { value: `tab-${_vm.insulationTab.key}` }
              }),
              _vm._v(" "),
              _c(
                "v-menu",
                {
                  attrs: { bottom: "", left: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass:
                                    "align-self-center mr-4 d-flex d-sm-none more-tab-button",
                                  staticStyle: { position: "relative" },
                                  attrs: { text: "" }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("common.more")) +
                                  "\n\n            "
                              ),
                              _c(
                                "div",
                                [_c("v-icon", [_vm._v("fas fa-ellipsis-h")])],
                                1
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-list",
                    { staticClass: "grey lighten-3" },
                    _vm._l(_vm.hiddenTabDefinitions, function(item) {
                      return _c(
                        "v-list-item",
                        {
                          key: item.tabname,
                          on: {
                            click: function($event) {
                              return _vm.showNewTabFromMoreMenu(item)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.tabname) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }