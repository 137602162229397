import { tagService, Tag } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

export interface TaggableItem {
  tagIDs?: string[];
}

var storeModule = createAutomaticCrudStoreModule<Tag, TaggableItem>({
  crudService: tagService,
  singularStoreName: "TAG",
  pluralStoreName: "TAGS",
  localizationPrefix: "tags",
  consumerRelatedIDProperty: {
    name: "tagIDs",
    type: "array"
  }
});

export default storeModule;
