import FDVue from "@fd/lib/vue";
export default FDVue.extend({
  name: "fd-login-terms-and-conditions-accepted-form",
  props: {
    value: { type: Number, default: 5 },
    show: { type: Boolean, default: false },
    smallishMobileDevice: { type: Boolean, default: false },
    smallWidthBrowser: { type: Boolean, default: false }
  },
  methods: {
    allowNotificationsChanged(value: boolean, contactMethod: any) {
      this.$emit("change:allowNotifications", value, contactMethod.id);
    }
  }
});

