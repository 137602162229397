import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { mapMutations, mapActions, mapState } from "vuex";
import { ProjectCostCode, WorkSubType, WorkType } from "../services";
import rules from "@fd/lib/vue/rules";

type WorkSubTypeWithArchived = WorkSubType & { archived: boolean };

export default FDVue.extend({
  name: "fd-work-sub-type-existing",

  mixins: [errorHandling, rules],

  components: {
    "fd-back-button": () => import("@fd/lib/vue/components/BackButton.vue")
  },

  data() {
    return {
      loaded: false,
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,

      slidein: false,

      //Simple object for the form data
      workSubType: {} as WorkSubTypeWithArchived
    };
  },
  computed: {
    allCostCodes(): ProjectCostCode[] {
      return this.$store.state.projectCostCodes.fullList as ProjectCostCode[];
    },
    workSubTypeIsMisconfigured(): boolean {
      return (
        !!this.workSubType.id &&
        !this.workSubType.useWorkOrderCostCode &&
        !this.workSubType.defaultCostCodeID
      );
    }
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      this.inlineMessage.message = null;

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      if (!this.workSubType.useWorkOrderCostCode && !this.workSubType.defaultCostCodeID) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.workSubType.archived) {
          this.workSubType.archivedDate = null;
        } else if (this.workSubType.archived && !this.workSubType.archivedDate) {
          this.workSubType.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateWorkSubType(this.workSubType);
        if (closeOnComplete) {
          this.$router.push(`/worktypes/${this.workSubType.workTypeID}`);
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // Method used in conjunction with the Delete button.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteWorkSubType({ id: this.workSubType.id, name: this.workSubType.name });
        this.$router.push(`/worktypes/${this.workSubType.workTypeID}`);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push(`/worktypes/${this.workSubType.workTypeID}`);
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB"
    }),
    ...mapActions({
      loadWorkType: "LOAD_WORK_TYPE",
      loadWorkSubType: "LOAD_WORK_SUB_TYPE",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES",
      updateWorkSubType: "UPDATE_WORK_SUB_TYPE",
      deleteWorkSubType: "DELETE_WORK_SUB_TYPE",
      loadCostCodes: "LOAD_PROJECT_COST_CODES"
    })
  },

  watch: {
    workSubType: async function(newValue) {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/worktypes") {
        this.notifyNewBreadcrumb({
          text: this.$t("work-types.list.title"),
          to: "/worktypes",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        await this.loadWorkType(newValue.workTypeID);

        var workType = this.$store.state.workTypes.fullList.find(
          (x: any) => x.id == newValue.workTypeID
        ) as WorkType;

        this.notifyNewBreadcrumb({
          text: workType.name,
          to: `/worktypes/${workType.id}`
        });

        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: newValue.name,
        to: `/worksubtypes/${this.$route.params.subAreaID}`
      });
    },
    "workSubType.defaultCostCodeID": function(newValue, oldValue) {
      if (!this.loaded) return;
      console.log(`workSubType.defaultCostCodeID ${oldValue} -> ${newValue}`);
      if (newValue == oldValue) return;
      if (!!newValue) this.workSubType.useWorkOrderCostCode = false;
    },
    "workSubType.useWorkOrderCostCode": function(newValue, oldValue) {
      if (!this.loaded) return;
      console.log(`workSubType.useWorkOrderCostCode ${oldValue} -> ${newValue}`);
      if (newValue == oldValue) return;
      if (!!newValue) this.workSubType.defaultCostCodeID = null;
    },
    "workSubType.isWorkOrderRelated": function(newValue, oldValue) {
      if (!this.loaded) return;
      console.log(`workSubType.isWorkOrderRelated ${oldValue} -> ${newValue}`);
      if (newValue == oldValue) return;
      if (!newValue) this.workSubType.useWorkOrderCostCode = false;
    }
  },

  created: async function() {
    this.inlineMessage.message = null;
    this.processing = true;

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    try {
      await this.loadCostCodes();
      await this.loadWorkSubType(this.$route.params.id);
      var workSubType = (this.$store.state.workSubTypes.fullList as WorkSubType[]).find(
        x => x.id == this.$route.params.id
      )!;
      this.workSubType = { ...workSubType, archived: !!workSubType.archivedDate };
      this.$nextTick(() => {
        this.loaded = true;
      });
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

