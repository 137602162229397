export class TextEncoderPolyfill {
  public encode(value: string): Uint8Array {
    // TODO: Replace with true UTF8 encoding when we build this and other polyfills to be "pluggable" (so they don't affect webpack size)
    let encodedData = new Uint8Array(new ArrayBuffer(value.length));
    for (let i = 0; i < value.length; i++) {
      encodedData[i] = value.charCodeAt(i);
    }
    return encodedData;
  }
  public encodeInto(source: string, destination: Uint8Array): TextEncoderEncodeIntoResult {
    const lengthEncoded = Math.min(source.length, destination.length);
    for (let i = 0; i < lengthEncoded; i++) {
      destination[i] = source.charCodeAt(i);
    }
    return {
      read: lengthEncoded,
      written: lengthEncoded
    };
  }
}

export class TextDecoderPolyfill {
  public decode(value: BufferSource): string {
    let uint8Array: Uint8Array;
    if (value instanceof Uint8Array) {
      uint8Array = value;
    } else if ("buffer" in value) {
      uint8Array = new Uint8Array(value.buffer);
    } else {
      uint8Array = new Uint8Array(value);
    }

    // TODO: Make this more efficient in terms of string building
    let unencodedData = "";
    for (let i = 0; i < uint8Array.length; i++) {
      // TODO: Replace with true UTF8 encoding when we build this and other polyfills to be "pluggable" (so they don't affect webpack size)
      unencodedData += String.fromCharCode(value[i]);
    }
    return unencodedData;
  }
}

export function encodeIntoPolyfill(
  this: TextEncoder,
  source: string,
  destination: Uint8Array
): TextEncoderEncodeIntoResult {
  // Use the built-in encode to do the heavy lifting; this is memory-inefficient but will keep
  // this polyfill to the minimum size since most platforms don't need it
  let fullEncoding = this.encode(source);
  if (fullEncoding.length > destination.length) {
    fullEncoding = fullEncoding.subarray(0, destination.length);
  }

  destination.set(fullEncoding);
  return {
    written: fullEncoding.length
  };
}
