import { reactive } from "vue";
import { Module, Store } from "vuex";
import { Record, DropDownListItem, BasicReferenceDataCrudService } from "../baseTypes";

export interface ReferenceState<ReferenceDataRecord extends Record> {
  recordsByID: Map<string, ReferenceStateItem<ReferenceDataRecord>>;
  isLoading: boolean;
  nextUpdateTime: UpdateTime;
  cacheTimeout: UpdateTime;
}

export interface ReferenceStateItem<ReferenceDataRecord extends Record> {
  record: ReferenceDataRecord | null;
  dropdownListItem: DropDownListItem | null;
  nextUpdateTime: UpdateTime;
  isLoading: boolean;
}

export interface CaptionSelector<ReferenceDataRecord> {
  (record: ReferenceDataRecord): string;
}

export interface UpdateOptions {
  clearExisting?: boolean;
}

export type UpdateTime = number;

// TODO: Establish a "full reset" schedule? Is there an amount of time after which we should reset everything?
// TODO: Load the full set of records on initial creation?

function createRecordState<ReferenceDataRecord extends Record>(
  record: ReferenceDataRecord,
  captionSelector: CaptionSelector<ReferenceDataRecord> | null,
  nextUpdateTime: UpdateTime,
  isLoading: boolean
): ReferenceStateItem<ReferenceDataRecord> {
  return reactive({
    record,
    dropdownListItem: captionSelector ? {
      id: record.id,
      caption: captionSelector(record)
    } : null,
    nextUpdateTime,
    isLoading
  }) as ReferenceStateItem<ReferenceDataRecord>;
}

function createUnloadedRecordPlaceholder<ReferenceDataRecord extends Record>(
  id: string,
  nextUpdateTime: UpdateTime,
  hasDropdownListItem: boolean
): ReferenceStateItem<ReferenceDataRecord> {
  return reactive({
    record: { id } as ReferenceDataRecord,
    dropdownListItem: hasDropdownListItem ? {
      id,
      caption: ""
    } : null,
    nextUpdateTime,
    isLoading: true
  }) as ReferenceStateItem<ReferenceDataRecord>;
}

function resetState<ReferenceDataRecord extends Record>(
  state: ReferenceState<ReferenceDataRecord>,
  records: ReferenceDataRecord[],
  captionSelector: CaptionSelector<ReferenceDataRecord>,
  nextUpdateTime: UpdateTime
): void {
  state.recordsByID = reactive(new Map(
    records.map(record => [record.id, createRecordState(record, captionSelector, nextUpdateTime, false)])
  ));
}

function* getDropDownListItems<ReferenceDataRecord extends Record>(
  values: IterableIterator<ReferenceStateItem<ReferenceDataRecord>>
) {
  for (let value of values) {
    if (value.dropdownListItem) yield value.dropdownListItem;
  }
}

function updateRecordsByID<ReferenceDataRecord extends Record>(
  state: ReferenceState<ReferenceDataRecord>,
  updatedItems: ReferenceDataRecord[],
  requestedIDs: string[],
  captionSelector: CaptionSelector<ReferenceDataRecord>,
  nextUpdateTime: UpdateTime
) {
  // Update the records we found first
  let requestedIDSet = new Set(requestedIDs);
  for (let record of updatedItems) {
    requestedIDSet.delete(record.id);
    let existingRecordState = state.recordsByID.get(record.id);
    if (existingRecordState) {
      let existingRecord = existingRecordState.record;
      if (existingRecord != null) {
        for (let key in record) {
          if (existingRecord[key] != record[key]) {
            existingRecord[key] = record[key];
          }
        }
      } else {
        existingRecordState.record = record;
      }
      if (existingRecordState.dropdownListItem) {
        existingRecordState.dropdownListItem.caption = captionSelector(record);
      }
      existingRecordState.nextUpdateTime = nextUpdateTime;
      existingRecordState.isLoading = false;
    } else {
      state.recordsByID.set(record.id, createRecordState(record, captionSelector, nextUpdateTime, false));
    }
  }

  // Also update unfound records so we don't remain stuck in the loading state
  for (let unfoundID of requestedIDSet) {
    let existingRecordState = state.recordsByID.get(unfoundID);

    // I don't expect that we'll ever not have found the existing record state, but just in case
    // the server returns something we don't expect or another process deletes the records we'll
    // check for it just in case
    if (existingRecordState) {
      // If we get here the record is no longer present on the server or has been made unavailable
      // to the current user
      // TODO: Do we want to delete everything or leave it present?
      existingRecordState.record = null;
      if (existingRecordState.dropdownListItem) {
        existingRecordState.dropdownListItem = null;
      }
      existingRecordState.nextUpdateTime = nextUpdateTime;
      existingRecordState.isLoading = false;
    }
  }
}

function createReferenceDataModule<ReferenceDataRecord extends Record>(
  referenceDataService: BasicReferenceDataCrudService<ReferenceDataRecord>,
  captionSelector: CaptionSelector<ReferenceDataRecord>
): Module<ReferenceState<ReferenceDataRecord>, {}> {
  return {
    namespaced: true,
    state: () => ({
      recordsByID: new Map(),
      isLoading: true,
      nextUpdateTime: new Date().getTime(),
      cacheTimeout: 15 * 60 * 1000 // TODO: Make this configurable per list?
    }),
    actions: {
      async updateIfCacheExpired(context) {

      },
      async updateFull(context, payload?: UpdateOptions) {
        context.commit("fullUpdateStarted", payload);
        let newFullList = await referenceDataService.getReferenceList();
        context.commit("fullUpdateCompleted", newFullList);
      },
      async fillInIDs(context, payload: string[]) {
        // If our cached is timed out, either due to us having never loaded anything or because
        // it is older than desired, do a full requery
        if (context.state.nextUpdateTime > new Date().getTime()) {
          let updatedItems = await referenceDataService.getReferencesByID(payload);
          context.commit("missingItemUpdateCompleted", { requestedIDs: payload, updatedItems });
        } else {
          let newFullList = await referenceDataService.getReferenceList();
          context.commit("fullUpdateCompleted", newFullList);
        }
      }
    },
    mutations: {
      fullUpdateStarted(state, payload?: UpdateOptions) {
        if (payload?.clearExisting) {
          state.recordsByID = new Map();
        }
        state.isLoading = true;
      },
      fullUpdateCompleted(state, payload: ReferenceDataRecord[]) {
        resetState(state, payload, captionSelector, new Date().getTime() + state.cacheTimeout);
        state.isLoading = false;
      },
      addItemPlaceholder(state, payload: ReferenceStateItem<ReferenceDataRecord>) {
        // TODO: Is anything referencing this method? If not, we should delete it
        if (!payload.record) throw new Error("Item placeholders need to have a stub record present.")
        state.recordsByID.set(payload.record.id, payload);
      },
      missingItemUpdateCompleted(state, payload: { requestedIDs: string[], updatedItems: ReferenceDataRecord[] }) {
        updateRecordsByID(state, payload.updatedItems, payload.requestedIDs, captionSelector, new Date().getTime() + state.cacheTimeout);
        state.isLoading = false;
      }
    },
    getters: {
      dropDownListItems(state) {
        return Array.from(
          getDropDownListItems(state.recordsByID.values())
        ).sort((a, b) => a.caption < b.caption ? -1 : a.caption == b.caption ? 0 : 1);
      }
    }
  };
}

export class ReferenceDataManager<ReferenceDataRecord extends Record> {
  public constructor(
    public readonly rootModulePath: string,
    public captionSelector: CaptionSelector<ReferenceDataRecord>,
    private store: Store<any>
  ) { }

  public refresh(options?: UpdateOptions) {
    return this.store.dispatch(`${this.rootModulePath}/updateFull`, options);
  }

  private pendingUnfoundItems = new Set<string>();

  public lookup(id: string): ReferenceDataRecord;
  public lookup(id: string | null): ReferenceDataRecord | null;
  public lookup(id: string | null): ReferenceDataRecord | null {
    if (id) {
      return this.getItemState(id).record;
    } else {
      return null;
    }
  }

  // TODO: No one likes the name "caption" - do we switch to "label"?
  // TODO: `undefined` means `not loaded yet` - is that a good use given our other use of `undefined` = "does not exist at all"?
  public lookupCaption(id: string | null): string | null | undefined {
    if (id) {
      let itemState = this.getItemState(id);
      if (itemState.dropdownListItem) {
        return itemState.dropdownListItem.caption;
      } else if (!itemState.isLoading) {
        if (itemState.record) {
          return this.captionSelector(itemState.record);
        } else {
          return null;
        }
      } else {
        return undefined;
      }
    } else {
      return id; // Return null or undefined based on what we are given
    }
  }

  public getItemState(id: string): ReferenceStateItem<ReferenceDataRecord> {
    // TODO: Check time here and run new query after timeout period?
    let relatedRecordState = this.state.recordsByID.get(id);
    if (!relatedRecordState) {
      // If we don't have a related record this is a missing record due to a few possible issues
      // (the item could be archived, it could be deleted, etc.); for this stage we're not going
      // to bother with trying to figure out why we don't have the record and we'll simply assume
      // it was deleted, which means it won't show up in the drop down list; if this assertion is
      // false we'll catch it during the next full refresh or if the user refreshes the browser
      relatedRecordState = createUnloadedRecordPlaceholder(
        id,
        new Date().getTime() + this.state.cacheTimeout,
        false
      );
      this.state.recordsByID.set(id, relatedRecordState);
      this.registerUnfoundItemForLoading(id);
    }
    return relatedRecordState;
  }

  private registerUnfoundItemForLoading(id: string): void {
    // When we receive the first unfound item for loading we kick off a new async loading process;
    // if our collection isn't empty it means there's a background process that will kick off at
    // some point in the future
    if (this.pendingUnfoundItems.size == 0) {
      setTimeout(() => {
        let pendingUnfoundItems = this.pendingUnfoundItems;
        this.pendingUnfoundItems = new Set();
        this.loadUnfoundItems(pendingUnfoundItems);
      }); // TODO: Do we want any delay at all?
    }
    this.pendingUnfoundItems.add(id);
  }

  private loadUnfoundItems(pendingUnfoundItems: Set<string>) {
    return this.store.dispatch(
      `${this.rootModulePath}/fillInIDs`,
      Array.from(pendingUnfoundItems.values())
    );
  }

  private get state(): ReferenceState<ReferenceDataRecord> {
    return this.store.state[this.rootModulePath];
  }

  public get dropdownListData(): DropDownListItem[] {
    return this.store.state[`${this.rootModulePath}/dropDownListItems`];
  }

  public getRelatedRecords<RelatedRecord>(
    baseList: RelatedRecord[],
    baseListLookupKey: (record: RelatedRecord) => string | null
  ): [baseRecord: RelatedRecord, relatedRecord: ReferenceDataRecord | null][] {
    return baseList.map(record => [record, this.lookup(baseListLookupKey(record))]);
  }
}

export function createReferenceDataStore<ReferenceDataRecord extends Record>(
  referenceTypeName: string,
  referenceDataService: BasicReferenceDataCrudService<ReferenceDataRecord>,
  captionSelector: CaptionSelector<ReferenceDataRecord>,
  store: Store<any>
): ReferenceDataManager<ReferenceDataRecord> {
  let referenceDataModule = createReferenceDataModule<ReferenceDataRecord>(
    referenceDataService,
    captionSelector
  );

  const rootModulePath = `reference/${referenceTypeName}`;
  store.registerModule(rootModulePath, referenceDataModule);

  return new ReferenceDataManager<ReferenceDataRecord>(rootModulePath, captionSelector, store);
}
