import FDVue from "../";

// Import vue-pintura
const PinturaEditor = require("../../pintura/vue-pintura/index.js").PinturaEditor;
const PinturaEditorModal = require("../../pintura/vue-pintura/index.js").PinturaEditorModal;
// Import pintura
import { getEditorDefaults } from "pintura";

export default FDVue.extend({
  name: "fd-image-editor",

  components: {
    PinturaEditor,
    PinturaEditorModal
  },

  props: {
    name: { type: String, default: undefined },
    value: { type: String, default: "" },
    cy: { type: String, default: "fd-image-editor" },
    completeButtonLabelKey: { type: String, default: "common.save" },

    activeUtil: { type: String, default: "annotate" },
    crop: { type: Boolean, default: true },
    filter: { type: Boolean, default: false },
    finetune: { type: Boolean, default: false },
    annotate: { type: Boolean, default: true },
    decorate: { type: Boolean, default: false },
    frame: { type: Boolean, default: false },
    redact: { type: Boolean, default: true },
    resize: { type: Boolean, default: false }
  },

  data: function() {
    return {
      editorProps: getEditorDefaults(),
      fileName: "" as string
    };
  },

  methods: {
    close() {
      this.$emit("input", undefined);
    },
    save() {
      (this.$refs.inline as any).editor.processImage();
    },
    handleProcess(result: any) {
      this.$emit("input", undefined);
      let fileName = [this.fileName, this.fileExtension].join(".");
      this.$emit("edit", result.dest, fileName);
    }
  },

  computed: {
    fileExtension(): string {
      return this.name.split(".").pop() ?? "";
    },
    fileNameWithoutExtension(): string {
      var nameParts = this.name.split(".");
      nameParts.pop();
      return nameParts.join(".");
    },
    enabledUtils() {
      let utils = [];

      if (this.crop) utils.push("crop");
      if (this.filter) utils.push("filter");
      if (this.finetune) utils.push("finetune");
      if (this.annotate) utils.push("annotate");
      if (this.decorate) utils.push("decorate");
      if (this.frame) utils.push("frame");
      if (this.redact) utils.push("redact");
      if (this.resize) utils.push("resize");
      return utils;
    }
  },

  watch: {
    name(newValue: string) {
      this.fileName = this.fileNameWithoutExtension;
    }
  }
});
