var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "transfers" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.currentUserCanViewTransfers
        ? _c("v-alert", { staticClass: "mt-0 mb-3", attrs: { type: "info" } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("transfers.no-permission-to-view")) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            {
              attrs: { loading: _vm.processing },
              scopedSlots: _vm._u([
                {
                  key: "extension",
                  fn: function() {
                    return [
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "add-fab-button-primary",
                                        attrs: {
                                          fab: "",
                                          absolute: "",
                                          bottom: "",
                                          left: "",
                                          elevation: "5",
                                          depressed:
                                            _vm.processing ||
                                            !_vm.currentUserCanCreateTransfers,
                                          disabled:
                                            _vm.processing ||
                                            !_vm.currentUserCanCreateTransfers,
                                          color: "new"
                                        }
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                { on: { click: _vm.openNewDelivery } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "transfers.new.scaffold-delivery"
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { on: { click: _vm.openNewReturn } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("transfers.new.scaffold-return")
                                      )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { on: { click: _vm.openNewYardTransfer } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("transfers.new.yard-transfer")
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass:
                "d-flex align-center justify-end ml-0 mr-0 fd-inline-radio-button-qualifier-background"
            },
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "fd-badge-radio-group",
                  attrs: { row: "" },
                  model: {
                    value: _vm.transferContext,
                    callback: function($$v) {
                      _vm.transferContext = $$v
                    },
                    expression: "transferContext"
                  }
                },
                [
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t("transfers.list.selection-type-delivery"),
                      value: "delivery",
                      "data-cy": "radiodelivery"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t("transfers.list.selection-type-return"),
                      value: "return",
                      "data-cy": "radioreturn"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t("transfers.list.selection-type-transfer"),
                      value: "transfer",
                      "data-cy": "radiotransfer"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0 order-sm-1 order-2",
                      attrs: { cols: "12", sm: "6", md: "9" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: { align: "center", justify: "center" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-5",
                              attrs: {
                                small: "",
                                rounded: "",
                                outlined: "",
                                disabled: _vm.processing,
                                color: "#7a7a7a"
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              !_vm.$vuetify.breakpoint.sm
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        left: _vm.$vuetify.breakpoint.smAndUp
                                      }
                                    },
                                    [_vm._v("mdi-reload")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            class: [
                              _vm.$vuetify.breakpoint.sm
                                ? "fd-restrict-clearable-position"
                                : "",
                              "pt-0",
                              "mt-0",
                              "fd-table-search-input"
                            ],
                            attrs: {
                              "data-cy": "tablesearch",
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: "",
                              disabled: _vm.processing
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0 order-sm-2 order-1",
                      attrs: { cols: "12", sm: "6", md: "3" }
                    },
                    [
                      _c("fd-date-range-picker", {
                        attrs: {
                          dense: "",
                          min: _vm.minDate,
                          max: _vm.maxDate,
                          loading: _vm.processing,
                          disabled: _vm.processing,
                          "hide-details": "",
                          outlined: false,
                          presets: _vm.dateRangePresetOptions
                        },
                        on: {
                          "input:fromDate": _vm.fromDateChanged,
                          "input:toDate": _vm.toDateChanged
                        },
                        model: {
                          value: _vm.showArchivedDateRange,
                          callback: function($$v) {
                            _vm.showArchivedDateRange = $$v
                          },
                          expression: "showArchivedDateRange"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:icon.no-sort.hide-when-extra-small.class_fd-table-icon-cell",
                value: "",
                expression: "''",
                arg: "icon",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "class_fd-table-icon-cell": true
                }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:internalNumber",
                value: _vm.$t("transfers.list.number-column-label"),
                expression: "$t('transfers.list.number-column-label')",
                arg: "internalNumber"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:transferDate",
                value: _vm.$t("transfers.list.transfer-date-column-label"),
                expression: "$t('transfers.list.transfer-date-column-label')",
                arg: "transferDate"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:fromYardName.hide-when-header-text-empty",
                value:
                  _vm.transferContextIsDelivery || _vm.transferContextIsTransfer
                    ? _vm.$t("transfers.list.from-yard-column-label")
                    : "",
                expression:
                  "\n        transferContextIsDelivery || transferContextIsTransfer\n          ? $t('transfers.list.from-yard-column-label')\n          : ''\n      ",
                arg: "fromYardName",
                modifiers: { "hide-when-header-text-empty": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:fromScaffoldNumber.hide-when-header-text-empty",
                value: _vm.transferContextIsReturn
                  ? _vm.$t("transfers.list.from-scaffold-column-label")
                  : "",
                expression:
                  "\n        transferContextIsReturn ? $t('transfers.list.from-scaffold-column-label') : ''\n      ",
                arg: "fromScaffoldNumber",
                modifiers: { "hide-when-header-text-empty": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:toYardName.hide-when-header-text-empty",
                value:
                  _vm.transferContextIsReturn || _vm.transferContextIsTransfer
                    ? _vm.$t("transfers.list.to-yard-column-label")
                    : "",
                expression:
                  "\n        transferContextIsReturn || transferContextIsTransfer\n          ? $t('transfers.list.to-yard-column-label')\n          : ''\n      ",
                arg: "toYardName",
                modifiers: { "hide-when-header-text-empty": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:toScaffoldNumber.hide-when-header-text-empty",
                value: _vm.transferContextIsDelivery
                  ? _vm.$t("transfers.list.to-scaffold-column-label")
                  : "",
                expression:
                  "\n        transferContextIsDelivery ? $t('transfers.list.to-scaffold-column-label') : ''\n      ",
                arg: "toScaffoldNumber",
                modifiers: { "hide-when-header-text-empty": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:transactionNumber.hide-when-small",
                value: _vm.$t("transfers.list.transaction-number-column-label"),
                expression:
                  "\n        $t('transfers.list.transaction-number-column-label')\n      ",
                arg: "transactionNumber",
                modifiers: { "hide-when-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:workOrderNumber",
                value: _vm.$t("transfers.list.work-order-column-label"),
                expression: "$t('transfers.list.work-order-column-label')",
                arg: "workOrderNumber"
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-action-cell",
                arg: "actions",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "class_fd-action-cell": true
                }
              },
              {
                name: "fd-row-navigate",
                rawName: "v-fd-row-navigate.show-when-extra-small",
                value: item => _vm.$router.push(`/transfers/${item.id}`),
                expression: "item => $router.push(`/transfers/${item.id}`)",
                modifiers: { "show-when-extra-small": true }
              }
            ],
            ref: "datatable",
            attrs: {
              "data-cy": "list",
              "mobile-breakpoint": "0",
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "items-per-page": _vm.itemsPerPage,
              "footer-props": {
                "items-per-page-options": _vm.itemsPerPageOptions
              },
              items: _vm.filteredTransfers,
              "sort-by": "transferDate",
              "sort-desc": ""
            },
            scopedSlots: _vm._u([
              {
                key: "item.icon",
                fn: function({ item }) {
                  return [
                    !!item.reversedByTransferID
                      ? _c("v-icon", { attrs: { color: "error" } }, [
                          _vm._v("info")
                        ])
                      : _vm.transferIsReversal(item)
                      ? _c("v-icon", { attrs: { color: "warning" } }, [
                          _vm._v("info")
                        ])
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.workOrderNumber",
                fn: function({ item }) {
                  return [
                    _c("fd-value-display", {
                      attrs: { value: item.workOrderNumber }
                    }),
                    _vm._v(" "),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "", disabled: _vm.processing },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function({ on }) {
                                return [
                                  !!item.relatedTransfers &&
                                  !!item.relatedTransfers.length
                                    ? _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            staticClass: "ml-1",
                                            attrs: {
                                              small: "",
                                              color: "black",
                                              dark: ""
                                            }
                                          },
                                          on
                                        ),
                                        [_vm._v("info")]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(" "),
                        _c(
                          "v-row",
                          { attrs: { dense: "", "no-gutters": "" } },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("transfers.list.related-transfers"))
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(item.relatedTransfers, function(transfer) {
                          return _c(
                            "v-row",
                            {
                              key: transfer.id,
                              staticClass: "pl-3",
                              attrs: { dense: "", "no-gutters": "" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "transfers.list.related-transfer-details",
                                      [
                                        transfer.internalNumber,
                                        _vm.formatDate(transfer.transferDate),
                                        _vm.$t(
                                          `transfers.direction.${_vm.directionForTransfer(
                                            transfer
                                          )}`
                                        )
                                      ]
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]
                }
              },
              {
                key: "item.transferDate",
                fn: function({ item }) {
                  return [_vm._v(_vm._s(_vm.formatDate(item.transferDate)))]
                }
              },
              {
                key: "item.actions",
                fn: function({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "table-1-action-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              link: "",
                                              disabled: _vm.processing,
                                              to: `/transfers/${item.id}`
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-eye\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("common.view")))])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }