import { workPackageService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

export type LoadWorkPackagesWithStatusesParameter = {
  forcedArchivedState: boolean;
  archivedFromDate: Date | null;
  archivedToDate: Date | null;
  statuses: string[];
};

var storeModule = createAutomaticCrudStoreModule({
  crudService: workPackageService,
  singularStoreName: "WORK_PACKAGE",
  pluralStoreName: "WORK_PACKAGES",
  localizationPrefix: "workpackages",
  consumerRelatedIDProperty: {
    name: "workPackageID",
    type: "string"
  },
  storeExtensions: {
    actions: {
      async UPLOAD_WORK_PACKAGES(context, payload: Blob) {
        var response = await workPackageService.uploadWorkPackageList(payload);
        await context.dispatch("LOAD_WORK_PACKAGES");
        return response;
      },
      async LOAD_WORK_PACKAGES_WITH_STATUSES(
        context,
        payload: LoadWorkPackagesWithStatusesParameter
      ) {
        var response = await workPackageService.getAllWithStatus(
          payload.forcedArchivedState,
          payload.archivedFromDate,
          payload.archivedToDate,
          payload.statuses
        );
        context.commit("SET_WORK_PACKAGES", response);
        return response;
      }
    }
  }
});

export default storeModule;
