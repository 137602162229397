import FDVue from "@fd/lib/vue";

export default FDVue.extend({
  name: "fd-scaffold-number-with-badges",
  props: {
    value: { default: undefined, type: [String, Number] },
    otherRequestCount: { default: undefined, type: Number },
    requestCount: { default: 0, type: Number },
    otherWorkOrderCount: { default: undefined, type: Number },
    workOrderCount: { default: 0, type: Number },
    dismantleCount: { default: 0, type: Number }
  },

  data: function() {
    return {};
  },

  computed: {
    tooltip(): any {
      if (this.requestCount > 0) {
        if (this.workOrderCount == 0)
          return this.$t("scaffolds.work-summary.request-count-summary", [this.requestCount]);
        else if (this.dismantleCount == 0)
          return this.$t("scaffolds.work-summary.request-and-work-order-count-summary", [
            this.requestCount,
            this.workOrderCount
          ]);
        else
          return this.$t(
            "scaffolds.work-summary.request-and-work-order-with-dismantle-count-summary",
            [this.requestCount, this.workOrderCount]
          );
      } else if (this.workOrderCount > 0) {
        if (this.dismantleCount == 0)
          return this.$t("scaffolds.work-summary.work-order-count-summary", [this.workOrderCount]);
        else
          return this.$t("scaffolds.work-summary.work-order-with-dismantle-count-summary", [
            this.workOrderCount
          ]);
      }
    },
    showRequestBadge(): boolean {
      return (
        this.$vuetify.breakpoint.mdAndUp &&
        (this.otherRequestCount > 0 ||
          (this.otherRequestCount == undefined && this.requestCount > 0))
      );
    },
    showWorkOrderBadge(): boolean {
      return (
        this.otherWorkOrderCount > 0 ||
        (this.otherWorkOrderCount == undefined && this.workOrderCount > 0)
      );
    },
    showDismantleBadge(): boolean {
      return this.showWorkOrderBadge && this.$vuetify.breakpoint.mdAndUp && this.dismantleCount > 0;
    },
    workOrderBadgePadding(): number {
      let requestPadding = -5;
      if (this.showRequestBadge) requestPadding = this.requestCount < 10 ? 12 : 17;
      return requestPadding;
    },
    dismantleBadgePadding(): number {
      let requestPadding = this.workOrderBadgePadding;
      // If the dismantle badge is showing, the WO badge is always visible too
      return requestPadding + (this.workOrderCount < 10 ? 11 : 16);
    }
  }
});
