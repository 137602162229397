import { scaffoldHeightModifierService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: scaffoldHeightModifierService,
  singularStoreName: "SCAFFOLD_HEIGHT_MODIFIER",
  pluralStoreName: "SCAFFOLD_HEIGHT_MODIFIERS",
  localizationPrefix: "modifiers.scaffold-height",
  consumerRelatedIDProperty: {
    name: "scaffoldHeightModifierID",
    type: "string"
  }
});

export default storeModule;

