import FDVue from "@fd/lib/vue";
import { FDColumnDirective, FDHiddenArgumentName } from "@fd/lib/vue/utility/dataTable";
import { PropType } from "vue";
import { EstimateComponentData } from "../../../services";

export default FDVue.extend({
  props: {
    estimateComponents: Array as PropType<Array<EstimateComponentData>>,
    summaryPanelTimeUnitDivider: { type: Number },
    loading: { type: Boolean },
    hideActions: { type: Boolean },
    hideUnchanged: { type: Boolean },
    hideTotalRow: { type: Boolean }
  },
  directives: {
    fdColumn: FDColumnDirective
  },
  computed: {
    showIconColumn(): boolean {
      return (
        (this.estimateComponents as any[]).findIndex(
          x => !!x.isNew || !!x.isModified || !!x.isRemoved
        ) !== -1
      );
    },
    iconColumnArgument(): string {
      return this.showIconColumn ? "hasStatusChange" : FDHiddenArgumentName;
    },
    numberOfPreTimeTableColumns(): number {
      let columnNames = [] as string[];

      if (this.$vuetify.breakpoint.xl) {
        // Include "hide-when-large" columns
        columnNames.push("factor1");
        columnNames.push("factor2");
      }

      if (this.$vuetify.breakpoint.lgAndUp) {
        // Include "hide-when-medium" columns
        columnNames.push("scaffoldTypeID");
      }

      if (this.$vuetify.breakpoint.mdAndUp) {
        // Include "hide-when-small" columns
        if (this.showIconColumn) {
          columnNames.push(this.iconColumnArgument);
        }
      }

      if (this.$vuetify.breakpoint.smAndUp) {
        // Include "hide-when-extra-small" columns
      }

      columnNames.push("scaffoldSubTypeID");
      columnNames.push("length");
      columnNames.push("width");
      columnNames.push("height");

      return columnNames.length;
    },
    items(): Array<EstimateComponentData> {
      return (
        (this.estimateComponents as any[])?.filter(
          x => !this.hideUnchanged || !!x.isNew || !!x.isModified || !!x.isRemoved
        ) ?? []
      );
    },
    includedItems(): Array<EstimateComponentData> {
      return this.items.filter(x => !x.isExcluded);
    },
    totalErectMpp(): string | undefined {
      let totalErectMinutes = this.sumNumber(this.includedItems, "estimatedTotalErectMinutes") ?? 0;
      let totalPieces = this.sumNumber(this.includedItems, "estimatedTotalPartCount");
      if (!totalPieces) return undefined;

      let result = totalErectMinutes / totalPieces;
      return this.$format.number(result, 2);
    },
    totalDismantleMpp(): string | undefined {
      let totalDismantleMinutes =
        this.sumNumber(this.includedItems, "estimatedTotalDismantleMinutes") ?? 0;
      let totalPieces = this.sumNumber(this.includedItems, "estimatedTotalPartCount");
      if (!totalPieces) return undefined;

      let result = totalDismantleMinutes / totalPieces;
      return this.$format.number(result, 2);
    }
  },
  methods: {
    itemClassName(item: any): string {
      let className = "";

      if (!!item.isModified) className = "fd-modified-estimate-component-row";

      if (!!item.isNew) className = "fd-new-estimate-component-row";

      if (!!item.isRemoved) className = "fd-removed-estimate-component-row";
      else if (!!item.isExcluded) className = "fd-not-included-estimate-component-row";

      return className;
    },
    sanitizeNumber(value: number | string | undefined | null): number {
      if (!value) return 0;

      let number = Number(value);
      if (isNaN(number)) return 0;
      return number;
    },
    sumNumber(items: any[], propName: string): number | undefined {
      let obj = this;
      let result = items.reduce((a, b) => {
        if (!!b.isRemoved) return a;
        return a + (obj.sanitizeNumber(b[propName]) ?? 0);
      }, 0);
      return !result ? undefined : result;
    },
    sum(items: any[], propName: string, digits: number = 2): string | undefined {
      return this.$format.number(this.sumNumber(items, propName), digits);
    },
    getTimeDisplay(
      minutes: string | number | undefined | null,
      digits: number = 2,
      allowZero: boolean = false
    ) {
      if (minutes == undefined || minutes == null) return undefined;
      let val = Number(minutes);
      if (isNaN(val)) return undefined;

      let time = val / this.summaryPanelTimeUnitDivider;
      return this.$format.number(time, digits, allowZero);
    },
    editTableItem(item: any) {
      this.$emit("edit", item);
    },
    deleteTableItem(item: any) {
      this.$emit("delete", item);
    },
    undeleteTableItem(item: any) {
      this.$emit("undelete", item);
    }
  }
});

