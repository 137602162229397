import FDVue from "@fd/lib/vue";

export default FDVue.extend({
  components: {
    "fd-value-display": () => import("@fd/lib/vue/components/FP.ValueDisplay.vue")
  },
  props: {
    label: { type: [String] },

    value: { type: Boolean },
    valueStyle: { type: String, default: "" },
    valueClass: { type: [String, Array], default: () => [] },
    color: { type: String, default: "primary" },

    loading: { type: Boolean, default: false },

    labelCols: { type: [String, Number], default: 6 },
    valueCols: { type: [String, Number], default: 6 },
    labelSmCols: { type: [String, Number], default: 6 },
    valueSmCols: { type: [String, Number], default: 6 },

    hideNoValuePlaceholder: { type: Boolean, default: false },
    noValueKey: { type: String, default: "common.no" },
    noValueStyle: { type: String, default: "" },
    noValueClass: { type: [String, Array], default: () => [] }
  }
});

