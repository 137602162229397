import { PropType } from "vue";
import FDVue from "@fd/lib/vue";
import { ScaffoldStatuses, WorkOrderStatuses } from "../../../../services";
import { FPMapLocationColour } from "../../../../../../lib/vue/components/FP.Map.vue";

export type LocationPopupScaffold = {
  id: string;
  scaffoldStatus: ScaffoldStatuses;
  scaffoldNumber: number | null | undefined;
  externalReferenceNumber: string | null | undefined;
  areaID: string | null;
  subAreaID: string | null;
  latitude: number | null;
  longitude: number | null;
  lastWorkOrderStatus: WorkOrderStatuses | null | undefined;
};

export default FDVue.extend({
  name: "sp-scaffold-location-popup",
  props: {
    scaffolds: { type: Array as PropType<Array<LocationPopupScaffold>> }
  },
  data: function() {
    return {};
  },
  computed: {},
  methods: {
    statusColour(item: LocationPopupScaffold) {
      let colour: FPMapLocationColour = "blue";

      if (item.lastWorkOrderStatus == WorkOrderStatuses.Started) colour = "green";
      else if (item.lastWorkOrderStatus == WorkOrderStatuses.OnHold) colour = "orange";
      else if (
        item.scaffoldStatus == ScaffoldStatuses.Dismantled ||
        item.scaffoldStatus == ScaffoldStatuses.Cancelled
      ) {
        colour = "red";
      }

      return colour;
    }
  }
});

