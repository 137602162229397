import FDVue from "@fd/lib/vue";
import { TranslateResult } from "vue-i18n";
import { mapActions, mapMutations } from "vuex";
import { addDaysToDate, localizedDateTimeString } from "@fd/lib/client-util/datetime";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { VDataTable } from "@fd/lib/vue/types";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import {
  Contractor,
  ContractorWithTags,
  reportService,
  SummaryTimesheetRow,
  timesheetService,
  TimesheetType,
  TimesheetWithDetails,
  WorkSubType,
  WorkType
} from "../services";
import {
  HashTable,
  ParseWorkSubTypeIDsFromRow,
  SortAllWorkSubTypes,
  TableHeader,
  TimesheetRow,
  TimesheetRowTimeValues,
  TimesheetRowType,
  UpdatableTimesheetWithTimesheetRows
} from "../utils/timesheet";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import downloadBlob from "../../../lib/client-util/downloadBlob";
import printBlob from "../../../lib/client-util/printBlob";
import { HasName, SortItemsWithName } from "../utils/person";

type EntryGroupingType = "groupnone" | "groupperson" | "grouptransactiontype";
let today = new Date(new Date().toDateString());
export default FDVue.extend({
  name: "fd-foreman-timesheet-summary",
  mixins: [serviceErrorHandling, tabbedView],
  components: {
    "sp-timesheet-time-display": () => import("./components/SP.TimesheetTimeDisplay.vue")
  },
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },
  data: function() {
    return {
      firstTabKey: `0`,
      peopleTab: {
        tabname: this.$t("timesheets.summary.tabs.people"),
        key: "0",
        visible: true
      } as Tab,
      equipmentTab: {
        tabname: this.$t("timesheets.summary.tabs.equipment"),
        key: "1",
        visible: true
      } as Tab,

      allWorkTypes: [] as WorkType[],
      allWorkSubTypes: [] as WorkSubType[],
      usedWorkSubTypes: [] as WorkSubType[],
      perDiemSubType: undefined as WorkSubType | undefined,
      equipmentSubType: undefined as WorkSubType | undefined,

      tablesearch: "",
      selectedGroupingType: "groupperson" as EntryGroupingType,
      selectedDay: today,
      showDayShift: true,
      showNightShift: true,
      timesheetsForDay: [] as UpdatableTimesheetWithTimesheetRows[]
    };
  },
  computed: {
    directGeneralWorkOrderPlaceholderText() {
      return this.$t("timesheets.existing.generalized-direct-work-order-placeholder");
    },
    equipmentWorkOrderPlaceholderText() {
      return this.$t("timesheets.existing.equipment-work-order-placeholder");
    },
    indirectWorkOrderPlaceholderText() {
      return this.$t("timesheets.existing.indirect-work-order-placeholder");
    },
    notAvailablePlaceholder() {
      return this.$t("common.not-available");
    },
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.peopleTab, this.equipmentTab] as Tab[];
    },
    contractorsInUse(): Contractor[] {
      return this.$store.getters.getSortedInUseContractors(
        this.timesheetsForDay.filter(x => !!x.timesheetRows.length)
      );
    },
    contractorsSelectedForFiltering: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contractorsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_CONTRACTORS_FOR_FILTERING", val);
      }
    },
    visibleTimesheetsForDay(): UpdatableTimesheetWithTimesheetRows[] {
      let visibleTimesheetsForDay = this.timesheetsForDay;
      if (!!this.contractorsSelectedForFiltering?.length) {
        visibleTimesheetsForDay = visibleTimesheetsForDay.filter(x =>
          this.contractorsSelectedForFiltering.includes(x.contractorID!)
        );
      }
      return visibleTimesheetsForDay;
    },

    allPeopleTimesheetRowsForDay(): TimesheetRow[] {
      let allTimesheetRowsForDay = this.timesheetsForDay
        .map(x => x.timesheetRows)
        .reduce((a, b) => {
          return a.concat(b);
        }, []);
      return allTimesheetRowsForDay.filter(x => x.rowType != TimesheetRowType.Equipment);
    },
    visiblePeopleTimesheetRowsForDay(): TimesheetRow[] {
      let visibleTimesheetsForDay = this.visibleTimesheetsForDay;
      let allTimesheetRowsForDay = visibleTimesheetsForDay
        .map(x => x.timesheetRows)
        .reduce((a, b) => {
          return a.concat(b);
        }, []);
      let visiblePeopleTimesheetRowsForDay = allTimesheetRowsForDay.filter(
        x => x.rowType != TimesheetRowType.Equipment
      );

      if (!!this.tablesearch?.length) {
        // This specific data table may be hidden behind a tab and not accessible yet
        // Since we're not using any custom filters, we can use either data table's filter function
        let dataTable = this.$refs.summarydatatable as VDataTable;
        if (!dataTable) dataTable = this.$refs.equipmentdatatable as VDataTable;
        if (!!dataTable) {
          visiblePeopleTimesheetRowsForDay = dataTable.customFilterWithColumns(
            visiblePeopleTimesheetRowsForDay,
            this.tablesearch
          );
        }
      }
      return visiblePeopleTimesheetRowsForDay;
    },
    allEquipmentTimesheetRowsForDay(): TimesheetRow[] {
      let allTimesheetRowsForDay = this.timesheetsForDay
        .map(x => x.timesheetRows)
        .reduce((a, b) => {
          return a.concat(b);
        }, []);
      return allTimesheetRowsForDay.filter(x => x.rowType == TimesheetRowType.Equipment);
    },
    visibleEquipmentTimesheetRowsForDay(): TimesheetRow[] {
      let visibleTimesheetsForDay = this.visibleTimesheetsForDay;
      let visibleTimesheetRowsForDay = visibleTimesheetsForDay
        .map(x => x.timesheetRows)
        .reduce((a, b) => {
          return a.concat(b);
        }, []);
      let visibleEquipmentTimesheetRowsForDay = visibleTimesheetRowsForDay.filter(
        x => x.rowType == TimesheetRowType.Equipment
      );

      if (!!this.tablesearch?.length) {
        // This specific data table may be hidden behind a tab and not accessible yet
        // Since we're not using any custom filters, we can use either data table's filter function
        let dataTable = this.$refs.equipmentdatatable as VDataTable;
        if (!dataTable) dataTable = this.$refs.summarydatatable as VDataTable;

        if (!!dataTable) {
          visibleEquipmentTimesheetRowsForDay = dataTable.customFilterWithColumns(
            visibleEquipmentTimesheetRowsForDay,
            this.tablesearch
          );
        }
      }

      return visibleEquipmentTimesheetRowsForDay;
    },
    groupColumn(): string | undefined {
      if (this.selectedGroupingType == "groupperson") return "employeeName";
      else if (this.selectedGroupingType == "grouptransactiontype") return "rowType";
      return undefined;
    },
    allGroupsExpanded(): boolean {
      let toggleRefs = Object.keys(this.$refs).filter(x => x.startsWith(`summarygrouptoggle`));
      let anyGroupsClosed = false;
      for (let ref of toggleRefs) {
        let groupName = ref.replace(`summarygrouptoggle`, "");
        let isOpen = (this.$refs[`summarydatatable`] as VDataTable).openCache[groupName];
        if (!isOpen) {
          anyGroupsClosed = true;
          break;
        }
      }
      return !anyGroupsClosed;
    },
    summaryTableHeaders(): TableHeader[] {
      let headers = [] as TableHeader[];
      if (!!this.groupColumn) {
        headers.push({
          text: "",
          value: "empty",
          sortable: false,
          class: "fd-table-icon-cell",
          cellClass: "fd-table-icon-cell"
        });
      }
      headers = headers.concat([
        {
          text: this.$t("timesheets.existing.contractor-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "contractorName"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeCode"
        },
        {
          text: this.$t("timesheets.existing.employee-badge-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeBadge"
        },
        {
          text: this.$t("timesheets.existing.classification-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "classificationDisplayName"
        },
        {
          text: this.$t("timesheets.number-label") as string | TranslateResult | undefined,
          value: "timesheetNumber"
        },
        {
          text: this.$t("timesheets.existing.work-order-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "workOrderNumber"
        },
        {
          text: this.$t("timesheets.existing.area-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "areaName"
        },
        {
          text: this.$t("timesheets.existing.sub-area-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "subAreaName"
        }
      ] as TableHeader[]);

      let usedWorkSubTypes = this.usedWorkSubTypes;
      for (let workSubType of usedWorkSubTypes) {
        headers.push({
          text: workSubType.code ?? workSubType.name,
          value: workSubType.id,
          sortable: false,
          class: "fd-rotate-header-text"
        });
      }

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        sortable: false,
        class: "fd-table-column-text-end-override"
      });
      if (!!this.perDiemSubType) {
        headers.push({
          text: this.$t("common.per-diem"),
          value: "perdiem",
          sortable: false,
          class: "fd-table-column-text-end-override"
        });
      }
      return headers;
    },
    equipmentTableHeaders(): TableHeader[] {
      if (!this.equipmentSubType) return [];

      let headers = [
        {
          text: "",
          value: "empty",
          sortable: false,
          class: "fd-table-icon-cell",
          cellClass: "fd-table-icon-cell"
        },
        {
          text: this.$t("timesheets.existing.contractor-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "contractorName"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeCode"
        },
        {
          text: this.$t("timesheets.existing.employee-badge-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeBadge"
        },
        {
          text: this.$t("timesheets.existing.classification-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "classificationDisplayName"
        }
      ] as TableHeader[];

      headers.push({
        text: this.$t("timesheets.existing.equipment-days-column-label"),
        value: "equipmentDays",
        sortable: false,
        class: "fd-rotate-header-text"
      });
      headers.push({
        text: this.$t("timesheets.existing.equipment-quantity-column-label"),
        value: "equipmentQuantity",
        sortable: false,
        class: "fd-rotate-header-text"
      });

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        sortable: false,
        class: "fd-table-column-text-end-override"
      });
      return headers;
    }
  },
  watch: {
    selectedDay(newValue, oldValue) {
      if (new Date(newValue).getTime() == new Date(oldValue).getTime()) return;

      this.loadTimesheetsForSelectedDay();
    },
    showNightShift(newValue, oldValue) {
      if (newValue == oldValue) return;

      this.loadTimesheetsForSelectedDay();
    },
    showDayShift(newValue, oldValue) {
      if (newValue == oldValue) return;

      this.loadTimesheetsForSelectedDay();
    },
    selectedGroupingType() {
      this.$nextTick(() => {
        this.toggleTableGroups("summary", true);
      });
    }
  },
  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS",
      loadWorkTypes: "LOAD_WORK_TYPES",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES"
    }),
    async selectPreviousDay(e: Event) {
      e.stopPropagation();
      e.preventDefault();
      this.selectedDay = addDaysToDate(this.selectedDay, -1);
      return false;
    },
    async selectNextDay(e: Event) {
      e.stopPropagation();
      e.preventDefault();
      this.selectedDay = addDaysToDate(this.selectedDay, 1);
      return false;
    },
    toggleTableGroups(tableIdentifier: string, closed: boolean = true) {
      let allRefs = Object.keys(this.$refs);
      let toggleRefs = allRefs.filter(x => x.startsWith(`${tableIdentifier}grouptoggle`));
      let datatable = this.$refs[`${tableIdentifier}datatable`] as VDataTable;
      for (let ref of toggleRefs) {
        let groupName = ref.replace(`${tableIdentifier}grouptoggle`, "");
        let isOpen = datatable.openCache[groupName];
        if ((closed && isOpen) || (!closed && !isOpen)) {
          datatable.openCache[groupName] = !datatable.openCache[groupName];
        }
      }
    },
    labelForGroup(groupValue: string) {
      if (this.selectedGroupingType == "groupperson")
        return this.$t("timesheets.summary.person-group-label", [groupValue]);
      else if (this.selectedGroupingType == "grouptransactiontype") {
        let rowTypeName = this.$t(`timesheets.row-types.${groupValue}`);
        return `${this.$t("timesheets.summary.transaction-type-group-label", [
          rowTypeName
        ])}`.toUpperCase();
      }
      return undefined;
    },
    countPerDiems(items: TimesheetRow[]): number {
      let result = items.reduce(
        (a, b) => a + (!!b.hasPerDiem ? 1 : 0) - (!!b.removePerDiem ? 1 : 0),
        0
      );
      return result;
    },
    sumRowTimeValues(
      items: TimesheetRow[],
      propName: string | undefined
    ): TimesheetRowTimeValues | null | undefined {
      if (!propName) return undefined;

      let result = items.reduce(
        (a, b) => a.adding(b.times[propName]),
        new TimesheetRowTimeValues()
      );

      return result;
    },
    sumEquipmentDays(items: TimesheetRow[]): string | undefined {
      let result = items.reduce((a, b) => a + this.$parse.sanitizedNumber(b.equipmentDays), 0);
      return !result ? undefined : result.toFixed(2);
    },
    sumEquipmentQuantity(items: TimesheetRow[]): string | undefined {
      let result = items.reduce((a, b) => a + this.$parse.sanitizedNumber(b.equipmentQuantity), 0);
      return !result ? undefined : result.toFixed(2);
    },
    getTimesForItem(
      item: TimesheetRow,
      workSubTypeID: string | null | undefined
    ): TimesheetRowTimeValues | null | undefined {
      if (!workSubTypeID?.length) return undefined;
      return item.times[workSubTypeID];
    },
    getTimeValueForItem(
      item: TimesheetRow,
      workSubTypeID: string | null | undefined
    ): string | null | undefined {
      return this.getTimesForItem(item, workSubTypeID)?.summaryString;
    },
    calculateTotalForItem(item: TimesheetRow): TimesheetRowTimeValues {
      var allWorkSubTypes = this.allWorkSubTypes;
      let total = allWorkSubTypes.reduce(
        (a: TimesheetRowTimeValues, b: WorkSubType) => a.adding(item.times[b.id!]),
        new TimesheetRowTimeValues()
      );

      let equipmentTotal = (item.equipmentDays ?? 0) * (item.equipmentQuantity ?? 1);
      if (!!equipmentTotal) total.regularTime = (total.regularTime ?? 0) + equipmentTotal;

      return total;
    },
    calculateTotalForItems(items: TimesheetRow[]): TimesheetRowTimeValues {
      let total: TimesheetRowTimeValues = items.reduce(
        (a: TimesheetRowTimeValues, b: any) => a.adding(this.calculateTotalForItem(b)),
        new TimesheetRowTimeValues()
      );
      return total;
    },

    workOrderNumberTextForRow(row: TimesheetRow): string | TranslateResult | undefined {
      if (!!row.workOrderNumber) return this.$t("common.work-order-number", [row.workOrderNumber]);
      return undefined;
    },
    workOrderPlaceholderTextForRow(row: TimesheetRow): string | TranslateResult {
      if (row.rowType == TimesheetRowType.DirectGeneral)
        return this.directGeneralWorkOrderPlaceholderText;
      else if (row.rowType == TimesheetRowType.Equipment)
        return this.equipmentWorkOrderPlaceholderText;
      return this.indirectWorkOrderPlaceholderText;
    },
    // Only indirect work rows can have per diem entry
    isPerDiemApplicable(item: TimesheetRow): boolean {
      return !item.workOrderID || this.perDiemSubType?.isWorkOrderRelated == true;
    },

    async downloadAndPrintPeopleTimesheetSummaryReport(
      showOnlyVisible: boolean,
      reportType: string,
      overviewOnly: boolean
    ) {
      let rows = showOnlyVisible
        ? this.visiblePeopleTimesheetRowsForDay
        : this.allPeopleTimesheetRowsForDay;
      return await this.downloadAndPrintTimesheetSummaryReportForTimesheetRows(
        rows,
        reportType,
        overviewOnly
      );
    },
    async downloadAndPrintEquipmentTimesheetSummaryReport(
      showOnlyVisible: boolean,
      reportType: string,
      overviewOnly: boolean
    ) {
      let rows = showOnlyVisible
        ? this.visibleEquipmentTimesheetRowsForDay
        : this.allEquipmentTimesheetRowsForDay;
      return await this.downloadAndPrintTimesheetSummaryReportForTimesheetRows(
        rows,
        reportType,
        overviewOnly
      );
    },
    getTimeTotalForWorkSubTypeFromRow(workSubTypeID: string, row: TimesheetRow): number {
      return this.$parse.sanitizedNumber(row.times[workSubTypeID].totalTime);
    },
    totalRows(rows: TimesheetRow[]): TimesheetRow {
      let totalRow = { perdiemCount: 0, times: {} } as TimesheetRow & { perdiemCount: number };
      for (let row of rows) {
        if (!totalRow.employeeName) totalRow.employeeName = row.employeeName;
        if (!totalRow.employeeCode) totalRow.employeeCode = row.employeeCode;
        if (!totalRow.employeeBadge) totalRow.employeeBadge = row.employeeBadge;
        if (!totalRow.classificationDisplayName)
          totalRow.classificationDisplayName = row.classificationDisplayName;
        if (!!row.hasPerDiem) totalRow.perdiemCount += 1;
        if (!!row.removePerDiem) totalRow.perdiemCount -= 1;

        let hoursByWorkSubTypeID = row.times;
        let hoursByWorkSubTypeIDKeys = Object.keys(hoursByWorkSubTypeID);
        for (let wstID of hoursByWorkSubTypeIDKeys) {
          let existingTimes = totalRow.times[wstID] ?? new TimesheetRowTimeValues();
          totalRow.times[wstID] = existingTimes.adding(row.times[wstID]);
        }
      }
      if (totalRow.perdiemCount > 0) totalRow.hasPerDiem = true;
      return totalRow;
    },
    summarizeRows(rows: TimesheetRow[]): TimesheetRow[] {
      let rowsByEmployee = rows.reduce((a, b) => {
        let employeeIDForRow = b.employeeID;

        let rowsForEmployee = a[employeeIDForRow] ?? [];
        rowsForEmployee.push(b);
        a[employeeIDForRow] = rowsForEmployee;

        return a;
      }, {} as HashTable<TimesheetRow[]>);

      let summarizedRows = [] as TimesheetRow[];
      let rowKeys = Object.keys(rowsByEmployee);
      for (let employeeID of rowKeys) {
        let rowsForEmployee = rowsByEmployee[employeeID];
        let totalRowForEmployee = this.totalRows(rowsForEmployee);
        summarizedRows.push(totalRowForEmployee);
      }
      return summarizedRows;
    },
    getHourSummaryStringsByWorkSubTypeIDForRow(row: TimesheetRow): { [key: string]: string } {
      let wstIDs = ParseWorkSubTypeIDsFromRow(row);
      let hourSummaryStringsByWorkSubTypeID = wstIDs.reduce((a, b) => {
        a[b] = row.times[b]?.summaryString ?? "";
        return a;
      }, {} as HashTable<string>);
      return hourSummaryStringsByWorkSubTypeID;
    },
    getHourTotalsByWorkSubTypeIDForRow(row: TimesheetRow): { [key: string]: number } {
      let wstIDs = ParseWorkSubTypeIDsFromRow(row);
      let hourTotalsByWorkSubTypeID = wstIDs.reduce((a, b) => {
        a[b] = this.$parse.sanitizedNumber(row.times[b]?.totalTime);
        return a;
      }, {} as HashTable<number>);
      return hourTotalsByWorkSubTypeID;
    },
    async downloadAndPrintTimesheetSummaryReportForTimesheetRows(
      allTimesheetRows: TimesheetRow[],
      reportType: string,
      overviewOnly: boolean
    ) {
      let timesheetRows = allTimesheetRows;
      if (overviewOnly) {
        timesheetRows = this.summarizeRows(allTimesheetRows);
      }
      this.processing = true;
      try {
        if (timesheetRows.length == 0) {
          this.$store.dispatch("SHOW_SNACKBAR", {
            text: this.$t("timesheets.summary.printing.no-data-message"),
            type: "info"
          });
          return;
        }
        let mappedRows = timesheetRows.map(
          x =>
            ({
              name: x.employeeName,
              employeeName: x.employeeName,
              employeeCode: x.employeeCode,
              employeeBadge: x.employeeBadge,
              employeeClassification: x.classificationDisplayName,
              workOrder: this.workOrderNumberTextForRow(x),
              areaName: x.areaName,
              subAreaName: x.subAreaName,
              // hoursSummaryByWorkSubTypeID: this.getHourSummaryStringsByWorkSubTypeIDForRow(x),
              hoursTotalByWorkSubTypeID: this.getHourTotalsByWorkSubTypeIDForRow(x),
              perDiem: x.hasPerDiem,
              total: this.calculateTotalForItem(x).summaryString,
              equipmentDays: x.equipmentDays,
              equipmentQuantity: x.equipmentQuantity
            } as SummaryTimesheetRow & HasName)
        );
        let summaryTimesheetRows = SortItemsWithName(mappedRows);

        let usedWorkSubTypeIDs = summaryTimesheetRows
          .map(x =>
            // Object.keys(x.hoursSummaryByWorkSubTypeID).filter(
            //   id => !!x.hoursSummaryByWorkSubTypeID[id]?.length
            // )
            Object.keys(x.hoursTotalByWorkSubTypeID).filter(
              id => !!x.hoursTotalByWorkSubTypeID[id] && x.hoursTotalByWorkSubTypeID[id] > 0
            )
          )
          .reduce((a, b) => {
            b.forEach(id => {
              if (!a.includes(id)) a.push(id);
            });
            return a;
          }, [] as string[]);
        let allWorkSubTypes = this.allWorkSubTypes;
        let usedWorkSubTypes = allWorkSubTypes.filter(x => usedWorkSubTypeIDs.includes(x.id!));

        var blob = await reportService.getTimesheetSummaryPrintoutReportContentWithData(
          summaryTimesheetRows,
          usedWorkSubTypes,
          reportType,
          localizedDateTimeString(new Date()),
          overviewOnly
        );
        if (reportType == "xls") {
          downloadBlob(blob, "timesheet-summary-printout.xlsx");
        } else {
          printBlob(blob, "timesheet-summary-printout.pdf", "application/pdf");
        }
      } catch (error) {
      } finally {
        this.processing = false;
      }
    },

    reloadTableData() {
      this.loadTimesheetsForSelectedDay();
    },
    async loadEntriesForTimesheetAndAddToDay(
      ts: TimesheetWithDetails,
      timesheetsForDay: UpdatableTimesheetWithTimesheetRows[],
      allWorkTypes: WorkType[],
      allWorkSubTypes: WorkSubType[]
    ) {
      let entries = await timesheetService.getEntriesForTimesheetID(ts.id!);
      timesheetsForDay.push(
        new UpdatableTimesheetWithTimesheetRows(ts, entries, allWorkTypes, allWorkSubTypes)
      );
    },
    async loadTimesheetsForSelectedDay() {
      this.inlineMessage.message = "";

      this.timesheetsForDay = [];

      this.processing = true;
      try {
        let timesheets = await timesheetService.getAllForDay(
          this.selectedDay,
          this.showDayShift,
          this.showNightShift,
          true,
          false
        );

        let timesheetsForDay = [] as UpdatableTimesheetWithTimesheetRows[];
        let allWorkTypes = this.allWorkTypes;
        let allWorkSubTypes = this.allWorkSubTypes;

        let queries = [];
        for (let ts of timesheets) {
          // If the day was changed while loading, we may be loading data for timesheets that aren't for the currently selected day
          if (ts.day?.getTime() != this.selectedDay.getTime()) continue;

          // let entries = await timesheetService.getEntriesForTimesheetID(ts.id!);
          // timesheetsForDay.push(
          //   new UpdatableTimesheetWithTimesheetRows(ts, entries, allWorkTypes, allWorkSubTypes)
          // );

          queries.push(
            this.loadEntriesForTimesheetAndAddToDay(
              ts,
              timesheetsForDay,
              allWorkTypes,
              allWorkSubTypes
            )
          );
        }
        await Promise.all(queries);

        this.timesheetsForDay = timesheetsForDay;

        /**
         * Computed properties which have changed due to this data:
         * - usedWorkSubTypes
         * - summaryTableHeaders
         */
        this.setUsedWorkSubTypes();

        this.$nextTick(() => {
          this.toggleTableGroups("summary", true);
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    setUsedWorkSubTypes() {
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      let usedContractorIDs = [...new Set(this.timesheetsForDay.map(x => x.contractorID ?? ""))];
      let usedContractors = allContractors.filter(x => usedContractorIDs.includes(x.id!));
      let allowedWorkTypeIDs = [
        ...new Set(
          usedContractors.reduce((a, b) => {
            if (!b.workTypeIDs) return a;
            return a.concat(b.workTypeIDs);
          }, [] as string[])
        )
      ];
      this.usedWorkSubTypes = this.allWorkSubTypes.filter(
        x =>
          allowedWorkTypeIDs.includes(x.workTypeID!) &&
          x.id != this.perDiemSubType?.id &&
          x.id != this.equipmentSubType?.id
      );
    }
  },
  created: async function() {
    this.setFilteringContext({
      context: "timesheetsummary",
      parentalContext: null,
      selectedTab: this.firstTabKey,
      contractorsForFiltering: [],
      searchStringForFiltering: "",
      contextForFiltering: "all",
      peopleForFiltering: [],
      statusesForFiltering: []
    });

    this.notifyNewBreadcrumb({
      text: this.$t("timesheets.summary.title"),
      to: "/timesheetsummary",
      resetHistory: true
    });

    this.processing = true;
    try {
      await Promise.all([this.loadContractors(), this.loadWorkTypes(), this.loadWorkSubTypes()]);

      this.allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      this.allWorkSubTypes = SortAllWorkSubTypes(
        this.$store.state.workSubTypes.fullList as WorkSubType[],
        this.allWorkTypes
      );

      let perDiemTypeID = this.allWorkTypes.find(x => !!x.isPerDiem)?.id;
      this.perDiemSubType = this.allWorkSubTypes.find(x => x.workTypeID == perDiemTypeID);

      let equipmentTypeID = this.allWorkTypes.find(x => !!x.isEquipment)?.id;
      this.equipmentSubType = this.allWorkSubTypes.find(x => x.workTypeID == equipmentTypeID);

      await this.loadTimesheetsForSelectedDay();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

