import FDVue from "@fd/lib/vue";
import {
  WorkOrderStatuses,
  ScaffoldRequestStatuses,
  WalkdownStatuses,
  ScaffoldRequestTypes,
  ScaffoldRequestSubTypes
} from "../../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { PropType } from "vue";

export class WalkdownDetails {
  walkdownStatus: WalkdownStatuses | undefined;
  notes?: string | undefined;
  scaffoldBayLengthID?: string | null | undefined;
  bayLengthName: string | undefined;
  lengthBayCount: number | null | undefined;
  scaffoldBayWidthID?: string | null | undefined;
  bayWidthName: string | undefined;
  widthBayCount: number | null | undefined;
  scaffoldBayHeightID?: string | null | undefined;
  bayHeightName: string | undefined;
  scaffoldTypeModifierID?: string | null | undefined;
  scaffoldTypeName: string | undefined;
  scaffoldElevationModifierID?: string | null | undefined;
  elevationModifierName: string | undefined;
  scaffoldHeightModifierID?: string | null | undefined;
  heightModifierName: string | undefined;
  scaffoldDistanceModifierID?: string | null | undefined;
  distanceModifierName: string | undefined;
  scaffoldCongestionFactorID?: string | null | undefined;
  congestionFactorName: string | undefined;
  internalModifierID?: string | null | undefined;
  internalModifierName: string | undefined;
  hoardingModifierID?: string | null | undefined;
  hoardingModifierName: string | undefined;
  buildDismantleRatioID?: string | null | undefined;
  buildDismantleRatioName: string | undefined;
  submitterName: string | undefined;
  approvalComments: string | undefined;
  length: number | null | undefined;
  width: number | null | undefined;
  height: number | null | undefined;
  deckLevels: number | null | undefined;
  accessType: number | null | undefined;
  isHardBarricade: boolean | undefined;
  barricadeGates: number | null | undefined;
  crewSize: number | null | undefined;
  modificationHours: number | null | undefined;
  submittedBy: string | null | undefined;
  submittedOn: Date | null | undefined;
}
export class WorkOrderDetails {
  isLoading?: boolean | undefined;
  approvalComments?: string | undefined;
  lastStatusChangeDate: Date | null | undefined;
  lastStatusChangedByID?: string | null | undefined;
  lastStatusChangedBy: string | null | undefined;
  lastUrgentValueChangedDate: Date | null | undefined;
  lastUrgentValueChangedBy: string | null | undefined;
  lastUrgentDetailChangedDate: Date | null | undefined;
  lastUrgentDetailChangedBy: string | null | undefined;
  workPackageNames: Array<string> | undefined;
  isModifyRequest: boolean = false;
  assignedContractorID?: string | null | undefined;
  assignedContractorName: string | undefined;
  coordinatorID?: string | null | undefined;
  coordinatorName: string | undefined;
  generalForemanID?: string | null | undefined;
  generalForemanName: string | undefined;
  foremanID?: string | null | undefined;
  foremanName: string | undefined;
  requestingContractorID?: string | null | undefined;
  requestingContractorName: string | undefined;
  requestingEmployeeID?: string | null | undefined;
  requestingEmployeeName: string | undefined;
  requestSubmittedOn: Date | null | undefined;
  requestSubmittedBy?: string | null | undefined;
  requestSubmitterName: string | undefined;
  areaID?: string | null | undefined;
  areaName: string | undefined;
  subAreaID?: string | null | undefined;
  subAreaName: string | undefined;
  scaffoldNumber: number | null | undefined;
  requestNumber: string | undefined;
  disciplineID?: string | null | undefined;
  disciplineName: string | undefined;
  requestStatus: ScaffoldRequestStatuses | undefined;
  requestType: ScaffoldRequestTypes | null | undefined;
  requestSubType: ScaffoldRequestSubTypes | null | undefined;
  isPlanned: boolean | null | undefined;
  specificWorkLocation: string | undefined;
  siteContact: string | undefined;
  detailedWorkDescription: string | undefined;
  internalNumber: number | undefined;
  workOrderStatus: WorkOrderStatuses | undefined;
  workOrderStatusDetail: string | undefined;
  priority: number | undefined;
  progress: number | undefined;
  startDate: Date | null | undefined;
  completedDate: Date | null | undefined;
  requestDate: Date | null | undefined;
  requiredDate: Date | null | undefined;
  approvedRequiredDate: Date | null | undefined;
  requiredUntilDate: Date | null | undefined;
  isUrgent: boolean | undefined;
  isUrgentDetail: string | undefined;
  notes: string | undefined;
  isClientWorkOrder: boolean | undefined;
  clientWorkOrderReferenceNumber: string | undefined;
  clientWorkOrderReason: string | undefined;
  isChangeOrder: boolean | undefined;
  changeOrderReferenceNumber: string | undefined;
  changeOrderReason: string | undefined;
  isRework: boolean | undefined;
  reworkReferenceNumber: string | undefined;
  reworkReason: string | undefined;
  isServiceOrder: boolean | undefined;
  serviceOrderReferenceNumber: string | undefined;
  serviceOrderReason: string | undefined;
  purchaseOrderID: string | null | undefined;
  existingTagNumber: string | null | undefined;
  currentUserPermissions: { canViewEstimateDetails: boolean | undefined } | undefined;
}
export default FDVue.extend({
  name: "fd-work-order-details",

  components: {
    "fd-work-order-scope-details": () => import("./data/workOrderDetails/WorkOrderScopeDetails.vue")
  },

  props: {
    workOrder: { type: Object as PropType<WorkOrderDetails> },
    walkdown: { type: Object as PropType<WalkdownDetails> },
    showEditControls: { type: Boolean, default: false },
    hideScopeValues: { type: [Boolean, String], default: false },
    editControlsDisabled: { type: Boolean, default: false },
    cy: { type: String, default: "fd-work-order-details" }
  },

  computed: {
    requestStatusName() {
      return this.$t(`scaffold-requests.status.${this.workOrder.requestStatus}`);
    },
    workOrderStatusName() {
      return this.$t(`workorders.status.${this.workOrder.workOrderStatus}`);
    },
    formattedRequiredDate(): string | undefined {
      return !!this.workOrder.requiredDate
        ? DateUtil.stripTimeFromLocalizedDateTime(this.workOrder.requiredDate)
        : undefined;
    },
    requiredDateDifference(): string | undefined {
      if (
        !this.workOrder?.requiredDate ||
        !this.workOrder?.approvedRequiredDate ||
        this.workOrder.requiredDate.getTime() == this.workOrder.approvedRequiredDate.getTime()
      )
        return undefined;

      var difference =
        this.workOrder.requiredDate.getTime() - this.workOrder.approvedRequiredDate.getTime();
      var days = Math.round(Math.abs(difference) / 86400000);
      let differenceDisplay =
        difference > 0
          ? this.$t("common.days-later-label", [days])
          : this.$t("common.days-earlier-label", [days]);
      return ` (${differenceDisplay})`;
      // return ` (${days} days ${difference > 0 ? "later" : "earlier"})`;
    },
    formattedApprovedRequiredDate(): string | undefined {
      return !!this.workOrder.approvedRequiredDate
        ? DateUtil.stripTimeFromLocalizedDateTime(this.workOrder.approvedRequiredDate)
        : undefined;
    },
    formattedRequiredUntilDate(): string | undefined {
      return !!this.workOrder.requiredUntilDate
        ? DateUtil.stripTimeFromLocalizedDateTime(this.workOrder.requiredUntilDate)
        : undefined;
    },
    formattedRequestDate(): string | undefined {
      return !!this.workOrder.requestDate
        ? DateUtil.stripTimeFromLocalizedDateTime(this.workOrder.requestDate)
        : undefined;
    },
    formattedSubmittedDate(): string | undefined {
      return !!this.workOrder.requestSubmittedOn
        ? DateUtil.localizedDateTimeString(this.workOrder.requestSubmittedOn)
        : undefined;
    },
    formattedStartDate(): string | undefined {
      return !!this.workOrder.startDate
        ? DateUtil.stripTimeFromLocalizedDateTime(this.workOrder.startDate)
        : undefined;
    },
    formattedCompletedDate(): string | undefined {
      return !!this.workOrder.completedDate
        ? DateUtil.stripTimeFromLocalizedDateTime(this.workOrder.completedDate)
        : undefined;
    },
    formattedLastStatusChangeDate(): string | undefined {
      if (!this.workOrder.lastStatusChangeDate) return undefined;

      // The date comes back from the server without a timezone, but we know it's UTC
      // Convert to local
      let convertedDate = new Date(
        DateUtil.isoString(this.workOrder.lastStatusChangeDate) + "+0000"
      );
      return DateUtil.localizedDateTimeString(convertedDate);
    },
    formattedLastUrgentValueChangeDate(): string | undefined {
      return !!this.workOrder.lastUrgentValueChangedDate
        ? DateUtil.localizedDateTimeString(this.workOrder.lastUrgentValueChangedDate)
        : undefined;
    },
    lastUrgentValueChangedSummaryString(): string | undefined {
      console.log(
        `WO-${this.workOrder.internalNumber} lastUrgentValueChangedSummaryString lastUrgentValueChangedDate: ${this.workOrder.lastUrgentValueChangedDate}`
      );
      // If the work order isn't urgent, or there is no log date, return nothing
      if (!this.workOrder.isUrgent || !this.formattedLastUrgentValueChangeDate) return undefined;
      let summaryValues = [] as string[];

      if (!!this.workOrder.lastUrgentValueChangedBy?.length) {
        summaryValues.push(`${this.workOrder.lastUrgentValueChangedBy}`);
      }

      if (!!this.formattedLastUrgentValueChangeDate?.length)
        summaryValues.push(this.formattedLastUrgentValueChangeDate);

      return summaryValues.join(" - ");
    },
    formattedLastUrgentDetailChangeDate(): string | undefined {
      return !!this.workOrder.lastUrgentDetailChangedDate
        ? DateUtil.localizedDateTimeString(this.workOrder.lastUrgentDetailChangedDate)
        : undefined;
    },
    lastUrgentDetailChangedSummaryString(): string | undefined {
      // If the work order isn't urgent, or there is no log date, return nothing
      if (!this.workOrder.isUrgent || !this.formattedLastUrgentDetailChangeDate) return undefined;
      let summaryValues = [] as string[];

      if (!!this.workOrder.lastUrgentDetailChangedBy?.length) {
        var changeAction = this.$t("scheduler.urgent-detail-last-log-action-description", [
          this.workOrder.lastUrgentDetailChangedBy
        ]);
        summaryValues.push(`${changeAction}`);
      }

      if (!!this.formattedLastUrgentDetailChangeDate?.length)
        summaryValues.push(this.formattedLastUrgentDetailChangeDate);

      return summaryValues.join(" - ");
    },

    // **** STATUS CHECKS ****

    workOrderIsDeclined(): boolean {
      return (
        !!this.workOrder?.workOrderStatus &&
        this.workOrder.workOrderStatus == WorkOrderStatuses.Declined
      );
    },
    walkdownIsDeclined(): boolean {
      return !!this.walkdown && this.walkdown?.walkdownStatus == WalkdownStatuses.Declined;
    },
    workOrderIsOnHold(): boolean {
      return (
        !!this.workOrder?.workOrderStatus &&
        this.workOrder.workOrderStatus == WorkOrderStatuses.OnHold
      );
    },

    workOrderIsCancelled(): boolean {
      return (
        !!this.workOrder?.workOrderStatus &&
        this.workOrder.workOrderStatus == WorkOrderStatuses.Cancelled
      );
    },

    workOrderIsUrgent(): boolean {
      return this.workOrder.isUrgent ?? false;
    }
  },

  methods: {}
});

