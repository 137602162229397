import FDVue from "@fd/lib/vue";
import { mapMutations, mapActions } from "vuex";
import {
  FDColumnDirective,
  FDRowNavigateDirective,
  FDTableSortableDirective,
  SortableEvent
} from "@fd/lib/vue/utility/dataTable";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { addDaysToDate, addMonthsToDate } from "@fd/lib/client-util/datetime";
import { countSheetGroupService, CountSheetGroupWithParts, JobTypes } from "../services";
import ServiceError from "@fd/lib/client-util/serviceError";
import { showCountSheetGroupNewDialog } from "./components/dialogs/CountSheetGroupNewDialog.vue";
import { showCountSheetPreviewDialog } from "./components/dialogs/CountSheetPreviewDialog.vue";
import { SortCountSheetGroups } from "../dataMixins/countSheetGroupSorting";
import { stripHtml, truncateWithEllipsis } from "@fd/lib/vue/utility/helper";

type CountSheetGroupWithDetails = CountSheetGroupWithParts & {
  archived: boolean;
  partCount: number;
};
type FilteringContext = "all" | "scaffold" | "maintenance" | "paint" | "insulation";

export default FDVue.extend({
  mixins: [errorHandling],
  name: "fd-count-sheet-groups",

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    fdTableSortable: FDTableSortableDirective
  },

  components: {},

  data: function() {
    return {
      cachedCountSheetGroups: undefined as CountSheetGroupWithDetails[] | undefined,
      deleting: false,
      archivedLoading: false

      // sortedItems: undefined as CountSheetGroupWithParts[] | undefined
    };
  },

  computed: {
    visibleJobCount(): number {
      return (
        (this.showScaffoldColumn ? 1 : 0) +
        (this.showMaintenanceColumn ? 1 : 0) +
        (this.showPaintColumn ? 1 : 0) +
        (this.showInsulationColumn ? 1 : 0)
      );
    },
    showScaffoldColumn(): boolean {
      return true;
    },
    showMaintenanceColumn(): boolean {
      return this.$store.state.curEnvironment.enableMaintenanceWork ?? false;
    },
    showPaintColumn(): boolean {
      return this.$store.state.curEnvironment.enablePaintWork ?? false;
    },
    showInsulationColumn(): boolean {
      return this.$store.state.curEnvironment.enableInsulationWork ?? false;
    },
    countSheetGroups(): CountSheetGroupWithDetails[] {
      console.log(`CountSheetGroups.countSheetGroups`);
      if (!this.cachedCountSheetGroups) {
        this.cachedCountSheetGroups = SortCountSheetGroups(
          (this.$store.state.countSheetGroups.fullList as CountSheetGroupWithParts[]).map(
            x =>
              ({
                ...x,
                description: truncateWithEllipsis(stripHtml(x.description)),
                archived: !!x.archivedDate,
                partCount: x.parts?.length
              } as CountSheetGroupWithDetails)
          )
        );
      }
      var countSheetGroups = this.cachedCountSheetGroups;
      if (this.filterContext != "all") {
        countSheetGroups = countSheetGroups.filter(
          x =>
            (this.filterContext == "insulation" && x.isInsulationGroup == true) ||
            (this.filterContext == "maintenance" && x.isMaintenanceGroup == true) ||
            (this.filterContext == "paint" && x.isPaintGroup == true) ||
            (this.filterContext == "scaffold" && x.isScaffoldGroup == true)
        );
      }
      return countSheetGroups;
    },
    filterContext: {
      get(): FilteringContext {
        return (
          this.$store.state.filters.find(
            (x: any) => x.context == this.$store.state.filteringContext
          )!.contextForFiltering ?? "all"
        );
      },
      set(val: FilteringContext) {
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", val);
      }
    },

    showArchived: {
      get(): boolean {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFiltering;
      },
      async set(val: boolean) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING", val);
        this.processing = true;
        this.archivedLoading = true;
        try {
          await this.reloadCountSheetGroups();
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = false;
          this.archivedLoading = false;
        }
      }
    },

    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = addMonthsToDate(date, -2);
      return minDate;
    },

    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = addMonthsToDate(date, 2);
      return maxDate;
    },

    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          this.archivedLoading = true;
          try {
            await this.reloadCountSheetGroups();
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
            this.archivedLoading = false;
          }
        } else this.showArchivedToDate = null;
      }
    },

    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },

    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    }
  },

  methods: {
    async openNewDialog() {
      this.optOutOfErrorHandling();
      let lastOrder = 0;
      if (this.countSheetGroups.length) lastOrder = this.countSheetGroups.slice(-1)[0].order ?? 0;
      await showCountSheetGroupNewDialog(lastOrder);
      this.cachedCountSheetGroups = undefined;
    },
    async preview() {
      this.optOutOfErrorHandling();
      let jobType: JobTypes | null = null;
      if (this.filterContext == "scaffold") jobType = JobTypes.Scaffold;
      else if (this.filterContext == "paint") jobType = JobTypes.Paint;
      else if (this.filterContext == "maintenance") jobType = JobTypes.Maintenance;
      else if (this.filterContext == "insulation") jobType = JobTypes.Insulation;
      await showCountSheetPreviewDialog(
        this.reloadCountSheetGroups,
        this.reloadCountSheetGroups,
        jobType
      );
    },
    async confirmItemOrdering() {
      console.log(`confirmItemOrdering totalGroups: ${this.countSheetGroups.length}`);

      let itemsToUpdate = [] as CountSheetGroupWithParts[];

      this.countSheetGroups.forEach((group, index) => {
        let newOrder = index + 1;
        // console.log(`  ${group.name}: ${group.order} vs ${newOrder}`);
        if (!group.order || group.order != newOrder) {
          console.log(`    ${group.order} --> ${newOrder}`);
          group.order = newOrder;
          itemsToUpdate.push(group);
        }
      });

      if (itemsToUpdate.length > 0) {
        console.log(` updating ${itemsToUpdate.length} groups' ordering`);
        await countSheetGroupService.updateCountSheetGroupOrders(itemsToUpdate);

        var snackbarPayload = {
          text: this.$t("countsheetgroups.snack-bar-order-updated-message"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      }
    },
    async dragEnded(e: SortableEvent) {
      console.log(`dragEnded`);
      let oldIndex = e.oldIndex ?? 0;
      let newIndex = e.newIndex ?? 0;
      if (oldIndex == newIndex) return;

      this.countSheetGroups.splice(newIndex, 0, ...this.countSheetGroups.splice(oldIndex, 1));

      this.processing = true;
      try {
        await this.confirmItemOrdering();
      } catch (error) {
        this.handleError(error as ServiceError);
        await this.reloadCountSheetGroups();
      } finally {
        this.processing = false;
      }
    },

    async deleteTableItem(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      this.deleting = true;
      try {
        await this.deleteCountSheetGroup(item);
        this.cachedCountSheetGroups = undefined;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    },

    async flipArchived(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateCountSheetGroup({
          id: item.id,
          archivedDate: archivedDate,
          name: item.name
        });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }
    },

    async reloadTableData() {
      this.processing = true;
      try {
        await this.reloadCountSheetGroups();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async reloadCountSheetGroups() {
      await this.loadCountSheetGroups({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });
      this.cachedCountSheetGroups = undefined;
    },

    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    ...mapActions({
      loadCountSheetGroups: "LOAD_COUNT_SHEET_GROUPS",
      updateCountSheetGroup: "UPDATE_COUNT_SHEET_GROUP",
      deleteCountSheetGroup: "DELETE_COUNT_SHEET_GROUP"
    })
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "countsheetgroups",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: "",
      contextForFiltering: "all"
    });

    this.processing = true;
    try {
      this.notifyNewBreadcrumb({
        text: this.$t("countsheetgroups.setup.title"),
        to: "/countsheetgroups",
        resetHistory: true
      });

      await this.reloadCountSheetGroups();

      this.processing = true;
      await this.confirmItemOrdering();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

