import FDVue from "@fd/lib/vue";
//import workOrderModel from "../../dataMixins/workOrderModel";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import store from "../../store";
import { VForm } from "@fd/lib/vue/types";
//import statusData from "../../dataMixins/statusData";

export default FDVue.extend({
  //mixins: [errorHandling, workPackageModel, statusData],
  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue")
  },

  data: () => ({
    processing: false
  }),

  props: {
    closeAction: Function
  },

  computed: {},

  watch: {
    processing(value) {
      this.$emit("processingUpdated", value);
    }
  },

  methods: {
    //Method used in conjunction with new view dialog.
    async saveDialog() {
      if (!(this.$refs.form as VForm).validate()) {
        return;
      }

      await store.dispatch("ADD_WORK_PACKAGE", {
        //...this.workPackage,
        enabled: true
      });

      this.$props.closeAction();
    }
  },

  created() {}
});

