var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "950px", persistent: "", "data-cy": "locationdialog" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { ref: "content", attrs: { "data-cy": "materialOrdernew" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [_vm._v(_vm._s(_vm.$t("locations.dialog.title")))]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.dialogOpen
            ? _c("fp-map", {
                attrs: {
                  center: _vm.environmentLocation,
                  height: "calc(100vh - 380px)",
                  zoom: _vm.zoom,
                  "min-zoom": _vm.minZoom,
                  "max-zoom": _vm.maxZoom,
                  "bounds-size": _vm.boundsSize,
                  "show-comparison": ""
                },
                model: {
                  value: _vm.location,
                  callback: function($$v) {
                    _vm.location = $$v
                  },
                  expression: "location"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0" },
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      label: "Latitude",
                      type: "number",
                      step: "0.001",
                      disabled: _vm.processing || !_vm.location
                    },
                    model: {
                      value: _vm.latitude,
                      callback: function($$v) {
                        _vm.latitude = _vm._n($$v)
                      },
                      expression: "latitude"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      label: "Longitude",
                      type: "number",
                      step: "0.001",
                      disabled: _vm.processing || !_vm.location
                    },
                    model: {
                      value: _vm.longitude,
                      callback: function($$v) {
                        _vm.longitude = _vm._n($$v)
                      },
                      expression: "longitude"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.inlineMessage.message
            ? _c(
                "v-row",
                { staticClass: "mx-2", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        { attrs: { type: _vm.inlineMessage.type } },
                        [
                          _c(
                            "span",
                            { staticStyle: { "white-space": "pre-line" } },
                            [_vm._v(_vm._s(_vm.inlineMessage.message))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass:
                "mx-0 mb-3 d-flex d-md-none justify-end align-center v-card__actions"
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "resetlocation-small",
                    outlined: "",
                    disabled: !_vm.originalLocation || _vm.processing,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.resetLocation }
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "pr-1 mt-1", attrs: { small: "" } },
                    [_vm._v("fa-location-crosshairs")]
                  ),
                  _vm._v("Reset to Original\n      ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "currentlocation-small",
                    outlined: "",
                    color: "green",
                    disabled: _vm.processing,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.setLocationToCurrent }
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "pr-1 mt-1", attrs: { small: "" } },
                    [_vm._v("fa-location-arrow")]
                  ),
                  _vm._v("My Location\n      ")
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "d-none d-md-flex",
                  attrs: {
                    "data-cy": "resetlocation",
                    outlined: "",
                    disabled: !_vm.originalLocation || _vm.processing
                  },
                  on: { click: _vm.resetLocation }
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "pr-1 mt-1", attrs: { small: "" } },
                    [_vm._v("fa-location-crosshairs")]
                  ),
                  _vm._v("Reset to Original\n      ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "d-none d-md-flex",
                  attrs: {
                    "data-cy": "currentlocation",
                    outlined: "",
                    color: "green",
                    disabled: _vm.processing
                  },
                  on: { click: _vm.setLocationToCurrent }
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "pr-1 mt-1", attrs: { small: "" } },
                    [_vm._v("fa-location-arrow")]
                  ),
                  _vm._v("My Location\n      ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "ml-4 mr-3 d-none d-md-flex fd-button-context-separator-hide-when-mobile"
                },
                [_vm._v("\n        |\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: _vm.$vuetify.breakpoint.xsOnly },
                  on: { click: _vm.cancelDialog }
                },
                [_vm._v(_vm._s(_vm.$t("common.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: _vm.processing,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.acceptDialog }
                },
                [_vm._v(_vm._s(_vm.$t("common.accept")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }