var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("fd-image-viewer", {
        attrs: { name: _vm.imageName },
        model: {
          value: _vm.imageSource,
          callback: function($$v) {
            _vm.imageSource = $$v
          },
          expression: "imageSource"
        }
      }),
      _vm._v(" "),
      _c("fd-image-editor", {
        attrs: { name: _vm.imageName },
        on: { edit: _vm.handleEdit },
        model: {
          value: _vm.editImageSource,
          callback: function($$v) {
            _vm.editImageSource = $$v
          },
          expression: "editImageSource"
        }
      }),
      _vm._v(" "),
      _c(
        "v-form",
        { ref: "form", attrs: { "data-cy": "scaffolddesignexisting" } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: item =>
                                        _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || `/scaffolddesigns`
                                        )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          staticClass: "pt-3",
                          attrs: { "icons-and-text": "", "show-arrows": "" },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              key: _vm.detailsTab.key,
                              ref: "tab",
                              attrs: { href: `#tab-${_vm.detailsTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.detailsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.detailsTab.tabname) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-edit")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.photosTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.photosTab.key,
                                  attrs: { href: `#tab-${_vm.photosTab.key}` },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.photosTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.photosTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-camera")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.attachmentsTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.attachmentsTab.key,
                                  attrs: {
                                    href: `#tab-${_vm.attachmentsTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.attachmentsTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.attachmentsTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-paperclip")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.scaffoldDesign.currentUserPermissions
                            .canViewEstimateDetails &&
                          (_vm.estimatesTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.estimatesTab.key,
                                  attrs: {
                                    "data-cy": "estimates",
                                    href: `#tab-${_vm.estimatesTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.estimatesTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.estimatesTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-pen-ruler")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.notesTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.notesTab.key,
                                  attrs: { href: `#tab-${_vm.notesTab.key}` },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.notesTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.notesTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-clipboard")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.designToolTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.designToolTab.key,
                                  attrs: {
                                    href: `#tab-${_vm.designToolTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.designToolTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.designToolTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [
                                    _vm._v("fas fa-compass-drafting")
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: { bottom: "", left: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "align-self-center mr-4 d-flex d-sm-none more-tab-button",
                                              staticStyle: {
                                                position: "relative"
                                              },
                                              attrs: { text: "" }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("common.more")) +
                                              "\n\n                  "
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c("v-icon", [
                                                _vm._v("fas fa-ellipsis-h")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                { staticClass: "grey lighten-3" },
                                _vm._l(_vm.hiddenTabDefinitions, function(
                                  item
                                ) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.tabname,
                                      on: {
                                        click: function($event) {
                                          return _vm.showNewTabFromMoreMenu(
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.tabname) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.detailsTab.key,
                              attrs: { value: `tab-${_vm.detailsTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "detailsform",
                                  staticClass: "scaffold-design-form",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "mx-0 px-2 fd-inline-scaffold-design-keywords-qualifier",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c("fd-chip-selector", {
                                                attrs: {
                                                  "available-items":
                                                    _vm.availableKeywords,
                                                  "selected-items":
                                                    _vm.selectedKeywords,
                                                  "item-label": "name",
                                                  disabled:
                                                    _vm.processing ||
                                                    !_vm.scaffoldDesignIsEditableAndUserHasPermissions,
                                                  "control-label": _vm.$t(
                                                    "common.add-tags"
                                                  ),
                                                  "chip-class":
                                                    "keywords-selection-chip-colors",
                                                  outlined: false
                                                },
                                                on: {
                                                  "update:selectedItems": function(
                                                    $event
                                                  ) {
                                                    _vm.selectedKeywords = $event
                                                  },
                                                  "update:selected-items": function(
                                                    $event
                                                  ) {
                                                    _vm.selectedKeywords = $event
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mx-0 pt-3",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          !(
                                            _vm.scaffoldDesignIsOnHold ||
                                            _vm.scaffoldDesignIsCancelled
                                          )
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "justify-space-between pt-8 pb-0",
                                                  attrs: {
                                                    align: "center",
                                                    cols: "12",
                                                    sm: "6"
                                                  }
                                                },
                                                [
                                                  _vm.scaffoldDesignIsEditableAndUserHasPermissions
                                                    ? _c("v-select", {
                                                        attrs: {
                                                          outlined: "",
                                                          label: _vm.$t(
                                                            "scaffold-designs.design-status-label"
                                                          ),
                                                          disabled:
                                                            !_vm.scaffoldDesignDetailsAreEditable ||
                                                            _vm.processing,
                                                          items:
                                                            _vm.selectableStatuses
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.scaffoldDesign
                                                              .scaffoldDesignStatusID,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.scaffoldDesign,
                                                              "scaffoldDesignStatusID",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scaffoldDesign.scaffoldDesignStatusID"
                                                        }
                                                      })
                                                    : _c("fd-text-field", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.processing,
                                                          readonly: true,
                                                          label: _vm.$t(
                                                            "scaffold-designs.design-status-label"
                                                          ),
                                                          value: _vm.$t(
                                                            `scaffold-designs.status.${_vm.scaffoldDesign.scaffoldDesignStatusID}`
                                                          )
                                                        }
                                                      })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !(
                                            _vm.scaffoldDesignIsOnHold ||
                                            _vm.scaffoldDesignIsCancelled
                                          )
                                            ? _c("v-col", {
                                                staticClass:
                                                  "justify-space-between pt-5 pb-1",
                                                attrs: {
                                                  align: "center",
                                                  cols: "0",
                                                  sm: "6"
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.scaffoldDesignIsOnHold ||
                                          _vm.scaffoldDesignIsCancelled
                                            ? _c(
                                                "v-col",
                                                {
                                                  class: [
                                                    {
                                                      "fd-alert-container-warning":
                                                        _vm.scaffoldDesignIsOnHold
                                                    },
                                                    {
                                                      "fd-alert-container-error":
                                                        _vm.scaffoldDesignIsCancelled
                                                    },
                                                    "fd-alert-container",
                                                    "justify-space-between",
                                                    "mt-4",
                                                    "mb-5",
                                                    "pt-8",
                                                    "pb-1",
                                                    "px-0"
                                                  ],
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pa-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "6"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticClass:
                                                            "px-0 mx-0 align-start"
                                                        },
                                                        [
                                                          _vm.scaffoldDesignIsOnHold
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "fd-alert-container-icon"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fa-exclamation-circle"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.scaffoldDesignIsCancelled
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "fd-alert-container-icon"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fa-times-octagon"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.scaffoldDesignIsEditableAndUserHasPermissions
                                                            ? _c("v-select", {
                                                                staticClass:
                                                                  "px-3",
                                                                staticStyle: {
                                                                  width:
                                                                    "min-content"
                                                                },
                                                                attrs: {
                                                                  outlined: "",
                                                                  label: _vm.$t(
                                                                    "scaffold-designs.design-status-label"
                                                                  ),
                                                                  disabled:
                                                                    !_vm.scaffoldDesignDetailsAreEditable ||
                                                                    _vm.processing,
                                                                  items:
                                                                    _vm.selectableStatuses
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .scaffoldDesign
                                                                      .scaffoldDesignStatusID,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.scaffoldDesign,
                                                                      "scaffoldDesignStatusID",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "scaffoldDesign.scaffoldDesignStatusID"
                                                                }
                                                              })
                                                            : _c(
                                                                "fd-text-field",
                                                                {
                                                                  staticClass:
                                                                    "px-3",
                                                                  staticStyle: {
                                                                    width:
                                                                      "min-content"
                                                                  },
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.processing,
                                                                    readonly: true,
                                                                    label: _vm.$t(
                                                                      "scaffold-designs.design-status-label"
                                                                    ),
                                                                    value: _vm.$t(
                                                                      `scaffold-designs.status.${_vm.scaffoldDesign.scaffoldDesignStatusID}`
                                                                    )
                                                                  }
                                                                }
                                                              )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pa-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _c("fd-textarea", {
                                                        staticClass: "px-3",
                                                        staticStyle: {
                                                          "margin-left": "54px"
                                                        },
                                                        attrs: {
                                                          outlined: "",
                                                          label: _vm.$t(
                                                            "scaffold-designs.design-status-reason-label"
                                                          ),
                                                          disabled:
                                                            !_vm.scaffoldDesignIsEditableAndUserHasPermissions ||
                                                            _vm.processing,
                                                          readonly: !_vm.scaffoldDesignIsEditableAndUserHasPermissions,
                                                          rows: "1",
                                                          "auto-grow": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.scaffoldDesign
                                                              .scaffoldDesignStatusDetail,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.scaffoldDesign,
                                                              "scaffoldDesignStatusDetail",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scaffoldDesign.scaffoldDesignStatusDetail"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-1 pb-1",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "3"
                                              }
                                            },
                                            [
                                              _vm.scaffoldDesignIsEditableAndUserHasPermissions
                                                ? _c("v-select", {
                                                    attrs: {
                                                      outlined: "",
                                                      label: _vm.$t(
                                                        "scaffold-designs.priority-label"
                                                      ),
                                                      disabled:
                                                        !_vm.scaffoldDesignDetailsAreEditable ||
                                                        _vm.processing,
                                                      items: _vm.priorityValues,
                                                      "item-text": "name",
                                                      "item-value": "id"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.scaffoldDesign
                                                          .priority,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.scaffoldDesign,
                                                          "priority",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scaffoldDesign.priority"
                                                    }
                                                  })
                                                : _c("fd-text-field", {
                                                    attrs: {
                                                      disabled: _vm.processing,
                                                      readonly: true,
                                                      label: _vm.$t(
                                                        "scaffold-designs.priority-label"
                                                      ),
                                                      value:
                                                        _vm.scaffoldDesign
                                                          .priority
                                                    }
                                                  })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-1 pb-1",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "3"
                                              }
                                            },
                                            [
                                              _vm.scaffoldDesignIsEditableAndUserHasPermissions
                                                ? _c("v-select", {
                                                    attrs: {
                                                      outlined: "",
                                                      label: _vm.$t(
                                                        "scaffold-designs.progress-label"
                                                      ),
                                                      disabled: _vm.processing,
                                                      items: _vm.progressValues,
                                                      "item-text": "name",
                                                      "item-value": "id"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.scaffoldDesign
                                                          .progress,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.scaffoldDesign,
                                                          "progress",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scaffoldDesign.progress"
                                                    }
                                                  })
                                                : _c("fd-text-field", {
                                                    attrs: {
                                                      disabled: _vm.processing,
                                                      readonly: true,
                                                      label: _vm.$t(
                                                        "scaffold-designs.progress-label"
                                                      ),
                                                      value:
                                                        _vm.scaffoldDesign
                                                          .progress
                                                    }
                                                  })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-1 pb-1",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "6"
                                              }
                                            },
                                            [
                                              _vm.scaffoldDesignIsEditableAndUserHasPermissions
                                                ? _c("fd-date-picker", {
                                                    attrs: {
                                                      disabled: _vm.processing,
                                                      label: _vm.$t(
                                                        "scaffold-designs.required-date-label"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.scaffoldDesign
                                                          .requiredDate,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.scaffoldDesign,
                                                          "requiredDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scaffoldDesign.requiredDate"
                                                    }
                                                  })
                                                : _c("fd-text-field", {
                                                    attrs: {
                                                      disabled: _vm.processing,
                                                      readonly: true,
                                                      label: _vm.$t(
                                                        "scaffold-designs.required-date-label"
                                                      ),
                                                      value:
                                                        _vm.scaffoldDesign
                                                          .formattedRequiredDate
                                                    }
                                                  })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-1 pb-1",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "6"
                                              }
                                            },
                                            [
                                              _vm.scaffoldDesignIsEditableAndUserHasPermissions
                                                ? _c("v-autocomplete", {
                                                    attrs: {
                                                      outlined: "",
                                                      label: _vm.$t(
                                                        "scaffold-designs.assigned-label"
                                                      ),
                                                      disabled: _vm.processing,
                                                      items: _vm.allDesigners,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.scaffoldDesign
                                                          .assignedPersonID,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.scaffoldDesign,
                                                          "assignedPersonID",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scaffoldDesign.assignedPersonID"
                                                    }
                                                  })
                                                : _c("fd-text-field", {
                                                    attrs: {
                                                      disabled: _vm.processing,
                                                      readonly: true,
                                                      label: _vm.$t(
                                                        "scaffold-designs.assigned-label"
                                                      ),
                                                      value: _vm.$lookup.person(
                                                        _vm.scaffoldDesign
                                                          .assignedPersonID
                                                      )
                                                    }
                                                  })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-1 pb-1",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "6"
                                              }
                                            },
                                            [
                                              _vm.scaffoldDesignIsEditableAndUserHasPermissions
                                                ? _c("v-autocomplete", {
                                                    attrs: {
                                                      outlined: "",
                                                      label: _vm.$t(
                                                        "scaffold-designs.reviewer-label"
                                                      ),
                                                      disabled: _vm.processing,
                                                      items: _vm.allReviewers,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.scaffoldDesign
                                                          .reviewerID,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.scaffoldDesign,
                                                          "reviewerID",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scaffoldDesign.reviewerID"
                                                    }
                                                  })
                                                : _c("fd-text-field", {
                                                    attrs: {
                                                      disabled: _vm.processing,
                                                      readonly: true,
                                                      label: _vm.$t(
                                                        "scaffold-designs.reviewer-label"
                                                      ),
                                                      value: _vm.$lookup.person(
                                                        _vm.scaffoldDesign
                                                          .reviewerID
                                                      )
                                                    }
                                                  })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-1 pb-1",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "6"
                                              }
                                            },
                                            [
                                              _vm.scaffoldDesignIsEditableAndUserHasPermissions
                                                ? _c("v-autocomplete", {
                                                    attrs: {
                                                      outlined: "",
                                                      label: _vm.$t(
                                                        "scaffold-designs.manager-label"
                                                      ),
                                                      disabled: _vm.processing,
                                                      items: _vm.allManagers,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.scaffoldDesign
                                                          .managerID,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.scaffoldDesign,
                                                          "managerID",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scaffoldDesign.managerID"
                                                    }
                                                  })
                                                : _c("fd-text-field", {
                                                    attrs: {
                                                      disabled: _vm.processing,
                                                      readonly: true,
                                                      label: _vm.$t(
                                                        "scaffold-designs.manager-label"
                                                      ),
                                                      value: _vm.$lookup.person(
                                                        _vm.scaffoldDesign
                                                          .managerID
                                                      )
                                                    }
                                                  })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-1 pb-1",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "6"
                                              }
                                            },
                                            [
                                              _c("fd-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "scaffold-designs.navisworks-tag-label"
                                                  ),
                                                  readonly: !_vm.scaffoldDesignIsEditableAndUserHasPermissions,
                                                  disabled: _vm.processing
                                                },
                                                model: {
                                                  value:
                                                    _vm.scaffoldDesign
                                                      .navisworksTag,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.scaffoldDesign,
                                                      "navisworksTag",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scaffoldDesign.navisworksTag"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "scaffold-designs.is-engineering-required-label"
                                                  ),
                                                  disabled: _vm.processing,
                                                  readonly: !_vm.scaffoldDesignIsEditableAndUserHasPermissions,
                                                  "persistent-hint": "",
                                                  hint: _vm.$t(
                                                    "scaffold-designs.is-engineering-required-hint"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.scaffoldDesign
                                                      .isEngineeringRequired,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.scaffoldDesign,
                                                      "isEngineeringRequired",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scaffoldDesign.isEngineeringRequired"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0 pb-5 mt-5",
                                              attrs: {
                                                align: "center",
                                                cols: "12"
                                              }
                                            },
                                            [
                                              _c("v-subheader", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scaffold-designs.location-sub-header"
                                                    )
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pb-0",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "6"
                                              }
                                            },
                                            [
                                              _vm.scaffoldDesignIsEditableAndUserHasPermissions
                                                ? _c("v-select", {
                                                    attrs: {
                                                      outlined: "",
                                                      label: _vm.$t(
                                                        "scaffold-designs.area-label"
                                                      ),
                                                      disabled: _vm.processing,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      items: _vm.allAreas,
                                                      rules:
                                                        _vm.scaffoldDesignRules
                                                          .areaID,
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.scaffoldDesign
                                                          .areaID,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.scaffoldDesign,
                                                          "areaID",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scaffoldDesign.areaID"
                                                    }
                                                  })
                                                : _c("fd-text-field", {
                                                    attrs: {
                                                      disabled: _vm.processing,
                                                      readonly: true,
                                                      label: _vm.$t(
                                                        "scaffold-designs.area-label"
                                                      ),
                                                      value: _vm.$lookup.location(
                                                        _vm.scaffoldDesign
                                                          .areaID
                                                      )
                                                    }
                                                  })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pb-0",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "6"
                                              }
                                            },
                                            [
                                              _vm.scaffoldDesignIsEditableAndUserHasPermissions
                                                ? _c("v-select", {
                                                    attrs: {
                                                      outlined: "",
                                                      label: _vm.$t(
                                                        "scaffold-designs.sub-area-label"
                                                      ),
                                                      disabled:
                                                        !_vm.scaffoldDesign
                                                          .areaID ||
                                                        _vm.processing,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      items: _vm.subAreas,
                                                      rules:
                                                        _vm.scaffoldDesignRules
                                                          .subAreaID,
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.scaffoldDesign
                                                          .subAreaID,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.scaffoldDesign,
                                                          "subAreaID",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scaffoldDesign.subAreaID"
                                                    }
                                                  })
                                                : _c("fd-text-field", {
                                                    attrs: {
                                                      disabled: _vm.processing,
                                                      readonly: true,
                                                      label: _vm.$t(
                                                        "scaffold-designs.sub-area-label"
                                                      ),
                                                      value: _vm.$lookup.location(
                                                        _vm.scaffoldDesign
                                                          .subAreaID
                                                      )
                                                    }
                                                  })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-1 pb-0",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "12"
                                              }
                                            },
                                            [
                                              _c("fd-textarea", {
                                                attrs: {
                                                  cy: "location",
                                                  outlined: "",
                                                  label: _vm.$t(
                                                    "scaffold-designs.specific-work-location-label"
                                                  ),
                                                  disabled: _vm.processing,
                                                  readonly: !_vm.scaffoldDesignIsEditableAndUserHasPermissions,
                                                  rules:
                                                    _vm.scaffoldDesignRules
                                                      .specificWorkLocation,
                                                  rows: "1",
                                                  "auto-grow": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.scaffoldDesign
                                                      .specificWorkLocation,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.scaffoldDesign,
                                                      "specificWorkLocation",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scaffoldDesign.specificWorkLocation"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-1 pb-0",
                                              attrs: {
                                                align: "center",
                                                cols: "12"
                                              }
                                            },
                                            [
                                              _c("fd-textarea", {
                                                attrs: {
                                                  outlined: "",
                                                  label: _vm.$t(
                                                    "scaffold-designs.detailed-work-description-label"
                                                  ),
                                                  disabled: _vm.processing,
                                                  readonly: !_vm.scaffoldDesignIsEditableAndUserHasPermissions,
                                                  rules:
                                                    _vm.scaffoldDesignRules
                                                      .detailedWorkDescription,
                                                  rows: "1",
                                                  "auto-grow": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.scaffoldDesign
                                                      .detailedWorkDescription,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.scaffoldDesign,
                                                      "detailedWorkDescription",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scaffoldDesign.detailedWorkDescription"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0 pb-5 mt-5",
                                              attrs: {
                                                align: "center",
                                                cols: "12"
                                              }
                                            },
                                            [
                                              _c("v-subheader", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scaffold-designs.coordinates-label"
                                                    )
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "4" } },
                                            [
                                              _c("fd-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "scaffold-designs.coordinates-x-label"
                                                  ),
                                                  readonly: !_vm.scaffoldDesignIsEditableAndUserHasPermissions,
                                                  disabled: _vm.processing,
                                                  numeric: "",
                                                  type: "number",
                                                  "hide-spin-buttons": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.scaffoldDesign.coordX,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.scaffoldDesign,
                                                      "coordX",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scaffoldDesign.coordX"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "4" } },
                                            [
                                              _c("fd-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "scaffold-designs.coordinates-y-label"
                                                  ),
                                                  readonly: !_vm.scaffoldDesignIsEditableAndUserHasPermissions,
                                                  disabled: _vm.processing,
                                                  numeric: "",
                                                  type: "number",
                                                  "hide-spin-buttons": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.scaffoldDesign.coordY,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.scaffoldDesign,
                                                      "coordY",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scaffoldDesign.coordY"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "4" } },
                                            [
                                              _c("fd-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "scaffold-designs.coordinates-z-label"
                                                  ),
                                                  readonly: !_vm.scaffoldDesignIsEditableAndUserHasPermissions,
                                                  disabled: _vm.processing,
                                                  numeric: "",
                                                  type: "number",
                                                  "hide-spin-buttons": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.scaffoldDesign.coordZ,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.scaffoldDesign,
                                                      "coordZ",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scaffoldDesign.coordZ"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.photosTab.key,
                              attrs: { value: `tab-${_vm.photosTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "filesform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            [
                                              _c("fd-add-file-button", {
                                                attrs: {
                                                  disabled:
                                                    _vm.processing ||
                                                    !_vm.scaffoldDesignIsEditableAndUserHasPermissions,
                                                  depressed:
                                                    _vm.processing ||
                                                    !_vm.scaffoldDesignIsEditableAndUserHasPermissions
                                                },
                                                on: {
                                                  change: _vm.selectNewFile
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("v-divider", {
                                                staticClass: "mx-4",
                                                attrs: {
                                                  inset: "",
                                                  vertical: ""
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function({ on }) {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-1 mr-3",
                                                                attrs: {
                                                                  color:
                                                                    "black",
                                                                  dark: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v("info")]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "scaffold-designs.photos-table-listing-tooltip"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c("v-text-field", {
                                                staticClass: "pl-0",
                                                attrs: {
                                                  "append-icon": "search",
                                                  label: _vm.$t(
                                                    "common.search"
                                                  ),
                                                  "single-line": "",
                                                  "hide-details": "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value: _vm.tablesearchfiles,
                                                  callback: function($$v) {
                                                    _vm.tablesearchfiles = $$v
                                                  },
                                                  expression: "tablesearchfiles"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("fd-alert", {
                                            staticClass: "mx-3",
                                            attrs: {
                                              type: "info",
                                              label: _vm.$t(
                                                "attachments.file-not-photo-alert-message",
                                                [
                                                  _vm.touchedFileName.toUpperCase()
                                                ]
                                              ),
                                              dismissible: ""
                                            },
                                            model: {
                                              value:
                                                _vm.showPhotoTabAttachmentAlert,
                                              callback: function($$v) {
                                                _vm.showPhotoTabAttachmentAlert = $$v
                                              },
                                              expression:
                                                "showPhotoTabAttachmentAlert"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName: "v-fd-column:name",
                                                value: _vm.$t("common.name"),
                                                expression: "$t('common.name')",
                                                arg: "name"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                                arg: "actions",
                                                modifiers: {
                                                  "no-sort": true,
                                                  "hide-when-extra-small": true,
                                                  "class_fd-actions-cell": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:action.no-sort.show-when-extra-small.class_fd-actions-cell-edit-only",
                                                arg: "action",
                                                modifiers: {
                                                  "no-sort": true,
                                                  "show-when-extra-small": true,
                                                  "class_fd-actions-cell-edit-only": true
                                                }
                                              }
                                            ],
                                            class: _vm.$vuetify.breakpoint
                                              .xsOnly
                                              ? "fd-action-table"
                                              : "fd-actions-table",
                                            attrs: {
                                              items: _vm.photoFiles,
                                              search: _vm.tablesearchfiles,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": ["name"],
                                              "item-class": item =>
                                                item.name == _vm.touchedFileName
                                                  ? "fd-selected-table-row-background"
                                                  : "",
                                              "mobile-breakpoint": "0"
                                            },
                                            on: {
                                              "dblclick:row": (e, data) =>
                                                _vm.viewFile(data.item)
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "item.action",
                                                fn: function({ item }) {
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          top: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteFile(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.delete"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.actions",
                                                fn: function({ item }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "table-4-actions-column-min-width"
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              left: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.openFileInNewWindow(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-open-in-new\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.open-in-new-tab"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              left: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                !item.isPreviewable ||
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.viewFile(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-eye\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.view"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              top: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                !item.isPreviewable ||
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.editFile(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-pencil\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.edit"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              top: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.deleteFile(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-delete\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.delete"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.attachmentsTab.key,
                              attrs: { value: `tab-${_vm.attachmentsTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "attachmentsform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mx-2",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                [
                                                  _c("input", {
                                                    ref: "addFileButton",
                                                    staticClass: "d-none",
                                                    attrs: {
                                                      type: "file",
                                                      onclick:
                                                        "this.value=null;"
                                                    },
                                                    on: {
                                                      change: v =>
                                                        _vm.selectNewFile(
                                                          v.target.files[0]
                                                        )
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: { "offset-y": "" },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "add-fab-button-primary",
                                                                    attrs: {
                                                                      fab: "",
                                                                      elevation:
                                                                        "5",
                                                                      depressed:
                                                                        _vm.processing ||
                                                                        !_vm.scaffoldDesignIsEditableAndUserHasPermissions,
                                                                      disabled:
                                                                        _vm.processing ||
                                                                        !_vm.scaffoldDesignIsEditableAndUserHasPermissions,
                                                                      color:
                                                                        "new"
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-plus"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-list",
                                                        [
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  _vm.openNewExternalLinkDialog
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "attachments.external-link.menu-title"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  _vm.selectFile
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.local-file"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-divider", {
                                                    staticClass: "mx-4",
                                                    attrs: {
                                                      inset: "",
                                                      vertical: ""
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "ml-1",
                                                                    attrs: {
                                                                      color:
                                                                        "black",
                                                                      dark: ""
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [_vm._v("info")]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "scaffold-designs.files-table-listing-tooltip"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-spacer"),
                                                  _vm._v(" "),
                                                  _c("v-text-field", {
                                                    staticClass: "pl-0",
                                                    attrs: {
                                                      "append-icon": "search",
                                                      label: _vm.$t(
                                                        "common.search"
                                                      ),
                                                      "single-line": "",
                                                      "hide-details": "",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.tablesearchfiles,
                                                      callback: function($$v) {
                                                        _vm.tablesearchfiles = $$v
                                                      },
                                                      expression:
                                                        "tablesearchfiles"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("fd-alert", {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  type: "info",
                                                  label: _vm.$t(
                                                    "attachments.file-is-photo-alert-message",
                                                    [
                                                      _vm.touchedFileName.toUpperCase()
                                                    ]
                                                  ),
                                                  dismissible: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.showAttachmentTabPhotoAlert,
                                                  callback: function($$v) {
                                                    _vm.showAttachmentTabPhotoAlert = $$v
                                                  },
                                                  expression:
                                                    "showAttachmentTabPhotoAlert"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("v-data-table", {
                                                directives: [
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:icon.no-sort.hide-when-extra-small.class_fd-table-icon-cell",
                                                    value: "",
                                                    expression:
                                                      "\n                          ''\n                        ",
                                                    arg: "icon",
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "hide-when-extra-small": true,
                                                      "class_fd-table-icon-cell": true
                                                    }
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName: "v-fd-column:name",
                                                    value: _vm.$t(
                                                      "common.name"
                                                    ),
                                                    expression:
                                                      "$t('common.name')",
                                                    arg: "name"
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                                    arg: "actions",
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "hide-when-extra-small": true,
                                                      "class_fd-actions-cell": true
                                                    }
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:action.no-sort.show-when-extra-small.class_fd-actions-cell-edit-only",
                                                    arg: "action",
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "show-when-extra-small": true,
                                                      "class_fd-actions-cell-edit-only": true
                                                    }
                                                  }
                                                ],
                                                class: _vm.$vuetify.breakpoint
                                                  .xsOnly
                                                  ? "fd-action-table"
                                                  : "fd-actions-table",
                                                attrs: {
                                                  items:
                                                    _vm.nonPhotoAttachments,
                                                  search: _vm.tablesearchfiles,
                                                  loading: _vm.processing,
                                                  "loading-text": _vm.$t(
                                                    "common.table-loading-message"
                                                  ),
                                                  "sort-by": ["name"],
                                                  "item-class": item =>
                                                    item.name ==
                                                    _vm.touchedFileName
                                                      ? "fd-selected-table-row-background"
                                                      : "",
                                                  "mobile-breakpoint": "0"
                                                },
                                                on: {
                                                  "dblclick:row": (e, data) =>
                                                    _vm.openAttachment(
                                                      data.item
                                                    )
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "item.icon",
                                                    fn: function({ item }) {
                                                      return [
                                                        item.type == "link"
                                                          ? _c("v-icon", [
                                                              _vm._v("fa-link")
                                                            ])
                                                          : item.type ==
                                                              "file" &&
                                                            item.isPhoto
                                                          ? _c("v-icon", [
                                                              _vm._v(
                                                                "fa-camera"
                                                              )
                                                            ])
                                                          : _c("v-icon", [
                                                              _vm._v("fa-file")
                                                            ])
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item.action",
                                                    fn: function({ item }) {
                                                      return [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              top: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.deleteAttachment(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-delete\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.delete"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item.actions",
                                                    fn: function({ item }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "table-4-actions-column-min-width"
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  left: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    !item.canOpenInNew ||
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.openAttachment(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-open-in-new\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.open-in-new-tab"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  left: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    !item.isPreviewable ||
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.viewFile(
                                                                                        item.file
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-eye\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.view"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  link:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editAttachment(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-pencil\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.edit"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deleteAttachment(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-delete\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.delete"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.estimatesTab.key,
                              attrs: { value: `tab-${_vm.estimatesTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "estimatesform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _vm.scaffoldDesign.currentUserPermissions
                                    .canEditEstimateDetails
                                    ? _c(
                                        "v-card-title",
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: { "offset-y": "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function({ on }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "add-fab-button-primary",
                                                              attrs: {
                                                                fab: "",
                                                                elevation: "5",
                                                                depressed:
                                                                  _vm.processing,
                                                                disabled:
                                                                  _vm.processing,
                                                                color: "new"
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("mdi-plus")
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3800927588
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c(
                                                "v-list",
                                                { attrs: { subheader: "" } },
                                                [
                                                  !_vm.scaffoldTypes.length
                                                    ? _c(
                                                        "v-list-item-group",
                                                        [
                                                          _c(
                                                            "v-subheader",
                                                            {
                                                              staticClass:
                                                                "pt-3 pb-0",
                                                              staticStyle: {
                                                                height:
                                                                  "auto !important"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "scheduler.estimates.no-scaffold-types-configured-message"
                                                                    )
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.scaffoldTypes,
                                                    function(scaffoldType) {
                                                      return _c(
                                                        "v-list-item-group",
                                                        {
                                                          key:
                                                            scaffoldType.value
                                                        },
                                                        [
                                                          _c(
                                                            "v-subheader",
                                                            {
                                                              staticClass:
                                                                "pt-3 pb-0",
                                                              staticStyle: {
                                                                height:
                                                                  "auto !important"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    scaffoldType.text
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("v-divider", {
                                                            staticClass: "my-1"
                                                          }),
                                                          _vm._v(" "),
                                                          _vm._l(
                                                            scaffoldType.subTypes,
                                                            function(subType) {
                                                              return _c(
                                                                "v-list-item",
                                                                {
                                                                  key:
                                                                    subType.value,
                                                                  staticClass:
                                                                    "py-1",
                                                                  staticStyle: {
                                                                    "min-height":
                                                                      "0px !important"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openNewDialog(
                                                                        scaffoldType.value,
                                                                        subType.value
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      subType.text
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function({ on }) {
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "ml-1 mr-3",
                                                              attrs: {
                                                                color: "black",
                                                                dark: ""
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("info")]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3947564490
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scheduler.estimates.table-listing-tooltip"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer")
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panels",
                                    {
                                      attrs: { multiple: "", accordion: "" },
                                      model: {
                                        value: _vm.estimatePanel,
                                        callback: function($$v) {
                                          _vm.estimatePanel = $$v
                                        },
                                        expression: "estimatePanel"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel",
                                        {
                                          staticClass: "secondary-section-style"
                                        },
                                        [
                                          _c("v-expansion-panel-header", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduler.estimates.summary-panel.title"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-expansion-panel-content",
                                            {
                                              staticClass:
                                                "no-left-right-bottom-padding",
                                              staticStyle: {
                                                background: "white"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "justify-end ml-0 mr-0 fd-secondary-context-qualifier-background"
                                                },
                                                [
                                                  _c(
                                                    "v-radio-group",
                                                    {
                                                      attrs: { row: "" },
                                                      model: {
                                                        value:
                                                          _vm.summaryPanelTimeUnitDivider,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.summaryPanelTimeUnitDivider = $$v
                                                        },
                                                        expression:
                                                          "summaryPanelTimeUnitDivider"
                                                      }
                                                    },
                                                    [
                                                      _c("v-radio", {
                                                        attrs: {
                                                          value: 1,
                                                          label: _vm.$t(
                                                            "common.minutes-short"
                                                          )
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("v-radio", {
                                                        attrs: {
                                                          value: 60,
                                                          label: _vm.$t(
                                                            "common.hours"
                                                          )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-container",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { fluid: "" }
                                                },
                                                [
                                                  _c(
                                                    "sp-estimate-summary-table",
                                                    {
                                                      attrs: {
                                                        "estimate-components":
                                                          _vm.workingComponents,
                                                        summaryPanelTimeUnitDivider:
                                                          _vm.summaryPanelTimeUnitDivider
                                                      },
                                                      on: {
                                                        edit: _vm.editItem,
                                                        delete: _vm.deleteItem,
                                                        undelete: _vm.reAddItem
                                                      }
                                                    }
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-card-actions",
                                                    [
                                                      _c("v-spacer"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            outlined: "",
                                                            disabled:
                                                              _vm.processing ||
                                                              !_vm
                                                                .workingComponents
                                                                .length
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.openWorkingTakeoffDialog
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "scheduler.estimates.components-panel.takeoff"
                                                                )
                                                              ) +
                                                              "\n                          "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            disabled:
                                                              _vm.processing ||
                                                              !_vm.canGenerateSnapshot,
                                                            loading:
                                                              _vm.generatingSnapshot
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.generateSnapshot
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "scheduler.estimates.components-panel.generate-snapshot-button-label"
                                                                )
                                                              ) +
                                                              "\n                          "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-expansion-panel",
                                        {
                                          staticClass: "secondary-section-style"
                                        },
                                        [
                                          _c("v-expansion-panel-header", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduler.estimates.estimates-panel.title"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-expansion-panel-content",
                                            {
                                              staticClass:
                                                "no-left-right-bottom-padding",
                                              staticStyle: {
                                                background: "white"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "ml-1 mr-3",
                                                                        attrs: {
                                                                          color:
                                                                            "black",
                                                                          dark:
                                                                            ""
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "info"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ])
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "scheduler.estimates.table-listing-tooltip"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("v-spacer")
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "pl-0",
                                                        attrs: {
                                                          "append-icon":
                                                            "search",
                                                          label: _vm.$t(
                                                            "common.search"
                                                          ),
                                                          "single-line": "",
                                                          "hide-details": "",
                                                          clearable: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.tablesearchestimates,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.tablesearchestimates = $$v
                                                          },
                                                          expression:
                                                            "tablesearchestimates"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { attrs: { align: "center" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c("sp-estimates-list", {
                                                        attrs: {
                                                          estimates:
                                                            _vm.estimatesList,
                                                          loading:
                                                            _vm.processing,
                                                          tablesearchestimates:
                                                            _vm.tablesearchestimates,
                                                          summaryPanelTimeUnitDivider:
                                                            _vm.summaryPanelTimeUnitDivider
                                                        },
                                                        on: {
                                                          download:
                                                            _vm.downloadEstimateExcelFile
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.notesTab.key,
                              attrs: { value: `tab-${_vm.notesTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "notesform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "pa-0 ma-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 ma-0",
                                          attrs: { cols: "12", lg: "8" }
                                        },
                                        [
                                          _c(
                                            "v-timeline",
                                            { attrs: { dense: "" } },
                                            [
                                              _c("fd-new-note-timeline-item", {
                                                attrs: {
                                                  loading: _vm.saving,
                                                  disabled: _vm.processing
                                                },
                                                on: {
                                                  addNewNote: _vm.addNewNote
                                                },
                                                model: {
                                                  value: _vm.newNoteText,
                                                  callback: function($$v) {
                                                    _vm.newNoteText = $$v
                                                  },
                                                  expression: "newNoteText"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _vm._l(_vm.notes, function(
                                                item,
                                                i
                                              ) {
                                                return _c(
                                                  "fd-history-note-timeline-item",
                                                  {
                                                    key: i,
                                                    attrs: { note: item }
                                                  }
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-form",
                        { ref: "form", on: { submit: _vm.onSubmit } },
                        [
                          _vm.inlineMessage.message
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "mx-2",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _vm.inlineMessage.message
                                        ? _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                type: _vm.inlineMessage.type
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "white-space": "pre-line"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.inlineMessage.message
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            { staticClass: "mx-0 px-3 pb-3" },
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    text: "",
                                    link: "",
                                    disabled: _vm.processing,
                                    small: _vm.$vuetify.breakpoint.xsOnly
                                  },
                                  on: { click: _vm.cancel }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common.cancel")) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("fd-menu-save", {
                                attrs: {
                                  disabled:
                                    !_vm.scaffoldDesignIsEditableAndUserHasPermissions ||
                                    _vm.processing,
                                  loading: _vm.saving,
                                  small: _vm.$vuetify.breakpoint.xsOnly
                                },
                                on: {
                                  "click:save": function($event) {
                                    return _vm.save(false)
                                  },
                                  "click:save-and-close": function($event) {
                                    return _vm.save(true)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }