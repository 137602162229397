var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "pa-0",
      staticStyle: { background: "white" },
      attrs: { fluid: "" }
    },
    [
      _c("fd-detail-view-header", {
        attrs: {
          "data-cy": "set-up-type--header",
          info: _vm.$t(
            "build-sheet-setup.tabs.paint.set-up-types.listing-information"
          )
        },
        on: { "click:add": _vm.showAddNewDialog },
        model: {
          value: _vm.tablesearch,
          callback: function($$v) {
            _vm.tablesearch = $$v
          },
          expression: "tablesearch"
        }
      }),
      _vm._v(" "),
      _c("v-data-table", {
        directives: [
          { name: "fd-column", rawName: "v-fd-column:name", arg: "name" },
          {
            name: "fd-column",
            rawName: "v-fd-column:description",
            arg: "description"
          },
          {
            name: "fd-column",
            rawName:
              "v-fd-column:archived.class_fd-archiving-cell.hide-when-extra-small.hide-when-header-text-empty",
            value: _vm.showArchived ? _vm.$t("common.archived") : "",
            expression:
              "\n      showArchived ? $t('common.archived') : ''\n    ",
            arg: "archived",
            modifiers: {
              "class_fd-archiving-cell": true,
              "hide-when-extra-small": true,
              "hide-when-header-text-empty": true
            }
          },
          {
            name: "fd-column",
            rawName:
              "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
            arg: "actions",
            modifiers: {
              "no-sort": true,
              "hide-when-extra-small": true,
              "class_fd-actions-cell": true
            }
          },
          {
            name: "fd-row-navigate",
            rawName: "v-fd-row-navigate.show-when-extra-small",
            value: _vm.navigateToItem,
            expression: "navigateToItem",
            modifiers: { "show-when-extra-small": true }
          }
        ],
        class: [
          "fd-actions-table",
          {
            "fd-archive-bonus-padding-bottom": _vm.showArchived
          }
        ],
        attrs: {
          loading: _vm.processing,
          "loading-text": _vm.$t("common.table-loading-message"),
          "mobile-breakpoint": "0",
          search: _vm.tablesearch,
          items: _vm.setUpTypes,
          "sort-by": "inches"
        },
        scopedSlots: _vm._u([
          {
            key: "footer.prepend",
            fn: function() {
              return [
                _c("fd-archived-data-loader", {
                  staticClass: "ml-2 mr-3",
                  attrs: {
                    showArchived: _vm.showArchived,
                    showArchivedDateRange: _vm.showArchivedDateRange,
                    showArchivedMinDate: _vm.showArchivedMinDate,
                    showArchivedMaxDate: _vm.showArchivedMaxDate,
                    loading: _vm.archivedLoading,
                    disabled: _vm.processing,
                    "allow-all-data": ""
                  },
                  on: {
                    "update:showArchived": function($event) {
                      _vm.showArchived = $event
                    },
                    "update:show-archived": function($event) {
                      _vm.showArchived = $event
                    },
                    "update:showArchivedDateRange": function($event) {
                      _vm.showArchivedDateRange = $event
                    },
                    "update:show-archived-date-range": function($event) {
                      _vm.showArchivedDateRange = $event
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "item.fd-nav",
            fn: function() {
              return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
            },
            proxy: true
          },
          {
            key: "item.archived",
            fn: function({ item }) {
              return [
                _c("v-simple-checkbox", {
                  attrs: { value: item.archived, disabled: _vm.processing },
                  on: {
                    input: function($event) {
                      return _vm.flipArchived(item)
                    }
                  }
                })
              ]
            }
          },
          {
            key: "item.actions",
            fn: function({ item }) {
              return [
                _c(
                  "div",
                  { staticClass: "table-2-actions-column-min-width" },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          icon: "",
                                          link: "",
                                          disabled: _vm.processing,
                                          to: _vm.itemNavigationPath(item)
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(
                                          "\n                mdi-pencil\n              "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          icon: "",
                                          disabled: _vm.processing
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { small: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteTableItem(item)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                mdi-delete\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("common.remove")))])
                      ]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }