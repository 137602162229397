import { contractorNormService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: contractorNormService,
  singularStoreName: "CONTRACTOR_NORM",
  pluralStoreName: "CONTRACTOR_NORMS",
  localizationPrefix: "contractors.norms",
  // The following is the name of the ID property used by foreign objects.
  consumerRelatedIDProperty: {
    name: "contractorNormID",
    type: "string"
  }
});

export default storeModule;

