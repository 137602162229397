import FDVue from "../";

export default FDVue.extend({
  name: "fd-textarea",

  inheritAttrs: false,

  props: {
    value: {},
    cy: { type: String, default: undefined },
    dataCy: { type: String, default: "fd-textarea" },
    disabled: { type: Boolean, default: false }
  },

  data: function() {
    return {};
  },

  methods: {},

  computed: {},

  watch: {}
});
