import { mapMutations, mapActions, mapState } from "vuex";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import rules from "@fd/lib/vue/rules";
import { TestPackage, ISO, System } from "@fd/current/client/services";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import tabbedView, { PageTab, Tab } from "@fd/lib/vue/mixins/tabbedView";
import archivedDataList from "../dataMixins/archivedDataList";
import { showISONewWithTestPackageDialog } from "./components/dialogs/SP.ISONewDialog.vue";
import { SortItemsWithName } from "../utils/person";
import { stripHtml, truncateWithEllipsis } from "@fd/lib/vue/utility/helper";

type TestPackageWithArchived = TestPackage & { archived: boolean };
type ISOWithArchived = ISO & { archived: boolean };

export default FDVue.extend({
  name: "sp-test-package-existing",

  mixins: [errorHandling, tabbedView, rules, archivedDataList],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      slidein: false,
      navigatedFromSystem: false,
      canChangeSystem: false,

      firstTabKey: `1`,
      detailsTab: new PageTab({
        nameKey: "systems.test-packages.existing.tabs.details",
        key: `1`,
        visible: true
      }),
      isosTab: new PageTab({
        nameKey: "systems.test-packages.existing.tabs.isos",
        key: "2",
        visible: false
      }),

      testPackage: {
        systemID: "",
        name: "",
        code: "",
        description: "",
        archived: false
      } as TestPackageWithArchived
    };
  },

  computed: {
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.isosTab] as Tab[];
    },

    storeTestPackage(): TestPackage {
      return this.$store.state.testPackages.fullList.find(
        (x: TestPackage) => x.id == this.$route.params.id
      );
    },

    systems(): System[] {
      return SortItemsWithName(this.$store.state.systems.fullList as System[]);
    },

    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    // Get all the ISOs that are associated to the current test packages object.
    isos(): ISOWithArchived[] {
      return SortItemsWithName(
        this.$store.state.isos.fullList.map((x: ISO) => {
          return {
            ...x,
            description: truncateWithEllipsis(stripHtml(x.description)),
            archived: !!x.archivedDate
          };
        })
      );
    }
  },

  methods: {
    backButtonClicked() {
      this.cancel();
    },
    cancel() {
      this.close();
    },
    close() {
      if (this.navigatedFromSystem) this.$router.push(`/systems/${this.testPackage.systemID}`);
      else this.$router.push("/testpackages");
    },
    ...mapMutations({
      setTestPackage: "SET_TEST_PACKAGE",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadTestPackage: "LOAD_TEST_PACKAGE",
      updateTestPackage: "UPDATE_TEST_PACKAGE",
      deleteTestPackage: "DELETE_TEST_PACKAGE",
      loadISOsByTestPackageID: "LOAD_ISOS_BY_TEST_PACKAGE_ID",
      deleteISO: "DELETE_ISO",
      updateISO: "UPDATE_ISO",
      loadSystems: "LOAD_SYSTEMS"
    }),
    async loadData() {
      await this.loadISOsByTestPackageID({
        testPackageID: this.$route.params.id,
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    async openNewISODialog() {
      this.optOutOfErrorHandling();
      await showISONewWithTestPackageDialog(this.$route.params.id);
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.testPackage.archived) {
          this.testPackage.archivedDate = null;
        } else if (this.testPackage.archived && !this.testPackage.archivedDate) {
          this.testPackage.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateTestPackage({
          ...this.testPackage,
          id: this.$route.params.id
        });

        if (closeOnComplete) {
          this.close();
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteTestPackage({ id: this.$route.params.id, name: this.testPackage.name });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
        this.close();
      }
    },

    isoNavigationUrl(item: ISO) {
      if (this.navigatedFromSystem) return `/system-testpackage-isos/${item.id}`;
      else return `/testpackage-isos/${item.id}`;
    },
    navigateToISO(item: ISO) {
      this.$router.push(this.isoNavigationUrl(item));
    },

    async flipISOArchived(item: ISOWithArchived) {
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateISO({ id: item.id, archivedDate: archivedDate, name: item.name });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }
    },

    async deleteISOsTableItem(item: ISO) {
      this.processing = true;
      try {
        await this.deleteISO({ id: item.id, name: item.name });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },

  watch: {
    testPackage: async function(newValue) {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if (this.navigatedFromSystem) {
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/systems") {
          this.notifyNewBreadcrumb({
            text: this.$t("systems.list.title"),
            to: "/systems",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");

          var system = this.systems.find((x: any) => x.id == newValue.systemID) as System;

          this.notifyNewBreadcrumb({
            text: system?.name,
            to: `/systems/${newValue.systemID}`
          });

          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }

        this.notifyNewBreadcrumb({
          text: newValue.name,
          to: `/system-testpackages/${this.$route.params.id}`
        });
      } else {
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/testpackages") {
          this.notifyNewBreadcrumb({
            text: this.$t("test-packages.list.title"),
            to: "/testpackages",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }

        this.notifyNewBreadcrumb({
          text: newValue.name,
          to: `/testpackages/${this.$route.params.id}`
        });
      }
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    this.navigatedFromSystem = this.$route.name == "SystemTestPackageExisting";
    this.canChangeSystem = !this.navigatedFromSystem;

    var parentalContext = this.navigatedFromSystem ? "systems-existing" : "testpackages";
    this.setFilteringContext({
      context: "test-packages-existing",
      parentalContext: parentalContext,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      selectedTab: this.firstTabKey,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: new Date(0),
      showArchivedForFilteringToDate: new Date()
    });

    this.notifyNewBreadcrumb({
      text: this.$t("loading-dot-dot-dot"),
      disabled: true
    });

    this.processing = true;
    try {
      await this.loadSystems();
      await this.loadData();
      await this.loadTestPackage(this.$route.params.id);
      var testPackage = this.$store.state.testPackages.fullList.find(
        (x: TestPackage) => x.id == this.$route.params.id
      );
      this.testPackage = {
        ...testPackage,
        archived: !!testPackage.archivedDate
      } as TestPackageWithArchived;

      this.notifyNewBreadcrumb({
        text: this.testPackage.name,
        to: `/system-testpackages/${this.$route.params.id}`
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.processing = false;
    }
  }
});

