import FDVue from "@fd/lib/vue";
import {
  FDColumnDirective,
  FDHiddenArgumentName,
  FDRowNavigateDirective
} from "@fd/lib/vue/utility/dataTable";
import { mapActions, mapMutations } from "vuex";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import {
  Contractor,
  ContractorWithTags,
  ProjectCostCode,
  TimesheetEntry,
  timesheetService,
  TimesheetStatus,
  TimesheetWithDetails,
  EmployeeTimeSummary,
  workOrderService,
  WorkOrderWithAllDetails,
  WorkSubType,
  WorkType,
  personService,
  TimesheetType,
  Environment
} from "../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { TranslateResult } from "vue-i18n";
import { addTimesheetEntries } from "./components/dialogs/TimesheetEntriesAddDialog.vue";
import {
  UpdatableTimesheetEntryWithDetails,
  UpdatableTimesheetWithEntries
} from "../utils/timesheet";
import { VDataTable } from "@fd/lib/vue/types";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";
import rules from "@fd/lib/vue/rules";
import { showTimesheetStatusHistoryDialog } from "./components/dialogs/TimesheetStatusHistoryDialog.vue";
import { showItemSelectionDialog } from "./components/ItemSelectionDialog.vue";
import { GetPersonName, SortItemsWithName } from "../utils/person";
import { SelectListOption } from "@fd/lib/vue/utility/select";

type EntryGroupingType =
  | "groupnone"
  | "groupperson"
  | "groupworkorder"
  | "groupemployeeworkorder"
  | "groupcostcode";
function CompareWorkTypes<T extends { order: number | undefined; name: string | null | undefined }>(
  a: T,
  b: T
): number {
  let aOrder = a.order ?? 0;
  let bOrder = b.order ?? 0;
  if (aOrder != bOrder) return aOrder - bOrder;

  let aName = a.name?.toLocaleLowerCase() ?? "";
  let bName = b.name?.toLocaleLowerCase() ?? "";
  if (aName < bName) return -1;
  else if (aName > bName) return 1;
  return 0;
}
function SortWorkTypes<T extends { order: number | undefined; name: string | null | undefined }>(
  items: T[] | null | undefined
): T[] {
  if (!items) return [];
  return items.sort(CompareWorkTypes);
}
export default FDVue.extend({
  name: "fd-timesheet-existing",

  mixins: [serviceErrorHandling, rules],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      // *** GLOBAL ***
      slidein: false,
      selectedEntryGroupingType: "groupperson" as EntryGroupingType,
      tablepage: 1,

      isOverriding: false,
      saving: false,
      submitting: false,
      approving: false,
      declining: false,

      isReadonly: false,

      timesheet: {
        currentUserPermissions: {}
      } as UpdatableTimesheetWithEntries,
      timeSummaries: [] as EmployeeTimeSummary[],

      allUsedWorkOrders: [] as WorkOrderWithAllDetails[]
    };
  },

  computed: {
    entryGroupingTypeOptions(): { text: TranslateResult; value: string }[] {
      let options = [
        { text: this.$t("timesheets.existing.group-by-none-radio"), value: "groupnone" },
        { text: this.$t("timesheets.existing.group-by-person-radio"), value: "groupperson" }
      ];

      if (!this.timesheetIsEquipment) {
        options = options.concat([
          {
            text: this.$t("timesheets.existing.group-by-work-order-radio"),
            value: "groupworkorder"
          },
          {
            text: this.$t("timesheets.existing.group-by-person-work-order-radio"),
            value: "groupemployeeworkorder"
          }
        ]);
      }

      options = options.concat([
        { text: this.$t("timesheets.existing.group-by-cost-code-radio"), value: "groupcostcode" }
      ]);
      return options;
    },
    overrideColumnArgument(): string {
      return this.timesheetCanBeOverridden ? "override" : FDHiddenArgumentName;
    },
    timesheetIsEquipment(): boolean {
      return this.timesheet.timesheetTypeID == TimesheetType.Equipment;
    },
    timesheetIsSubmitted(): boolean {
      return this.timesheet.timesheetStatusID == TimesheetStatus.Submitted;
    },
    timesheetIsDeclined(): boolean {
      return this.timesheet.timesheetStatusID == TimesheetStatus.Declined;
    },
    timesheetDeclineComments(): string | undefined {
      return this.timesheet?.lastStatusLog?.comments;
    },
    timesheetIsApproved(): boolean {
      return this.timesheet.timesheetStatusID == TimesheetStatus.Approved;
    },
    timesheetIsCancelled(): boolean {
      return this.timesheet.timesheetStatusID == TimesheetStatus.Cancelled;
    },
    timesheetCanBeSubmitted(): boolean {
      return (
        (this.timesheet.timesheetStatusID == TimesheetStatus.New ||
          this.timesheet.timesheetStatusID == TimesheetStatus.Declined) &&
        this.timesheet.currentUserPermissions.canSubmit
      );
    },
    timesheetHasEntriesMissingCostCode(): boolean {
      return (
        !!this.timesheet.entries &&
        this.timesheet.entries.findIndex(
          x => (!x.overridden && !x.costCodeID) || (!!x.overridden && !x.costCodeIDOverride)
        ) !== -1
      );
    },
    timesheetCanBeOverridden(): boolean {
      return (
        this.timesheet.timesheetStatusID == TimesheetStatus.Submitted &&
        this.timesheet.currentUserPermissions.canOverrideSubmittedTimesheetValues
      );
    },
    timesheetCanBeApproved(): boolean {
      return (
        this.timesheet.timesheetStatusID == TimesheetStatus.Submitted &&
        this.timesheet.currentUserPermissions.canApprove &&
        !this.timesheetHasEntriesMissingCostCode
      );
    },
    timesheetCanBeDeclined(): boolean {
      return (
        this.timesheet.timesheetStatusID == TimesheetStatus.Submitted &&
        this.timesheet.currentUserPermissions.canApprove
      );
    },
    timesheetCanBeSaved(): boolean {
      if (!this.isReadonly && this.timesheet.currentUserPermissions.canEditCostCode) return true;
      if (!!this.isReadonly && this.timesheetCanBeOverridden) return true;
      return false;
    },
    timeColumns(): string[] {
      return [
        "regularTime",
        "overTime",
        "doubleTime",
        "perDiemUnits",
        "doubleTime",
        "equipmentUnits"
      ];
    },
    visibleColumns(): string[] {
      let columns = ["expander"];
      if (this.selectedEntryGroupingType != "groupperson") columns.push("employeeName");
      if (this.$vuetify.breakpoint.mdAndUp) {
        columns.push("classificationName");
        columns.push("workTypeName");
      }
      columns.push("workSubTypeName");
      if (this.timesheet.currentUserPermissions.canViewCostCode) {
        if (this.selectedEntryGroupingType != "groupcostcode") columns.push("costCodeName");
      }
      if (this.selectedEntryGroupingType != "groupworkorder" && !this.timesheetIsEquipment)
        columns.push("workOrderNumber");
      if (this.$vuetify.breakpoint.lgAndUp) {
        columns.push("areaName");
      }

      columns = columns.concat(this.timeColumns);

      if (this.timesheetCanBeOverridden) columns.push("override");

      columns.push("action");
      return columns;
    },
    numCols(): number {
      return this.visibleColumns.length;
    },
    preTimeNumCols(): number {
      return this.numCols - this.timeColumns.length - this.postTimeNumCols;
    },
    postTimeNumCols(): number {
      let cols = 1;
      if (this.timesheetCanBeOverridden) cols++;
      return cols;
    },
    itemsPerPage(): number {
      if (this.selectedEntryGroupingType == "groupperson") return -1;
      else if (this.selectedEntryGroupingType == "groupworkorder") return -1;
      else if (this.selectedEntryGroupingType == "groupemployeeworkorder") return -1;
      else if (this.selectedEntryGroupingType == "groupcostcode") return -1;
      return 25;
    },
    itemsPerPageOptions(): number[] {
      if (this.selectedEntryGroupingType == "groupperson") return [-1];
      else if (this.selectedEntryGroupingType == "groupworkorder") return [-1];
      else if (this.selectedEntryGroupingType == "groupemployeeworkorder") return [-1];
      else if (this.selectedEntryGroupingType == "groupcostcode") return [-1];
      return [5, 10, 15, 25, 50, -1];
    },
    formattedDay(): string {
      return DateUtil.stripTimeFromLocalizedDateTime(this.timesheet.day);
    },
    groupColumn(): string | undefined {
      if (this.selectedEntryGroupingType == "groupperson") return "employeeName";
      else if (this.selectedEntryGroupingType == "groupworkorder") return "workOrderNumber";
      else if (this.selectedEntryGroupingType == "groupemployeeworkorder")
        return "employeeWorkOrder";
      else if (this.selectedEntryGroupingType == "groupcostcode") return "costCodeName";
      return undefined;
    },

    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },
    validWorkOrderCostCodeIDs(): string[] {
      let validWorkOrderCostCodeIDs = [];
      let curEnvironment = this.$store.state.curEnvironment as Environment | undefined;
      if (!!curEnvironment?.defaultErectRequestCostCodeID)
        validWorkOrderCostCodeIDs.push(curEnvironment.defaultErectRequestCostCodeID);
      if (!!curEnvironment?.defaultModifyRequestCostCodeID)
        validWorkOrderCostCodeIDs.push(curEnvironment.defaultModifyRequestCostCodeID);
      if (!!curEnvironment?.defaultDismantleRequestCostCodeID)
        validWorkOrderCostCodeIDs.push(curEnvironment.defaultDismantleRequestCostCodeID);
      return validWorkOrderCostCodeIDs;
    },
    isGrouped(): boolean {
      return this.selectedEntryGroupingType != "groupnone";
    },
    isGroupedByEmployee(): boolean {
      return this.selectedEntryGroupingType == "groupperson";
    },
    allGroupsExpanded(): boolean {
      console.log(`allGroupsExpanded`);
      let toggleRefs = Object.keys(this.$refs).filter(x => x.startsWith("grouptoggle"));
      let anyGroupsClosed = false;
      for (let ref of toggleRefs) {
        let groupName = ref.replace("grouptoggle", "");
        let isOpen = (this.$refs.datatable as VDataTable).openCache[groupName];
        if (!isOpen) {
          console.log(`    group closed: ${ref}`);
          anyGroupsClosed = true;
          break;
        }
      }
      console.log(`  anyGroupsClosed: ${anyGroupsClosed}`);
      return !anyGroupsClosed;
    }
  },

  methods: {
    sanitizeNumber(value: string | number | null | undefined): number {
      if (!value) return 0;
      let number = Number(value);
      if (isNaN(number)) return 0;
      return number;
    },
    regularTimeChanged(entry: UpdatableTimesheetEntryWithDetails, newValue: any) {
      // When we display  numbers in read-only, we only display 2 decimal digits
      // The server also automatically rounds to 2 decimal digits
      // As such, when the user types the value we want to make sure they don't end up saving a different value than they typed
      let valueNumber = this.sanitizeNumber(newValue);
      let sanitizedValueNumber = Number(valueNumber.toFixed(2));
      if (valueNumber != sanitizedValueNumber) {
        this.$nextTick(() => {
          entry.regularTime = Number(Number(newValue).toFixed(2));
          this.regularTimeChanged(entry, sanitizedValueNumber);
        });
        return;
      }
    },
    overTimeChanged(entry: UpdatableTimesheetEntryWithDetails, newValue: any) {
      // When we display  numbers in read-only, we only display 2 decimal digits
      // The server also automatically rounds to 2 decimal digits
      // As such, when the user types the value we want to make sure they don't end up saving a different value than they typed
      let valueNumber = this.sanitizeNumber(newValue);
      let sanitizedValueNumber = Number(valueNumber.toFixed(2));
      if (valueNumber != sanitizedValueNumber) {
        this.$nextTick(() => {
          entry.overTime = Number(Number(newValue).toFixed(2));
          this.overTimeChanged(entry, sanitizedValueNumber);
        });
        return;
      }
    },
    doubleTimeChanged(entry: UpdatableTimesheetEntryWithDetails, newValue: any) {
      // When we display  numbers in read-only, we only display 2 decimal digits
      // The server also automatically rounds to 2 decimal digits
      // As such, when the user types the value we want to make sure they don't end up saving a different value than they typed
      let valueNumber = this.sanitizeNumber(newValue);
      let sanitizedValueNumber = Number(valueNumber.toFixed(2));
      if (valueNumber != sanitizedValueNumber) {
        this.$nextTick(() => {
          entry.doubleTime = Number(Number(newValue).toFixed(2));
          this.doubleTimeChanged(entry, sanitizedValueNumber);
        });
        return;
      }
    },
    selectableCostCodesForEntry(entry: UpdatableTimesheetEntryWithDetails): ProjectCostCode[] {
      var selectedWorkSubType = this.selectableWorkSubTypes(entry).find(
        x => x.id == entry.workSubTypeID
      );
      if (!selectedWorkSubType) {
        console.log(`selectedWorkSubType not found`);
        return [];
      }
      if (!this.workSubTypeIsConfiguredCorrectly(selectedWorkSubType)) return [];

      var selectableCostCodeIDs = [] as string[];
      if (selectedWorkSubType.useWorkOrderCostCode) {
        selectableCostCodeIDs = this.validWorkOrderCostCodeIDs;
      } else if (!!selectedWorkSubType.defaultCostCodeID) {
        selectableCostCodeIDs.push(selectedWorkSubType.defaultCostCodeID);
      } else {
        console.log(`selectedWorkSubType not configured`);
        return [];
      }
      console.log(`selectableCostCodeIDs: ${selectableCostCodeIDs}`);
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      let contractor = allContractors.find(x => x.id == this.timesheet.contractorID);
      if (!contractor?.costCodeIDs?.length) return [];
      return (this.$store.state.projectCostCodes.fullList as ProjectCostCode[])
        .filter(
          x => contractor!.costCodeIDs?.includes(x.id!) && selectableCostCodeIDs.includes(x.id!)
        )
        .sort((a, b) => {
          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;
          else return 0;
        });
    },
    workSubTypeIsConfiguredCorrectly(workSubType: WorkSubType | undefined): boolean {
      if (!workSubType) return false;

      return (
        (!!workSubType.isWorkOrderRelated && !!workSubType.useWorkOrderCostCode) ||
        !!workSubType.defaultCostCodeID
      );
    },
    employeeHasEntriesMissingCostCode(employeeID: string): boolean {
      return (
        this.timesheet.entries.findIndex(x => x.employeeID == employeeID && !x.costCodeID) !== -1
      );
    },
    anyItemsMissingCostCode(entries: UpdatableTimesheetEntryWithDetails[] | undefined): boolean {
      return (
        entries?.findIndex(
          x => (!x.overridden && !x.costCodeID) || (!!x.overridden && !x.costCodeIDOverride)
        ) !== -1 ?? false
      );
    },
    nameForCostCodeID(costCodeID: string): string | undefined {
      return (this.$store.state.projectCostCodes.fullList as ProjectCostCode[]).find(
        x => x.id == costCodeID
      )?.name;
    },
    overrideEntryValues(entry: UpdatableTimesheetEntryWithDetails) {
      if (!this.isOverriding) {
        this.isOverriding = true;
      }
      entry.overridden = true;
      entry.costCodeIDOverride = entry.costCodeID;

      entry.originalRegularTime = entry.regularTime ?? 0;
      entry.originalOverTime = entry.overTime ?? 0;
      entry.originalDoubleTime = entry.doubleTime ?? 0;
      entry.originalUnits = entry.units ?? 0;
    },
    cancelOverrideEntryValues(entry: UpdatableTimesheetEntryWithDetails) {
      entry.overridden = false;
      entry.costCodeIDOverride = null;

      entry.regularTime = entry.originalRegularTime;
      entry.originalRegularTime = null;

      entry.overTime = entry.originalOverTime;
      entry.originalOverTime = null;

      entry.doubleTime = entry.originalDoubleTime;
      entry.originalDoubleTime = null;

      entry.units = entry.originalUnits;
      entry.originalUnits = null;

      let overriddenEntries = this.timesheet.entries.filter(x => x.overridden);
      if (!overriddenEntries.length) {
        this.isOverriding = false;
      }
    },
    // This method determines if the row in the data-table should be colored to represent if it is "Urgent".
    timesheetRowClassName(item: UpdatableTimesheetEntryWithDetails): string[] {
      let classes = [] as string[];
      if (item.isCorrectionEntry) classes.push("fd-correction-table-row-background");
      if (!item.costCodeID) classes.push("fd-missing-cost-code-table-row-background");
      return classes;
    },
    toggleGroups(closed: boolean = true) {
      console.log(`toggleGroups closed: ${closed}`);
      let toggleRefs = Object.keys(this.$refs).filter(x => x.startsWith("grouptoggle"));
      let datatable = this.$refs.datatable as VDataTable;
      for (let ref of toggleRefs) {
        let groupName = ref.replace("grouptoggle", "");
        let isOpen = datatable.openCache[groupName];
        if ((closed && isOpen) || (!closed && !isOpen)) {
          datatable.openCache[groupName] = !datatable.openCache[groupName];
        }
      }
    },
    async _initialize() {
      console.log(`_initialize`);

      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/timesheetapproval") {
        this.notifyNewBreadcrumb({
          text: this.$t("timesheets.list.approval-title"),
          to: "/timesheetapproval",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: ``,
          to: `/timesheetapproval/${this.$route.params.id}`
        });
      }

      // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
      // a sub screen of something that is currently filtered by their choices that those choices will be
      // preserved as they move between the two screens.
      this.setFilteringContext({
        context: "timesheets-existing",
        parentalContext: "timesheets",
        searchStringForFiltering: ""
      });

      this.processing = true;
      try {
        console.log(`  get timesheet by id: ${this.$route.params.id}`);
        await Promise.all([
          this.loadContractors(),
          this.loadWorkTypes(),
          this.loadWorkSubTypes(),
          this.loadCostCodes(),
          this.loadWorkOrdersForTimesheet()
        ]);
        this.timesheet.id = this.$route.params.id;
        await this.loadTimesheet();

        this.isOverriding = this.timesheet.entries.findIndex(x => !!x.overridden) !== -1;

        this.$nextTick(() => {
          this.toggleGroups(true);
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS",
      loadWorkTypes: "LOAD_WORK_TYPES",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES",
      loadCostCodes: "LOAD_PROJECT_COST_CODES"
    }),
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    sum(items: any[], propName: string): string | undefined {
      let result = items.reduce((a: number, b: any) => a + this.sanitizeNumber(b[propName]), 0);
      return !result ? undefined : result.toFixed(2);
    },
    labelForGroup(value: string): TranslateResult | string | undefined {
      if (this.selectedEntryGroupingType == "groupperson")
        return this.$t("timesheets.existing.person-group-label-with-code", [
          value,
          this.timesheet.entries.find(x => x.employeeName == value)?.employeeCode
        ]);
      else if (this.selectedEntryGroupingType == "groupworkorder")
        return !!value
          ? this.$t("timesheets.existing.work-order-group-label", [value])
          : this.$t("timesheets.existing.work-order-none-group-label");
      else if (this.selectedEntryGroupingType == "groupemployeeworkorder") {
        if (value.startsWith("0_")) {
          return this.$t(
            "timesheets.existing.person-work-order-none-group-label",
            value.split("_")
          );
        } else {
          return this.$t("timesheets.existing.person-work-order-group-label", value.split("_"));
        }
      } else if (this.selectedEntryGroupingType == "groupcostcode")
        return this.$t("timesheets.existing.cost-code-group-label", [value]);
      return undefined;
    },
    cancel() {
      this.$router.push("/timesheetapproval");
    },
    async baseSave(): Promise<boolean> {
      try {
        var errorEntries = this.timesheet.entries.filter(
          x => !x.regularTime && !x.overTime && !x.doubleTime && !x.units
        );
        if (errorEntries.length) {
          this.inlineMessage.message = this.$t(
            "timesheets.entries.entries-missing-data-error-message"
          );
          this.processing = false;
          this.saving = false;
          return false;
        }

        if (this.timesheet.hasModifiedEntries) {
          await timesheetService.updateEntriesForTimesheetWithID(
            this.timesheet.id!,
            this.timesheet.modifiedExistingEntryData
          );
        }
        if (this.timesheet.hasRemovedEntries) {
          await timesheetService.removeEntriesFromTimesheetWithID(
            this.timesheet.id!,
            this.timesheet.removedEntryIDs
          );
        }

        var snackbarPayload = {
          text: this.$t("timesheets.snack-bar-updated-message", [
            this.timesheet.ownerName,
            this.formattedDay
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        throw error;
      }
      return true;
    },
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        console.log(`form validation failed`);
        this.inlineMessage.message = this.$t("timesheets.existing.form-errors-message");
        this.inlineMessage.type = "error";
        return;
      }
      this.processing = true;
      this.saving = true;
      try {
        if (!this.timesheet.isDirty) {
          this.processing = false;
          this.saving = false;
          var snackbarPayload = {
            text: this.$t("timesheets.entries.no-changes-to-save-message"),
            type: "info",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
          this.processing = false;
          this.saving = false;
          console.log(`no changes to save`);
          return;
        }

        var saved = await this.baseSave();

        if (saved) {
          if (closeOnComplete) {
            this.$router.push("/timesheetapproval");
          } else {
            await this.loadTimesheet();
          }
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    async submitTimesheet() {
      if (!this.timesheet.currentUserPermissions.canSubmit) return;
      this.processing = true;
      this.submitting = true;
      try {
        let submittedToID = undefined as string | undefined;

        if (
          this.timesheet.timesheetTypeID == TimesheetType.Indirect ||
          this.timesheet.timesheetTypeID == TimesheetType.Equipment
        ) {
          let visibleTimeManagers = SortItemsWithName(
            (await personService.getVisibleTimeManagers())
              .filter(x => x.contractorID == this.timesheet.contractorID)
              .map(x => ({
                ...x,
                name: GetPersonName(x)
              }))
          );

          var title = this.$t("timesheets.entries.submit-to-time-manager-label");
          submittedToID = await showItemSelectionDialog(
            title,
            this.$t("timesheets.entries.time-manager-label"),
            [this.rules.required],
            visibleTimeManagers,
            "name",
            "id"
          );
          // If details is undefined the dialog was cancelled, if empty we somehow bypassed selecting a TM
          if (!submittedToID) {
            return false;
          }
        } else {
          let visibleGeneralForeman = SortItemsWithName(
            (await personService.getVisibleGeneralForemen())
              .filter(x => x.contractorID == this.timesheet.contractorID)
              .map(x => ({
                ...x,
                name: GetPersonName(x)
              }))
          );

          var title = this.$t("timesheets.entries.submit-to-gen-foreman-label");
          submittedToID = await showItemSelectionDialog(
            title,
            this.$t("timesheets.entries.general-foreman-label"),
            [this.rules.required],
            visibleGeneralForeman,
            "name",
            "id"
          );
        }

        // If details is undefined the dialog was cancelled
        if (!submittedToID) {
          this.submitting = false;
          this.processing = false;
          return false;
        }

        this.timesheet.submittedTo = submittedToID;

        await timesheetService.submitTimesheet(this.timesheet.id!, submittedToID);
        this.timesheet.timesheetStatusID = TimesheetStatus.Submitted;
        this.isReadonly = this.timesheet.isLocked;

        var snackbarPayload = {
          text: this.$t("timesheets.list.submit-success", [
            this.timesheet.ownerName,
            this.formattedDay,
            this.formattedDay
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.$router.push("/timesheetapproval");
      } catch (error) {
        if ((error as any).statusCode == 422) {
          var snackbarPayload = {
            text: this.$t("timesheets.list.submit-validation-failed", [
              this.timesheet.ownerName,
              this.formattedDay,
              this.formattedDay
            ]),
            type: "error",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        } else {
          this.handleError(error as Error);
        }
      } finally {
        this.submitting = false;
        this.processing = false;
      }
    },
    async declineTimesheet() {
      if (!this.timesheet.currentUserPermissions.canApprove) return;
      this.processing = true;
      this.declining = true;
      try {
        // get reason
        var title = this.$t("timesheets.approval.decline-reason");
        var reason = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);

        // If details is undefined the dialog was cancelled
        if (!reason) {
          this.declining = false;
          this.processing = false;
          return false;
        }

        await timesheetService.declinePendingTimesheet(this.timesheet.id!, reason);
        this.timesheet.timesheetStatusID = TimesheetStatus.Declined;
        this.isReadonly = this.timesheet.isLocked;

        var snackbarPayload = {
          text: this.$t("timesheets.approval.decline-success", [
            this.timesheet.ownerName,
            this.formattedDay
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.$router.push("/timesheetapproval");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.declining = false;
        this.processing = false;
      }
    },
    async saveAndApproveTimesheet() {
      this.processing = true;
      try {
        if (this.timesheet.isDirty) {
          this.saving = true;
          var saved = await this.baseSave();
          this.saving = false;
          if (!saved) return;
        }

        this.approving = true;
        await this.baseApproveTimesheet();
        this.approving = false;

        this.$router.push("/timesheetapproval");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
        this.approving = false;
      }
    },
    async baseApproveTimesheet() {
      await timesheetService.approvePendingTimesheet(this.timesheet.id!);
      this.timesheet.timesheetStatusID = TimesheetStatus.Approved;
      this.isReadonly = this.timesheet.isLocked;

      var snackbarPayload = {
        text: this.$t("timesheets.approval.approve-success", [
          this.timesheet.ownerName,
          this.formattedDay
        ]),
        type: "success",
        undoCallback: null
      };
      this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
    },
    async approveTimesheet() {
      if (!this.timesheet.currentUserPermissions.canApprove) return;
      this.processing = true;
      this.approving = true;
      try {
        await this.baseApproveTimesheet();

        this.$router.push("/timesheetapproval");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.approving = false;
        this.processing = false;
      }
    },
    async showStatusLogDialog() {
      await showTimesheetStatusHistoryDialog(this.timesheet?.statusLogs ?? []);
    },
    removeExistingEntry(entry: UpdatableTimesheetEntryWithDetails) {
      const index = this.timesheet.entries.indexOf(entry);
      if (index < 0) {
        return;
      }
      let removedEntries = this.timesheet.entries.splice(index, 1);
      this.timesheet.removedEntryIDs = this.timesheet.removedEntryIDs.concat(
        removedEntries.map(x => x.id!)
      );
    },
    async openNewTimesheetEntryDialog() {
      if (await addTimesheetEntries(this.timesheet)) {
        await Promise.all([this.loadTimesheetEntries(), this.loadTimeSummaries()]);
      }
    },
    async loadTimesheet() {
      let timesheet = await timesheetService.getByID(this.$route.params.id);
      this.timesheet = new UpdatableTimesheetWithEntries(timesheet);
      this.isReadonly = this.timesheet.isLocked;
      await Promise.all([this.loadTimesheetEntries(), this.loadTimeSummaries()]);
    },
    async loadTimesheetEntries() {
      let timesheetEntries = await timesheetService.getEntriesForTimesheetID(this.$route.params.id);
      this.timesheet.entries = timesheetEntries.map(x => new UpdatableTimesheetEntryWithDetails(x));
    },
    async loadTimeSummaries() {
      let timeSummaries = await timesheetService.getEmployeeTimeSummariesForTimesheetWithID(
        this.$route.params.id,
        false
      );
      this.timeSummaries = timeSummaries;
    },
    getTotalTimeForEmployee(employeeName: string, propertyName: string): string | undefined {
      let sampleTimeEntry = this.timesheet.entries.find(x => x.employeeName == employeeName);
      let summary = this.timeSummaries.find(x => x.employeeID == sampleTimeEntry?.employeeID);
      if (!summary) return undefined;
      let time = (summary as any)[propertyName];
      if (!time || time == 0) return undefined;
      return time.toFixed(2);
    },

    // *** REFERENCE DATA ***
    async loadWorkOrdersForTimesheet() {
      this.allUsedWorkOrders = await workOrderService.getWorkOrdersForTimesheet(
        this.$route.params.id
      );
    },
    selectedEntryEmployeeHasOtherPerDiemRecord(item: TimesheetEntry): boolean {
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      let contractor = allContractors.find(x => x.id == this.timesheet.contractorID);
      if (!contractor?.workTypeIDs?.length) return false;

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let perDiemWorkType = allWorkTypes.find(x => !!x.isPerDiem);
      if (!perDiemWorkType) return false;

      return (
        this.timesheet.entries.findIndex(
          x =>
            x.id != item.id &&
            x.workTypeID == perDiemWorkType!.id &&
            item.employeeID == x.employeeID
        ) !== -1
      );
    },
    workTypeSelectable(workType: WorkType | undefined, item: TimesheetEntry): boolean {
      if (!workType) return false;

      let canEdit = false;
      if (workType.isPerDiem) {
        canEdit = !this.selectedEntryEmployeeHasOtherPerDiemRecord(item) && !item.workOrderID;
      } else {
        var allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
        let workSubTypes = allWorkSubTypes.filter(x => x.workTypeID == workType.id);
        let hasWorkOrderSubTypes = workSubTypes.findIndex(x => !!x.isWorkOrderRelated) !== -1;
        let canSelectWithWorkOrder =
          (workType.isDirect &&
            !workType.isPerDiem &&
            !workType.isEquipment &&
            hasWorkOrderSubTypes) ??
          false;

        let hasIndirectSubTypes = workSubTypes.findIndex(x => !x.isWorkOrderRelated) !== -1;
        let canSelectWithoutWorkOrder = !workType.isDirect || hasIndirectSubTypes;

        let hasWorkOrder = !!item.workOrderID ?? false;
        canEdit =
          (canSelectWithWorkOrder && hasWorkOrder) || (canSelectWithoutWorkOrder && !hasWorkOrder);
      }
      return canEdit;
    },
    selectableWorkTypes(item: TimesheetEntry): SelectListOption<WorkType>[] {
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      let contractor = allContractors.find(x => x.id == this.timesheet.contractorID);

      if (!contractor?.workTypeIDs?.length) return [];

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      return SortWorkTypes(
        allWorkTypes
          .filter(
            x =>
              (this.timesheet.timesheetTypeID == TimesheetType.Equipment && x.isEquipment) ||
              (this.timesheet.timesheetTypeID != TimesheetType.Equipment &&
                (contractor!.workTypeIDs?.includes(x.id!) || x.isPerDiem))
          )
          .map(x => {
            return { ...x, disabled: !this.workTypeSelectable(x, item) };
          })
      );
    },
    workTypeChangedForItem(item: TimesheetEntry, newValue: string) {
      console.log(`workTypeChangedForItem`);
      item.workTypeID = newValue;
      let selectableWorkSubTypes = this.selectableWorkSubTypes(item);
      if (selectableWorkSubTypes.findIndex(x => x.id == item.workSubTypeID) === -1) {
        console.log(`  Previously selected subtype ID not found.  Clear value.`);
        item.workSubTypeID = undefined;
      }
    },
    workSubTypeSelectable(workSubType: WorkSubType | undefined, entry: TimesheetEntry): boolean {
      if (!workSubType) return false;

      var allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let parentWorkType = allWorkTypes.find(x => x.id == workSubType.workTypeID);
      let canEdit = false;
      if (parentWorkType?.isPerDiem) {
        canEdit = !this.selectedEntryEmployeeHasOtherPerDiemRecord(entry) && !entry.workOrderID;
      } else {
        let canSelectWithWorkOrder =
          (parentWorkType?.isDirect && workSubType.isWorkOrderRelated) ?? false;

        let canSelectWithoutWorkOrder =
          !parentWorkType?.isDirect || !workSubType.isWorkOrderRelated;

        let hasWorkOrder = !!entry.workOrderID ?? false;
        canEdit =
          (canSelectWithWorkOrder && hasWorkOrder) || (canSelectWithoutWorkOrder && !hasWorkOrder);
      }
      return canEdit;
    },
    selectableWorkSubTypes(item: TimesheetEntry): WorkSubType[] {
      var allWorkSubTypes = SortWorkTypes(
        (this.$store.state.workSubTypes.fullList as WorkSubType[])
          .filter(x => !!this.workSubTypeIsConfiguredCorrectly(x))
          .map(x => {
            return { ...x, disabled: !this.workSubTypeSelectable(x, item) };
          })
      );
      return allWorkSubTypes.filter(x => x.workTypeID == item.workTypeID);
    },
    workSubTypeChangedForItem(item: TimesheetEntry, newValue: string) {
      console.log(`workSubTypeChangedForItem`);
      item.workSubTypeID = newValue;
      var allWorkSubTypes = (this.$store.state.workSubTypes.fullList as WorkSubType[])
        .slice()
        .sort((a, b) => {
          let aOrder = a.order ?? 0;
          let bOrder = b.order ?? 0;
          if (aOrder != bOrder) return aOrder - bOrder;

          let aName = a.name!.toLocaleLowerCase();
          let bName = b.name!.toLocaleLowerCase();
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        });
      let workSubType = allWorkSubTypes.find(x => x.id == item.workSubTypeID);
      let usedWorkOrder = this.allUsedWorkOrders.find(x => x.id == item.workOrderID);
      var costCodeID =
        workSubType?.useWorkOrderCostCode == true && !!usedWorkOrder?.costCodeID
          ? usedWorkOrder!.costCodeID
          : workSubType?.defaultCostCodeID;
      item.costCodeID = costCodeID;
    },
    canEditItem(item: TimesheetEntry): boolean {
      if (!this.timesheet.currentUserPermissions.canEditExistingEntries) return false;
      var editable = !this.isReadonly || (this.isOverriding && !!item.overridden);
      return editable;
    },
    canEditRegularTimeHoursForItem(item: TimesheetEntry): boolean {
      if (!this.canEditItem(item)) return false;

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let perDiemWorkType = allWorkTypes.find(x => !!x.isPerDiem);
      if (!!perDiemWorkType) {
        if (perDiemWorkType.id == item.workTypeID) return false;
      }

      return true;
    },
    canEditOverTimeHoursForItem(item: TimesheetEntry): boolean {
      if (!this.canEditItem(item)) return false;

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let perDiemWorkType = allWorkTypes.find(x => !!x.isPerDiem);
      if (!!perDiemWorkType) {
        if (perDiemWorkType.id == item.workTypeID) return false;
      }

      return true;
    },
    canEditDoubleTimeHoursForItem(item: TimesheetEntry): boolean {
      if (!this.canEditItem(item)) return false;

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let perDiemWorkType = allWorkTypes.find(x => !!x.isPerDiem);
      if (!!perDiemWorkType) {
        if (perDiemWorkType.id == item.workTypeID) return false;
      }

      return true;
    },
    canEditPerDiemUnitsForItem(item: TimesheetEntry): boolean {
      if (!this.canEditItem(item)) return false;

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let perDiemWorkType = allWorkTypes.find(x => !!x.isPerDiem);
      if (!!perDiemWorkType) {
        if (perDiemWorkType.id != item.workTypeID) return false;
      }

      return true;
    },
    canEditEquipmentDaysForItem(item: TimesheetEntry): boolean {
      if (!this.canEditItem(item)) return false;

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let equipmentWorkType = allWorkTypes.find(x => !!x.isEquipment);
      if (!!equipmentWorkType) {
        if (equipmentWorkType.id != item.workTypeID) return false;
      }

      return true;
    },
    canEditEquipmentUnitsForItem(item: TimesheetEntry): boolean {
      if (!this.canEditItem(item)) return false;

      let allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
      let equipmentWorkType = allWorkTypes.find(x => !!x.isEquipment);
      if (!!equipmentWorkType) {
        if (equipmentWorkType.id != item.workTypeID) return false;
      }

      return true;
    },

    // *** INLINE NAVIGATION ***
    getFieldRef(fieldName: string, item: UpdatableTimesheetEntryWithDetails) {
      let id = item.id!.replace("-", "").replace("-", "");
      return `${fieldName}_${id}`;
    },
    focusFieldForVisibleItemAtIndex(
      fieldName: string,
      index: number,
      visibleItems: UpdatableTimesheetEntryWithDetails[]
    ) {
      if (!visibleItems.length) return;

      if (index < 0) index = 0;
      if (index >= visibleItems.length) index = visibleItems.length - 1;
      let item = visibleItems[index];

      let itemFieldRef = this.getFieldRef(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    async selectPreviousField(fieldName: string, item: UpdatableTimesheetEntryWithDetails) {
      let datatable = this.$refs.datatable as VDataTable;
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex <= 0) {
        if (this.tablepage <= 1) return;
        this.tablepage -= 1;

        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(
            fieldName,
            datatable.computedItemsPerPage,
            visibleItems
          );
        });
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, previousIndex, visibleItems);
    },
    async selectNextField(fieldName: string, item: UpdatableTimesheetEntryWithDetails) {
      let datatable = this.$refs.datatable as VDataTable;
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex >= visibleItems.length - 1) {
        let maxPage =
          datatable.computedItemsPerPage <= 0
            ? 1
            : Math.ceil(this.timesheet.entries.length / datatable.computedItemsPerPage);

        if (this.tablepage >= maxPage) return;
        this.tablepage += 1;

        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, 0, visibleItems);
        });
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, nextIndex, visibleItems);
    },
    async enterPressed(
      e: KeyboardEvent,
      fieldName: string,
      item: UpdatableTimesheetEntryWithDetails
    ) {
      if (e.shiftKey) await this.selectPreviousField(fieldName, item);
      else await this.selectNextField(fieldName, item);
    }
  },

  watch: {
    timesheet() {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/timesheetapproval") {
        this.notifyNewBreadcrumb({
          text: this.$t("timesheets.list.approval-title"),
          to: "/timesheetapproval",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      let timesheetNumberString = `00000${this.timesheet.timesheetNumber}`.slice(-5);
      this.notifyNewBreadcrumb({
        text: !!this.timesheet.id
          ? this.$t("timesheets.existing.title", [
              this.timesheet.ownerName,
              this.formattedDay,
              timesheetNumberString
            ])
          : this.$t("timesheets.existing.new-timesheet"),
        to: `/timesheetapproval/${this.$route.params.id}`
      });
    },
    selectedEntryGroupingType(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$nextTick(() => {
          this.toggleGroups(true);
        });
      }
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    console.log(`created`);
    this._initialize();
  }
});

