import { defineComponent, onMounted, onBeforeUnmount, ref, watch } from "../";

// There is allegedly a Quill typings file but it doesn't seem to be working for me... since
// we abstract it out anyhow I won't worry about it
const Quill = require("quill/core").default;
const Toolbar = require("quill/modules/toolbar").default;
const Snow = require("quill/themes/snow").default;
const Bold = require("quill/formats/bold").default;
const Italic = require("quill/formats/italic").default;
const Header = require("quill/formats/header").default;
const Underline = require("quill/formats/underline").default;
const Indent = require("quill/formats/indent").IndentClass;
const List = require("quill/formats/list");
const Color = require("quill/formats/color").ColorStyle;
const Background = require("quill/formats/background").BackgroundStyle;
const Align = require("quill/formats/align").AlignStyle;
const Image = require("quill/formats/image").default;

const Delta = Quill.import("delta");

import "quill/dist/quill.snow.css";

Quill.register({
  "modules/toolbar": Toolbar,
  "themes/snow": Snow,
  "formats/bold": Bold,
  "formats/italic": Italic,
  "formats/header": Header,
  "formats/underline": Underline,
  "formats/indent": Indent,
  "formats/list": List.default,
  "formats/list-item": List.ListItem,
  "formats/color": Color,
  "formats/background": Background,
  "formats/align": Align,
  "formats/image": Image
});

export default defineComponent({
  props: {
    value: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    readonly: {
      type: Boolean,
      required: false
    },
    cy: {
      type: String,
      required: false
    },
    allowImages: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  name: "fd-rich-textarea",
  setup(props, context) {
    const editor = ref<HTMLDivElement>();
    const cachedContents = ref<string | null>(null);
    var quillInstance: any = null;

    onMounted(() => {
      let toolbar = [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }]
      ];
      if (props.allowImages) {
        toolbar.push(["image"]);
      }

      let showToolbar = !props.readonly;
      quillInstance = new Quill(editor.value, {
        modules: {
          toolbar: showToolbar ? toolbar : false
        },
        placeholder: props.placeholder,
        theme: "snow" // or 'bubble'
      });
      quillInstance.enable(!props.disabled && !props.readonly);
      quillInstance.on("text-change", onTextChange);
      cachedContents.value = props.value || null;
      quillInstance.root.innerHTML = props.value || "";
    });

    watch(
      () => props.value,
      newValue => {
        if (newValue != cachedContents.value) {
          cachedContents.value = newValue || null;
          quillInstance.root.innerHTML = newValue || "";
        }
      }
    );

    watch(
      () => props.disabled,
      newValue => {
        quillInstance.enable(!newValue && !props.readonly);
      }
    );
    watch(
      () => props.readonly,
      newValue => {
        quillInstance.enable(!newValue && !props.disabled);
      }
    );

    const onTextChange = (delta: any, oldDelta: any, source: "api" | "user") => {
      var newValue = quillInstance.root.innerHTML as string;
      var finalDelta = oldDelta.compose(delta);
      var testString = deltaToString(finalDelta);
      var reformedDelta = stringToDelta(testString);
      if (newValue != cachedContents.value) {
        cachedContents.value = newValue;
        context.emit("input", newValue);
      }
    };

    return {
      editor
    };
  }
});

function deltaToString(delta: any): string {
  return delta.reduce((content: string, item: any) => {
    if (!item.insert) throw new Error("Not supported delta"); // TODO: Is this necessary?
    if (item.attributes) {
      let attributeString = attributesToString(item.attributes);
      if (item.insert == "\n") {
        content += `[[${attributeString}]]`;
      } else {
        content += `[[${item.insert}:${attributeString}]]`;
      }
    } else {
      content += item.insert;
    }
    return content;
  }, "");
}

function stringToDelta(value: string): any {
  var returnValue = new Delta();
  var index = 0;
  while (index < value.length) {
    if (value.substr(index, 2) == "[[") {
      let endIndex = value.indexOf("]]", index + 2);
      let separatorIndex = value.lastIndexOf(":", endIndex);
      let nextString: string;
      if (separatorIndex < index) {
        nextString = "\n";
        separatorIndex = index + 1;
      } else {
        nextString = value.substring(index + 2, separatorIndex);
      }
      let attributes = stringToAttributes(value.substring(separatorIndex + 1, endIndex));
      returnValue.insert(nextString, attributes);
      index = endIndex + 2;
    } else {
      let nextIndex = value.indexOf("[[", index);
      if (nextIndex === -1) nextIndex = value.length;
      returnValue.insert(value.substring(index, nextIndex));
      index = nextIndex;
    }
  }
  return returnValue;
}

function attributesToString(attributes: { [key: string]: boolean | string | number }): string {
  let returnValue = "";
  for (let key in attributes) {
    if (returnValue) returnValue += ",";
    if (attributes[key] === true) {
      returnValue += key;
    } else {
      returnValue += `${key}=${attributes[key]}`;
    }
  }
  return returnValue;
}

function stringToAttributes(value: string): { [key: string]: boolean | string | number } {
  var returnValue: { [key: string]: boolean | string | number } = {};
  var rawAttributes = value.split(",");
  for (let rawAttribute of rawAttributes) {
    let splitRawAttribute = rawAttribute.split(":");
    let attributeValue: boolean | string | number;
    if (splitRawAttribute.length === 1) {
      attributeValue = true;
    } else {
      var candidateNumber = Number.parseInt(splitRawAttribute[1]);
      if (candidateNumber !== undefined) {
        attributeValue = candidateNumber;
      } else {
        attributeValue = splitRawAttribute[1];
      }
    }
    returnValue[splitRawAttribute[0]] = attributeValue;
  }
  return returnValue;
}

// import FDVue from "../";
// import { VueEditor } from "vue2-editor";

// export default FDVue.extend({
//   name: "fd-rich-textarea",

//   inheritAttrs: false,

//   props: {
//     value: {},
//     cy: { type: String, default: "fd-rich-textarea" },
//     disabled: { type: Boolean, default: false }
//   },

//   components: {
//     VueEditor
//   },

//   data: function() {
//     return {};
//   },

//   methods: {},

//   computed: {
//     customToolbarConfig() {
//       // if the 'images' attribute is set to true then the user will have the ability to insert images within the rich text control
//       // these images will be saved in the database in a base64 form.
//       if (this.$attrs.images) {
//         return {
//           customToolbar: [
//             [{ header: [1, 2, 3, 4, 5, 6, false] }],
//             ["bold", "italic", "underline"],
//             [{ list: "ordered" }, { list: "bullet" }],
//             [{ indent: "-1" }, { indent: "+1" }],
//             [{ color: [] }, { background: [] }], // dropdown with defaults from theme
//             [{ align: [] }],
//             ["image"]
//           ]
//         };
//       } else {
//         return {
//           customToolbar: [
//             [{ header: [1, 2, 3, 4, 5, 6, false] }],
//             ["bold", "italic", "underline"],
//             [{ list: "ordered" }, { list: "bullet" }],
//             [{ indent: "-1" }, { indent: "+1" }],
//             [{ color: [] }, { background: [] }], // dropdown with defaults from theme
//             [{ align: [] }]
//           ]
//         };
//       }
//     }
//   },

//   watch: {}
// });

