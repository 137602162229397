import { mapActions, mapMutations } from "vuex";
import FDVue from "@fd/lib/vue";
import { Language, ISO, System, TestPackage } from "../services";
import archivedDataList from "../dataMixins/archivedDataList";
import {
  FDColumnDirective,
  FDRowNavigateDirective,
  FDTableSortableDirective
} from "@fd/lib/vue/utility/dataTable";
import userAccess from "../dataMixins/userAccess";
import { SortItemsWithName } from "../utils/person";
import { stripHtml, truncateWithEllipsis } from "@fd/lib/vue/utility/helper";
import { showISONewDialog } from "./components/dialogs/SP.ISONewDialog.vue";
import { valueInArray } from "@fd/lib/client-util/array";

type IsoWithDetails = ISO & {
  testPackageID: string | null | undefined;
  testPackageName: string | null | undefined;
  systemID: string | null | undefined;
  systemName: string | null | undefined;
  archived: boolean;
};

export default FDVue.extend({
  name: "sp-isos-list",

  mixins: [userAccess, archivedDataList],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    fdTableSortable: FDTableSortableDirective
  },

  data: function() {
    return {
      deleting: false,
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5
    };
  },

  computed: {
    languageslist(): Language[] {
      return this.$store.state.languages.fullList as Language[];
    },
    testPackages(): TestPackage[] {
      return this.$store.state.testPackages.fullList as TestPackage[];
    },
    systems(): System[] {
      return this.$store.state.systems.fullList as System[];
    },
    allIsos(): IsoWithDetails[] {
      return (this.$store.state.isos.fullList as IsoWithDetails[]).map(iso => {
        let testPackage = this.testPackages.find(pkg => pkg.id == iso.testPackageID);
        let system = this.systems.find(sys => sys.id == testPackage?.systemID);
        return {
          ...iso,
          description: truncateWithEllipsis(stripHtml(iso.description)),
          testPackageID: testPackage?.id,
          testPackageName: testPackage?.name,
          systemID: system?.id,
          systemName: system?.name,
          archived: !!iso.archivedDate
        } as IsoWithDetails;
      });
    },
    isos(): IsoWithDetails[] {
      let allIsos = SortItemsWithName(this.allIsos);
      if (!!this.selectedSystemIDs.length) {
        allIsos = allIsos.filter(x => valueInArray(x.systemID, this.selectedSystemIDs));
        if (!!this.selectedTestPackageIDs.length) {
          allIsos = allIsos.filter(x => valueInArray(x.testPackageID, this.selectedTestPackageIDs));
        }
      }
      return allIsos;
    },

    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    selectableSystems(): System[] {
      let selectableSystemIDs = this.allIsos.filter(x => !!x.systemID).map(x => x.systemID!);

      let selectedTestPackageIDs = this.selectedTestPackageIDs;
      if (!!selectedTestPackageIDs.length) {
        let testPackageFilteredSystemIDs = this.testPackages
          .filter(x => !!x.systemID && valueInArray(x.id, selectedTestPackageIDs))
          .map(x => x.systemID!);
        selectableSystemIDs = selectableSystemIDs.filter(x =>
          valueInArray(x, testPackageFilteredSystemIDs)
        );
      }

      selectableSystemIDs = [...new Set(selectableSystemIDs)];
      return this.systems.filter(x => valueInArray(x.id, selectableSystemIDs));
    },

    selectedSystemIDs: {
      get(): string[] {
        var selectedSystemIDs = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.areasForFiltering as string[];
        return selectedSystemIDs;
      },
      set(val: string[]) {
        this.$store.commit("SET_AREAS_FOR_FILTERING", val);
      }
    },

    selectableTestPackages(): TestPackage[] {
      let selectableTestPackageIDs = this.allIsos
        .filter(x => !!x.testPackageID)
        .map(x => x.testPackageID!);

      let selectedSystemIDs = this.selectedSystemIDs;
      if (!!selectedSystemIDs.length) {
        let systemFilteredTestPackageIDs = this.testPackages
          .filter(x => valueInArray(x.systemID, selectedSystemIDs))
          .map(x => x.id!);
        selectableTestPackageIDs = selectableTestPackageIDs.filter(x =>
          valueInArray(x, systemFilteredTestPackageIDs)
        );
      }

      selectableTestPackageIDs = [...new Set(selectableTestPackageIDs)];
      return this.testPackages.filter(x => valueInArray(x.id, selectableTestPackageIDs));
    },

    selectedTestPackageIDs: {
      get(): string[] {
        var selectedTestPackageIDs = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.subAreasForFiltering as string[];
        return selectedTestPackageIDs;
      },
      set(val: string[]) {
        this.$store.commit("SET_SUB_AREAS_FOR_FILTERING", val);
      }
    }
  },
  methods: {
    navigate(item: ISO) {
      this.$router.push(`/isos/${item.id}`);
    },
    // *** GLOBAL ***
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadIsos: "LOAD_ISOS",
      loadTestPackages: "LOAD_TEST_PACKAGES",
      loadSystems: "LOAD_SYSTEMS",
      updateIso: "UPDATE_ISO",
      deleteIso: "DELETE_ISO"
    }),
    async openNewDialog() {
      await showISONewDialog();
    },
    async loadData() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      // Because the ISO doesn't store the system ID,
      // If we want to display the system name in the grid we need to get the system ID from the test package
      // Therefore the easiest way to get our name info is to load all systems & test packages and find the name manually
      await Promise.all([this.loadSystems(), this.loadTestPackages()]);
      await this.loadIsos({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },

    async reloadTableData() {
      this.processing = true;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // the following works with the delete "Action" button in the Datatable.
    async deleteTableItem(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteIso({ id: item.id, name: item.name });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async flipArchived(item: IsoWithDetails) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateIso({
          id: item.id,
          archivedDate: archivedDate,
          name: item.name
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "isos",
      parentalContext: null,
      areasForFiltering: [],
      subAreasForFiltering: [],
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: new Date(0),
      showArchivedForFilteringToDate: new Date(),
      searchStringForFiltering: ""
    });
    this.notifyNewBreadcrumb({
      text: this.$t("isos.list.title"),
      to: "/isos",
      resetHistory: true
    });

    this.processing = true;
    try {
      await this.loadData();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

