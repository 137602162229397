import { scaffoldBayHeightService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: scaffoldBayHeightService,
  singularStoreName: "SCAFFOLD_BAY_HEIGHT",
  pluralStoreName: "SCAFFOLD_BAY_HEIGHTS",
  localizationPrefix: "scaffold-bays.bay-heights",
  consumerRelatedIDProperty: {
    name: "scaffoldBayHeightID",
    type: "string"
  }
});

export default storeModule;

