import { yardService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: yardService,
  singularStoreName: "YARD",
  pluralStoreName: "YARDS",
  localizationPrefix: "yards",
  // The following is the name of the ID property used by foreign objects.
  consumerRelatedIDProperty: {
    name: "yardID",
    type: "string"
  }
});

export default storeModule;

