import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { Yard, YardType } from "../../../services";

const YardNewDialog = FDVue.extend({
  name: "fd-yard-new-dialog",

  mixins: [dialogSupport, rules],

  data: function() {
    return {
      saving: false,
      yard: {
        name: "",
        yardTypeID: YardType.OnRent,
        isSystemYard: false
      } as Yard,
      allYardTypes: (Object.keys(YardType)
        .filter(x => !isNaN(Number(x)))
        .map(x => Number(x)) as number[]).map(x => {
        return {
          value: x,
          text: this.$t(`yards.type.${YardType[x].toLocaleLowerCase()}`)
        };
      })
    };
  },

  computed: {
    yardRules() {
      return {
        name: [this.rules.required],
        yardTypeID: [this.rules.required],
        isSystemYard: [this.rules.required]
      };
    }
  },

  methods: {
    async open(lastDisplayOrder: number) {
      this.yard.order = lastDisplayOrder + 1;
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.saving = true;
      this.processing = true;
      try {
        await this.addYard({
          ...this.yard
        });
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    ...mapActions({
      addYard: "ADD_YARD"
    })
  }
});

export default YardNewDialog;

export async function showYardNewDialog(lastDisplayOrder: number): Promise<boolean> {
  let dialog = createDialog(YardNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(lastDisplayOrder);
}

