var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { "data-cy": _vm.cy } },
    [
      _vm.workOrderIsDeclined
        ? _c("fd-alert", {
            staticClass: "mx-3 mt-5",
            attrs: {
              type: "warning",
              label: !!_vm.workOrder.approvalComments
                ? _vm.$t("scaffold-requests.declined-with-reason")
                : _vm.$t("scaffold-requests.declined"),
              comments: _vm.workOrder.approvalComments,
              "persistent-comments": ""
            }
          })
        : _vm.walkdownIsDeclined
        ? _c("fd-alert", {
            staticClass: "mx-3 mt-5",
            attrs: {
              type: "warning",
              label: !!_vm.walkdown.approvalComments
                ? _vm.$t("scaffold-requests.walkdown-declined-with-reason")
                : _vm.$t("scaffold-requests.walkdown-declined"),
              comments: _vm.walkdown.approvalComments,
              "persistent-comments": ""
            }
          })
        : _vm.workOrderIsOnHold
        ? _c("fd-alert", {
            staticClass: "mx-3 mt-5",
            attrs: {
              type: "warning",
              label: _vm.$t("scheduler.on-hold-reason-extended"),
              comments: _vm.workOrder.workOrderStatusDetail,
              "persistent-comments": ""
            }
          })
        : _vm.workOrderIsCancelled
        ? _c("fd-alert", {
            staticClass: "mx-3 mt-5",
            attrs: {
              type: "error",
              label: _vm.$t("scheduler.cancellation-reason-extended"),
              comments: _vm.workOrder.workOrderStatusDetail,
              "persistent-comments": ""
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.workOrderIsUrgent
        ? _c("fd-alert", {
            staticClass: "mx-3 mt-5",
            attrs: {
              type: "urgent",
              label: _vm.$t("scheduler.urgent-status"),
              comments: _vm.workOrder.isUrgentDetail,
              "comment-hint": _vm.lastUrgentDetailChangedSummaryString,
              "persistent-comments": ""
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "fd-details-section pt-3" },
        [
          _vm.workOrder.isLoading
            ? _c(
                "v-row",
                { staticClass: "mx-0", staticStyle: { width: "100%" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pl-0 pr-0", attrs: { cols: "12" } },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "black"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.workOrder.isLoading
            ? _c(
                "v-row",
                { staticClass: "mx-0", staticStyle: { width: "100%" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pl-0 pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mx-0", staticStyle: { width: "100%" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "fd-remove-left-padding-cols-below pl-0",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "mx-0 px-0 fd-subheader-with-control",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: {
                                        "align-self": "end",
                                        cols: "12",
                                        sm: "4"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-subheader",
                                        {
                                          staticClass: "px-0",
                                          staticStyle: {
                                            "font-size": "1em",
                                            height: "32px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "scheduler.scaffold-request-request-details"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.showEditControls
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "8"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              class: [
                                                _vm.workOrder.isUrgent
                                                  ? "fd-hot-job-latest-stamp-container"
                                                  : "",
                                                "d-flex",
                                                "justify-sm-end"
                                              ]
                                            },
                                            [
                                              _c("v-switch", {
                                                staticClass:
                                                  "fd-remove-messages",
                                                attrs: {
                                                  label: _vm.$t(
                                                    "scheduler.is-urgent"
                                                  ),
                                                  "input-value":
                                                    _vm.workOrder.isUrgent,
                                                  disabled:
                                                    _vm.editControlsDisabled
                                                },
                                                on: {
                                                  change: v =>
                                                    _vm.$emit(
                                                      "change:isurgent",
                                                      v
                                                    )
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function() {
                                                        return [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "scheduler.is-urgent"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticStyle: {
                                                                "padding-left":
                                                                  "6px !important"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        fas fa-fire-alt\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1444309338
                                                )
                                              }),
                                              _vm._v(" "),
                                              _vm.workOrder.isUrgent
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fd-hot-job-latest-stamp"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.lastUrgentValueChangedSummaryString
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-0 pl-0",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c("v-divider", {
                                    staticClass: "mx-0 mt-0 px-0"
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-0 pl-0 pt-2 pb-3",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.request-type-column-header"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: {
                                          value: _vm.$t(
                                            `scaffold-requests.types.${_vm.workOrder.requestType}`
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.request-status-column-header"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: { value: _vm.requestStatusName }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("scheduler.request-sub-type")
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: {
                                          value: _vm.$t(
                                            `scaffold-requests.sub-types.${_vm.workOrder.requestSubType}`
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.work-order-status-column-header"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: {
                                          value: _vm.workOrderStatusName
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.request-number-column-header"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: {
                                          value: _vm.workOrder.requestNumber
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.status-change-date"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: {
                                          value:
                                            _vm.formattedLastStatusChangeDate
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.wo-number-column-header"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: {
                                          value: _vm.workOrder.internalNumber
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.status-changed-by"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      !!_vm.workOrder.lastStatusChangedBy
                                        ? _c("fd-value-display", {
                                            attrs: {
                                              value:
                                                _vm.workOrder
                                                  .lastStatusChangedBy
                                            }
                                          })
                                        : _c("fd-value-display", {
                                            attrs: {
                                              value: _vm.$lookup.personBySecurityID(
                                                _vm.workOrder
                                                  .lastStatusChangedByID
                                              )
                                            }
                                          })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("scheduler.scaffold-number")
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: {
                                          value: _vm.workOrder.scaffoldNumber
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.assigned-contractor-long"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      !!_vm.workOrder.assignedContractorName
                                        ? _c("fd-value-display", {
                                            attrs: {
                                              value:
                                                _vm.workOrder
                                                  .assignedContractorName
                                            }
                                          })
                                        : _c("fd-value-display", {
                                            attrs: {
                                              value: _vm.$lookup.contractor(
                                                _vm.workOrder
                                                  .assignedContractorID
                                              )
                                            }
                                          })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "6", sm: "3" } },
                                        [_c("strong")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c("v-col", {
                                        attrs: { cols: "6", sm: "3" }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("scheduler.coordinator")
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      !!_vm.workOrder.coordinatorName
                                        ? _c("fd-value-display", {
                                            attrs: {
                                              value:
                                                _vm.workOrder.coordinatorName
                                            }
                                          })
                                        : _c("fd-value-display", {
                                            attrs: {
                                              value: _vm.$lookup.person(
                                                _vm.workOrder.coordinatorID
                                              )
                                            }
                                          })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.required-date-column-header"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("fd-value-display", {
                                        staticStyle: { display: "inline" },
                                        attrs: {
                                          value: _vm.formattedRequiredDate
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.requiredDateDifference
                                        ? _c("strong", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.requiredDateDifference
                                                ) +
                                                "\n                "
                                            )
                                          ])
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("scheduler.general-foreman")
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      !!_vm.workOrder.generalForemanName
                                        ? _c("fd-value-display", {
                                            attrs: {
                                              value:
                                                _vm.workOrder.generalForemanName
                                            }
                                          })
                                        : _c("fd-value-display", {
                                            attrs: {
                                              value: _vm.$lookup.person(
                                                _vm.workOrder.generalForemanID
                                              )
                                            }
                                          })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.approved-required-date-column-header"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: {
                                          value:
                                            _vm.formattedApprovedRequiredDate
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.$t("scheduler.foreman"))
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      !!_vm.workOrder.foremanName
                                        ? _c("fd-value-display", {
                                            attrs: {
                                              value: _vm.workOrder.foremanName
                                            }
                                          })
                                        : _c("fd-value-display", {
                                            attrs: {
                                              value: _vm.$lookup.person(
                                                _vm.workOrder.foremanID
                                              )
                                            }
                                          })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.required-until-date-column-header"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: {
                                          value: _vm.formattedRequiredUntilDate
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "6", sm: "3" } },
                                        [_c("strong")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c("v-col", {
                                        attrs: { cols: "6", sm: "3" }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _vm.workOrder.isPlanned !== null
                                        ? _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduler.requested-date-column-header"
                                                )
                                              )
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _vm.workOrder.isPlanned !== null
                                        ? _c("fd-value-display", {
                                            attrs: {
                                              value: _vm.formattedRequestDate
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "6", sm: "3" } },
                                        [_c("strong")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c("v-col", {
                                        attrs: { cols: "6", sm: "3" }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.submitted-date-column-header"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: {
                                          value: _vm.formattedSubmittedDate
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.start-date-column-header"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: { value: _vm.formattedStartDate }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "6", sm: "3" } },
                                        [_c("strong")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c("v-col", {
                                        attrs: { cols: "6", sm: "3" }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.completed-date-column-header"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: {
                                          value: _vm.formattedCompletedDate
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.requesting-contractor-column-header"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      !!_vm.workOrder.requestingContractorName
                                        ? _c("fd-value-display", {
                                            attrs: {
                                              value:
                                                _vm.workOrder
                                                  .requestingContractorName
                                            }
                                          })
                                        : _c("fd-value-display", {
                                            attrs: {
                                              value: _vm.$lookup.contractor(
                                                _vm.workOrder
                                                  .requestingContractorID
                                              )
                                            }
                                          })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.$t("scheduler.priority"))
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: { value: _vm.workOrder.priority }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.requesting-employee"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      !!_vm.workOrder.requestingEmployeeName
                                        ? _c("fd-value-display", {
                                            attrs: {
                                              value:
                                                _vm.workOrder
                                                  .requestingEmployeeName
                                            }
                                          })
                                        : _c("fd-value-display", {
                                            attrs: {
                                              value: _vm.$lookup.person(
                                                _vm.workOrder
                                                  .requestingEmployeeID
                                              )
                                            }
                                          })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _vm.workOrder.isPlanned !== null
                                        ? _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("scheduler.planned")
                                              )
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _vm.workOrder.isPlanned !== null
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.workOrder.isPlanned
                                                  ? _vm.$t("scheduler.planned")
                                                  : _vm.$t(
                                                      "scheduler.not-planned"
                                                    )
                                              )
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.request-submitted-by-column-header"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      !!_vm.workOrder.requestSubmitterName
                                        ? _c("fd-value-display", {
                                            attrs: {
                                              value:
                                                _vm.workOrder
                                                  .requestSubmitterName
                                            }
                                          })
                                        : _c("fd-value-display", {
                                            attrs: {
                                              value: _vm.$lookup.personBySecurityID(
                                                _vm.workOrder.requestSubmittedBy
                                              )
                                            }
                                          })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.$t("scheduler.progress"))
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: { value: _vm.workOrder.progress }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.$t("scheduler.discipline"))
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      !!_vm.workOrder.disciplineName
                                        ? _c("fd-value-display", {
                                            attrs: {
                                              value:
                                                _vm.workOrder.disciplineName
                                            }
                                          })
                                        : _c("fd-value-display", {
                                            attrs: {
                                              value: _vm.$lookup.discipline(
                                                _vm.workOrder.disciplineID
                                              )
                                            }
                                          })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "6", sm: "3" } },
                                        [_c("strong")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c("v-col", {
                                        attrs: { cols: "6", sm: "3" }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.$t("scheduler.iwp")))
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      !!_vm.workOrder.workPackageNames &&
                                      _vm.workOrder.workPackageNames.length > 0
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              _vm.workOrder.workPackageNames,
                                              function(iwp, key) {
                                                return _c("div", { key: key }, [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(iwp) +
                                                      "\n                  "
                                                  )
                                                ])
                                              }
                                            ),
                                            0
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "fd-item-placeholder"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("common.not-available")
                                                )
                                              )
                                            ]
                                          )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mx-0", staticStyle: { width: "100%" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "fd-remove-left-padding-cols-below pl-0",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-0 px-0",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { align: "center", cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-subheader",
                                        {
                                          staticClass: "pa-0 mt-3",
                                          staticStyle: {
                                            "font-size": "1em",
                                            height: "32px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "scheduler.scaffold-request-work-requirements-sub-header"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-0 pl-0",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c("v-divider", {
                                    staticClass: "mx-0 mt-0 px-0"
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-0 pl-0 pt-2 pb-3",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.$t("scheduler.area")))
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "9" } },
                                    [
                                      !!_vm.workOrder.areaName
                                        ? _c("fd-value-display", {
                                            attrs: {
                                              value: _vm.workOrder.areaName
                                            }
                                          })
                                        : _c("fd-value-display", {
                                            attrs: {
                                              value: _vm.$lookup.location(
                                                _vm.workOrder.areaID
                                              )
                                            }
                                          })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.$t("scheduler.sub-area"))
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "9" } },
                                    [
                                      !!_vm.workOrder.subAreaName
                                        ? _c("fd-value-display", {
                                            attrs: {
                                              value: _vm.workOrder.subAreaName
                                            }
                                          })
                                        : _c("fd-value-display", {
                                            attrs: {
                                              value: _vm.$lookup.location(
                                                _vm.workOrder.subAreaID
                                              )
                                            }
                                          })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("scheduler.site-contact")
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "9" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: {
                                          value: _vm.workOrder.siteContact
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.$t("scheduler.location"))
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "9" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: {
                                          value:
                                            _vm.workOrder.specificWorkLocation
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "3" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "scheduler.scaffold-request-work-description"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", sm: "9" } },
                                    [
                                      _c("fd-value-display", {
                                        attrs: {
                                          value:
                                            _vm.workOrder
                                              .detailedWorkDescription
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mx-0", staticStyle: { width: "100%" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "fd-remove-left-padding-cols-below pl-0",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-0 px-0",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { align: "center", cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-subheader",
                                        {
                                          staticClass: "pa-0 mt-3",
                                          staticStyle: {
                                            "font-size": "1em",
                                            height: "32px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "scheduler.scaffold-request-notes-sub-header"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-0 pl-0",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c("v-divider", {
                                    staticClass: "mx-0 mt-0 px-0"
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-0 pl-0 pt-2 pb-3",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "px-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("fd-value-display", {
                                        attrs: { value: _vm.workOrder.notes }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.hideScopeValues || _vm.hideScopeValues == "false"
                        ? _c("fd-work-order-scope-details", {
                            staticClass:
                              "fd-expanding-table-single-details-section",
                            attrs: { workOrder: _vm.workOrder }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !!_vm.walkdown
                        ? _c(
                            "v-row",
                            {
                              staticClass: "mx-0",
                              staticStyle: { width: "100%" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "fd-remove-left-padding-cols-below pl-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-0 px-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { align: "center", cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-subheader",
                                            {
                                              staticClass: "pa-0 mt-3",
                                              staticStyle: {
                                                "font-size": "1em",
                                                height: "32px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "scheduler.scaffold-request-walk-down"
                                                  )
                                                )
                                              ),
                                              _vm.walkdown.isHardBarricade
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                     | " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "scheduler.walkdown-hard-barricade"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-0 px-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("v-divider", {
                                        staticClass: "mx-0 mt-0 px-0"
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  !!_vm.walkdown.submitterName ||
                                  !!_vm.walkdown.submittedBy
                                    ? _c(
                                        "v-row",
                                        {
                                          staticClass: "mx-0 px-0 pt-2 pb-3",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scheduler.walkdown-submitted-by"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "10" } },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  !!_vm.walkdown.submitterName
                                                    ? _c("fd-value-display", {
                                                        attrs: {
                                                          value:
                                                            _vm.walkdown
                                                              .submitterName
                                                        }
                                                      })
                                                    : _c("fd-value-display", {
                                                        attrs: {
                                                          value: _vm.$lookup.personBySecurityID(
                                                            _vm.walkdown
                                                              .submittedBy
                                                          )
                                                        }
                                                      })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-0 px-0 pt-2 pb-3",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6", sm: "2" } },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduler.walkdown-notes"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6", sm: "10" } },
                                        [
                                          _c("fd-value-display", {
                                            attrs: { value: _vm.walkdown.notes }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.workOrder.isModifyRequest &&
                                  !!_vm.workOrder.currentUserPermissions &&
                                  !!_vm.workOrder.currentUserPermissions
                                    .canViewEstimateDetails
                                    ? _c(
                                        "v-row",
                                        {
                                          staticClass: "mx-0 px-0 pt-2 pb-3",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4", sm: "2" } },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scheduler.walkdown-crew-size"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4", sm: "2" } },
                                            [
                                              _c("fd-value-display", {
                                                attrs: {
                                                  value: _vm.walkdown.crewSize
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4", sm: "2" } },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scheduler.walkdown-modification-hours"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4", sm: "2" } },
                                            [
                                              _c("fd-value-display", {
                                                attrs: {
                                                  value:
                                                    _vm.walkdown
                                                      .modificationHours
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : !_vm.workOrder.isModifyRequest
                                    ? _c(
                                        "v-row",
                                        {
                                          staticClass: "mx-0 px-0 pt-2 pb-3",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scheduler.walkdown-length"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              _c("fd-value-display", {
                                                attrs: {
                                                  value: _vm.walkdown.length
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scheduler.walkdown-bay-length-name"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              !!_vm.walkdown.bayLengthName
                                                ? _c("fd-value-display", {
                                                    attrs: {
                                                      value:
                                                        _vm.walkdown
                                                          .bayLengthName
                                                    }
                                                  })
                                                : _c("fd-value-display", {
                                                    attrs: {
                                                      value: _vm.$lookup.scaffoldBayLength(
                                                        _vm.walkdown
                                                          .scaffoldBayLengthID
                                                      )
                                                    }
                                                  })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scheduler.walkdown-length-bay-count"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              _vm.walkdown.lengthBayCount
                                                ? _c("div", [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.walkdown
                                                            .lengthBayCount
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ])
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fd-item-placeholder"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common.not-available"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          !_vm.walkdown.isHardBarricade
                                            ? _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "col-12 pa-0 ma-0"
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "scheduler.walkdown-width"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      _vm.walkdown.width
                                                        ? _c("div", [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.walkdown
                                                                    .width
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ])
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "fd-item-placeholder"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.not-available"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "scheduler.walkdown-bay-width-name"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      !!_vm.walkdown
                                                        .bayWidthName
                                                        ? _c(
                                                            "fd-value-display",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  _vm.walkdown
                                                                    .bayWidthName
                                                              }
                                                            }
                                                          )
                                                        : _c(
                                                            "fd-value-display",
                                                            {
                                                              attrs: {
                                                                value: _vm.$lookup.scaffoldBayWidth(
                                                                  _vm.walkdown
                                                                    .scaffoldBayWidthID
                                                                )
                                                              }
                                                            }
                                                          )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "scheduler.walkdown-width-bay-count"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      _vm.walkdown.widthBayCount
                                                        ? _c("div", [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.walkdown
                                                                    .widthBayCount
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ])
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "fd-item-placeholder"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.not-available"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "col-12 pa-0 ma-0"
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "scheduler.walkdown-bay-width-name"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      !!_vm.walkdown
                                                        .bayWidthName
                                                        ? _c(
                                                            "fd-value-display",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  _vm.walkdown
                                                                    .bayWidthName
                                                              }
                                                            }
                                                          )
                                                        : _c(
                                                            "fd-value-display",
                                                            {
                                                              attrs: {
                                                                value: _vm.$lookup.scaffoldBayWidth(
                                                                  _vm.walkdown
                                                                    .scaffoldBayWidthID
                                                                )
                                                              }
                                                            }
                                                          )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "6",
                                                            sm: "2"
                                                          }
                                                        },
                                                        [_c("strong")]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                                    ? _c("v-col", {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "2"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "6",
                                                            sm: "2"
                                                          }
                                                        },
                                                        [_c("strong")]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                                    ? _c("v-col", {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "2"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                          _vm._v(" "),
                                          !_vm.walkdown.isHardBarricade
                                            ? _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "col-12 pa-0 ma-0"
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "scheduler.walkdown-height"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      _vm.walkdown.height
                                                        ? _c("div", [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.walkdown
                                                                    .height
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ])
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "fd-item-placeholder"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.not-available"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "scheduler.walkdown-bay-height-name"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      !!_vm.walkdown
                                                        .bayHeightName
                                                        ? _c(
                                                            "fd-value-display",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  _vm.walkdown
                                                                    .bayHeightName
                                                              }
                                                            }
                                                          )
                                                        : _c(
                                                            "fd-value-display",
                                                            {
                                                              attrs: {
                                                                value: _vm.$lookup.scaffoldBayHeight(
                                                                  _vm.walkdown
                                                                    .scaffoldBayHeightID
                                                                )
                                                              }
                                                            }
                                                          )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "6",
                                                            sm: "2"
                                                          }
                                                        },
                                                        [_c("strong")]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                                    ? _c("v-col", {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "2"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.walkdown.isHardBarricade
                                            ? _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "col-12 pa-0 ma-0"
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "scheduler.walkdown-access-type"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              !_vm.walkdown
                                                                .accessType
                                                                ? _vm.$t(
                                                                    "common.none"
                                                                  )
                                                                : _vm.$t(
                                                                    "scheduler.walkdown-ladder-access"
                                                                  )
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "scheduler.walkdown-deck-levels"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      _vm.walkdown.deckLevels
                                                        ? _c("div", [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.walkdown
                                                                    .deckLevels
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ])
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "fd-item-placeholder"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.not-available"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "6",
                                                            sm: "2"
                                                          }
                                                        },
                                                        [_c("strong")]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                                    ? _c("v-col", {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "2"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "col-12 pa-0 ma-0"
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "scheduler.walkdown-barricade-gates"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "2"
                                                      }
                                                    },
                                                    [
                                                      _vm.walkdown
                                                        .barricadeGates
                                                        ? _c("div", [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.walkdown
                                                                    .barricadeGates
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ])
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "fd-item-placeholder"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.not-available"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "6",
                                                            sm: "2"
                                                          }
                                                        },
                                                        [_c("strong")]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                                    ? _c("v-col", {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "2"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "6",
                                                            sm: "2"
                                                          }
                                                        },
                                                        [_c("strong")]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                                    ? _c("v-col", {
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "2"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-0 px-0 pt-2 pb-3",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      !_vm.walkdown.isHardBarricade
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scheduler.walkdown-type-modifier"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.walkdown.isHardBarricade
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              !!_vm.walkdown.scaffoldTypeName
                                                ? _c("fd-value-display", {
                                                    attrs: {
                                                      value:
                                                        _vm.walkdown
                                                          .scaffoldTypeName
                                                    }
                                                  })
                                                : _c("fd-value-display", {
                                                    attrs: {
                                                      value: _vm.$lookup.scaffoldTypeModifier(
                                                        _vm.walkdown
                                                          .scaffoldTypeModifierID
                                                      )
                                                    }
                                                  })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.walkdown.isHardBarricade
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scheduler.walkdown-elevation-modifier"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.walkdown.isHardBarricade
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              !!_vm.walkdown
                                                .elevationModifierName
                                                ? _c("fd-value-display", {
                                                    attrs: {
                                                      value:
                                                        _vm.walkdown
                                                          .elevationModifierName
                                                    }
                                                  })
                                                : _c("fd-value-display", {
                                                    attrs: {
                                                      value: _vm.$lookup.scaffoldElevationModifier(
                                                        _vm.walkdown
                                                          .scaffoldElevationModifierID
                                                      )
                                                    }
                                                  })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.walkdown.isHardBarricade
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scheduler.walkdown-height-modifier"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.walkdown.isHardBarricade
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              !!_vm.walkdown.heightModifierName
                                                ? _c("fd-value-display", {
                                                    attrs: {
                                                      value:
                                                        _vm.walkdown
                                                          .heightModifierName
                                                    }
                                                  })
                                                : _c("fd-value-display", {
                                                    attrs: {
                                                      value: _vm.$lookup.scaffoldHeightModifier(
                                                        _vm.walkdown
                                                          .scaffoldHeightModifierID
                                                      )
                                                    }
                                                  })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6", sm: "2" } },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduler.walkdown-congestion-factor"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6", sm: "2" } },
                                        [
                                          !!_vm.walkdown.congestionFactorName
                                            ? _c("fd-value-display", {
                                                attrs: {
                                                  value:
                                                    _vm.walkdown
                                                      .congestionFactorName
                                                }
                                              })
                                            : _c("fd-value-display", {
                                                attrs: {
                                                  value: _vm.$lookup.scaffoldCongestionFactor(
                                                    _vm.walkdown
                                                      .scaffoldCongestionFactorID
                                                  )
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      !_vm.walkdown.isHardBarricade
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scheduler.walkdown-internal-modifier"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.walkdown.isHardBarricade
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              !!_vm.walkdown
                                                .internalModifierName
                                                ? _c("fd-value-display", {
                                                    attrs: {
                                                      value:
                                                        _vm.walkdown
                                                          .internalModifierName
                                                    }
                                                  })
                                                : _c("fd-value-display", {
                                                    attrs: {
                                                      value: _vm.$lookup.internalModifier(
                                                        _vm.walkdown
                                                          .internalModifierID
                                                      )
                                                    }
                                                  })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.walkdown.isHardBarricade
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scheduler.walkdown-hoarding-modifier"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.walkdown.isHardBarricade
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "6", sm: "2" } },
                                            [
                                              !!_vm.walkdown
                                                .hoardingModifierName
                                                ? _c("fd-value-display", {
                                                    attrs: {
                                                      value:
                                                        _vm.walkdown
                                                          .hoardingModifierName
                                                    }
                                                  })
                                                : _c("fd-value-display", {
                                                    attrs: {
                                                      value: _vm.$lookup.hoardingModifier(
                                                        _vm.walkdown
                                                          .hoardingModifierID
                                                      )
                                                    }
                                                  })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }