import FDVue from "@fd/lib/vue";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import { VForm } from "@fd/lib/vue/types";
import { mapActions } from "vuex";
import { BulkImportResults, WorkOrderImportSettings, workOrderService } from "../../services";
import store from "../../store";
import rules from "@fd/lib/vue/rules";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import userAccess from "../../dataMixins/userAccess";

export default FDVue.extend({
  mixins: [rules, errorHandling, userAccess],

  props: {
    closeAction: Function
  },

  data: () => ({
    processing: false,
    downloading: false,
    downloadingerrors: false,
    uploading: false,

    file: null as any,

    returnFile: null as any,
    importResults: null as BulkImportResults | null,

    workOrderImportColumnMapping: {} as WorkOrderImportSettings
  }),

  methods: {
    async loadColumnMapping() {
      this.workOrderImportColumnMapping = await workOrderService.getDefaultImportSettings();
    },
    async uploadFiles() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = null;
      this.importResults = null;
      this.returnFile = null;

      if (!(this.$refs.uploadForm as VForm).validate()) {
        console.log((this.$refs.uploadForm as VForm).validate());
        return;
      }

      this.processing = true;
      this.uploading = true;

      var isSuccessful = await this.validateFile();
      if (isSuccessful) isSuccessful = await this.uploadFile();

      this.processing = false;
      this.uploading = false;

      if (isSuccessful) this.showSnackbarAndCloseDialog();
    },
    showSnackbarAndCloseDialog() {
      var snackbarPayload = {
        text: this.$t("common.bulk-upload-results.import-success"),
        type: "success",
        undoCallback: null
      };
      store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      // this.$props.closeAction(true);
    },

    async validateFile(): Promise<boolean> {
      console.log("validateFile()");

      var isSuccessful = false;
      try {
        this.returnFile = await workOrderService.validateWorkOrderList(
          this.workOrderImportColumnMapping.identifyingColumnFriendlyName || "",
          this.workOrderImportColumnMapping.workOrderNumber || "",
          this.workOrderImportColumnMapping.startDate || "",
          this.workOrderImportColumnMapping.requiredDate || "",
          this.file
        );
        isSuccessful = this.returnFile == null;
      } catch (error) {
        this.handleError(error, "common.bulk-upload-results.sp2-import-error", {
          422: {
            errorMessageKey: "common.bulk-upload-results.column-mismatch-error"
          }
        });
      } finally {
        console.log("\tisSuccessful: " + isSuccessful);
        return isSuccessful;
      }
    },
    async uploadFile(): Promise<boolean> {
      console.log("uploadFile()");
      var isSuccessful = false;
      try {
        // We only care about the results of the last call, the SP2 upload.
        this.importResults = await workOrderService.uploadWorkOrderList(
          this.workOrderImportColumnMapping.identifyingColumnFriendlyName || "",
          this.workOrderImportColumnMapping.workOrderNumber || "",
          this.workOrderImportColumnMapping.startDate || "",
          this.workOrderImportColumnMapping.requiredDate || "",
          this.file
        );
        isSuccessful = this.importResults.successful;
      } catch (error) {
        this.handleError(error, "common.bulk-upload-results.sp2-import-error", {
          422: {
            errorMessageKey: "common.bulk-upload-results.column-mismatch-error"
          }
        });
      } finally {
        console.log("\tisSuccessful: " + isSuccessful);
        return isSuccessful;
      }
    },
    async downloadReturnFile() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = null;
      this.importResults = null;

      if (!this.returnFile) {
        this.inlineMessage.message = this.$t("common.bulk-upload-general.file-does-not-exist");
        this.inlineMessage.type = "warning";
        return;
      }

      this.downloadingerrors = true;
      this.processing = true;
      try {
        downloadBlob(this.returnFile, this.file.name);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
        this.downloadingerrors = false;
      }
    },
    async downloadTemplate() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = null;
      this.importResults = null;
      this.returnFile = null;

      this.processing = true;
      this.downloading = true;
      try {
        let workOrderListTemplate = await workOrderService.downloadWorkOrderListTemplate();
        downloadBlob(workOrderListTemplate, "WorkOrderListTemplate.xlsx");
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
        this.downloading = false;
      }
    },
    async downloadFullWorkOrdersList() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = null;
      this.importResults = null;
      this.returnFile = null;

      this.processing = true;
      this.downloading = true;
      try {
        let workOrderListTemplate = await workOrderService.downloadWorkOrderList();
        downloadBlob(workOrderListTemplate, "WorkOrderList.xlsx");
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
        this.downloading = false;
      }
    },
    ...mapActions({})
  },
  watch: {
    processing(value) {
      this.$emit("processingUpdated", value);
    }
  },

  created: async function() {
    this.processing = true;
    await this.loadColumnMapping();
    this.processing = false;
  }
});

