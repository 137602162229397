import FDVue from "@fd/lib/vue";
import { TranslateResult } from "vue-i18n";
import { TimesheetStatus, TimesheetStatusLogWithDetails } from "../../../services";
import * as DateUtil from "@fd/lib/client-util/datetime";

function compareLogs(a: TimesheetStatusLogWithDetails, b: TimesheetStatusLogWithDetails): number {
  // being DTOs instead of DateTimes has these returned as a string instead of a date object
  return new Date(b.changed!).getTime() - new Date(a.changed!).getTime();
}

export default FDVue.extend({
  name: "fd-timesheet-status-history-form",
  props: ["statusLogs"],
  computed: {
    sortedStatusLogs(): TimesheetStatusLogWithDetails[] {
      let statusLogs = (this.statusLogs ?? []) as TimesheetStatusLogWithDetails[];
      return statusLogs.sort(compareLogs);
    }
  },
  methods: {
    formatLogChangedDate(log: TimesheetStatusLogWithDetails): string {
      if (!log.changed) return "";
      var changedDate = DateUtil.localizedDateTimeString(log.changed);
      return changedDate;
    },
    getLogActionColor(log: TimesheetStatusLogWithDetails): string {
      var actionColor = "";
      if (log.timesheetStatusID == TimesheetStatus.Declined) {
        actionColor = "warning";
      } else if (log.correctionMade) {
        actionColor = "secondary";
      } else if (log.timesheetStatusID == TimesheetStatus.Approved) {
        actionColor = "success";
      } else if (log.timesheetStatusID == TimesheetStatus.Cancelled) {
        actionColor = "error";
      }
      return actionColor;
    },
    getLogActionIcon(log: TimesheetStatusLogWithDetails): string {
      var actionIcon = "fa-paper-plane";
      if (log.timesheetStatusID == TimesheetStatus.Declined) {
        actionIcon = "fas fa-exclamation-circle";
      } else if (log.correctionMade) {
        actionIcon = "fal fa-highlighter";
      } else if (log.timesheetStatusID == TimesheetStatus.Approved) {
        actionIcon = "far fa-check";
      } else if (log.timesheetStatusID == TimesheetStatus.Cancelled) {
        actionIcon = "far fa-ban";
      }
      return actionIcon;
    },
    getLogActionString(log: TimesheetStatusLogWithDetails): string | TranslateResult {
      var changedBy = log.changedByName;
      if (log.timesheetStatusID == TimesheetStatus.Submitted) {
        return this.$t("timesheets.approval.submitted-log-action-description", [changedBy]);
      } else if (log.correctionMade) {
        return this.$t("timesheets.approval.corrected-log-action-description", [changedBy]);
      } else if (log.timesheetStatusID == TimesheetStatus.Approved) {
        return this.$t("timesheets.approval.approved-log-action-description", [changedBy]);
      } else if (log.timesheetStatusID == TimesheetStatus.Declined) {
        return this.$t("timesheets.approval.declined-log-action-description", [changedBy]);
      } else if (log.timesheetStatusID == TimesheetStatus.Cancelled) {
        return this.$t("timesheets.approval.cancelled-log-action-description", [changedBy]);
      }
      return "";
    },
    summaryStringForStatusLog(log: TimesheetStatusLogWithDetails): string {
      return [this.getLogActionString(log), this.formatLogChangedDate(log)].join(" - ");
    },
    showLogComments(log: TimesheetStatusLogWithDetails): boolean {
      return !log.correctionMade && !!log.comments;
    }
  }
});

