import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { PersonClassificationLogWithDetails } from "../../../services";

const PersonClassificationHistoryDialog = FDVue.extend({
  name: "fd-person-classification-history-dialog",

  mixins: [dialogSupport],

  components: {
    "fd-log-history-form": () => import("../forms/LogHistoryForm.vue")
  },

  data: function() {
    return {
      classificationLogs: [] as PersonClassificationLogWithDetails[]
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return ["summaryStringForLog"];
    }
  },

  methods: {
    async open(classificationLogs: PersonClassificationLogWithDetails[] | undefined) {
      this.classificationLogs = classificationLogs ?? [];
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.cancelDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    summaryStringForLog(log: PersonClassificationLogWithDetails) {
      return this.$t("people.existing-person.classification-log-summary", [
        log.classificationName ?? this.$t("common.none"),
        log.changedByName ?? this.$t("common.unknown")
      ]);
    }
  },

  created: async function() {}
});

export default PersonClassificationHistoryDialog;

export async function showPersonClassificationHistoryDialog(
  classificationLogs: PersonClassificationLogWithDetails[] | undefined
): Promise<boolean> {
  let dialog = createDialog(PersonClassificationHistoryDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(classificationLogs);
}

