import FDVue from "@fd/lib/vue";

import fileHandling, { isFilePreviewable, FileData } from "@fd/lib/vue/mixins/fileHandling";

// Import vue-pintura
const PinturaEditor = require("@fd/lib/pintura/vue-pintura/index.js").PinturaEditor;
const PinturaEditorModal = require("@fd/lib/pintura/vue-pintura/index.js").PinturaEditorModal;
const PinturaEditorOverlay = require("@fd/lib/pintura/vue-pintura/index.js").PinturaEditorOverlay;

// Import pintura
import {
  getEditorDefaults,
  // editor
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  locale_en_gb,

  // plugins
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_filter,
  plugin_filter_defaults,
  plugin_filter_locale_en_gb,
  plugin_finetune,
  plugin_finetune_defaults,
  plugin_finetune_locale_en_gb,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb
} from "pintura";

// setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);

export default FDVue.extend({
  name: "fd-pintura-test",

  mixins: [fileHandling],

  components: {
    PinturaEditor,
    PinturaEditorModal,
    PinturaEditorOverlay,
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue")
  },
  data() {
    return {
      // defaults
      editorProps: getEditorDefaults(),
      // editorProps: {
      //   imageReader: createDefaultImageReader(),
      //   imageWriter: createDefaultImageWriter(),
      //   shapePreprocessor: createDefaultShapePreprocessor(),
      //   ...plugin_finetune_defaults,
      //   ...plugin_filter_defaults,
      //   ...markup_editor_defaults,
      //   locale: {
      //     ...locale_en_gb,
      //     ...plugin_crop_locale_en_gb,
      //     ...plugin_finetune_locale_en_gb,
      //     ...plugin_filter_locale_en_gb,
      //     ...plugin_annotate_locale_en_gb,
      //     ...markup_editor_locale_en_gb
      //   }
      // },

      // FD Image Editing
      imageName: undefined as string | undefined,
      // imageSource: undefined as string | undefined,
      imageSource: "/assets/img/pintura_test_image.jpeg" as string | undefined,

      // inline state
      inlineSrc: "/assets/img/pintura_test_image.jpeg",
      inlineResult: undefined as any,

      // modal state
      modalSrc: "/assets/img/pintura_test_image.jpeg",
      modalVisible: false,
      modalResult: undefined as any,

      // overlay state
      overlaySrc: "/assets/img/pintura_test_image.jpeg",
      overlayVisible: false,
      overlayResult: {
        imageState: undefined as any,
        imagePreview: undefined as any
      }
    };
  },

  computed: {},

  methods: {
    async addFile(file: any) {
      var fileData = await this.optimizedFileDataForUpload(file);
      if (!fileData) return;

      this.imageName = fileData.name;
      this.imageSource = this.covertFileToDataURL(fileData.file);
      this.modalSrc = this.imageSource;
      this.inlineSrc = this.imageSource;
      this.overlaySrc = this.imageSource;
    },

    handleEdit(res: any) {
      this.inlineResult = URL.createObjectURL(res);
    },

    // inline
    handleInlineLoad: function(res: any) {
      console.log("inline load", res);
    },
    handleInlineProcess: function(res: any) {
      console.log("inline process", res);
      this.inlineResult = URL.createObjectURL(res.dest);
    },

    inlineButtonClick() {
      let inline = this.$refs.inline;
      (inline as any).editor.processImage();
    },

    // modal
    handleModalLoad: function(res: any) {
      console.log("modal load", res);
    },
    handleModalShow: function() {
      console.log("modal show");
    },
    handleModalClose: function() {
      console.log("modal close");
    },
    handleModalProcess: function(res: any) {
      console.log("modal process", res);
      this.modalResult = URL.createObjectURL(res.dest);
    },

    // overlay
    handleOverlayLoad: function(res: any) {
      console.log("overlay load", res);
    },
    handleOverlayProcess: function(res: any) {
      console.log("overlay process", res);
      const { imageState, dest } = res;
      this.overlayResult = {
        imagePreview: URL.createObjectURL(dest),
        imageState: imageState
      };
      this.overlayVisible = false;
    }
  }
});
