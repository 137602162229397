import FDVue from "@fd/lib/vue";
import * as DateUtil from "@fd/lib/client-util/datetime";
import workOrderList, {
  ParseWorkOrderWithAllDetails,
  FilteringRequestorContext,
  FilteringContext
} from "../dataMixins/workOrderList";
import { CountSheetReviewStatus, reportService, workOrderService } from "../services";
import { mapMutations } from "vuex";
import { showCountSheetDialog } from "./components/dialogs/CountSheetDialog.vue";
import { FormattedWorkOrder } from "./components/KanbanItem.vue";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import printBlob from "@fd/lib/client-util/printBlob";
import { valueInArray } from "@fd/lib/client-util/array";

export default FDVue.extend({
  name: "fd-work-order-administration",

  mixins: [workOrderList],

  data: function() {
    return {};
  },
  computed: {},

  methods: {
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    async loadWorkOrders() {
      this.allWorkOrders = await (
        await workOrderService.getWorkOrdersRequiringAdminWork(
          this.showArchived,
          this.showArchivedFromDate,
          this.showArchivedToDate
        )
      ).map(x => {
        return {
          ...ParseWorkOrderWithAllDetails(x),
          countSheetStatus:
            !!x.countSheet && !!x.countSheet.reviewStatusID
              ? this.$t(`countsheet.status.${x.countSheet.reviewStatusID}`)
              : this.$t("countsheet.status.not-submitted")
        };
      });
    },

    async showWorkOrderCountSheetDialog(item: FormattedWorkOrder) {
      if (await showCountSheetDialog(item, item.scaffoldID!)) {
        this.reloadTableData();
      }
    },

    async downloadAndPrintWorkOrderAdminReport(reportType: string = "pdf") {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        var workOrdersToPrint = this.visibleWorkOrders;
        if (workOrdersToPrint.length == 0) {
          this.$store.dispatch("SHOW_SNACKBAR", {
            text: this.$t("work-order-admin.printing.no-data-message"),
            type: "info"
          });
          return;
        }

        workOrdersToPrint = workOrdersToPrint.sort((a, b) => {
          // No matter how we sort, we want the urgent at the top
          let urgentA = a.isUrgent ?? false;
          let urgentB = b.isUrgent ?? false;
          if (urgentA != urgentB) {
            return urgentA ? -1 : 1;
          }

          // Urgency is the same, sort by priority ascending, with the lowest number at the top
          var priorityA = a.priority ?? 5;
          var priorityB = b.priority ?? 5;
          if (priorityA != priorityB) {
            return priorityA - priorityB;
          }

          // Priority of the items are the same, sort by required date ascing with the earliest required at the top
          // A lack of required date means it's not required and so goes to the bottom
          var requiredA = a.requiredDate ?? new Date(2999, 12, 31);
          var requiredB = a.requiredDate ?? new Date(2999, 12, 31);
          if (requiredA.getTime() != requiredB.getTime()) {
            return requiredA.getTime() - requiredB.getTime();
          }

          return 0;
        });

        var contractorFilterValue = !!this.selectedContractors?.length
          ? this.allContractors
              .filter(x => valueInArray(x.id!, this.selectedContractors))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var disciplineFilterValue = !!this.selectedDisciplines?.length
          ? this.allDisciplines
              .filter(x => valueInArray(x.id!, this.selectedDisciplines))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var areaFilterValue = !!this.selectedAreas?.length
          ? this.allAreas
              .filter(x => valueInArray(x.id!, this.selectedAreas))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var subAreaFilterValue = !!this.selectedSubAreas?.length
          ? this.allSubAreas
              .filter(x => valueInArray(x.id!, this.selectedSubAreas))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var generalForemanFilterValue = !!this.selectedGeneralForemanIDs?.length
          ? this.allGeneralForemen
              .filter(x => valueInArray(x.id!, this.selectedGeneralForemanIDs))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;
        var foremanFilterValue = !!this.selectedForemanIDs?.length
          ? this.allForemen
              .filter(x => valueInArray(x.id!, this.selectedForemanIDs))
              .map(x => x.name)
              .join(", ")
          : `${this.$t("common.all")}`;

        var blob = await reportService.getWorkOrderAdminPrintoutReportContentWithData(
          workOrdersToPrint,
          reportType,
          contractorFilterValue,
          disciplineFilterValue,
          areaFilterValue,
          subAreaFilterValue,
          generalForemanFilterValue,
          foremanFilterValue,
          DateUtil.localizedDateTimeString(new Date())
        );
        if (reportType == "xls") {
          downloadBlob(blob, "work-order-admin-printout.xlsx");
        } else {
          printBlob(blob, "work-order-admin-printout.pdf", "application/pdf");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },

  created: async function() {
    var requestorFilter: FilteringRequestorContext = !!this.curUserAccess.homeContractorID
      ? "mine"
      : "all";
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = DateUtil.addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "work-order-admin",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: DateUtil.addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      requestTypesForFiltering: [],
      statusesForFiltering: [],
      contractorsForFiltering: [],
      disciplinesForFiltering: [],
      foremanIDsForFiltering: [],
      generalForemanIDsForFiltering: [],
      areasForFiltering: [],
      subAreasForFiltering: [],
      contextForFiltering: {
        requestorFilter: requestorFilter,
        unassignedFilter: true,
        assignedFilter: true,
        showScaffoldRequests: true,
        showMaintenanceRequests: this.curUserAccess.canViewMaintenanceJobs,
        showPaintRequests: this.curUserAccess.canViewPaintJobs,
        showInsulationRequests: this.curUserAccess.canViewInsulationJobs
      } as FilteringContext
    });

    if (this.$route.path == "/administration") {
      this.notifyNewBreadcrumb({
        text: this.$t("work-order-admin.list.title"),
        to: "/administration",
        resetHistory: true
      });
    } else if (this.$route.path == "/countsheetadministration") {
      this.notifyNewBreadcrumb({
        text: this.$t("work-order-admin.list.count-sheet-admin-title"),
        to: "/countsheetadministration",
        resetHistory: true
      });
    }

    this.processing = true;
    try {
      await Promise.all([
        this.loadDisciplines(),
        this.loadCurrentUserDisciplines(),
        this.loadAreas(),
        this.loadSubAreas(),
        this.loadCoordinators(),
        this.loadGeneralForemen(),
        this.loadForemen(),
        this.loadContractors()
      ]);
      this.processing = true;

      this.scaffoldContractors = this.allContractors.filter(x => !!x.isScaffoldCompany);
      this.paintContractors = this.allContractors.filter(x => !!x.isPaintCompany);
      this.insulationContractors = this.allContractors.filter(x => !!x.isInsulationCompany);
      this.maintenanceContractors = this.allContractors.filter(x => !!x.isMaintenanceCompany);

      await this.reloadTableData();

      this.requestorFilterIsMine = false;
    } catch (error) {
      if ((error as any).statusCode == 403) {
        this.inlineMessage.message = "";
      } else {
        this.handleError(error as Error);
      }
    } finally {
      this.processing = false;
    }
  }
});

