import FDVue from "@fd/lib/vue";
export default FDVue.extend({
  name: "fd-login-terms-and-conditions-form",
  props: {
    value: { type: Boolean, default: undefined },

    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    show: { type: Boolean, default: false },
    smallishMobileDevice: { type: Boolean, default: false },
    smallWidthBrowser: { type: Boolean, default: false }
  },
  data: function() {
    return {
      didOpenTermsDialog: false
    };
  },
  methods: {
    openTermsDialog() {
      this.didOpenTermsDialog = true;
      this.$emit("openTermsDialog");
    },
    allowNotificationsChanged(value: boolean, contactMethod: any) {
      this.$emit("change:allowNotifications", value, contactMethod.id);
    }
  }
});

