var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-alert",
    _vm._b(
      {
        class: _vm.computedClass,
        attrs: {
          value: _vm.value,
          "data-cy": !!_vm.cy ? _vm.cy : _vm.dataCy,
          icon: _vm.computedIcon,
          transition: "scale-transition",
          type: _vm.sanitizedType
        },
        on: { input: v => _vm.$emit("input", v) }
      },
      "v-alert",
      _vm.$attrs,
      false
    ),
    [
      _c("div", [_vm._t("body.prepend")], 2),
      _vm._v(" "),
      _c("span", { style: _vm.labelStyle }, [_vm._v(_vm._s(_vm.$attrs.label))]),
      _vm._v(" "),
      !!_vm.$attrs.comments || _vm.persistentComments
        ? _c(
            "div",
            { staticClass: "mt-2 fd-secondary-alert-text" },
            [
              _c("fd-value-display", {
                attrs: {
                  value: _vm.$attrs.comments,
                  "no-value-key": _vm.commentPlaceholderKey,
                  "no-value-style": _vm.commentPlaceholderStyle,
                  "no-value-class": _vm.commentPlaceholderClass
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !!_vm.commentHint && _vm.commentHint.length > 0
        ? _c(
            "div",
            {
              staticClass: "mt-1 fd-alert-comment-hint-text",
              class: _vm.commentHintClass,
              style: _vm.commentHintStyle
            },
            [_vm._v("\n    " + _vm._s(_vm.commentHint) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", [_vm._t("body.append")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }