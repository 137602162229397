import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import {
  FDColumnDirective,
  FDRowNavigateDirective,
  FDTableSortableDirective,
  SortableEvent
} from "@fd/lib/vue/utility/dataTable";
import {
  Classification,
  Contractor,
  CrewEmployeeWithName,
  CrewWithEmployees,
  Person,
  PersonWithDetails
} from "../services";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import { mapActions, mapMutations } from "vuex";
import rules from "@fd/lib/vue/rules";
import userAccess from "../dataMixins/userAccess";
import { GetPersonName, HasName } from "../utils/person";
import { SortCrewEmployees } from "./components/dialogs/CrewDetailsBottomDialog.vue";

type CrewWithEmployeesAndArchived = CrewWithEmployees & { isArchived: boolean };
type PersonWithDetailsAndClassificationName = PersonWithDetails &
  HasName & {
    classificationName: string | undefined;
  };

export default FDVue.extend({
  name: "fd-crew-existing",

  mixins: [serviceErrorHandling, tabbedView, rules, userAccess],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    fdTableSortable: FDTableSortableDirective
  },

  data: function() {
    return {
      slidein: false,
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      deleting: false,
      crew: {} as CrewWithEmployeesAndArchived,

      detailserror: false,
      firstTabKey: `0`,
      detailsTab: {
        tabname: this.$t("contractors.crews.new-crew.steps.details"),
        key: "0",
        visible: true
      } as Tab,
      employeesTab: {
        tabname: this.$t("contractors.crews.new-crew.steps.employees"),
        key: "1",
        visible: false
      } as Tab,
      employeeOrderingTab: {
        tabname: this.$t("contractors.crews.new-crew.steps.employee-ordering"),
        key: "2",
        visible: false
      } as Tab,

      // *** EMPLOYEES ***
      showOnlyIncludedEmployees: false,
      tablesearchemployees: "",
      selectableEmployees: [] as Array<PersonWithDetailsAndClassificationName>
    };
  },

  computed: {
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.employeesTab] as Tab[];
    },
    visiblePeople(): (PersonWithDetails & HasName)[] {
      let visiblePeople = (this.$store.state.users.fullList as PersonWithDetails[])
        .filter(x => x.contractorID == this.crew.contractorID)
        .map(x => ({ ...x, name: GetPersonName(x) }))
        .sort((a, b) => {
          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;
          else return 0;
        });
      return visiblePeople;
    },

    // *** EMPLOYEES ***
    employees: {
      cache: false,
      get(): Array<PersonWithDetailsAndClassificationName> {
        let returnValue = this.selectableEmployees;
        if (this.showOnlyIncludedEmployees)
          returnValue = returnValue.filter(x => this.selectedEmployeeIDs.includes(x.id!));
        return returnValue;
      }
    },

    selectedEmployeeIDs(): string[] {
      return this.crew.employees?.map(e => e.employeeID!) ?? [];
    },

    selectedEmployees(): PersonWithDetailsAndClassificationName[] {
      return this.selectedEmployeeIDs
        .filter(x => !!this.selectableEmployees.find(e => e.id == x))
        .map(x => this.selectableEmployees.find(e => e.id == x)!);
    },

    searchedEmployees(): Array<PersonWithDetailsAndClassificationName> {
      // This is a hack because the employees list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .employeesDataTable as any)?.customFilter;
      if (this.tablesearchemployees && customFilter) {
        return this.employees.filter(
          x =>
            customFilter(x.firstName!, this.tablesearchemployees, x) ||
            customFilter(x.lastName!, this.tablesearchemployees, x) ||
            customFilter(x.employeeCode!, this.tablesearchemployees, x)
        );
      } else {
        return this.employees;
      }
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedEmployeesSelected(): boolean {
      return (
        this.searchedEmployees.findIndex(x => !this.selectedEmployeeIDs.includes(x.id!)) === -1
      );
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedEmployeesSelected(): boolean {
      var searchedEmployees = this.searchedEmployees;
      return (
        searchedEmployees.findIndex(x => this.selectedEmployeeIDs.includes(x.id!)) !== -1 &&
        searchedEmployees.findIndex(x => !this.selectedEmployeeIDs.includes(x.id!)) !== -1
      );
    }
  },

  methods: {
    // *** GLOBAL ***
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT",
      setSelectedTab: "SET_SELECTED_TAB_INDEX_IN_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadContractor: "LOAD_CONTRACTOR",
      loadCrew: "LOAD_CREW",
      updateCrew: "UPDATE_CREW",
      deleteCrew: "DELETE_CREW",
      loadClassifications: "LOAD_CLASSIFICATIONS",
      loadEmployees: "LOAD_USERS"
    }),
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    // DOES NOT manage processing or error message logic

    validate(): boolean {
      this.detailserror = !((this.$refs.detailsform as HTMLFormElement)?.validate() ?? true);
      return !this.detailserror;
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // if (!!this.crew.contractorID && !this.crew.contractorIDs!.includes(this.crew.contractorID)) {
      //   this.crew.contractorIDs!.push(this.crew.contractorID);
      // }

      if (!this.validate()) {
        var message = this.$t("contractors.crews.new-crew.error-message");
        if (this.detailserror)
          message += "\n\t- " + this.$t("contractors.crews.new-crew.steps.details");

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        var archivedDate = null;
        if (this.crew.isArchived && !this.crew.archivedDate) {
          archivedDate = new Date(new Date().toUTCString());
        }
        this.crew.archivedDate = archivedDate;

        await this.$store.dispatch("UPDATE_CREW", {
          ...this.crew
        });
        if (closeOnComplete) {
          // this.$router.push(`/contractors/${this.crew.contractorID}`);
          this.$router.back();
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    async deleteItem() {
      this.processing = true;
      this.deleting = true;
      try {
        await this.deleteCrew(this.crew);
        this.$router.push(`/contractors/${this.crew.contractorID}`);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // this.$router.push(`/contractors/${this.crew.contractorID}`);
      this.$router.back();
    },

    // *** EMPLOYEES ***
    orderForItem(item: Person) {
      return this.crew.employees?.find(x => x.employeeID == item.id)?.order;
    },
    itemIsSelected(item: Person & HasName) {
      return this.selectedEmployeeIDs.includes(item.id!);
    },
    flipEmployeeSelected(item: PersonWithDetailsAndClassificationName & HasName) {
      if (!this.crew.employees) this.crew.employees = [];

      console.log(`flipEmployeeSelected ${item.name}`);
      let employeeSelected = this.itemIsSelected(item);
      if (employeeSelected) {
        let index = this.selectedEmployeeIDs.indexOf(item.id!);
        console.log(` selected.  Remove at index: ${index}`);
        if (index > -1) {
          this.crew.employees!.splice(index, 1);
        }
      } else {
        console.log(` Not Selected.  Add to list`);
        if (!this.crew.employees) this.crew.employees = [];
        this.crew.employees.push({
          name: item.name,
          crewID: this.crew.id,
          employeeID: item.id,
          order: this.crew.employees.length + 1
        } as CrewEmployeeWithName);
      }

      this.crew.employees.forEach((item, index) => {
        let newOrder = index + 1;
        console.log(`  ${item.name}: ${item.order} --> ${newOrder}`);
        item.order = newOrder;
      });
    },

    flipSearchedEmployeeselected() {
      if (!this.crew.employees) this.crew.employees = [];

      let selected = !this.allSearchedEmployeesSelected;
      // let changedEmployeeIDs = [] as string[];
      for (let employee of this.searchedEmployees) {
        let employeeSelected = this.selectedEmployeeIDs.includes(employee.id!);
        if (employeeSelected !== selected) {
          if (!!selected) {
            if (!this.crew.employees) this.crew.employees = [];
            this.crew.employees.push({
              name: employee.name,
              crewID: this.crew.id,
              employeeID: employee.id,
              order: this.crew.employees.length + 1
            } as CrewEmployeeWithName);
          } else {
            let index = this.selectedEmployeeIDs.indexOf(employee.id!);
            if (index > -1) {
              this.selectedEmployeeIDs.splice(index, 1);
            }
          }
        }
      }
      this.crew.employees.forEach((item, index) => {
        let newOrder = index + 1;
        console.log(`  ${item.name}: ${item.order} --> ${newOrder}`);
        item.order = newOrder;
      });
    },
    async dragEnded(e: SortableEvent) {
      if (!this.crew.employees?.length) return;

      let oldIndex = e.oldIndex ?? 0;
      let newIndex = e.newIndex ?? 0;
      if (oldIndex == newIndex) return;

      this.crew.employees.splice(newIndex, 0, ...this.crew.employees.splice(oldIndex, 1));
      console.log(`Employee Indexes`);
      this.crew.employees.forEach((item, index) => {
        let newOrder = index + 1;
        console.log(`  ${item.name}: ${item.order} --> ${newOrder}`);
        item.order = newOrder;
      });
    },
    classificationNameForPerson(person: Person) {
      let allClassifications = this.$store.state.classifications.fullList as Classification[];
      return allClassifications.find(x => x.id == person.classificationID)?.name;
    }
  },

  watch: {
    crew: async function(newValue) {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/contractors") {
        this.notifyNewBreadcrumb({
          text: this.$t("contractors.list-title"),
          to: "/contractors",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        await this.loadContractor(newValue.contractorID);
        var contractor = this.$store.state.contractors.fullList.find(
          (x: any) => x.id == newValue.contractorID
        ) as Contractor;

        this.notifyNewBreadcrumb({
          text: contractor.name,
          to: `/contractors/${newValue.contractorID}`
        });

        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      this.notifyNewBreadcrumb({
        text: newValue.name,
        to: `/crews/${this.$route.params.id}`
      });
    }
  },

  created: async function() {
    this.processing = true;

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    this.setFilteringContext({
      context: "crews",
      parentalContext: "contractors-existing",
      searchStringForFiltering: "",
      selectedTab: this.firstTabKey
    });

    try {
      await Promise.all([
        this.loadClassifications(),
        this.loadEmployees(),
        this.loadCrew(this.$route.params.id)
      ]);
      var crew = this.$store.state.crews.fullList.find(
        (x: any) => x.id == this.$route.params.id
      ) as CrewWithEmployees;
      this.crew = {
        ...crew,
        isArchived: !!crew.archivedDate
      } as CrewWithEmployeesAndArchived;

      this.crew.employees = this.crew.employees?.filter(
        x => this.visiblePeople.findIndex(e => e.id == x.employeeID) !== -1
      );

      SortCrewEmployees(this.crew.employees);

      this.selectableEmployees = (this.$store.state.users.fullList as PersonWithDetails[])
        .filter(x => x.contractorID == this.crew.contractorID)
        .map(
          x =>
            ({
              ...x,
              name: GetPersonName(x, true, false),
              classificationName: this.classificationNameForPerson(x)
            } as PersonWithDetailsAndClassificationName)
        );
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

