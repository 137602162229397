import VueI18n from "vue-i18n";
import FDVue from "@fd/lib/vue";
import rules from "@fd/lib/vue/rules";
import { JobTypes, JobWithData, ScaffoldRequestTypes } from "../services";
import userAccess from "./userAccess";

export const job = FDVue.extend({
  mixins: [rules, userAccess],
  data: function() {
    return {
      isSavingDraft: true,
      job: {} as JobWithData
    };
  },
  computed: {
    // Override this in the file using this mixin. Used to calculate rules.
    isScaffoldJob(): boolean {
      return this.job.jobTypeID == JobTypes.Scaffold;
    },
    isScaffoldErectJob(): boolean {
      return this.isScaffoldJob && this.job.requestType == ScaffoldRequestTypes.Erect;
    },
    requiredDate(): Date | null | undefined {
      return this.job.requiredDate;
    },
    hasTag(): boolean {
      return !!this.job.scaffoldID?.length;
    },
    requiredUntilDateRules(): ((value: any) => boolean | VueI18n.TranslateResult)[] {
      let requiredUntilLaterThanRequired = (value: any) => {
        return (
          !value ||
          !this.requiredDate ||
          new Date(value).getTime() >= this.requiredDate.getTime() ||
          this.$t("scaffold-requests.required-until-date-later-than-required-date")
        );
      };
      let rules: ((value: any) => boolean | VueI18n.TranslateResult)[] = [
        requiredUntilLaterThanRequired
      ];
      if (
        !this.isSavingDraft &&
        !!this.isScaffoldErectJob &&
        !!this.$store.state.curEnvironment.scaffoldRequestDismantleDateRequired
      ) {
        rules.push(this.rules.required);
      }

      return rules;
    },
    jobRules(): any {
      let requiredDateLaterThanToday = (value: any) => {
        // The required date is valid if empty, or if entered and is today or later
        return (
          !value ||
          this.currentUserCanEnterHistoricalData ||
          new Date(value).getTime() >= new Date(new Date().toDateString()).getTime() ||
          this.$t("scaffold-requests.required-date-later-than-today")
        );
      };
      if (this.isSavingDraft) {
        return {
          requestType: [this.rules.required],
          requestSubType: [this.rules.required],
          requiredDate: [requiredDateLaterThanToday],
          // requiredUntilDate: this.requiredUntilDateRules,
          scaffoldNumber: [],
          requestingContractorID: [],
          disciplineID: [],
          requestingEmployeeID: [],
          areaID: [],
          subAreaID: [],
          siteContact: [],
          location: [],
          description: [],
          notes: [],
          workPackageIDs: [],
          clientWorkOrderReason: this.job.isClientWorkOrder ? [] : [],
          clientWorkOrderReferenceNumber: this.job.isClientWorkOrder ? [this.rules.required] : [],
          serviceOrderReason: this.job.isServiceOrder ? [] : [],
          serviceOrderReferenceNumber: this.job.isServiceOrder ? [this.rules.required] : [],
          changeOrderReason: this.job.isChangeOrder ? [] : [],
          changeOrderReferenceNumber: this.job.isChangeOrder ? [this.rules.required] : [],
          reworkReason: this.job.isRework ? [] : [],
          reworkReferenceNumber: this.job.isRework ? [this.rules.required] : [],
          purchaseOrderNumber: this.$store.state.curEnvironment.enablePurchaseOrders
            ? [this.rules.required]
            : [],
          iso: []
        };
      } else {
        var disciplineRequiredRule = this.rules.required;
        var requestorRequiredRule = this.rules.required;
        var areaRequiredRule = this.rules.required;
        var subAreaRequiredRule = this.rules.required;
        return {
          requestType: [this.rules.required],
          requestSubType: [this.rules.required],
          requiredDate: [this.rules.required, requiredDateLaterThanToday],
          requiredUntilDate: this.requiredUntilDateRules,
          scaffoldNumber: this.isScaffoldErectJob ? [] : [this.rules.required],
          requestingContractorID: [this.rules.required],
          disciplineID: [disciplineRequiredRule],
          requestingEmployeeID: [requestorRequiredRule],
          areaID: this.hasTag ? [] : [areaRequiredRule],
          subAreaID: this.hasTag ? [] : [subAreaRequiredRule],
          siteContact: [],
          location: [this.rules.required],
          description: [this.rules.required],
          notes: [],
          workPackageIDs: [this.rules.required],
          clientWorkOrderReason: this.job.isClientWorkOrder ? [] : [],
          clientWorkOrderReferenceNumber: this.job.isClientWorkOrder ? [this.rules.required] : [],
          serviceOrderReason: this.job.isServiceOrder ? [] : [],
          serviceOrderReferenceNumber: this.job.isServiceOrder ? [this.rules.required] : [],
          changeOrderReason: this.job.isChangeOrder ? [] : [],
          changeOrderReferenceNumber: this.job.isChangeOrder ? [this.rules.required] : [],
          reworkReason: this.job.isRework ? [] : [],
          reworkReferenceNumber: this.job.isRework ? [this.rules.required] : [],
          purchaseOrderNumber: this.$store.state.curEnvironment.enablePurchaseOrders
            ? [this.rules.required]
            : [],
          iso: []
        };
      }
    },
    jobType(): JobTypes {
      let jobType = JobTypes.Scaffold;
      if (this.$route.name == "PaintJobExisting") {
        jobType = JobTypes.Paint;
      } else if (this.$route.name == "MaintenanceJobExisting") {
        jobType = JobTypes.Maintenance;
      } else if (this.$route.name == "InsulationJobExisting") {
        jobType = JobTypes.Insulation;
      }
      return jobType;
    },
    jobTypeName(): string {
      let jobType = this.jobType;
      let jobTypeName = "scaffold";
      if (jobType == JobTypes.Paint) {
        jobTypeName = "paint";
      } else if (jobType == JobTypes.Maintenance) {
        jobTypeName = "maintenance";
      } else if (jobType == JobTypes.Insulation) {
        jobTypeName = "insulation";
      }
      return jobTypeName;
    }
  },
  methods: {
    /// This value's purpose is solely to determine which validation rules to return
    /// As such, modifying it changes which rules are applied to the UI, which requires a DOM refresh
    /// We need to use an awaited setter here to provide time for the UI to update
    updateIsDraft(val: boolean): Promise<void> {
      let _this = this;
      this.isSavingDraft = val;
      return new Promise((resolve, reject) => {
        _this.$nextTick(() => {
          resolve();
        });
      });
    }
  }
});

