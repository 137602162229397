var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("fd-subheader", {
            staticClass: "mt-3 mb-3",
            attrs: {
              "hide-switch": "",
              title: _vm.$t("estimate-setup.general.base-gear")
            }
          }),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.collar"),
                  items: _vm.parts,
                  value: _vm.collarPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:collarPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.mudsill"),
                  items: _vm.parts,
                  value: _vm.mudsillPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:mudsillPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.screwJack"),
                  items: _vm.parts,
                  value: _vm.screwJackPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:screwJackPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.ladderGate"),
                  items: _vm.parts,
                  value: _vm.ladderGatePartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:ladderGatePartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.clamp"),
                  items: _vm.parts,
                  value: _vm.clampPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:clampPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.crazyLeg"),
                  items: _vm.parts,
                  value: _vm.crazyLegPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:crazyLegPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.davitArm"),
                  items: _vm.parts,
                  value: _vm.davitArmPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:davitArmPartID", v) }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c("fd-subheader", {
            staticClass: "mt-3 mb-3",
            attrs: {
              "hide-switch": "",
              title: _vm.$t("estimate-setup.general.standards")
            }
          }),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.standard3_0m"),
                  items: _vm.parts,
                  value: _vm.standard3_0mPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:standard3_0mPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.standard2_5m"),
                  items: _vm.parts,
                  value: _vm.standard2_5mPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:standard2_5mPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.standard2_0m"),
                  items: _vm.parts,
                  value: _vm.standard2_0mPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:standard2_0mPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.standard1_5m"),
                  items: _vm.parts,
                  value: _vm.standard1_5mPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:standard1_5mPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.standard1_0m"),
                  items: _vm.parts,
                  value: _vm.standard1_0mPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:standard1_0mPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.standard0_5m"),
                  items: _vm.parts,
                  value: _vm.standard0_5mPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:standard0_5mPartID", v) }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c("fd-subheader", {
            staticClass: "mt-3 mb-3",
            attrs: {
              "hide-switch": "",
              title: _vm.$t("estimate-setup.general.access")
            }
          }),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.starterBracket"),
                  items: _vm.parts,
                  value: _vm.starterBracketPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:starterBracketPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.ladder10ft"),
                  items: _vm.parts,
                  value: _vm.ladder10ftPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:ladder10ftPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.ladder06ft"),
                  items: _vm.parts,
                  value: _vm.ladder06ftPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:ladder06ftPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.ladder05ft"),
                  items: _vm.parts,
                  value: _vm.ladder05ftPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:ladder05ftPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.ladder03ft"),
                  items: _vm.parts,
                  value: _vm.ladder03ftPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:ladder03ftPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.yoyo100ft"),
                  items: _vm.parts,
                  value: _vm.yoyo100ftPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:yoyo100ftPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.yoyo050ft"),
                  items: _vm.parts,
                  value: _vm.yoyo050ftPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:yoyo050ftPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.yoyo030ft"),
                  items: _vm.parts,
                  value: _vm.yoyo030ftPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:yoyo030ftPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.yoyo020ft"),
                  items: _vm.parts,
                  value: _vm.yoyo020ftPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:yoyo020ftPartID", v) }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c("fd-subheader", {
            staticClass: "mt-3 mb-3",
            attrs: {
              "hide-switch": "",
              title: _vm.$t("estimate-setup.general.steel-tubes")
            }
          }),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.steelTube13ft"),
                  items: _vm.parts,
                  value: _vm.steelTube13ftPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:steelTube13ftPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.steelTube10ft"),
                  items: _vm.parts,
                  value: _vm.steelTube10ftPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:steelTube10ftPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.steelTube08ft"),
                  items: _vm.parts,
                  value: _vm.steelTube08ftPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:steelTube08ftPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.steelTube06ft"),
                  items: _vm.parts,
                  value: _vm.steelTube06ftPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:steelTube06ftPartID", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", lg: "4", xl: "3" } },
            [
              _c("fd-part-selection-field", {
                attrs: {
                  label: _vm.$t("estimate-setup.general.steelTube04ft"),
                  items: _vm.parts,
                  value: _vm.steelTube04ftPartID,
                  disabled: _vm.loading
                },
                on: { input: v => _vm.$emit("update:steelTube04ftPartID", v) }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }