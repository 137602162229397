import { ownerService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: ownerService,
  singularStoreName: "OWNER",
  pluralStoreName: "OWNERS",
  localizationPrefix: "owners",
  consumerRelatedIDProperty: {
    name: "ownerID",
    type: "string"
  }
});

export default storeModule;
