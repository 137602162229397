var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: item =>
                                        _vm.$router.push(`/projects/`)
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "pb-0 px-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          attrs: { "icons-and-text": "" },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              key: _vm.detailsTab.key,
                              ref: "tab",
                              attrs: { href: `#tab-${_vm.detailsTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.detailsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.details")) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-edit")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.detailsTab.key,
                              attrs: { value: `tab-${_vm.detailsTab.key}` }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pl-3 pr-3 pt-5" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.name"),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.project.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.project, "name", $$v)
                                          },
                                          expression: "project.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-10",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("fd-rich-textarea", {
                                        attrs: {
                                          disabled: _vm.processing,
                                          label: _vm.$t("common.description")
                                        },
                                        model: {
                                          value: _vm.project.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.project,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "project.description"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          "item-text": "alias",
                                          "item-value": "id",
                                          items: _vm.owners,
                                          label: _vm.$t("projects.owner"),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.project.ownerID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.project,
                                              "ownerID",
                                              $$v
                                            )
                                          },
                                          expression: "project.ownerID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          "item-text": "name",
                                          "item-value": "id",
                                          items: _vm.regions,
                                          label: _vm.$t("projects.region"),
                                          disabled: _vm.processing,
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.project.regionID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.project,
                                              "regionID",
                                              $$v
                                            )
                                          },
                                          expression: "project.regionID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "projects.productivity"
                                          ),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.numeric]
                                        },
                                        model: {
                                          value: _vm.project.productivity,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.project,
                                              "productivity",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "project.productivity"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("projects.labourrate"),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.numeric]
                                        },
                                        model: {
                                          value: _vm.project.labourRate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.project,
                                              "labourRate",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "project.labourRate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t("common.archived"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value: _vm.project.archived,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.project,
                                              "archived",
                                              $$v
                                            )
                                          },
                                          expression: "project.archived"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _vm.inlineMessage.message
                                        ? _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                type: _vm.inlineMessage.type,
                                                dense:
                                                  _vm.$vuetify.breakpoint.xsOnly
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.inlineMessage.message
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-0 mr-0" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                link: "",
                                                disabled: _vm.processing,
                                                color: "error",
                                                outlined: "",
                                                small:
                                                  _vm.$vuetify.breakpoint
                                                    .name == "xs"
                                              },
                                              on: { click: _vm.deleteItem }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.delete"))
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                text: "",
                                                link: "",
                                                disabled: _vm.processing,
                                                small:
                                                  _vm.$vuetify.breakpoint
                                                    .name == "xs"
                                              },
                                              on: { click: _vm.cancel }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.cancel"))
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("fd-menu-save", {
                                            attrs: {
                                              disabled: _vm.processing,
                                              loading: _vm.saving,
                                              small:
                                                _vm.$vuetify.breakpoint.xsOnly
                                            },
                                            on: {
                                              "click:save": function($event) {
                                                return _vm.save(false)
                                              },
                                              "click:save-and-close": function(
                                                $event
                                              ) {
                                                return _vm.save(true)
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab",
                            {
                              key: _vm.partsTab.key,
                              attrs: { href: `#tab-${_vm.partsTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.partsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("projects.parts")) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-truck-loading")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.partsTab.key,
                              attrs: { value: `tab-${_vm.partsTab.key}` }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "pl-7 ml-0 mr-0",
                                  staticStyle: { background: "#EAEAEA" }
                                },
                                [
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: {
                                        value: _vm.partsCatalogUsageType,
                                        row: ""
                                      }
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label: _vm.$t(
                                            "projects.parts-selection-type-entire-catalog"
                                          ),
                                          value: "entire"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.setPartsCatalogUsageTypeToEntire()
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-radio", {
                                        staticClass: "ml-4",
                                        attrs: {
                                          label: _vm.$t(
                                            "projects.parts-selection-type-individual-parts-selection"
                                          ),
                                          value: "selection"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.setPartsCatalogUsageTypeToSelection()
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-expand-transition",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.partsCatalogUsageType ==
                                            "selection",
                                          expression:
                                            "partsCatalogUsageType == 'selection'"
                                        }
                                      ],
                                      attrs: { flat: "", tile: "" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "ml-0 mr-0" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pb-sm-0",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "7"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c("v-switch", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "projects.only-included-label"
                                                          )
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.showOnlyIncludedParts,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.showOnlyIncludedParts = $$v
                                                          },
                                                          expression:
                                                            "showOnlyIncludedParts"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "ml-4",
                                                                        attrs: {
                                                                          color:
                                                                            "black",
                                                                          dark:
                                                                            ""
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "info"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ])
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "projects.parts-selection-sub-menu-information"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pt-sm-1 pt-md-3 pb-0",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "5"
                                                  }
                                                },
                                                [
                                                  _c("v-combobox", {
                                                    class: [
                                                      _vm
                                                        .tagsSelectedForFiltering
                                                        .length > 0
                                                        ? "fd-combobox-control-item-selected-small"
                                                        : "fd-combobox-control-item-not-selected"
                                                    ],
                                                    attrs: {
                                                      items: _vm.tagsInUse,
                                                      label: _vm.$t(
                                                        "common.filter-by-tags"
                                                      ),
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      multiple: "",
                                                      "small-chips": "",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.tagsSelectedForFiltering,
                                                      callback: function($$v) {
                                                        _vm.tagsSelectedForFiltering = $$v
                                                      },
                                                      expression:
                                                        "tagsSelectedForFiltering"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            { staticClass: "mr-0 ml-0" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "order-md-1 order-0 pb-0",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "5"
                                                  }
                                                },
                                                [
                                                  _c("v-select", {
                                                    class: [
                                                      _vm
                                                        .suppliersSelectedForFiltering
                                                        .length > 0
                                                        ? "fd-select-control-item-selected"
                                                        : "",
                                                      "pt-3"
                                                    ],
                                                    attrs: {
                                                      "item-text": "alias",
                                                      "item-value": "id",
                                                      items: _vm.suppliersInUse,
                                                      label: _vm.$t(
                                                        "projects.parts-selection-supplier-filter-label"
                                                      ),
                                                      multiple: "",
                                                      "small-chips": "",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.suppliersSelectedForFiltering,
                                                      callback: function($$v) {
                                                        _vm.suppliersSelectedForFiltering = $$v
                                                      },
                                                      expression:
                                                        "suppliersSelectedForFiltering"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "order-0",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "7"
                                                  }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "append-icon": "search",
                                                      label: "Search",
                                                      "single-line": "",
                                                      "hide-details": "",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.tablesearchparts,
                                                      callback: function($$v) {
                                                        _vm.tablesearchparts = $$v
                                                      },
                                                      expression:
                                                        "tablesearchparts"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName: "v-fd-column:name",
                                                value: _vm.$t("common.name"),
                                                expression: "$t('common.name')",
                                                arg: "name"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:description.hide-when-extra-small",
                                                value: _vm.$t(
                                                  "common.description"
                                                ),
                                                expression:
                                                  "$t('common.description')",
                                                arg: "description",
                                                modifiers: {
                                                  "hide-when-extra-small": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:include.no-sort",
                                                value: _vm.$t("common.include"),
                                                expression:
                                                  "$t('common.include')",
                                                arg: "include",
                                                modifiers: { "no-sort": true }
                                              }
                                            ],
                                            ref: "partsDataTable",
                                            staticClass:
                                              "fixed-header v-table__overflow",
                                            staticStyle: {
                                              "max-height": "calc(50vh - 10px)",
                                              "backface-visibility": "hidden"
                                            },
                                            attrs: {
                                              items: _vm.parts,
                                              search: _vm.tablesearchparts,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": ["name"],
                                              "mobile-breakpoint": "0",
                                              "items-per-page": 100,
                                              "footer-props": {
                                                "items-per-page-options": [
                                                  5,
                                                  25,
                                                  50,
                                                  100,
                                                  -1
                                                ]
                                              }
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "header.include",
                                                fn: function({ header }) {
                                                  return [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        value:
                                                          _vm.allSearchedPartsSelected,
                                                        indeterminate:
                                                          _vm.someSearchedPartsSelected,
                                                        label: _vm.$t(
                                                          "common." +
                                                            header.value
                                                        )
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.flipSearchedPartsSelected()
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.include",
                                                fn: function({ item }) {
                                                  return [
                                                    _c("v-simple-checkbox", {
                                                      attrs: {
                                                        value: item.selected,
                                                        disabled: _vm.processing
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.flipPartSelected(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab",
                            {
                              key: _vm.areasTab.key,
                              attrs: { href: `#tab-${_vm.areasTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.areasTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("projects.areas.sub-menu-title")
                                  ) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-map-marked-alt")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.areasTab.key,
                              attrs: { value: `tab-${_vm.areasTab.key}` }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticStyle: { "margin-top": "15px" }
                                        },
                                        [
                                          _c("fd-inline-add-button", {
                                            on: {
                                              click: function($event) {
                                                return _vm.openNewDialog()
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "projects.areas.sub-menu-information"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: _vm.$t("common.search"),
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.tablesearchareas,
                                              callback: function($$v) {
                                                _vm.tablesearchareas = $$v
                                              },
                                              expression: "tablesearchareas"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:name",
                                            arg: "name"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:description.hide-when-extra-small",
                                            arg: "description",
                                            modifiers: {
                                              "hide-when-extra-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:archived.hide-when-extra-small.hide-when-header-text-empty",
                                            value: _vm.showArchived
                                              ? _vm.$t("common.archived")
                                              : "",
                                            expression:
                                              "\n                      showArchived ? $t('common.archived') : ''\n                    ",
                                            arg: "archived",
                                            modifiers: {
                                              "hide-when-extra-small": true,
                                              "hide-when-header-text-empty": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                            arg: "actions",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "class_fd-actions-cell": true
                                            }
                                          },
                                          {
                                            name: "fd-row-navigate",
                                            rawName:
                                              "v-fd-row-navigate.show-when-extra-small",
                                            value: item =>
                                              _vm.$router.push(
                                                `/areas/${item.id}`
                                              ),
                                            expression:
                                              "\n                      item => $router.push(`/areas/${item.id}`)\n                    ",
                                            modifiers: {
                                              "show-when-extra-small": true
                                            }
                                          }
                                        ],
                                        staticClass:
                                          "fd-actions-table fd-archiving-table",
                                        class: [
                                          {
                                            "fd-archive-bonus-padding-bottom":
                                              _vm.showArchived
                                          }
                                        ],
                                        attrs: {
                                          items: _vm.areas,
                                          search: _vm.tablesearchareas,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "sort-by": ["name"],
                                          "mobile-breakpoint": "0",
                                          "items-per-page": 100,
                                          "footer-props": {
                                            "items-per-page-options": [
                                              5,
                                              25,
                                              50,
                                              100,
                                              -1
                                            ]
                                          }
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "footer.prepend",
                                            fn: function() {
                                              return [
                                                _c("fd-archived-data-loader", {
                                                  staticClass: "ml-2 mr-5",
                                                  attrs: {
                                                    showArchived:
                                                      _vm.showArchived,
                                                    showArchivedDateRange:
                                                      _vm.showArchivedDateRange,
                                                    showArchivedMinDate:
                                                      _vm.showArchivedMinDate,
                                                    showArchivedMaxDate:
                                                      _vm.showArchivedMaxDate,
                                                    loading:
                                                      _vm.archivedLoading,
                                                    disabled: _vm.processing,
                                                    "hide-date-range-picker": ""
                                                  },
                                                  on: {
                                                    "input:showArchived": v =>
                                                      (_vm.showArchived = v),
                                                    "input:showArchivedDateRange": v =>
                                                      (_vm.showArchivedDateRange = v)
                                                  }
                                                })
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "item.fd-nav",
                                            fn: function() {
                                              return [
                                                _c("v-icon", [
                                                  _vm._v("mdi-chevron-right")
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "item.archived",
                                            fn: function({ item }) {
                                              return [
                                                _c("v-simple-checkbox", {
                                                  attrs: {
                                                    value: item.archived,
                                                    disabled: _vm.processing
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.flipAreaArchived(
                                                        item
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.actions",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-actions-column-min-width"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { left: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          link:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing,
                                                                          to: `/areas/${item.id}`
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-pencil\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.edit"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { right: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteAreasTableItem(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab",
                            {
                              key: _vm.costCodesTab.key,
                              attrs: { href: `#tab-${_vm.costCodesTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.costCodesTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.costCodesTab.tabname) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-binary")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.costCodesTab.key,
                              attrs: { value: `tab-${_vm.costCodesTab.key}` }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticStyle: { "margin-top": "15px" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "fd-position-relative fd-add-button-container"
                                            },
                                            [
                                              _c("fd-add-button", {
                                                attrs: {
                                                  action:
                                                    _vm.openNewCostCodeDialog
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "projects.cost-codes.sub-menu-information"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: _vm.$t("common.search"),
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.tablesearchcostcodes,
                                              callback: function($$v) {
                                                _vm.tablesearchcostcodes = $$v
                                              },
                                              expression: "tablesearchcostcodes"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:name",
                                            arg: "name"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:code.hide-when-extra-small",
                                            value: _vm.$t("common.costcode"),
                                            expression: "$t('common.costcode')",
                                            arg: "code",
                                            modifiers: {
                                              "hide-when-extra-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:archived.hide-when-extra-small.hide-when-header-text-empty",
                                            value: _vm.showArchived
                                              ? _vm.$t("common.archived")
                                              : "",
                                            expression:
                                              "\n                      showArchived ? $t('common.archived') : ''\n                    ",
                                            arg: "archived",
                                            modifiers: {
                                              "hide-when-extra-small": true,
                                              "hide-when-header-text-empty": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                            arg: "actions",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "class_fd-actions-cell": true
                                            }
                                          },
                                          {
                                            name: "fd-row-navigate",
                                            rawName:
                                              "v-fd-row-navigate.show-when-extra-small",
                                            value: item =>
                                              _vm.$router.push(
                                                `/costcodes/${item.id}`
                                              ),
                                            expression:
                                              "\n                      item => $router.push(`/costcodes/${item.id}`)\n                    ",
                                            modifiers: {
                                              "show-when-extra-small": true
                                            }
                                          }
                                        ],
                                        staticClass:
                                          "fd-actions-table fd-archiving-table",
                                        class: [
                                          {
                                            "fd-archive-bonus-padding-bottom":
                                              _vm.showArchived
                                          }
                                        ],
                                        attrs: {
                                          items: _vm.costcodes,
                                          search: _vm.tablesearchcostcodes,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "sort-by": ["name"],
                                          "mobile-breakpoint": "0",
                                          "items-per-page": 100,
                                          "footer-props": {
                                            "items-per-page-options": [
                                              5,
                                              25,
                                              50,
                                              100,
                                              -1
                                            ]
                                          }
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "footer.prepend",
                                            fn: function() {
                                              return [
                                                _c("fd-archived-data-loader", {
                                                  staticClass: "ml-2 mr-5",
                                                  attrs: {
                                                    showArchived:
                                                      _vm.showArchived,
                                                    showArchivedDateRange:
                                                      _vm.showArchivedDateRange,
                                                    showArchivedMinDate:
                                                      _vm.showArchivedMinDate,
                                                    showArchivedMaxDate:
                                                      _vm.showArchivedMaxDate,
                                                    loading:
                                                      _vm.archivedLoading,
                                                    disabled: _vm.processing,
                                                    "hide-date-range-picker": ""
                                                  },
                                                  on: {
                                                    "input:showArchived": v =>
                                                      (_vm.showArchived = v),
                                                    "input:showArchivedDateRange": v =>
                                                      (_vm.showArchivedDateRange = v)
                                                  }
                                                })
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "item.fd-nav",
                                            fn: function() {
                                              return [
                                                _c("v-icon", [
                                                  _vm._v("mdi-chevron-right")
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "item.archived",
                                            fn: function({ item }) {
                                              return [
                                                _c("v-simple-checkbox", {
                                                  attrs: {
                                                    value: item.archived,
                                                    disabled: _vm.processing
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.flipCostCodeArchived(
                                                        item
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.actions",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-actions-column-min-width"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { left: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          link:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing,
                                                                          to: `/costcodes/${item.id}`
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-pencil\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.edit"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { right: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteCostCodesTableItem(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("fd-area-new-dialog", {
        attrs: {
          show: _vm.showNewDialog,
          projectID: _vm.$route.params.projectID
        },
        on: {
          "update:show": function($event) {
            _vm.showNewDialog = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }