import FDVue from "../";

export default FDVue.extend({
  name: "fd-show-archived-switch",

  props: {
    value: { type: Boolean },
    cy: { type: String, default: "fd-show-archived-switch" }
  },

  data: function() {
    return {};
  },

  methods: {},

  computed: {},

  watch: {}
});
