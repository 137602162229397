import FDVue from "@fd/lib/vue";
import { mapMutations, mapActions } from "vuex";
import { showNewProjectDialog } from "./components/ProjectNewDialog.vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { stripHtml, truncateWithEllipsis } from "@fd/lib/vue/utility/helper";
import { addDaysToDate, addMonthsToDate } from "@fd/lib/client-util/datetime";
import archivedDataList from "../dataMixins/archivedDataList";

export default FDVue.extend({
  mixins: [errorHandling, archivedDataList],
  name: "fd-projects",

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {},

  data: function() {
    return {
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5
    };
  },

  computed: {
    projects(): any[] {
      return this.$store.state.projects.fullList.map((x: any) => {
        return {
          ...x,
          description: truncateWithEllipsis(stripHtml(x.description)),
          archived: !!x.archivedDate
        };
      });
    },

    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    openNewDialog() {
      showNewProjectDialog(this.$route.params.clientID);
    },

    async deleteTableItem(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteProject({ id: item.id, name: item.name });
      } catch (error) {
        this.handleError(error as Error, "projects.save-network-error");
      } finally {
        this.processing = false;
      }
    },

    async flipArchived(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateProject({ id: item.id, archivedDate: archivedDate, name: item.name });
      } catch (error) {
        this.handleError(error as Error, "projects.save-network-error");
      } finally {
        this.processing = false;
      }
    },

    async reloadTableData() {
      this.processing = true;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadData() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      await this.loadProjects({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },

    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT",
      setSelectedTab: "SET_SELECTED_TAB_INDEX_IN_FILTERING_CONTEXT"
    }),

    ...mapActions({
      loadProjects: "LOAD_PROJECTS",
      updateProject: "UPDATE_PROJECT",
      deleteProject: "DELETE_PROJECT"
    })
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "projects",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: new Date(0),
      showArchivedForFilteringToDate: new Date(),
      searchStringForFiltering: "",
      tagsForFiltering: [],
      suppliersForFiltering: []
    });

    this.processing = true;
    try {
      await this.loadData();
      this.notifyNewBreadcrumb({
        text: this.$t("projects.list-title"),
        to: "/projects",
        resetHistory: true
      });
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

