import FDVue from "@fd/lib/vue";

export default FDVue.extend({
  name: "sp-work-order-estimate-modifiers-form",
  props: {
    processing: {},
    scaffoldIsHardBarricade: {},
    scaffoldTypeModifierID: {},
    scaffoldDistanceModifierID: {},
    scaffoldElevationModifierID: {},
    scaffoldHeightModifierID: {},
    buildDismantleRatioID: {},
    scaffoldCongestionFactorID: {},
    modificationPercent: {},
    internalModifierID: {},
    supervisionPercent: {},
    logisticsPercent: {},
    hoardingModifierID: {},
    allScaffoldTypes: { type: Array },
    allScaffoldDistances: {},
    allScaffoldElevations: {},
    allScaffoldHeights: {},
    allBuildDismantleRatios: {},
    allScaffoldCongestionFactors: {},
    allInternalModifiers: {},
    allHoardingModifiers: {},
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {};
  },
  computed: {},
  watch: {
    scaffoldTypeModifierID(newValue, oldValue) {
      console.log(`scaffoldTypeModifierID changed: ${oldValue} -> ${newValue}`);
    },
    scaffoldIsHardBarricade(newValue, oldValue) {
      console.log(`scaffoldIsHardBarricade changed: ${oldValue} -> ${newValue}`);
    }
  },
  methods: {
    valueChanged(v: any, fieldname: string) {
      this.$emit(`update:${fieldname}`, v);
      this.$emit("change", v);
    },
    fieldKeyDown(e: KeyboardEvent) {
      if (e.key == ".") e.preventDefault();
    }
  }
});

