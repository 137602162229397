import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { TimesheetStatusLogWithDetails } from "../../../services";

const TimesheetStatusHistoryDialog = FDVue.extend({
  name: "fd-timesheet-status-history-dialog",

  mixins: [dialogSupport],

  components: {
    "fd-timesheet-status-history-form": () => import("../forms/TimesheetStatusHistoryForm.vue")
  },

  data: function() {
    return {
      statusLogs: [] as TimesheetStatusLogWithDetails[]
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return [];
    }
  },

  methods: {
    async open(statusLogs: TimesheetStatusLogWithDetails[]) {
      this.statusLogs = statusLogs;
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.cancelDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    }
  },

  created: async function() {}
});

export default TimesheetStatusHistoryDialog;

export async function showTimesheetStatusHistoryDialog(
  statusLogs: TimesheetStatusLogWithDetails[]
): Promise<boolean> {
  let dialog = createDialog(TimesheetStatusHistoryDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(statusLogs);
}

