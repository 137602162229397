import Vue from "vue";
import { VForm } from "@fd/lib/vue/types";
import { mapActions } from "vuex";
import VueI18n from "vue-i18n";
import i18n from "../../i18n";

import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import {
  filterByTags,
  filterBySuppliers,
  getSuppliersInUse,
  getTagsInUse
} from "../../services/taggableItems";
import { Tag, SupplierWithTags, PartWithTags } from "../../services";

const ProjectNewDialog = Vue.extend({
  name: "fd-project-new-dialog",
  mixins: [dialogSupport],

  data() {
    return {
      // The following tracks the current width of the browser window. It works in conjunction with a EventListener
      // setup in the "created" hook.
      windowWidth: 0,

      clientID: "",

      visibleParts: [] as Array<
        PartWithTags & {
          selected: boolean;
        }
      >,
      selectableParts: [] as Array<
        PartWithTags & {
          selected: boolean;
        }
      >,
      partsCatalogUsageType: "entire" as "entire" | "selection",

      wizardStep: 1,
      detailsInError: false,
      detailsComplete: false,
      partsListInError: false,
      partsListComplete: false,

      // The following will control whether the controls on screen are disabled while we are processing.
      processing: false,

      // These objects are used for the view specific "Tags" filtering.
      tagsSelectedForFiltering: [] as Tag[],

      // These objects are used for the view specific "Suppliers" filtering.
      suppliersSelectedForFiltering: [] as string[],

      // The following is responsible for tracking how the table within the view is currently being sorted by.
      sortPartsTableBy: ["publicID"],

      showOnlyIncluded: false,

      selectedsupplier: null,

      tablesearchparts: "",
      tablesearchareas: "",
      headersparts: [
        {
          text: "Name",
          value: "name",
          sortable: true,
          hideWhenSmall: false,
          hideWhenMedium: false,
          hideWhenLarge: false
        },
        {
          text: "Description",
          value: "description",
          sortable: true,
          hideWhenSmall: true,
          hideWhenMedium: false,
          hideWhenLarge: false
        },
        {
          text: "Include",
          value: "include",
          sortable: false,
          hideWhenSmall: false,
          hideWhenMedium: false,
          hideWhenLarge: false
        }
      ],

      // The following is responsible for the inline messages that may be presented to the user.
      // If the message property is null then no message will be shown to the user.
      // The type can be one of the following strings:
      // 'success'
      // 'info'
      // 'warning'
      // 'error'
      inlineMessage: {
        message: null as VueI18n.TranslateResult | null,
        //message: 'hello Byron',
        type: "error"
      },

      project: {
        name: "",
        description: "",
        productivity: 68,
        labourRate: 28,
        ownerID: "",
        regionID: "",
        enabled: true
      },

      rules: {
        // TODO: Correct type for value?
        required(value: any) {
          return !!value || i18n.t("common.rule-required");
        },
        numbersonly(value: any) {
          return !(value && isNaN(value)) || i18n.t("common.rule-numbers-only");
        }
      }
    };
  },

  computed: {
    // Since this is a new object ONLY get the list of "Enabled" owners.
    owners() {
      var x = this.project;
      return this.$store.getters.sortedEnabledOwners;
    },
    // Since this is a new object ONLY get the list of "Enabled" regions.
    regions() {
      return this.$store.getters.sortedEnabledRegions;
    },

    // Since this is for a New parts list, start by getting all parts that are currently "Enabled"
    // Then potentially filter this list by any Tags that are in use by any of the currently enabled parts.
    // Then potentially filter this list of parts by any Suppliers that are in use by any of the currently enabled parts.
    parts(): Array<PartWithTags & { selected: boolean }> {
      let returnValue = filterBySuppliers<PartWithTags & { selected: boolean }>(
        this.suppliersSelectedForFiltering,
        filterByTags(this.tagsSelectedForFiltering, this.selectableParts)
      );
      if (this.showOnlyIncluded) returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedPartIDs(): string[] {
      return this.selectableParts.filter(x => x.selected).map(x => x.id!);
    },

    searchedParts(): Array<PartWithTags & { selected: boolean }> {
      // This is a hack because the parts list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .partsDataTable as any)?.customFilter;
      if (this.tablesearchparts && customFilter) {
        return this.parts.filter(
          x =>
            customFilter(x.name!, this.tablesearchparts, x) ||
            customFilter(x.description!, this.tablesearchparts, x)
        );
      } else {
        return this.parts;
      }
    },

    allVisiblePartsSelected: {
      get(): boolean {
        return this.visibleParts.findIndex(x => !x.selected) === -1;
      },
      set(value: boolean) {
        for (let part of this.visibleParts) {
          part.selected = value;
        }
      }
    },

    someVisiblePartsSelected: {
      get(): boolean {
        return (
          this.visibleParts.findIndex(x => x.selected) !== -1 &&
          this.visibleParts.findIndex(x => !x.selected) !== -1
        );
      }
    },

    allSearchedPartsSelected: {
      get(): boolean {
        return this.searchedParts.findIndex(x => !x.selected) === -1;
      },
      set(value: boolean) {
        for (let part of this.searchedParts) {
          part.selected = value;
        }
      }
    },

    someSearchedPartsSelected: {
      get(): boolean {
        return (
          this.searchedParts.findIndex(x => x.selected) !== -1 &&
          this.searchedParts.findIndex(x => !x.selected) !== -1
        );
      }
    },

    // The list of Suppliers will be used both by the list of parts, but also by the Supplier filtering control. As such you can
    // filter the list of suppliers used by ONLY those suppliers that are currently associated to any parts in the system.
    suppliersInUse() {
      return getSuppliersInUse(
        this.$store.state.suppliers.fullList,
        this.$store.getters.sortedEnabledParts
      );
    },

    // The list of Tags will be used by the Tag filtering control and should be limited only to those tags that are currently
    // associated to any of the parts in the system.
    tagsInUse() {
      return getTagsInUse(this.$store.state.tags.fullList, this.$store.getters.sortedEnabledParts);
    },

    computedPartsHeaders(): {
      text: string;
      value: string;
      sortable: boolean;
      hideWhenSmall: boolean;
      hideWhenMedium: boolean;
      hideWhenLarge: boolean;
    }[] {
      if (this.windowWidth < 1264 && this.windowWidth >= 960) {
        return this.headersparts.filter(h => !h.hideWhenLarge);
      } else if (this.windowWidth < 960 && this.windowWidth >= 600) {
        return this.headersparts.filter(h => !h.hideWhenMedium);
      } else if (this.windowWidth < 600) {
        return this.headersparts.filter(h => !h.hideWhenSmall);
      } else {
        return this.headersparts;
      }
    }
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!();
    },

    setVisibleParts(visibleParts: Array<PartWithTags & { selected: boolean }>) {
      this.visibleParts = visibleParts;
    },

    wizardStepChanged() {
      if (this.wizardStep > 1) {
        this.detailsInError = !((this.$refs.detailsform as unknown) as VForm).validate();
        this.detailsComplete = this.detailsComplete || !this.detailsInError;
      } else if (!this.detailsComplete) {
        (this.$refs.detailsform as VForm).resetValidation();
      }
    },

    moveNextFromDetailsStep() {
      this.detailsComplete = true;
      this.detailsInError = !(this.$refs.detailsform as VForm).validate();
      this.wizardStep = 2;
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      this.inlineMessage.message = null;

      this.detailsInError = !(this.$refs.detailsform as VForm).validate();
      this.detailsComplete = true;
      this.partsListComplete = true;
      if (this.detailsInError) {
        return;
      }

      this.processing = true;
      try {
        await this.addProject({
          ...this.project,
          clientID: this.clientID,
          ownerID: this.project.ownerID ? this.project.ownerID : null,
          regionID: this.project.regionID ? this.project.regionID : null,
          productivity: this.project.productivity,
          labourRate: this.project.labourRate,
          partIDs: this.partsCatalogUsageType == "selection" ? this.selectedPartIDs : null
        });
        this.closeDialog!();
      } catch (error) {
        this.inlineMessage.message = this.$t("projects.save-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
      }
    },

    lookupSupplier(supplierID: string) {
      if (this.suppliersInUse) {
        let supplier = this.suppliersInUse.find(x => x.id == supplierID);
        if (supplier) {
          return supplier.alias;
        } else {
          return "(unknown)";
        }
      } else {
        return "Loading...";
      }
    },

    async createNewProject(clientID: string): Promise<void> {
      this.clientID = clientID;
      await this.showDialog!();
    },

    ...mapActions({
      loadOwners: "LOAD_OWNERS",
      loadRegions: "LOAD_REGIONS",
      addProject: "ADD_PROJECT",
      loadParts: "LOAD_PARTS",
      loadSuppliers: "LOAD_SUPPLIERS",
      loadTags: "LOAD_TAGS"
    })
  },

  created: async function() {
    // Listen to the "resize" even for the browser so we always know the width and can use that
    // knowledge for various responsive layout reasons.
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.windowWidth = window.innerWidth;

    this.processing = true;
    try {
      await Promise.all([
        this.loadOwners(),
        this.loadRegions(),
        this.loadParts(),
        this.loadSuppliers(),
        this.loadTags()
      ]);
      this.selectableParts = this.$store.getters.sortedEnabledParts.map((x: PartWithTags) => ({
        ...x,
        selected: false
      }));
    } catch (error) {
      this.inlineMessage.message = this.$t("unexpected-network-error");
      this.inlineMessage.type = "error";
    } finally {
      this.processing = false;
    }
  }
});

export default ProjectNewDialog;

export function showNewProjectDialog(clientID: string): Promise<void> {
  let dialog = createDialog(ProjectNewDialog);
  return dialog.createNewProject(clientID);
}
