import { mapMutations, mapActions, mapState } from "vuex";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import rules from "@fd/lib/vue/rules";
import { ScaffoldCongestionFactor } from "@fd/current/client/services";

type ScaffoldCongestionFactorWithDetails = ScaffoldCongestionFactor & { archived: boolean };

export default FDVue.extend({
  mixins: [errorHandling, rules],

  name: "sp-scaffold-congestion-factor-existing",

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,

      slidein: false,

      scaffoldCongestionFactor: {} as ScaffoldCongestionFactorWithDetails
    };
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.scaffoldCongestionFactor.archived) {
          this.scaffoldCongestionFactor.archivedDate = null;
        } else if (
          this.scaffoldCongestionFactor.archived &&
          !this.scaffoldCongestionFactor.archivedDate
        ) {
          this.scaffoldCongestionFactor.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateScaffoldCongestionFactor({
          ...this.scaffoldCongestionFactor,
          modifier: !!this.scaffoldCongestionFactor.modifier
            ? +this.scaffoldCongestionFactor.modifier
            : undefined,
          id: this.$route.params.id
        });

        if (closeOnComplete) {
          this.$router.push("/estimatesetup");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteScaffoldCongestionFactor({
          id: this.$route.params.id,
          name: this.scaffoldCongestionFactor.name
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.$router.push("/estimatesetup");
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push("/estimatesetup");
    },
    ...mapMutations({
      setScaffoldCongestionFactor: "SET_SCAFFOLD_CONGESTION_FACTOR",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadScaffoldCongestionFactor: "LOAD_SCAFFOLD_CONGESTION_FACTOR",
      updateScaffoldCongestionFactor: "UPDATE_SCAFFOLD_CONGESTION_FACTOR",
      deleteScaffoldCongestionFactor: "DELETE_SCAFFOLD_CONGESTION_FACTOR"
    })
  },

  watch: {
    scaffoldCongestionFactor(newValue) {
      console.log(`watch.scaffoldCongestionFactor`);
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/estimatesetup") {
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        this.notifyNewBreadcrumb({
          text: this.$t("estimate-setup.title"),
          to: "/estimatesetup",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      this.notifyNewBreadcrumb({
        text: this.scaffoldCongestionFactor.name,
        to: `/estimatesetup/scaffoldcongestionfactors/${this.$route.params.id}`,
        history: [
          {
            text: this.$t("modifiers.scaffold-congestion-factor.title"),
            disabled: true
          }
        ]
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/estimatesetup") {
      this.notifyNewBreadcrumb({
        text: this.$t("estimate-setup.title"),
        to: "/estimatesetup",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }

    this.notifyNewBreadcrumb({
      text: this.$t("loading-dot-dot-dot"),
      disabled: true,
      history: [
        {
          text: this.$t("modifiers.scaffold-congestion-factor.title"),
          disabled: true
        }
      ]
    });

    this.processing = true;
    try {
      await this.loadScaffoldCongestionFactor(this.$route.params.id);
      var scaffoldCongestionFactor = this.$store.state.scaffoldCongestionFactors.fullList.find(
        (x: ScaffoldCongestionFactor) => x.id == this.$route.params.id
      );
      this.scaffoldCongestionFactor = {
        ...scaffoldCongestionFactor,
        archived: !!scaffoldCongestionFactor.archivedDate
      } as ScaffoldCongestionFactorWithDetails;
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

