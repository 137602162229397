import FDVue from "@fd/lib/vue";
import { GroupableSelectListOption } from "@fd/lib/vue/utility/select";
import { ScaffoldInspectionTimeRange } from "../../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { PropType } from "vue";

export function GetSelectableDays(
  startDate: Date | string = new Date(),
  daysBack: number = 10
): Date[] {
  var dates = [];
  for (let i = 0; i < daysBack; i++) {
    let day = DateUtil.addDaysToDate(startDate, -i);
    dates.push(day);
  }
  return dates;
}
export function GetSelectableTimeSegments(
  timeRanges: ScaffoldInspectionTimeRange[],
  startDate: Date | string = new Date(),
  daysBack: number = 10
): GroupableSelectListOption<TimeSegment>[] {
  console.log(`selectableTimeSegments`);
  var timeSegments = timeRanges.slice().sort((a, b) => b.start.getTime() - a.start.getTime());
  var selectableTimeSegments = [];
  for (let day of GetSelectableDays(startDate, daysBack)) {
    console.log(`\t day: ${day}`);
    console.log(`\t\t header: ${DateUtil.stripTimeFromLocalizedDateTime(day)}`);
    let dayText = DateUtil.stripTimeFromLocalizedDateTime(day);
    var groupItem = {
      header: dayText
    };
    selectableTimeSegments.push(groupItem);

    timeSegments.forEach(t => {
      // console.log(`\t\t Segment ${t.name}`);
      var segmentStartTimeString = t.startTimeString;
      var segmentStartTime = new Date(`${DateUtil.isoDateString(day)}T${segmentStartTimeString}`);
      if (segmentStartTime.getTime() > new Date().getTime()) {
        // console.log(
        //   `\t\t\t segmentStartTime (${segmentStartTime}) in the future (now = ${new Date()})`
        // );
        // console.log(`\t\t\t DateUtil.isoDateString(day) ${DateUtil.isoDateString(day)}`);
        // console.log(
        //   `\t\t\t DateUtil.isoTimeWithOffsetString(segmentStartTime) ${DateUtil.isoTimeWithOffsetString(
        //     segmentStartTime
        //   )}`
        // );
        return;
      }

      // console.log(`\t\t\t start ${t.start}`);
      // console.log(`\t\t\t new ${segmentStartTime}`);

      let segmentDuration = t.end.getTime() - t.start.getTime();
      let newEndVal = segmentStartTime.getTime() + segmentDuration;
      let newEnd = new Date(newEndVal);
      // console.log(`\t\t\t end ${t.end}`);
      // console.log(`\t\t\t NEW END ${newEnd}`);

      let segmentItem = {
        text: t.name,
        description: `${DateUtil.stripDateFromLocalizedDateTime(
          segmentStartTime
        )} ~ ${DateUtil.stripDateFromLocalizedDateTime(newEnd)}`,
        style: t.style,
        value: segmentStartTime,
        start: segmentStartTime,
        end: newEnd,
        day: dayText
      };
      selectableTimeSegments.push(segmentItem);
    });
  }
  return selectableTimeSegments;
}

export type TimeSegment = {
  text: string;
  description: string;
  style: string;
  value: Date;
  start: Date;
  end: Date;
  day: string;
};
export default FDVue.extend({
  name: "sp-scaffold-inspection-time-segment-select",
  props: {
    value: {
      type: Object as PropType<TimeSegment>
    },
    selectableTimeSegments: {
      type: Array as PropType<Array<TimeSegment>>,
      default: () => []
    }
  },

  data: function() {
    return {
      selectedTimeRange: {} as TimeSegment
    };
  },
  computed: {},
  methods: {
    valueChanged(v: any) {
      this.$emit("input", v);
    }
  }
});

