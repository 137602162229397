var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "pa-0",
      attrs: { fluid: "", "data-cy": "workorderscopedetailsform" }
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "request-form",
          on: { submit: _vm.preventSubmit }
        },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("fd-subheader", {
                    attrs: {
                      icon: `fas ${_vm.$store.getters.icon(
                        "client-work-order"
                      )}`,
                      label: _vm.$t("scaffold-requests.client-work-order"),
                      value: _vm.isClientWorkOrder,
                      disabled: _vm.disabled,
                      "on-color": _vm.$store.getters.colour(
                        "client-work-order"
                      ),
                      "on-highlight-color": _vm.$store.getters.colour(
                        "client-work-order-highlight"
                      ),
                      "always-highlight": ""
                    },
                    on: { input: v => _vm.$emit("update:isClientWorkOrder", v) }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-0 pl-0 pr-0 pb-0 pt-5",
                      style: `background-color: ${_vm.$store.getters.colour(
                        "client-work-order-background"
                      )} !important;`,
                      attrs: { align: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-1 pb-0", attrs: { cols: "12" } },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "scaffold-requests.client-work-order-reference-number"
                              ),
                              value: _vm.clientWorkOrderReferenceNumber,
                              rules: _vm.clientWorkOrderReferenceNumberRules,
                              disabled: _vm.disabled || !_vm.isClientWorkOrder
                            },
                            on: {
                              input: v =>
                                _vm.$emit(
                                  "update:clientWorkOrderReferenceNumber",
                                  v
                                )
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("fd-textarea", {
                            attrs: {
                              label: _vm.$t(
                                "scaffold-requests.client-work-order-reason"
                              ),
                              value: _vm.clientWorkOrderReason,
                              rules: _vm.clientWorkOrderReasonRules,
                              disabled: _vm.disabled || !_vm.isClientWorkOrder,
                              rows: "1",
                              "auto-grow": ""
                            },
                            on: {
                              input: v =>
                                _vm.$emit("update:clientWorkOrderReason", v)
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("fd-subheader", {
                    attrs: {
                      icon: `fas ${_vm.$store.getters.icon("service-order")}`,
                      label: _vm.$t("scaffold-requests.service-order"),
                      value: _vm.isServiceOrder,
                      disabled: _vm.disabled,
                      "on-color": _vm.$store.getters.colour("service-order"),
                      "on-highlight-color": _vm.$store.getters.colour(
                        "service-order-highlight"
                      ),
                      "always-highlight": ""
                    },
                    on: { input: v => _vm.$emit("update:isServiceOrder", v) }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-0 pl-0 pr-0 pb-0 pt-5",
                      style: `background-color: ${_vm.$store.getters.colour(
                        "service-order-background"
                      )} !important;`,
                      attrs: { align: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-1 pb-0", attrs: { cols: "12" } },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "scaffold-requests.service-order-reference-number"
                              ),
                              value: _vm.serviceOrderReferenceNumber,
                              rules: _vm.serviceOrderReferenceNumberRules,
                              disabled: _vm.disabled || !_vm.isServiceOrder
                            },
                            on: {
                              input: v =>
                                _vm.$emit(
                                  "update:serviceOrderReferenceNumber",
                                  v
                                )
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("fd-textarea", {
                            attrs: {
                              label: _vm.$t(
                                "scaffold-requests.service-order-reason"
                              ),
                              value: _vm.serviceOrderReason,
                              rules: _vm.serviceOrderReasonRules,
                              disabled: _vm.disabled || !_vm.isServiceOrder,
                              rows: "1",
                              "auto-grow": ""
                            },
                            on: {
                              input: v =>
                                _vm.$emit("update:serviceOrderReason", v)
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("fd-subheader", {
                    attrs: {
                      icon: `fas ${_vm.$store.getters.icon("change-order")}`,
                      label: _vm.$t("scaffold-requests.change-order"),
                      value: _vm.isChangeOrder,
                      disabled: _vm.disabled,
                      "on-color": _vm.$store.getters.colour("change-order"),
                      "on-highlight-color": _vm.$store.getters.colour(
                        "change-order-highlight"
                      ),
                      "always-highlight": ""
                    },
                    on: { input: v => _vm.$emit("update:isChangeOrder", v) }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-0 pl-0 pr-0 pb-0 pt-5",
                      style: `background-color: ${_vm.$store.getters.colour(
                        "change-order-background"
                      )} !important;`,
                      attrs: { align: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-1 pb-0", attrs: { cols: "12" } },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "scaffold-requests.change-order-reference-number"
                              ),
                              value: _vm.changeOrderReferenceNumber,
                              rules: _vm.changeOrderReferenceNumberRules,
                              disabled: _vm.disabled || !_vm.isChangeOrder
                            },
                            on: {
                              input: v =>
                                _vm.$emit(
                                  "update:changeOrderReferenceNumber",
                                  v
                                )
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("fd-textarea", {
                            attrs: {
                              label: _vm.$t(
                                "scaffold-requests.change-order-reason"
                              ),
                              value: _vm.changeOrderReason,
                              rules: _vm.changeOrderReasonRules,
                              disabled: _vm.disabled || !_vm.isChangeOrder,
                              rows: "1",
                              "auto-grow": ""
                            },
                            on: {
                              input: v =>
                                _vm.$emit("update:changeOrderReason", v)
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("fd-subheader", {
                    attrs: {
                      icon: `fas ${_vm.$store.getters.icon("rework")}`,
                      label: _vm.$t("scaffold-requests.rework"),
                      value: _vm.isRework,
                      disabled: _vm.disabled,
                      "on-color": _vm.$store.getters.colour("rework"),
                      "on-highlight-color": _vm.$store.getters.colour(
                        "rework-highlight"
                      ),
                      "always-highlight": ""
                    },
                    on: { input: v => _vm.$emit("update:isRework", v) }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-0 pl-0 pr-0 pb-0 pt-5",
                      style: `background-color: ${_vm.$store.getters.colour(
                        "rework-background"
                      )} !important;`,
                      attrs: { align: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-1 pb-0", attrs: { cols: "12" } },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "scaffold-requests.rework-reference-number"
                              ),
                              disabled: _vm.disabled || !_vm.isRework,
                              value: _vm.reworkReferenceNumber,
                              rules: _vm.reworkReferenceNumberRules
                            },
                            on: {
                              input: v =>
                                _vm.$emit("update:reworkReferenceNumber", v)
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("fd-textarea", {
                            attrs: {
                              label: _vm.$t("scaffold-requests.rework-reason"),
                              disabled: _vm.disabled || !_vm.isRework,
                              value: _vm.reworkReason,
                              rules: _vm.reworkReasonRules,
                              rows: "1",
                              "auto-grow": ""
                            },
                            on: {
                              input: v => _vm.$emit("update:reworkReason", v)
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.showExistingTagNumber || _vm.purchaseOrders
            ? _c(
                "v-row",
                {
                  staticClass: "mx-0 px-2 fd-subheader-with-control",
                  attrs: { align: "center" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "mt-3 pa-0",
                      attrs: { align: "center", cols: "12", sm: "12" }
                    },
                    [
                      _c(
                        "v-subheader",
                        { staticStyle: { "font-size": "1em", height: "auto" } },
                        [_vm._v(_vm._s(_vm.$t("common.other")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showExistingTagNumber || _vm.purchaseOrders
            ? _c(
                "v-row",
                { staticClass: "mx-2", attrs: { align: "center" } },
                [_c("v-divider", { staticClass: "mt-0" })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showExistingTagNumber || _vm.purchaseOrders
            ? _c(
                "v-row",
                { staticClass: "mx-0" },
                [
                  _vm.showExistingTagNumber
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "scaffold-requests.existing-tag-number"
                              ),
                              value: _vm.existingTagNumber,
                              rules: _vm.existingTagNumberRules,
                              disabled: _vm.disabled
                            },
                            on: {
                              input: v =>
                                _vm.$emit("update:existingTagNumber", v)
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.purchaseOrders
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-select", {
                            attrs: {
                              "data-cy": "purchaseorderselect",
                              outlined: "",
                              label: _vm.$t(
                                "scaffold-requests.purchase-order-number"
                              ),
                              items: _vm.selectablePurchaseOrders,
                              "item-text": "purchaseOrderNumber",
                              "item-value": "id",
                              value: _vm.purchaseOrderID,
                              rules: _vm.purchaseOrderIDRules,
                              disabled: _vm.disabled
                            },
                            on: {
                              input: v => _vm.$emit("update:purchaseOrderID", v)
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }