import FDVue from "../";

export default FDVue.extend({
  name: "fd-back-button",

  props: {
    route: {
      type: Function
    },
    cy: { type: String, default: "fd-back-button" }
  },

  data: () => ({}),

  methods: {
    routeme() {
      this.route();
    }
  }
});
