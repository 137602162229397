var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.showClientWorkOrderBadge ||
    _vm.showChangeOrderBadge ||
    _vm.showReworkBadge ||
    _vm.showServiceOrderBadge
    ? _c(
        "v-row",
        {
          staticClass: "fd-inline-table-request-type-badge-container",
          attrs: { justify: "center", "no-gutters": "" }
        },
        [
          _vm.showClientWorkOrderBadge
            ? _c(
                "v-col",
                {
                  staticClass: "justify-center",
                  attrs: { cols: _vm.badgeColumnSize, justify: "center" }
                },
                [
                  _vm.item.isClientWorkOrder
                    ? _c("v-badge", {
                        staticClass: "fd-inline-table-request-type-badge",
                        attrs: {
                          color: _vm.$store.getters.colour("client-work-order")
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "badge",
                              fn: function() {
                                return [
                                  _c("v-icon", {
                                    attrs: { "x-small": "" },
                                    domProps: {
                                      textContent: _vm._s(
                                        `fas ${_vm.$store.getters.icon(
                                          "client-work-order"
                                        )}`
                                      )
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1739614103
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showServiceOrderBadge
            ? _c(
                "v-col",
                {
                  staticClass: "justify-center",
                  attrs: { cols: _vm.badgeColumnSize, justify: "center" }
                },
                [
                  _vm.item.isServiceOrder
                    ? _c("v-badge", {
                        staticClass: "fd-inline-table-request-type-badge",
                        attrs: {
                          color: _vm.$store.getters.colour("service-order")
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "badge",
                              fn: function() {
                                return [
                                  _c("v-icon", {
                                    attrs: { "x-small": "" },
                                    domProps: {
                                      textContent: _vm._s(
                                        `fas ${_vm.$store.getters.icon(
                                          "service-order"
                                        )}`
                                      )
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3005438943
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showChangeOrderBadge
            ? _c(
                "v-col",
                {
                  staticClass: "justify-center",
                  attrs: { cols: _vm.badgeColumnSize, justify: "center" }
                },
                [
                  _vm.item.isChangeOrder
                    ? _c("v-badge", {
                        staticClass: "fd-inline-table-request-type-badge",
                        attrs: {
                          color: _vm.$store.getters.colour("change-order")
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "badge",
                              fn: function() {
                                return [
                                  _c("v-icon", {
                                    attrs: { "x-small": "" },
                                    domProps: {
                                      textContent: _vm._s(
                                        `fas ${_vm.$store.getters.icon(
                                          "change-order"
                                        )}`
                                      )
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          705397060
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showReworkBadge
            ? _c(
                "v-col",
                {
                  staticClass: "justify-center",
                  attrs: { cols: _vm.badgeColumnSize, justify: "center" }
                },
                [
                  _vm.item.isRework
                    ? _c("v-badge", {
                        staticClass: "fd-inline-table-request-type-badge",
                        attrs: { color: _vm.$store.getters.colour("rework") },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "badge",
                              fn: function() {
                                return [
                                  _c("v-icon", {
                                    attrs: { "x-small": "" },
                                    domProps: {
                                      textContent: _vm._s(
                                        `fas ${_vm.$store.getters.icon(
                                          "rework"
                                        )}`
                                      )
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          507049015
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }