import { mapMutations, mapActions, mapState } from "vuex";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import rules from "@fd/lib/vue/rules";
import { Tag } from "@fd/current/client/services";

type TagWithDetails = Tag & { archived: boolean };

export default FDVue.extend({
  mixins: [errorHandling, rules],

  name: "fd-Tag-Existing",

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,

      slidein: false,

      //The following object is used in conjunction with the breadcrumbs that are presented to the user for sub-view navigation.
      breadcrumbs: [
        {
          text: this.$t("tags.menu-title"),
          disabled: false,
          to: "/tags"
        },
        {
          text: this.$t("loading-dot-dot-dot"),
          disabled: true
        }
      ],

      tag: {
        archived: false
      } as TagWithDetails
    };
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.tag.archived) {
          this.tag.archivedDate = null;
        } else if (this.tag.archived && !this.tag.archivedDate) {
          this.tag.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateTag({
          ...this.tag,
          id: this.$route.params.id
        });

        if (closeOnComplete) {
          this.$router.push("/tags");
        }
      } catch (error) {
        this.handleError(error, "tags.save-network-error");
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteTag({ id: this.$route.params.id, name: this.tag.name });
      } catch (error) {
        this.handleError(error, "unexpected-network-error");
      } finally {
        this.processing = false;
        this.$router.push("/tags");
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push("/tags");
    },
    ...mapMutations({
      setTag: "SET_TAG",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadTag: "LOAD_TAG",
      updateTag: "UPDATE_TAG",
      deleteTag: "DELETE_TAG"
    })
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    this.processing = true;
    try {
      await this.loadTag(this.$route.params.id);
      var tag = this.$store.state.tags.fullList.find((x: Tag) => x.id == this.$route.params.id);
      this.tag = {
        ...tag,
        archived: !!tag.archivedDate
      } as TagWithDetails;
      this.notifyNewBreadcrumb({
        text: this.tag.name,
        to: `/tags/${this.$route.params.id}`
      });
    } catch (error) {
      this.handleError(error, "common.unexpected-network-error");
    } finally {
      this.processing = false;
    }
  }
});

