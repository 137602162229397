export async function hmacSha512(keyData: Uint8Array, data: Uint8Array): Promise<Uint8Array> {
  var hmacKey = await crypto.subtle.importKey(
    "raw",
    keyData,
    { name: "HMAC", hash: { name: "SHA-512" } },
    false,
    ["sign", "verify"]
  );
  var signature = await crypto.subtle.sign("HMAC", hmacKey, data);
  return new Uint8Array(signature);
}
