var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: [
        {
          "appear-nicely": _vm.show,
          "disappear-nicely": !_vm.show,
          "login-screen-floating-placement-mobile-override":
            _vm.smallishMobileDevice || _vm.smallWidthBrowser
        },
        "login-screen-floating-placement",
        "sign-in-controls-container"
      ],
      staticStyle: { opacity: "0", "pointer-events": "none" }
    },
    [
      _c(
        "v-form",
        { ref: "allowNotificationsForm" },
        [
          _c(
            "v-row",
            { staticClass: "ml-0" },
            [
              _c(
                "v-col",
                { staticClass: "pl-0 pb-0", attrs: { cols: "10", md: "12" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "ml-0", attrs: { justify: "start" } },
                    [
                      _c(
                        "span",
                        {
                          class: {
                            "update-password-title-text-regular":
                              !_vm.smallishMobileDevice &&
                              !_vm.smallWidthBrowser,
                            "update-password-title-text-smaller":
                              _vm.smallishMobileDevice || _vm.smallWidthBrowser
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("terms.notifications.title")))]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "grow" },
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-0 fd-privacy-terms" },
                    [
                      _c("v-card-text", { staticClass: "pt-0" }, [
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("terms.notifications.line-1")))
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("terms.notifications.line-2")))
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("terms.notifications.line-3")))
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                _vm._l(_vm.contactMethods, function(contactMethod) {
                  return _c("v-switch", {
                    key: contactMethod.id,
                    attrs: {
                      "input-value": contactMethod.allowNotifications,
                      label: _vm.$t(
                        "terms.notifications.switch-label-contact-method",
                        [
                          !!contactMethod.emailAddress
                            ? contactMethod.emailAddress
                            : `***-***-*${contactMethod.phoneNumberTail}`
                        ]
                      )
                    }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "marketing-main-color-button-style width-100 mt-0",
                      attrs: {
                        loading: _vm.loading,
                        disabled: _vm.disabled,
                        depressed: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("done")
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("terms.notifications.accept-button-title")
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }