import { VueConstructor } from "vue";

export class ParsePluginController {
  /**
   * Tries to parse a number out of a value, if one exists
   * If the supplied value is either undefined or null, returns that same value
   * This matters as the service behaves differently based on whether a supplied value is null or undefined
   */
  public number(value: string | number | null | undefined): number | null | undefined {
    if (value == null || value == undefined) return value;

    let number = Number(value);
    if (isNaN(number)) return null;
    return number;
  }

  /**
   * Tries to parse a number out of a value, returning a default number otherwise
   * This guarantees a valid number value as a result
   */
  public sanitizedNumber(
    value: string | number | null | undefined,
    defaultValue: number = 0
  ): number {
    if (!value) return defaultValue;

    let number = Number(value);
    if (isNaN(number)) return defaultValue;
    return number;
  }
}

export function ParsePlugin(Vue: VueConstructor<Vue>) {
  Vue.prototype.$parse = new ParsePluginController();
}

declare module "vue/types/vue" {
  interface Vue {
    $parse: ParsePluginController;
  }
}

