import FDVue from "../";

export default FDVue.extend({
  name: "fd-basic-button",

  props: {
    cy: { type: String, default: "fd-btn" }
  },

  data: function() {
    return {};
  },

  methods: {},

  computed: {},

  watch: {}
});
