import FDVue from "../index";
import * as DateUtil from "../../client-util/datetime";
import VueI18n from "vue-i18n";

export type DateRangePreset = {
  fromDate: Date | string;
  toDate: Date | string;
  key: string;
  labelKey: string;
};

export default FDVue.extend({
  name: "fd-date-range-picker",

  inheritAttrs: false,

  props: {
    label: { type: String, default: undefined },
    value: Array,
    rules: Array,

    // If this is true, the custom
    customRangeMonths: {
      default: 2,
      type: Number
    },

    loading: { default: false, type: Boolean },
    disabled: { default: false, type: Boolean },
    outlined: { default: true, type: Boolean },
    clearable: { default: false, type: Boolean },
    i18nLocale: { default: "en-us", type: String },
    cy: { type: String, default: "fd-date-range-picker" },
    format: { type: String, default: "llll" },
    // min: { type: [String, Date], default: undefined },
    // max: { type: [String, Date], default: undefined },
    textFieldClass: { type: [Object, String, Array], default: undefined },
    textFieldStyle: { type: String, default: undefined },
    displayDot: { type: Boolean, default: false },
    rangeSeparator: { default: " ~ ", type: String },
    displayRangeAsPresetLabel: { type: Boolean, default: true },

    displayInlineButtons: { type: Boolean, default: false },

    // Preset Menu Options
    presetMenuTitleKey: {
      type: String,
      default: "fd-date-range-picker.preset-menu-title"
    },
    showCustomPreset: { type: Boolean, default: true },
    allowAllData: { type: Boolean, default: false },
    presets: {
      type: Array,
      default: () => [
        {
          fromDate: DateUtil.addDaysToDate(null, 0),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "today",
          labelKey: "fd-date-range-picker.preset-today-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -6),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-week",
          labelKey: "fd-date-range-picker.preset-previous-week-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addMonthsToDate(null, -2),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-months",
          labelKey: "fd-date-range-picker.preset-previous-two-months-label"
        } as DateRangePreset
        // {
        //   fromDate: DateUtil.addMonthsToDate(null, -12),
        //   toDate: DateUtil.addDaysToDate(null, 0),
        //   key: "previous-year",
        //   labelKey: "fd-date-range-picker.preset-previous-year-label"
        // } as DateRangePreset
      ]
    },

    // Date Pickers
    fromPickerTitleKey: {
      type: String,
      default: "fd-date-range-picker.from-picker-title"
    },
    toPickerTitleKey: {
      type: String,
      default: "fd-date-range-picker.to-picker-title"
    },
    dateRangeInfoMessage: {
      type: String,
      default: function() {
        return this.$t("fd-date-range-picker.date-range-info-label", [this.customRangeMonths]);
      }
    },
    cancelButtonLabelKey: {
      type: String,
      default: "fd-date-range-picker.cancel-button-label"
    },
    doneButtonLabelKey: {
      type: String,
      default: "fd-date-range-picker.done-button-label"
    }
  },

  data: () => ({
    // fromValid: true,
    // toValid: true,
    fromDate: undefined as Date | undefined,
    toDate: undefined as Date | undefined,
    menuOpen: false,

    selectedPresetKey: undefined as string | undefined,
    customPreset: {
      key: "custom",
      labelKey: "fd-date-range-picker.custom-preset-label"
    } as {
      key: string;
      labelKey: string;
    },
    allPreset: {
      fromDate: DateUtil.minimumDate(),
      toDate: DateUtil.maximumDate(),
      key: "all",
      labelKey: "fd-date-range-picker.all-preset-label"
    } as DateRangePreset
  }),

  computed: {
    validCustomRange(): boolean {
      if (!this.fromDate || !this.toDate) return false;
      if (this.fromDate.getTime() > this.toDate.getTime()) return false;

      let maxToDate = DateUtil.getToDateForRangeUsingFromDate(
        this.fromDate,
        this.customRangeMonths
      );
      let isValid = this.toDate.getTime() == maxToDate.getTime();
      return isValid;
    },
    allVisiblePresets(): { key: string; labelKey: string }[] {
      var presets = this.presets as {
        key: string;
        labelKey: string;
      }[];
      if (
        this.allowAllData !== undefined &&
        this.allowAllData !== null &&
        this.allowAllData !== false
      ) {
        presets.push(this.allPreset);
      }
      if (this.showCustomPreset) {
        presets.push(this.customPreset);
      }
      return presets;
    },
    activeDates(): string[] {
      if (!this.validCustomRange) return [] as string[];
      if (this.selectedPresetKey == this.allPreset.key) [];

      if (!this.fromDate)
        this.fromDate = DateUtil.getFromDateForRangeUsingToDate(
          this.toDate,
          this.customRangeMonths
        );
      if (!this.toDate)
        this.toDate = DateUtil.getToDateForRangeUsingFromDate(
          this.fromDate,
          this.customRangeMonths
        );

      let activeDates = [];

      let current = this.fromDate;
      while (current.getTime() <= this.toDate.getTime()) {
        activeDates.push(DateUtil.isoDateString(current));
        current = DateUtil.addDaysToDate(current, 1);
      }

      return activeDates;
    },
    // The text displayed in the text box.  Displays the DATE values (based on the supplied format string), separated by the set range separator
    dateRangeText: {
      cache: false,
      get(): string | VueI18n.TranslateResult | undefined {
        if (this.selectedPresetKey == "all")
          return this.$t("fd-date-range-picker.all-preset-hint-text");

        var dateStrings = [];
        if (!!this.fromDate) {
          var dateVal = this.fromDate;
          var dateString = DateUtil.stripTimeFromLocalizedDateTime(dateVal, this.$props.format);
          dateStrings.push(dateString);
        }
        if (!!this.toDate) {
          var dateVal = this.toDate;
          var dateString = DateUtil.stripTimeFromLocalizedDateTime(dateVal, this.$props.format);
          dateStrings.push(dateString);
        }
        return dateStrings.join(this.rangeSeparator);
      }
    },
    fromDateISOString: {
      cache: false,
      get(): string {
        return DateUtil.isoDateString(this.fromDate);
      }
    },
    toDateISOString: {
      cache: false,
      get(): string {
        return DateUtil.isoDateString(this.toDate);
      }
    },
    currentRangeDays(): number {
      return DateUtil.getDaysDifference(this.fromDate, this.toDate) ?? 0;
    }
  },

  methods: {
    confirmSelectedPresetKey() {
      let selectedPreset = (this.presets as DateRangePreset[]).find(
        x =>
          DateUtil.isoDateString(x.fromDate) == this.fromDateISOString &&
          DateUtil.isoDateString(x.toDate) == this.toDateISOString
      );
      if (!!selectedPreset) this.selectedPresetKey = selectedPreset.key;
      else this.selectedPresetKey = this.customPreset.key;
    },
    moveBackCurrentRange() {
      // TODO: Figure out how to decide that this is actually a "MONTHS" range and add months instead
      let newToDate = DateUtil.addDaysToDate(this.fromDate, -1);
      let newFromDate = DateUtil.addDaysToDate(this.fromDate, -(this.currentRangeDays + 1));
      this.fromDate = this.parseDate(newFromDate);
      this.toDate = this.parseDate(newToDate);

      this.confirmSelectedPresetKey();

      this.done();
    },
    moveForwardCurrentRange() {
      // TODO: Figure out how to decide that this is actually a "MONTHS" range and add months instead
      let newFromDate = DateUtil.addDaysToDate(this.toDate, 1);
      let newToDate = DateUtil.addDaysToDate(this.toDate, this.currentRangeDays + 1);
      this.fromDate = this.parseDate(newFromDate);
      this.toDate = this.parseDate(newToDate);

      this.confirmSelectedPresetKey();

      this.done();
    },
    moveBack() {
      let newFromDate = DateUtil.addMonthsToDate(this.fromDate, -this.customRangeMonths);
      this.fromDateChanged(newFromDate);
    },
    moveForward() {
      let newToDate = DateUtil.addMonthsToDate(this.toDate, this.customRangeMonths);
      this.toDateChanged(newToDate);
    },
    fromDateChanged(val: string | Date) {
      this.fromDate = this.parseDate(val);
      this.$emit("input:fromDate", this.fromDate);

      this.toDate = DateUtil.getToDateForRangeUsingFromDate(this.fromDate, this.customRangeMonths);
      this.$emit("input:toDate", this.toDate);
    },
    toDateChanged(val: string | Date) {
      this.toDate = this.parseDate(val);
      this.$emit("input:toDate", this.toDate);

      this.fromDate = DateUtil.getFromDateForRangeUsingToDate(this.toDate, this.customRangeMonths);
      this.$emit("input:fromDate", this.fromDate);
    },
    cancel() {
      // Reset the values of the TO and FROM dates using the original passed-in value
      var dates = this.value as (string | Date)[];
      let fromDate = undefined as Date | undefined;
      if (!!dates?.length && dates.length > 0) {
        fromDate = this.parseDate(dates[0]);
      }
      this.fromDate = fromDate;

      let toDate = undefined as Date | undefined;
      if (!!dates?.length && dates.length > 1) {
        toDate = this.parseDate(dates[1]);
      }
      this.toDate = toDate;

      this.confirmSelectedPresetKey();

      this.menuOpen = false;
    },
    done() {
      if (!this.fromDate || !this.toDate) return;

      var dates = [] as Date[];
      if (!!this.fromDate) {
        dates.push(this.fromDate);
      }
      if (!!this.toDate) {
        dates.push(this.toDate);
      }
      this.$emit("input", dates);
      this.menuOpen = false;
    },
    menuItemSelected(presetKey: string) {
      console.log(`menuItemSelected presetKey: ${presetKey}`);
      // If the user pressed custom, we always want to open the menu.
      if (presetKey == this.customPreset.key) {
        // Show FROM and TO date pickers
        if (!this.validCustomRange) {
          this.toDate = new Date();
          this.fromDate = DateUtil.getFromDateForRangeUsingToDate(
            this.toDate,
            this.customRangeMonths
          );
        }
        this.menuOpen = true;
        return;
      }

      var selectedPreset = (this.presets as DateRangePreset[]).find(x => x.key == presetKey);
      if (!selectedPreset) {
        console.log(`Unable to find preset with key "${presetKey}"`);
        return;
      }

      var fromDate = this.parseDate(selectedPreset.fromDate);
      this.fromDate = fromDate;
      var toDate = this.parseDate(selectedPreset.toDate);
      this.toDate = toDate;

      this.done();
    },
    parseDate(val: string | Date): Date {
      // The value passed in may include time, or be in a format we don't like so parse it to ISO first
      var isoDateString = DateUtil.isoDateString(val);
      // This is a DATE range picker with no time functionality so we ignore time
      let newFullDateTimeString = `${isoDateString} 00:00:00`;
      let dateVal = new Date(
        DateUtil.localizedDateTimeString(newFullDateTimeString, this.$props.format)
      );
      return dateVal;
    }
  },

  created: function() {
    var dates = this.value as (string | Date)[];

    let fromDate = undefined as Date | undefined;
    if (!!dates?.length && dates.length > 0) {
      fromDate = this.parseDate(dates[0]);
    }
    this.fromDate = fromDate;

    let toDate = undefined as Date | undefined;
    if (!!dates?.length && dates.length > 1) {
      toDate = this.parseDate(dates[1]);
    }
    this.toDate = toDate;
  },

  mounted: function() {
    this.confirmSelectedPresetKey();
  }
});

