import FDVue from "../";

export default FDVue.extend({
  name: "fd-add-button",

  props: {
    action: Function,
    cy: { type: String, default: "fd-add-button" },
    disabled: {
      type: Boolean,
      default: false
    },
    absolute: {
      type: Boolean,
      default: true
    },
    bottom: {
      type: Boolean,
      default: true
    },
    left: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    clicked(e: any): any {
      if (!!this.$props.action) this.$props.action();
      else this.$emit("click", e);
    }
  }
});

