import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { TranslateResult } from "vue-i18n";

type ItemSelectionDialogResult = string | undefined;

const ItemSelectionDialog = FDVue.extend({
  name: "fd-item-selection-dialog",

  mixins: [dialogSupport],

  components: {},

  data() {
    return {
      title: this.$t("common.additional-details") as string | TranslateResult,
      fieldLabel: this.$t("common.additional-details") as string | TranslateResult,
      rules: [] as any[],

      items: [] as any,
      itemText: "name" as string,
      itemValue: "id" as string,

      selectedValue: "" as string
    };
  },

  computed: {},

  mounted: async function() {},

  methods: {
    async open(
      title?: string | TranslateResult,
      label?: string | TranslateResult,
      rules?: any[],
      items?: any[],
      itemText?: string,
      itemValue?: string,
      selectedValue?: string
    ): Promise<ItemSelectionDialogResult> {
      if (!!title) this.title = title;
      if (!!label) this.fieldLabel = label;
      if (!!rules) this.rules = rules;
      if (!!items) this.items = items;
      if (!!itemText) this.itemText = itemText;
      if (!!itemValue) this.itemValue = itemValue;

      if (!!selectedValue) {
        this.selectedValue = selectedValue;
      } else if (this.items.length == 1 && !!(this.items[0] as any)[this.itemValue]) {
        this.selectedValue = (this.items[0] as any)[this.itemValue];
      }
      if (await this.showDialog()) return this.selectedValue;
      else return undefined;
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    acceptDialog() {
      if (!(this.$refs.form as HTMLFormElement).validate()) return;

      this.closeDialog(true);
    }
  }
});

export default ItemSelectionDialog;

export async function showItemSelectionDialog(
  title?: string | TranslateResult,
  label?: string | TranslateResult,
  rules?: any[],
  items?: any[],
  itemText?: string,
  itemValue?: string,
  selectedValue?: string
): Promise<ItemSelectionDialogResult> {
  let dialog = createDialog(ItemSelectionDialog);
  dialog.optOutOfErrorHandling();
  let result = await dialog.open(title, label, rules, items, itemText, itemValue, selectedValue);
  console.log("showItemSelectionDialog.result: " + JSON.stringify(result));
  return result;
}

