import FDVue from "@fd/lib/vue";
import rules from "@fd/lib/vue/rules";
import { TransferPartDetails } from "../../../services";

export default FDVue.extend({
  name: "fd-count-sheet-yard-selection-form",

  mixins: [rules],

  props: {
    processing: { type: Boolean, default: false },
    selectSourceYard: { type: Boolean, default: false },
    selectDestinationYard: { type: Boolean, default: false },
    sourceYardID: { type: String, default: undefined },
    destinationYardID: { type: String, default: undefined },
    allYards: { type: Array, default: () => [] },
    visibleYards: { type: Array, default: () => [] },
    returnedPartSources: { type: Array, default: () => [] }
  },

  computed: {},
  methods: {
    numberOfPartsSourcedByYardID(yardID: string): number {
      return (this.returnedPartSources as TransferPartDetails[]).filter(x => x.yardID == yardID)
        .length;
    }
  }
});

