import FDVue from "@fd/lib/vue";
import { mapMutations } from "vuex";
import {
  AccessCodeChallenge,
  accessCodeService,
  BasicContractor,
  contractorService,
  BasicDiscipline,
  disciplineService
} from "../services";

import rules from "@fd/lib/vue/rules";
import loginBranding from "../../../common/client/mixins/loginBranding";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import ServiceError from "@fd/lib/client-util/serviceError";
import { SortItemsWithName } from "../utils/person";
import { GroupableSelectItem } from "../../../lib/vue/utility/select";

enum SignupStages {
  EnterAccountInfo,
  EnterAccessCodeForActivation,
  StartOver,
  RequestedAccess
}

export default FDVue.extend({
  name: "sp-signup",

  mixins: [rules, errorHandling, loginBranding],

  components: {
    "fd-privacy-dialog": () => import("@fd/current/client/views/components/PrivacyDialog.vue"),
    "fd-terms-dialog": () => import("@fd/current/client/views/components/TermsDialog.vue"),
    "fd-code-entry": () => import("@fd/lib/vue/components/CodeEntry.vue")
  },

  watch: {
    signupStage(newValue, oldValue) {
      console.log(`SignupStage: ${SignupStages[oldValue]} -> ${SignupStages[newValue]}`);
    }
  },

  data: () => ({
    signupStage: SignupStages.EnterAccountInfo as SignupStages,
    // signupStage: SignupStages.EnterAccessCodeForActivation as SignupStages,
    // signupStage: SignupStages.RequestedAccess as SignupStages,

    firstname: "",
    lastname: "",
    emailAddressOrPhoneNumber: "",
    // email: "",
    // phone: "",
    contractorID: "" as string | null,
    allContractors: [] as BasicContractor[],
    disciplineID: "" as string | null,
    allDisciplines: [] as BasicDiscipline[],
    isRequestingAccess: false,

    isAccountExistsError: false,

    accessCodeChallenge: null as AccessCodeChallenge | null,
    accesscode: "" as string,
    processingAccessCodeRequest: false
  }),

  computed: {
    signupRules(): any {
      let nullOrHasValueRule = (value: any) => {
        return value == null || !!value.length || this.$t("common.rule-required");
      };
      return {
        firstName: [this.rules.required],
        lastName: [this.rules.required],
        companyName: [this.rules.required],
        emailAddressOrPhoneNumber: [
          this.rules.required,
          this.rules.validEmailOrPhoneNumberOrIdentifier
        ],
        contractor: [nullOrHasValueRule],
        discipline: [nullOrHasValueRule]
      };
    },

    showAccountInfo(): boolean {
      return this.showAccountInfoOnLoad || this.reshowAccountInfo;
    },
    showAccountInfoOnLoad(): boolean {
      return this.signupStage == SignupStages.EnterAccountInfo;
    },
    // Used to differentiate between email entry being shown on load vs going back to it, since restarting requires transitions for appearing
    reshowAccountInfo(): boolean {
      return this.signupStage == SignupStages.StartOver;
    },

    showAccessCodeEntryForActivation(): boolean {
      return this.signupStage == SignupStages.EnterAccessCodeForActivation;
    },

    showAccessSuccessfullyRequestedMessage(): boolean {
      return this.signupStage == SignupStages.RequestedAccess;
    },

    contractors(): GroupableSelectItem[] {
      let items: GroupableSelectItem[] = this.allContractors.map(x => ({
        text: x.name,
        value: x.id
      }));

      if (items.length) items.push({ divider: true });
      items.push({ text: "Other", value: null });

      return items;
    },

    disciplines(): GroupableSelectItem[] {
      let items: GroupableSelectItem[] = this.allDisciplines.map(x => ({
        text: x.name,
        value: x.id
      }));

      if (items.length) items.push({ divider: true });
      items.push({ text: "Other", value: null });

      return items;
    },

    languageslist() {
      return this.$store.state.languages.fullList;
    },

    language: {
      get() {
        return this.$store.state.language;
      },
      set(val) {
        if (val != undefined) {
          let language = this.$store.state.languages.fullList.find((x: any) => x.number == val);
          this.$store.commit("SET_PREFERRED_LANGUAGE", {
            shortCode: language.shortCode,
            shortCodeExt: language.shortCodeExt,
            number: language.number
          });
        }
      }
    },

    dialogPrivacy: {
      get() {
        return this.$store.state.dialogPrivacy;
      },
      set(val) {
        this.$store.commit("SET_PRIVACY_DIALOG", val);
      }
    },

    dialogTerms: {
      get() {
        return this.$store.state.dialogTerms;
      },
      set(val) {
        this.$store.commit("SET_TERMS_DIALOG", val);
      }
    }
  },

  methods: {
    /*
      GLOBAL
    */
    ...mapMutations({
      setShowAppBar: "SET_SHOW_APP_BAR",
      setShowDrawer: "SET_SHOW_DRAWER",
      setShowFooter: "SET_SHOW_FOOTER",
      setShowBottomBar: "SET_SHOW_BOTTOM_BAR",
      setPrivacyDialog: "SET_PRIVACY_DIALOG",
      setTermsDialog: "SET_TERMS_DIALOG"
    }),

    async loadContractors() {
      this.allContractors = SortItemsWithName(
        await contractorService.getPubliclyAvailableContractors()
      );
      if (!this.allContractors.length) this.contractorID = null;
      else if (this.allContractors.length == 1) this.contractorID = this.allContractors[0].id!;
    },

    async loadDisciplines() {
      this.allDisciplines = SortItemsWithName(
        await disciplineService.getPubliclyAvailableDisciplines()
      );
      if (!this.allDisciplines.length) this.disciplineID = null;
      else if (this.allDisciplines.length == 1) this.disciplineID = this.allDisciplines[0].id!;
    },

    async refreshUserReferenceData() {
      await Promise.all([
        this.$store.dispatch("LOAD_LANGUAGES"),
        this.loadContractors(),
        this.loadDisciplines()
      ]);
    },

    startOver() {
      this.inlineMessage.message = "";
      this.signupStage = SignupStages.StartOver;

      // Keep email as it will probably be the same
      this.accesscode = "";
      this.accessCodeChallenge = null;

      this.processing = false;
    },

    /*
      ACCESS CODE
    */
    startOverFromAccessCodeEntry() {
      this.startOver();

      (this.$refs.accesscodeentry as any)?.clear();

      this.processingAccessCodeRequest = false;
      this.processing = false;
    },

    // This function is fired by the user clicking on the button that requests an "Access Code"
    async sendAccessCode(e: Event) {
      e.preventDefault();

      if (!(this.$refs.enterAccountInfoForm as HTMLFormElement).validate()) {
        return;
      }

      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.processingAccessCodeRequest = true;

      let email = "";
      let phoneNumber = "";
      if (this.rules.validPhoneNumber(this.emailAddressOrPhoneNumber) == true) {
        phoneNumber = this.emailAddressOrPhoneNumber;
      } else if (this.rules.validEmail(this.emailAddressOrPhoneNumber) == true) {
        email = this.emailAddressOrPhoneNumber;
      }

      try {
        this.accessCodeChallenge = await accessCodeService.verifyUnusedPersonalInformationAndGenerateAccessCodeForSignup(
          this.firstname,
          this.lastname,
          this.contractorID,
          email,
          phoneNumber,
          this.$store.getters.languageCode
        );

        this.signupStage = SignupStages.EnterAccessCodeForActivation;

        this.accesscode = "";
        (this.$refs.accesscodeentry as any)?.clear();
        setTimeout(() => {
          (this.$refs.accesscodeentry as any)?.focus();
        }, 4000);
      } catch (err) {
        let error = err as ServiceError;
        if (error.statusCode == 409) {
          this.isAccountExistsError = error.message == "fdserverr_emailaddressalreadyinuse";
        }
        this.handleError(error as Error, "unexpected-network-error-short");
      } finally {
        this.processing = false;
        this.processingAccessCodeRequest = false;
      }
    },

    async resendAccessCode() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.processingAccessCodeRequest = true;

      try {
        await accessCodeService.resendAccessCodeForSignup(
          this.accessCodeChallenge!,
          this.$store.getters.languageCode
        );
      } catch (error) {
        this.inlineMessage.message = this.$t("unexpected-network-error-short");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
        this.processingAccessCodeRequest = false;

        this.accesscode = "";
        (this.$refs.accesscodeentry as any)?.clear();
        setTimeout(() => {
          (this.$refs.accesscodeentry as any)?.focus();
        }, 4000);
      }
    },

    // Event for when the last digit of the access code has been entered by the user
    codeEntered(codeString: string) {
      this.accesscode = codeString;
      this.processAccessCode();
    },

    async processAccessCode() {
      this.requestAccessWithAccessCode();
    },

    // Navigate the logged-in user to the application
    async requestAccessWithAccessCode() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      try {
        // Call service to confirm access code validity
        this.accessCodeChallenge = await accessCodeService.requestUserAccessWithAccessCode(
          this.accessCodeChallenge!,
          this.accesscode,
          this.isRequestingAccess,
          this.firstname,
          this.lastname,
          this.contractorID,
          this.disciplineID
        );

        let loginResult = this.accessCodeChallenge?.loginInformation;
        if (!!loginResult) {
          // Send request access call
          this.signupStage = SignupStages.RequestedAccess;
        } else {
          this.inlineMessage.message = this.$t("invalid-access-code");
          this.inlineMessage.type = "warning";
          // After a brief moment set the focus on the access code entry box
          this.accesscode = "";
          (this.$refs.accesscodeentry as any)?.clear();
          setTimeout(() => {
            (this.$refs.accesscodeentry as any)?.focus();
          }, 200);
        }
      } catch (error) {
        var message =
          (error as ServiceError).statusCode == 422
            ? this.$t("access-code-expired")
            : this.$t("unexpected-network-error-short");
        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";
      } finally {
        // These are to be uncommented when they can be properly setup against a real function
        this.processing = false;
      }
    },

    startOverFromEnd() {
      this.startOver();

      (this.$refs.accesscodeentry as any)?.clear();

      this.processingAccessCodeRequest = false;
      this.processing = false;
    }
  },

  created: async function() {
    // Tells the store to hide the various UI bars and drawers as appropriate.
    this.setShowAppBar(false);
    this.setShowDrawer(false);
    this.setShowFooter(false);
    this.setShowBottomBar(false);

    //Refresh any dependency data in the store.
    this.refreshUserReferenceData();
  }
});

