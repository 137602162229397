export default {
  name: "fd-login-allow-notifications-form",
  props: {
    contactMethods: { type: Array, default: () => [] },

    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    show: { type: Boolean, default: false },
    smallishMobileDevice: { type: Boolean, default: false },
    smallWidthBrowser: { type: Boolean, default: false }
  }
};

