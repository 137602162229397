var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: item =>
                                        _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || `/contractors/`
                                        )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "py-0 px-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pl-3 pr-3 pt-3" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-1 pb-md-0 pt-md-3",
                              attrs: { cols: "12", sm: "12", md: "6" }
                            },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  label: _vm.$t(
                                    "contractors.norms.description-label"
                                  ),
                                  disabled: _vm.processing,
                                  rules: [_vm.rules.required]
                                },
                                model: {
                                  value: _vm.norm.description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.norm, "description", $$v)
                                  },
                                  expression: "norm.description"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: _vm.$t("common.active"),
                                  disabled: _vm.processing
                                },
                                model: {
                                  value: _vm.norm.isActive,
                                  callback: function($$v) {
                                    _vm.$set(_vm.norm, "isActive", $$v)
                                  },
                                  expression: "norm.isActive"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: _vm.$t("common.archived"),
                                  disabled: _vm.processing
                                },
                                model: {
                                  value: _vm.norm.archived,
                                  callback: function($$v) {
                                    _vm.$set(_vm.norm, "archived", $$v)
                                  },
                                  expression: "norm.archived"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-form",
                    { ref: "form", on: { submit: _vm.onSubmit } },
                    [
                      !!_vm.inlineMessage.message
                        ? _c(
                            "v-row",
                            { staticClass: "mx-0", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _vm.inlineMessage.message
                                    ? _c(
                                        "v-alert",
                                        {
                                          staticClass: "mr-0",
                                          attrs: {
                                            type: _vm.inlineMessage.type
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "white-space": "pre-line"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.inlineMessage.message
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        { staticClass: "mx-1" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                link: "",
                                disabled: _vm.processing,
                                color: "error",
                                outlined: "",
                                small: _vm.$vuetify.breakpoint.xsOnly
                              },
                              on: { click: _vm.deleteItem }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("common.delete")) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                text: "",
                                link: "",
                                disabled: _vm.processing,
                                small: _vm.$vuetify.breakpoint.name == "xs"
                              },
                              on: { click: _vm.cancel }
                            },
                            [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                          ),
                          _vm._v(" "),
                          _c("fd-menu-save", {
                            attrs: {
                              disabled: _vm.processing,
                              loading: _vm.saving,
                              small: _vm.$vuetify.breakpoint.xsOnly
                            },
                            on: {
                              "click:save": function($event) {
                                return _vm.save(false)
                              },
                              "click:save-and-close": function($event) {
                                return _vm.save(true)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }