import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { ScaffoldBayWidth } from "../../../services";

const ScaffoldBayWidthNewDialog = FDVue.extend({
  name: "fd-scaffold-bay-width-new-dialog",

  mixins: [dialogSupport, rules],

  components: {},

  data: function() {
    return {
      saving: false,
      scaffoldBayWidth: {
        name: "",
        inches: 0
      } as ScaffoldBayWidth
    };
  },

  computed: {},

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        await this.addScaffoldBayWidth({
          ...this.scaffoldBayWidth,
          inches: !!this.scaffoldBayWidth.inches ? +this.scaffoldBayWidth.inches : undefined
        });
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    ...mapActions({
      addScaffoldBayWidth: "ADD_SCAFFOLD_BAY_WIDTH"
    })
  }
});

export default ScaffoldBayWidthNewDialog;

export async function createNewScaffoldBayWidth(): Promise<boolean> {
  let dialog = createDialog(ScaffoldBayWidthNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.showDialog!();
}

