var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticStyle: { display: "flex", "flex-wrap": "wrap", flex: "1 1 auto" } },
    [
      !_vm.hideArchivedSwitch
        ? _c("fd-show-archived-switch", {
            attrs: {
              value: _vm.showArchived,
              loading: _vm.$attrs.loading,
              disabled: _vm.$attrs.disabled || _vm.disableArchivedSwitch
            },
            on: { input: _vm.showArchivedChanged }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showArchived && !_vm.hideDateRangePicker
        ? _c("fd-date-range-picker", {
            attrs: {
              dense: "",
              value: _vm.showArchivedDateRange,
              "text-field-class": "mt-3",
              "text-field-style": "min-width: 260px; width: 260px;",
              min: _vm.minDate,
              max: _vm.maxDate,
              loading: _vm.$attrs.loading,
              disabled: _vm.$attrs.disabled,
              "hide-details": "",
              allowAllData: _vm.allowAllData
            },
            on: {
              input: _vm.dateRangeChanged,
              "input:fromDate": _vm.fromDateChanged,
              "input:toDate": _vm.toDateChanged
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }