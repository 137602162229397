import { TestPackage, testPackageService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

export type LoadTestPackagesBySystemIDParameter = {
  systemID: string;
  forcedArchivedState: boolean;
  archivedFromDate: Date | null;
  archivedToDate: Date | null;
};

var storeModule = createAutomaticCrudStoreModule({
  crudService: testPackageService,
  singularStoreName: "TEST_PACKAGE",
  pluralStoreName: "TEST_PACKAGES",
  localizationPrefix: "systems.test-packages",
  consumerRelatedIDProperty: {
    name: "testPackageID",
    type: "string"
  },
  storeExtensions: {
    actions: {
      async LOAD_TEST_PACKAGES_BY_SYSTEM_ID(
        context: any,
        payload: LoadTestPackagesBySystemIDParameter
      ): Promise<TestPackage[]> {
        var response = await testPackageService.getBySystemID(
          payload.systemID,
          payload.forcedArchivedState,
          payload.archivedFromDate,
          payload.archivedToDate
        );
        context.commit("SET_TEST_PACKAGES", response);
        return response;
      }
    }
  }
});

export default storeModule;

