export default function downloadBlob(fileContents: Blob, filename: string) {
  let linkElement = document.createElement("a"),
  url = URL.createObjectURL(fileContents);
  linkElement.href = url;
  linkElement.download = filename;
  document.body.appendChild(linkElement);
  linkElement.click();
  setTimeout(function() {
      document.body.removeChild(linkElement);
      window.URL.revokeObjectURL(url);  
  }, 0); 
}