var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-select", {
    class: [
      "fd-inspections-date-selection-width",
      "fd-inspections-date-selection",
      _vm.$vuetify.breakpoint.mdAndUp
        ? "fd-inspections-date-selection-md-width"
        : ""
    ],
    attrs: {
      outlined: "",
      dense: "",
      "hide-details": "",
      items: _vm.selectableTimeSegments,
      value: _vm.value,
      "return-object": ""
    },
    on: { input: _vm.valueChanged },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function({ item }) {
          return [
            _c(
              "v-row",
              { attrs: { "no-gutters": "", dense: "" } },
              [
                _c("v-col", { attrs: { cols: "10", md: "8" } }, [
                  _vm._v("\n        " + _vm._s(item.day) + "\n      ")
                ]),
                _vm._v(" "),
                _c(
                  "v-col",
                  { attrs: { cols: "2", md: "4" } },
                  [
                    _c(
                      "v-chip",
                      {
                        staticClass:
                          "fd-inspection-time-chip fd-selected-time-segment",
                        class: [
                          !_vm.$vuetify.breakpoint.mdAndUp
                            ? "fd-inspection-time-chip-xs-size"
                            : "",
                          item.style
                        ],
                        attrs: { small: "" }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "fd-inspection-time-chip-icon",
                            attrs: { small: "" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$store.getters.icon(
                                    `inspection-time-${item.style}`
                                  )
                                ) +
                                "\n          "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.$vuetify.breakpoint.mdAndUp
                          ? _c("span", [
                              item.text == "allday"
                                ? _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "inspections.times.all-day-label"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.text) +
                                        "\n            "
                                    )
                                  ])
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      },
      {
        key: "item",
        fn: function({ item, on, attrs }) {
          return [
            _c(
              "v-list-item",
              _vm._g(_vm._b({}, "v-list-item", attrs, false), on),
              [
                _c(
                  "v-list-item-content",
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "justify-center",
                        attrs: { "no-gutters": "" }
                      },
                      [
                        _c(
                          "v-chip",
                          {
                            staticClass:
                              "fd-inspection-time-chip-for-selection",
                            class: item.style
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "fd-inspection-time-chip-icon" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$store.getters.icon(
                                        `inspection-time-${item.style}`
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            item.text == "allday"
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "inspections.times.all-day-label"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.text) +
                                      "\n            "
                                  )
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }