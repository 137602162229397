import { Person, PersonWithDetails } from "../services";
import store from "../store";

export type HasContractorName = { contractorName: string };
export type HasName = { name: string | null | undefined };
export type PersonWithDetailsAndName = PersonWithDetails & HasName;
export type PersonWithName = Person & HasName;
export function JoinNameValues(
  firstName: string | null | undefined,
  secondName: string | null | undefined,
  separator: string = " "
) {
  var names = [];
  if (!!firstName) {
    names.push(firstName);
  }
  if (!!secondName) {
    names.push(secondName);
  }
  return names.join(separator);
}
export function CompareItemsWithName(a: HasName, b: HasName): number {
  let aName = a.name!.toLocaleLowerCase();
  let bName = b.name!.toLocaleLowerCase();
  if (aName < bName) return -1;
  else if (aName > bName) return 1;
  else return 0;
}
export function SortItemsWithName<T extends HasName>(items: T[] | undefined): T[] {
  if (!items) return [];
  return items.sort(CompareItemsWithName);
}
export function CompareItemsByStringProp(a: any, b: any, propName: string): number {
  let aName = a[propName]!.toLocaleLowerCase();
  let bName = b[propName]!.toLocaleLowerCase();
  if (aName < bName) return -1;
  else if (aName > bName) return 1;
  else return 0;
}
export function SortItemsByStringProp<T>(items: T[] | undefined, propName: string): T[] {
  if (!items) return [];
  return items.sort((a, b) => CompareItemsByStringProp(a, b, propName));
}
export function GetPersonName(
  item:
    | {
        firstName: string | null | undefined;
        lastName: string | null | undefined;
        employeeCode: string | null | undefined;
        employeeBadge: string | null | undefined;
      }
    | null
    | undefined,
  includeCode: boolean = false,
  codeInBrackets: boolean = true,
  includeBadge: boolean = false,
  badgeInBrackets: boolean = true
) {
  if (!item) return "";
  var names = [];
  if (!!item.firstName) {
    names.push(item.firstName);
  }
  if (!!item.lastName) {
    names.push(item.lastName);
  }
  if (!!item.employeeCode && includeCode) {
    if (codeInBrackets) names.push(`(${item.employeeCode})`);
    else names.push(`${item.employeeCode}`);
  }
  if (!!item.employeeBadge && includeBadge) {
    if (badgeInBrackets) names.push(`(${item.employeeBadge})`);
    else names.push(`${item.employeeBadge}`);
  }
  return names.join(" ");
}

export type SelectablePerson = PersonWithDetailsAndName | { header: string } | { divider: boolean };
export function PeopleFromSelectableList(list: SelectablePerson[]): PersonWithDetailsAndName[] {
  return list.filter(x => !!(x as any)["id"]).map(x => ({ ...x } as PersonWithDetailsAndName));
}

export function PersonHasEquipmentClassification(person: Person): boolean {
  return !!store.state.classifications.fullList.find(x => x.id == person.classificationID)
    ?.isEquipment;
}

