import { mapActions, mapMutations } from "vuex";
import FDVue from "@fd/lib/vue";
import { Language, QuestionWithText, questionService } from "../services";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import archivedDataList from "../dataMixins/archivedDataList";
import {
  FDColumnDirective,
  FDRowNavigateDirective,
  FDTableSortableDirective,
  SortableEvent
} from "@fd/lib/vue/utility/dataTable";
import { addDaysToDate, addMonthsToDate } from "@fd/lib/client-util/datetime";
import userAccess from "../dataMixins/userAccess";
import { truncateWithEllipsis } from "../../../lib/vue/utility/helper";
import { showQuestionNewDialog } from "./components/dialogs/SP.QuestionNewDialog.vue";

type QuestionWithArchived = QuestionWithText & { archived: boolean };
export default FDVue.extend({
  name: "sp-questions-list",

  mixins: [userAccess, archivedDataList],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    fdTableSortable: FDTableSortableDirective
  },

  data: function() {
    return {
      cachedQuestions: undefined as QuestionWithArchived[] | undefined,
      deleting: false,
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5
    };
  },

  computed: {
    languageslist(): Language[] {
      return this.$store.state.languages.fullList as Language[];
    },
    questions(): QuestionWithArchived[] {
      if (!this.cachedQuestions) {
        this.cachedQuestions = (this.$store.state.questions.fullList as QuestionWithArchived[])
          .map(x => ({ ...x } as QuestionWithArchived))
          .sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0));
      }
      // if (!this.cachedQuestions) {
      //   this.cachedQuestions = (this.$store.state.questions.fullList as QuestionWithArchived[])
      //     .map(x => ({ ...x } as QuestionWithArchived))
      //     .sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0));
      // }
      return this.cachedQuestions;
    },

    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    }
  },
  methods: {
    // *** GLOBAL ***
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadQuestions: "LOAD_QUESTIONS",
      updateQuestion: "UPDATE_QUESTION",
      deleteQuestion: "DELETE_QUESTION"
    }),
    async openNewDialog() {
      let lastOrder = 0;
      if (this.questions.length) lastOrder = this.questions.slice(-1)[0].displayOrder ?? 0;
      await showQuestionNewDialog(lastOrder);
      this.cachedQuestions = undefined;
    },
    async loadData() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      await this.loadQuestions({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });
      this.cachedQuestions = undefined;

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },

    async reloadTableData() {
      this.processing = true;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // the following works with the delete "Action" button in the Datatable.
    async deleteTableItem(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteQuestion({ id: item.id, name: item.name });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async flipArchived(item: QuestionWithArchived) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateQuestion({
          id: item.id,
          archivedDate: archivedDate,
          name: this.getQuestionText(item)
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    getQuestionText(question: QuestionWithText): string | null | undefined {
      if (!question.textByLanguageCode) return undefined;

      var languageNumber = this.$store.state.language;
      var selectedLanguage = this.languageslist.find(x => x.number == languageNumber);
      var languageCode = selectedLanguage?.shortCode ?? "en";

      var questionText = question.textByLanguageCode[languageCode];
      if (!questionText) {
        let key = Object.keys(question.textByLanguageCode)[0];
        questionText = question.textByLanguageCode[key];
      }
      return truncateWithEllipsis(questionText);
    },

    // *** SORTING ***
    async confirmItemOrdering() {
      console.log(`confirmItemOrdering total Questions: ${this.questions.length}`);

      let itemsToUpdate = [] as QuestionWithArchived[];

      this.questions.forEach((question, index) => {
        let newOrder = index + 1;
        if (!question.displayOrder || question.displayOrder != newOrder) {
          console.log(`    ${question.displayOrder} --> ${newOrder}`);
          question.displayOrder = newOrder;
          itemsToUpdate.push(question);
        }
      });

      if (itemsToUpdate.length > 0) {
        console.log(` updating ${itemsToUpdate.length} questions' ordering`);
        await questionService.updateQuestionOrders(itemsToUpdate);

        var snackbarPayload = {
          text: this.$t("questions.snack-bar-order-updated-message"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      }
    },
    async dragEnded(e: SortableEvent) {
      console.log(`dragEnded`);
      let oldIndex = e.oldIndex ?? 0;
      let newIndex = e.newIndex ?? 0;
      if (oldIndex == newIndex) return;

      this.questions.splice(newIndex, 0, ...this.questions.splice(oldIndex, 1));

      this.processing = true;
      try {
        await this.confirmItemOrdering();
      } catch (error) {
        this.handleError(error as Error);
        await this.loadData();
      } finally {
        this.processing = false;
      }
    }
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "questions",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: ""
    });
    this.notifyNewBreadcrumb({
      text: this.$t("questions.list.title"),
      to: "/questions",
      resetHistory: true
    });

    this.processing = true;
    try {
      await this.loadData();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

