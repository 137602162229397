import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import userAccess from "../dataMixins/userAccess";
import { mapActions, mapMutations } from "vuex";
import { Yard, YardType } from "../services";
import rules from "@fd/lib/vue/rules";

type YardWithArchived = Yard & { isArchived: boolean };

export default FDVue.extend({
  name: "fd-yard-existing",

  mixins: [userAccess, serviceErrorHandling, rules],

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      deleting: false,

      yard: {} as YardWithArchived,
      allYardTypes: (Object.keys(YardType)
        .filter(x => !isNaN(Number(x)))
        .map(x => Number(x)) as number[]).map(x => {
        return {
          value: x,
          text: this.$t(`yards.type.${YardType[x].toLocaleLowerCase()}`)
        };
      }),

      //The following object is used in conjunction with the breadcrumbs that are presented to the user for sub-view navigation.
      slidein: false
    };
  },

  computed: {
    yardRules() {
      return {
        name: [this.rules.required],
        yardTypeID: [this.rules.required]
      };
    }
  },

  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadYard: "LOAD_YARD",
      updateYard: "UPDATE_YARD",
      deleteYard: "DELETE_YARD"
    }),
    cancel() {
      this.$router.push("/yards");
    },
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = null;

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        var archivedDate = null;
        if (this.yard.isArchived && !this.yard.archivedDate) {
          archivedDate = new Date(new Date().toUTCString());
        }
        this.yard.archivedDate = archivedDate;

        await this.updateYard(this.yard);
        if (closeOnComplete) {
          this.$router.push("/yards");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      this.deleting = true;
      try {
        await this.deleteYard({
          id: this.$route.params.id,
          name: this.yard.name
        });
        this.$router.push("/yards");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    }
  },

  watch: {
    yard() {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/yards") {
        this.notifyNewBreadcrumb({
          text: this.$t("yards.list.title"),
          to: "/yards",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: this.yard.name,
        to: `/yards/${this.$route.params.id}`
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);
    this.processing = true;
    try {
      await Promise.all([this.loadYard(this.$route.params.id)]);
      let yard = this.$store.state.yards.fullList.find((x: any) => x.id == this.$route.params.id);
      this.yard = {
        ...yard,
        isArchived: !!yard.archivedDate,
        created: undefined,
        createdBy: undefined,
        updated: undefined,
        updatedBy: undefined
      };
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});


