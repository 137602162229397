import FDVue from "../index";
import twoWayProps from "../mixins/twoWayProps";
import * as DateUtil from "../../client-util/datetime";

export default FDVue.extend({
  name: "fd-time-picker",
  mixins: [twoWayProps],

  props: {
    label: String,
    value: [Date, String],
    rules: Array,
    disabled: { default: false, type: Boolean },
    outlined: { default: true, type: Boolean },
    clearable: { default: false, type: Boolean },
    i18nLocale: { default: "en-us", type: String },
    cy: { type: String, default: undefined },
    dataCy: { type: String, default: "fd-time-picker" }
  },

  twoWayProps: ["value"],

  data: () => ({
    menuOpen: false
  }),

  computed: {
    valueString: {
      get(): string {
        console.log(
          `TimePicker.valueString.get value="${
            this.twoWayProps.value
          }", valueString="${DateUtil.isoTimeString(this.twoWayProps.value)}"`
        );
        return DateUtil.isoTimeString(this.twoWayProps.value);
      },
      set(val: string) {
        // We capture the date of the current time value, so we can use it when resetting the value with the newly selected time
        // This prevents the new time from removing the passed-in time's date value
        let dateString = DateUtil.isoDateString(this.twoWayProps.value);
        let newFullDateTimeString = `${dateString} ${val}`;
        this.twoWayProps.value = new Date(DateUtil.localizedDateTimeString(newFullDateTimeString));
      }
    },
    formattedTime(): string {
      return this.twoWayProps!.value
        ? DateUtil.stripDateFromLocalizedDateTime(this.twoWayProps!.value)
        : "";
    }
  }
});
