var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { staticStyle: { background: "#f2f2f2" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [_vm._v(_vm._s(_vm.$t("workorders.new-dialog-title")))]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-expansion-panels",
            {
              staticClass: "br-0",
              attrs: { flat: "" },
              model: {
                value: _vm.panel,
                callback: function($$v) {
                  _vm.panel = $$v
                },
                expression: "panel"
              }
            },
            [
              _c(
                "v-expansion-panel",
                {
                  staticClass: "secondary-section-style",
                  attrs: { disabled: _vm.processing }
                },
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(
                      _vm._s(_vm.$t("workorders.new-dialog-bulk-upload-title"))
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-expansion-panel-content",
                    { staticClass: "no-left-right-bottom-padding" },
                    [
                      _c("fd-work-order-bulk-upload-form", {
                        attrs: { closeAction: _vm.cancelDialog },
                        on: { processingUpdated: _vm.processingUpdated }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }