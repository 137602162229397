import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { isoDateWithOffsetString } from "@fd/lib/client-util/datetime";

type ThreeWeekLookAheadParametersDialogResult = Date | undefined;

const ThreeWeekLookAheadParametersDialog = FDVue.extend({
  name: "sp-three-week-look-ahead-parameters-dialog",

  mixins: [dialogSupport, rules],

  components: {},
  directives: {},

  data() {
    return {
      date: new Date()
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return ["open"];
    },
    calculatedSundayDate(): Date | string | null | undefined {
      // console.log(`calculatedSundayDate`);
      // console.log(`\t date: ${this.date} (${isoDateWithOffsetString(this.date)})`);
      let sundayDate = new Date(isoDateWithOffsetString(this.date));
      // console.log(`\t sundayDate BEFORE: ${sundayDate}`);
      // console.log(`\t\t getDate(): ${sundayDate.getDate()}`);
      // console.log(`\t\t getDay(): ${sundayDate.getDay()}`);
      sundayDate.setDate(sundayDate.getDate() - sundayDate.getDay());
      // console.log(`\t sundayDate AFTER: ${sundayDate}`);
      return sundayDate;
    }
  },

  watch: {},

  mounted: async function() {},

  methods: {
    async open(): Promise<ThreeWeekLookAheadParametersDialogResult> {
      return await this.showDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(undefined);
    },

    async acceptDialog() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        this.closeDialog(this.calculatedSundayDate);
      } catch (error) {
      } finally {
        this.processing = false;
      }
    }
  }
});

export default ThreeWeekLookAheadParametersDialog;

export async function showThreeWeekLookAheadParametersDialog(): Promise<
  ThreeWeekLookAheadParametersDialogResult
> {
  let dialog = createDialog(ThreeWeekLookAheadParametersDialog);
  dialog.optOutOfErrorHandling();
  let result = await dialog.open();
  console.log("showThreeWeekLookAheadParametersDialog.result: " + JSON.stringify(result));
  return result;
}

