var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "grey" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("privacy.title")) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "v-container",
            { staticClass: "fd-privacy-terms" },
            [
              _c("v-card-text", { staticClass: "pt-0" }, [
                _c("h3", { staticClass: "mb-3" }, [
                  _vm._v(
                    _vm._s(_vm.$t("privacy.last-updated", [_vm.lastUpdated]))
                  )
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.$t("privacy.policy.line1")))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.$t("privacy.policy.line2")))]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacy.policy.interpretations-and-definitions.header"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacy.policy.interpretations-and-definitions.interpretations.header"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacy.policy.interpretations-and-definitions.interpretations.line1"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacy.policy.interpretations-and-definitions.definitions.header"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacy.policy.interpretations-and-definitions.definitions.line1"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition1"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition1-explanation"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition2"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition2-explanation"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition3"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition3-explanation",
                              [_vm.$store.state.applicationName]
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.$store.state.themeName != "venture"
                    ? _c("li", [
                        _c("p", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "privacy.policy.interpretations-and-definitions.definitions.definition4"
                                )
                              )
                            )
                          ]),
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "privacy.policy.interpretations-and-definitions.definitions.definition4-explanation",
                                  [_vm.companyName, _vm.companyAddress]
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition5"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition5-explanation",
                              [_vm.companyCountry]
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition6"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition6-explanation"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition7"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition7-explanation"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition8"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition8-explanation"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition9"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition9-explanation"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition10"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition10-explanation"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition11"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.interpretations-and-definitions.definitions.definition11-explanation"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("privacy.policy.collecting-personal-data.header")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacy.policy.collecting-personal-data.types-of-data.header"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("h3", { staticClass: "pt-2" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.types-of-data.personal-data.header"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.types-of-data.personal-data.line1"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("p", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.collecting-personal-data.types-of-data.personal-data.line2"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.collecting-personal-data.types-of-data.personal-data.line3"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.collecting-personal-data.types-of-data.personal-data.line4"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.collecting-personal-data.types-of-data.personal-data.line5"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("h3", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.types-of-data.personal-data.usage-data.header"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.types-of-data.personal-data.usage-data.line1"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.types-of-data.personal-data.usage-data.line2"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.types-of-data.personal-data.usage-data.line3"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.types-of-data.personal-data.usage-data.line4"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("h3", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.types-of-data.personal-data.information-collected.header"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.types-of-data.personal-data.information-collected.line1"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.types-of-data.personal-data.information-collected.line2"
                          )
                        ) +
                        "\n          "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.types-of-data.personal-data.information-collected.line3"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.types-of-data.personal-data.information-collected.line4"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacy.policy.collecting-personal-data.use-of-personal-data.header"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacy.policy.collecting-personal-data.use-of-personal-data.line1"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.collecting-personal-data.use-of-personal-data.line2a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.use-of-personal-data.line2b"
                          )
                        ) + "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.collecting-personal-data.use-of-personal-data.line3a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.use-of-personal-data.line3b"
                          )
                        ) + "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.collecting-personal-data.use-of-personal-data.line4a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.use-of-personal-data.line4b"
                          )
                        ) + "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.collecting-personal-data.use-of-personal-data.line5a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.use-of-personal-data.line5b"
                          )
                        ) + "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.collecting-personal-data.use-of-personal-data.line6a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.use-of-personal-data.line6b"
                          )
                        ) + "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.collecting-personal-data.use-of-personal-data.line7a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.use-of-personal-data.line7b"
                          )
                        ) + "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.collecting-personal-data.use-of-personal-data.line8a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.use-of-personal-data.line8b"
                          )
                        ) + "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacy.policy.collecting-personal-data.use-of-personal-data.line9a"
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.use-of-personal-data.line9b"
                          )
                        ) + "\n            "
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacy.policy.collecting-personal-data.use-of-personal-data.line10"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.use-of-personal-data.line11a"
                          )
                        )
                      )
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.use-of-personal-data.line11b"
                        )
                      ) + "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.use-of-personal-data.line12a"
                          )
                        )
                      )
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.use-of-personal-data.line12b"
                        )
                      ) + "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.use-of-personal-data.line13a"
                          )
                        )
                      )
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.use-of-personal-data.line13b"
                        )
                      ) + "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.use-of-personal-data.line14a"
                          )
                        )
                      )
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.use-of-personal-data.line14b"
                        )
                      ) + "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.use-of-personal-data.line15a"
                          )
                        )
                      )
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.use-of-personal-data.line15b"
                        )
                      ) + "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.use-of-personal-data.line16a"
                          )
                        )
                      )
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.use-of-personal-data.line16b"
                        )
                      ) + "\n          "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "pt-3" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.retention-of-data.header"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacy.policy.collecting-personal-data.retention-of-data.line1"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacy.policy.collecting-personal-data.retention-of-data.line2"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacy.policy.collecting-personal-data.transfer-of-data.header"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacy.policy.collecting-personal-data.transfer-of-data.line1"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacy.policy.collecting-personal-data.transfer-of-data.line2"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacy.policy.collecting-personal-data.transfer-of-data.line3"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacy.policy.collecting-personal-data.disclosure-of-data.header"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("h3", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.disclosure-of-data.business-transactions.header"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.disclosure-of-data.business-transactions.line1"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("h3", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.header"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.line1"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("h3", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.other-legal.header"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.other-legal.line1"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.other-legal.line2"
                          )
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.other-legal.line3"
                          )
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.other-legal.line4"
                          )
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.other-legal.line5"
                          )
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t(
                            "privacy.policy.collecting-personal-data.disclosure-of-data.law-enforcement.other-legal.line6"
                          )
                        ) +
                        "\n          "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "pt-4" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "privacy.policy.collecting-personal-data.security-of-personal-data.header"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacy.policy.collecting-personal-data.security-of-personal-data.line1"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(
                    _vm._s(_vm.$t("privacy.policy.childrens-privacy.header"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("privacy.policy.childrens-privacy.line1"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("privacy.policy.childrens-privacy.line2"))
                  )
                ]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(
                    _vm._s(_vm.$t("privacy.policy.links-to-websites.header"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("privacy.policy.links-to-websites.line1"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("privacy.policy.links-to-websites.line2"))
                  )
                ]),
                _vm._v(" "),
                _c("h1", [
                  _vm._v(
                    _vm._s(_vm.$t("privacy.policy.changes-to-policy.header"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("privacy.policy.changes-to-policy.line1"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("privacy.policy.changes-to-policy.line2"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("privacy.policy.changes-to-policy.line3"))
                  )
                ]),
                _vm._v(" "),
                _vm.$store.state.themeName != "venture"
                  ? _c("h1", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("privacy.policy.contact-us.header")) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.$store.state.themeName != "venture"
                  ? _c("p", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("privacy.policy.contact-us.line1")) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.$store.state.themeName != "venture"
                  ? _c("ul", [
                      _c("li", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("privacy.policy.contact-us.line2")) +
                            "\n            "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.companyContactURL,
                              rel: "external nofollow noopener",
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(_vm.companyContactURL))]
                        )
                      ])
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("common.close")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }