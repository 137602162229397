import { mapMutations, mapActions } from "vuex";
import { filterByTags } from "../services/taggableItems";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import userAccess from "../dataMixins/userAccess";
import archivedDataList from "../dataMixins/archivedDataList";
import { SupplierWithTags } from "../services";
import { stripHtml, truncateWithEllipsis } from "../../../lib/vue/utility/helper";
import { createNewSupplier } from "./components/dialogs/SP.SupplierNewDialog.vue";

type SupplierWithArchived = SupplierWithTags & {
  archived: boolean;
};

export default FDVue.extend({
  name: "fd-suppliers",

  mixins: [errorHandling, userAccess, archivedDataList],

  components: {},

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: () => ({
    archivedLoading: false,
    // Used to track the the auto-reload for the table data
    reloadTimer: null as NodeJS.Timeout | null,
    dataReloadMinutes: 5
  }),

  computed: {
    // suppliers() {
    //   return filterByTags(this.tagsSelectedForFiltering, this.$store.state.suppliers.fullList);
    // },
    suppliers(): SupplierWithArchived[] {
      return (filterByTags(
        this.tagsSelectedForFiltering,
        this.$store.state.suppliers.fullList
      ) as SupplierWithTags[]).map(x => {
        return {
          ...x,
          description: truncateWithEllipsis(stripHtml(x.description)),
          archived: !!x.archivedDate
        } as SupplierWithArchived;
      });
    },
    tagsInUse() {
      return this.$store.getters.getSortedInUseTags(this.$store.state.suppliers.fullList);
    },

    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    tagsSelectedForFiltering: {
      get(): any[] {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.tagsForFiltering;
      },
      set(val: any[]) {
        this.$store.commit("SET_TAGS_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    async openNewDialog() {
      this.optOutOfErrorHandling();
      await createNewSupplier();
    },

    // the following works with the delete "Action" button in the Datatable.
    async deleteTableItem(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteSupplier({ id: item.id, name: item.name });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }
    },
    async flipArchived(item: SupplierWithArchived) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateSupplier({
          id: item.id,
          archivedDate: archivedDate,
          name: item.name
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async reloadTableData() {
      this.processing = true;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadData() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      await this.loadSuppliers({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },

    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadSuppliers: "LOAD_SUPPLIERS",
      loadTags: "LOAD_TAGS",
      updateSupplier: "UPDATE_SUPPLIER",
      deleteSupplier: "DELETE_SUPPLIER"
    })
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "suppliers",
      parentalContext: null,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      suppliersForFiltering: [],
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: new Date(0),
      showArchivedForFilteringToDate: new Date()
    });

    this.notifyNewBreadcrumb({
      text: this.$t("suppliers.list.title"),
      to: "/suppliers",
      resetHistory: true
    });

    this.processing = true;
    try {
      await Promise.all([this.loadData(), this.loadTags()]);
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

