import FDVue from "@fd/lib/vue";
import rules from "@fd/lib/vue/rules";
import serviceErrorHandling from "../../../../../lib/vue/mixins/serviceErrorHandling";
import {
  PartGenerationType,
  ScaffoldBayHeight,
  ScaffoldBayLength,
  ScaffoldBayWidth,
  ScaffoldSubType,
  ScaffoldType,
  walkdownReferenceDataService
} from "../../../services";
import { SelectListOption } from "@fd/lib/vue/utility/select";

type EstimateLike = {
  id: string | undefined;
  generationTypeID: PartGenerationType | undefined;
  scaffoldTypeID: ScaffoldType;
  scaffoldSubTypeID: ScaffoldSubType;
  notes: string | undefined;
  length: number | null | undefined;
  width: number | null | undefined;
  height: number | null | undefined;
  scaffoldBayLengthID: string | null | undefined;
  scaffoldBayWidthID: string | null | undefined;
  scaffoldBayHeightID: string | null | undefined;
  deckLevels: number | null | undefined;
  accessType: number | null | undefined;
  scaffoldDistanceModifierID: string | null | undefined;
  scaffoldElevationModifierID: string | null | undefined;
  scaffoldHeightModifierID: string | null | undefined;
  buildDismantleRatioID: string | null | undefined;
  scaffoldCongestionFactorID: string | null | undefined;
  internalModifierID: string | null | undefined;
  hoardingModifierID: string | null | undefined;
  barricadeGates: number | null | undefined;
  factor1: number | null | undefined;
  factor2: number | null | undefined;
  modificationPercent: number | null | undefined;
  estimatedTotalErectMinutes: number | null | undefined;
  estimatedTotalDismantleMinutes: number | null | undefined;
  estimatedTotalModifyMinutes: number | null | undefined;
  estimatedTotalMobilizationMinutes: number | null | undefined;
  estimatedTotalDemobilizationMinutes: number | null | undefined;
  estimatedTotalHoardingMinutes: number | null | undefined;
  estimatedTotalWeight: number | null | undefined;
  estimatedTotalPartCount: number | null | undefined;
  crewSize: number | null | undefined;
  workDays: number | null | undefined;
  modificationHours: number | null | undefined;
  isEstimateGenerationSuccessful: boolean | undefined;
  created: Date | undefined;
  createdBy: string | null | undefined;
  updated: Date | null | undefined;
  updatedBy: string | null | undefined;
  archivedDate: Date | null | undefined;
};

export default FDVue.extend({
  name: "sp-lwh-estimate-form",
  mixins: [rules, serviceErrorHandling],
  components: { "fd-duration-picker": () => import("@fd/lib/vue/components/DurationPicker.vue") },

  props: {
    estimate: { type: Object, default: () => ({}) },
    isModifyRequest: { type: Boolean },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      saving: false,

      accessTypes: [
        {
          id: 0,
          name: this.$t("walkdowns.existing-walkdown.access-type-none")
        },
        {
          id: 1,
          name: this.$t("walkdowns.existing-walkdown.access-type-ladder")
        }
      ],

      allScaffoldBayWidths: [] as ScaffoldBayWidth[],
      allScaffoldBayLengths: [] as ScaffoldBayLength[],
      allScaffoldBayHeights: [] as ScaffoldBayHeight[],

      crewSizeOptions: Array.from(Array(16).keys()),
      workDayOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      workTimePerDay: 0 as number
    };
  },
  computed: {
    walkdown(): EstimateLike {
      return this.estimate;
    },
    selectableScaffoldBayLengths(): ScaffoldBayLength[] {
      var length = this.walkdown.length;
      if (!length) return this.allScaffoldBayLengths;

      var lengthInInches = length * 12.0;
      return this.allScaffoldBayLengths.map(
        x =>
          ({
            ...x,
            disabled: (x.inches ?? 0) > lengthInInches
          } as SelectListOption<ScaffoldBayLength>)
      );
      // return this.allScaffoldBayLengths.filter(x => (x.inches ?? 0) <= lengthInInches);
    },

    selectableScaffoldBayWidths(): ScaffoldBayWidth[] {
      var width = this.walkdown.width;
      if (!width) return this.allScaffoldBayWidths;

      var widthInInches = width * 12.0;
      return this.allScaffoldBayWidths.map(
        x =>
          ({
            ...x,
            disabled: (x.inches ?? 0) > widthInInches
          } as SelectListOption<ScaffoldBayWidth>)
      );
      // return this.allScaffoldBayWidths.filter(x => (x.inches ?? 0) <= widthInInches);
    },

    selectableScaffoldBayHeights(): ScaffoldBayHeight[] {
      return this.allScaffoldBayHeights;
    },

    numberOfLengthBays(): string {
      var scaffoldLengthFeet = this.walkdown.length ?? 0;
      var scaffoldLength = scaffoldLengthFeet * 12;
      var selectedBayLength = this.allScaffoldBayLengths.find(
        x => x.id == this.walkdown.scaffoldBayLengthID
      );
      var bayLength = selectedBayLength?.inches ?? 0;
      var bays = !!bayLength && bayLength > 0 ? Math.floor(scaffoldLength / bayLength) : 0;
      return `${bays}`;
    },

    numberOfWidthBays(): string {
      var scaffoldWidthFeet = this.walkdown.width ?? 0;
      var scaffoldWidth = scaffoldWidthFeet * 12;
      var selectedBayWidth = this.allScaffoldBayWidths.find(
        x => x.id == this.walkdown.scaffoldBayWidthID
      );
      var bayWidth = selectedBayWidth?.inches ?? 0;
      var bays = !!bayWidth && bayWidth > 0 ? Math.floor(scaffoldWidth / bayWidth) : 0;
      return `${bays}`;
    },
    // Data is stored in the SP1 db with a value called "ScaffoldType"
    // Possible values are:
    // - 0 = regular (volume) scaffold
    // - 1 = hard barricade
    scaffoldIsHardBarricade: {
      get(): boolean {
        return this.walkdown.scaffoldTypeID == ScaffoldType.Barricade;
      }
    }
  },
  watch: {
    processing(value) {
      this.$emit("update:processing", value);
    },
    estimate(newValue) {
      this.workTimePerDay =
        !this.walkdown.crewSize ||
        this.walkdown.crewSize == 0 ||
        !this.walkdown.workDays ||
        this.walkdown.workDays == 0
          ? 0
          : (this.walkdown.modificationHours ?? 0) /
            (this.walkdown.crewSize * this.walkdown.workDays);
    },
    workTimePerDay(newValue) {
      console.log(` workTimePerDay changed: ${newValue}`);
      this.walkdown.modificationHours =
        (this.walkdown.crewSize ?? 0) * (this.walkdown.workDays ?? 1) * newValue;
      this.valueChanged(this.walkdown.modificationHours);
    },
    "walkdown.length": function(newValue) {
      if (!this.walkdown.scaffoldBayLengthID) return;

      var selectedSelectableBayLength = this.selectableScaffoldBayLengths.find(
        x => x.id == this.walkdown.scaffoldBayLengthID
      );
      if (!selectedSelectableBayLength) {
        this.walkdown.scaffoldBayLengthID = null;
      }
    },
    "walkdown.width": function(newValue) {
      if (!this.walkdown.scaffoldBayWidthID) return;

      var selectedSelectableBayWidth = this.selectableScaffoldBayWidths.find(
        x => x.id == this.walkdown.scaffoldBayWidthID
      );
      if (!selectedSelectableBayWidth) {
        this.walkdown.scaffoldBayWidthID = null;
      }
    },
    "walkdown.height": function(newValue) {
      if (!this.walkdown.scaffoldBayHeightID) return;

      var selectedSelectableBayHeight = this.selectableScaffoldBayHeights.find(
        x => x.id == this.walkdown.scaffoldBayHeightID
      );
      if (!selectedSelectableBayHeight) {
        this.walkdown.scaffoldBayHeightID = null;
      }
    },
    "walkdown.crewSize": function(newValue, oldValue) {
      this.walkdown.modificationHours =
        newValue * (this.walkdown.workDays ?? 1) * this.workTimePerDay;
      this.valueChanged(this.walkdown.modificationHours);
    },
    "walkdown.workDays": function(newValue, oldValue) {
      this.walkdown.modificationHours =
        (this.walkdown.crewSize ?? 0) * newValue * this.workTimePerDay;
      this.valueChanged(this.walkdown.modificationHours);
    }
  },
  methods: {
    valueChanged(v: any) {
      this.$emit("change", v);
    },
    // DOES NOT manage processing or error message logic
    async loadScaffoldBayLengths(): Promise<void> {
      this.allScaffoldBayLengths = (
        await walkdownReferenceDataService.getAllScaffoldBayLengths()
      ).sort((a, b) => (a.inches ?? 0) - (b.inches ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldBayWidths(): Promise<void> {
      this.allScaffoldBayWidths = (
        await walkdownReferenceDataService.getAllScaffoldBayWidths()
      ).sort((a, b) => (a.inches ?? 0) - (b.inches ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldBayHeights(): Promise<void> {
      this.allScaffoldBayHeights = (
        await walkdownReferenceDataService.getAllScaffoldBayHeights()
      ).sort((a, b) => (a.meters ?? 0) - (b.meters ?? 0));
    },
    validate(): boolean {
      return (this.$refs.lwhform as HTMLFormElement).validate();
    }
  },

  mounted: async function() {
    this.processing = true;
    try {
      // Due to the data binding, we need to load all the reference data before we bind the actual walkdown object
      // However, we dont' want the data to load until the popup is visible so this has to be done AFTER calling the `showDialog` method
      await Promise.all([
        this.loadScaffoldBayLengths(),
        this.loadScaffoldBayWidths(),
        this.loadScaffoldBayHeights()
      ]);
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }

    if (!!this.walkdown?.id) {
      this.workTimePerDay =
        !this.walkdown.crewSize ||
        this.walkdown.crewSize == 0 ||
        !this.walkdown.workDays ||
        this.walkdown.workDays == 0
          ? 0
          : (this.walkdown.modificationHours ?? 0) /
            (this.walkdown.crewSize * this.walkdown.workDays);
    }
  },

  created: async function() {}
});

