import { TranslateResult } from "vue-i18n";
import FDVue from "../../../../lib/vue";
import { ScaffoldForList } from "../../services";
import { PropType } from "vue";
import { FDColumnDirective, FDRowNavigateDirective } from "../../../../lib/vue/utility/dataTable";
import { VDataTable } from "../../../../lib/vue/types";

export type FormattedScaffoldForList = ScaffoldForList & {
  scaffoldStatusName: string | TranslateResult | null | undefined;
  daysStanding: number | undefined;
  areaName: string | null | undefined;
  subAreaName: string | null | undefined;
  scaffoldContractorName: string | null | undefined;
  plannedErect: string | undefined;
  actualErect: string | undefined;
  plannedDismantle: string | undefined;
  actualDismantle: string | undefined;
  archived: boolean;
  workPackageNames: string[] | undefined;
  prefixedTagNumber: string | null | undefined;
};

export default FDVue.extend({
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },
  props: {
    scaffolds: { type: Array as PropType<Array<FormattedScaffoldForList>> },

    tablesearch: { type: String },
    processing: { type: Boolean, default: false },

    showArchived: { type: Boolean, default: false },
    showArchivedMinDate: { type: Date },
    showArchivedMaxDate: { type: Date },
    showArchivedDateRange: { type: Array },
    archivedLoading: { type: Boolean, default: false }
  },
  data: function() {
    return {
      // Table Footer page size options
      itemsPerPage: 25,
      itemsPerPageOptions: [5, 10, 15, 25, 50, -1],
      item: {} as FormattedScaffoldForList
    };
  },
  computed: {
    datatable(): VDataTable {
      return this.$refs.datatable as VDataTable;
    },
    expanderColSpan(): number {
      if (this.$vuetify.breakpoint.xs) {
        return this.showArchived ? 5 : 4;
      } else if (this.$vuetify.breakpoint.sm) {
        return this.showArchived ? 11 : 10;
      } else if (this.$vuetify.breakpoint.md) {
        return this.showArchived ? 14 : 13;
      }

      return 25;
    }
  },
  methods: {
    customSort(
      items: FormattedScaffoldForList[],
      sortBy: string[],
      sortDesc: boolean[],
      locale: string
    ) {
      let desc = sortDesc[0] == true;
      let temp = {} as FormattedScaffoldForList;
      let propName = sortBy[0];
      // Examine the property trying to be sorted, and switch it to the ACTUAL property name of the object
      // This prop name is the name of the item in the table, and therefore sometimes won't match the object property.
      if (propName === "plannedErect") {
        propName = "plannedErectDate";
      } else if (propName === "actualErect") {
        propName = "actualErectDate";
      } else if (propName === "plannedDismantle") {
        propName = "plannedDismantleDate";
      }

      items.sort((a: FormattedScaffoldForList, b: FormattedScaffoldForList) => {
        let val1 = (a as any)[propName];
        let val2 = (b as any)[propName];
        if (val1 < val2) {
          return desc ? 1 : -1;
        } else if (val1 > val2) {
          return desc == true ? -1 : 1;
        } else {
          return 0;
        }
      });
      return items;
    },
    navigatePressed(item: FormattedScaffoldForList) {
      this.$emit("navigate", item.id!);
    },
    showArchivedChanged(v: any) {
      console.log(`SPScaffoldsList.showArchivedChanged v: ${v}`);
      this.$emit("update:showArchived", v);
    },
    showArchivedDateRangeChanged(v: any) {
      this.$emit("update:showArchivedDateRange", v);
    }
  }
});

