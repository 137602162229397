import { TranslateResult } from "vue-i18n";
import FDVue from "../../../lib/vue";
import { ScaffoldSubType, ScaffoldType } from "../services";
import i18n from "../i18n";

export var scaffoldTypes = [
  {
    text: i18n.t(`work-order-estimates.scaffold-types.${ScaffoldType.Independent}`),
    value: ScaffoldType.Independent
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-types.${ScaffoldType.BirdCage}`),
    value: ScaffoldType.BirdCage
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-types.${ScaffoldType.Specialty}`),
    value: ScaffoldType.Specialty
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-types.${ScaffoldType.Access}`),
    value: ScaffoldType.Access
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-types.${ScaffoldType.Barricade}`),
    value: ScaffoldType.Barricade
  }
];
export var independentSubTypes = [
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.Independent00_10M}`),
    value: ScaffoldSubType.Independent00_10M
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.Independent10_30M}`),
    value: ScaffoldSubType.Independent10_30M
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.Independent30_50M}`),
    value: ScaffoldSubType.Independent30_50M
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.Independent50PlusM}`),
    value: ScaffoldSubType.Independent50PlusM
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.IndependentTower}`),
    value: ScaffoldSubType.IndependentTower
  }
];
export var birdCageSubTypes = [
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.Birdcage000_050M}`),
    value: ScaffoldSubType.Birdcage000_050M
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.Birdcage050_200M}`),
    value: ScaffoldSubType.Birdcage050_200M
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.Birdcage200_500M}`),
    value: ScaffoldSubType.Birdcage200_500M
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.Birdcage500PlusM}`),
    value: ScaffoldSubType.Birdcage500PlusM
  }
];
export var specialtySubTypes = [
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.SpecialtyCantilever}`),
    value: ScaffoldSubType.SpecialtyCantilever
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.SpecialtySuspended}`),
    value: ScaffoldSubType.SpecialtySuspended
  },
  {
    text: i18n.t(
      `work-order-estimates.scaffold-sub-types.${ScaffoldSubType.SpecialtyBridgingSpan}`
    ),
    value: ScaffoldSubType.SpecialtyBridgingSpan
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.SpecialtyRamp}`),
    value: ScaffoldSubType.SpecialtyRamp
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.SpecialtyPlatform}`),
    value: ScaffoldSubType.SpecialtyPlatform
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.SpecialtyShed}`),
    value: ScaffoldSubType.SpecialtyShed
  }
];
export var accessSubTypes = [
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.AccessLadder}`),
    value: ScaffoldSubType.AccessLadder
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.AccessStairTower}`),
    value: ScaffoldSubType.AccessStairTower
  }
];
export var barricadeSubTypes = [
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.BarricadeSingleRail}`),
    value: ScaffoldSubType.BarricadeSingleRail
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.BarricadeDoubleRail}`),
    value: ScaffoldSubType.BarricadeDoubleRail
  },
  {
    text: i18n.t(`work-order-estimates.scaffold-sub-types.${ScaffoldSubType.BarricadeTripleRail}`),
    value: ScaffoldSubType.BarricadeTripleRail
  }
];
export function allScaffoldSubTypesForType(
  scaffoldTypeID: ScaffoldType
): { text: string | TranslateResult; value: number }[] {
  switch (scaffoldTypeID) {
    case ScaffoldType.Independent:
      return independentSubTypes;
    case ScaffoldType.BirdCage:
      return birdCageSubTypes;
    case ScaffoldType.Access:
      return accessSubTypes;
    case ScaffoldType.Specialty:
      return specialtySubTypes;
    case ScaffoldType.Barricade:
      return barricadeSubTypes;
    default:
      return [];
  }
}

export default FDVue.extend({
  computed: {
    allScaffoldTypes(): { text: string | TranslateResult; value: number }[] {
      return scaffoldTypes;
    },
    independentSubTypes(): { text: string | TranslateResult; value: number }[] {
      return independentSubTypes;
    },
    birdCageSubTypes(): { text: string | TranslateResult; value: number }[] {
      return birdCageSubTypes;
    },
    specialtySubTypes(): { text: string | TranslateResult; value: number }[] {
      return specialtySubTypes;
    },
    accessSubTypes(): { text: string | TranslateResult; value: number }[] {
      return accessSubTypes;
    },
    barricadeSubTypes(): { text: string | TranslateResult; value: number }[] {
      return barricadeSubTypes;
    }
  },
  methods: {
    allScaffoldSubTypesForType(
      scaffoldTypeID: ScaffoldType
    ): { text: string | TranslateResult; value: number }[] {
      return allScaffoldSubTypesForType(scaffoldTypeID);
    }
  }
});

