var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { "data-cy": "testpackagenew" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("systems.test-packages.new.dialog-title"))
                  )
                ]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12" } },
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              "data-cy": "systems",
                              label: _vm.$t("test-packages.system-name"),
                              items: _vm.systems,
                              "item-text": "name",
                              "item-value": "id",
                              disabled: !_vm.chooseSystem || _vm.processing,
                              "hide-details": !_vm.chooseSystem,
                              dense: !_vm.chooseSystem
                            },
                            model: {
                              value: _vm.testPackage.systemID,
                              callback: function($$v) {
                                _vm.$set(_vm.testPackage, "systemID", $$v)
                              },
                              expression: "testPackage.systemID"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              label: _vm.$t("common.name"),
                              disabled: _vm.processing,
                              rules: _vm.testPackageRules.name
                            },
                            model: {
                              value: _vm.testPackage.name,
                              callback: function($$v) {
                                _vm.$set(_vm.testPackage, "name", $$v)
                              },
                              expression: "testPackage.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              label: _vm.$t("common.code"),
                              disabled: _vm.processing,
                              rules: _vm.testPackageRules.code
                            },
                            model: {
                              value: _vm.testPackage.code,
                              callback: function($$v) {
                                _vm.$set(_vm.testPackage, "code", $$v)
                              },
                              expression: "testPackage.code"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("fd-rich-textarea", {
                            attrs: {
                              label: _vm.$t("common.description"),
                              disabled: _vm.processing,
                              rules: _vm.testPackageRules.description
                            },
                            model: {
                              value: _vm.testPackage.description,
                              callback: function($$v) {
                                _vm.$set(_vm.testPackage, "description", $$v)
                              },
                              expression: "testPackage.description"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.inlineMessage.message
                ? _c(
                    "v-row",
                    { staticClass: "px-3" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "mt-0 mb-3",
                              attrs: { type: _vm.inlineMessage.type }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.inlineMessage.message) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        disabled: _vm.processing,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.cancelDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.processing,
                        disabled: _vm.processing,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.saveDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.save-and-close")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }