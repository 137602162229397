import { tsaService } from "../../services/index";
import { createAutomaticCrudStoreModule } from "../automatic";

const storeModule = createAutomaticCrudStoreModule({
  crudService: tsaService,
  singularStoreName: "TSA",
  pluralStoreName: "TSAS",
  localizationPrefix: "build-sheet-setup.tabs.paint.tsas",
  consumerRelatedIDProperty: {
    name: "tsaID",
    type: "string"
  }
});

export default storeModule;

