var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", attrs: { "data-cy": "questionsexisting" } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: () =>
                                        _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || `/questions`
                                        )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c("v-subheader", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("questions.new.enter-question-text-title")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _vm._l(_vm.questionTextList, function(questionText) {
                            return _c(
                              "v-col",
                              {
                                key: questionText.languageCode,
                                attrs: { cols: "12", sm: "12" }
                              },
                              [
                                _c("fd-text-field", {
                                  attrs: {
                                    label: questionText.languageName,
                                    disabled: _vm.processing,
                                    rules: [_vm.hasTextRule]
                                  },
                                  model: {
                                    value: questionText.text,
                                    callback: function($$v) {
                                      _vm.$set(questionText, "text", $$v)
                                    },
                                    expression: "questionText.text"
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: _vm.$t("common.archived"),
                                  disabled: _vm.processing
                                },
                                model: {
                                  value: _vm.question.archived,
                                  callback: function($$v) {
                                    _vm.$set(_vm.question, "archived", $$v)
                                  },
                                  expression: "question.archived"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.inlineMessage.message
                        ? _c(
                            "v-row",
                            { staticClass: "mx-0", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-alert",
                                    { attrs: { type: _vm.inlineMessage.type } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-line"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.inlineMessage.message)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "mx-1" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            link: "",
                            disabled: _vm.processing,
                            loading: _vm.deleting,
                            color: "error",
                            outlined: "",
                            small: _vm.$vuetify.breakpoint.name == "xs"
                          },
                          on: { click: _vm.deleteItem }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.delete")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            "data-cy": "close",
                            text: "",
                            link: "",
                            disabled: _vm.saving || _vm.deleting,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancel }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.cancel")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("fd-menu-save", {
                        attrs: {
                          disabled: _vm.processing,
                          loading: _vm.saving,
                          small: _vm.$vuetify.breakpoint.xsOnly
                        },
                        on: {
                          "click:save": function($event) {
                            return _vm.save(false)
                          },
                          "click:save-and-close": function($event) {
                            return _vm.save(true)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }