import { workCategoryService } from "../../services/index";
import { createAutomaticCrudStoreModule } from "../automatic";

const storeModule = createAutomaticCrudStoreModule({
  crudService: workCategoryService,
  singularStoreName: "WORK_CATEGORY",
  pluralStoreName: "WORK_CATEGORIES",
  localizationPrefix: "build-sheet-setup.tabs.paint.work-categories",
  consumerRelatedIDProperty: {
    name: "workCategoryID",
    type: "string"
  }
});

export default storeModule;

