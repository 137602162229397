import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { mapMutations } from "vuex";
import rules from "@fd/lib/vue/rules";
import { personalEmailAddressService, PersonalEmailAddress } from "@fd/current/client/services";
import { showAccessCodeEntryDialog } from "./components/AccessCodeEntryDialog.vue";
import userAccess from "@fd/current/client/dataMixins/userAccess";

type PersonalEmailAddressWithVerified = PersonalEmailAddress & { isVerified: boolean };
export default FDVue.extend({
  name: "fd-email-address-existing",

  mixins: [errorHandling, rules, userAccess],

  components: {
    "fd-back-button": () => import("@fd/lib/vue/components/BackButton.vue")
  },

  data() {
    return {
      // The following will control whether the controls on screen are disabled while we are conducting any screen
      // wide actions.
      processing: false,

      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      pageHasLoaded: false,

      slidein: false,

      //Simple object for the form data
      emailAddress: {} as PersonalEmailAddressWithVerified
    };
  },

  computed: {
    emailAddressRules() {
      return {
        emailAddress: [this.rules.required, this.rules.validEmail]
      };
    }
  },

  methods: {
    onSubmit(e: any) {
      e.preventDefault();
      this.save(false);
    },

    async verifyEmailAddress() {
      if (!this.emailAddress?.emailAddress) return;

      this.optOutOfErrorHandling();
      if (await showAccessCodeEntryDialog(this.emailAddress.emailAddress!, null)) {
        await this.loadEmailAddress(this.$route.params.emailAddressID);
      }
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      this.inlineMessage.message = null;

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        await personalEmailAddressService.updateItem(this.$route.params.emailAddressID, {
          ...this.emailAddress,
          emailAddress: this.emailAddress.isVerified ? undefined : this.emailAddress.emailAddress
        });
        // this.$router.push(`/areas/${this.subArea.parentLocationID}`);
        if (closeOnComplete) {
          this.$router.back();
        }
      } catch (error) {
        this.handleError(error, "users.save-network-error");
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // Method used in conjunction with the Delete button.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await personalEmailAddressService.deleteItem(this.$route.params.emailAddressID);
        this.$router.back();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.back();
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB"
    }),
    async loadEmailAddress(id: any) {
      this.processing = true;
      try {
        let emailAddress = await personalEmailAddressService.getByID(id);
        this.emailAddress = {
          ...emailAddress,
          isVerified: !!emailAddress.verifiedDate
        } as PersonalEmailAddressWithVerified;
        this.pageHasLoaded = !!this.emailAddress;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }
    }
  },

  created: async function() {
    this.processing = true;

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    try {
      await this.loadEmailAddress(this.$route.params.emailAddressID);
      this.notifyNewBreadcrumb({
        text: this.emailAddress.emailAddress,
        to: `/emailaddress/${this.$route.params.emailAddressID}`
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.processing = false;
    }
  }
});

