import FDVue from "@fd/lib/vue";
export default FDVue.extend({
  data() {
    return {
      statusItems: [
        {
          text: this.$t("common.notstarted"),
          value: "Not Started"
        },
        {
          text: this.$t("common.inprogress"),
          value: "In Progress"
        },
        {
          text: this.$t("common.completed"),
          value: "Completed"
        }
      ]
    };
  }
});
