import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { PartWithTags } from "../../../services";
import { parseBCountString } from "../../../utils/bcount";

type PartQuantity = { partID: string; quantity: number };
type DialogReturnType = PartQuantity[] | undefined;

const BCountBottomDialog = FDVue.extend({
  components: {
    "sp-designed-estimate-form": () => import("../forms/SP.EstimateDesignedForm.vue")
  },
  mixins: [dialogSupport],
  data: function() {
    return {
      bCountString: ""
    };
  },
  computed: {
    unwatchedMethodNames(): string[] {
      return ["open"];
    },
    allParts(): PartWithTags[] {
      return this.$store.state.parts.fullList as PartWithTags[];
    },
    bCountPartQuantities(): PartQuantity[] {
      let partQuantities = parseBCountString(this.bCountString);
      let bCountPartQuantities = partQuantities
        .filter(pq => this.allParts.findIndex(p => p.name == pq.code) !== -1)
        .map(pq => {
          let part = this.allParts.find(p => p.name == pq.code)!;
          return {
            partID: part.id,
            quantity: pq.quantity
          } as PartQuantity;
        });

      return bCountPartQuantities;
    }
  },
  watch: {},
  methods: {
    async open(mountPoint?: Element | undefined): Promise<DialogReturnType> {
      this.optOutOfErrorHandling();
      return await this.showDialog!(mountPoint);
    },
    cancelDialog() {
      this.closeDialog(undefined);
    },
    saveDialog() {
      return this.closeDialog(this.bCountPartQuantities);
    }
  }
});

export default BCountBottomDialog;
export async function openBCountBottomDialog(
  parent?: Vue | Element | null | undefined
): Promise<DialogReturnType> {
  let dialog = createDialog(BCountBottomDialog);
  dialog.optOutOfErrorHandling();
  let mountPoint = undefined;
  if (!!parent) {
    mountPoint = document.createElement("div");
    mountPoint.id = "mountPoint";
    if (!!(parent as Vue)) (parent as Vue).$el.appendChild(mountPoint);
    else if (!!(parent as Element)) (parent as Element).appendChild(mountPoint);
  }
  return await dialog.open(mountPoint);
}

