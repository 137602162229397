import FDVue from "@fd/lib/vue";
import { mapState } from "vuex";
import { NoteWithSenderDetails } from "../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import i18n from "../i18n";
import VueI18n from "vue-i18n";

export type NoteWithAdditionalDetails = NoteWithSenderDetails & {
  isPinned: boolean;
  isNew: boolean;
  initials: string;
  name: string;
  role: string;
  date: string;
  time: string;
};

export function ParseNoteWithSenderDetails(note: NoteWithSenderDetails): NoteWithAdditionalDetails {
  var names = [] as string[];
  var initials = [] as string[];
  if (!!note.sender?.firstName) {
    names.push(note.sender.firstName);
    initials.push(note.sender.firstName.toUpperCase()[0]);
  }
  if (!!note.sender?.lastName) {
    names.push(note.sender.lastName);
    initials.push(note.sender.lastName.toUpperCase()[0]);
  }
  var senderInitials = initials.join("");
  var senderName = names.join(" ");
  var formattedSentDate = DateUtil.stripTimeFromLocalizedDateTime(note.sentTime);
  var formattedSentTime = DateUtil.stripDateFromLocalizedDateTime(note.sentTime);
  var role = "" as string | VueI18n.TranslateResult;
  if (note.sender?.isPlanner) {
    role = i18n.t("people.security.roles.planner");
  } else if (note.sender?.isCoordinator) {
    role = i18n.t("people.security.roles.coordinator");
  } else if (note.sender?.isGeneralForeman) {
    role = i18n.t("people.security.roles.general-foreman");
  } else if (note.sender?.isForeman) {
    role = i18n.t("people.security.roles.foreman");
  }

  return {
    ...note,
    isPinned: false,
    isNew: false,
    initials: senderInitials,
    name: senderName,
    role: role,
    date: formattedSentDate,
    time: formattedSentTime
  } as NoteWithAdditionalDetails;
}

function compareNotes(a: NoteWithAdditionalDetails, b: NoteWithAdditionalDetails): number {
  // Start by sorting pinned.  Pinned are always on top
  if (a.isPinned != b.isPinned) return a.isPinned ? -1 : 1;
  // being DTOs instead of DateTimes has these returned as a string instead of a date object
  return new Date(b.sentTime!).getTime() - new Date(a.sentTime!).getTime();
}

export function SortNotesArray<T extends NoteWithAdditionalDetails>(notes: T[]): T[] {
  return notes.sort(compareNotes);
}

export default FDVue.extend({
  components: {
    "fd-history-note-timeline-item": () =>
      import("../views/components/NoteHistoryTimelineItem.vue"),
    "fd-new-note-timeline-item": () => import("../views/components/NoteNewTimelineItem.vue")
  },
  data: function() {
    return {
      notes: [] as NoteWithAdditionalDetails[],
      newNoteText: ""
    };
  },
  computed: {
    ...mapState(["avatarInitials"])
  }
});

