import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { TranslateResult } from "vue-i18n";
import { LemStatus, LEMStatusLogWithDetails } from "../../../services";
import * as DateUtil from "@fd/lib/client-util/datetime";

const LEMStatusHistoryDialog = FDVue.extend({
  name: "fd-lem-status-history-dialog",

  mixins: [dialogSupport],

  components: {
    "fd-log-history-form": () => import("../forms/LogHistoryForm.vue")
  },

  data: function() {
    return {
      statusLogs: [] as LEMStatusLogWithDetails[]
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return [
        "formatLogChangedDate",
        "getLogActionColor",
        "getLogActionIcon",
        "getLogActionString"
      ];
    }
  },

  methods: {
    async open(statusLogs: LEMStatusLogWithDetails[]) {
      this.statusLogs = statusLogs;
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.cancelDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    formatLogChangedDate(log: LEMStatusLogWithDetails): string {
      if (!log.changed) return "";
      var changedDate = DateUtil.localizedDateTimeString(log.changed);
      return changedDate;
    },
    getLogActionColor(log: LEMStatusLogWithDetails): string {
      console.log(`getLogActionColor`);
      var actionIcon = "";
      if (log.lemStatusID == LemStatus.Declined) {
        actionIcon = "warning";
      } else if (log.lemStatusID == LemStatus.Approved) {
        actionIcon = "success";
      } else if (log.lemStatusID == LemStatus.Cancelled) {
        actionIcon = "error";
      }
      return actionIcon;
    },
    getLogActionIcon(log: LEMStatusLogWithDetails): string {
      console.log(`getLogActionIcon`);
      var actionIcon = "fa-paper-plane";
      if (log.lemStatusID == LemStatus.Declined) {
        actionIcon = "fas fa-exclamation-circle";
      } else if (log.lemStatusID == LemStatus.Approved) {
        actionIcon = "far fa-check";
      } else if (log.lemStatusID == LemStatus.Cancelled) {
        actionIcon = "far fa-ban";
      }
      return actionIcon;
    },
    getLogActionString(log: LEMStatusLogWithDetails): string | TranslateResult {
      console.log(`getLogActionString`);
      var changedBy = log.changedByName;
      if (log.lemStatusID == LemStatus.Submitted) {
        return this.$t("lems.approval.submitted-log-action-description", [changedBy]);
      } else if (log.lemStatusID == LemStatus.Approved) {
        return this.$t("lems.approval.approved-log-action-description", [changedBy]);
      } else if (log.lemStatusID == LemStatus.Declined) {
        return this.$t("lems.approval.declined-log-action-description", [changedBy]);
      } else if (log.lemStatusID == LemStatus.Cancelled) {
        return this.$t("lems.approval.cancelled-log-action-description", [changedBy]);
      }
      return "";
    }
  },

  created: async function() {}
});

export default LEMStatusHistoryDialog;

export async function showLEMStatusHistoryDialog(
  statusLogs: LEMStatusLogWithDetails[]
): Promise<boolean> {
  let dialog = createDialog(LEMStatusHistoryDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(statusLogs);
}

