import FDItemList from "@fd/lib/vue/components/FDItemList";
import { mapActions } from "vuex";
import { createNewWorkOrderDialog } from "./components/WorkOrderNewDialog.vue";
//import { WorkOrder } from "../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import store from "../store";
import statusData from "../dataMixins/statusData";
import userAccess from "../dataMixins/userAccess";

export default FDItemList.extend({
  name: "fd-work-orders-list",

  mixins: [statusData, userAccess],

  components: {},

  data: () => ({
    // Overrides from FDItemList
    listRouteUrl: "workorders",
    title: "Work Orders"
  }),

  computed: {
    items() {
      // let workPackages: WorkPackage[] = this.$store.state.workPackages.fullList;
      // let formattedWorkPackages = workPackages.map(function(x) {
      //   return {
      //     ...x,
      //     formattedStartDate: DateUtil.stripTimeFromLocalizedDateTime(x.startDate),
      //     formattedFinishDate: DateUtil.stripTimeFromLocalizedDateTime(x.finishDate)
      //   };
      // });
      // return formattedWorkPackages;
    }
  },

  methods: {
    openNewDialog() {
      this.optOutOfErrorHandling();
      createNewWorkOrderDialog();
    },

    async refreshItemList() {
      //await this.loadWorkPackages(this.statusesForFiltering);
    },

    async deleteItem(workOrder: any) {
      //await store.dispatch("DELETE_WORK_PACKAGE", workPackage);
    },

    async flipEnabled(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      // try {
      //   await this.updateWorkPackage({ ...item, enabled: !item.enabled });
      // } catch (error) {
      //   this.inlineMessage.message = this.$t("workorders.save-network-error");
      //   this.inlineMessage.type = "error";
      // } finally {
      //   this.processing = false;
      // }
    },

    ...mapActions({
      // loadWorkPackages: "LOAD_WORK_PACKAGES_WITH_STATUSES",
      // updateWorkPackage: "UPDATE_WORK_PACKAGE",
      // loadTags: "LOAD_TAGS"
    })
  }
});
