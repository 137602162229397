import { mapActions, mapMutations } from "vuex";
import FDVue from "../../../lib/vue";
import serviceErrorHandling from "../../../lib/vue/mixins/serviceErrorHandling";
import userAccess from "../dataMixins/userAccess";
import { Language, QuestionWithText } from "../services";
import { truncateWithEllipsis } from "../../../lib/vue/utility/helper";

type QuestionTextWithLanguage = { text: string; languageCode: string; languageName: string };
type QuestionWithArchived = QuestionWithText & { archived: boolean };

export default FDVue.extend({
  name: "sp-question-existing",

  mixins: [serviceErrorHandling, userAccess],

  data: function() {
    return {
      saving: false,
      deleting: false,
      slidein: false,
      question: {} as QuestionWithArchived,
      questionTextList: [] as QuestionTextWithLanguage[]
    };
  },

  computed: {
    languageslist(): Language[] {
      return this.$store.state.languages.fullList as Language[];
    },
    questionText(): string | null | undefined {
      if (!this.questionTextList.length) return undefined;
      // if (!this.question.textByLanguageCode) return undefined;

      var languageNumber = this.$store.state.language;
      var selectedLanguage = this.languageslist.find(x => x.number == languageNumber);
      var languageCode = selectedLanguage?.shortCode ?? "en";

      var questionText = this.questionTextList.find(x => x.languageCode == languageCode)?.text;
      if (!questionText?.length) {
        questionText = this.questionTextList[0].text;
      }
      return truncateWithEllipsis(questionText);
    },
    hasText(): boolean {
      return !!this.questionText?.length;
    }
  },
  watch: {
    question(newValue) {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/questions") {
        this.notifyNewBreadcrumb({
          text: this.$t("questions.list.title"),
          to: "/questions",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: this.questionText,
        to: `/questions/${this.$route.params.id}`
      });
    }
  },
  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadQuestion: "LOAD_QUESTION",
      updateQuestion: "UPDATE_QUESTION",
      deleteQuestion: "DELETE_QUESTION"
    }),
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },
    cancel() {
      // Method used in conjunction with the Cancel button.
      this.$router.push("/questions");
    },
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        this.inlineMessage.message = this.$t("questions.new.text-missing-error-message");
        return;
      }
      this.saving = true;
      this.processing = true;
      try {
        for (let questionText of this.questionTextList) {
          if (
            !this.question.textByLanguageCode ||
            !this.question.textByLanguageCode[questionText.languageCode!]
          ) {
            continue;
          }

          this.question.textByLanguageCode[questionText.languageCode!] = questionText.text;
        }

        if (!this.question.archived) {
          this.question.archivedDate = null;
        } else if (this.question.archived && !this.question.archivedDate) {
          this.question.archivedDate = new Date(new Date().toUTCString());
        }
        await this.updateQuestion({
          ...this.question,
          name: this.questionText
        });
        if (closeOnComplete) {
          this.$router.push("/questions");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    async deleteItem() {
      this.inlineMessage.message = null;

      this.saving = true;
      this.processing = true;
      try {
        await this.deleteQuestion({
          ...this.question,
          name: this.questionText
        });

        this.$router.push("/questions");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    hasTextRule(): boolean {
      return !!this.hasText;
    }
  },
  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/questions") {
      this.notifyNewBreadcrumb({
        text: this.$t("questions.list.title"),
        to: "/questions",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }

    this.notifyNewBreadcrumb({
      text: this.$t("loading-dot-dot-dot"),
      disabled: true
    });

    this.processing = true;
    try {
      await Promise.all([this.loadQuestion(this.$route.params.id)]);

      let question = this.$store.state.questions.fullList.find(
        (x: any) => x.id == this.$route.params.id
      );

      this.question = {
        ...question,
        archived: !!question.archivedDate
      } as QuestionWithArchived;

      for (let lang of this.languageslist) {
        this.questionTextList.push({
          text: question.textByLanguageCode[lang.shortCode!],
          languageCode: lang.shortCode,
          languageName: lang.name
        } as QuestionTextWithLanguage);
      }
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

