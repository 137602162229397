import { global, symbolExists } from "./polyfillSupport";

void (function() {
  if (!symbolExists("TextEncoder")) {
    global.TextEncoder = require("./cheapTextEncoding").TextEncoder;
  } else if (!TextEncoder.prototype.encodeInto) {
    TextEncoder.prototype.encodeInto = require("./cheapTextEncoding").encodeIntoPolyfill;
  }
  if (!symbolExists("TextDecoder")) {
    global.TextEncoder = require("./cheapTextEncoding").TextDecoder;
  }

  if (!Blob.prototype.text) {
    Blob.prototype.text = require("./blobToText").textPrototype;
  }
})();
