var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        {},
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              !!_vm.canEditBuildSheet
                ? _c("v-select", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.processing,
                      label: _vm.$t("Work Category"),
                      items: _vm.workCategories,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.workCategoryID,
                      "hide-details": ""
                    },
                    on: { input: v => _vm.$emit("update:workCategoryID", v) }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.canEditBuildSheet
                ? _c("fd-text-field", {
                    attrs: {
                      disabled: _vm.processing,
                      readonly: true,
                      label: _vm.$t("Work Category"),
                      value: _vm.$lookup.workCategory(_vm.workCategoryID),
                      "hide-details": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("fd-text-field", {
                staticClass: "mb-3",
                attrs: {
                  disabled: _vm.processing,
                  readonly: !_vm.canEditBuildSheet,
                  label: _vm.$t("Equipment Number"),
                  value: _vm.equipmentNumber,
                  "hide-details": ""
                },
                on: { input: v => _vm.$emit("update:equipmentNumber", v) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", sm: "6" } },
            [
              !!_vm.canEditBuildSheet
                ? _c("v-select", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.processing,
                      label: _vm.$t("Paint Code"),
                      items: _vm.paintCodes,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.paintCodeID,
                      "hide-details": ""
                    },
                    on: { input: v => _vm.$emit("update:paintCodeID", v) }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.canEditBuildSheet
                ? _c("fd-text-field", {
                    attrs: {
                      disabled: _vm.processing,
                      readonly: true,
                      label: _vm.$t("Paint Code"),
                      value: _vm.$lookup.paintCode(_vm.paintCodeID),
                      "hide-details": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-row",
                {},
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-1 pt-0 pb-0", attrs: { cols: "4" } },
                    [
                      _c("fd-text-field", {
                        staticClass: "mb-3",
                        attrs: {
                          disabled: _vm.processing,
                          readonly: !_vm.canEditBuildSheet,
                          label: _vm.$t(
                            "norms.work-order-entry-dialog-paint.diameter"
                          ),
                          value: _vm.diameter,
                          "hide-details": ""
                        },
                        on: { input: v => _vm.$emit("update:diameter", v) }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pr-1 pt-0 pb-0", attrs: { cols: "4" } },
                    [
                      _c("fd-text-field", {
                        staticClass: "mb-3",
                        attrs: {
                          disabled: _vm.processing,
                          readonly: !_vm.canEditBuildSheet,
                          label: _vm.$t(
                            "norms.work-order-entry-dialog-paint.sf2"
                          ),
                          value: _vm.squareFeet,
                          "hide-details": ""
                        },
                        on: { input: v => _vm.$emit("update:squareFeet", v) }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pt-0 pb-0", attrs: { cols: "4" } },
                    [
                      _c("fd-text-field", {
                        staticClass: "mb-3",
                        attrs: {
                          disabled: _vm.processing,
                          readonly: !_vm.canEditBuildSheet,
                          label: _vm.$t(
                            "norms.work-order-entry-dialog-paint.linear-feet"
                          ),
                          value: _vm.linearFeet,
                          "hide-details": ""
                        },
                        on: { input: v => _vm.$emit("update:linearFeet", v) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-row",
                {},
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      !!_vm.canEditBuildSheet
                        ? _c("v-select", {
                            attrs: {
                              outlined: "",
                              disabled: _vm.processing,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog-paint.set-up-type"
                              ),
                              items: _vm.setUpTypes,
                              "item-text": "name",
                              "item-value": "id",
                              value: _vm.setUpTypeID,
                              "hide-details": ""
                            },
                            on: {
                              input: v => _vm.$emit("update:setUpTypeID", v)
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.canEditBuildSheet
                        ? _c("fd-text-field", {
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog-paint.set-up-type"
                              ),
                              value: _vm.$lookup.setUpType(_vm.setUpTypeID),
                              "hide-details": ""
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      !!_vm.canEditBuildSheet
                        ? _c("v-select", {
                            attrs: {
                              outlined: "",
                              disabled: _vm.processing,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog-paint.application-type"
                              ),
                              items: _vm.applicationTypes,
                              "item-text": "name",
                              "item-value": "id",
                              value: _vm.applicationTypeID,
                              "hide-details": ""
                            },
                            on: {
                              input: v =>
                                _vm.$emit("update:applicationTypeID", v)
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.canEditBuildSheet
                        ? _c("fd-text-field", {
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog-paint.application-type"
                              ),
                              value: _vm.$lookup.applicationType(
                                _vm.applicationTypeID
                              ),
                              "hide-details": ""
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "pa-0 fd-build-sheet-padding-margin-removal-switch"
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        attrs: { label: "Prep", "hide-details": "" },
                        model: {
                          value: _vm.paintreason.prep,
                          callback: function($$v) {
                            _vm.$set(_vm.paintreason, "prep", $$v)
                          },
                          expression: "paintreason.prep"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        attrs: { label: "Touch Up", "hide-details": "" },
                        model: {
                          value: _vm.paintreason.touchup,
                          callback: function($$v) {
                            _vm.$set(_vm.paintreason, "touchup", $$v)
                          },
                          expression: "paintreason.touchup"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        attrs: { label: "Prime", "hide-details": "" },
                        model: {
                          value: _vm.paintreason.prime,
                          callback: function($$v) {
                            _vm.$set(_vm.paintreason, "prime", $$v)
                          },
                          expression: "paintreason.prime"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        attrs: { label: "Damage", "hide-details": "" },
                        model: {
                          value: _vm.paintreason.damage,
                          callback: function($$v) {
                            _vm.$set(_vm.paintreason, "damage", $$v)
                          },
                          expression: "paintreason.damage"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        attrs: { label: "Paint", "hide-details": "" },
                        model: {
                          value: _vm.paintreason.paint,
                          callback: function($$v) {
                            _vm.$set(_vm.paintreason, "paint", $$v)
                          },
                          expression: "paintreason.paint"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        attrs: { label: "Rework", "hide-details": "" },
                        model: {
                          value: _vm.paintreason.rework,
                          callback: function($$v) {
                            _vm.$set(_vm.paintreason, "rework", $$v)
                          },
                          expression: "paintreason.rework"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", sm: "6" } },
            [
              !!_vm.canEditBuildSheet
                ? _c("v-select", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.processing,
                      label: _vm.$t("norms.work-order-entry-dialog-paint.tsa"),
                      items: _vm.tsas,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.tsaID,
                      "hide-details": ""
                    },
                    on: { input: v => _vm.$emit("update:tsaID", v) }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.canEditBuildSheet
                ? _c("fd-text-field", {
                    attrs: {
                      disabled: _vm.processing,
                      readonly: true,
                      label: _vm.$t("norms.work-order-entry-dialog-paint.tsa"),
                      value: _vm.$lookup.tsa(_vm.tsaID),
                      "hide-details": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "fd-build-sheet-margin-removal-radio-group",
                  attrs: { dense: "" }
                },
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "", "hide-details": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c("div", { staticClass: "ml-2" }, [
                                _c("strong", [_vm._v("Select Coat #")])
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ]),
                      model: {
                        value: _vm.coatNumberValue,
                        callback: function($$v) {
                          _vm.coatNumberValue = $$v
                        },
                        expression: "coatNumberValue"
                      }
                    },
                    [
                      _vm._v(" "),
                      _c("v-radio", {
                        staticClass: "pl-5",
                        attrs: { label: "1", value: 1 }
                      }),
                      _vm._v(" "),
                      _c("v-radio", {
                        staticClass: "pl-5",
                        attrs: { label: "2", value: 2 }
                      }),
                      _vm._v(" "),
                      _c("v-radio", {
                        staticClass: "pl-5",
                        attrs: { label: "3", value: 3 }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12" } },
            [
              _c("fd-text-field", {
                staticClass: "mb-3",
                attrs: {
                  disabled: _vm.processing,
                  readonly: !_vm.canEditBuildSheet,
                  label: _vm.$t("norms.work-order-entry-dialog.notes"),
                  value: _vm.notes,
                  "hide-details": ""
                },
                on: { input: v => _vm.$emit("update:notes", v) }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        {
          staticClass: "fd-norm-answer-container",
          attrs: { flat: "", tile: "" }
        },
        [
          !_vm.normsWithResponses.length
            ? _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticStyle: { "justify-content": "center" },
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c("fd-alert", {
                            attrs: {
                              label: _vm.$t(
                                "norms.work-order-entry-dialog.no-norms-configured-message"
                              ),
                              type: "hint"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.normsWithResponses, function(norm) {
            return _c(
              "v-row",
              {
                key: norm.id,
                staticClass: "fd-norm-answer-row",
                attrs: { "no-gutters": "" }
              },
              [
                _c(
                  "v-row",
                  { staticClass: "fd-norm-answer-content" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "py-1", attrs: { cols: "5", sm: "7" } },
                      [
                        !!norm.description
                          ? _c(
                              "span",
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(norm.description) +
                                    "\n            "
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function({ on }) {
                                            return [
                                              !!norm.answeredDescription &&
                                              norm.answeredDescription !=
                                                norm.description
                                                ? _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass: "ml-1",
                                                        attrs: {
                                                          small: "",
                                                          color: "black",
                                                          dark: ""
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v("info")]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      {
                                        attrs: { dense: "", "no-gutters": "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "norms.work-order-entry-dialog.previous-norm-description-message"
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "pl-3",
                                        attrs: { dense: "", "no-gutters": "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(norm.answeredDescription) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !norm.description
                          ? _c(
                              "v-row",
                              { attrs: { "no-gutters": "", dense: "" } },
                              [
                                _c("fd-value-display", {
                                  attrs: { value: norm.answeredDescription }
                                }),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      small: "",
                                                      color: "black",
                                                      dark: ""
                                                    }
                                                  },
                                                  on
                                                ),
                                                [_vm._v("info")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "norms.work-order-entry-dialog.norm-is-no-longer-active-message"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "grow pl-0 py-1" },
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pl-1", attrs: { cols: "4" } },
                              [
                                _c("fd-text-field", {
                                  ref: _vm.getFieldRef("quantity", norm),
                                  refInFor: true,
                                  staticClass: "fd-norm-answer-text-field",
                                  attrs: {
                                    "data-cy": "quantity",
                                    value: norm.quantity,
                                    dense: "",
                                    "hide-details": "",
                                    disabled: _vm.processing,
                                    readonly: !_vm.canEditBuildSheet,
                                    label: _vm.$t(
                                      "norms.work-order-entry-dialog.quantity-label"
                                    ),
                                    "hide-spin-buttons": "",
                                    type: "number",
                                    min: "0",
                                    "highlight-on-focus": ""
                                  },
                                  on: {
                                    input: v =>
                                      _vm.normQuantityUpdated(norm, v),
                                    keydown: [
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "up",
                                            38,
                                            $event.key,
                                            ["Up", "ArrowUp"]
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.selectPreviousField(
                                          "quantity",
                                          norm
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.selectNextField(
                                          "quantity",
                                          norm
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return (e =>
                                          _vm.enterPressed(
                                            e,
                                            "quantity",
                                            norm
                                          )).apply(null, arguments)
                                      }
                                    ]
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { staticClass: "pl-1", attrs: { cols: "4" } },
                              [
                                _c("fd-text-field", {
                                  ref: _vm.getFieldRef("squareFeet", norm),
                                  refInFor: true,
                                  staticClass: "fd-norm-answer-text-field",
                                  attrs: {
                                    "data-cy": "squareFeet",
                                    value: norm.squareFeet,
                                    dense: "",
                                    "hide-details": "",
                                    disabled: _vm.processing,
                                    readonly: !_vm.canEditBuildSheet,
                                    label: _vm.$t(
                                      "norms.work-order-entry-dialog.square-feet-label"
                                    ),
                                    "hide-spin-buttons": "",
                                    type: "number",
                                    min: "0",
                                    "highlight-on-focus": ""
                                  },
                                  on: {
                                    input: v =>
                                      _vm.normSquareFeetUpdated(norm, v),
                                    keydown: [
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "up",
                                            38,
                                            $event.key,
                                            ["Up", "ArrowUp"]
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.selectPreviousField(
                                          "squareFeet",
                                          norm
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.selectNextField(
                                          "squareFeet",
                                          norm
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return (e =>
                                          _vm.enterPressed(
                                            e,
                                            "squareFeet",
                                            norm
                                          )).apply(null, arguments)
                                      }
                                    ]
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { staticClass: "pl-1", attrs: { cols: "4" } },
                              [
                                _c("fd-text-field", {
                                  ref: _vm.getFieldRef("hours", norm),
                                  refInFor: true,
                                  staticClass: "fd-norm-answer-text-field",
                                  attrs: {
                                    "data-cy": "hours",
                                    value: norm.hours,
                                    dense: "",
                                    "hide-details": "",
                                    disabled: _vm.processing,
                                    readonly: !_vm.canEditBuildSheet,
                                    label: _vm.$t(
                                      "norms.work-order-entry-dialog.hours-label"
                                    ),
                                    "hide-spin-buttons": "",
                                    type: "number",
                                    min: "0",
                                    "highlight-on-focus": ""
                                  },
                                  on: {
                                    input: v => _vm.normHoursUpdated(norm, v),
                                    keydown: [
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "up",
                                            38,
                                            $event.key,
                                            ["Up", "ArrowUp"]
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.selectPreviousField(
                                          "hours",
                                          norm
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.selectNextField(
                                          "hours",
                                          norm
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return (e =>
                                          _vm.enterPressed(
                                            e,
                                            "hours",
                                            norm
                                          )).apply(null, arguments)
                                      }
                                    ]
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !!_vm.canEditBuildSheet
                      ? _c(
                          "v-col",
                          { staticClass: "shrink pl-0 pr-2 pt-1 pr-sm-1" },
                          [
                            !_vm.$vuetify.breakpoint.xs
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      text: "",
                                      "x-small": "",
                                      disabled: _vm.processing
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.clearResponses(norm)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("common.clear")))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.$vuetify.breakpoint.xs
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "fd-build-sheet-responsive-clear-button",
                                    attrs: {
                                      "x-small": "",
                                      rounded: "",
                                      outlined: "",
                                      disabled: _vm.processing
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.clearResponses(norm)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticStyle: { "font-size": "14px" },
                                        attrs: { dark: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n              mdi-close\n            "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }