import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import userAccess from "../../../dataMixins/userAccess";
import rules from "@fd/lib/vue/rules";
import {
  BuildDismantleRatio,
  HoardingModifier,
  InternalModifier,
  ScaffoldBayHeight,
  ScaffoldBayLength,
  ScaffoldBayWidth,
  ScaffoldCongestionFactor,
  ScaffoldDistanceModifier,
  ScaffoldElevationModifier,
  ScaffoldHeightModifier,
  ScaffoldRequestTypes,
  ScaffoldType,
  ScaffoldTypeModifier,
  Walkdown,
  walkdownReferenceDataService,
  WalkdownStatuses,
  WorkOrder
} from "../../../services";
import { SelectListOption } from "@fd/lib/vue/utility/select";
import { WorkOrderWithExtraDetails } from "../../../dataMixins/workOrderList";
import { TranslateResult } from "vue-i18n";
import { SortItemsByStringProp, SortItemsWithName } from "../../../utils/person";
import { SortItemsWithModifier } from "../../../utils/sorting";

export default FDVue.extend({
  name: "fd-walkdown-form",

  mixins: [serviceErrorHandling, userAccess, rules],

  components: {
    "fd-subheader": () => import("@fd/lib/vue/components/layout/Subheader.vue"),
    "fd-duration-picker": () => import("@fd/lib/vue/components/DurationPicker.vue"),
    "fp-add-remove-field": () => import("@fd/lib/vue/components/FP.AddRemoveField.vue")
  },

  props: {
    cy: { type: String, default: "fd-walkdown-form" },
    walkdown: { type: Object, default: () => ({}) },
    workOrder: { type: Object, default: () => ({}) },
    walkdownReadonly: { type: Boolean, default: false },
    requireUnassign: { type: Boolean, default: false },
    vlf: { type: [String, Boolean], default: true },
    requireVLF: { type: Boolean, default: false }
  },

  data: function() {
    return {
      screenLoaded: false,
      saving: false,

      accessTypes: [
        {
          id: 0,
          name: this.$t("walkdowns.existing-walkdown.access-type-none")
        },
        {
          id: 1,
          name: this.$t("walkdowns.existing-walkdown.access-type-ladder")
        }
      ],

      cachedLWHSettings: {
        Height: null as number | null | undefined,
        Width: null as number | null | undefined,
        BayHeightID: null as string | null | undefined,
        DeckLevels: null as number | null | undefined,
        AccessType: null as number | null | undefined,
        BarricadeGates: null as number | null | undefined
      },

      // scaffoldModifierDefaults: {} as ScaffoldDefaults,
      allScaffoldBayWidths: [] as ScaffoldBayWidth[],
      allScaffoldBayLengths: [] as ScaffoldBayLength[],
      allScaffoldBayHeights: [] as ScaffoldBayHeight[],

      allScaffoldTypes: [] as ScaffoldTypeModifier[],
      allScaffoldDistances: [] as ScaffoldDistanceModifier[],
      allScaffoldElevations: [] as ScaffoldElevationModifier[],
      allScaffoldHeights: [] as ScaffoldHeightModifier[],
      allBuildDismantleRatios: [] as BuildDismantleRatio[],
      allScaffoldCongestionFactors: [] as ScaffoldCongestionFactor[],
      allInternalModifiers: [] as InternalModifier[],
      allHoardingModifiers: [] as HoardingModifier[],

      crewSizeOptions: Array.from(Array(16).keys()),
      workDayOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      workTimePerDay: 0 as number
    };
  },

  computed: {
    vlfInfoMessage(): string | TranslateResult {
      if (this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Erect) {
        return this.$t("walkdowns.existing-walkdown.erect-vertical-length-feet-info-message");
      } else if (this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Modify) {
        return this.$t("walkdowns.existing-walkdown.modify-vertical-length-feet-info-message");
      } else if (this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Dismantle) {
        return this.$t("walkdowns.existing-walkdown.dismantle-vertical-length-feet-info-message");
      }
      return "";
    },
    vlfChange: {
      get(): number {
        return (this.walkdown as Walkdown).vlfChangeAmount ?? 0;
      },
      set(val: number) {
        if (!(this.walkdown as Walkdown)) return;
        this.walkdown.vlfChangeAmount = val;
      }
    },
    allowVLFAdd(): boolean {
      return (
        !!this.workOrder &&
        (this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Erect ||
          this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Modify)
      );
    },
    allowVLFRemove(): boolean {
      return (
        !!this.workOrder &&
        (this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Modify ||
          this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Dismantle)
      );
    },
    showVLF(): boolean {
      let showVLF = !(this.vlf == undefined || this.vlf == "false" || this.vlf === false);
      return showVLF && !this.scaffoldIsHardBarricade;
    },
    walkdownIsComplete(): boolean {
      let walkdown = this.walkdown as Walkdown;
      return (
        walkdown.walkdownStatus == WalkdownStatuses.Approved ||
        walkdown.walkdownStatus == WalkdownStatuses.Cancelled
      );
    },
    selectableScaffoldBayLengths(): SelectListOption<ScaffoldBayLength>[] {
      var length = this.walkdown.length;
      if (!length) return this.allScaffoldBayLengths;

      var lengthInInches = length * 12.0;
      return this.allScaffoldBayLengths.map(
        x =>
          ({
            ...x,
            disabled: (x.inches ?? 0) > lengthInInches
          } as SelectListOption<ScaffoldBayLength>)
      );
    },

    selectableScaffoldBayWidths(): SelectListOption<ScaffoldBayWidth>[] {
      var width = this.walkdown.width;
      if (!width) return this.allScaffoldBayWidths;

      var widthInInches = width * 12.0;
      return this.allScaffoldBayWidths.map(
        x =>
          ({
            ...x,
            disabled: (x.inches ?? 0) > widthInInches
          } as SelectListOption<ScaffoldBayWidth>)
      );
      // return this.allScaffoldBayWidths.filter(x => (x.inches ?? 0) <= widthInInches);
    },

    selectableScaffoldBayHeights(): ScaffoldBayHeight[] {
      return this.allScaffoldBayHeights;
    },

    numberOfLengthBays(): string {
      var scaffoldLengthFeet = this.walkdown.length ?? 0;
      var scaffoldLength = scaffoldLengthFeet * 12;
      var selectedBayLength = this.allScaffoldBayLengths.find(
        x => x.id == this.walkdown.scaffoldBayLengthID
      );
      var bayLength = selectedBayLength?.inches ?? 0;
      var bays = !!bayLength && bayLength > 0 ? Math.floor(scaffoldLength / bayLength) : 0;
      return `${bays}`;
    },

    numberOfWidthBays(): string {
      var scaffoldWidthFeet = this.walkdown.width ?? 0;
      var scaffoldWidth = scaffoldWidthFeet * 12;
      var selectedBayWidth = this.allScaffoldBayWidths.find(
        x => x.id == this.walkdown.scaffoldBayWidthID
      );
      var bayWidth = selectedBayWidth?.inches ?? 0;
      var bays = !!bayWidth && bayWidth > 0 ? Math.floor(scaffoldWidth / bayWidth) : 0;
      return `${bays}`;
    },

    // Data is stored in the SP1 db with a value called "ScaffoldType"
    // Possible values are:
    // - 0 = regular (volume) scaffold
    // - 1 = hard barricade
    scaffoldIsHardBarricade: {
      get(): boolean {
        return this.walkdown.isHardBarricade ?? false;
      },
      set(val: boolean) {
        // Since this is now set by the user selecting a scaffold type modifier, it could be set to the same thing multiple times
        // If this is the case, we don't want to run the caching logic.
        if (val == this.walkdown.isHardBarricade) return;

        // Set scaffold type to hard barricade
        this.walkdown.isHardBarricade = val;
        if (val) {
          // For hard barricades we don't have a width or height
          // We do have a "width bay size" (used as a brace) but not a height bay size, nor do we have deck levels
          // Cache the current values in case the user toggles the switch back
          this.cachedLWHSettings.Height = this.walkdown.height;
          this.cachedLWHSettings.Width = this.walkdown.width;
          this.cachedLWHSettings.BayHeightID = this.walkdown.scaffoldBayHeightID;
          this.cachedLWHSettings.DeckLevels = this.walkdown.deckLevels;
          this.cachedLWHSettings.AccessType = this.walkdown.accessType;
          // Clear out the unused values for hard barricades
          this.walkdown.height = null;
          this.walkdown.width = null;
          this.walkdown.scaffoldBayHeightID = null;
          this.walkdown.deckLevels = null;
          this.walkdown.accessType = null;

          this.walkdown.barricadeGates = this.cachedLWHSettings.BarricadeGates;
        } else {
          // Re-use the cached values
          this.walkdown.height = this.cachedLWHSettings.Height;
          this.walkdown.width = this.cachedLWHSettings.Width;
          this.walkdown.scaffoldBayHeightID = this.cachedLWHSettings.BayHeightID;
          this.walkdown.deckLevels = this.cachedLWHSettings.DeckLevels;
          this.walkdown.accessType = this.cachedLWHSettings.AccessType;

          this.cachedLWHSettings.BarricadeGates = this.walkdown.barricadeGates;
          this.walkdown.barricadeGates = null;
        }
      }
    }
  },

  watch: {
    workOrder(newValue) {
      this.workOrder.scaffoldTotalVLF = this.workOrder.scaffoldTotalVLF ?? 0;
    },
    walkdown(newValue) {
      if (!this.screenLoaded) {
        this.workTimePerDay =
          !this.walkdown.crewSize ||
          this.walkdown.crewSize == 0 ||
          !this.walkdown.workDays ||
          this.walkdown.workDays == 0
            ? 0
            : (this.walkdown.modificationHours ?? 0) /
              (this.walkdown.crewSize * this.walkdown.workDays);
        this.cachedLWHSettings.Height = this.walkdown.height;
        this.cachedLWHSettings.Width = this.walkdown.width;
        this.cachedLWHSettings.BayHeightID = this.walkdown.scaffoldBayHeightID;
        this.cachedLWHSettings.DeckLevels = this.walkdown.deckLevels;
        this.cachedLWHSettings.AccessType = this.walkdown.accessType;
        this.cachedLWHSettings.BarricadeGates = this.walkdown.barricadeGates;

        // If the associated work order is a dismantle, ensure the entire height is being removed from the scaffold
        if (
          !!this.workOrder &&
          this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Dismantle &&
          !this.walkdown.vlfChangeAmount
        ) {
          this.walkdown.vlfChangeAmount = -this.workOrder.scaffoldTotalVLF;
        }

        this.screenLoaded = true;
      }
    },
    workTimePerDay(newValue) {
      console.log(` workTimePerDay changed: ${newValue}`);
      this.walkdown.modificationHours =
        (this.walkdown.crewSize ?? 0) * (this.walkdown.workDays ?? 1) * newValue;
    },
    "walkdown.length": function(newValue) {
      if (!this.walkdown.scaffoldBayLengthID) return;

      var selectedSelectableBayLength = this.selectableScaffoldBayLengths.find(
        x => x.id == this.walkdown.scaffoldBayLengthID
      );
      if (!selectedSelectableBayLength) {
        this.walkdown.scaffoldBayLengthID = null;
      }
    },
    "walkdown.width": function(newValue) {
      if (!this.walkdown.scaffoldBayWidthID) return;

      var selectedSelectableBayWidth = this.selectableScaffoldBayWidths.find(
        x => x.id == this.walkdown.scaffoldBayWidthID
      );
      if (!selectedSelectableBayWidth) {
        this.walkdown.scaffoldBayWidthID = null;
      }
    },
    "walkdown.height": function(newValue) {
      if (!this.walkdown.scaffoldBayHeightID) return;

      var selectedSelectableBayHeight = this.selectableScaffoldBayHeights.find(
        x => x.id == this.walkdown.scaffoldBayHeightID
      );
      if (!selectedSelectableBayHeight) {
        this.walkdown.scaffoldBayHeightID = null;
      }
    },
    "walkdown.crewSize": function(newValue, oldValue) {
      this.walkdown.modificationHours =
        newValue * (this.walkdown.workDays ?? 1) * this.workTimePerDay;
    },
    "walkdown.workDays": function(newValue, oldValue) {
      this.walkdown.modificationHours =
        (this.walkdown.crewSize ?? 0) * newValue * this.workTimePerDay;
    },
    "walkdown.scaffoldTypeModifierID": function(newValue) {
      this.scaffoldIsHardBarricade =
        this.allScaffoldTypes.find(x => x.id == newValue)?.scaffoldTypeID == ScaffoldType.Barricade;
    }
  },

  methods: {
    validate(): boolean {
      let form = this.$refs.fdwalkdownform as HTMLFormElement;
      let isValid = form?.validate() ?? true;
      console.log(`WalkdownForm.validate fdwalkdownform: ${form}, valid: ${isValid}`);
      return isValid;
    },
    preventSubmit(e: Event): boolean {
      e.preventDefault();
      return false;
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldDefaults(): Promise<void> {
      // this.scaffoldModifierDefaults = await walkdownReferenceDataService.getScaffoldDefaults();
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldBayLengths(): Promise<void> {
      this.allScaffoldBayLengths = (
        await walkdownReferenceDataService.getAllScaffoldBayLengths()
      ).sort((a, b) => (a.inches ?? 0) - (b.inches ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldBayWidths(): Promise<void> {
      this.allScaffoldBayWidths = (
        await walkdownReferenceDataService.getAllScaffoldBayWidths()
      ).sort((a, b) => (a.inches ?? 0) - (b.inches ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldBayHeights(): Promise<void> {
      this.allScaffoldBayHeights = (
        await walkdownReferenceDataService.getAllScaffoldBayHeights()
      ).sort((a, b) => (a.meters ?? 0) - (b.meters ?? 0));
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldTypes(): Promise<void> {
      this.allScaffoldTypes = (
        await walkdownReferenceDataService.getActiveScaffoldTypeModifiers()
      ).sort((a, b) => (a.scaffoldSubTypeID ?? 0) - (b.scaffoldSubTypeID ?? 0));
      if (!!this.allScaffoldTypes?.length && !this.walkdown.scaffoldTypeModifierID?.length) {
        this.walkdown.scaffoldTypeModifierID = this.allScaffoldTypes[0].id;
      }
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldDistances(): Promise<void> {
      this.allScaffoldDistances = SortItemsWithModifier(
        await walkdownReferenceDataService.getAllScaffoldDistanceModifiers()
      );
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldHeights(): Promise<void> {
      this.allScaffoldHeights = SortItemsWithModifier(
        await walkdownReferenceDataService.getAllScaffoldHeightModifiers()
      );
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldElevations(): Promise<void> {
      this.allScaffoldElevations = SortItemsWithModifier(
        await walkdownReferenceDataService.getAllScaffoldElevationModifiers()
      );
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldCongestionFactors(): Promise<void> {
      this.allScaffoldCongestionFactors = SortItemsWithModifier(
        await walkdownReferenceDataService.getAllScaffoldCongestionFactors()
      );
    },

    // DOES NOT manage processing or error message logic
    async loadBuildDismantleRatios(): Promise<void> {
      this.allBuildDismantleRatios = SortItemsByStringProp(
        await walkdownReferenceDataService.getAllBuildDismantleRatios(),
        "ratio"
      );
    },

    // DOES NOT manage processing or error message logic
    async loadInternalModifiers(): Promise<void> {
      this.allInternalModifiers = SortItemsWithModifier(
        await walkdownReferenceDataService.getAllInternalModifiers()
      );
    },

    // DOES NOT manage processing or error message logic
    async loadHoardingModifiers(): Promise<void> {
      this.allHoardingModifiers = SortItemsWithModifier(
        await walkdownReferenceDataService.getAllHoardingModifiers()
      );
    }
  },

  mounted: async function() {
    this.processing = true;
    try {
      // Due to the data binding, we need to load all the reference data before we bind the actual walkdown object
      // However, we dont' want the data to load until the popup is visible so this has to be done AFTER calling the `showDialog` method
      await Promise.all([
        this.loadScaffoldDefaults(),
        this.loadScaffoldBayLengths(),
        this.loadScaffoldBayWidths(),
        this.loadScaffoldBayHeights(),
        this.loadScaffoldTypes(),
        this.loadScaffoldDistances(),
        this.loadScaffoldElevations(),
        this.loadScaffoldHeights(),
        this.loadScaffoldCongestionFactors(),
        this.loadBuildDismantleRatios(),
        this.loadInternalModifiers(),
        this.loadHoardingModifiers()
      ]);
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
    // this.workOrder = this.workOrderData!;
    // this.workOrderData = null;
    // this.walkdown = this.walkdownData!;
    // this.walkdownData = null;

    if (!!this.walkdown?.id) {
      this.workTimePerDay =
        !this.walkdown.crewSize ||
        this.walkdown.crewSize == 0 ||
        !this.walkdown.workDays ||
        this.walkdown.workDays == 0
          ? 0
          : (this.walkdown.modificationHours ?? 0) /
            (this.walkdown.crewSize * this.walkdown.workDays);

      this.cachedLWHSettings.Height = this.walkdown.height;
      this.cachedLWHSettings.Width = this.walkdown.width;
      this.cachedLWHSettings.BayHeightID = this.walkdown.scaffoldBayHeightID;
      this.cachedLWHSettings.DeckLevels = this.walkdown.deckLevels;
      this.cachedLWHSettings.AccessType = this.walkdown.accessType;
      this.cachedLWHSettings.BarricadeGates = this.walkdown.barricadeGates;

      this.workOrder.scaffoldTotalVLF = this.workOrder.scaffoldTotalVLF ?? 0;
      // If the associated work order is a dismantle, ensure the entire height is being removed from the scaffold
      if (
        !!this.workOrder &&
        this.workOrder.scaffoldRequestType == ScaffoldRequestTypes.Dismantle &&
        !this.walkdown.vlfChangeAmount
      ) {
        this.walkdown.vlfChangeAmount = -this.workOrder.scaffoldTotalVLF;
      }

      this.screenLoaded = true;
    }
  },

  created: async function() {}
});

