import { classificationService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: classificationService,
  singularStoreName: "CLASSIFICATION",
  pluralStoreName: "CLASSIFICATIONS",
  localizationPrefix: "classifications",
  // The following is the name of the ID property used by foreign objects.
  consumerRelatedIDProperty: {
    name: "classificationID",
    type: "string"
  }
});

export default storeModule;

