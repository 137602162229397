import { scaffoldElevationModifierService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: scaffoldElevationModifierService,
  singularStoreName: "SCAFFOLD_ELEVATION_MODIFIER",
  pluralStoreName: "SCAFFOLD_ELEVATION_MODIFIERS",
  localizationPrefix: "modifiers.scaffold-elevation",
  consumerRelatedIDProperty: {
    name: "scaffoldElevationModifierID",
    type: "string"
  }
});

export default storeModule;

