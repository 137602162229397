import FDVue from "../";

export default FDVue.extend({
  name: "fd-checkbox",

  inheritAttrs: false,

  props: {
    value: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    hideChips: { type: Boolean, default: false },

    tooltip: { type: String, default: "" },
    inactiveTooltip: { type: String, default: "" },

    onColor: { type: String, default: "primary" },
    onText: { type: String, default: undefined },
    onTextKey: { type: String, default: "common.yes" },
    onTextColor: { type: String, default: "" },

    offColor: { type: String, default: "" },
    offText: { type: String, default: undefined },
    offTextKey: { type: String, default: "common.no" },
    offTextColor: { type: String, default: "" },

    chipTextStyle: { type: String },

    cy: { type: String, default: undefined },
    dataCy: { type: String, default: "fd-checkbox" }
  },

  data: function() {
    return {};
  },

  methods: {},

  computed: {
    showTooltip(): boolean {
      return this.showInactiveTooltip || this.showActiveTooltip;
    },
    showInactiveTooltip(): boolean {
      return (
        (this.disabled || this.readonly) &&
        !!this.inactiveTooltip &&
        this.inactiveTooltip.length > 0
      );
    },
    showActiveTooltip(): boolean {
      return !this.disabled && !this.readonly && !!this.tooltip && this.tooltip.length > 0;
    },
    chipsVisible(): boolean {
      return !this.hideChips && this.readonly;
    }
  },

  watch: {}
});

