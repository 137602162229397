var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("fd-image-viewer", {
        attrs: { name: _vm.imageName },
        model: {
          value: _vm.imageSource,
          callback: function($$v) {
            _vm.imageSource = $$v
          },
          expression: "imageSource"
        }
      }),
      _vm._v(" "),
      _c("fd-image-editor", {
        attrs: { name: _vm.imageName },
        on: { edit: _vm.handleEdit },
        model: {
          value: _vm.editImageSource,
          callback: function($$v) {
            _vm.editImageSource = $$v
          },
          expression: "editImageSource"
        }
      }),
      _vm._v(" "),
      _c(
        "v-form",
        { ref: "form", attrs: { "data-cy": "workorderexisting" } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: { route: _vm.backButtonClicked }
                                  }),
                                  _vm._v(" "),
                                  !!_vm.workOrder.workOrderStatus
                                    ? _c(
                                        "div",
                                        {
                                          class: [
                                            {
                                              "ml-2":
                                                _vm.$vuetify.breakpoint.xsOnly
                                            },
                                            "py-1",
                                            "px-3",
                                            "fd-status-indicator"
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  `workorders.status.${_vm.workOrder.workOrderStatus}`
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "py-0 px-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          staticClass: "pt-3",
                          attrs: { "icons-and-text": "", "show-arrows": "" },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              key: _vm.detailsTab.key,
                              ref: "tab",
                              attrs: { href: `#tab-${_vm.detailsTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.detailsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("scheduler.tabs.details")) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-edit")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.includeWalkdownTab &&
                          (_vm.walkdownTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.walkdownTab.key,
                                  attrs: {
                                    "data-cy": "walkdown",
                                    href: `#tab-${_vm.walkdownTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.walkdownTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("scheduler.tabs.walkdown")
                                      ) +
                                      "\n              "
                                  ),
                                  false
                                    ? _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            color: "orange",
                                            icon: "fa-exclamation"
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("fas fa-ruler-combined")
                                          ])
                                        ],
                                        1
                                      )
                                    : _c("v-icon", [
                                        _vm._v("fas fa-ruler-combined")
                                      ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.includeEstimatesTab &&
                          (_vm.estimatesTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.estimatesTab.key,
                                  attrs: {
                                    "data-cy": "estimates",
                                    href: `#tab-${_vm.estimatesTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.estimatesTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.estimatesTab.tabname) +
                                      "\n              "
                                  ),
                                  false
                                    ? _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            color: "orange",
                                            icon: "fa-exclamation"
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("fas fa-pen-ruler")
                                          ])
                                        ],
                                        1
                                      )
                                    : _c("v-icon", [_vm._v("fas fa-pen-ruler")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.photosTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.photosTab.key,
                                  attrs: { href: `#tab-${_vm.photosTab.key}` },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.photosTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("scheduler.tabs.photos")) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-camera")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.notesTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.notesTab.key,
                                  attrs: { href: `#tab-${_vm.notesTab.key}` },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.notesTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("scheduler.tabs.notes")) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-clipboard")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.messagingTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.messagingTab.key,
                                  attrs: {
                                    href: `#tab-${_vm.messagingTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.messagingTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "scaffold-requests.existing-scaffold-request.tabs.messaging"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                  _c(
                                    "v-badge",
                                    {
                                      attrs: {
                                        value: _vm.messages.length > 0,
                                        color: "primary",
                                        content: _vm.messages.length
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("fas fa-comments")])],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.scopeTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.scopeTab.key,
                                  attrs: { href: `#tab-${_vm.scopeTab.key}` },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.scopeTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.scopeTab.tabname) +
                                      "\n              "
                                  ),
                                  _c(
                                    "v-badge",
                                    {
                                      attrs: {
                                        color: "error",
                                        value: _vm.scopeTabError,
                                        content: "!"
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("fas fa-chart-gantt")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.includeOptionsTab &&
                          (_vm.optionsTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.optionsTab.key,
                                  attrs: { href: `#tab-${_vm.optionsTab.key}` },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.optionsTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("scheduler.tabs.options")) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-tools")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.attachmentsTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.attachmentsTab.key,
                                  attrs: {
                                    href: `#tab-${_vm.attachmentsTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.attachmentsTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("common.attachments")) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-paperclip")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.allowCountSheet &&
                          (_vm.countSheetTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.countSheetTab.key,
                                  attrs: {
                                    "data-cy": "countsheet",
                                    href: `#tab-${_vm.countSheetTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.countSheetTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.countSheetTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-list-ol")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$store.state.curEnvironment.enableNorms &&
                          (_vm.normsTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.normsTab.key,
                                  attrs: {
                                    "data-cy": "norms",
                                    href: `#tab-${_vm.normsTab.key}`
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.normsTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.normsTab.tabname) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [
                                    _vm._v(
                                      "fas " +
                                        _vm._s(
                                          _vm.$store.getters.icon(
                                            "contractor-norms"
                                          )
                                        )
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: { bottom: "", left: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "align-self-center mr-4 d-flex d-sm-none more-tab-button",
                                              staticStyle: {
                                                position: "relative"
                                              },
                                              attrs: { text: "" }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("common.more")) +
                                              "\n\n                  "
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c("v-icon", [
                                                _vm._v("fas fa-ellipsis-h")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                { staticClass: "grey lighten-3" },
                                _vm._l(_vm.hiddenTabDefinitions, function(
                                  item
                                ) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.tabname,
                                      on: {
                                        click: function($event) {
                                          return _vm.showNewTabFromMoreMenu(
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.tabname) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.detailsTab.key,
                              attrs: { value: `tab-${_vm.detailsTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "detailsform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "mx-0 px-2 fd-inline-request-tags-qualifier",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("fd-chip-selector", {
                                            attrs: {
                                              "available-items":
                                                _vm.availableKeywords,
                                              "selected-items":
                                                _vm.selectedKeywords,
                                              "item-label": "name",
                                              disabled:
                                                _vm.readonly || _vm.processing,
                                              "control-label": _vm.$t(
                                                "common.add-tags"
                                              ),
                                              "chip-class":
                                                "keywords-selection-chip-colors",
                                              outlined: false
                                            },
                                            on: {
                                              "update:selectedItems": function(
                                                $event
                                              ) {
                                                _vm.selectedKeywords = $event
                                              },
                                              "update:selected-items": function(
                                                $event
                                              ) {
                                                _vm.selectedKeywords = $event
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-0 pt-1",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-5 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "6",
                                            sm: "3"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            staticClass:
                                              "fd-readonly-input fd-remove-messages",
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              label: _vm.$t(
                                                "scheduler.scaffold-number"
                                              ),
                                              disabled: _vm.processing,
                                              readonly: true
                                            },
                                            model: {
                                              value:
                                                _vm.workOrder.scaffoldNumber,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "scaffoldNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.scaffoldNumber"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-5 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "6",
                                            sm: "3"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            staticClass:
                                              "fd-readonly-input fd-remove-messages",
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              label: _vm.$t(
                                                "scheduler.wo-number-column-header"
                                              ),
                                              value:
                                                _vm.workOrder.internalNumber,
                                              disabled: _vm.processing,
                                              readonly: true
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-5 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "6",
                                            sm: "3"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            staticClass:
                                              "fd-readonly-input fd-remove-messages",
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              label: _vm.$t(
                                                "scheduler.request-type-column-header"
                                              ),
                                              disabled: _vm.processing,
                                              readonly: true
                                            },
                                            model: {
                                              value:
                                                _vm.workOrder.requestTypeName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "requestTypeName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.requestTypeName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-5 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "6",
                                            sm: "3"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            staticClass:
                                              "fd-readonly-input fd-remove-messages",
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              label: _vm.$t(
                                                "scheduler.request-sub-type"
                                              ),
                                              disabled: _vm.processing,
                                              readonly: true
                                            },
                                            model: {
                                              value:
                                                _vm.workOrder
                                                  .requestSubTypeName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "requestSubTypeName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.requestSubTypeName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      !(
                                        _vm.workOrderIsOnHold ||
                                        _vm.workOrderIsCancelled
                                      )
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-8 pb-0",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                sm: "6"
                                              }
                                            },
                                            [
                                              _vm.currentUserCanEditWorkOrderSchedule &&
                                              _vm.workOrder
                                                .currentUserPermissions
                                                .canEditStatus
                                                ? _c("v-select", {
                                                    attrs: {
                                                      outlined: "",
                                                      label: _vm.$t(
                                                        "scheduler.status"
                                                      ),
                                                      disabled:
                                                        _vm.readonly ||
                                                        _vm.processing,
                                                      items:
                                                        _vm.workOrderSelectableStatuses,
                                                      "item-text":
                                                        "displayName",
                                                      "item-value": "value",
                                                      rules:
                                                        _vm.schedulerRules
                                                          .workOrderStatus
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.workOrder
                                                          .workOrderStatus,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.workOrder,
                                                          "workOrderStatus",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "workOrder.workOrderStatus"
                                                    }
                                                  })
                                                : _c("fd-text-field", {
                                                    attrs: {
                                                      disabled: _vm.processing,
                                                      readonly: true,
                                                      label: _vm.$t(
                                                        "scheduler.status"
                                                      ),
                                                      value:
                                                        _vm.workOrder
                                                          .workOrderStatusName
                                                    }
                                                  })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !(
                                        _vm.workOrderIsOnHold ||
                                        _vm.workOrderIsCancelled
                                      )
                                        ? _c("v-col", {
                                            staticClass:
                                              "justify-space-between pt-5 pb-1",
                                            attrs: {
                                              align: "center",
                                              cols: "0",
                                              sm: "6"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.workOrderIsOnHold ||
                                      _vm.workOrderIsCancelled
                                        ? _c(
                                            "v-col",
                                            {
                                              class: [
                                                {
                                                  "fd-alert-container-warning":
                                                    _vm.workOrderIsOnHold
                                                },
                                                {
                                                  "fd-alert-container-error":
                                                    _vm.workOrderIsCancelled
                                                },
                                                "fd-alert-container",
                                                "justify-space-between",
                                                "mt-4",
                                                "mb-5",
                                                "pt-8",
                                                "pb-1",
                                                "px-0"
                                              ],
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { cols: "12", sm: "6" }
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "px-0 mx-0 align-start"
                                                    },
                                                    [
                                                      _vm.workOrderIsOnHold
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "fd-alert-container-icon"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fa-exclamation-circle"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.workOrderIsCancelled
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "fd-alert-container-icon"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fa-times-octagon"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.currentUserCanEditWorkOrderSchedule &&
                                                      _vm.workOrder
                                                        .currentUserPermissions
                                                        .canEditStatus
                                                        ? _c("v-select", {
                                                            staticClass: "px-3",
                                                            staticStyle: {
                                                              width:
                                                                "min-content"
                                                            },
                                                            attrs: {
                                                              outlined: "",
                                                              label: _vm.$t(
                                                                "scheduler.status"
                                                              ),
                                                              disabled:
                                                                _vm.readonly ||
                                                                _vm.processing,
                                                              items:
                                                                _vm.workOrderSelectableStatuses,
                                                              "item-text":
                                                                "displayName",
                                                              "item-value":
                                                                "value",
                                                              rules:
                                                                _vm
                                                                  .schedulerRules
                                                                  .workOrderStatus
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.workOrder
                                                                  .workOrderStatus,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.workOrder,
                                                                  "workOrderStatus",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "workOrder.workOrderStatus"
                                                            }
                                                          })
                                                        : _c("fd-text-field", {
                                                            staticClass: "px-3",
                                                            staticStyle: {
                                                              width:
                                                                "min-content"
                                                            },
                                                            attrs: {
                                                              disabled:
                                                                _vm.processing,
                                                              readonly: true,
                                                              label: _vm.$t(
                                                                "scheduler.status"
                                                              ),
                                                              value:
                                                                _vm.workOrder
                                                                  .workOrderStatusName
                                                            }
                                                          })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c("fd-textarea", {
                                                    staticClass: "px-3",
                                                    staticStyle: {
                                                      "margin-left": "54px"
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      label: _vm.$t(
                                                        "scheduler.status-reason"
                                                      ),
                                                      disabled:
                                                        !(
                                                          _vm.currentUserCanEditWorkOrderSchedule &&
                                                          _vm.workOrder
                                                            .currentUserPermissions
                                                            .canEditStatus
                                                        ) ||
                                                        _vm.readonly ||
                                                        _vm.processing,
                                                      readonly: !(
                                                        _vm.currentUserCanEditWorkOrderSchedule &&
                                                        _vm.workOrder
                                                          .currentUserPermissions
                                                          .canEditStatus
                                                      ),
                                                      rows: "1",
                                                      "auto-grow": "",
                                                      rules:
                                                        _vm.schedulerRules
                                                          .workOrderStatusDetail
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.workOrder
                                                          .workOrderStatusDetail,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.workOrder,
                                                          "workOrderStatusDetail",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "workOrder.workOrderStatusDetail"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "3"
                                          }
                                        },
                                        [
                                          _vm.currentUserCanEditWorkOrderSchedule &&
                                          _vm.workOrder.currentUserPermissions
                                            .canEditPriority
                                            ? _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  label: _vm.$t(
                                                    "scheduler.priority"
                                                  ),
                                                  disabled:
                                                    _vm.readonly ||
                                                    _vm.processing,
                                                  items: _vm.priorityValues,
                                                  "item-text": "name",
                                                  "item-value": "id"
                                                },
                                                model: {
                                                  value: _vm.workOrder.priority,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.workOrder,
                                                      "priority",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "workOrder.priority"
                                                }
                                              })
                                            : _c("fd-text-field", {
                                                attrs: {
                                                  disabled: _vm.processing,
                                                  readonly: true,
                                                  label: _vm.$t(
                                                    "scheduler.priority"
                                                  ),
                                                  value: _vm.workOrder.priority
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "3"
                                          }
                                        },
                                        [
                                          _vm.currentUserCanEditWorkOrderSchedule &&
                                          _vm.workOrder.currentUserPermissions
                                            .canEditProgress
                                            ? _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  label: _vm.$t(
                                                    "scheduler.progress"
                                                  ),
                                                  disabled:
                                                    _vm.readonly ||
                                                    _vm.processing,
                                                  items: _vm.progressValues,
                                                  "item-text": "name",
                                                  "item-value": "id"
                                                },
                                                model: {
                                                  value: _vm.workOrder.progress,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.workOrder,
                                                      "progress",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "workOrder.progress"
                                                }
                                              })
                                            : _c("fd-text-field", {
                                                attrs: {
                                                  disabled: _vm.processing,
                                                  readonly: true,
                                                  label: _vm.$t(
                                                    "scheduler.progress"
                                                  ),
                                                  value: _vm.workOrder.progress
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _vm.currentUserCanEditWorkOrderSchedule &&
                                          _vm.workOrder.currentUserPermissions
                                            .canEditRequiredDate
                                            ? _c("fd-date-picker", {
                                                attrs: {
                                                  disabled:
                                                    _vm.readonly ||
                                                    _vm.processing,
                                                  label: _vm.$t(
                                                    "scheduler.required-date-column-header"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.workOrder.requiredDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.workOrder,
                                                      "requiredDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "workOrder.requiredDate"
                                                }
                                              })
                                            : _c("fd-text-field", {
                                                attrs: {
                                                  disabled: _vm.processing,
                                                  readonly: true,
                                                  label: _vm.$t(
                                                    "scheduler.required-date-column-header"
                                                  ),
                                                  value:
                                                    _vm.workOrder
                                                      .formattedRequiredDate
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _vm.workOrder.currentUserPermissions
                                            .canEditAssignedContractor
                                            ? _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  label: _vm.$t(
                                                    "scheduler.assigned-contractor-long"
                                                  ),
                                                  disabled:
                                                    _vm.readonly ||
                                                    _vm.processing,
                                                  items:
                                                    _vm.assignableContractorsForWorkOrder,
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  rules:
                                                    _vm.schedulerRules
                                                      .assignedContractorID,
                                                  clearable: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.workOrder
                                                      .assignedContractorID,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.workOrder,
                                                      "assignedContractorID",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "workOrder.assignedContractorID"
                                                }
                                              })
                                            : _c("fd-text-field", {
                                                attrs: {
                                                  disabled: _vm.processing,
                                                  readonly: true,
                                                  label: _vm.$t(
                                                    "scheduler.assigned-contractor-long"
                                                  ),
                                                  value:
                                                    _vm.workOrder
                                                      .assignedContractorName
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          false &&
                                          _vm.workOrder.currentUserPermissions
                                            .canEditAssignedCoordinator
                                            ? _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  label: _vm.$t(
                                                    "scheduler.coordinator"
                                                  ),
                                                  disabled:
                                                    _vm.readonly ||
                                                    _vm.processing,
                                                  items: _vm.getCoordinatorsForContractor(
                                                    _vm.workOrder
                                                      .assignedContractorID
                                                  ),
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  rules:
                                                    _vm.schedulerRules
                                                      .coordinatorID,
                                                  clearable: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.workOrder.coordinatorID,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.workOrder,
                                                      "coordinatorID",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "workOrder.coordinatorID"
                                                }
                                              })
                                            : _c("fd-text-field", {
                                                attrs: {
                                                  disabled: _vm.processing,
                                                  readonly: true,
                                                  label: _vm.$t(
                                                    "scheduler.coordinator"
                                                  ),
                                                  value:
                                                    _vm.workOrder
                                                      .coordinatorName
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _vm.currentUserCanEditWorkOrderSchedule &&
                                          _vm.workOrder.currentUserPermissions
                                            .canEditAssignedGeneralForeman
                                            ? _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  label: _vm.$t(
                                                    "scheduler.general-foreman"
                                                  ),
                                                  disabled:
                                                    _vm.readonly ||
                                                    _vm.processing,
                                                  items: _vm.getGeneralForemenForContractor(
                                                    _vm.workOrder
                                                      .assignedContractorID
                                                  ),
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  rules:
                                                    _vm.schedulerRules
                                                      .generalForemanID,
                                                  clearable: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.workOrder
                                                      .generalForemanID,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.workOrder,
                                                      "generalForemanID",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "workOrder.generalForemanID"
                                                }
                                              })
                                            : _c("fd-text-field", {
                                                attrs: {
                                                  disabled: _vm.processing,
                                                  readonly: true,
                                                  label: _vm.$t(
                                                    "scheduler.general-foreman"
                                                  ),
                                                  value:
                                                    _vm.workOrder
                                                      .generalForemanName
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _vm.currentUserCanEditWorkOrderSchedule &&
                                          _vm.workOrder.currentUserPermissions
                                            .canEditAssignedForeman
                                            ? _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  label: _vm.$t(
                                                    "scheduler.foreman"
                                                  ),
                                                  disabled:
                                                    _vm.readonly ||
                                                    _vm.processing,
                                                  items: _vm.getForemenForContractor(
                                                    _vm.workOrder
                                                      .assignedContractorID
                                                  ),
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  rules:
                                                    _vm.schedulerRules
                                                      .foremanID,
                                                  clearable: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.workOrder.foremanID,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.workOrder,
                                                      "foremanID",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "workOrder.foremanID"
                                                }
                                              })
                                            : _c("fd-text-field", {
                                                attrs: {
                                                  disabled: _vm.processing,
                                                  readonly: true,
                                                  label: _vm.$t(
                                                    "scheduler.foreman"
                                                  ),
                                                  value:
                                                    _vm.workOrder.foremanName
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-0 pb-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "scheduler.is-to-do"
                                              ),
                                              disabled: _vm.processing,
                                              readonly:
                                                !_vm.workOrder
                                                  .currentUserPermissions
                                                  .canPushToToDoList ||
                                                _vm.readonly,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "scheduler.is-to-do-hint"
                                              )
                                            },
                                            model: {
                                              value: _vm.showOnToDo,
                                              callback: function($$v) {
                                                _vm.showOnToDo = $$v
                                              },
                                              expression: "showOnToDo"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      !_vm.workOrder.isUrgent
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-0 pb-5 mb-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "scheduler.is-urgent"
                                                  ),
                                                  "persistent-hint": "",
                                                  hint: _vm.$t(
                                                    "scheduler.is-urgent-hint"
                                                  ),
                                                  disabled: _vm.processing,
                                                  readonly:
                                                    !(
                                                      _vm.currentUserCanEditWorkOrderSchedule &&
                                                      _vm.workOrder
                                                        .currentUserPermissions
                                                        .canEditPriority
                                                    ) || _vm.readonly
                                                },
                                                model: {
                                                  value: _vm.workOrder.isUrgent,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.workOrder,
                                                      "isUrgent",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "workOrder.isUrgent"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-col",
                                            {
                                              class: [
                                                "fd-alert-container-urgent",
                                                "fd-alert-container",
                                                "justify-space-between",
                                                "mt-4",
                                                {
                                                  "mb-0":
                                                    _vm.validLogsForWorkOrder(
                                                      _vm.workOrder
                                                    ).length > 0,
                                                  "mb-5":
                                                    _vm.validLogsForWorkOrder(
                                                      _vm.workOrder
                                                    ).length == 0
                                                },
                                                "py-0",
                                                "px-0",
                                                "pb-5"
                                              ],
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "px-0 pt-2 pb-2",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "align-start pb-3",
                                                      attrs: {
                                                        "no-gutters": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "fd-alert-container-icon pt-3",
                                                          staticStyle: {
                                                            color:
                                                              "rgb(0,0,0,0.6)"
                                                          }
                                                        },
                                                        [_vm._v("fa-fire-alt")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("v-switch", {
                                                        staticClass:
                                                          "px-3 pt-1",
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "scheduler.is-urgent"
                                                          ),
                                                          "persistent-hint": "",
                                                          hint: _vm.$t(
                                                            "scheduler.is-urgent-hint"
                                                          ),
                                                          disabled:
                                                            _vm.processing,
                                                          readonly:
                                                            !(
                                                              _vm.currentUserCanEditWorkOrderSchedule &&
                                                              _vm.workOrder
                                                                .currentUserPermissions
                                                                .canEditPriority
                                                            ) || _vm.readonly
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.workOrder
                                                              .isUrgent,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.workOrder,
                                                              "isUrgent",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "workOrder.isUrgent"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm.workOrder.isUrgent
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pa-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _c("fd-text-field", {
                                                        staticClass: "px-3",
                                                        staticStyle: {
                                                          "margin-left": "49px"
                                                        },
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "scheduler.urgent-reason"
                                                          ),
                                                          disabled:
                                                            _vm.processing,
                                                          readonly:
                                                            !(
                                                              _vm.currentUserCanEditWorkOrderSchedule &&
                                                              _vm.workOrder
                                                                .currentUserPermissions
                                                                .canEditPriority
                                                            ) || _vm.readonly,
                                                          rows: "1",
                                                          "auto-grow": "",
                                                          rules:
                                                            _vm.schedulerRules
                                                              .isUrgentDetail,
                                                          "hide-details": !_vm.currentUrgentLogSummaryString(),
                                                          "persistent-hint": !!_vm.currentUrgentLogSummaryString(),
                                                          hint: _vm.currentUrgentLogSummaryString()
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.workOrder
                                                              .isUrgentDetail,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.workOrder,
                                                              "isUrgentDetail",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "workOrder.isUrgentDetail"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                      _vm._v(" "),
                                      _vm.validLogsForWorkOrder(_vm.workOrder)
                                        .length > 0
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 pb-5 mb-5 fd-urgent-log-container"
                                            },
                                            [
                                              _c("v-subheader", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scheduler.urgent-log-history-sub-title"
                                                    )
                                                  )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.validLogsForWorkOrder(
                                                  _vm.workOrder
                                                ),
                                                function(log) {
                                                  return _c(
                                                    "v-row",
                                                    {
                                                      key: log.id,
                                                      staticClass:
                                                        "fd-urgent-log-entry",
                                                      attrs: {
                                                        "no-gutters": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "fd-hot-job-stamp fd-urgent-log-entry-title",
                                                          staticStyle: {
                                                            "margin-left":
                                                              "49px"
                                                          },
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                "x-small": ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getLogActionIcon(
                                                                    log
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getUrgentLogSummaryString(
                                                                  log
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      !!log.isUrgentDetail
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "fd-urgent-log-entry-reason-text",
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "59px"
                                                              },
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    log.isUrgentDetail
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 pb-5 mt-5",
                                          attrs: { align: "center", cols: "12" }
                                        },
                                        [
                                          _c("v-subheader", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduler.scaffold-request-work-requirements-sub-header"
                                                )
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          class: [
                                            "justify-space-between",
                                            "pt-1",
                                            {
                                              "pb-0": _vm.canEditArea,
                                              "pb-5": !_vm.canEditArea
                                            }
                                          ],
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _vm.canEditArea
                                            ? _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  label: _vm.$t(
                                                    "scheduler.area"
                                                  ),
                                                  disabled:
                                                    _vm.readonly ||
                                                    _vm.processing,
                                                  items: _vm.allAreas,
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  rules:
                                                    _vm.schedulerRules.areaID,
                                                  clearable: ""
                                                },
                                                model: {
                                                  value: _vm.workOrder.areaID,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.workOrder,
                                                      "areaID",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "workOrder.areaID"
                                                }
                                              })
                                            : _c("fd-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "scheduler.area"
                                                  ),
                                                  value: _vm.workOrder.areaName,
                                                  disabled: _vm.processing,
                                                  readonly: true,
                                                  "hide-details": ""
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          class: [
                                            "justify-space-between",
                                            "pt-1",
                                            {
                                              "pb-0": _vm.canEditArea,
                                              "pb-5": !_vm.canEditArea
                                            }
                                          ],
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _vm.canEditArea
                                            ? _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  label: _vm.$t(
                                                    "scheduler.sub-area"
                                                  ),
                                                  disabled:
                                                    _vm.readonly ||
                                                    _vm.processing,
                                                  items: _vm.subAreas,
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  rules:
                                                    _vm.schedulerRules
                                                      .subAreaID,
                                                  clearable: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.workOrder.subAreaID,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.workOrder,
                                                      "subAreaID",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "workOrder.subAreaID"
                                                }
                                              })
                                            : _c("fd-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "scheduler.sub-area"
                                                  ),
                                                  disabled: _vm.processing,
                                                  readonly: true,
                                                  "hide-details": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.workOrder.subAreaName,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.workOrder,
                                                      "subAreaName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "workOrder.subAreaName"
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-5",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "scheduler.site-contact"
                                              ),
                                              disabled: _vm.processing,
                                              readonly: !_vm.canEditSiteContact,
                                              "hide-details": ""
                                            },
                                            model: {
                                              value: _vm.workOrder.siteContact,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "siteContact",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.siteContact"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-5",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fd-textarea", {
                                            attrs: {
                                              label: _vm.$t(
                                                "scheduler.location"
                                              ),
                                              rows: "1",
                                              "auto-grow": "",
                                              disabled: _vm.processing,
                                              readonly: !_vm.canEditLocation,
                                              "hide-details": ""
                                            },
                                            model: {
                                              value:
                                                _vm.workOrder
                                                  .specificWorkLocation,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "specificWorkLocation",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.specificWorkLocation"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-5",
                                          attrs: { align: "center", cols: "12" }
                                        },
                                        [
                                          _c("fd-textarea", {
                                            attrs: {
                                              label: _vm.$t(
                                                "scheduler.scaffold-request-work-description"
                                              ),
                                              rows: "1",
                                              "auto-grow": "",
                                              disabled: _vm.processing,
                                              readonly: !_vm.canEditDescription,
                                              "hide-details": ""
                                            },
                                            model: {
                                              value:
                                                _vm.workOrder
                                                  .detailedWorkDescription,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "detailedWorkDescription",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.detailedWorkDescription"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 pb-5 mt-5",
                                          attrs: { align: "center", cols: "12" }
                                        },
                                        [
                                          _c("v-subheader", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "common.additional-details"
                                                )
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            staticClass: "fd-readonly-input",
                                            attrs: {
                                              outlined: "",
                                              label: _vm.$t(
                                                "scheduler.request-number-column-header"
                                              ),
                                              disabled: _vm.processing,
                                              readonly: true
                                            },
                                            model: {
                                              value:
                                                _vm.workOrder.requestNumber,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "requestNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.requestNumber"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            staticClass: "fd-readonly-input",
                                            attrs: {
                                              outlined: "",
                                              label: _vm.$t(
                                                "scheduler.job-number-column-header"
                                              ),
                                              disabled: _vm.processing,
                                              readonly: true
                                            },
                                            model: {
                                              value: _vm.workOrder.jobNumber,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "jobNumber",
                                                  $$v
                                                )
                                              },
                                              expression: "workOrder.jobNumber"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-5 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            staticClass: "fd-readonly-input",
                                            attrs: {
                                              label: _vm.$t(
                                                "scheduler.requesting-contractor-column-header"
                                              ),
                                              disabled: _vm.processing,
                                              readonly: true
                                            },
                                            model: {
                                              value:
                                                _vm.workOrder
                                                  .requestingContractorName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "requestingContractorName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.requestingContractorName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-5 pt-sm-1 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            staticClass: "fd-readonly-input",
                                            attrs: {
                                              outlined: "",
                                              label: _vm.$t(
                                                "scheduler.requesting-employee"
                                              ),
                                              disabled: _vm.processing,
                                              readonly: true
                                            },
                                            model: {
                                              value:
                                                _vm.workOrder
                                                  .requestingEmployeeName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "requestingEmployeeName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.requestingEmployeeName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-5 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            staticClass: "fd-readonly-input",
                                            attrs: {
                                              outlined: "",
                                              label: _vm.$t(
                                                "scheduler.discipline"
                                              ),
                                              disabled: _vm.processing,
                                              readonly: true
                                            },
                                            model: {
                                              value:
                                                _vm.workOrder.disciplineName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "disciplineName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.disciplineName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-5 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "6"
                                          }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            staticClass: "fd-readonly-input",
                                            attrs: {
                                              outlined: "",
                                              label: _vm.$t(
                                                "scheduler.start-date-column-header"
                                              ),
                                              disabled: _vm.processing,
                                              readonly: true
                                            },
                                            model: {
                                              value:
                                                _vm.workOrder
                                                  .formattedStartDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "formattedStartDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.formattedStartDate"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "justify-space-between pt-5 pb-1",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            md: _vm.isScaffoldWorkOrder ? 12 : 6
                                          }
                                        },
                                        [
                                          _c("fd-work-package-selector", {
                                            attrs: {
                                              loading: _vm.processing,
                                              refreshItems:
                                                _vm.loadWorkPackages,
                                              items: _vm.availableIWPs,
                                              disabled: _vm.processing,
                                              readonly: !_vm.canEditWorkPackages
                                            },
                                            on: {
                                              focused: function($event) {
                                                {
                                                  _vm.hideActionButtons = true
                                                }
                                              },
                                              unfocused: function($event) {
                                                {
                                                  _vm.hideActionButtons = false
                                                }
                                              }
                                            },
                                            model: {
                                              value: _vm.selectedIWPs,
                                              callback: function($$v) {
                                                _vm.selectedIWPs = $$v
                                              },
                                              expression: "selectedIWPs"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      !_vm.isScaffoldWorkOrder
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-3 pb-7",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                md: "6"
                                              }
                                            },
                                            [
                                              _c("fd-async-search-box", {
                                                attrs: {
                                                  "data-cy": "iso",
                                                  refreshItems: _vm.loadIsos,
                                                  items: _vm.availableIsos,
                                                  selectedItems:
                                                    _vm.selectedIso,
                                                  label: _vm.$t("job.iso"),
                                                  multiple: false,
                                                  itemText: "search",
                                                  itemDetail: "details",
                                                  chipClass:
                                                    "keywords-selection-chip-colors-secondary",
                                                  disabled:
                                                    _vm.readonly ||
                                                    _vm.processing,
                                                  placeholder: _vm.$t(
                                                    "job.iso-select-placeholder"
                                                  ),
                                                  "hide-details": ""
                                                },
                                                on: {
                                                  "update:selectedItems": function(
                                                    $event
                                                  ) {
                                                    _vm.selectedIso = $event
                                                  },
                                                  "update:selected-items": function(
                                                    $event
                                                  ) {
                                                    _vm.selectedIso = $event
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "selection",
                                                      fn: function({ item }) {
                                                        return [
                                                          _c("div", [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  item.description
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ])
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item",
                                                      fn: function({ item }) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  attrs: {
                                                                    "no-gutters":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        item.description
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              !!item.details
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "fd-item-detail-light pb-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            item.details
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2755498417
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.walkdownTab.key,
                              attrs: { value: `tab-${_vm.walkdownTab.key}` }
                            },
                            [
                              _c("fd-walkdown-form", {
                                ref: "walkdownform",
                                attrs: {
                                  walkdown: _vm.workOrder.walkdown,
                                  workOrder: _vm.workOrder,
                                  walkdownReadonly:
                                    _vm.processing || _vm.walkdownReadonly,
                                  "require-unassign": "",
                                  vlf:
                                    _vm.$store.state.curEnvironment
                                      .trackScaffoldVLF,
                                  requireVLF:
                                    _vm.$store.state.curEnvironment
                                      .walkdownVLFRequired
                                },
                                on: { unassign: _vm.unassignWalkdown }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.estimatesTab.key,
                              attrs: { value: `tab-${_vm.estimatesTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "estimatesform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _vm.workOrder.currentUserPermissions
                                    .canEditEstimateDetails
                                    ? _c(
                                        "v-card-title",
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: { "offset-y": "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function({ on }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "add-fab-button-primary",
                                                              attrs: {
                                                                fab: "",
                                                                elevation: "5",
                                                                depressed:
                                                                  _vm.processing,
                                                                disabled:
                                                                  _vm.processing,
                                                                color: "new"
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("mdi-plus")
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3800927588
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c(
                                                "v-list",
                                                { attrs: { subheader: "" } },
                                                [
                                                  !_vm.scaffoldTypesForMenu
                                                    .length
                                                    ? _c(
                                                        "v-list-item-group",
                                                        [
                                                          _c(
                                                            "v-subheader",
                                                            {
                                                              staticClass:
                                                                "pt-3 pb-0",
                                                              staticStyle: {
                                                                height:
                                                                  "auto !important"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "scheduler.estimates.no-scaffold-types-configured-message"
                                                                    )
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.scaffoldTypesForMenu,
                                                    function(scaffoldType) {
                                                      return _c(
                                                        "v-list-item-group",
                                                        {
                                                          key:
                                                            scaffoldType.value
                                                        },
                                                        [
                                                          _c(
                                                            "v-subheader",
                                                            {
                                                              staticClass:
                                                                "pt-3 pb-0",
                                                              staticStyle: {
                                                                height:
                                                                  "auto !important"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    scaffoldType.text
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("v-divider", {
                                                            staticClass: "my-1"
                                                          }),
                                                          _vm._v(" "),
                                                          _vm._l(
                                                            scaffoldType.subTypes,
                                                            function(subType) {
                                                              return _c(
                                                                "v-list-item",
                                                                {
                                                                  key:
                                                                    subType.value,
                                                                  staticClass:
                                                                    "py-1",
                                                                  staticStyle: {
                                                                    "min-height":
                                                                      "0px !important"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openNewDialog(
                                                                        scaffoldType.value,
                                                                        subType.value
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      subType.text
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function({ on }) {
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "ml-1 mr-3",
                                                              attrs: {
                                                                color: "black",
                                                                dark: ""
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("info")]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3947564490
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "scheduler.estimates.table-listing-tooltip"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer")
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panels",
                                    {
                                      attrs: { multiple: "", accordion: "" },
                                      model: {
                                        value: _vm.estimatePanel,
                                        callback: function($$v) {
                                          _vm.estimatePanel = $$v
                                        },
                                        expression: "estimatePanel"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel",
                                        {
                                          staticClass: "secondary-section-style"
                                        },
                                        [
                                          _c("v-expansion-panel-header", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduler.estimates.summary-panel.title"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-expansion-panel-content",
                                            {
                                              staticClass:
                                                "no-left-right-bottom-padding",
                                              staticStyle: {
                                                background: "white"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "justify-end ml-0 mr-0 fd-secondary-context-qualifier-background"
                                                },
                                                [
                                                  _c(
                                                    "v-radio-group",
                                                    {
                                                      attrs: { row: "" },
                                                      model: {
                                                        value:
                                                          _vm.summaryPanelTimeUnitDivider,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.summaryPanelTimeUnitDivider = $$v
                                                        },
                                                        expression:
                                                          "summaryPanelTimeUnitDivider"
                                                      }
                                                    },
                                                    [
                                                      _c("v-radio", {
                                                        attrs: {
                                                          value: 1,
                                                          label: _vm.$t(
                                                            "common.minutes-short"
                                                          )
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("v-radio", {
                                                        attrs: {
                                                          value: 60,
                                                          label: _vm.$t(
                                                            "common.hours"
                                                          )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-container",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { fluid: "" }
                                                },
                                                [
                                                  _c(
                                                    "sp-estimate-summary-table",
                                                    {
                                                      attrs: {
                                                        "estimate-components":
                                                          _vm.workingComponents,
                                                        summaryPanelTimeUnitDivider:
                                                          _vm.summaryPanelTimeUnitDivider
                                                      },
                                                      on: {
                                                        edit: _vm.editItem,
                                                        delete: _vm.deleteItem,
                                                        undelete: _vm.reAddItem
                                                      }
                                                    }
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-card-actions",
                                                    [
                                                      _c("v-spacer"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            outlined: "",
                                                            disabled:
                                                              _vm.processing ||
                                                              !_vm
                                                                .workingComponents
                                                                .length
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.openWorkingTakeoffDialog
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "scheduler.estimates.components-panel.takeoff"
                                                                )
                                                              ) +
                                                              "\n                          "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            disabled:
                                                              _vm.processing ||
                                                              !_vm.canGenerateSnapshot,
                                                            loading:
                                                              _vm.generatingSnapshot
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.generateSnapshot
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "scheduler.estimates.components-panel.generate-snapshot-button-label"
                                                                )
                                                              ) +
                                                              "\n                          "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-expansion-panel",
                                        {
                                          staticClass: "secondary-section-style"
                                        },
                                        [
                                          _c("v-expansion-panel-header", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduler.estimates.estimates-panel.title"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-expansion-panel-content",
                                            {
                                              staticClass:
                                                "no-left-right-bottom-padding",
                                              staticStyle: {
                                                background: "white"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "ml-1 mr-3",
                                                                        attrs: {
                                                                          color:
                                                                            "black",
                                                                          dark:
                                                                            ""
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "info"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ])
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "scheduler.estimates.table-listing-tooltip"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("v-spacer")
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "6" } },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "pl-0",
                                                        attrs: {
                                                          "append-icon":
                                                            "search",
                                                          label: _vm.$t(
                                                            "common.search"
                                                          ),
                                                          "single-line": "",
                                                          "hide-details": "",
                                                          clearable: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.tablesearchestimates,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.tablesearchestimates = $$v
                                                          },
                                                          expression:
                                                            "tablesearchestimates"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { attrs: { align: "center" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c("sp-estimates-list", {
                                                        attrs: {
                                                          estimates:
                                                            _vm.estimatesList,
                                                          loading:
                                                            _vm.processing,
                                                          tablesearchestimates:
                                                            _vm.tablesearchestimates,
                                                          summaryPanelTimeUnitDivider:
                                                            _vm.summaryPanelTimeUnitDivider
                                                        },
                                                        on: {
                                                          download:
                                                            _vm.downloadEstimateExcelFile
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.photosTab.key,
                              attrs: { value: `tab-${_vm.photosTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "filesform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            [
                                              _c("fd-add-file-button", {
                                                attrs: {
                                                  disabled:
                                                    _vm.readonly ||
                                                    _vm.processing
                                                },
                                                on: {
                                                  change: _vm.selectNewFile
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("v-divider", {
                                                staticClass: "mx-4",
                                                attrs: {
                                                  inset: "",
                                                  vertical: ""
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function({ on }) {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-1 mr-3",
                                                                attrs: {
                                                                  color:
                                                                    "black",
                                                                  dark: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v("info")]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "scaffold-requests.photos-table-listing-tooltip"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c("v-text-field", {
                                                staticClass: "pl-0",
                                                attrs: {
                                                  "append-icon": "search",
                                                  label: _vm.$t(
                                                    "common.search"
                                                  ),
                                                  "single-line": "",
                                                  "hide-details": "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value: _vm.tablesearchfiles,
                                                  callback: function($$v) {
                                                    _vm.tablesearchfiles = $$v
                                                  },
                                                  expression: "tablesearchfiles"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("fd-alert", {
                                            attrs: {
                                              type: "info",
                                              label: _vm.$t(
                                                "attachments.file-not-photo-alert-message",
                                                [
                                                  _vm.touchedFileName.toUpperCase()
                                                ]
                                              ),
                                              dismissible: ""
                                            },
                                            model: {
                                              value:
                                                _vm.showPhotoTabAttachmentAlert,
                                              callback: function($$v) {
                                                _vm.showPhotoTabAttachmentAlert = $$v
                                              },
                                              expression:
                                                "showPhotoTabAttachmentAlert"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            directives: [
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:[photosIconColumnArgument].no-sort.hide-when-extra-small.class_fd-table-icon-cell",
                                                value: "",
                                                expression:
                                                  "\n                        ''\n                      ",
                                                arg:
                                                  _vm.photosIconColumnArgument,
                                                modifiers: {
                                                  "no-sort": true,
                                                  "hide-when-extra-small": true,
                                                  "class_fd-table-icon-cell": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName: "v-fd-column:name",
                                                value: _vm.$t("common.name"),
                                                expression: "$t('common.name')",
                                                arg: "name"
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                                arg: "actions",
                                                modifiers: {
                                                  "no-sort": true,
                                                  "hide-when-extra-small": true,
                                                  "class_fd-actions-cell": true
                                                }
                                              },
                                              {
                                                name: "fd-column",
                                                rawName:
                                                  "v-fd-column:action.no-sort.show-when-extra-small.class_fd-actions-cell-edit-only",
                                                arg: "action",
                                                modifiers: {
                                                  "no-sort": true,
                                                  "show-when-extra-small": true,
                                                  "class_fd-actions-cell-edit-only": true
                                                }
                                              }
                                            ],
                                            class: _vm.$vuetify.breakpoint
                                              .xsOnly
                                              ? "fd-action-table"
                                              : "fd-actions-table",
                                            attrs: {
                                              items: _vm.photoFiles,
                                              search: _vm.tablesearchfiles,
                                              loading: _vm.processing,
                                              "loading-text": _vm.$t(
                                                "common.table-loading-message"
                                              ),
                                              "sort-by": ["name"],
                                              "item-class":
                                                _vm.fileRowClassName,
                                              "mobile-breakpoint": "0"
                                            },
                                            on: {
                                              "dblclick:row":
                                                _vm.photosRowClicked
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "item.icon",
                                                fn: function({ item }) {
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          left: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  !!item.isIsoFile
                                                                    ? _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$store.getters.icon(
                                                                                "isos"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "job.existing.file-comes-from-iso"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.action",
                                                fn: function({ item }) {
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          left: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            !item.isPreviewable ||
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.viewFile(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-eye\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.view"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item.actions",
                                                fn: function({ item }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        class: [
                                                          {
                                                            "table-5-actions-column-min-width":
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .mdAndUp,
                                                            "table-4-actions-column-min-width": !_vm
                                                              .$vuetify
                                                              .breakpoint
                                                              .mdAndUp
                                                          }
                                                        ]
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              left: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.openFileInNewWindow(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-open-in-new\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.open-in-new-tab"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              left: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                !item.isPreviewable ||
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.viewFile(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-eye\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.view"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              top: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                !item.isPreviewable ||
                                                                                item.isIsoFile ||
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.editFile(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-pencil\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.edit"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.$vuetify.breakpoint
                                                          .mdAndUp
                                                          ? _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  link:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.downloadFile(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  mdi-cloud-download\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.download"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              top: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                _vm.processing ||
                                                                                item.isIsoFile
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.deleteFile(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-delete\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.remove"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.notesTab.key,
                              attrs: { value: `tab-${_vm.notesTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "notesform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "pa-0 ma-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 ma-0",
                                          attrs: { cols: "12", lg: "8" }
                                        },
                                        [
                                          _vm.currentUserCanAddNote ||
                                          (!!_vm.notes && _vm.notes.length > 0)
                                            ? _c(
                                                "v-timeline",
                                                { attrs: { dense: "" } },
                                                [
                                                  _c(
                                                    "fd-new-note-timeline-item",
                                                    {
                                                      attrs: {
                                                        loading: _vm.saving,
                                                        disabled:
                                                          !_vm.currentUserCanAddNote ||
                                                          _vm.processing
                                                      },
                                                      on: {
                                                        addNewNote:
                                                          _vm.addNewNote
                                                      },
                                                      model: {
                                                        value: _vm.newNoteText,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.newNoteText = $$v
                                                        },
                                                        expression:
                                                          "newNoteText"
                                                      }
                                                    }
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._l(_vm.notes, function(
                                                    item,
                                                    i
                                                  ) {
                                                    return _c(
                                                      "fd-history-note-timeline-item",
                                                      {
                                                        key: i,
                                                        attrs: { note: item }
                                                      }
                                                    )
                                                  })
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.messagingTab.key,
                              attrs: { value: `tab-${_vm.messagingTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "messagingform",
                                  class: ["mr-5"],
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "pa-0 ma-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 ma-0",
                                          attrs: { cols: "12", lg: "8" }
                                        },
                                        [
                                          _vm.currentUserCanAddMessage ||
                                          (!!_vm.messages &&
                                            _vm.messages.length > 0)
                                            ? _c(
                                                "v-timeline",
                                                { attrs: { dense: "" } },
                                                [
                                                  _vm.currentUserCanAddMessage
                                                    ? _c(
                                                        "v-timeline-item",
                                                        {
                                                          staticClass:
                                                            "fd-new-messaging-avatar",
                                                          attrs: {
                                                            large:
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .smAndUp
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "icon",
                                                                fn: function() {
                                                                  return [
                                                                    _c(
                                                                      "v-avatar",
                                                                      {
                                                                        staticClass:
                                                                          "profile-avatar-background-color",
                                                                        attrs: {
                                                                          size: _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .smAndUp
                                                                            ? "48"
                                                                            : "34"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            class: [
                                                                              "white--text",
                                                                              "text-h5",
                                                                              _vm
                                                                                .$vuetify
                                                                                .breakpoint
                                                                                .smAndUp
                                                                                ? ""
                                                                                : "fd-work-order-message-avatar-initials"
                                                                            ]
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.avatarInitials
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                },
                                                                proxy: true
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            813896479
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                position:
                                                                  "relative"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "fd-textarea",
                                                                {
                                                                  attrs: {
                                                                    rows: "1",
                                                                    dense:
                                                                      _vm
                                                                        .$vuetify
                                                                        .breakpoint
                                                                        .xs,
                                                                    loading:
                                                                      _vm.saving,
                                                                    disabled:
                                                                      !_vm.currentUserCanAddMessage ||
                                                                      _vm.processing,
                                                                    "auto-grow":
                                                                      "",
                                                                    label: _vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .xs
                                                                      ? _vm.$t(
                                                                          "scaffold-requests.messaging-new-input-placeholder-short"
                                                                        )
                                                                      : _vm.$t(
                                                                          "scaffold-requests.messaging-new-input-placeholder"
                                                                        )
                                                                  },
                                                                  on: {
                                                                    keydown: function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "enter",
                                                                          13,
                                                                          $event.key,
                                                                          "Enter"
                                                                        )
                                                                      )
                                                                        return null
                                                                      return _vm.comment.apply(
                                                                        null,
                                                                        arguments
                                                                      )
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.newMessageText,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.newMessageText = $$v
                                                                    },
                                                                    expression:
                                                                      "newMessageText"
                                                                  }
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  class: _vm
                                                                    .$vuetify
                                                                    .breakpoint
                                                                    .xs
                                                                    ? "fd-new-messaging-btn-small"
                                                                    : "fd-new-messaging-btn",
                                                                  attrs: {
                                                                    "data-cy":
                                                                      "post",
                                                                    loading:
                                                                      _vm.saving,
                                                                    small:
                                                                      _vm
                                                                        .$vuetify
                                                                        .breakpoint
                                                                        .xsOnly,
                                                                    disabled:
                                                                      !_vm.currentUserCanAddMessage ||
                                                                      _vm.processing
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.addNewMessage
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "common.post"
                                                                        )
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm._l(_vm.messages, function(
                                                    item,
                                                    i
                                                  ) {
                                                    return _c(
                                                      "fd-history-message-timeline-item",
                                                      {
                                                        key: i,
                                                        attrs: { message: item }
                                                      }
                                                    )
                                                  })
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.scopeTab.key,
                              attrs: { value: `tab-${_vm.scopeTab.key}` }
                            },
                            [
                              _c(
                                "v-card",
                                { staticStyle: { "padding-top": "1px" } },
                                [
                                  _c("sp-work-order-scope-details-form", {
                                    ref: "scopechangeform",
                                    attrs: {
                                      cy: "scopechangeform",
                                      disabled: _vm.readonly || _vm.processing,
                                      isClientWorkOrder:
                                        _vm.workOrder.isClientWorkOrder,
                                      clientWorkOrderReferenceNumber:
                                        _vm.workOrder
                                          .clientWorkOrderReferenceNumber,
                                      clientWorkOrderReferenceNumberRules:
                                        _vm.schedulerRules
                                          .clientWorkOrderReferenceNumber,
                                      clientWorkOrderReason:
                                        _vm.workOrder.clientWorkOrderReason,
                                      clientWorkOrderReasonRules:
                                        _vm.schedulerRules
                                          .clientWorkOrderReason,
                                      isChangeOrder:
                                        _vm.workOrder.isChangeOrder,
                                      changeOrderReferenceNumber:
                                        _vm.workOrder
                                          .changeOrderReferenceNumber,
                                      changeOrderReferenceNumberRules:
                                        _vm.schedulerRules
                                          .changeOrderReferenceNumber,
                                      changeOrderReason:
                                        _vm.workOrder.changeOrderReason,
                                      changeOrderReasonRules:
                                        _vm.schedulerRules.changeOrderReason,
                                      isRework: _vm.workOrder.isRework,
                                      reworkReferenceNumber:
                                        _vm.workOrder.reworkReferenceNumber,
                                      reworkReferenceNumberRules:
                                        _vm.schedulerRules
                                          .reworkReferenceNumber,
                                      reworkReason: _vm.workOrder.reworkReason,
                                      reworkReasonRules:
                                        _vm.schedulerRules.reworkReason,
                                      isServiceOrder:
                                        _vm.workOrder.isServiceOrder,
                                      serviceOrderReferenceNumber:
                                        _vm.workOrder
                                          .serviceOrderReferenceNumber,
                                      serviceOrderReferenceNumberRules:
                                        _vm.schedulerRules
                                          .serviceOrderReferenceNumber,
                                      serviceOrderReason:
                                        _vm.workOrder.serviceOrderReason,
                                      serviceOrderReasonRules:
                                        _vm.schedulerRules.serviceOrderReason,
                                      "purchase-orders":
                                        _vm.$store.state.curEnvironment
                                          .enablePurchaseOrders,
                                      purchaseOrderID:
                                        _vm.workOrder.purchaseOrderID,
                                      purchaseOrderIDRules:
                                        _vm.schedulerRules.purchaseOrderNumber,
                                      purchaseOrderDisabled: !_vm.workOrder
                                        .requestingContractorID,
                                      selectablePurchaseOrders:
                                        _vm.selectablePurchaseOrders,
                                      showExistingTagNumber:
                                        _vm.workOrderCanHaveExistingTagNumber,
                                      existingTagNumber:
                                        _vm.workOrder.existingTagNumber
                                    },
                                    on: {
                                      "update:isClientWorkOrder": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "isClientWorkOrder",
                                          $event
                                        )
                                      },
                                      "update:is-client-work-order": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "isClientWorkOrder",
                                          $event
                                        )
                                      },
                                      "update:clientWorkOrderReferenceNumber": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "clientWorkOrderReferenceNumber",
                                          $event
                                        )
                                      },
                                      "update:client-work-order-reference-number": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "clientWorkOrderReferenceNumber",
                                          $event
                                        )
                                      },
                                      "update:clientWorkOrderReason": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "clientWorkOrderReason",
                                          $event
                                        )
                                      },
                                      "update:client-work-order-reason": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "clientWorkOrderReason",
                                          $event
                                        )
                                      },
                                      "update:isChangeOrder": function($event) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "isChangeOrder",
                                          $event
                                        )
                                      },
                                      "update:is-change-order": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "isChangeOrder",
                                          $event
                                        )
                                      },
                                      "update:changeOrderReferenceNumber": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "changeOrderReferenceNumber",
                                          $event
                                        )
                                      },
                                      "update:change-order-reference-number": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "changeOrderReferenceNumber",
                                          $event
                                        )
                                      },
                                      "update:changeOrderReason": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "changeOrderReason",
                                          $event
                                        )
                                      },
                                      "update:change-order-reason": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "changeOrderReason",
                                          $event
                                        )
                                      },
                                      "update:isRework": function($event) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "isRework",
                                          $event
                                        )
                                      },
                                      "update:is-rework": function($event) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "isRework",
                                          $event
                                        )
                                      },
                                      "update:reworkReferenceNumber": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "reworkReferenceNumber",
                                          $event
                                        )
                                      },
                                      "update:rework-reference-number": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "reworkReferenceNumber",
                                          $event
                                        )
                                      },
                                      "update:reworkReason": function($event) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "reworkReason",
                                          $event
                                        )
                                      },
                                      "update:rework-reason": function($event) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "reworkReason",
                                          $event
                                        )
                                      },
                                      "update:isServiceOrder": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "isServiceOrder",
                                          $event
                                        )
                                      },
                                      "update:is-service-order": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "isServiceOrder",
                                          $event
                                        )
                                      },
                                      "update:serviceOrderReferenceNumber": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "serviceOrderReferenceNumber",
                                          $event
                                        )
                                      },
                                      "update:service-order-reference-number": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "serviceOrderReferenceNumber",
                                          $event
                                        )
                                      },
                                      "update:serviceOrderReason": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "serviceOrderReason",
                                          $event
                                        )
                                      },
                                      "update:service-order-reason": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "serviceOrderReason",
                                          $event
                                        )
                                      },
                                      "update:purchaseOrderID": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "purchaseOrderID",
                                          $event
                                        )
                                      },
                                      "update:purchase-order-i-d": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "purchaseOrderID",
                                          $event
                                        )
                                      },
                                      "update:existingTagNumber": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "existingTagNumber",
                                          $event
                                        )
                                      },
                                      "update:existing-tag-number": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.workOrder,
                                          "existingTagNumber",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.optionsTab.key,
                              attrs: { value: `tab-${_vm.optionsTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "optionsform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-2" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "scheduler.options.work-shift-days"
                                              ),
                                              disabled:
                                                _vm.readonly || _vm.processing
                                            },
                                            model: {
                                              value: _vm.workOrder.hasDayShift,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "hasDayShift",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.hasDayShift"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "scheduler.options.work-shift-nights"
                                              ),
                                              disabled:
                                                _vm.readonly || _vm.processing
                                            },
                                            model: {
                                              value:
                                                _vm.workOrder.hasNightShift,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "hasNightShift",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.hasNightShift"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "scheduler.options.electricity"
                                              ),
                                              disabled:
                                                _vm.readonly || _vm.processing
                                            },
                                            model: {
                                              value:
                                                _vm.workOrder
                                                  .hasEnergizedEquipment,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "hasEnergizedEquipment",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.hasEnergizedEquipment"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "scheduler.options.confined-space"
                                              ),
                                              disabled:
                                                _vm.readonly || _vm.processing
                                            },
                                            model: {
                                              value:
                                                _vm.workOrder
                                                  .requiresConfinedSpacePermit,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "requiresConfinedSpacePermit",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.requiresConfinedSpacePermit"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6"
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.$t(
                                                "scheduler.options.lockout"
                                              ),
                                              disabled:
                                                _vm.readonly || _vm.processing
                                            },
                                            model: {
                                              value:
                                                _vm.workOrder
                                                  .requiresLockoutProcedure,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.workOrder,
                                                  "requiresLockoutProcedure",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "workOrder.requiresLockoutProcedure"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.attachmentsTab.key,
                              attrs: { value: `tab-${_vm.attachmentsTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "attachmentsform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mx-2",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                [
                                                  _c("input", {
                                                    ref: "addFileButton",
                                                    staticClass: "d-none",
                                                    attrs: {
                                                      type: "file",
                                                      onclick:
                                                        "this.value=null;"
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.fileInputChanged
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: { "offset-y": "" },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "add-fab-button-primary",
                                                                    attrs: {
                                                                      fab: "",
                                                                      elevation:
                                                                        "5",
                                                                      depressed:
                                                                        _vm.readonly ||
                                                                        _vm.processing,
                                                                      disabled:
                                                                        _vm.readonly ||
                                                                        _vm.processing,
                                                                      color:
                                                                        "new"
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-plus"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-list",
                                                        [
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  _vm.openNewExternalLinkDialog
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "attachments.external-link.menu-title"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  _vm.selectFile
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.local-file"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-divider", {
                                                    staticClass: "mx-4",
                                                    attrs: {
                                                      inset: "",
                                                      vertical: ""
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "ml-1",
                                                                    attrs: {
                                                                      color:
                                                                        "black",
                                                                      dark: ""
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [_vm._v("info")]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "users.files-table-listing-tooltip"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-spacer"),
                                                  _vm._v(" "),
                                                  _c("v-text-field", {
                                                    staticClass: "pl-0",
                                                    attrs: {
                                                      "append-icon": "search",
                                                      label: _vm.$t(
                                                        "common.search"
                                                      ),
                                                      "single-line": "",
                                                      "hide-details": "",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.tablesearchfiles,
                                                      callback: function($$v) {
                                                        _vm.tablesearchfiles = $$v
                                                      },
                                                      expression:
                                                        "tablesearchfiles"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("fd-alert", {
                                                attrs: {
                                                  type: "info",
                                                  label: _vm.$t(
                                                    "attachments.file-is-photo-alert-message",
                                                    [
                                                      _vm.touchedFileName.toUpperCase()
                                                    ]
                                                  ),
                                                  dismissible: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.showAttachmentTabPhotoAlert,
                                                  callback: function($$v) {
                                                    _vm.showAttachmentTabPhotoAlert = $$v
                                                  },
                                                  expression:
                                                    "showAttachmentTabPhotoAlert"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("v-data-table", {
                                                directives: [
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:icon.no-sort.hide-when-extra-small.class_fd-table-icon-cell",
                                                    value: "",
                                                    expression:
                                                      "\n                          ''\n                        ",
                                                    arg: "icon",
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "hide-when-extra-small": true,
                                                      "class_fd-table-icon-cell": true
                                                    }
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:[nonPhotosIconColumnArgument].no-sort.hide-when-extra-small.class_fd-table-icon-cell",
                                                    value: "",
                                                    expression:
                                                      "\n                          ''\n                        ",
                                                    arg:
                                                      _vm.nonPhotosIconColumnArgument,
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "hide-when-extra-small": true,
                                                      "class_fd-table-icon-cell": true
                                                    }
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName: "v-fd-column:name",
                                                    value: _vm.$t(
                                                      "common.name"
                                                    ),
                                                    expression:
                                                      "$t('common.name')",
                                                    arg: "name"
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                                    arg: "actions",
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "hide-when-extra-small": true,
                                                      "class_fd-actions-cell": true
                                                    }
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:action.no-sort.show-when-extra-small.class_fd-actions-cell-edit-only",
                                                    arg: "action",
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "show-when-extra-small": true,
                                                      "class_fd-actions-cell-edit-only": true
                                                    }
                                                  }
                                                ],
                                                class: _vm.$vuetify.breakpoint
                                                  .xsOnly
                                                  ? "fd-action-table"
                                                  : "fd-actions-table",
                                                attrs: {
                                                  items:
                                                    _vm.nonPhotoAttachments,
                                                  search: _vm.tablesearchfiles,
                                                  loading: _vm.processing,
                                                  "loading-text": _vm.$t(
                                                    "common.table-loading-message"
                                                  ),
                                                  "sort-by": ["name"],
                                                  "item-class":
                                                    _vm.fileRowClassName,
                                                  "mobile-breakpoint": "0"
                                                },
                                                on: {
                                                  "dblclick:row":
                                                    _vm.attachmentRowClicked
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "item.isoIcon",
                                                    fn: function({ item }) {
                                                      return [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              left: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      !!item.blobFile &&
                                                                      item
                                                                        .blobFile
                                                                        .isIsoFile
                                                                        ? _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              {},
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$store.getters.icon(
                                                                                    "isos"
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "job.existing.file-comes-from-iso"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item.icon",
                                                    fn: function({ item }) {
                                                      return [
                                                        item.type == "link"
                                                          ? _c("v-icon", [
                                                              _vm._v("fa-link")
                                                            ])
                                                          : item.type ==
                                                              "file" &&
                                                            item.isPhoto
                                                          ? _c("v-icon", [
                                                              _vm._v(
                                                                "fa-camera"
                                                              )
                                                            ])
                                                          : _c("v-icon", [
                                                              _vm._v("fa-file")
                                                            ])
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item.action",
                                                    fn: function({ item }) {
                                                      return [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              left: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                !item.canOpenInNew ||
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.openAttachment(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-open-in-new\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.open-in-new-tab"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item.actions",
                                                    fn: function({ item }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            class: [
                                                              {
                                                                "table-5-actions-column-min-width":
                                                                  _vm.$vuetify
                                                                    .breakpoint
                                                                    .mdAndUp,
                                                                "table-4-actions-column-min-width": !_vm
                                                                  .$vuetify
                                                                  .breakpoint
                                                                  .mdAndUp
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  left: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    !item.canOpenInNew ||
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.openAttachment(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-open-in-new\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.open-in-new-tab"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  left: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    !item.isPreviewable ||
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.viewFile(
                                                                                        item.file
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-eye\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.view"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  link:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.processing ||
                                                                                    (!!item.blobFile &&
                                                                                      item
                                                                                        .blobFile
                                                                                        .isIsoFile)
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editAttachment(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-pencil\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.edit"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.$vuetify
                                                              .breakpoint
                                                              .mdAndUp
                                                              ? _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        !_vm
                                                                          .$vuetify
                                                                          .breakpoint
                                                                          .lgAndUp ||
                                                                        _vm.processing,
                                                                      top: ""
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function({
                                                                            on
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  {
                                                                                    attrs: {
                                                                                      icon:
                                                                                        "",
                                                                                      link:
                                                                                        "",
                                                                                      disabled:
                                                                                        (!item.file &&
                                                                                          !item.blobFile) ||
                                                                                        _vm.processing
                                                                                    }
                                                                                  },
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      attrs: {
                                                                                        small:
                                                                                          ""
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.downloadAttachment(
                                                                                            item
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                    mdi-cloud-download\n                                  "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  },
                                                                  [
                                                                    _vm._v(" "),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "common.download"
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.processing ||
                                                                                    (!!item.blobFile &&
                                                                                      item
                                                                                        .blobFile
                                                                                        .isIsoFile)
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deleteAttachment(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-delete\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.remove"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.countSheetTab.key,
                              attrs: { value: `tab-${_vm.countSheetTab.key}` }
                            },
                            [
                              _c("fd-count-sheet-form", {
                                attrs: {
                                  "restrict-content-height": false,
                                  workOrder: _vm.workOrder,
                                  countSheet: _vm.countSheet,
                                  countSheetGroups: _vm.countSheetGroups,
                                  "can-pick-individual": _vm.canPickIndividual,
                                  "can-remove-all": _vm.canRemoveAll,
                                  "can-have-no-material": _vm.canHaveNoMaterial,
                                  "force-readonly":
                                    _vm.countSheetReadonly ||
                                    !_vm.countSheetIsEditable,
                                  "prevent-approval": ""
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.normsTab.key,
                              attrs: { value: `tab-${_vm.normsTab.key}` }
                            },
                            [
                              _vm.isScaffoldWorkOrder
                                ? _c("sp-build-sheet-form", {
                                    attrs: {
                                      scaffoldTypeModifiers:
                                        _vm.activeScaffoldTypeModifiers,
                                      scaffoldElevationModifiers:
                                        _vm.allScaffoldElevations,
                                      totalTimesheetHours:
                                        _vm.totalTimesheetHours,
                                      processing: _vm.processing,
                                      normsWithResponses:
                                        _vm.normsWithResponses,
                                      length: _vm.buildSheet.length,
                                      width: _vm.buildSheet.width,
                                      height: _vm.buildSheet.height,
                                      deckLevels: _vm.buildSheet.deckLevels,
                                      hazardStatusResult:
                                        _vm.buildSheet.hazardLevel,
                                      confinedSpace:
                                        _vm.buildSheet.confinedSpace,
                                      ppeRequired: _vm.buildSheet.ppeRequired,
                                      scaffoldTypeModifierID:
                                        _vm.buildSheet.scaffoldTypeModifierID,
                                      scaffoldElevationModifierID:
                                        _vm.buildSheet
                                          .scaffoldElevationModifierID,
                                      notes: _vm.buildSheet.notes,
                                      vlfChangeAmount:
                                        _vm.buildSheet.vlfChangeAmount,
                                      currentScaffoldVlf:
                                        _vm.buildSheet.currentScaffoldVlf,
                                      scaffoldTotalVLF:
                                        _vm.workOrder.scaffoldTotalVLF,
                                      scaffoldRequestType:
                                        _vm.workOrder.scaffoldRequestType,
                                      canEditBuildSheetResponses:
                                        _vm.workOrder.currentUserPermissions
                                          .canEditBuildSheetResponses
                                    },
                                    on: {
                                      "update:normsWithResponses": function(
                                        $event
                                      ) {
                                        _vm.normsWithResponses = $event
                                      },
                                      "update:norms-with-responses": function(
                                        $event
                                      ) {
                                        _vm.normsWithResponses = $event
                                      },
                                      "update:length": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "length",
                                          $event
                                        )
                                      },
                                      "update:width": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "width",
                                          $event
                                        )
                                      },
                                      "update:height": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "height",
                                          $event
                                        )
                                      },
                                      "update:deckLevels": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "deckLevels",
                                          $event
                                        )
                                      },
                                      "update:deck-levels": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "deckLevels",
                                          $event
                                        )
                                      },
                                      "update:hazardStatusResult": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "hazardLevel",
                                          $event
                                        )
                                      },
                                      "update:hazard-status-result": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "hazardLevel",
                                          $event
                                        )
                                      },
                                      "update:confinedSpace": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "confinedSpace",
                                          $event
                                        )
                                      },
                                      "update:confined-space": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "confinedSpace",
                                          $event
                                        )
                                      },
                                      "update:ppeRequired": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "ppeRequired",
                                          $event
                                        )
                                      },
                                      "update:ppe-required": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "ppeRequired",
                                          $event
                                        )
                                      },
                                      "update:scaffoldTypeModifierID": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "scaffoldTypeModifierID",
                                          $event
                                        )
                                      },
                                      "update:scaffold-type-modifier-i-d": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "scaffoldTypeModifierID",
                                          $event
                                        )
                                      },
                                      "update:scaffoldElevationModifierID": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "scaffoldElevationModifierID",
                                          $event
                                        )
                                      },
                                      "update:scaffold-elevation-modifier-i-d": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "scaffoldElevationModifierID",
                                          $event
                                        )
                                      },
                                      "update:notes": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "notes",
                                          $event
                                        )
                                      },
                                      "update:vlfChangeAmount": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "vlfChangeAmount",
                                          $event
                                        )
                                      },
                                      "update:vlf-change-amount": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "vlfChangeAmount",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isPaintWorkOrder
                                ? _c("sp-paint-build-sheet-form", {
                                    attrs: {
                                      processing: _vm.processing,
                                      canEditBuildSheetResponses:
                                        _vm.workOrder.currentUserPermissions
                                          .canEditBuildSheetResponses,
                                      normsWithResponses:
                                        _vm.normsWithResponses,
                                      notes: _vm.buildSheet.notes,
                                      applicationTypes: _vm.allApplicationTypes,
                                      paintCodes: _vm.allPaintCodes,
                                      setUpTypes: _vm.allSetUpTypes,
                                      tsas: _vm.allTsas,
                                      workCategories: _vm.allWorkCategories,
                                      applicationTypeID:
                                        _vm.buildSheet.applicationTypeID,
                                      paintCodeID: _vm.buildSheet.paintCodeID,
                                      setUpTypeID: _vm.buildSheet.setUpTypeID,
                                      tsaID: _vm.buildSheet.tsaID,
                                      workCategoryID:
                                        _vm.buildSheet.workCategoryID,
                                      equipmentNumber:
                                        _vm.buildSheet.equipmentNumber,
                                      diameter: _vm.buildSheet.diameter,
                                      squareFeet: _vm.buildSheet.squareFeet,
                                      linearFeet: _vm.buildSheet.linearFeet,
                                      paintReason: _vm.buildSheet.paintReason,
                                      coatNumber: _vm.buildSheet.coatNumber
                                    },
                                    on: {
                                      "update:normsWithResponses": function(
                                        $event
                                      ) {
                                        _vm.normsWithResponses = $event
                                      },
                                      "update:norms-with-responses": function(
                                        $event
                                      ) {
                                        _vm.normsWithResponses = $event
                                      },
                                      "update:notes": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "notes",
                                          $event
                                        )
                                      },
                                      "update:applicationTypeID": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "applicationTypeID",
                                          $event
                                        )
                                      },
                                      "update:application-type-i-d": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "applicationTypeID",
                                          $event
                                        )
                                      },
                                      "update:paintCodeID": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "paintCodeID",
                                          $event
                                        )
                                      },
                                      "update:paint-code-i-d": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "paintCodeID",
                                          $event
                                        )
                                      },
                                      "update:setUpTypeID": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "setUpTypeID",
                                          $event
                                        )
                                      },
                                      "update:set-up-type-i-d": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "setUpTypeID",
                                          $event
                                        )
                                      },
                                      "update:tsaID": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "tsaID",
                                          $event
                                        )
                                      },
                                      "update:tsa-i-d": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "tsaID",
                                          $event
                                        )
                                      },
                                      "update:workCategoryID": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "workCategoryID",
                                          $event
                                        )
                                      },
                                      "update:work-category-i-d": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "workCategoryID",
                                          $event
                                        )
                                      },
                                      "update:equipmentNumber": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "equipmentNumber",
                                          $event
                                        )
                                      },
                                      "update:equipment-number": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "equipmentNumber",
                                          $event
                                        )
                                      },
                                      "update:diameter": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "diameter",
                                          $event
                                        )
                                      },
                                      "update:squareFeet": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "squareFeet",
                                          $event
                                        )
                                      },
                                      "update:square-feet": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "squareFeet",
                                          $event
                                        )
                                      },
                                      "update:linearFeet": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "linearFeet",
                                          $event
                                        )
                                      },
                                      "update:linear-feet": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "linearFeet",
                                          $event
                                        )
                                      },
                                      "update:paintReason": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "paintReason",
                                          $event
                                        )
                                      },
                                      "update:paint-reason": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "paintReason",
                                          $event
                                        )
                                      },
                                      "update:coatNumber": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "coatNumber",
                                          $event
                                        )
                                      },
                                      "update:coat-number": function($event) {
                                        return _vm.$set(
                                          _vm.buildSheet,
                                          "coatNumber",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-form",
                        { ref: "form", on: { submit: _vm.onSubmit } },
                        [
                          _vm.inlineMessage.message
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "mx-2",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _vm.inlineMessage.message
                                        ? _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                type: _vm.inlineMessage.type
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "white-space": "pre-line"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.inlineMessage.message
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.displayAdditionalActionButtons
                            ? _c(
                                "v-row",
                                {
                                  staticClass:
                                    "mx-2 mb-3 d-flex d-sm-none justify-end align-center",
                                  class: {
                                    "fd-actions-hidden": _vm.hideActionButtons
                                  }
                                },
                                [
                                  _vm.selectedTab == _vm.walkdownTab
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            "data-cy": "submitwalkdown-small",
                                            outlined: "",
                                            color: "primary",
                                            loading: _vm.submitting,
                                            disabled:
                                              _vm.walkdownReadonly ||
                                              _vm.processing,
                                            small: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.submitWalkdown()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "walkdowns.existing-walkdown.submit"
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.selectedTab == _vm.countSheetTab
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            "data-cy": "submitcountsheet-small",
                                            outlined: "",
                                            color: "primary",
                                            loading: _vm.submitting,
                                            disabled:
                                              _vm.countSheetReadonly ||
                                              _vm.processing ||
                                              !_vm.countSheetIsEditable ||
                                              !_vm.canSubmitCountSheet,
                                            small: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.saveAndSubmitCountSheet()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "countsheet.existing.submit"
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            {
                              staticClass: "mx-0 px-3 pb-3",
                              class: {
                                "fd-actions-hidden": _vm.hideActionButtons
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    outlined: "",
                                    color: "secondary",
                                    disabled: _vm.processing,
                                    small: _vm.$vuetify.breakpoint.xsOnly
                                  },
                                  on: { click: _vm.showWorkOrderSummary }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common.summary"))
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _vm.selectedTab == _vm.walkdownTab
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "d-none d-sm-flex",
                                      attrs: {
                                        "data-cy": "submitwalkdown",
                                        outlined: "",
                                        color: "primary",
                                        loading: _vm.submitting,
                                        disabled:
                                          _vm.walkdownReadonly || _vm.processing
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.submitWalkdown()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "walkdowns.existing-walkdown.submit"
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.selectedTab == _vm.countSheetTab
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "d-none d-sm-flex",
                                      attrs: {
                                        "data-cy": "submitcountsheet",
                                        outlined: "",
                                        color: "primary",
                                        loading: _vm.submitting,
                                        disabled:
                                          _vm.countSheetReadonly ||
                                          _vm.processing ||
                                          !_vm.countSheetIsEditable ||
                                          !_vm.canSubmitCountSheet
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.saveAndSubmitCountSheet()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("countsheet.existing.submit")
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.displayAdditionalActionButtons
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ml-4 mr-3 fd-button-context-separator-hide-when-mobile"
                                    },
                                    [
                                      _vm._v(
                                        "\n                |\n              "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    text: "",
                                    link: "",
                                    disabled: _vm.processing,
                                    small: _vm.$vuetify.breakpoint.xsOnly
                                  },
                                  on: { click: _vm.cancel }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common.cancel")) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("fd-menu-save", {
                                attrs: {
                                  disabled:
                                    (_vm.readonly && _vm.walkdownReadonly) ||
                                    _vm.processing,
                                  loading: _vm.saving,
                                  small: _vm.$vuetify.breakpoint.xsOnly
                                },
                                on: {
                                  "click:save": function($event) {
                                    return _vm.save(false)
                                  },
                                  "click:save-and-close": function($event) {
                                    return _vm.save(true)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }