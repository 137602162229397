var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "fd-work-order-administration-screen",
      attrs: { fluid: "", "data-cy": "workorderadministration" }
    },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view" },
        [
          _c(
            "fd-toolbar",
            { attrs: { extended: "", loading: _vm.processing } },
            [
              _c(
                "v-row",
                { staticClass: "pb-5 pb-sm-0" },
                [
                  _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : ""
                        ],
                        staticStyle: {
                          "font-size": "1.40rem !important",
                          position: "relative"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "0px",
                              left: "0px"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                _c("v-breadcrumbs", {
                                  class: [
                                    _vm.processing
                                      ? "breadcrumb-processing-opacity"
                                      : "",
                                    "pl-0"
                                  ],
                                  attrs: {
                                    items: _vm.$store.state.currentBreadcrumbs,
                                    large: ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "divider",
                                      fn: function() {
                                        return [
                                          _c("v-icon", [
                                            _vm._v("fa-chevron-right")
                                          ])
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                }),
                                _vm._v(" "),
                                !!_vm.curUserAccess.contractorID
                                  ? _c("span", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "hide-when-no-contractor-class"
                                        },
                                        [_vm._v("|")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-view-header-contractor-qualifier-label"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "6px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "contractors.contractor-select"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-view-header-contractor-qualifier-contractor-detail-positioning"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: [
                                                _vm.$vuetify.breakpoint.xs
                                                  ? "fd-view-header-contractor-qualifier-contractor-detail-xs-width-restrictor"
                                                  : "",
                                                "fd-view-header-contractor-qualifier-contractor-detail"
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.curUserContractorName
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "0", sm: "8" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: { justify: "end" }
                        },
                        [
                          _c("v-combobox", {
                            class: [
                              _vm.tagsSelectedForFiltering.length > 0
                                ? "fd-combobox-control-item-selected"
                                : "fd-combobox-control-item-not-selected",
                              "pr-3",
                              "fd-limit-combobox-size-to-content"
                            ],
                            attrs: {
                              items: _vm.tagsInUse,
                              label: _vm.$t("common.filter-by-tags"),
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tagsSelectedForFiltering,
                              callback: function($$v) {
                                _vm.tagsSelectedForFiltering = $$v
                              },
                              expression: "tagsSelectedForFiltering"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            [
              _c(
                "v-row",
                [
                  _vm.canViewContractorFilter
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", sm: "6", md: "6" }
                        },
                        [
                          _c("v-select", {
                            staticClass: "pt-0 mt-0",
                            attrs: {
                              label: _vm.$t(
                                "scheduler.filter-by-contractor-label"
                              ),
                              disabled: _vm.processing,
                              items: _vm.selectableContractors,
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: "",
                              dense: _vm.$vuetify.breakpoint.xsOnly
                            },
                            model: {
                              value: _vm.selectedContractors,
                              callback: function($$v) {
                                _vm.selectedContractors = $$v
                              },
                              expression: "selectedContractors"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.canViewDisciplinesFilter
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", sm: "6", md: "6" }
                        },
                        [
                          _c("v-select", {
                            staticClass: "pt-0 mt-0",
                            attrs: {
                              label: _vm.$t(
                                "scheduler.filter-by-discipline-label"
                              ),
                              disabled: _vm.processing,
                              items: _vm.selectableDisciplines,
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: "",
                              dense: _vm.$vuetify.breakpoint.xsOnly
                            },
                            model: {
                              value: _vm.selectedDisciplines,
                              callback: function($$v) {
                                _vm.selectedDisciplines = $$v
                              },
                              expression: "selectedDisciplines"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6", md: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scheduler.filter-by-area-label"),
                          disabled: _vm.processing,
                          items: _vm.selectableAreas,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: "",
                          dense: _vm.$vuetify.breakpoint.xsOnly
                        },
                        model: {
                          value: _vm.selectedAreas,
                          callback: function($$v) {
                            _vm.selectedAreas = $$v
                          },
                          expression: "selectedAreas"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6", md: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scheduler.filter-by-sub-area-label"),
                          disabled:
                            !_vm.selectableSubAreas ||
                            _vm.selectableSubAreas.length == 0 ||
                            _vm.processing,
                          items: _vm.selectableSubAreas,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: "",
                          dense: _vm.$vuetify.breakpoint.xsOnly
                        },
                        model: {
                          value: _vm.selectedSubAreas,
                          callback: function($$v) {
                            _vm.selectedSubAreas = $$v
                          },
                          expression: "selectedSubAreas"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6", md: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t(
                            "scheduler.filter-by-general-foreman-label"
                          ),
                          disabled: _vm.processing,
                          items: _vm.selectableGeneralForemen,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: "",
                          dense: _vm.$vuetify.breakpoint.xsOnly
                        },
                        model: {
                          value: _vm.selectedGeneralForemanIDs,
                          callback: function($$v) {
                            _vm.selectedGeneralForemanIDs = $$v
                          },
                          expression: "selectedGeneralForemanIDs"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6", md: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scheduler.filter-by-foreman-label"),
                          disabled: _vm.processing,
                          items: _vm.selectableForemen,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: "",
                          dense: _vm.$vuetify.breakpoint.xsOnly
                        },
                        model: {
                          value: _vm.selectedForemanIDs,
                          callback: function($$v) {
                            _vm.selectedForemanIDs = $$v
                          },
                          expression: "selectedForemanIDs"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { class: ["pt-0", "pb-5"], attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: { align: "center", justify: "center" }
                        },
                        [
                          _c(
                            "v-menu",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _vm.$vuetify.breakpoint.lgAndUp
                                        ? _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "mr-3",
                                                staticStyle: {
                                                  "min-width": "110px"
                                                },
                                                attrs: {
                                                  small: "",
                                                  rounded: "",
                                                  outlined: "",
                                                  disabled: _vm.processing,
                                                  color: "#7a7a7a"
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    left:
                                                      _vm.$vuetify.breakpoint
                                                        .smAndUp
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-printer\n                  "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.$vuetify.breakpoint.smAndUp
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t("common.print")
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "margin-left":
                                                      "2px !important"
                                                  },
                                                  attrs: { right: "" }
                                                },
                                                [_vm._v("mdi-menu-down")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.downloadAndPrintWorkOrderAdminReport(
                                            "pdf"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "work-order-admin.printing.pdf"
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.downloadAndPrintWorkOrderAdminReport(
                                            "xls"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "work-order-admin.printing.excel"
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-5",
                              attrs: {
                                "data-cy": "reload",
                                small: "",
                                rounded: "",
                                outlined: "",
                                color: "#7a7a7a",
                                disabled: _vm.processing
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              !_vm.$vuetify.breakpoint.sm
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        left: _vm.$vuetify.breakpoint.smAndUp
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                mdi-reload\n              "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            class: [
                              _vm.$vuetify.breakpoint.sm
                                ? "fd-restrict-clearable-position"
                                : "",
                              "pt-0",
                              "mt-0",
                              "fd-table-search-input"
                            ],
                            attrs: {
                              "data-cy": "tablesearch",
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:internalNumber.hide-when-medium",
                value: _vm.$t("work-order-admin.list.work-order-column-label"),
                expression:
                  "\n        $t('work-order-admin.list.work-order-column-label')\n      ",
                arg: "internalNumber",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:scaffoldNumber",
                value: _vm.$t(
                  "work-order-admin.list.scaffold-number-column-label"
                ),
                expression:
                  "$t('work-order-admin.list.scaffold-number-column-label')",
                arg: "scaffoldNumber"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:requestTypeName.hide-when-medium",
                value: _vm.$t(
                  "work-order-admin.list.request-type-column-label"
                ),
                expression:
                  "\n        $t('work-order-admin.list.request-type-column-label')\n      ",
                arg: "requestTypeName",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:workOrderStatusName.hide-when-medium",
                value: _vm.$t(
                  "work-order-admin.list.work-order-status-column-label"
                ),
                expression:
                  "\n        $t('work-order-admin.list.work-order-status-column-label')\n      ",
                arg: "workOrderStatusName",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:coordinatorName.hide-when-extra-small",
                value: _vm.$t("work-order-admin.list.coordinator-column-label"),
                expression:
                  "\n        $t('work-order-admin.list.coordinator-column-label')\n      ",
                arg: "coordinatorName",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:generalForemanName.hide-when-extra-small",
                value: _vm.$t(
                  "work-order-admin.list.general-foreman-column-label"
                ),
                expression:
                  "\n        $t('work-order-admin.list.general-foreman-column-label')\n      ",
                arg: "generalForemanName",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:foremanName",
                value: _vm.$t("work-order-admin.list.foreman-column-label"),
                expression: "$t('work-order-admin.list.foreman-column-label')",
                arg: "foremanName"
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:assignedContractorName.hide-when-header-text-empty.hide-when-small",
                value: _vm.$t("work-order-admin.list.contractor-column-label"),
                expression:
                  "\n        $t('work-order-admin.list.contractor-column-label')\n      ",
                arg: "assignedContractorName",
                modifiers: {
                  "hide-when-header-text-empty": true,
                  "hide-when-small": true
                }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:formattedCompletedDate",
                value: _vm.$t(
                  "work-order-admin.list.completed-date-column-label"
                ),
                expression:
                  "$t('work-order-admin.list.completed-date-column-label')",
                arg: "formattedCompletedDate"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:countSheetStatus",
                value: _vm.$t(
                  "work-order-admin.list.count-sheet-status-column-label"
                ),
                expression:
                  "$t('work-order-admin.list.count-sheet-status-column-label')",
                arg: "countSheetStatus"
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                arg: "actions",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "class_fd-actions-cell": true
                }
              },
              {
                name: "fd-row-navigate",
                rawName: "v-fd-row-navigate.show-when-extra-small",
                value: item =>
                  _vm.$router.push(`${_vm.$route.path}/${item.id}`),
                expression: "item => $router.push(`${$route.path}/${item.id}`)",
                modifiers: { "show-when-extra-small": true }
              }
            ],
            class: ["fd-expanding-table", "fd-work-order-admin-list"],
            attrs: {
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "mobile-breakpoint": "0",
              "data-cy": "list",
              items: _vm.filteredWorkOrders,
              "items-per-page": _vm.itemsPerPage,
              "footer-props": {
                "items-per-page-options": _vm.itemsPerPageOptions
              },
              "show-expand": ""
            },
            scopedSlots: _vm._u([
              {
                key: "item.fd-nav",
                fn: function() {
                  return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
                },
                proxy: true
              },
              {
                key: "item.actions",
                fn: function({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "table-2-actions-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              link: "",
                                              disabled: _vm.processing
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.showWorkOrderCountSheetDialog(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-view-list\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.material")))
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              link: "",
                                              disabled: _vm.processing,
                                              to: `${_vm.$route.path}/${item.id}`
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-pencil\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "expanded-item",
                fn: function({ item }) {
                  return [
                    _c(
                      "td",
                      { attrs: { colspan: 12 } },
                      [
                        _c("fd-work-order-details", {
                          staticClass:
                            "fd-expanding-table-single-details-section",
                          attrs: {
                            workOrder: item,
                            walkdown: item.walkdown,
                            "show-edit-controls": false,
                            "edit-controls-disabled": true
                          },
                          on: {
                            "change:isurgent": v =>
                              _vm.saveField(item, "isUrgent", v)
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }