var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-textarea",
    _vm._b(
      {
        class: { "fd-readonly": _vm.$attrs.readonly },
        attrs: {
          outlined: "",
          "data-cy": !!_vm.cy ? _vm.cy : _vm.dataCy,
          value: _vm.value,
          disabled: _vm.$attrs.disabled || _vm.disabled,
          readonly: _vm.$attrs.readonly
        },
        on: { input: v => _vm.$emit("input", v) }
      },
      "v-textarea",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }