import FDVue from "../";

export default FDVue.extend({
  name: "fd-select",

  props: {
    value: { type: [String, Number, Array], default: undefined },
    cy: { type: String, default: "fd-select" },
    displayCount: { type: Boolean, default: false },
    itemCount: { type: String, default: "count" }
  },

  data: function() {
    return {};
  },

  methods: {},

  computed: {},

  watch: {}
});
