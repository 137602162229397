import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { mapActions, mapMutations } from "vuex";
import {
  MaterialOrderWithDetails,
  materialOrderService,
  CountSheetGroupWithParts,
  Part,
  MaterialOrderDirection,
  MaterialOrderPartDetails,
  Supplier,
  supplierService,
  MaterialOrderStatus,
  TransferWithDetails,
  reportService,
  MaterialOrderForReport,
  MaterialOrderPartForReport
} from "../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import {
  CountSheetGroupPartFromMaterialOrderPart,
  CountSheetGroupWithSortedParts,
  PartWithCounts,
  SummarizeModifiedPartsInGroups
} from "../dataMixins/countSheet";
import { SortCountSheetGroups, SortParts } from "../dataMixins/countSheetGroupSorting";
import userAccess from "@fd/current/client/dataMixins/userAccess";
import rules from "@fd/lib/vue/rules";
import { showMaterialRequestTransferNewDialog } from "./components/dialogs/SP.MaterialRequestTransferNewDialog.vue";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";
import tabbedView, { Tab } from "../../../lib/vue/mixins/tabbedView";
import { FDColumnDirective, FDRowNavigateDirective } from "../../../lib/vue/utility/dataTable";
import downloadBlob from "../../../lib/client-util/downloadBlob";
import printBlob from "../../../lib/client-util/printBlob";

type FormattedMaterialOrderWithDetails = MaterialOrderWithDetails & {
  formattedOrderDate: string;
  formattedExpectedDate: string;
};
export default FDVue.extend({
  name: "fd-material-order-existing",

  mixins: [serviceErrorHandling, userAccess, rules, tabbedView],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      slidein: false,
      openPanels: [0] as number[],

      firstTabKey: `0`,
      detailsTab: {
        tabname: this.$t("material-orders.existing.tabs.details"),
        key: "0",
        visible: true
      } as Tab,
      transfersTab: {
        tabname: this.$t("material-orders.existing.tabs.transfers"),
        key: "1",
        visible: true
      } as Tab,

      // Draft -> Submitted -> Declined w/ Decline Reason -> Submitted -> Approved -> Partially Fulfilled -> Fulfilled
      // -> Cancelled/Abandoned w/ Cancel Reason
      printing: false,
      saving: false,
      submitting: false,
      declining: false,
      approving: false,
      cancelling: false,

      unlocked: false,

      countSheetGroups: [] as CountSheetGroupWithSortedParts[],
      materialOrder: {} as FormattedMaterialOrderWithDetails,
      transfers: [] as TransferWithDetails[],
      /*** TO/FROM SUPPLIER ***/
      allSuppliers: [] as Supplier[]
    };
  },

  computed: {
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.transfersTab] as Tab[];
    },
    directionIsMaterialRequest(): boolean {
      return this.materialOrder.direction == MaterialOrderDirection.MaterialRequest;
    },
    directionIsMaterialReturn(): boolean {
      return this.materialOrder.direction == MaterialOrderDirection.MaterialReturn;
    },
    orderIsDraft(): boolean {
      return this.materialOrder.orderStatusID == MaterialOrderStatus.New;
    },
    orderIsSubmitted(): boolean {
      return this.materialOrder.orderStatusID == MaterialOrderStatus.Submitted;
    },
    orderIsApproved(): boolean {
      return this.materialOrder.orderStatusID == MaterialOrderStatus.Approved;
    },
    orderIsDeclined(): boolean {
      return this.materialOrder.orderStatusID == MaterialOrderStatus.Declined;
    },
    orderHasDeclineReason(): boolean {
      return !!this.materialOrder?.reviewDeclineReason?.length;
    },
    orderIsCancelled(): boolean {
      return this.materialOrder.orderStatusID == MaterialOrderStatus.Cancelled;
    },
    orderIsAbandoned(): boolean {
      return this.materialOrder.orderStatusID == MaterialOrderStatus.Abandoned;
    },
    orderHasCancelReason(): boolean {
      return !!this.materialOrder?.cancelReason?.length;
    },
    canFulfillOrder(): boolean {
      return (
        !!this.materialOrder &&
        (this.materialOrder.orderStatusID == MaterialOrderStatus.Approved ||
          this.materialOrder.orderStatusID == MaterialOrderStatus.PartiallyFulfilled)
      );
    },
    orderIsPartiallyFilled(): boolean {
      return this.materialOrder.orderStatusID == MaterialOrderStatus.PartiallyFulfilled;
    },
    orderIsFilled(): boolean {
      return this.materialOrder.orderStatusID == MaterialOrderStatus.Fulfilled;
    },
    canEdit(): boolean {
      return (
        !!this.materialOrder &&
        (this.materialOrder.orderStatusID == MaterialOrderStatus.New ||
          this.materialOrder.orderStatusID == MaterialOrderStatus.Declined)
      );
    },
    canSubmit(): boolean {
      return (
        this.materialOrder?.orderStatusID == MaterialOrderStatus.New ||
        this.materialOrder?.orderStatusID == MaterialOrderStatus.Declined
      );
    },
    canApprove(): boolean {
      return this.materialOrder?.orderStatusID == MaterialOrderStatus.Submitted;
    },
    canDecline(): boolean {
      return this.materialOrder?.orderStatusID == MaterialOrderStatus.Submitted;
    },
    canAbandonOrder(): boolean {
      return (
        !!this.materialOrder &&
        this.materialOrder.orderStatusID == MaterialOrderStatus.PartiallyFulfilled
      );
    },
    canCancelOrder(): boolean {
      return (
        !!this.materialOrder &&
        (this.materialOrder.orderStatusID == MaterialOrderStatus.New ||
          this.materialOrder.orderStatusID == MaterialOrderStatus.Submitted ||
          this.materialOrder.orderStatusID == MaterialOrderStatus.Declined ||
          this.materialOrder.orderStatusID == MaterialOrderStatus.Approved)
      );
    },
    visibleCountSheetGroups(): CountSheetGroupWithSortedParts[] {
      if (this.canEdit) return this.countSheetGroups;

      return this.countSheetGroups
        .map(x => {
          return {
            ...x,
            sortedParts: x.sortedParts.filter(x => !!x.count && x.count > 0)
          };
        })
        .filter(x => x.sortedParts.length > 0);
    },
    partsForSummary(): PartWithCounts[] {
      return SummarizeModifiedPartsInGroups(this.countSheetGroups);
    }
  },

  watch: {
    materialOrder() {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/materialorders") {
        this.notifyNewBreadcrumb({
          text: this.$t("material-orders.list.title"),
          to: "/materialorders",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      let directionText = this.$t("material-orders.direction.materialrequest");
      if (this.directionIsMaterialReturn) {
        directionText = this.$t("material-orders.direction.materialreturn");
      }
      let numberString = `00000${this.materialOrder.number}`.slice(-5);
      let summary = `${directionText} #${numberString}`;
      this.notifyNewBreadcrumb({
        text: summary,
        to: `/materialorders/${this.$route.params.id}`
      });
    }
  },

  methods: {
    buildMaterialOrderForReport() {
      let supplierID: string | null =
        this.materialOrder.toSupplierID ?? this.materialOrder.fromSupplierID ?? null;

      let totalWeight = 0;
      let totalQuantity = 0;
      let parts: Array<MaterialOrderPartForReport> = this.partsForSummary.map(part => {
        let weight = part.weight ?? 0;
        let quantity = part.count ?? 0;
        totalWeight += weight;
        totalQuantity += quantity;
        return {
          name: part.name,
          description: part.description,
          weight: `${weight}`,
          quantity: `${quantity}`
        } as MaterialOrderPartForReport;
      });
      let attention: string = this.materialOrder.contactPerson ?? "";
      let specialInstructions: string = this.materialOrder.instructions ?? "";
      let number: string = this.materialOrder.number?.toString() ?? "";
      let date: string = DateUtil.stripTimeFromLocalizedDateTime(this.materialOrder.orderDate);
      let requiredDate: string = DateUtil.stripTimeFromLocalizedDateTime(
        this.materialOrder.expectedDate
      );
      let purchaseOrder: string = this.materialOrder.referenceNumber ?? "";
      let totalPieces: string = `${totalQuantity}`;
      let order: MaterialOrderForReport = {
        supplierID: supplierID,
        parts: parts,
        attention: attention,
        specialInstructions: specialInstructions,
        number: number,
        date: date,
        requiredDate: requiredDate,
        purchaseOrder,
        totalPieces,
        totalWeight: `${totalWeight}`
      };
      return order;
    },
    async downloadAndPrintReport(reportType: string = "pdf") {
      if (this.directionIsMaterialRequest)
        await this.downloadAndPrintMaterialRequestReport(reportType);
      else await this.downloadAndPrintMaterialReturnReport(reportType);
    },
    async downloadAndPrintMaterialRequestReport(reportType: string) {
      this.inlineMessage.message = null;

      this.processing = true;
      this.printing = true;
      try {
        var order = this.buildMaterialOrderForReport();
        var blob = await reportService.getMaterialRequestPrintoutWithData(
          order,
          reportType,
          DateUtil.localizedDateTimeString(new Date())
        );
        if (reportType == "xls") {
          downloadBlob(blob, "material-request-printout.xlsx");
        } else {
          printBlob(blob, "material-request-printout.pdf", "application/pdf");
        }
      } catch (e) {
        this.handleError(e as Error);
      } finally {
        this.processing = false;
        this.printing = false;
      }
    },
    async downloadAndPrintMaterialReturnReport(reportType: string) {
      this.processing = true;
      try {
        var order = this.buildMaterialOrderForReport();
        var blob = await reportService.getMaterialReturnPrintoutWithData(
          order,
          reportType,
          DateUtil.localizedDateTimeString(new Date())
        );
        if (reportType == "xls") {
          downloadBlob(blob, "material-return-printout.xlsx");
        } else {
          printBlob(blob, "material-return-printout.pdf", "application/pdf");
        }
      } catch (e) {
        this.handleError(e as Error);
      } finally {
        this.processing = false;
      }
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB"
    }),
    ...mapActions({
      loadCountSheetGroups: "LOAD_COUNT_SHEET_GROUPS"
    }),
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },
    formatDate(date: Date | string) {
      console.log(`formatDate: ${date}`);
      return DateUtil.stripTimeFromLocalizedDateTime(date);
    },
    async saveOrder(): Promise<boolean> {
      let parts = this.partsForSummary
        .filter(x => !!x.count && x.count > 0)
        .map(
          x =>
            ({
              partID: x.id,
              count: +x.count!,
              currentAssignedCount: +x.assigned
            } as MaterialOrderPartDetails)
        );

      this.partsForSummary.forEach(x => {
        let part = this.materialOrder.parts?.find(p => p.partID == x.id);
        if (!part) return;
        part.count = +x.count! ?? 0;
      });

      await materialOrderService.updateMaterialOrder(
        this.materialOrder.id!,
        this.materialOrder.orderDate!,
        this.materialOrder.expectedDate!,
        this.materialOrder.referenceNumber,
        this.materialOrder.contactPerson,
        this.materialOrder.instructions,
        parts
      );

      return true;
    },
    async save(closeOnComplete: boolean) {
      if (!this.canEdit && !this.unlocked) return;

      // First reset the inline message if there are any.
      this.inlineMessage.message = null;

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (await this.saveOrder()) {
          let directionText = this.$t("material-orders.direction.materialrequest");
          if (this.directionIsMaterialReturn) {
            directionText = this.$t("material-orders.direction.materialreturn");
          }
          var snackbarPayload = {
            text: this.$t("material-orders.existing.save-success", [
              directionText,
              `00000${this.materialOrder.number}`.slice(-5)
            ]),
            type: "success",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
          if (closeOnComplete) {
            this.$router.push("/materialorders");
          }
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.back();
    },

    async loadMaterialOrderDetails() {
      let materialOrder = await materialOrderService.getByID(this.$route.params.id);
      this.materialOrder = {
        ...materialOrder,
        formattedOrderDate: DateUtil.stripTimeFromLocalizedDateTime(materialOrder?.orderDate),
        formattedExpectedDate: DateUtil.stripTimeFromLocalizedDateTime(materialOrder?.expectedDate)
      } as FormattedMaterialOrderWithDetails;
      if (!this.materialOrder) return;
      this.transfers = await materialOrderService.getTransfersForMaterialOrder(
        this.materialOrder.id!
      );
    },

    async loadScreenData() {
      await this.loadCountSheetGroups({
        forcedArchivedState: false,
        archivedFromDate: null,
        archivedToDate: null
      });

      this.allSuppliers = (await supplierService.getAll(false, null, null)).sort((a, b) => {
        let nameA = (a.name ?? "").toLowerCase();
        let nameB = (b.name ?? "").toLowerCase();
        if (nameA < nameB) return -1;
        else if (nameA > nameB) return 1;
        else return 0;
      });

      await this.loadMaterialOrderDetails();

      let countSheetGroups = SortCountSheetGroups(
        (this.$store.state.countSheetGroups.fullList as CountSheetGroupWithParts[])
          .filter(group => !!group.parts?.length)
          .map(
            group =>
              ({
                ...group,
                sortedParts: SortParts(
                  group.parts?.map(part =>
                    CountSheetGroupPartFromMaterialOrderPart(part, this.materialOrder.parts)
                  )
                )
              } as CountSheetGroupWithSortedParts)
          )
      );

      let ungroupedPartsWithDetails = this.materialOrder.parts?.filter(x => !x.countSheetGroupID);
      if (!!ungroupedPartsWithDetails?.length) {
        let ungroupedParts = ungroupedPartsWithDetails.map(
          x =>
            ({
              id: x.partID,
              name: x.name,
              description: x.description,
              publicID: x.publicID
            } as Part)
        ) as Part[];
        let ungroupedGroup = {
          name: `${this.$t("common.other")}`,
          order: 999,
          parts: ungroupedParts
        } as CountSheetGroupWithParts;

        let ungroupedGroupWithSortedParts = {
          ...ungroupedGroup,
          sortedParts: SortParts(
            ungroupedGroup.parts?.map(part =>
              CountSheetGroupPartFromMaterialOrderPart(part, this.materialOrder.parts)
            )
          )
        } as CountSheetGroupWithSortedParts;

        countSheetGroups.push(ungroupedGroupWithSortedParts);
      }

      countSheetGroups.forEach(x => (x.parts = SortParts(x.parts)));

      this.countSheetGroups = countSheetGroups;
    },

    async fulfillOrder() {
      if (!(await showMaterialRequestTransferNewDialog(this.materialOrder))) return;

      this.processing = true;
      try {
        await this.loadScreenData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async submitOrder() {
      this.processing = true;
      this.submitting = true;
      try {
        if (!(await this.saveOrder())) {
          this.submitting = false;
          this.processing = false;
          return;
        }

        await materialOrderService.submitMaterialOrder(this.materialOrder.id!);

        let numberString = `00000${this.materialOrder.number}`.slice(-5);
        var snackbarPayload = {
          text: this.$t("material-orders.existing.submit-success", [numberString]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.$router.push(this.$store.getters.backBreadcrumb?.to || "/materialorders");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.submitting = false;
        this.processing = false;
      }
    },
    async declineOrder() {
      this.processing = true;
      this.declining = true;
      try {
        // get reason
        var title = this.$t("material-orders.existing.decline-reason");
        var reason = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);

        // If details is undefined the dialog was cancelled
        if (!reason) {
          // Change the value to something else and then back to its current to force a rebind
          this.declining = false;
          this.processing = false;
          return false;
        }

        await materialOrderService.declineMaterialOrder(this.materialOrder.id!, reason);

        await this.loadMaterialOrderDetails();

        let numberString = `00000${this.materialOrder.number}`.slice(-5);
        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.decline-success", [
            numberString
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.declining = false;
        this.processing = false;
      }
    },
    async approveOrder() {
      this.processing = true;
      this.approving = true;
      try {
        await materialOrderService.approveMaterialOrder(this.materialOrder.id!);

        await this.loadMaterialOrderDetails();

        let numberString = `00000${this.materialOrder.number}`.slice(-5);
        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.approve-success", [
            numberString
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.approving = false;
        this.processing = false;
      }
    },

    async cancelOrder() {
      this.processing = true;
      this.cancelling = true;
      try {
        let reason = await showAdditionalDetailsDialog(
          this.$t("material-orders.existing.cancel-reason"),
          this.$t("common.reason"),
          [this.rules.required]
        );

        // If details is undefined the dialog was cancelled
        if (reason == undefined) {
          // Change the value to something else and then back to its current to force a rebind
          this.cancelling = false;
          this.processing = false;
          return;
        }
        await materialOrderService.cancelMaterialOrder(this.materialOrder.id!, reason);
        await this.loadMaterialOrderDetails();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.cancelling = false;
      }
    },
    async abandonOrder() {
      this.processing = true;
      this.cancelling = true;
      try {
        let reason = await showAdditionalDetailsDialog(
          this.$t("material-orders.existing.abandon-reason"),
          this.$t("common.reason"),
          [this.rules.required]
        );

        // If details is undefined the dialog was cancelled
        if (reason == undefined) {
          // Change the value to something else and then back to its current to force a rebind
          this.cancelling = false;
          this.processing = false;
          return;
        }
        await materialOrderService.abandonMaterialOrder(this.materialOrder.id!, reason);
        await this.loadMaterialOrderDetails();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.cancelling = false;
      }
    },

    // *** SCREEN NAVIGATION ***
    partCountValueChanged(part: PartWithCounts) {
      let count = !!part.count ? +part.count : 0;
      part.overridden = !this.canEdit;
      // In this case the assigned means "fulfilled" so it comes out of the count
      part.total = count - part.assigned;
    },
    fieldRefForPart(fieldName: string, part: PartWithCounts) {
      let overrideRef = part.overridden == true ? "override" : "";

      let id = part.id!.replace("-", "").replace("-", "");
      return `${fieldName}${overrideRef}_${id}`;
    },
    focusFieldForVisibleItemAtIndex(fieldName: string, groupNumber: number, partIndex: number) {
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.order == groupNumber
      );
      if (!group) return;
      let sortedGroupParts = group.sortedParts;
      if (!sortedGroupParts.length) return;

      let groupPanelNumber = this.countSheetGroups.indexOf(group!) + 1;
      if (!this.openPanels.includes(groupPanelNumber)) {
        this.openPanels.push(groupPanelNumber);
        let self = this;

        this.$nextTick(() => {
          setTimeout(() => {
            self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, partIndex);
          }, 500);
        });
        return;
      }

      if (partIndex < 0) partIndex = 0;
      if (partIndex >= sortedGroupParts.length) partIndex = sortedGroupParts.length - 1;
      let item = sortedGroupParts[partIndex];

      let itemFieldRef = this.fieldRefForPart(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    selectPreviousField(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      let groupID = item.countSheetGroupID;
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.id == groupID
      );
      if (!group) return;

      let groupNumber = group?.order ?? 0;
      let sortedGroupParts = group?.sortedParts ?? [];
      // console.log(`  current group: ${group?.name}, parts: ${sortedGroupParts.length}`);
      if (!sortedGroupParts.length) return;

      let currentItemIndex = sortedGroupParts.indexOf(item);
      if (currentItemIndex <= 0) {
        if (groupNumber <= 1) return;
        groupNumber -= 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, 999);
        });
        e.preventDefault();
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, previousIndex);
      e.preventDefault();
    },
    selectNextField(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      // console.log(`selectNextField fieldName: ${fieldName}, part: ${item.publicID}`);
      let groupID = item.countSheetGroupID;
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.id == groupID
      );
      let groupNumber = group?.order ?? 0;
      let sortedGroupParts = group?.sortedParts ?? [];
      // console.log(`  current group: ${group?.name}, parts: ${sortedGroupParts.length}`);
      if (!sortedGroupParts.length) return;

      let currentItemIndex = sortedGroupParts.indexOf(item);
      if (currentItemIndex >= sortedGroupParts.length - 1) {
        groupNumber += 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, 0);
        });
        e.preventDefault();
        return;
      }
      let nextIndex = currentItemIndex + 1;
      // console.log(`  current: ${currentItemIndex}, next: ${nextIndex}`);
      this.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, nextIndex);
      e.preventDefault();
    },
    enterPressed(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      if (e.shiftKey) this.selectPreviousField(e, fieldName, item);
      else this.selectNextField(e, fieldName, item);
    }
  },

  created: async function() {
    this.setFilteringContext({
      context: "materialorder-existing",
      parentalContext: "materialorders",
      selectedTab: this.firstTabKey
    });

    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/materialorders") {
      this.notifyNewBreadcrumb({
        text: this.$t("material-orders.list.title"),
        to: "/materialorders",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }
    this.notifyNewBreadcrumb({
      text: "",
      to: `/materialorders/${this.$route.params.id}`
    });

    this.processing = true;

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    try {
      await this.loadScreenData();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

