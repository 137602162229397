import { paintCodeService } from "../../services/index";
import { createAutomaticCrudStoreModule } from "../automatic";

const storeModule = createAutomaticCrudStoreModule({
  crudService: paintCodeService,
  singularStoreName: "PAINT_CODE",
  pluralStoreName: "PAINT_CODES",
  localizationPrefix: "build-sheet-setup.tabs.paint.paint-codes",
  consumerRelatedIDProperty: {
    name: "paintCodeID",
    type: "string"
  }
});

export default storeModule;

