var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "", "data-cy": "isonewdialog" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c("fd-image-viewer", {
        attrs: { name: _vm.imageName },
        model: {
          value: _vm.imageSource,
          callback: function($$v) {
            _vm.imageSource = $$v
          },
          expression: "imageSource"
        }
      }),
      _vm._v(" "),
      _c("fd-image-editor", {
        attrs: {
          "complete-button-label-key": "common.done",
          name: _vm.imageName
        },
        on: { edit: _vm.handleEdit },
        model: {
          value: _vm.editImageSource,
          callback: function($$v) {
            _vm.editImageSource = $$v
          },
          expression: "editImageSource"
        }
      }),
      _vm._v(" "),
      _c(
        "v-card",
        { attrs: { "data-cy": "isonew" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("systems.test-packages.isos.new.dialog-title")
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-stepper",
            {
              attrs: { "non-linear": "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-stepper-header",
                {
                  staticClass: "fd-responsive-stepper",
                  attrs: { height: "60" }
                },
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.detailsStep.number,
                        step: _vm.detailsStep.number,
                        editable: "",
                        rules: [() => !_vm.detailsStep.error]
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.detailsStep.name) +
                          "\n          "
                      ),
                      _vm.detailsStep.error
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "systems.test-packages.isos.new.steps.details-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.attachmentsStep.number,
                        step: _vm.attachmentsStep.number,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.attachmentsStep.name) +
                          "\n          "
                      ),
                      _vm.attachmentsStep.error
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "systems.test-packages.isos.new.steps.attachments-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pl-0 pr-0 pb-0",
                      staticStyle: { "padding-top": "2px" },
                      attrs: { step: _vm.detailsStep.number }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "detailsform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                { staticClass: "px-2 pt-3" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          "data-cy": "systems",
                                          label: _vm.$t("isos.system-name"),
                                          items: _vm.systems,
                                          "item-text": "name",
                                          "item-value": "id",
                                          disabled:
                                            !_vm.chooseTestPackage ||
                                            _vm.processing,
                                          "hide-details": !_vm.chooseTestPackage,
                                          dense: !_vm.chooseTestPackage
                                        },
                                        model: {
                                          value: _vm.systemID,
                                          callback: function($$v) {
                                            _vm.systemID = $$v
                                          },
                                          expression: "systemID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          "data-cy": "testpackages",
                                          label: _vm.$t(
                                            "isos.test-package-name"
                                          ),
                                          items:
                                            _vm.testPackagesForSelectedSystem,
                                          "item-text": "name",
                                          "item-value": "id",
                                          disabled:
                                            !_vm.chooseTestPackage ||
                                            _vm.processing,
                                          "hide-details": !_vm.chooseTestPackage,
                                          dense: !_vm.chooseTestPackage
                                        },
                                        model: {
                                          value: _vm.iso.testPackageID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.iso,
                                              "testPackageID",
                                              $$v
                                            )
                                          },
                                          expression: "iso.testPackageID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.name"),
                                          disabled: _vm.processing,
                                          rules: _vm.isoRules.name
                                        },
                                        model: {
                                          value: _vm.iso.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.iso, "name", $$v)
                                          },
                                          expression: "iso.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.code"),
                                          disabled: _vm.processing,
                                          rules: _vm.isoRules.code
                                        },
                                        model: {
                                          value: _vm.iso.code,
                                          callback: function($$v) {
                                            _vm.$set(_vm.iso, "code", $$v)
                                          },
                                          expression: "iso.code"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "systems.test-packages.isos.estimated-work-hours"
                                          ),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.numeric],
                                          type: "number",
                                          "hide-spin-buttons": ""
                                        },
                                        model: {
                                          value: _vm.iso.estimatedWorkHours,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.iso,
                                              "estimatedWorkHours",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "iso.estimatedWorkHours"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("fd-rich-textarea", {
                                        attrs: {
                                          label: _vm.$t("common.description"),
                                          disabled: _vm.processing,
                                          rules: _vm.isoRules.description
                                        },
                                        model: {
                                          value: _vm.iso.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.iso,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "iso.description"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pt-0 pl-0 pr-0 pb-0",
                      attrs: { step: _vm.attachmentsStep.number }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "attachmentsform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-2",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        [
                                          _c("input", {
                                            ref: "addFileButton",
                                            staticClass: "d-none",
                                            attrs: {
                                              type: "file",
                                              onclick: "this.value=null;"
                                            },
                                            on: { change: _vm.fileInputChanged }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: { "offset-y": "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "add-fab-button-primary",
                                                            attrs: {
                                                              fab: "",
                                                              elevation: "5",
                                                              depressed:
                                                                _vm.processing,
                                                              disabled:
                                                                _vm.processing,
                                                              color: "new"
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("mdi-plus")
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c(
                                                "v-list",
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click:
                                                          _vm.openNewExternalLinkDialog
                                                      }
                                                    },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "attachments.external-link.menu-title"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: _vm.selectFile
                                                      }
                                                    },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "common.local-file"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function({ on }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "job.files-table-listing-tooltip"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: _vm.$t("common.search"),
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.tablesearchfiles,
                                              callback: function($$v) {
                                                _vm.tablesearchfiles = $$v
                                              },
                                              expression: "tablesearchfiles"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:icon.no-sort.hide-when-extra-small.class_fd-table-icon-cell",
                                            value: "",
                                            expression: "''",
                                            arg: "icon",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "class_fd-table-icon-cell": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:name",
                                            value: _vm.$t("common.name"),
                                            expression: "$t('common.name')",
                                            arg: "name"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                            arg: "actions",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "class_fd-actions-cell": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:action.no-sort.show-when-extra-small.class_fd-actions-cell-edit-only",
                                            arg: "action",
                                            modifiers: {
                                              "no-sort": true,
                                              "show-when-extra-small": true,
                                              "class_fd-actions-cell-edit-only": true
                                            }
                                          }
                                        ],
                                        class: _vm.$vuetify.breakpoint.xsOnly
                                          ? "fd-action-table"
                                          : "fd-actions-table",
                                        attrs: {
                                          items: _vm.attachments,
                                          search: _vm.tablesearchfiles,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "sort-by": ["name"],
                                          "item-class": _vm.fileRowClassName,
                                          "mobile-breakpoint": "0"
                                        },
                                        on: {
                                          "dblclick:row":
                                            _vm.attachmentRowClicked
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.icon",
                                            fn: function({ item }) {
                                              return [
                                                item.type == "link"
                                                  ? _c("v-icon", [
                                                      _vm._v("fa-link")
                                                    ])
                                                  : item.type == "file" &&
                                                    item.isPhoto
                                                  ? _c("v-icon", [
                                                      _vm._v("fa-camera")
                                                    ])
                                                  : _c("v-icon", [
                                                      _vm._v("fa-file")
                                                    ])
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.action",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.$vuetify.breakpoint
                                                          .lgAndUp ||
                                                        _vm.processing,
                                                      left: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      disabled:
                                                                        !item.canOpenInNew ||
                                                                        _vm.processing
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.openAttachment(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              mdi-open-in-new\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common.open-in-new-tab"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.actions",
                                            fn: function({ item }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      "table-4-actions-column-min-width"
                                                    ]
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          left: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            !item.canOpenInNew ||
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.openAttachment(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-open-in-new\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.open-in-new-tab"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          left: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            !item.isPreviewable ||
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.viewPhoto(
                                                                                item.file
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-eye\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.view"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          top: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          link:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.editAttachment(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-pencil\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.edit"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .lgAndUp ||
                                                            _vm.processing,
                                                          top: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function({
                                                                on
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteAttachment(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.onSubmit } },
                [
                  _vm.inlineMessage.message
                    ? _c(
                        "v-row",
                        { staticClass: "px-3" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "mt-0 mb-3",
                                  attrs: { type: _vm.inlineMessage.type }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.inlineMessage.message) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "save",
                            outlined: _vm.step != _vm.lastStep,
                            color: "primary",
                            loading: _vm.processing,
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.saveDialog }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.save-and-close")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 mr-3 d-none d-md-flex fd-button-context-separator-hide-when-mobile"
                        },
                        [_vm._v("\n            |\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancelDialog }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "continue",
                            disabled: _vm.step == _vm.lastStep,
                            color: "primary",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: {
                            click: function($event) {
                              _vm.step = Number(_vm.step) + 1
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.continue")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }