var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      !_vm.currentUserCanViewWorkOrders
        ? _c("v-alert", { staticClass: "mt-0 mb-3", attrs: { type: "info" } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("workorders.no-permission-to-view")) +
                "\n  "
            )
          ])
        : !_vm.currentUserCanImportWorkOrders
        ? _c("v-alert", { staticClass: "mt-0 mb-3", attrs: { type: "info" } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("workorders.no-permission-to-import")) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "v-toolbar",
            {
              class: [
                _vm.processing
                  ? "view-card-top-color-processing"
                  : "view-card-top-color"
              ],
              scopedSlots: _vm._u([
                {
                  key: "extension",
                  fn: function() {
                    return [
                      _c("fd-add-button", {
                        attrs: {
                          action: _vm.openNewDialog,
                          disabled: !_vm.currentUserCanImportWorkOrders
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        staticClass: "pt-0 mt-0 pt-md-3 mt-md-1",
                        attrs: {
                          "append-icon": "search",
                          label: _vm.$t("common.search"),
                          "single-line": "",
                          "hide-details": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.tablesearch,
                          callback: function($$v) {
                            _vm.tablesearch = $$v
                          },
                          expression: "tablesearch"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:workOrderID",
                value: _vm.$t("common.work-order"),
                expression: "$t('common.work-order')",
                arg: "workOrderID"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:location.hide-when-small",
                value: _vm.$t("common.location"),
                expression: "$t('common.location')",
                arg: "location",
                modifiers: { "hide-when-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:Description.hide-when-small",
                value: _vm.$t("common.description"),
                expression: "$t('common.description')",
                arg: "Description",
                modifiers: { "hide-when-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:RequestedDate.hide-when-medium",
                value: _vm.$t("common.requested-date"),
                expression: "$t('common.requested-date')",
                arg: "RequestedDate",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:StartDate.hide-when-medium",
                value: _vm.$t("common.startdate"),
                expression: "$t('common.startdate')",
                arg: "StartDate",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:RequiredDate.hide-when-medium",
                value: _vm.$t("common.required-date"),
                expression: "$t('common.required-date')",
                arg: "RequiredDate",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:ExistingRequest.hide-when-medium",
                value: _vm.$t("common.existing-request"),
                expression: "$t('common.existing-request')",
                arg: "ExistingRequest",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:enabled.hide-when-extra-small",
                arg: "enabled",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:actions.no-sort.hide-when-extra-small",
                arg: "actions",
                modifiers: { "no-sort": true, "hide-when-extra-small": true }
              },
              {
                name: "fd-row-navigate",
                rawName: "v-fd-row-navigate.show-when-extra-small",
                value: item =>
                  _vm.$router.push(`/${_vm.listRouteUrl}/${item.id}`),
                expression:
                  "\n        item => $router.push(`/${listRouteUrl}/${item.id}`)\n      ",
                modifiers: { "show-when-extra-small": true }
              }
            ],
            staticClass: "fd-actions-table fd-enabling-table",
            attrs: {
              items: _vm.displayedItems,
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "sort-by": ["activityID"],
              "mobile-breakpoint": "0"
            },
            scopedSlots: _vm._u([
              {
                key: "item.enabled",
                fn: function({ item }) {
                  return [
                    _c("v-simple-checkbox", {
                      attrs: { value: item.enabled, disabled: _vm.processing },
                      on: {
                        input: function($event) {
                          return _vm.flipEnabled(item)
                        }
                      }
                    })
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "table-actions-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              link: "",
                                              disabled: _vm.processing,
                                              to: `/${_vm.listRouteUrl}/${item.id}`
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-pencil\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              disabled: _vm.processing
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                disabled: _vm.processing
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteTableItem(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  mdi-delete\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.remove")))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }