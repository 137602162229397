export default {
  attachments: "fa-paperclip",
  "change-order": "fa-hammer-crash",
  "client-work-order": "fa-star-of-life",
  maintenance: "fas fa-toolbox",
  insulation: "fas fa-circle-dot",
  inspections: "fas fa-clipboard-check",
  imports: "fas fa-file-import",
  isos: "fa-cubes",
  jobs: "fa-screwdriver-wrench",
  paint: "fas fa-brush",
  scaffolds: "fas fa-layer-group",
  heattrace: "fas fa-temperature-list",
  refractory: "fas fa-trowel-bricks",
  fireproofing: "fas fa-fire",

  "service-order": "fa-diamond",
  rework: "fa-repeat",
  "scaffold-designs": "fas fa-compass-drafting",
  "scaffolds-map": "fa-map-location",
  "scaffold-location": "fa-location-dot",
  "inspection-time-sunrise": "mdi-weather-sunset-up",
  "inspection-time-day": "mdi-white-balance-sunny",
  "inspection-time-sunset": "mdi-weather-sunset-down",
  "inspection-time-night": "mdi-moon-waning-crescent",
  "contractor-norms": "fa-file-pen",
  "contractor-purchase-orders": "fa-file-invoice-dollar",
  classification: "fa-screen-users",
  "requesting-enrolment": "fa-user-plus",
  "requesting-access": "fa-user-shield",
  systems: "fa-object-intersect",
  "test-packages": "fa-sitemap"
} as { [key: string]: string };

