import FDVue from "@fd/lib/vue";
import {
  FDColumnDirective,
  FDRowNavigateDirective,
  FDTableSortableDirective,
  SortableEvent
} from "@fd/lib/vue/utility/dataTable";
import draggable from "vuedraggable";

export default FDVue.extend({
  name: "fd-draggable-table-test",

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    fdTableSortable: FDTableSortableDirective
  },

  components: {
    draggable
  },

  data: function() {
    return {
      theItems: [
        { name: "First", initialOrder: 1, order: 1 },
        { name: "Second", initialOrder: 2, order: 2 },
        { name: "Third", initialOrder: 3, order: 3 },
        { name: "Fourth", initialOrder: 4, order: 4 },
        { name: "Fifth", initialOrder: 5, order: 5 }
      ],
      dragItems: undefined as any[] | undefined
    };
  },

  computed: {
    items: {
      get(): any[] {
        return this.theItems;
      },
      set(val: any[]) {
        console.log(`items.set`);
        this.theItems = val;
      }
    },
    sortedItems(): any[] {
      return this.items.slice().sort((a, b) => a.order - b.order);
    },
    dragOptions(): any {
      return {
        animation: 200,
        handle: ".fd-drag-handle",
        ghostClass: "ghost"
      };
    }
  },

  methods: {
    dragEnded(e: SortableEvent) {
      console.log(`dragEnded e: ${e}`);
      let fromIndex = e.oldIndex;
      let toIndex = e.newIndex;
      if (fromIndex === undefined || toIndex === undefined) return;
      this.items.splice(toIndex, 0, ...this.items.splice(fromIndex, 1));
      this.items.forEach((item: any, index: number) => {
        let newOrder = index + 1;
        if (item.order != newOrder) {
          console.log(`  ${item.name}: ${item.order} -> ${newOrder}`);
          item.order = newOrder;
        }
      });
    },
    dragEnded2(e: SortableEvent) {
      console.log(`dragEnded2 e: ${e}`);
      let fromIndex = e.oldIndex;
      let toIndex = e.newIndex;
      if (fromIndex === undefined || toIndex === undefined) return;

      if (!this.dragItems) this.dragItems = this.items.slice();
      this.dragItems.splice(toIndex, 0, ...this.dragItems.splice(fromIndex, 1));
      this.dragItems.forEach((item: any, index: number) => {
        let newOrder = index + 1;
        let relatedItem = this.items.find(x => x.name == item.name);
        relatedItem.order = newOrder;
        // if (item.order != newOrder) {
        console.log(`  ${item.name}: ${item.order} -> ${newOrder}`);
        item.order = newOrder;
        // }
      });
    }
  }
});

