import { internalModifierService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: internalModifierService,
  singularStoreName: "INTERNAL_MODIFIER",
  pluralStoreName: "INTERNAL_MODIFIERS",
  localizationPrefix: "modifiers.internal",
  consumerRelatedIDProperty: {
    name: "internalModifierID",
    type: "string"
  }
});

export default storeModule;

