import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { System, TestPackage } from "../../../services";
import { SortItemsWithName } from "../../../utils/person";

type TestPackageNewDialogResult = boolean;
const TestPackageNewDialog = FDVue.extend({
  name: "sp-test-package-new-dialog",

  mixins: [dialogSupport, rules],

  data: function() {
    return {
      chooseSystem: false,
      saving: false,
      testPackage: {
        systemID: "",
        name: "",
        description: "",
        code: ""
      } as TestPackage
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return ["open", "setupData"];
    },
    testPackageRules() {
      return {
        name: [this.rules.required],
        code: [],
        description: []
      };
    },
    systems(): System[] {
      return SortItemsWithName(this.$store.state.systems.fullList as System[]);
    }
  },

  methods: {
    ...mapActions({
      loadSystems: "LOAD_SYSTEMS"
    }),
    async setupData() {
      this.processing = true;
      try {
        var calls = [];
        if (!this.systems.length) calls.push(this.loadSystems());
        await Promise.all(calls);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }
    },
    async open(systemID?: string | null | undefined) {
      if (!!systemID) this.testPackage.systemID = systemID;
      else this.chooseSystem = true;

      this.setupData();

      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.saving = true;
      this.processing = true;
      try {
        await this.addTestPackage({
          ...this.testPackage
        });
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    ...mapActions({
      addTestPackage: "ADD_TEST_PACKAGE"
    })
  }
});

export default TestPackageNewDialog;

export async function showTestPackageNewWithSystemDialog(
  systemID: string
): Promise<TestPackageNewDialogResult> {
  let dialog = createDialog(TestPackageNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(systemID);
}
export async function showTestPackageNewDialog(): Promise<TestPackageNewDialogResult> {
  let dialog = createDialog(TestPackageNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open();
}

