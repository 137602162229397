var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-container", { attrs: { fluid: "" } }, [
    _c("div", {
      ref: "mapContainer",
      staticStyle: { height: "calc(100vh - 380px)" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }