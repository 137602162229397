import FDVue from "@fd/lib/vue";
import { localizedDateTimeString } from "@fd/lib/client-util/datetime";

export default FDVue.extend({
  components: {
    "fd-work-order-detail-value": () => import("./WorkOrderDetailValue.vue")
  },
  props: {
    label: { type: [String] },

    value: { type: [Date, String] },
    differentialValue: { type: [Date, String], default: null },
    valueStyle: { type: String, default: "" },
    valueClass: { type: [String, Array], default: () => [] },

    loading: { type: Boolean, default: false },

    labelCols: { type: [String, Number], default: 6 },
    valueCols: { type: [String, Number], default: 6 },
    labelSmCols: { type: [String, Number], default: 6 },
    valueSmCols: { type: [String, Number], default: 6 },

    hideNoValuePlaceholder: { type: Boolean, default: false },
    noValueKey: { type: String, default: "common.not-available" },
    noValueStyle: { type: String, default: "" },
    noValueClass: { type: [String, Array], default: () => [] }
  },
  computed: {
    formattedValue(): string | null | undefined {
      if (this.value) {
        return localizedDateTimeString(this.value);
      } else {
        return this.value;
      }
    },
    formattedDifferentialValue(): string | undefined {
      // The compiler doesn't seem to detect the right data types here?
      var value = (this.value as unknown) as Date | null | undefined;
      var differentialValue = (this.differentialValue as unknown) as Date | null | undefined;

      if (value) {
        if (differentialValue) {
          var difference = differentialValue.getTime() - value.getTime();
          if (difference) {
            let days = Math.round(Math.abs(difference) / 86400000);
            let differenceDisplay =
              difference > 0
                ? this.$t("common.days-later-label", [days])
                : this.$t("common.days-earlier-label", [days]);
            return ` (${differenceDisplay})`;
          }
        }
      }
      return undefined;
    }
  }
});
