import { render, staticRenderFns } from "./DisciplineNewDialog.vue?vue&type=template&id=7c555c24"
import script from "./DisciplineNewDialog.vue.ts?vue&type=script&lang=ts&external"
export * from "./DisciplineNewDialog.vue.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports