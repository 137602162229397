import FDVue from "@fd/lib/vue";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";
import {
  EstimateComponentData,
  PartGenerationType,
  ScaffoldSubType,
  ScaffoldType
} from "../../../services";

type EstimateLike = {
  id: string | undefined;
  hasGeneratedExcelFile: boolean;
  creatorName: string;
  components: Array<EstimateComponentData> | undefined;
  notes: string | undefined;
  estimatedTotalErectMinutes: number | null | undefined;
  estimatedTotalDismantleMinutes: number | null | undefined;
  estimatedTotalModifyMinutes: number | null | undefined;
  estimatedTotalMobilizationMinutes: number | null | undefined;
  estimatedTotalDemobilizationMinutes: number | null | undefined;
  estimatedTotalHoardingMinutes: number | null | undefined;
  estimatedTotalTime: number | null | undefined;
  estimatedErectMPP: number | null | undefined;
  estimatedDismantleMPP: number | null | undefined;
  estimatedTotalWeight: number | null | undefined;
  estimatedTotalPartCount: number | null | undefined;
  isEstimateGenerationSuccessful: boolean | undefined;
  created: Date | undefined;
  createdBy: string | null | undefined;
};

export default FDVue.extend({
  name: "sp-estimates-list",
  props: {
    estimates: {},
    loading: { type: Boolean, default: false },
    tablesearchestimates: { type: String },
    summaryPanelTimeUnitDivider: { type: Number, default: 1 }
  },
  directives: {
    fdColumn: FDColumnDirective
  },
  components: {
    "sp-work-order-estimate-summary-table": () => import("./SP.EstimateSummaryTable.vue")
  },
  data: function() {
    return {};
  },
  computed: {},
  methods: {
    downloadEstimateExcelFile(id: string | undefined) {
      this.$emit("download", id);
    },
    canDownloadEstimateExcelFile(estimate: EstimateLike | null | undefined) {
      if (!estimate?.id?.length) return false;
      return estimate.hasGeneratedExcelFile ?? false;
    },
    getTimeDisplay(
      minutes: string | number | undefined | null,
      digits: number = 2,
      allowZero: boolean = false
    ) {
      if (minutes == undefined || minutes == null) return undefined;
      let val = Number(minutes);
      if (isNaN(val)) return undefined;

      let time = val / this.summaryPanelTimeUnitDivider;
      return this.$format.number(time, digits, allowZero);
    }
  }
});

