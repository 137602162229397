export interface HttpRequestOptions {
  content?: Document | XMLHttpRequestBodyInit;
  headers?: { [name: string]: string | string[] };
  noDelay?: boolean;
}

export interface HttpArrayBufferResponse {
  status: number;
  statusText: string;
  responseType: "arraybuffer";
  body: ArrayBuffer;
  headers: { [name: string]: string | string[] };
}

export interface HttpBlobResponse {
  status: number;
  statusText: string;
  responseType: "blob";
  body: Blob;
  headers: { [name: string]: string | string[] };
}

export interface HttpDocumentResponse {
  status: number;
  statusText: string;
  responseType: "document";
  body: Document;
  headers: { [name: string]: string | string[] };
}

export interface HttpJsonResponse<T = any> {
  status: number;
  statusText: string;
  responseType: "json";
  body: T;
  headers: { [name: string]: string | string[] };
}

export interface HttpTextResponse {
  status: number;
  statusText: string;
  responseType: "text";
  body: string;
  headers: { [name: string]: string | string[] };
}

export type HttpResponse =
  | HttpArrayBufferResponse
  | HttpBlobResponse
  | HttpDocumentResponse
  | HttpJsonResponse
  | HttpTextResponse;

const headerUpperCaseRegex = /^.|-./g;

export function parseHttpResponseHeaders(
  rawHeaders: string
): { [name: string]: string | string[] } {
  let returnValue: { [name: string]: string | string[] } = {};
  for (let rawHeader of rawHeaders.split("\r\n")) {
    if (rawHeader) {
      let nameEnd = rawHeader.indexOf(":");
      let name = rawHeader.substr(0, nameEnd);
      // Headers from XMLHttpRequest are always lowercase, we'll convert to uppercase except for
      // the x-ms-* headers, which Microsoft seems to leave as all lowercase
      // HACK: Is this strictly necessary? Should we just lowercase everything?
      if (!name.startsWith("x-ms-"))
        name = name.replace(headerUpperCaseRegex, function(char) {
          return char.toUpperCase();
        });
      let newValue = rawHeader.substr(nameEnd + 1).trim();
      if (name in returnValue) {
        let value = returnValue[name];
        if (Array.isArray(value)) {
          value.push(newValue);
        } else {
          returnValue[name] = [value, newValue];
        }
      } else {
        returnValue[name] = newValue;
      }
    }
  }
  return returnValue;
}

export function parseRawHttpResponse(rawResponse: string): HttpTextResponse {
  let httpStatusEnd = rawResponse.indexOf("\r\n");
  let httpHeaderEnd = rawResponse.indexOf("\r\n\r\n");
  let httpStatusLine = rawResponse.substr(0, httpStatusEnd);
  if (!httpStatusLine.startsWith("HTTP/1.1")) throw new Error("Invalid HTTP payload");
  return {
    status: parseInt(httpStatusLine.substr(9, 3)),
    statusText: httpStatusLine.substr(13),
    responseType: "text",
    body: rawResponse.substr(httpHeaderEnd + 4),
    headers: parseHttpResponseHeaders(rawResponse.substr(httpStatusEnd + 2, httpHeaderEnd))
  };
}

export interface MultipartPayloadItem {
  content: string;
  headers: { [name: string]: string | string[] };
}

export interface MultipartPayload {
  content: string;
  boundary: string;
}

const startingBase36Number = parseInt("aaaaaaaaaa", 36);

export function createMultipartPayload(parts: MultipartPayloadItem[]): MultipartPayload {
  // Produce the HTML chunk for each part
  let partContents = parts.map(function(part) {
    let headerContent = "";
    for (let key in part.headers) {
      let header = part.headers[key];
      if (Array.isArray(header)) {
        for (let headerItem of header) {
          headerContent += `${key}: ${headerItem}\r\n`;
        }
      } else {
        headerContent += `${key}: ${header}\r\n`;
      }
    }
    return `${headerContent}\r\n${part.content}`;
  });

  // Come up with a multipart string that isn't already in use anywhere; we use the base 36 radix
  // starting at startingBase36Number, which produces the 10 character string "aaaaaaaaaa", tripled
  // up to produce a 30 character string; if we find it in any payload we increment by one until we
  // get a unique combination; the MIME documentation (RFC 1341) indicates that the CRLF before the
  // boundary is part of the boundary but the CRLF after the boundary is not
  let base36BoundaryValue = startingBase36Number;
  let valueExistsInPart: boolean;
  let boundary: string;
  let boundaryWithCrLfDashes: string;
  do {
    valueExistsInPart = false;
    boundary = base36BoundaryValue.toString(36);
    boundary = boundary + boundary + boundary;
    boundaryWithCrLfDashes = "\r\n--" + boundary;
    for (let content of partContents) {
      if (content.indexOf(boundaryWithCrLfDashes) !== -1) {
        valueExistsInPart = true;
        base36BoundaryValue++;
        break;
      }
    }
  } while (valueExistsInPart);

  // We have our boundary; form the full remaining body payload with the start and end boundary
  // strings being slightly different from the standard one
  return {
    content:
      "--" +
      boundary +
      "\r\n" +
      partContents.join(boundaryWithCrLfDashes + "\r\n") +
      "\r\n--" +
      boundary +
      "--",
    boundary
  };
}

export function splitMultipartPayload(multipartPayload: MultipartPayload): MultipartPayloadItem[] {
  // The end of the multipart document will look like "\r\n--<boundary>--"; strip everything that
  // comes after that symbol
  let boundary = multipartPayload.boundary;
  let content = multipartPayload.content;
  let boundaryIndex = content.lastIndexOf(`\r\n--${boundary}--`);
  if (boundaryIndex === -1) throw new Error("Invalid multipart payload");
  content = content.substr(0, boundaryIndex);

  // We need to do a similar check at the beginning of the content block
  boundaryIndex = content.indexOf(`--${boundary}\r\n`);
  if (boundaryIndex === -1) throw new Error("Invalid multipart payload");
  content = content.substr(boundaryIndex + boundary.length + 4);

  // Split the remaining content using the boundary string
  let rawParts = content.split(`\r\n--${boundary}\r\n`);

  // Parse out the headers for each item and return the result
  return rawParts.map(function(rawPart) {
    // If there are no headers the payload is preceded by a single CRLF; if there are headers
    // we'll need to strip them out
    let headers: { [name: string]: string | string[] } = {};
    if (rawPart.startsWith("\r\n")) {
      headers = {};
      rawPart = rawPart.substr(2);
    } else {
      let bodyDelimiter = rawPart.indexOf("\r\n\r\n");
      headers = parseHttpResponseHeaders(rawPart.substr(0, bodyDelimiter));
      rawPart = rawPart.substr(bodyDelimiter + 4);
    }
    return { headers, content: rawPart };
  });
}

export function createQueryString(parameters: {
  [name: string]: string | string[] | number | boolean;
}): string {
  return (
    "?" +
    Object.keys(parameters)
      .map(function(key) {
        let parameter = parameters[key];
        if (Array.isArray(parameter)) {
          return parameter
            .map(function(item) {
              return `${key}=${encodeURIComponent(item)}`;
            })
            .join("&");
        } else if (parameter !== undefined) {
          return `${key}=${encodeURIComponent(parameter)}`;
        } else {
          return "";
        }
      })
      .filter(function(part) {
        return !!part;
      })
      .join("&")
  );
}

export function executeRawHttpRequest(
  verb: string,
  url: string,
  responseType: "arraybuffer",
  options?: HttpRequestOptions
): Promise<HttpArrayBufferResponse>;
export function executeRawHttpRequest(
  verb: string,
  url: string,
  responseType: "blob",
  options?: HttpRequestOptions
): Promise<HttpBlobResponse>;
export function executeRawHttpRequest(
  verb: string,
  url: string,
  responseType: "document",
  options?: HttpRequestOptions
): Promise<HttpDocumentResponse>;
export function executeRawHttpRequest<T = any>(
  verb: string,
  url: string,
  responseType: "json",
  options?: HttpRequestOptions
): Promise<HttpJsonResponse<T>>;
export function executeRawHttpRequest<T = any>(
  verb: string,
  url: string,
  responseType: "text",
  options?: HttpRequestOptions
): Promise<HttpTextResponse>;
export function executeRawHttpRequest(
  verb: string,
  url: string,
  responseType: "arraybuffer" | "blob" | "document" | "json" | "text",
  options?: HttpRequestOptions
): Promise<HttpResponse> {
  return new Promise((resolve, reject) => {
    var httpRequest = new XMLHttpRequest();
    // TODO: Reassess this
    httpRequest.timeout = 1200000;
    httpRequest.responseType = responseType;
    httpRequest.onprogress = function(event) {};
    httpRequest.onload = function() {
      resolve({
        status: httpRequest.status,
        statusText: httpRequest.statusText,
        responseType,
        body: httpRequest.response,
        headers: parseHttpResponseHeaders(httpRequest.getAllResponseHeaders())
      } as HttpResponse);
    };
    httpRequest.onerror = function(event) {
      // TODO: Specifics?
      reject(new Error("There was an HTTP connectivity error"));
    };
    // TODO: Do we want to leverage those items below?
    // httpRequest.ontimeout = function () { };
    // httpRequest.onabort = function () { };

    httpRequest.open(verb, url, true);
    if (options && options.headers) {
      let headers = options.headers;
      for (let key in headers) {
        let value = headers[key];
        if (Array.isArray(value)) {
          for (let valueItem of value) {
            httpRequest.setRequestHeader(key, valueItem);
          }
        } else {
          httpRequest.setRequestHeader(key, value);
        }
      }
    }
    httpRequest.send(options && "content" in options ? options.content : null);
  });
}

