var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: [
        {
          "fd-quill-container-disabled": _vm.disabled,
          "fd-readonly": _vm.readonly
        },
        "fd-quill-container"
      ]
    },
    [
      _c("v-subheader", { staticClass: "fd-rich-textarea-sub-header" }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _vm._v(" "),
      _c("div", {
        ref: "editor",
        attrs: { "data-cy": _vm.cy, readonly: _vm.readonly }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }