import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import i18n from "../../../i18n";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import {
  Classification,
  CrewEmployeeWithName,
  CrewWithEmployees,
  Person,
  PersonWithDetails
} from "../../../services";
import { VForm } from "@fd/lib/vue/types";
import {
  FDColumnDirective,
  FDRowNavigateDirective,
  FDTableSortableDirective,
  SortableEvent
} from "@fd/lib/vue/utility/dataTable";
import rules from "@fd/lib/vue/rules";
import { GetPersonName, HasName, PersonWithDetailsAndName } from "../../../utils/person";
import userAccess from "../../../dataMixins/userAccess";
import { SortCrewEmployees } from "./CrewDetailsBottomDialog.vue";

type PersonWithDetailsAndClassificationName = PersonWithDetailsAndName & {
  classificationName: string | undefined;
};

const CrewDetailsDialog = FDVue.extend({
  name: "fd-crew-details-dialog",

  mixins: [dialogSupport, rules, userAccess],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    fdTableSortable: FDTableSortableDirective
  },

  components: {},

  data: () => ({
    // *** GLOBAL ***
    step: 1,
    lastStep: 3,

    detailsStep: 1,
    employeesStep: 2,
    employeeOrderingStep: 3,

    detailserror: false,
    employeeserror: false,
    employeeordererror: false,

    // The following will control whether or not the save button shows the processing/loading indicator
    saving: false,

    canChangeOwner: true,

    // *** EMPLOYEES ***
    showOnlyIncludedEmployees: false,
    tablesearchemployees: "",
    selectableEmployees: [] as Array<PersonWithDetailsAndClassificationName>,

    sourceCrew: undefined as CrewWithEmployees | undefined,
    crew: {
      name: "",
      description: "",
      ownerID: null,
      employees: [] as CrewEmployeeWithName[]
    } as CrewWithEmployees
  }),

  computed: {
    unwatchedMethodNames(): string[] {
      return ["splitFilter", "itemIsSelected", "orderForItem"];
    },
    // List of people available to be selected as Crew Owner
    visiblePeople(): (Person & HasName)[] {
      let visiblePeople = (this.$store.state.users.fullList as PersonWithDetails[])
        .filter(
          x =>
            x.contractorID == this.crew.contractorID ||
            // Allow the current user to be the owner of a crew, and we always want the current owner to be visible even if from a different contractor than the crew
            x.id == this.curUserID ||
            x.id == this.crew.ownerID
        )
        .map(x => ({ ...x, name: GetPersonName(x) }))
        .sort((a, b) => {
          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;
          else return 0;
        });
      return visiblePeople;
    },

    employees: {
      cache: false,
      get(): Array<PersonWithDetailsAndClassificationName> {
        let returnValue = this.selectableEmployees;
        // if (this.showOnlyIncludedEmployees) returnValue = returnValue.filter(x => x.selected);
        if (this.showOnlyIncludedEmployees) {
          returnValue = returnValue.filter(x => this.selectedEmployeeIDs.includes(x.id!));
        }
        return returnValue;
      }
    },

    selectedEmployeeIDs(): string[] {
      return this.crew.employees?.map(e => e.employeeID!) ?? [];
    },
    selectedEmployees(): PersonWithDetailsAndClassificationName[] {
      return this.selectedEmployeeIDs
        .filter(x => !!this.selectableEmployees.find(e => e.id == x))
        .map(x => this.selectableEmployees.find(e => e.id == x)!);
    },

    searchedEmployees(): Array<PersonWithDetailsAndClassificationName> {
      // This is a hack because the employees list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .employeesDataTable as any)?.customFilter;
      if (this.tablesearchemployees && customFilter) {
        return this.employees.filter(
          x =>
            customFilter(x.name!, this.tablesearchemployees, x) ||
            customFilter(x.firstName!, this.tablesearchemployees, x) ||
            customFilter(x.lastName!, this.tablesearchemployees, x) ||
            customFilter(x.employeeCode!, this.tablesearchemployees, x) ||
            customFilter(x.employeeBadge!, this.tablesearchemployees, x)
        );
      } else {
        return this.employees;
      }
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedEmployeesSelected(): boolean {
      return (
        this.searchedEmployees.findIndex(x => !this.selectedEmployeeIDs.includes(x.id!)) === -1
      );
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedEmployeesSelected(): boolean {
      var searchedEmployees = this.searchedEmployees;
      return (
        searchedEmployees.findIndex(x => this.selectedEmployeeIDs.includes(x.id!)) !== -1 &&
        searchedEmployees.findIndex(x => !this.selectedEmployeeIDs.includes(x.id!)) !== -1
      );
    }
  },

  methods: {
    itemIsSelected(item: Person) {
      return this.selectedEmployeeIDs.includes(item.id!);
    },
    splitFilter(value: any, search: string | null, item: any): boolean {
      if (value == null || search == null || typeof value === "boolean") return false;

      value = value.toLocaleLowerCase();
      let searchArray = search.split(" ").map(x => x.toLocaleLowerCase());

      // If any search terms are NOT found in the value, return false
      return !(searchArray.findIndex(x => value.indexOf(x) === -1) !== -1);
    },
    async openForNew(
      contractorID: string,
      ownerID: string | null | undefined,
      defaultName: string | undefined = undefined
    ) {
      this.crew.contractorID = contractorID;
      this.crew.ownerID = ownerID;
      this.crew.name = defaultName;
      if (!!ownerID) this.canChangeOwner = false;
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },
    filterInactiveEmployees() {
      if (!this.visiblePeople?.length || !this.crew) return;

      this.crew.employees = this.crew.employees?.filter(
        x => this.visiblePeople.findIndex(e => e.id == x.employeeID) !== -1
      );
    },
    async openExisting(crew: CrewWithEmployees) {
      this.sourceCrew = crew;
      this.crew = { ...crew } as CrewWithEmployees;
      this.filterInactiveEmployees();
      SortCrewEmployees(this.crew.employees);

      this.canChangeOwner = false;
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },
    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    validate(): boolean {
      this.detailserror = !(this.$refs.detailsform as VForm).validate();
      this.employeeserror = !(this.$refs.employeesform as VForm).validate();

      return !(this.detailserror || this.employeeserror);
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with dialog.
    async saveDialog() {
      if (!this.validate()) {
        var message = i18n.t("contractors.crews.new-crew.error-message");
        if (this.detailserror)
          message += "\n\t- " + i18n.t("contractors.crews.new-crew.steps.details");
        if (this.employeeserror)
          message += "\n\t- " + i18n.t("contractors.crews.new-crew.steps.employees");

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return;
      }

      this.saving = true;
      this.processing = true;

      try {
        if (!this.crew.id) {
          var newCrewID = await this.$store.dispatch("ADD_CREW", {
            ...this.crew
          } as CrewWithEmployees);
          this.crew.id = newCrewID;
          this.crew.employees?.forEach(x => (x.crewID = newCrewID));
        } else {
          await this.$store.dispatch("UPDATE_CREW", this.crew);
        }
        if (!!this.sourceCrew) {
          this.sourceCrew.name = this.crew.name;
          this.sourceCrew.description = this.crew.description;
          this.sourceCrew.ownerID = this.crew.ownerID;
          this.sourceCrew.ownerName = this.crew.ownerName;
          this.sourceCrew.employees = this.crew.employees;
        }
        this.closeDialog(newCrewID);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    ...mapActions({
      loadClassifications: "LOAD_CLASSIFICATIONS",
      loadEmployees: "LOAD_USERS"
    }),

    // *** EMPLOYEES ***
    orderForItem(item: Person) {
      return this.crew.employees?.find(x => x.employeeID == item.id)?.order;
    },
    flipEmployeeSelected(item: PersonWithDetailsAndClassificationName) {
      if (!this.crew.employees) this.crew.employees = [];

      let employeeSelected = this.selectedEmployeeIDs.includes(item.id!);
      if (employeeSelected) {
        let index = this.selectedEmployeeIDs.indexOf(item.id!);
        if (index > -1) {
          this.crew.employees!.splice(index, 1);
        }
      } else {
        if (!this.crew.employees) this.crew.employees = [];
        this.crew.employees.push({
          name: item.name,
          crewID: this.crew.id,
          employeeID: item.id,
          order: this.crew.employees.length
        } as CrewEmployeeWithName);
      }
      this.crew.employees.forEach((item, index) => {
        let newOrder = index + 1;
        console.log(`  ${item.name}: ${item.order} --> ${newOrder}`);
        item.order = newOrder;
      });
    },

    flipSearchedEmployeeselected() {
      if (!this.crew.employees) this.crew.employees = [];

      let selected = !this.allSearchedEmployeesSelected;
      // let changedEmployeeIDs = [] as string[];
      for (let employee of this.searchedEmployees) {
        let employeeSelected = this.selectedEmployeeIDs.includes(employee.id!);
        if (employeeSelected !== selected) {
          if (!!selected) {
            if (!this.crew.employees) this.crew.employees = [];
            this.crew.employees.push({
              name: employee.name,
              crewID: this.crew.id,
              employeeID: employee.id,
              order: this.crew.employees.length
            } as CrewEmployeeWithName);
          } else {
            let index = this.selectedEmployeeIDs.indexOf(employee.id!);
            if (index > -1) {
              this.crew.employees!.splice(index, 1);
            }
          }
        }
      }
      this.crew.employees.forEach((item, index) => {
        let newOrder = index + 1;
        console.log(`  ${item.name}: ${item.order} --> ${newOrder}`);
        item.order = newOrder;
      });
    },
    async dragEnded(e: SortableEvent) {
      if (!this.crew.employees?.length) return;

      let oldIndex = e.oldIndex ?? 0;
      let newIndex = e.newIndex ?? 0;
      if (oldIndex == newIndex) return;

      this.crew.employees.splice(newIndex, 0, ...this.crew.employees.splice(oldIndex, 1));
      console.log(` Employee Indexes`);
      this.crew.employees.forEach((item, index) => {
        let newOrder = index + 1;
        console.log(`  ${item.name}: ${item.order} --> ${newOrder}`);
        item.order = newOrder;
      });
    },
    classificationNameForPerson(person: Person) {
      let allClassifications = this.$store.state.classifications.fullList as Classification[];
      return allClassifications.find(x => x.id == person.classificationID)?.name;
    }
  },

  created: async function() {
    this.processing = true;

    // *** EMPLOYEES ***
    await Promise.all([this.loadClassifications(), this.loadEmployees()]);

    this.selectableEmployees = (this.$store.state.users.fullList as PersonWithDetails[])
      .filter(x => x.contractorID == this.crew.contractorID)
      .map(
        x =>
          ({
            ...x,
            name: GetPersonName(x, true, false),
            classificationName: this.classificationNameForPerson(x)
          } as PersonWithDetailsAndClassificationName)
      );

    this.filterInactiveEmployees();

    this.processing = false;
  }
});

export default CrewDetailsDialog;

export async function createNewCrew(
  contractorID: string,
  ownerID: string | undefined | null = undefined,
  defaultName: string | undefined = undefined
): Promise<string | boolean> {
  let dialog = createDialog(CrewDetailsDialog);
  return await dialog.openForNew(contractorID, ownerID, defaultName);
}

export async function updateExistingCrew(crew: CrewWithEmployees): Promise<string | boolean> {
  let dialog = createDialog(CrewDetailsDialog);
  return await dialog.openExisting(crew);
}

