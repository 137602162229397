import FDVue from "@fd/lib/vue";
import { stripTimeFromLocalizedDateTime } from "@fd/lib/client-util/datetime";

export default FDVue.extend({
  components: {
    "fd-work-order-detail-value": () => import("./WorkOrderDetailValue.vue")
  },
  props: {
    label: { type: [String] },

    value: { type: [Number] },
    enum: { type: [Object] },
    valueStyle: { type: String, default: "" },
    valueClass: { type: [String, Array], default: () => [] },

    loading: { type: Boolean, default: false },

    labelCols: { type: [String, Number], default: 6 },
    valueCols: { type: [String, Number], default: 6 },
    labelSmCols: { type: [String, Number], default: 6 },
    valueSmCols: { type: [String, Number], default: 6 },

    hideNoValuePlaceholder: { type: Boolean, default: false },
    noValueKey: { type: String, default: "common.not-available" },
    noValueStyle: { type: String, default: "" },
    noValueClass: { type: [String, Array], default: () => [] }
  },
  computed: {
    formattedValue(): string | null | undefined {
      if (this.value || this.value === 0) {
        return this.enum[this.value];
      } else {
        return this.value as any;
      }
    }
  }
});
