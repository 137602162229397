import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { countSheetGroupService, PartWithTags } from "../../../services";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import ServiceError from "@fd/lib/client-util/serviceError";
import { SortParts } from "../../../dataMixins/countSheetGroupSorting";

type CountSheetGroupPartsDialogResults = PartWithTags[] | undefined;
type PartWithSelected = PartWithTags & { selected: boolean };

const CountSheetGroupPartsDialog = FDVue.extend({
  name: "fd-count-sheet-group-parts-dialog",
  mixins: [dialogSupport],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },
  components: {
    "fd-count-sheet-group-parts-selection-form": () =>
      import("../forms/CountSheetGroupPartsSelectionForm.vue")
  },

  data: function() {
    return {
      countSheetGroupID: undefined as string | undefined,
      saving: false,
      parts: [] as Array<PartWithTags> | undefined
    };
  },
  computed: {
    unwatchedMethodNames() {
      return ["itemCanBeSelected"];
    }
  },
  methods: {
    async open(
      countSheetGroupID?: string,
      parts?: PartWithTags[]
    ): Promise<CountSheetGroupPartsDialogResults> {
      this.countSheetGroupID = countSheetGroupID;
      this.parts = parts;

      this.optOutOfErrorHandling();
      let saved = await this.showDialog!();
      if (saved) return this.parts; //this.selectableParts.filter(x => x.selected == true);
      return undefined;
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      if (!this.countSheetGroupID) return;

      this.processing = true;
      this.saving = true;
      try {
        let selectedParts = this.parts;
        selectedParts = SortParts(selectedParts);
        selectedParts.forEach((part, index) => {
          part.countSheetGroupDisplayOrder = index + 1;
        });
        await countSheetGroupService.updateCountSheetGroupParts(
          this.countSheetGroupID,
          selectedParts
        );
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as ServiceError);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    ...mapActions({
      loadParts: "LOAD_PARTS"
    }),

    // *** PARTS ***
    flipPartSelected(item: PartWithSelected) {
      item.selected = !item.selected;
    }
  },
  watch: {
    parts() {
      console.log(`parts changed: ${this.parts}`);
    }
  }
});

export default CountSheetGroupPartsDialog;

export async function showCountSheetGroupPartsDialog(options?: {
  countSheetGroupID?: string;
  parts?: PartWithTags[];
}): Promise<CountSheetGroupPartsDialogResults> {
  console.log(
    `showCountSheetGroupPartsDialog countSheetGroupID: ${options?.countSheetGroupID}, parts: ${options?.parts}`
  );
  let dialog = createDialog(CountSheetGroupPartsDialog);
  return await dialog.open(options?.countSheetGroupID, options?.parts);
}

