import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { PersonWithDetails } from "../../../services";
import { Permission, PermissionDetails } from "../forms/UserSecuritySummaryDetailsForm.vue";

const UserSecuritySummaryDialog = FDVue.extend({
  name: "fd-user-security-summary-dialog",

  mixins: [dialogSupport],
  components: {
    "details-form": () => import("../forms/UserSecuritySummaryDetailsForm.vue"),
    "fd-back-button": () => import("@fd/lib/vue/components/BackButton.vue")
  },

  data: function() {
    return {
      user: {} as PersonWithDetails
    };
  },
  computed: {
    menuVisibilityPermissions(): PermissionDetails {
      return {
        title: "Menu Visibility",
        groups: ["Scaffold Requests", "Work Orders", "Material", "Labour", "Scaffolds", "Setup"],
        permissions: [
          {
            text: "Create/Edit",
            group: "Scaffold Requests",
            value: this.user.canSubmitScaffoldRequests
          },
          {
            text: "Approvals",
            group: "Scaffold Requests",
            value: this.user.canViewScaffoldRequestApprovals
          },
          {
            text: "Work Orders",
            group: "Work Orders",
            value: this.user.canViewWorkOrders
          },
          {
            text: "Estimates",
            group: "Work Orders",
            value: this.user.canViewWorkOrderEstimates
          },
          {
            text: "Scheduler",
            group: "Work Orders",
            value: this.user.canViewWorkOrderSchedule
          },
          {
            text: "To Do List",
            group: "Work Orders",
            value: this.user.canViewToDoList
          },
          {
            text: "History",
            group: "Work Orders",
            value: this.user.canViewToDoList
          },
          {
            text: "Paperwork",
            group: "Material",
            value: this.user.canViewMaterialApproval
          },
          {
            text: "Approvals",
            group: "Material",
            value: this.user.canViewMaterialApproval
          },
          {
            text: "Transfers",
            group: "Material",
            value: this.user.canViewTransfers
          },
          {
            text: "Material Orders",
            group: "Material",
            value: this.user.canViewMaterialOrders
          },
          {
            text: "Yard Balances",
            group: "Material",
            value: this.user.canViewMaterialApproval
          },
          {
            text: "Timesheets",
            group: "Labour",
            value: this.user.canViewTimesheets
          },
          {
            text: "Timesheet Approvals",
            group: "Labour",
            value: this.user.canApproveTimesheets
          },
          {
            text: "Timesheet Corrections",
            group: "Labour",
            value: this.user.canApproveTimesheets
          },
          {
            text: "LEMs",
            group: "Labour",
            value: this.user.canViewLEMs
          },
          {
            text: "Timesheet Summary",
            group: "Labour",
            value: this.user.canViewTimesheets
          },
          {
            text: "Weekending Summary",
            group: "Labour",
            value: this.user.canViewTimesheets
          },
          {
            text: "Contractor Details",
            group: "Labour",
            value: this.user.canConfigureContractorEmployees
          },
          {
            text: "Inspections",
            group: "Scaffolds",
            value: this.user.canViewScaffolds,
            moduleCheck: "enableScaffoldInspection"
          },
          {
            text: "Designs",
            group: "Scaffolds",
            value: this.user.canViewScaffolds,
            moduleCheck: "enableScaffoldDesign"
          },
          {
            text: "Scaffolds",
            group: "Scaffolds",
            value: this.user.canViewScaffolds
          },
          {
            text: "Setup",
            group: "Setup",
            value: this.user.canConfigureSettings
          }
        ]
      };
    },
    scaffoldRequestPermissions(): PermissionDetails {
      return {
        title: "Scaffold Requests",
        permissions: [
          {
            text: "Submit",
            value: this.user.canSubmitScaffoldRequests
          },
          {
            text: "Approve (Home Contractor)",
            value: this.user.canApproveScaffoldRequests
          },
          {
            text: "Approve (All Assigned Contractors)",
            value: this.user.canApproveScaffoldRequestsFromAllAssignedContractors
          }
        ]
      };
    },
    walkDownPermissions(): PermissionDetails {
      return {
        title: "Walk Downs",
        permissions: [
          {
            text: "Walk Down Unassigned WOs",
            value: this.user.canWalkDownUnassignedWorkOrders
          },
          {
            text: "Approve",
            value: this.user.canApproveWalkdowns
          }
        ]
      };
    },
    workOrderAssignmentPermissions(): Permission[] {
      return [
        {
          text: "Contractor",
          value: this.user.canEditWorkOrderContractor,
          group: "Assignment"
        },
        {
          text: "Coordinator (Home Contractor)",
          value: this.user.canEditWorkOrderCoordinator,
          group: "Assignment"
        },
        {
          text: "Coordinator (All Assigned Contractors)",
          value: this.user.canEditWorkOrderCoordinatorForAllAssignedContractors,
          group: "Assignment"
        },
        {
          text: "General Foreman (Home Contractor)",
          value: this.user.canEditWorkOrderGeneralForeman,
          group: "Assignment"
        },
        {
          text: "General Foreman (All Assigned Contractors)",
          value: this.user.canEditWorkOrderGeneralForemanForAllAssignedContractors,
          group: "Assignment"
        },
        {
          text: "Foreman (Home Contractor)",
          value: this.user.canEditWorkOrderForeman,
          group: "Assignment"
        },
        {
          text: "Foreman (All Assigned Contractors)",
          value: this.user.canEditWorkOrderForemanForAllAssignedContractors,
          group: "Assignment"
        }
      ];
    },
    workOrderEditPermissions(): Permission[] {
      return [
        {
          text: "Area (Home Contractor)",
          group: "Edit",
          value: this.user.canEditWorkOrderArea
        },
        {
          text: "Area (All Assigned Contractors)",
          group: "Edit",
          value: this.user.canEditWorkOrderAreaForAllAssignedContractors
        },
        {
          text: "Location (Home Contractor)",
          group: "Edit",
          value: this.user.canEditWorkOrderLocation
        },
        {
          text: "Location (All Assigned Contractors)",
          group: "Edit",
          value: this.user.canEditWorkOrderLocationForAllAssignedContractors
        },
        {
          text: "Work Description (Home Contractor)",
          group: "Edit",
          value: this.user.canEditWorkOrderWorkDescription
        },
        {
          text: "Work Description (All Assigned Contractors)",
          group: "Edit",
          value: this.user.canEditWorkOrderWorkDescriptionForAllAssignedContractors
        },
        {
          text: "Priority (Home Contractor)",
          group: "Edit",
          value: this.user.canEditWorkOrderPriority
        },
        {
          text: "Priority (All Assigned Contractors)",
          group: "Edit",
          value: this.user.canEditWorkOrderPriorityForAllAssignedContractors
        },
        {
          text: "Progress (Home Contractor)",
          group: "Edit",
          value: this.user.canEditWorkOrderProgress
        },
        {
          text: "Progress (All Assigned Contractors)",
          group: "Edit",
          value: this.user.canEditWorkOrderProgressForAllAssignedContractors
        },
        {
          text: "Status (Home Contractor)",
          group: "Edit",
          value: this.user.canEditWorkOrderStatus
        },
        {
          text: "Status (All Assigned Contractors)",
          group: "Edit",
          value: this.user.canEditWorkOrderStatusForAllAssignedContractors
        },
        {
          text: "Cancel (Home Contractor)",
          group: "Edit",
          value: this.user.canCancelWorkOrder
        },
        {
          text: "Cancel (All Assigned Contractors)",
          group: "Edit",
          value: this.user.canCancelWorkOrderForAllAssignedContractors
        },
        {
          text: "Required Date (Home Contractor)",
          group: "Edit",
          value: this.user.canEditWorkOrderRequiredDate
        },
        {
          text: "Required Date (All Assigned Contractors)",
          group: "Edit",
          value: this.user.canEditWorkOrderRequiredDateForAllAssignedContractors
        },
        {
          text: "To Do List (Home Contractor)",
          group: "Edit",
          value: this.user.canEditWorkOrderPlannedWorkDate
        },
        {
          text: "To Do List (All Assigned Contractors)",
          group: "Edit",
          value: this.user.canEditWorkOrderPlannedWorkDateForAllAssignedContractors
        },
        {
          text: "Work Packages",
          group: "Edit",
          value: this.user.canEditWorkOrderWorkPackages
        },
        {
          text: "Estimate Details Tab",
          group: "Edit",
          value: this.user.canViewWorkOrderEstimateDetails
        },
        {
          text: "Estimate Details",
          group: "Edit",
          value: this.user.canEditWorkOrderEstimateDetails
        },
        {
          text: "Build Sheet Responses",
          group: "Edit",
          value: this.user.canEditWorkOrderBuildSheetResponses
        }
      ];
    },
    workOrderPermissions(): PermissionDetails {
      let permissions = [] as Permission[];
      permissions = permissions.concat(this.workOrderAssignmentPermissions);
      permissions = permissions.concat(this.workOrderEditPermissions);
      return {
        title: "Work Orders",
        groups: ["Edit", "Assignment"],
        permissions: permissions
      };
    },
    materialPermissions(): PermissionDetails {
      return {
        title: "Material",
        permissions: [
          {
            text: "Approve",
            value: this.user.canApproveCountSheets
          },
          {
            text: "Create Transfers",
            value: this.user.canCreateTransfers
          },
          {
            text: "Override Transfer Values",
            value: this.user.canOverrideTransferValues
          },
          {
            text: "Create & Submit Material Orders",
            value: this.user.canSubmitMaterialOrders
          },
          {
            text: "Approve Material Orders",
            value: this.user.canApproveMaterialOrders
          },
          {
            text: "Fulfill Material Orders",
            value: this.user.canFulfillMaterialOrders
          }
        ]
      };
    },
    laborPermissions(): PermissionDetails {
      return {
        title: "Labor",
        groups: ["Timesheets", "LEMs", "Manage Resources"],
        permissions: [
          {
            text: "Submit",
            group: "Timesheets",
            value: this.user.canSubmitTimesheets
          },
          {
            text: "Own Direct Timesheets",
            group: "Timesheets",
            value: this.user.canOwnDirectTimesheets
          },
          {
            text: "Change Work Type",
            group: "Timesheets",
            value: this.user.canEditTimesheetWorkType
          },
          {
            text: "Change Cost Code",
            group: "Timesheets",
            value: this.user.canEditTimesheetCostCode
          },
          {
            text: "Approve",
            group: "Timesheets",
            value: this.user.canApproveTimesheets
          },
          {
            text: "See Other Peoples' Timesheets",
            group: "Timesheets",
            value: this.user.canSeeOtherPeopleTimesheets
          },
          {
            text: "Create Indirect Timesheets",
            group: "Timesheets",
            value: this.user.canCreateIndirectTimesheets
          },
          {
            text: "Make Corrections on Approved Timesheets",
            group: "Timesheets",
            value: this.user.canCorrectApprovedTimesheets
          },
          {
            text: "Cancel Timesheets",
            group: "Timesheets",
            value: this.user.canCancelTimesheets
          },
          {
            text: "Submit",
            group: "LEMs",
            value: this.user.canSubmitLEMs
          },
          {
            text: "Approve",
            group: "LEMs",
            value: this.user.canApproveLEMs
          },
          {
            text: "Add Contractor",
            group: "Manage Resources",
            value: this.user.canConfigureSettings
          },
          {
            text: "See Contractor Details",
            group: "Manage Resources",
            value: this.user.canConfigureContractorEmployees || this.user.canConfigureSettings
          },
          {
            text: "Edit Contractor Details",
            group: "Manage Resources",
            value: this.user.canConfigureSettings
          },
          {
            text: "Add Contractor Employee",
            group: "Manage Resources",
            value: this.user.canConfigureContractorEmployees || this.user.canConfigureSettings
          },
          {
            text: "Delete Contractor Employee",
            group: "Manage Resources",
            value: this.user.canConfigureSettings
          },
          {
            text: "Archive Contractor Employee",
            group: "Manage Resources",
            value: this.user.canConfigureContractorEmployees || this.user.canConfigureSettings
          }
        ]
      };
    },
    scaffoldPermissions(): PermissionDetails {
      return {
        title: "Scaffold",
        permissions: [
          {
            text: "View",
            value: this.user.canViewScaffolds
          },
          {
            text: "Edit Status",
            value: this.user.canEditScaffoldStatus
          },
          {
            text: "Edit Dismantle Date",
            value: this.user.canEditScaffoldDismantleDate
          }
        ]
      };
    }
  },
  methods: {
    async open(user: PersonWithDetails) {
      this.user = user;

      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },
    cancelDialog() {
      this.closeDialog(false);
    }
  }
});

export default UserSecuritySummaryDialog;

export async function openUserSecuritySummaryDialog(user: PersonWithDetails): Promise<boolean> {
  let dialog = createDialog(UserSecuritySummaryDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(user);
}

