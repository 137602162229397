import { supplierService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: supplierService,
  singularStoreName: "SUPPLIER",
  pluralStoreName: "SUPPLIERS",
  localizationPrefix: "suppliers",
  consumerRelatedIDProperty: {
    name: "supplierIDs",
    type: "array"
  }
});

export default storeModule;
