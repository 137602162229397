var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-switch",
    _vm._b(
      {
        staticClass: "fd-show-archived-switch mr-5",
        staticStyle: { "margin-top": "16px" },
        attrs: {
          "data-cy": _vm.cy,
          "input-value": _vm.value,
          label: _vm.$vuetify.breakpoint.smAndUp
            ? _vm.$t("common.show-archived-label")
            : _vm.$t("common.show-archived-label-short")
        },
        on: { change: v => _vm.$emit("input", v) }
      },
      "v-switch",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }