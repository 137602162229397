var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-combobox", {
    class: _vm.inputClass,
    attrs: {
      "data-cy": _vm.cy,
      filter: _vm.itemFilter,
      "hide-no-data": !_vm.tagsSearch,
      items: _vm.availableItems,
      "item-value": "id",
      "search-input": _vm.tagsSearch,
      "hide-selected": "",
      label: _vm.controlLabel,
      multiple: "",
      "small-chips": _vm.smallChips,
      chips: !_vm.smallChips,
      outlined: _vm.outlined,
      hint: _vm.controlHint,
      "persistent-hint": _vm.persistentHint,
      dark: _vm.dark,
      disabled: _vm.disabled,
      "solo-inverted": _vm.soloInverted,
      flat: _vm.flat,
      dense: _vm.dense,
      filled: _vm.filled,
      rules: _vm.$attrs.rules
    },
    on: {
      "update:searchInput": function($event) {
        _vm.tagsSearch = $event
      },
      "update:search-input": function($event) {
        _vm.tagsSearch = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "no-data",
        fn: function() {
          return [
            _vm.allowCreate
              ? _c(
                  "v-list-item",
                  [
                    _c("span", { staticClass: "subheading mr-2" }, [
                      _vm._v(_vm._s(_vm.$t("common.create")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-chip",
                      { class: _vm.chipClass, attrs: { dark: "", small: "" } },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.tagsSearch) + "\n      "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "selection",
        fn: function({ attrs, item, parent, selected }) {
          return [
            item === Object(item)
              ? _c(
                  "v-chip",
                  _vm._b(
                    {
                      class: _vm.chipClass,
                      attrs: { "input-value": selected, dark: "", small: "" }
                    },
                    "v-chip",
                    attrs,
                    false
                  ),
                  [
                    _c("span", { staticClass: "pr-2" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.getItemLabel(item)) +
                          "\n      "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-icon",
                      {
                        attrs: { small: "" },
                        on: {
                          click: function($event) {
                            return parent.selectItem(item)
                          }
                        }
                      },
                      [_vm._v("close")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        }
      },
      {
        key: "item",
        fn: function({ index, item }) {
          return [
            _vm.editedItem && _vm.editedItem.id == item.id
              ? _c("v-text-field", {
                  attrs: {
                    autofocus: "",
                    flat: "",
                    "background-color": "transparent",
                    "hide-details": "",
                    solo: ""
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.editTags(index, item)
                    }
                  },
                  model: {
                    value: _vm.editedItem.name,
                    callback: function($$v) {
                      _vm.$set(_vm.editedItem, "name", $$v)
                    },
                    expression: "editedItem.name"
                  }
                })
              : _c(
                  "v-chip",
                  { class: _vm.chipClass, attrs: { dark: "", small: "" } },
                  [
                    _vm._v(
                      "\n      " + _vm._s(_vm.getItemLabel(item)) + "\n    "
                    )
                  ]
                ),
            _vm._v(" "),
            _vm.allowEdit ? _c("v-spacer") : _vm._e(),
            _vm._v(" "),
            _vm.allowEdit
              ? _c(
                  "v-list-item-action",
                  {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.editTags(index, item)
                          }
                        }
                      },
                      [
                        _c("v-icon", [
                          _vm._v(
                            _vm._s(
                              _vm.editedItem !== item
                                ? "mdi-pencil"
                                : "mdi-check"
                            )
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        }
      }
    ]),
    model: {
      value: _vm.internalSelectedItems,
      callback: function($$v) {
        _vm.internalSelectedItems = $$v
      },
      expression: "internalSelectedItems"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }