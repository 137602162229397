var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              return _vm.closeDialogEntry(false)
            }
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                [
                  _vm.inlineMessage.message
                    ? _c(
                        "v-alert",
                        {
                          staticClass: "mt-0 mb-5 ml-0 mr-0",
                          attrs: { type: _vm.inlineMessage.type }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.inlineMessage.message) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.accessCodeSent
                    ? _c(
                        "v-col",
                        {
                          class: [
                            {
                              "appear-nicely": _vm.accessCodeSent,
                              "disappear-nicely": !_vm.accessCodeSent
                            }
                          ],
                          staticStyle: {
                            opacity: "0",
                            "pointer-events": "none"
                          },
                          attrs: { cols: "12" }
                        },
                        [
                          _c("v-row", { class: ["mt-5", "ml-0", "mr-0"] }, [
                            _c(
                              "span",
                              {
                                class: {
                                  "login-panel-sub-title-text-regular": true
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("access-code-prompt.explanation")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          !!_vm.accessCodeChallenge &&
                          _vm.accessCodeChallenge.sentToEmailAddresses.length >
                            0
                            ? _c("v-row", { class: ["mt-5", "ml-0", "mr-0"] }, [
                                !!_vm.accessCodeChallenge &&
                                _vm.accessCodeChallenge.sentToEmailAddresses
                                  .length == 1
                                  ? _c(
                                      "span",
                                      { staticStyle: { "font-size": "1.0em" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "access-code-prompt.quick-access-code-request-response-inline-email-statement"
                                            )
                                          ) + "\n              "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "login-call-out-text-style"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  !!_vm.accessCodeChallenge &&
                                                    _vm.accessCodeChallenge
                                                      .sentToEmailAddress
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _vm._v(".\n            ")
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      { staticStyle: { "font-size": "1.0em" } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "access-code-prompt.quick-access-code-request-response-inline-multiple-emails-statement"
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !!_vm.accessCodeChallenge &&
                          _vm.accessCodeChallenge.sentToPhoneNumbers.length > 0
                            ? _c("v-row", { class: ["mt-5", "ml-0", "mr-0"] }, [
                                !!_vm.accessCodeChallenge &&
                                _vm.accessCodeChallenge.sentToPhoneNumbers
                                  .length == 1
                                  ? _c(
                                      "span",
                                      { staticStyle: { "font-size": "1.0em" } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "access-code-prompt.quick-access-code-request-response-inline-phone-statement"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "login-call-out-text-style"
                                          },
                                          [
                                            _vm._v(
                                              "***-***-*" +
                                                _vm._s(
                                                  !!_vm.accessCodeChallenge &&
                                                    _vm.accessCodeChallenge
                                                      .sentToPhoneNumberTail
                                                )
                                            )
                                          ]
                                        ),
                                        _vm._v(".\n            ")
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      { staticStyle: { "font-size": "1.0em" } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "access-code-prompt.quick-access-code-request-response-inline-multiple-phones-statement"
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("fd-code-entry", {
                            ref: "accesscodeentry",
                            class: ["pt-5"],
                            attrs: {
                              disabled: _vm.processing,
                              value: _vm.accesscode
                            },
                            on: { codeEntered: _vm.codeEntered }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "ml-0 mr-0 mt-1" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pb-0",
                                  staticStyle: { "margin-left": "-25px" },
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.processing,
                                        text: "",
                                        small: ""
                                      },
                                      on: { click: _vm.resendAccessCode }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "access-code-prompt.resend-code-label"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", disabled: _vm.processing },
                      on: {
                        click: function($event) {
                          return _vm.closeDialog(null)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }