import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { ScaffoldVerticalLengthFeetLogWithDetails } from "../../../services";

const ScaffoldVLFHistoryDialog = FDVue.extend({
  name: "fd-scaffold-vlf-history-dialog",

  mixins: [dialogSupport],

  components: {
    "fd-log-history-form": () => import("../forms/LogHistoryForm.vue")
  },

  data: function() {
    return {
      logs: [] as ScaffoldVerticalLengthFeetLogWithDetails[]
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return ["summaryStringForLog"];
    }
  },

  methods: {
    async open(logs: ScaffoldVerticalLengthFeetLogWithDetails[] | undefined) {
      this.logs = logs ?? [];
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.cancelDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    summaryStringForLog(log: ScaffoldVerticalLengthFeetLogWithDetails) {
      return this.$t("scaffolds.existing-scaffold.vlf-log-summary", [
        log.changeAmount ?? this.$t("common.none"),
        log.workOrderNumber ?? this.$t("common.unknown")
      ]);
    }
  },

  created: async function() {}
});

export default ScaffoldVLFHistoryDialog;

export async function showScaffoldVLFHistoryDialog(
  logs: ScaffoldVerticalLengthFeetLogWithDetails[] | undefined
): Promise<boolean> {
  let dialog = createDialog(ScaffoldVLFHistoryDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(logs);
}

