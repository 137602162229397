var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    _vm._b(
      {
        attrs: {
          value: !!_vm.value && _vm.value.length > 0,
          "data-cy": _vm.cy,
          fullscreen: "",
          dark: ""
        },
        on: { input: v => _vm.$emit("input", v) }
      },
      "v-dialog",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "div",
        { staticClass: "fd-image-editor-height" },
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "" } },
            [
              _c("fd-text-field", {
                staticStyle: { "max-width": "320px" },
                attrs: { "hide-details": "" },
                model: {
                  value: _vm.fileName,
                  callback: function($$v) {
                    _vm.fileName = $$v
                  },
                  expression: "fileName"
                }
              }),
              _vm._v(" "),
              !!_vm.fileExtension
                ? _c("div", [_vm._v("." + _vm._s(_vm.fileExtension))])
                : _vm._e(),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: {
                    text: "",
                    link: "",
                    small: _vm.$vuetify.breakpoint.xs
                  },
                  on: { click: _vm.close }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.cancel")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    small: _vm.$vuetify.breakpoint.xs
                  },
                  on: { click: _vm.save }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(_vm.completeButtonLabelKey)) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "PinturaEditor",
            _vm._b(
              {
                ref: "inline",
                staticStyle: { "--color-background": "255, 255, 255" },
                attrs: {
                  utils: _vm.enabledUtils,
                  util: _vm.activeUtil,
                  src: _vm.value,
                  enableButtonClose: false,
                  enableButtonExport: false
                },
                on: {
                  "pintura:process": function($event) {
                    return _vm.handleProcess($event)
                  }
                }
              },
              "PinturaEditor",
              _vm.editorProps,
              false
            )
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }