import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import { Discipline, disciplineService, Person, PersonWithDetails } from "../../services";

export type DisciplineWithSelected = Discipline & { selected: boolean };
type DisciplineSelectionType = "entire" | "selection" | "match";

export const disciplineSelection = FDVue.extend({
  data: function() {
    return {
      user: {} as PersonWithDetails,
      // *** DISCIPLINES ***
      showOnlyIncludedDisciplines: false,
      disciplinesTableSearch: "",
      selectableDisciplines: [] as Array<DisciplineWithSelected>
    };
  },

  computed: {
    // *** DISCIPLINES ***
    disciplinesUsageType: {
      get(): string {
        return this.user.includesAllDisciplines ? "entire" : "selection";
      },
      set(val: string) {
        this.user.includesAllDisciplines = val == "entire";
      }
    },
    disciplines(): Array<DisciplineWithSelected> {
      let returnValue = this.selectableDisciplines;
      if (this.showOnlyIncludedDisciplines) returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedDisciplineIDs(): string[] {
      return this.selectableDisciplines.filter(x => x.selected).map(x => x.id!);
    },

    searchedDisciplines(): Array<DisciplineWithSelected> {
      // This is a hack because the disciplines list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .disciplinesDataTable as any)?.customFilter;
      if (this.disciplinesTableSearch && customFilter) {
        return this.disciplines.filter(
          x =>
            customFilter(x.name!, this.disciplinesTableSearch, x) ||
            customFilter(x.description!, this.disciplinesTableSearch, x)
        );
      } else {
        return this.disciplines;
      }
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedDisciplinesSelected(): boolean {
      return this.searchedDisciplines.findIndex(x => !x.selected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedDisciplinesSelected(): boolean {
      var searchedDisciplines = this.searchedDisciplines;
      return (
        searchedDisciplines.findIndex(x => x.selected) !== -1 &&
        searchedDisciplines.findIndex(x => !x.selected) !== -1
      );
    }
  },

  watch: {
    "user.contractorID": function() {
      this.loadDisciplines();
    }
  },

  methods: {
    // *** GLOBAL ***

    ...mapActions({
      // loadDisciplines: "LOAD_DISCIPLINES"
    }),

    // *** DISCIPLINES ***
    async loadDisciplines() {
      if (!!this.user.contractorID) {
        this.selectableDisciplines = (
          (await disciplineService.getByContractorID(this.user.contractorID)) ?? []
        ).map(
          x =>
            ({
              ...x,
              selected: this.user.disciplineIDs?.length
                ? this.user.disciplineIDs.indexOf(x.id!) > -1
                : false
            } as DisciplineWithSelected)
        );
      } else {
        this.selectableDisciplines = (this.$store.state.disciplines.fullList as Discipline[]).map(
          x =>
            ({
              ...x,
              selected: this.user.disciplineIDs?.length
                ? this.user.disciplineIDs.indexOf(x.id!) > -1
                : false
            } as DisciplineWithSelected)
        );
      }
    },

    // DOES NOT manage processing or error message logic
    flipDisciplineSelected(item: DisciplineWithSelected & { selected: boolean }) {
      item.selected = !item.selected;
    },

    flipSearchedDisciplinesSelected() {
      let selected = !this.allSearchedDisciplinesSelected;
      for (let discipline of this.searchedDisciplines) {
        if (discipline.selected !== selected) {
          discipline.selected = selected;
        }
      }
    }
  }
});

