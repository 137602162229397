import FDVue from "@fd/lib/vue";
import { mapMutations } from "vuex";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import {
  countSheetService,
  countSheetListService,
  CountSheetReviewStatus,
  CountSheetWithParts,
  WorkOrderWithAllDetails,
  GridCountSheet,
  WorkOrderStatuses,
  ScaffoldRequestTypes,
  CountSheetType,
  ScaffoldRequestSubTypes,
  WorkOrderPackageListItem
} from "../services";
import { contractorDataStore as contractorDataService } from "../store/referenceData";
import { personDataStore as personDataService } from "../store/referenceData";
import { personBySecurityIDDataStore as personBySecurityIDDataService } from "../store/referenceData";
import { disciplineDataStore as disciplineDataService } from "../store/referenceData";
import { projectLocationDataStore as projectLocationDataService } from "../store/referenceData";
import userAccess from "../dataMixins/userAccess";
import * as DateUtil from "@fd/lib/client-util/datetime";
import rules from "@fd/lib/vue/rules";
import { DateRangePreset } from "@fd/lib/vue/components/DateRangePicker.vue";
import { ParseWorkOrderWithAllDetails } from "../dataMixins/workOrderList";

type FormattedCountSheet = CountSheetWithParts & {
  scaffoldNumber: number | undefined;
  workOrderNumber: number | undefined;
  requestTypeName: string | undefined;
  workOrderStatusName: string | undefined;
  formattedReviewStatusChangeTime: string | undefined;
  // Transfer IDs only exist for approved count sheets
  transferIDsList?: string | undefined;
};

function scaffoldRequestSubTypeToDisplayName(value: ScaffoldRequestSubTypes) {
  switch (value) {
    case ScaffoldRequestSubTypes.ErectScaffold:
      return "SCAFFOLD";
    case ScaffoldRequestSubTypes.ErectHardBarricade:
      return "HARD BARRICADE";
    case ScaffoldRequestSubTypes.DismantleFull:
      return "FULL";
    case ScaffoldRequestSubTypes.ModifyAdjustmentOnly:
      return "ADJUSTMENT ONLY";
    case ScaffoldRequestSubTypes.ModifyMaterialAdded:
      return "MATERIAL | ADDED";
    case ScaffoldRequestSubTypes.ModifyMaterialRemoved:
      return "MATERIAL | REMOVED";
    case ScaffoldRequestSubTypes.ModifyModify:
      return "MODIFY";
    default:
      return null;
  }
}

export default FDVue.extend({
  name: "fd-countsheet-list",

  mixins: [serviceErrorHandling, userAccess, rules],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-work-order-details": () => import("../views/components/WorkOrderDetailsForm.vue"),
    "fd-scaffold-number-with-badges": () =>
      import("../views/components/ScaffoldNumberWithBadges.vue")
  },

  data: function() {
    return {
      archivedLoading: false,
      minDate: undefined as Date | undefined,
      maxDate: undefined as Date | undefined,

      didLoadApproved: false,

      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5,

      expanderExpandCount: 0,

      allPendingOrDeclinedCountSheets: [] as CountSheetWithParts[],
      allApprovedCountSheets: [] as GridCountSheet[],
      allApprovedCountSheetTransfers: new Map<string, string>(),
      allApprovedCountSheetWorkOrders: new Map<string, WorkOrderWithAllDetails>(),
      allApprovedCountSheetWorkPackages: new Map<string, WorkOrderPackageListItem[]>()
    };
  },

  computed: {
    selectionType: {
      get(): "pending" | "declined" | "approved" {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
      },
      set(val: "pending" | "declined" | "approved") {
        var current = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
        if (val == current) return;

        this.$store.commit("SET_CONTEXT_FOR_FILTERING", val);

        if (val == "approved" && !this.didLoadApproved) {
          this.reloadTableData();
        }
      }
    },
    selectionTypeIsApproved(): boolean {
      return this.selectionType == "approved";
    },
    countSheets(): FormattedCountSheet[] {
      if (this.selectionType == "pending") return this.pendingCountSheets;
      if (this.selectionType == "declined") return this.declinedCountSheets;
      else if (this.selectionTypeIsApproved) return this.approvedCountSheets;

      return [];
    },
    pendingCountSheets(): FormattedCountSheet[] {
      return this.allPendingOrDeclinedCountSheets
        .filter(x => x.reviewStatusID == CountSheetReviewStatus.Pending)
        .map(
          x =>
            ({
              ...x,
              scaffoldNumber: x.workOrder?.scaffoldNumber,
              workOrderNumber: x.workOrder?.internalNumber,
              requestTypeName: x.workOrder?.requestTypeName,
              workOrderStatusName: x.workOrder?.workOrderStatusName,
              formattedReviewStatusChangeTime: this.formatDate(x.reviewStatusChangeTime)
            } as FormattedCountSheet)
        );
    },
    declinedCountSheets(): FormattedCountSheet[] {
      return this.allPendingOrDeclinedCountSheets
        .filter(x => x.reviewStatusID == CountSheetReviewStatus.Declined)
        .map(
          x =>
            ({
              ...x,
              scaffoldNumber: x.workOrder?.scaffoldNumber,
              workOrderNumber: x.workOrder?.internalNumber,
              requestTypeName: x.workOrder?.requestTypeName,
              workOrderStatusName: x.workOrder?.workOrderStatusName,
              formattedReviewStatusChangeTime: this.formatDate(x.reviewStatusChangeTime)
            } as FormattedCountSheet)
        );
    },
    approvedCountSheets(): FormattedCountSheet[] {
      return this.allApprovedCountSheets.map(
        x =>
          (({
            ...x,
            countSheetTypeName:
              x.countSheetTypeID == CountSheetType.Individual
                ? "individual"
                : x.countSheetTypeID == CountSheetType.NotApplicable
                ? "not-applicable"
                : "remove-all",
            submittedByName: personDataService.lookupCaption(x.submittedBy ?? null) || "Loading...",
            submittedByContractorName:
              contractorDataService.lookupCaption(
                personDataService.lookup(x.submittedBy ?? null)?.contractorID ?? null
              ) || "Loading...",
            reviewApprovedByName:
              personDataService.lookupCaption(x.reviewApprovedBy ?? null) || "Loading...",
            reviewStatusName: "approved",
            scaffoldNumber: x.scaffoldNumber,
            workOrderNumber: x.workOrderNumber,
            requestTypeName: ScaffoldRequestTypes[x.requestType!]?.toUpperCase(),
            workOrderStatusName: this.$t(`workorders.status.${x.workOrderStatusID}`),
            formattedReviewStatusChangeTime: this.formatDate(x.reviewStatusChangeTime),
            transferIDsList:
              this.allApprovedCountSheetTransfers.size > 0
                ? this.allApprovedCountSheetTransfers.get(x.id!) || undefined
                : "Loading...",
            workOrder:
              this.allApprovedCountSheetWorkOrders.size && this.allApprovedCountSheetWorkPackages
                ? {
                    ...this.allApprovedCountSheetWorkOrders.get(x.workOrderID!),
                    workPackageNames: (
                      this.allApprovedCountSheetWorkPackages.get(x.workOrderID!) || []
                    ).map(workPackage => workPackage.workPackageName)
                  }
                : { isLoading: true }
          } as unknown) as FormattedCountSheet)
      );
    },
    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    // Archived Props for Approved Count Sheets
    dateRangePresetOptions(): DateRangePreset[] {
      return [
        {
          fromDate: DateUtil.addDaysToDate(null, 0),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "today",
          labelKey: "fd-date-range-picker.preset-today-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -6),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-week",
          labelKey: "fd-date-range-picker.preset-previous-week-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -13),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-weeks",
          labelKey: "fd-date-range-picker.preset-previous-two-weeks-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addMonthsToDate(null, -2),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-months",
          labelKey: "fd-date-range-picker.preset-previous-two-months-label"
        } as DateRangePreset
      ];
    },
    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = DateUtil.addMonthsToDate(date, -2);
      return minDate;
    },
    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = DateUtil.addMonthsToDate(date, 2);
      return maxDate;
    },
    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          this.archivedLoading = true;
          try {
            await this.reloadTableData();
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
            this.archivedLoading = false;
          }
        } else this.showArchivedToDate = null;
      }
    },
    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },
    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    }
  },

  methods: {
    formatDate(date: Date | string | undefined) {
      return DateUtil.localizedDateTimeString(date);
    },
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        let serviceCalls = [];
        if (this.selectionType == "approved") {
          serviceCalls.push(this.reloadApprovedCountSheets());
        } else {
          serviceCalls.push(this.reloadPendingOrDeclinedCountSheets());
        }

        await Promise.all(serviceCalls);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadPendingOrDeclinedCountSheets() {
      var allPendingOrDeclinedCountSheets = await countSheetService.getAllPendingOrDeclined();
      this.allPendingOrDeclinedCountSheets = allPendingOrDeclinedCountSheets.map(x => ({
        ...x,
        workOrder: ParseWorkOrderWithAllDetails(x.workOrder)
      }));
    },

    async reloadPendingOrDeclinedCountSheets() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      await this.loadPendingOrDeclinedCountSheets();

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },

    async loadApprovedCountSheets() {
      var approvedCountOperations = [
        this.loadApprovedCountSheetsBase(),
        this.loadApprovedCountSheetTransfers()
      ];
      if (this.expanderExpandCount > 0) {
        approvedCountOperations.push(this.loadApprovedCountSheetWorkOrders());
      }
      this.didLoadApproved = true;
      await Promise.all(approvedCountOperations);
    },

    async loadApprovedCountSheetsBase() {
      this.allApprovedCountSheets = await countSheetListService.getApprovedCountSheetsForGrid(
        true,
        this.showArchivedFromDate,
        this.showArchivedToDate
      );
    },

    async loadApprovedCountSheetTransfers() {
      let rawTransfers = await countSheetListService.getApprovedCountSheetTransfersForGrid(
        true,
        this.showArchivedFromDate,
        this.showArchivedToDate
      );
      let allApprovedCountSheetTransfers = new Map<string, string>();
      for (let transfer of rawTransfers) {
        let existingTransfers = allApprovedCountSheetTransfers.get(transfer.countSheetID);
        if (existingTransfers) {
          existingTransfers = `${existingTransfers}, ${transfer.transferNumber}`;
        } else {
          existingTransfers = transfer.transferNumber.toString();
        }
        allApprovedCountSheetTransfers.set(transfer.countSheetID, existingTransfers);
      }
      this.allApprovedCountSheetTransfers = allApprovedCountSheetTransfers;
    },

    async loadApprovedCountSheetWorkOrders() {
      let rawWorkOrders = await countSheetListService.getApprovedCountSheetWorkOrdersForGrid(
        true,
        this.showArchivedFromDate,
        this.showArchivedToDate
      );
      this.allApprovedCountSheetWorkOrders = new Map<string, WorkOrderWithAllDetails>(
        rawWorkOrders.map(workOrder => [
          workOrder.id!,
          {
            ...workOrder,
            requestTypeName: ScaffoldRequestTypes[workOrder.requestType!]?.toUpperCase(),
            requestSubTypeName: scaffoldRequestSubTypeToDisplayName(workOrder.requestSubType!)!,
            workOrderStatusName: WorkOrderStatuses[workOrder.workOrderStatus!]?.toUpperCase(),
            requestingContractorName: contractorDataService.lookupCaption(
              workOrder.requestingContractorID ?? null
            )!,
            requestingEmployeeName: personDataService.lookupCaption(
              workOrder.requestingEmployeeID ?? null
            )!,
            requestSubmitterName: personBySecurityIDDataService.lookupCaption(
              workOrder.requestSubmitterID
            )!,
            disciplineName: disciplineDataService.lookupCaption(workOrder.disciplineID ?? null)!,
            assignedContractorName: contractorDataService.lookupCaption(
              workOrder.assignedContractorID ?? null
            )!,
            coordinatorName: personDataService.lookupCaption(workOrder.coordinatorID ?? null)!,
            generalForemanName: personDataService.lookupCaption(
              workOrder.generalForemanID ?? null
            )!,
            foremanName: personDataService.lookupCaption(workOrder.foremanID ?? null)!,
            areaName: projectLocationDataService.lookupCaption(workOrder.areaID ?? null)!,
            subAreaName: projectLocationDataService.lookupCaption(workOrder.subAreaID ?? null)!,
            lastStatusChangedBy: personBySecurityIDDataService.lookupCaption(
              workOrder.lastStatusChangedByID
            )!
          }
        ])
      );
    },

    async loadApprovedCountSheetWorkPackages() {
      let rawWorkPackages = await countSheetListService.getApprovedCountSheetWorkPackagesForGrid(
        true,
        this.showArchivedFromDate,
        this.showArchivedToDate
      );
      let allApprovedCountSheetWorkPackages = new Map<string, WorkOrderPackageListItem[]>();
      for (let rawWorkPackage of rawWorkPackages) {
        let existingWorkPackageList = allApprovedCountSheetWorkPackages.get(
          rawWorkPackage.workOrderID
        );
        if (!existingWorkPackageList) {
          existingWorkPackageList = [rawWorkPackage];
          allApprovedCountSheetWorkPackages.set(
            rawWorkPackage.workOrderID,
            existingWorkPackageList
          );
        } else {
          existingWorkPackageList.push(rawWorkPackage);
        }
      }
      this.allApprovedCountSheetWorkPackages = allApprovedCountSheetWorkPackages;
    },

    async reloadApprovedCountSheets() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      await this.loadApprovedCountSheets();

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },
    fromDateChanged(val: Date) {
      this.maxDate = DateUtil.addMonthsToDate(val, 2);
      let now = new Date();
      if (this.maxDate.getTime() > now.getTime()) this.maxDate = now;
      this.showArchivedMinDate;
    },
    toDateChanged(val: Date) {
      this.minDate = DateUtil.addMonthsToDate(val, -2);
    },

    itemExpanded(itemExpanded: { item: any; value: boolean }) {
      if (itemExpanded.value) {
        if (this.expanderExpandCount++ == 0) {
          if (this.didLoadApproved == true) {
            this.loadApprovedCountSheetWorkOrders();
            this.loadApprovedCountSheetWorkPackages();
          }
        }
      }
    },

    // *** SCAFF BADGE COUNTS ***

    workOrderRequestsBadgeCount(workOrder: WorkOrderWithAllDetails): number {
      return workOrder.scaffoldSubmittedRequestIDs?.length ?? 0;
    },

    workOrderScaffoldOtherActiveWorkOrderIDs(workOrder: WorkOrderWithAllDetails): string[] {
      let scaffoldWorkOrderIDs = workOrder.scaffoldActiveWorkOrderIDs ?? [];
      let otherWorkOrderIDs = scaffoldWorkOrderIDs.filter(x => x != workOrder.id);
      return otherWorkOrderIDs;
    },

    workOrderOtherCount(workOrder: WorkOrderWithAllDetails): number {
      return this.workOrderScaffoldOtherActiveWorkOrderIDs(workOrder).length;
    },

    workOrderCountBadgeCount(workOrder: WorkOrderWithAllDetails): number {
      return workOrder.scaffoldActiveWorkOrderIDs?.length ?? 0;
    },

    workOrderDismantleBadgeCount(workOrder: WorkOrderWithAllDetails): number {
      return workOrder.scaffoldDismantleWorkOrderIDs?.length ?? 0;
    }
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = DateUtil.addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "countsheets",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: DateUtil.addDaysToDate(toDate, -13),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      statusesForFiltering: [],
      contractorsForFiltering: [],
      areasForFiltering: [],
      subAreasForFiltering: [],
      contextForFiltering: "pending"
    });

    this.notifyNewBreadcrumb({
      text: this.$t("countsheet.list.title"),
      to: "/countsheets",
      resetHistory: true
    });

    await this.reloadTableData();

    if (!this.pendingCountSheets.length && !!this.declinedCountSheets.length)
      this.selectionType = "declined";
    if (
      !this.pendingCountSheets.length &&
      !this.declinedCountSheets.length &&
      !!this.approvedCountSheets.length
    )
      this.selectionType = "approved";
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  }
});

