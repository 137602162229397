var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-form",
    { ref: "uploadForm" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "mx-2" },
            [
              _c(
                "v-col",
                { staticClass: "pt-0", attrs: { cols: "12" } },
                [
                  _c("v-row", { staticClass: "mt-0" }, [
                    _c("span", { staticClass: "inline-extra-info-text" }, [
                      _vm._v(
                        _vm._s(_vm.$t("workpackages.bulk-upload-explanation-1"))
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("v-row", { staticClass: "mt-3" }, [
                    _c("span", { staticClass: "inline-extra-info-text" }, [
                      _vm._v(
                        _vm._s(_vm.$t("workpackages.bulk-upload-explanation-2"))
                      )
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("v-file-input", {
                staticClass: "mt-4 pr-0 pl-0",
                staticStyle: { "margin-bottom": "-10px" },
                attrs: {
                  color: "primary",
                  counter: "",
                  label: _vm.$t("common.file-upload"),
                  placeholder: _vm.$t("common.select-your-file"),
                  "prepend-icon": "mdi-paperclip",
                  outlined: "",
                  "show-size": 1000,
                  rules: [_vm.rules.required],
                  disabled: _vm.processing
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function({ index, text }) {
                      return [
                        index < 2
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "keywords-selection-chip-colors",
                                attrs: { dark: "", label: "", small: "" }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(text) +
                                    "\n          "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.file,
                  callback: function($$v) {
                    _vm.file = $$v
                  },
                  expression: "file"
                }
              }),
              _vm._v(" "),
              _c(
                "v-expansion-panels",
                { staticClass: "mt-3", attrs: { accordion: "", tile: "" } },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "common.bulk-upload-advanced-import-settings.title"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel-content",
                        {
                          staticStyle: {
                            "max-height": "230px",
                            overflow: "auto"
                          }
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "pt-0" },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mt-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "inline-extra-info-text",
                                          staticStyle: {
                                            "font-size": "0.8em",
                                            "font-weight": "bold"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "common.bulk-upload-advanced-import-settings.explanation"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "span",
                                      { staticClass: "inline-extra-info-text" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "common.bulk-upload-advanced-import-settings.identifying-column-explanation-1"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "inline-extra-info-text",
                                          staticStyle: { "font-weight": "bold" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "common.bulk-upload-advanced-import-settings.identifying-column-explanation-2"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          label: _vm.$t(
                                            "common.bulk-upload-advanced-import-settings.identifying-column"
                                          ),
                                          items:
                                            _vm.workPackagesImportColumnMapping
                                              .columnFriendlyNames
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .identifyingColumnFriendlyName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "identifyingColumnFriendlyName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.identifyingColumnFriendlyName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "inline-extra-info-text"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "common.bulk-upload-advanced-import-settings.column-mapping-explanation-1"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "inline-extra-info-text",
                                          staticStyle: { "font-weight": "bold" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "common.bulk-upload-advanced-import-settings.column-mapping-explanation-2"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("workpackages.name")
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.activity-id"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .activityID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "activityID",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.activityID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.start-date"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .startDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "startDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.startDate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.finish-date"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .finishDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "finishDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.finishDate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.completion-percentage"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .completionPercentage,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "completionPercentage",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.completionPercentage"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.work-status"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .workStatus,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "workStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.workStatus"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("workpackages.purpose")
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .purpose,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "purpose",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.purpose"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.planned-start-date"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .plannedStartDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "plannedStartDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.plannedStartDate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.planned-finish-date"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .plannedFinishDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "plannedFinishDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.plannedFinishDate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.cwp-planned-start-date"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .cwpPlannedStartDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "cwpPlannedStartDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.cwpPlannedStartDate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.cwp-planned-finish-date"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .cwpPlannedFinishDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "cwpPlannedFinishDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.cwpPlannedFinishDate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.forecast-start-date"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .forecastStartDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "forecastStartDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.forecastStartDate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.forecast-finish-date"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .forecastFinishDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "forecastFinishDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.forecastFinishDate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.discipline"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .discipline,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "discipline",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.discipline"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("workpackages.unit")
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .unit,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "unit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.unit"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.construction-work-area"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .constructionWorkArea,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "constructionWorkArea",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.constructionWorkArea"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("workpackages.area")
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .area,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "area",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.area"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.long-description"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .longDescription,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "longDescription",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.longDescription"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.description"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.description"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("workpackages.cwp")
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .cwp,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "cwp",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.cwp"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.estimated-hours"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .estimatedHours,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "estimatedHours",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.estimatedHours"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.earned-hours"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .earnedHours,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "earnedHours",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.earnedHours"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pb-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "workpackages.status-date"
                                          )
                                        },
                                        model: {
                                          value:
                                            _vm.workPackagesImportColumnMapping
                                              .statusDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.workPackagesImportColumnMapping,
                                              "statusDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPackagesImportColumnMapping.statusDate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.inlineMessage.message
                ? _c(
                    "v-col",
                    { staticClass: "pl-0 pr-0 pb-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-0",
                          attrs: { type: _vm.inlineMessage.type }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.inlineMessage.message) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !!_vm.importResults
                ? _c(
                    "v-col",
                    { staticClass: "pl-0 pr-0 pb-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        { staticClass: "mb-0", attrs: { type: "success" } },
                        [
                          _c("h3", { staticClass: "headline" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "common.bulk-upload-results.import-success"
                                  )
                                )
                              )
                            ])
                          ]),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "common.bulk-upload-results.import-results-spreadsheet-row-count"
                                )
                              ) +
                              ":\n          " +
                              _vm._s(
                                _vm.$parse.sanitizedNumber(
                                  _vm.importResults.foundRowCount
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "workpackages.import-results-updated-row-count"
                                )
                              ) +
                              ":\n          " +
                              _vm._s(
                                _vm.$parse.sanitizedNumber(
                                  _vm.importResults.updatedRowCount
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "workpackages.import-results-added-row-count"
                                )
                              ) +
                              ":\n          " +
                              _vm._s(
                                _vm.$parse.sanitizedNumber(
                                  _vm.importResults.insertedRowCount
                                )
                              )
                          ),
                          _c("br")
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pl-0 pr-0 pt-0 pb-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "mt-5 mb-0",
                      attrs: {
                        text: "",
                        color: "error",
                        value: !!_vm.returnFile,
                        transition: "scale-transition"
                      }
                    },
                    [
                      _c("h3", { staticClass: "headline" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "common.bulk-upload-results.bulk-upload-errors-during-upload-title"
                              )
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "common.bulk-upload-results.bulk-upload-errors-during-upload-inline-1"
                              )
                            )
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "common.bulk-upload-results.bulk-upload-errors-during-upload-inline-2"
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("v-divider", {
                        staticClass: "my-4",
                        staticStyle: { opacity: "0.22" },
                        attrs: { color: "error" }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { align: "center", "no-gutters": "" } },
                        [
                          _c("v-col", { staticClass: "grow pr-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "common.bulk-upload-results.bulk-upload-errors-during-upload-inline-3"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "shrink" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "black",
                                    outlined: "",
                                    loading: _vm.downloadingerrors,
                                    disabled: _vm.processing
                                  },
                                  on: { click: _vm.downloadReturnFile }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common.download")) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    disabled: _vm.processing,
                    small:
                      _vm.$vuetify.breakpoint.xsOnly &&
                      _vm.$vuetify.breakpoint.width >= 360,
                    "x-small": _vm.$vuetify.breakpoint.width < 360
                  },
                  on: {
                    click: function($event) {
                      return _vm.$props.closeAction(!!_vm.returnFile)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("common.close")))]
              ),
              _vm._v(" "),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  text: "",
                                  color: "primary",
                                  loading: _vm.downloading,
                                  disabled: _vm.processing,
                                  small:
                                    _vm.$vuetify.breakpoint.xsOnly &&
                                    _vm.$vuetify.breakpoint.width >= 360,
                                  "x-small": _vm.$vuetify.breakpoint.width < 360
                                }
                              },
                              on
                            ),
                            [_vm._v(_vm._s(_vm.$t("common.download")))]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        { on: { click: _vm.downloadTemplate } },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "common.bulk-upload-general.download-empty-template"
                                )
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    loading: _vm.uploading,
                    disabled: _vm.processing,
                    small:
                      _vm.$vuetify.breakpoint.xsOnly &&
                      _vm.$vuetify.breakpoint.width >= 360,
                    "x-small": _vm.$vuetify.breakpoint.width < 360
                  },
                  on: { click: _vm.uploadFiles }
                },
                [_vm._v(_vm._s(_vm.$t("common.upload")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }