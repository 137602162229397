import { setUpTypeService } from "../../services/index";
import { createAutomaticCrudStoreModule } from "../automatic";

const storeModule = createAutomaticCrudStoreModule({
  crudService: setUpTypeService,
  singularStoreName: "SET_UP_TYPE",
  pluralStoreName: "SET_UP_TYPES",
  localizationPrefix: "build-sheet-setup.tabs.paint.set-up-types",
  consumerRelatedIDProperty: {
    name: "setupTypeID",
    type: "string"
  }
});

export default storeModule;

