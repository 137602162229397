import FDVue from "@fd/lib/vue";

export default FDVue.extend({
  data: () => ({
    // The following tracks the current width of the browser window. It works in conjunction with a EventListener
    // setup in the "created" hook.
    windowWidth: 0,
    windowHeight: 0,
    windowScrollY: 0,

    landscapeOrientation: false
  }),

  computed: {
    // Brand Image Default Numbers
    brandImageWidth: {
      get(): number {
        return this.$store.getters.brandImageWidth;
      },
      set(val: number) {
        this.$store.commit("SET_BRAND_LOGO_WIDTH", val);
      }
    },
    brandImageWidthSmaller: {
      get(): number {
        return this.$store.getters.brandImageWidthSmaller;
      },
      set(val: number) {
        this.$store.commit("SET_BRAND_LOGO_WIDTH_SMALLER", val);
      }
    },
    brandImageHeight: {
      get(): number {
        return this.$store.getters.brandImageHeight;
      },
      set(val: number) {
        this.$store.commit("SET_BRAND_LOGO_HEIGHT", val);
      }
    },
    brandImageHeightSmaller: {
      get(): number {
        return this.$store.getters.brandImageHeightSmaller;
      },
      set(val: number) {
        this.$store.commit("SET_BRAND_LOGO_HEIGHT_SMALLER", val);
      }
    },
    logobrandImageTopValue(): number {
      //The logo image will be placed such that it's top is the height of the window minus the height of the logo
      //minus a fixed amount of 20px for aesthetic reasons and then also remove the amount of the Scroll Y offset
      // since especially on iOS devices the presence or absence of the URL bar will affect the overall height.
      return this.windowHeight - this.brandImageHeight - 20 + this.windowScrollY;
    },

    logoSource(): string {
      return this.$store.state.imageSourceLogo;
    },
    logoReversedSource(): string {
      return this.$store.state.imageSourceLogoReversed;
    },

    // Calculates whether the current theme is "venture" for spacing and theming reasons. This could be altered to something less theme specific.
    isBrandVenture(): boolean {
      return this.$store.state.themeName == "venture";
    },
    tabletMobileDevice(): boolean {
      //First check to make sure the registered width and height have a value larger than zero other wise something strange is up.
      if (this.windowWidth > 0 && this.windowHeight > 0) {
        if (this.windowWidth > this.windowHeight) {
          //Potentially a LANDSCAPE oriented tablet device
          if (this.windowWidth < 1200) {
            //Since its width is less than 1200 is LIKELY a tablet device not a web browser on a workstation.
            if (this.windowHeight / this.windowWidth < 1.4) {
              //If the ratio of width to height is here it is VERY likely to be a tablet device in landscape orientation.
              this.landscapeOrientation = true;
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          //Potentially a PORTRAIT oriented mobile device.
          if (this.windowHeight < 1200) {
            //Since its width is less than 1200 is LIKELY a mobile device not a web browser on a workstation.
            if (this.windowWidth / this.windowHeight < 1.4) {
              //If the ratio of width to height is here it is VERY likely to be a mobile device in portrait orientation.
              this.landscapeOrientation = false;
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    },

    smallishMobileDevice(): boolean {
      //First check to make sure the registered width and height have a value larger than zero other wise something strange is up.
      if (this.windowWidth > 0 && this.windowHeight > 0) {
        if (this.windowWidth > this.windowHeight) {
          //Potentially a LANDSCAPE oriented mobile device
          if (this.windowWidth < 1000) {
            //Since its width is less than 1000 is LIKELY a mobile device not a web browser on a workstation.
            if (this.windowHeight / this.windowWidth < 0.7) {
              //If the ratio of width to height is here it is VERY likely to be a mobile device in landscape orientation.
              this.landscapeOrientation = true;
              this.brandImageWidth = this.$store.getters.brandImageWidth_Medium;
              this.brandImageHeight = this.$store.getters.brandImageHeight_Medium;
              this.brandImageWidthSmaller = this.$store.getters.brandImageWidth_Medium_alt;
              this.brandImageHeightSmaller = this.$store.getters.brandImageHeight_Medium_alt;
              return true;
            } else {
              // This would indicate this is just a very thin browser window.
              if (this.windowWidth < 600) {
                this.brandImageWidth = this.$store.getters.brandImageWidth_Smallest;
                this.brandImageHeight = this.$store.getters.brandImageHeight_Smallest;
              } else {
                this.brandImageWidth = this.$store.getters.brandImageWidth_Default;
                this.brandImageHeight = this.$store.getters.brandImageHeight_Default;
              }
              return false;
            }
          } else {
            this.brandImageWidth = this.$store.getters.brandImageWidth_Default;
            this.brandImageHeight = this.$store.getters.brandImageHeight_Default;
            return false;
          }
        } else {
          //Potentially a PORTRAIT oriented mobile device.
          if (this.windowHeight < 1000) {
            //Since its width is less than 1000 is LIKELY a mobile device not a web browser on a workstation.
            if (this.windowWidth / this.windowHeight < 0.7) {
              //If the ratio of width to height is here it is VERY likely to be a mobile device in portrait orientation.
              this.landscapeOrientation = false;
              this.brandImageWidth = this.$store.getters.brandImageWidth_Smallest;
              this.brandImageHeight = this.$store.getters.brandImageHeight_Smallest;
              return true;
            } else {
              // This would indicate this is just a very thin browser window.
              if (this.windowWidth < 600) {
                this.brandImageWidth = this.$store.getters.brandImageWidth_Smallest;
                this.brandImageHeight = this.$store.getters.brandImageHeight_Smallest;
              } else {
                this.brandImageWidth = this.$store.getters.brandImageWidth_Default;
                this.brandImageHeight = this.$store.getters.brandImageHeight_Default;
              }
              return false;
            }
          } else {
            // This would indicate this is just a very thin browser window.
            if (this.windowWidth < 600) {
              this.brandImageWidth = this.$store.getters.brandImageWidth_Smallest;
              this.brandImageHeight = this.$store.getters.brandImageHeight_Smallest;
            } else {
              this.brandImageWidth = this.$store.getters.brandImageWidth_Default;
              this.brandImageHeight = this.$store.getters.brandImageHeight_Default;
            }
            return false;
          }
        }
      } else {
        this.brandImageWidth = this.$store.getters.brandImageWidth_Default;
        this.brandImageHeight = this.$store.getters.brandImageHeight_Default;
        return false;
      }
    },

    smallWidthBrowser(): boolean {
      if (this.windowWidth < 600) {
        this.brandImageWidth = this.$store.getters.brandImageWidth_Smallest;
        this.brandImageHeight = this.$store.getters.brandImageHeight_Smallest;
        return true;
      } else {
        return false;
      }
    }
  },

  methods: {
    resize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.windowScrollY = window.scrollY;
    },
    scroll() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.windowScrollY = window.scrollY;
    }
  },

  created: async function() {
    // Listen to the "resize" even for the browser so we always know the width and height and can use that
    // knowledge for various responsive layout reasons.
    // Also we will additionally track the ScrollY offset for layout and placement related purposes.
    window.addEventListener("resize", this.resize);

    // Listen to the "scroll" even for the browser so we always know the width height and can use that
    // knowledge for various responsive layout reasons.
    window.addEventListener("scroll", this.scroll);

    //Grab the original width, height and ScrollY offset and place the logo image appropriately.
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.windowScrollY = window.scrollY;
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
    window.removeEventListener("scroll", this.scroll);
  }
});

