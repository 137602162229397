import { contractorPurchaseOrderService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: contractorPurchaseOrderService,
  singularStoreName: "CONTRACTOR_PURCHASE_ORDER",
  pluralStoreName: "CONTRACTOR_PURCHASE_ORDERS",
  localizationPrefix: "contractors.purchase-orders",
  // The following is the name of the ID property used by foreign objects.
  consumerRelatedIDProperty: {
    name: "contractorPurchaseOrderID",
    type: "string"
  }
});

export default storeModule;

