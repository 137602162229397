export default function printBlob(fileContents: Blob, filename: string, mimeType: string) {
  let file = new File([fileContents], filename, {
    type: mimeType
  });
  let url = URL.createObjectURL(file);

  // Since we can't remove the frame from the document, there may already be one existing and we don't want to create and add mutliple to the document
  let frameID = "printFrame";
  let frameElement = document.getElementById(frameID) as HTMLIFrameElement;
  if (!frameElement) {
    frameElement = document.createElement("iframe");
    document.body.appendChild(frameElement);
  }
  frameElement.style.visibility = "hidden";
  frameElement.id = frameID;
  frameElement.onload = function() {
    frameElement.contentWindow!.focus();
    frameElement.contentWindow!.print();
    setTimeout(function() {
      // Ideally we would call `document.body.removeChild(frameElement);` when the printing is done
      // However the `.print()` call isn't synchronous and removing the frame from the document too early prevents the print from happening
      // `onafterprint` events don't seem to work either, so instead of removing the frame we accommodate the fact that one may already exist.
      // document.body.removeChild(frameElement);
      window.URL.revokeObjectURL(url);
    }, 0);
  };
  frameElement.src = url;
}
