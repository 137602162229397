import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { TranslateResult } from "vue-i18n";
import {
  CountSheetGroupWithParts,
  Part,
  PartWithTags,
  Yard,
  yardService,
  TransferPartWithDetails,
  TransferPartDetails,
  MaterialOrderWithDetails,
  materialOrderService,
  TransferDirection
} from "../../../services";
import {
  CountSheetGroupPartFromTransferPart,
  CountSheetGroupWithSortedParts,
  FlattenedPartsList,
  PartWithCounts,
  SummarizeModifiedPartsInGroups
} from "../../../dataMixins/countSheet";
import { SortCountSheetGroups, SortParts } from "../../../dataMixins/countSheetGroupSorting";
import { mapActions } from "vuex";
import rules from "@fd/lib/vue/rules";
import userAccess from "../../../dataMixins/userAccess";
import { openBCountBottomDialog } from "./SP.BCountBottomDialog.vue";
import fileHandling, { FileData, confirmUniqueName } from "@fd/lib/vue/mixins/fileHandling";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";

const MaterialRequestTransferNewDialog = FDVue.extend({
  name: "sp-material-request-transfer-new-dialog",
  mixins: [dialogSupport, rules, userAccess, fileHandling],

  directives: {
    fdColumn: FDColumnDirective
  },

  components: {
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue")
  },

  data: function() {
    return {
      saving: false,

      direction: TransferDirection.SupplierRequest,

      step: 1,
      lastStep: 2,

      basicStep: {
        name: this.$t("transfers.new.steps.details"),
        number: 1,
        error: false
      },
      photosStep: {
        name: this.$t("transfers.new.steps.photos"),
        number: 2,
        error: false
      },
      basicStepError: false,
      photosStepError: false,

      materialOrder: {} as MaterialOrderWithDetails,
      transferDate: new Date(),
      transactionNumber: "",
      parts: [] as TransferPartWithDetails[],
      allParts: [] as PartWithTags[],
      countSheetGroups: [] as CountSheetGroupWithSortedParts[],
      openCategoryPanels: [] as number[],
      openIndividualPanels: [] as number[],

      partSearch: "",

      /*** TO/FROM YARD ***/
      allYards: [] as Yard[],
      selectedYardID: null as string | null,

      /*** FILES ***/
      touchedFileName: "",
      tablesearchfiles: "",
      files: [] as FileData[],

      /*** IMAGE EDIT ****/
      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as FileData | undefined
    };
  },
  computed: {
    directionIsSupplierRequest(): boolean {
      return this.direction == TransferDirection.SupplierRequest;
    },
    directionIsSupplierReturn(): boolean {
      return this.direction == TransferDirection.SupplierReturn;
    },
    canOpenBCountDialog(): boolean {
      return this.partCountsAreEmpty;
    },
    partCountsAreEmpty(): boolean {
      return !this.partsForSummary.filter(x => !!x.count && x.count > 0).length;
    },
    fromYards(): (Yard & { disabled?: boolean })[] {
      return this.allYards;
    },
    toYards(): (Yard & { disabled?: boolean })[] {
      return this.allYards;
    },
    visibleCountSheetGroups(): CountSheetGroupWithSortedParts[] {
      let visibleCountSheetGroups = this.countSheetGroups
        .map(x => ({
          ...x,
          sortedParts: x.sortedParts.filter(
            p => !!this.materialOrder.parts?.find(mp => mp.partID == p.id)
          )
        }))
        .filter(x => x.sortedParts.length > 0);

      if (!this.partSearch.length) return visibleCountSheetGroups;
      else {
        let lowersearch = this.partSearch.toLowerCase();
        return visibleCountSheetGroups
          .map(x => ({
            ...x,
            sortedParts: x.sortedParts.filter(
              p =>
                p.name?.toLowerCase().includes(lowersearch) ||
                p.publicID?.toLowerCase().includes(lowersearch)
            )
          }))
          .filter(x => x.sortedParts.length > 0);
      }
    },
    unwatchedMethodNames(): string[] {
      return [
        "open",
        "fieldRefForPart",
        "selectPreviousField",
        "selectNextField",
        "enterPressed",
        "partCountValueChanged",
        "numberOfPartsSourcedByYardID"
      ];
    },
    dialogTitle(): string | TranslateResult {
      return this.$t("transfers.new.dialog-title-material-request-transfer");
    },
    partsForSummary(): PartWithCounts[] {
      return SummarizeModifiedPartsInGroups(this.countSheetGroups);
    },
    photoFiles(): FileData[] {
      return this.files.filter(x => x.isPhoto);
    }
  },
  watch: {
    transferDate() {
      this.clearParts();
    }
  },
  methods: {
    ...mapActions({
      loadCountSheetGroups: "LOAD_COUNT_SHEET_GROUPS"
    }),
    async open(materialOrder: MaterialOrderWithDetails) {
      this.materialOrder = materialOrder;

      let direction = TransferDirection.SupplierRequest;
      if (!!materialOrder.toSupplierID?.length) direction = TransferDirection.SupplierReturn;
      this.direction = direction;

      this.loadScreenData();

      this.optOutOfErrorHandling();
      return await this.showDialog();
    },
    async loadScreenData() {
      this.processing = true;
      try {
        await this.loadYards();
        await this.loadParts();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },
    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    async saveDialog() {
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (await this.saveNewTransfer()) {
          this.closeDialog!(true);
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    async saveNewTransfer(): Promise<boolean> {
      let parts = this.partsForSummary
        .filter(x => !!x.count && x.count > 0)
        .map(
          x =>
            ({
              partID: x.id,
              count: +x.count!,
              currentAssignedCount: +x.assigned
            } as TransferPartDetails)
        );
      if (parts.length == 0) {
        this.inlineMessage.message = this.$t(
          "transfers.new.part-selection-required-to-submit-message"
        );
        this.inlineMessage.type = "error";
        return false;
      }
      if (!this.selectedYardID) {
        this.inlineMessage.message = this.$t("transfers.new.destination-required-message");
        if (this.directionIsSupplierReturn) {
          this.inlineMessage.message = this.$t("transfers.new.source-required-message");
        }
        this.inlineMessage.type = "error";
        return false;
      }
      await materialOrderService.fulfillOrder(
        this.materialOrder.id!,
        this.selectedYardID,
        this.transferDate,
        this.transactionNumber,
        parts
      );

      return true;
    },

    // *** YARDS ***
    async loadYards() {
      this.allYards = (await yardService.getAll(false, null, null))
        .filter(x => !x.isSystemYard && !!x.isReceivingYard)
        .sort((a, b) => {
          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;
          else return 0;
        });
      if (this.allYards.length == 1 && !this.selectedYardID) {
        this.selectedYardID = this.allYards[0].id!;
      }
    },

    // *** PARTS & COUNT SHEET ***
    async clearPartCounts() {
      this.partsForSummary.forEach(p => {
        p.count = 0;
        this.partCountValueChanged(p);
      });
    },
    /// Clears and rebuilds all count sheet groups, and reloads part quantities for yards
    async clearParts() {
      this.inlineMessage.message = "";

      this.parts = this.allParts.map(
        x =>
          ({
            name: x.name,
            description: x.description,
            publicID: x.publicID,
            countSheetGroupID: x.countSheetGroupID,
            partID: x.id,
            count: 0,
            currentAssignedCount: this.materialOrder.parts?.find(p => p.partID == x.id)?.outstanding
          } as TransferPartWithDetails)
      );
      let countSheetGroups = SortCountSheetGroups(
        (this.$store.state.countSheetGroups.fullList as CountSheetGroupWithParts[])
          .filter(group => !!group.parts?.length)
          .map(
            group =>
              ({
                ...group,
                sortedParts: SortParts(
                  group.parts?.map(part => CountSheetGroupPartFromTransferPart(part, this.parts))
                )
              } as CountSheetGroupWithSortedParts)
          )
      );

      let ungroupedPartsWithDetails = this.parts.filter(x => !x.countSheetGroupID);
      if (!!ungroupedPartsWithDetails?.length) {
        let ungroupedParts = ungroupedPartsWithDetails.map(
          x =>
            ({
              id: x.partID,
              name: x.name,
              description: x.description,
              publicID: x.publicID
            } as Part)
        ) as Part[];
        let ungroupedGroup = {
          name: `${this.$t("common.other")}`,
          order: 999,
          parts: ungroupedParts
        } as CountSheetGroupWithParts;

        let ungroupedGroupWithSortedParts = {
          ...ungroupedGroup,
          sortedParts: SortParts(
            ungroupedGroup.parts?.map(part => CountSheetGroupPartFromTransferPart(part, this.parts))
          )
        } as CountSheetGroupWithSortedParts;

        countSheetGroups.push(ungroupedGroupWithSortedParts);
      }

      countSheetGroups.forEach(x => (x.parts = SortParts(x.parts)));

      this.countSheetGroups = countSheetGroups;
    },
    async loadParts() {
      await this.$store.dispatch("LOAD_PARTS");
      this.allParts = this.$store.state.parts.fullList as PartWithTags[];
      // this.allParts = await partService.getAll(false, null, null);

      await this.loadCountSheetGroups({
        forcedArchivedState: false,
        archivedFromDate: null,
        archivedToDate: null
      });
      await this.clearParts();
    },

    // *** SCREEN NAVIGATION ***
    partWithID(id: string): PartWithCounts | undefined {
      return FlattenedPartsList(this.countSheetGroups).find(x => x.id == id);
    },
    async openBCountDialog() {
      let partCounts = await openBCountBottomDialog(this.$refs.content as Vue);
      if (!partCounts?.length) return;

      partCounts.forEach(p => {
        let part = this.partWithID(p.partID);
        if (!!part) {
          part.count = p.quantity;
          this.partCountValueChanged(part);
        }
      });
      if (!this.openCategoryPanels.includes(0)) this.openCategoryPanels.push(0);
    },
    partCountValueChanged(part: PartWithCounts) {
      let count = !!part.count ? +part.count : 0;
      part.total = part.assigned - count;
    },
    fieldRefForPart(fieldName: string, part: PartWithCounts) {
      let overrideRef = part.overridden == true ? "override" : "";

      let id = part.id!.replace("-", "").replace("-", "");
      return `${fieldName}${overrideRef}_${id}`;
    },
    focusFieldForVisibleItemAtIndex(fieldName: string, groupNumber: number, partIndex: number) {
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.order == groupNumber
      );
      if (!group) return;
      let sortedGroupParts = group.sortedParts;
      if (!sortedGroupParts.length) return;

      let groupPanelNumber = this.countSheetGroups.indexOf(group!);
      if (!this.openIndividualPanels.includes(groupPanelNumber)) {
        this.openIndividualPanels.push(groupPanelNumber);
        let self = this;

        this.$nextTick(() => {
          setTimeout(() => {
            self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, partIndex);
          }, 500);
        });
        return;
      }

      if (partIndex < 0) partIndex = 0;
      if (partIndex >= sortedGroupParts.length) partIndex = sortedGroupParts.length - 1;
      let item = sortedGroupParts[partIndex];

      let itemFieldRef = this.fieldRefForPart(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    selectPreviousField(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      let groupID = item.countSheetGroupID;
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.id == groupID
      );
      if (!group) return;

      let groupNumber = group?.order ?? 0;
      let sortedGroupParts = group?.sortedParts ?? [];
      // console.log(`  current group: ${group?.name}, parts: ${sortedGroupParts.length}`);
      if (!sortedGroupParts.length) return;

      let currentItemIndex = sortedGroupParts.indexOf(item);
      if (currentItemIndex <= 0) {
        if (groupNumber <= 1) return;
        groupNumber -= 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, 999);
        });
        e.preventDefault();
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, previousIndex);
      e.preventDefault();
    },
    selectNextField(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      // console.log(`selectNextField fieldName: ${fieldName}, part: ${item.publicID}`);
      let groupID = item.countSheetGroupID;
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.id == groupID
      );
      let groupNumber = group?.order ?? 0;
      let sortedGroupParts = group?.sortedParts ?? [];
      // console.log(`  current group: ${group?.name}, parts: ${sortedGroupParts.length}`);
      if (!sortedGroupParts.length) return;

      let currentItemIndex = sortedGroupParts.indexOf(item);
      if (currentItemIndex >= sortedGroupParts.length - 1) {
        groupNumber += 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, 0);
        });
        e.preventDefault();
        return;
      }
      let nextIndex = currentItemIndex + 1;
      // console.log(`  current: ${currentItemIndex}, next: ${nextIndex}`);
      this.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, nextIndex);
      e.preventDefault();
    },
    enterPressed(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      if (e.shiftKey) this.selectPreviousField(e, fieldName, item);
      else this.selectNextField(e, fieldName, item);
    },

    // *** FILES ***
    async selectNewFile(originalFile: any) {
      var fileData = await this.optimizedFileDataForUpload(originalFile, this.files);
      if (!fileData) return;

      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.files.push(fileData);
      }
    },
    editFile(fileData: FileData) {
      if (!fileData.isPreviewable) return;

      this.editingFileData = fileData;
      this.imageName = fileData.name;
      this.editImageSource = this.covertFileToDataURL(fileData.file);
    },
    async handleEdit(res: File, fileName: string | undefined) {
      console.log(`handleEdit fileName: ${fileName}`);

      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;

        this.files.push(this.newFileData);

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.files.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.editingFileData = undefined;
      }
    },
    viewFile(fileData: FileData) {
      this.imageName = fileData.name;
      this.imageSource = this.covertFileToDataURL(fileData.file);
    },
    removeFile(fileData: FileData) {
      var fileIndex = this.files.indexOf(fileData);
      if (fileIndex == undefined) return;

      this.files.splice(fileIndex, 1);
    }
  }
});

export default MaterialRequestTransferNewDialog;

export async function showMaterialRequestTransferNewDialog(
  materialOrder: MaterialOrderWithDetails
): Promise<boolean> {
  let dialog = createDialog(MaterialRequestTransferNewDialog);
  return await dialog.open(materialOrder);
}

