import FDVue from "@fd/lib/vue";
import { PropType } from "vue";
import { EstimateTotalTimes, PartGenerationType, WorkOrderEstimate } from "../../../services";
import { TranslateResult } from "vue-i18n";

type WorkOrderEstimateWithDetails = WorkOrderEstimate & {
  generationTypeName: string;
  generationMethodName: string;
  dateString: string;
  bayLengthName: string;
  lengthBayCount: number;
  bayWidthName: string;
  widthBayCount: number;
  bayHeightName: string;

  estimatedTotalTime: number;
  estimatedErectMPP: number;
  estimatedDismantleMPP: number;

  scaffoldTypeModifierName: string;
  scaffoldDistanceModifierName: string;
  scaffoldElevationModifierName: string;
  scaffoldHeightModifierName: string;
  scaffoldCongestionFactorName: string;
  buildDismantleRatioName: string;
  internalModifierName: string;
  hoardingModifierName: string;
};

/** <summary>
The number value of the summary time unit variable.
The value is divided into the estimated times to give either minutes (default value) or hours (time / 60)
</summary>*/
type SummaryPanelTimeUnitOption = 1 | 60;

export default FDVue.extend({
  props: {
    currentEstimate: { type: Object as PropType<WorkOrderEstimateWithDetails | undefined> },
    workOrderActualWorkedTimes: {
      type: Object
    },
    workOrderActualsEstimatedTimes: {
      type: Object
    },
    currentEstimateTimeTotals: {
      type: Object as PropType<EstimateTotalTimes>
    },
    loading: { type: Boolean, default: false },
    showActuals: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      summaryPanelTimeUnitDivider: 60 as SummaryPanelTimeUnitOption
    };
  },
  computed: {
    summaryPanelTimeUnit(): string | TranslateResult {
      if (this.summaryPanelTimeUnitDivider == 60) return this.$t("common.hours");

      return this.$t("common.minutes-short");
    },
    currentEstimateIsLWH(): boolean {
      return this.currentEstimate?.generationTypeID == PartGenerationType.LWH;
    },
    currentEstimateLWHSummary(): string {
      var parts = [] as string[];
      parts.push((this.currentEstimate?.length ?? 0).toString());
      parts.push((this.currentEstimate?.width ?? 0).toString());
      parts.push((this.currentEstimate?.height ?? 0).toString());
      return parts.join(" x ");
    }
  },
  methods: {
    getTimeDisplay(
      minutes: string | number | undefined | null,
      digits: number = 2,
      allowZero: boolean = false
    ) {
      if (minutes == undefined || minutes == null) return undefined;
      let val = Number(minutes);
      if (isNaN(val)) return undefined;

      let time = val / this.summaryPanelTimeUnitDivider;
      return this.$format.number(time, digits, allowZero);
    }
  }
});

