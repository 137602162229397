var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-navigation-drawer",
    {
      staticStyle: { "max-height": "90%", "z-index": "20" },
      attrs: {
        bottom: "",
        "mobile-breakpoint": "99999",
        absolute: "",
        height: "auto"
      },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "bottom-sheet-control",
          attrs: {
            flat: "",
            tile: "",
            "data-cy": "bcountpartslistbottomdialog"
          }
        },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "work-order-estimates.new-estimate.build-bcount-dialog-title"
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:publicID",
                value: _vm.$t("parts.public-id"),
                expression: "$t('parts.public-id')",
                arg: "publicID"
              },
              { name: "fd-column", rawName: "v-fd-column:name", arg: "name" },
              {
                name: "fd-column",
                rawName: "v-fd-column:description.hide-when-extra-small",
                arg: "description",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:quantity",
                arg: "quantity"
              }
            ],
            staticClass: "fixed-header v-table__overflow",
            staticStyle: {
              "max-height": "250px",
              "backface-visibility": "hidden"
            },
            attrs: {
              dense: "",
              "items-per-page": -1,
              "footer-props": { "items-per-page-options": [-1] },
              "hide-default-footer": "",
              items: _vm.parts,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "mobile-breakpoint": "0"
            },
            scopedSlots: _vm._u([
              {
                key: "item.quantity",
                fn: function({ item }) {
                  return [
                    _c("fd-text-field", {
                      ref: _vm.getFieldRef("quantity", item),
                      staticClass: "fd-short-table-input",
                      attrs: {
                        "data-id": item.id,
                        "data-cy": "quantity",
                        disabled: _vm.processing,
                        numeric: "",
                        outlined: false,
                        "highlight-on-focus": ""
                      },
                      on: {
                        keydown: [
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp"
                              ])
                            )
                              return null
                            return _vm.selectPreviousField("quantity", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown"
                              ])
                            )
                              return null
                            return _vm.selectNextField("quantity", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return (e =>
                              _vm.enterPressed(e, "quantity", item)).apply(
                              null,
                              arguments
                            )
                          }
                        ]
                      },
                      model: {
                        value: item.quantity,
                        callback: function($$v) {
                          _vm.$set(item, "quantity", $$v)
                        },
                        expression: "item.quantity"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm.inlineMessage.message
            ? _c(
                "v-row",
                { staticClass: "mx-0", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.inlineMessage.message
                        ? _c(
                            "v-alert",
                            { attrs: { type: _vm.inlineMessage.type } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "white-space": "pre-line" } },
                                [_vm._v(_vm._s(_vm.inlineMessage.message))]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: _vm.$vuetify.breakpoint.xsOnly },
                  on: { click: _vm.cancelDialog }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.cancel")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "save",
                    color: "primary",
                    disabled: _vm.processing,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.saveDialog }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.done")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }