import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { PartWithTags, SupplierWithTags } from "../../../services";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";

type PartWithTagsAndSelected = PartWithTags & { selected: boolean };

const SupplierNewDialog = FDVue.extend({
  name: "fd-supplier-new-dialog",

  mixins: [dialogSupport, errorHandling, rules],
  directives: {
    fdColumn: FDColumnDirective
  },

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue")
  },

  data: function() {
    return {
      saving: false,
      step: 1,
      lastStep: 2,

      detailsStep: 1,
      partSelectionStep: 2,
      detailserror: false,
      partserror: false,

      supplier: {
        name: "",
        alias: "",
        description: ""
      } as SupplierWithTags,

      selectedTags: [] as any[],

      // *** PARTS ***
      showOnlyIncludedParts: false,
      partsTableSearch: "",
      selectableParts: [] as Array<PartWithTagsAndSelected>
    };
  },

  computed: {
    availableTags(): any[] {
      return this.$store.getters.sortedEnabledTags;
    },

    parts(): Array<PartWithTagsAndSelected> {
      let returnValue = this.selectableParts;
      if (this.showOnlyIncludedParts) returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedPartIDs(): string[] {
      return this.selectableParts.filter(x => x.selected).map(x => x.id!);
    },

    searchedParts(): Array<PartWithTagsAndSelected> {
      // This is a hack because the parts list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .partsDataTable as any)?.customFilter;
      if (this.partsTableSearch && customFilter) {
        return this.parts.filter(
          x =>
            customFilter(x.name!, this.partsTableSearch, x) ||
            customFilter(x.description!, this.partsTableSearch, x)
        );
      } else {
        return this.parts;
      }
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedPartsSelected(): boolean {
      return this.searchedParts.findIndex(x => !x.selected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedPartsSelected(): boolean {
      var searchedParts = this.searchedParts;
      return (
        searchedParts.findIndex(x => x.selected) !== -1 &&
        searchedParts.findIndex(x => !x.selected) !== -1
      );
    }
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        this.supplier.partIDs = this.selectedPartIDs;
        await this.addSupplier({
          ...this.supplier,
          tagIDs: this.selectedTags.length > 0 ? this.selectedTags.map(x => x.id) : null
        });
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    ...mapActions({
      addSupplier: "ADD_SUPPLIER",
      loadTags: "LOAD_TAGS",
      loadParts: "LOAD_PARTS"
    }),

    // *** PARTS
    flipPartSelected(item: PartWithTagsAndSelected & { selected: boolean }) {
      item.selected = !item.selected;
    },

    flipSearchedPartselected() {
      let selected = !this.allSearchedPartsSelected;
      let changedPartIDs = [] as string[];
      for (let part of this.searchedParts) {
        if (part.selected !== selected) {
          part.selected = selected;
          changedPartIDs.push(part.id!);
        }
      }
    }
  },

  created: async function() {
    this.processing = true;
    try {
      await Promise.all([this.loadTags(), this.loadParts()]);

      // *** PARTS ***
      this.selectableParts = this.$store.state.parts.fullList.map((x: PartWithTags) => ({
        ...x,
        selected: false
      }));
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

export default SupplierNewDialog;

export async function createNewSupplier(): Promise<boolean> {
  let dialog = createDialog(SupplierNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.showDialog!();
}

