import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import fileHandling, {
  FileData,
  canOpenFileInNewWindow,
  componentsFromFileName,
  confirmUniqueName
} from "@fd/lib/vue/mixins/fileHandling";
import {
  ExternalLink,
  Person,
  ProjectLocation,
  ScaffoldDesignWithDetails,
  Tag,
  externalLinkService,
  personService,
  projectLocationService,
  scaffoldDesignService
} from "../../../services";
import userAccess from "../../../dataMixins/userAccess";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";
import { Attachment } from "../../../dataMixins/attachment";
import { openExternalLinkDetails } from "../ExternalLinkDialog.vue";
import { showTextPromptDialog } from "../../../../../common/client/views/components/TextPromptDialog.vue";
import rules from "../../../../../lib/vue/rules";
import { GetPersonName, HasName, SortItemsWithName } from "../../../utils/person";

type Keyword = Tag;

const ScaffoldDesignNewDialog = FDVue.extend({
  name: "fd-scaffold-design-new-dialog",

  mixins: [dialogSupport, fileHandling, userAccess, rules],

  directives: {
    fdColumn: FDColumnDirective
  },

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue")
  },

  data: function() {
    return {
      saving: false,

      /*** GLOBAL ****/
      step: 1,
      lastStep: 3,

      basicStep: 1,
      photosStep: 2,
      attachmentsStep: 3,

      // Form data errors
      basicStepError: false,
      photosStepError: false,
      attachmentsStepError: false,

      /*** DATA ***/
      // Reference
      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],
      allDesigners: [] as Person[],
      allReviewers: [] as Person[],
      allManagers: [] as Person[],

      scaffoldDesign: {} as ScaffoldDesignWithDetails,

      /*** KEYWORDS ***/
      selectedKeywords: [] as Keyword[],

      // *** FILES ***
      touchedFileName: "",
      showPhotoTabAttachmentAlert: false,
      showAttachmentTabPhotoAlert: false,
      tablesearchfiles: "",
      allFiles: [] as FileData[],
      externalLinks: [] as ExternalLink[],

      /*** IMAGE EDIT ****/
      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as FileData | undefined
    };
  },

  computed: {
    scaffoldDesignRules() {
      return {
        areaID: [this.rules.required],
        subAreaID: [this.rules.required],
        siteContact: [],
        specificWorkLocation: [],
        detailedWorkDescription: []
      };
    },
    availableKeywords(): Keyword[] {
      return this.$store.getters.sortedEnabledTags;
    },
    areas(): ProjectLocation[] {
      var areas = this.allAreas;
      if (!this.scaffoldDesign.areaID && areas.length == 1) {
        this.$nextTick(() => {
          this.scaffoldDesign.areaID = areas[0].id;
        });
      }
      return areas;
    },
    subAreas(): ProjectLocation[] {
      let areaID = this.scaffoldDesign.areaID;
      if (!areaID) return [];

      var subAreas = this.allSubAreas.filter(x => {
        return x.parentLocationID == areaID;
      });

      if (!this.scaffoldDesign.subAreaID && subAreas.length == 1) {
        this.$nextTick(() => {
          this.scaffoldDesign.subAreaID = subAreas[0].id;
        });
      }

      return subAreas;
    },
    nonPhotoAttachments(): Attachment[] {
      let attachments = [] as Attachment[];

      this.allFiles.forEach(file => {
        attachments.push({
          type: "file",
          name: file.name,
          isPhoto: file.isPreviewable ?? false,
          isPreviewable: file.isPreviewable ?? false,
          canOpenInNew: canOpenFileInNewWindow(file.name),
          file: file
        });
      });

      this.externalLinks.forEach(link => {
        attachments.push({
          type: "link",
          name: link.name!,
          isPhoto: false,
          isPreviewable: false,
          canOpenInNew: true,
          link: link
        });
      });

      return attachments.filter(x => !x.isPhoto);
    },
    photoFiles(): FileData[] {
      return this.allFiles.filter(x => x.isPhoto);
    }
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    async open() {
      this.optOutOfErrorHandling();
      this.loadData();
      return await this.showDialog!();
    },

    async loadPeople() {
      var allPeople = SortItemsWithName(
        (await personService.getAll(false, null, null)).map(
          p =>
            ({
              ...p,
              name: GetPersonName(p)
            } as Person & HasName)
        )
      );
      this.allDesigners = allPeople.filter(x => !!x.isDesigner);
      this.allReviewers = allPeople.filter(x => !!x.isDesigner || !!x.isDesignManager);
      this.allManagers = allPeople.filter(x => !!x.isDesignManager);
    },
    async loadData() {
      this.optOutOfErrorHandling();

      this.processing = true;

      try {
        await Promise.all([
          this.loadPeople(),
          this.loadAreas(),
          this.loadSubAreas(),
          this.$store.dispatch("LOAD_TAGS")
        ]);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allAreas = SortItemsWithName(areas);
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allSubAreas = SortItemsWithName(subAreas);
    },

    async cancelDialog() {
      this.closeDialog!(false);
    },
    async saveDialog() {
      this.inlineMessage.message = "";

      this.processing = true;
      this.saving = true;
      try {
        this.scaffoldDesign.coordX = isNaN(Number(this.scaffoldDesign.coordX))
          ? null
          : Number(this.scaffoldDesign.coordX);
        this.scaffoldDesign.coordY = isNaN(Number(this.scaffoldDesign.coordY))
          ? null
          : Number(this.scaffoldDesign.coordY);
        this.scaffoldDesign.coordZ = isNaN(Number(this.scaffoldDesign.coordZ))
          ? null
          : Number(this.scaffoldDesign.coordZ);
        let tagIDs =
          this.selectedKeywords.length > 0 ? this.selectedKeywords.map(x => x.id!) : undefined;

        let newID = await scaffoldDesignService.addItem({
          ...this.scaffoldDesign,
          tagIDs: tagIDs
        } as ScaffoldDesignWithDetails);
        if (!!newID?.length) {
          if (!!this.allFiles.length) {
            for (let file of this.allFiles) {
              await scaffoldDesignService.uploadScaffoldDesignFile(
                newID,
                file.name,
                file.file as Blob
              );
            }
          }
          if (this.externalLinks.length) {
            for (let newLink of this.externalLinks) {
              await externalLinkService.addItem({
                scaffoldDesignID: newID,
                name: newLink.name,
                address: newLink.address
              } as ExternalLink);
            }
          }
        }

        var snackbarPayload = {
          text: this.$t("scaffold-designs.new.save-success"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.saving = false;
        this.processing = false;
      }
    },

    // *** ATTACHMENTS ***
    // Attachments - Catch the generic "Attachment" objects and pass along to link or file-specific actions
    async openAttachment(item: Attachment) {
      if (!item.canOpenInNew) return;

      if (!!item.file && item.canOpenInNew) {
        await this.openFileInNewWindow(item.file);
      } else if (!!item.link) {
        let url = item.link.address;
        window.open(url, "_blank");
      }
    },
    async editAttachment(item: Attachment) {
      if (!!item.link) {
        await this.editLink(item.link);
      } else if (!!item.file && item.file.isPreviewable) {
        await this.editFile(item.file);
      } else if (!!item.file) {
        await this.editNameForFile(item.file);
      }
    },
    async deleteAttachment(item: Attachment) {
      if (!!item.link) {
        await this.removeLink(item.link);
      } else if (!!item.file) {
        await this.removeFile(item.file);
      }
    },

    // Links
    // Method to open the dialog for when the user wishes to add a new External Link.
    async openNewExternalLinkDialog() {
      let newLink = await openExternalLinkDetails();
      if (!!newLink) {
        await this.addNewExternalLink(newLink);
      }
    },
    async addNewExternalLink(newLink: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        this.externalLinks.push(newLink);

        this.showAttachmentTabPhotoAlert = false;
        this.showPhotoTabAttachmentAlert = false;

        this.touchedFileName = newLink.name ?? "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    async editLink(link: ExternalLink) {
      let editedLink = await openExternalLinkDetails(link);
      if (!!editedLink) {
        link.address = editedLink.address;
        link.name = editedLink.name;
      }
    },
    async removeLink(link: ExternalLink) {
      this.externalLinks.splice(this.externalLinks.indexOf(link), 1);
      this.touchedFileName = link.name ?? "";
    },

    // *** FILES ***
    async openFileInNewWindow(fileData: FileData) {
      let currentProcessing = this.processing;
      this.processing = true;
      if (!fileData.file) {
        return;
      }
      let url = URL.createObjectURL(fileData.file);
      window.open(url, "_blank");
      this.processing = currentProcessing;
    },
    async selectFile() {
      (this.$refs.addFileButton as any).click();
    },
    async selectNewFile(originalFile: File) {
      this.processing = true;
      var fileData = await this.optimizedFileDataForUpload(originalFile, this.allFiles);
      this.processing = false;
      if (!fileData) return;

      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.allFiles.push(fileData);
        this.touchedFileName = fileData.name;
        this.showPhotoTabAttachmentAlert = this.step == this.photosStep && !fileData.isPhoto;
        this.showAttachmentTabPhotoAlert =
          this.step == this.attachmentsStep && fileData.isPhoto == true;
      }
    },
    async editNameForFile(fileData: FileData) {
      let components = componentsFromFileName(fileData.name);
      let newName = await showTextPromptDialog({
        title: this.$t("attachments.edit-file-name-title"),
        label: this.$t("common.name"),
        rules: [this.rules.required],
        text: components.name
      });
      if (!!newName?.length && newName.toLowerCase() != components.name.toLowerCase()) {
        let newFileName = `${newName}.${components.extension}`;
        var originalFileName = fileData.name;
        if (newFileName.toLowerCase() == originalFileName.toLowerCase()) return;

        var uniqueFileName = confirmUniqueName(newFileName, this.allFiles);

        fileData.name = uniqueFileName;

        this.editingFileData = undefined;
      }
    },
    editFile(fileData: FileData) {
      if (!fileData.isPreviewable) return;

      this.editingFileData = fileData;
      this.imageName = fileData.name;
      this.editImageSource = this.covertFileToDataURL(fileData.file);
    },
    async handleEdit(res: File, fileName: string | undefined) {
      console.log(`handleEdit fileName: ${fileName}`);

      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;

        this.allFiles.push(this.newFileData);

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.allFiles.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.editingFileData = undefined;
      }
    },
    viewFile(fileData: FileData) {
      this.imageName = fileData.name;
      this.imageSource = this.covertFileToDataURL(fileData.file);
    },
    removeFile(fileData: FileData) {
      var fileIndex = this.allFiles.indexOf(fileData);
      if (fileIndex == undefined) return;

      this.allFiles.splice(fileIndex, 1);
    }
  },

  created: async function() {}
});

export default ScaffoldDesignNewDialog;
export async function createNewScaffoldDesign(options?: {}): Promise<boolean> {
  let dialog = createDialog(ScaffoldDesignNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open();
}

