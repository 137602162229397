import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";

const WorkPackageNewDialog = FDVue.extend({
  name: "fd-work-package-base-dialog",
  components: {
    "fd-work-package-bulk-upload-form": () => import("./WorkPackageBulkUploadForm.vue"),
    "fd-work-package-new-form": () => import("./WorkPackageNewForm.vue")
  },
  mixins: [dialogSupport],
  data: () => ({
    // Variable to hold which expansion panel is currently open
    panel: 0
  }),
  methods: {
    processingUpdated(processing: boolean) {
      this.processing = processing;
    }
  },
  created: async function() {}
});

export default WorkPackageNewDialog;

export async function createNewWorkPackageDialog(): Promise<boolean> {
  let dialog = createDialog(WorkPackageNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.showDialog!();
}
