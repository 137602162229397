import { hoardingModifierService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: hoardingModifierService,
  singularStoreName: "HOARDING_MODIFIER",
  pluralStoreName: "HOARDING_MODIFIERS",
  localizationPrefix: "modifiers.hoarding",
  consumerRelatedIDProperty: {
    name: "hoardingModifierID",
    type: "string"
  }
});

export default storeModule;

