var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "partexisting" } },
    [
      !_vm.currentUserCanConfigureSettings
        ? _c("v-alert", { staticClass: "mt-0 mb-3", attrs: { type: "info" } }, [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("parts.no-permission-to-edit")) + "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: item =>
                                        _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || `/parts/`
                                        )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "mx-0 px-2 fd-inline-request-tags-qualifier",
                          attrs: { align: "center" }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c("fd-chip-selector", {
                                attrs: {
                                  "available-items": _vm.availableTags,
                                  "selected-items": _vm.selectedTags,
                                  "item-label": "name",
                                  disabled: _vm.processing,
                                  "control-label": _vm.$t("common.add-tags"),
                                  "chip-class":
                                    "keywords-selection-chip-colors",
                                  outlined: false
                                },
                                on: {
                                  "update:selectedItems": function($event) {
                                    _vm.selectedTags = $event
                                  },
                                  "update:selected-items": function($event) {
                                    _vm.selectedTags = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !!_vm.availableSuppliers &&
                      _vm.availableSuppliers.length > 1
                        ? _c(
                            "v-row",
                            {
                              staticClass:
                                "mx-0 px-2 fd-inline-request-tags-qualifier",
                              staticStyle: { "margin-top": "0 !important" },
                              attrs: { align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c("fd-chip-selector", {
                                    attrs: {
                                      "available-items": _vm.availableSuppliers,
                                      "selected-items": _vm.selectedSuppliers,
                                      "item-label": "alias",
                                      disabled: _vm.processing,
                                      "control-label": _vm.$t("parts.supplier"),
                                      "chip-class":
                                        "keywords-selection-chip-colors-secondary",
                                      outlined: false
                                    },
                                    on: {
                                      "update:selectedItems": function($event) {
                                        _vm.selectedSuppliers = $event
                                      },
                                      "update:selected-items": function(
                                        $event
                                      ) {
                                        _vm.selectedSuppliers = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-2 pt-5",
                          attrs: { align: "center" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "6" }
                            },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  label: _vm.$t("common.name"),
                                  rules: _vm.partRules.name,
                                  disabled: _vm.processing,
                                  dense: _vm.$vuetify.breakpoint.xsOnly
                                },
                                model: {
                                  value: _vm.part.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.part, "name", $$v)
                                  },
                                  expression: "part.name"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "6" }
                            },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  label: _vm.$t("parts.public-id"),
                                  rules: _vm.partRules.publicID,
                                  disabled: _vm.processing,
                                  dense: _vm.$vuetify.breakpoint.xsOnly
                                },
                                model: {
                                  value: _vm.part.publicID,
                                  callback: function($$v) {
                                    _vm.$set(_vm.part, "publicID", $$v)
                                  },
                                  expression: "part.publicID"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "6" }
                            },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  label: _vm.$t("common.description"),
                                  rules: _vm.partRules.description,
                                  disabled: _vm.processing,
                                  dense: _vm.$vuetify.breakpoint.xsOnly
                                },
                                model: {
                                  value: _vm.part.description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.part, "description", $$v)
                                  },
                                  expression: "part.description"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "6" }
                            },
                            [
                              _c("fd-text-field", {
                                attrs: {
                                  label: _vm.$t("parts.weightkg"),
                                  rules: _vm.partRules.weight,
                                  disabled: _vm.processing,
                                  dense: _vm.$vuetify.breakpoint.xsOnly
                                },
                                model: {
                                  value: _vm.part.weight,
                                  callback: function($$v) {
                                    _vm.$set(_vm.part, "weight", _vm._n($$v))
                                  },
                                  expression: "part.weight"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.currentUserCanConfigurePrivateSettings
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mr-0 ml-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("parts.mpp"),
                                          rules: _vm.partRules.mpp,
                                          disabled:
                                            _vm.processing ||
                                            !_vm.currentUserCanConfigurePrivateSettings,
                                          dense: _vm.$vuetify.breakpoint.xsOnly
                                        },
                                        model: {
                                          value: _vm.part.mpp,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.part,
                                              "mpp",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "part.mpp"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentUserCanConfigurePrivateSettings
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mr-0 ml-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("parts.cleating"),
                                          rules: _vm.partRules.cleatingMPP,
                                          disabled:
                                            _vm.processing ||
                                            !_vm.currentUserCanConfigurePrivateSettings,
                                          dense: _vm.$vuetify.breakpoint.xsOnly
                                        },
                                        model: {
                                          value: _vm.part.cleatingMPP,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.part,
                                              "cleatingMPP",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "part.cleatingMPP"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentUserCanConfigurePrivateSettings
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mr-0 ml-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("parts.lashing"),
                                          rules: _vm.partRules.lashingMPP,
                                          disabled:
                                            _vm.processing ||
                                            !_vm.currentUserCanConfigurePrivateSettings,
                                          dense: _vm.$vuetify.breakpoint.xsOnly
                                        },
                                        model: {
                                          value: _vm.part.lashingMPP,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.part,
                                              "lashingMPP",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "part.lashingMPP"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentUserCanConfigurePrivateSettings
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mr-0 ml-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("parts.carpentry"),
                                          rules: _vm.partRules.carpentryMPP,
                                          disabled:
                                            _vm.processing ||
                                            !_vm.currentUserCanConfigurePrivateSettings,
                                          dense: _vm.$vuetify.breakpoint.xsOnly
                                        },
                                        model: {
                                          value: _vm.part.carpentryMPP,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.part,
                                              "carpentryMPP",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "part.carpentryMPP"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentUserCanConfigurePrivateSettings
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between pt-1 pb-1",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mr-0 ml-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("parts.other"),
                                          rules: _vm.partRules.otherMPP,
                                          disabled:
                                            _vm.processing ||
                                            !_vm.currentUserCanConfigurePrivateSettings,
                                          dense: _vm.$vuetify.breakpoint.xsOnly
                                        },
                                        model: {
                                          value: _vm.part.otherMPP,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.part,
                                              "otherMPP",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "part.otherMPP"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("v-col", {
                            staticClass:
                              "align-center justify-space-between pt-1 pb-1",
                            attrs: { cols: "0", sm: "0", md: "6" }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "6" }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mr-0 ml-0",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      label: _vm.$t("parts.designation"),
                                      rules: _vm.partRules.designation,
                                      disabled: _vm.processing,
                                      dense: _vm.$vuetify.breakpoint.xsOnly
                                    },
                                    model: {
                                      value: _vm.part.designation,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.part,
                                          "designation",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "part.designation"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "6" }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mr-0 ml-0",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      label: _vm.$t("parts.rental-rate"),
                                      rules: _vm.partRules.rentalRate,
                                      disabled: _vm.processing,
                                      dense: _vm.$vuetify.breakpoint.xsOnly
                                    },
                                    model: {
                                      value: _vm.part.rentalRate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.part,
                                          "rentalRate",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "part.rentalRate"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "6" }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mr-0 ml-0",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      label: _vm.$t("parts.cost-used"),
                                      rules: _vm.partRules.costUsed,
                                      disabled: _vm.processing,
                                      dense: _vm.$vuetify.breakpoint.xsOnly
                                    },
                                    model: {
                                      value: _vm.part.costUsed,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.part,
                                          "costUsed",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "part.costUsed"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12", sm: "6" }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mr-0 ml-0",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      label: _vm.$t("parts.cost-new"),
                                      rules: _vm.partRules.costNew,
                                      disabled: _vm.processing,
                                      dense: _vm.$vuetify.breakpoint.xsOnly
                                    },
                                    model: {
                                      value: _vm.part.costNew,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.part,
                                          "costNew",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "part.costNew"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-1 pb-1",
                              attrs: { align: "center", cols: "12" }
                            },
                            [
                              _c("v-switch", {
                                attrs: {
                                  "data-cy": "archive",
                                  label: _vm.$t("common.archived"),
                                  disabled: _vm.processing
                                },
                                model: {
                                  value: _vm.part.isArchived,
                                  callback: function($$v) {
                                    _vm.$set(_vm.part, "isArchived", $$v)
                                  },
                                  expression: "part.isArchived"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0",
                              attrs: { cols: "12", sm: "12" }
                            },
                            [
                              _c("v-subheader", { staticClass: "pl-0" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("common.extended-description"))
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-1",
                              attrs: { cols: "12", sm: "12" }
                            },
                            [
                              _c("fd-rich-textarea", {
                                attrs: {
                                  disabled: _vm.processing,
                                  label: "",
                                  allowImages: false,
                                  rules: _vm.partRules.longDescription
                                },
                                model: {
                                  value: _vm.part.longDescription,
                                  callback: function($$v) {
                                    _vm.$set(_vm.part, "longDescription", $$v)
                                  },
                                  expression: "part.longDescription"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { align: "center", cols: "12" }
                            },
                            [
                              _c("v-subheader", { staticClass: "mt-3" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("common.additional-details"))
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-5 pb-1",
                              attrs: { align: "center", cols: "12", sm: "6" }
                            },
                            [
                              _c("fd-text-field", {
                                staticClass:
                                  "fd-readonly-input fd-remove-messages",
                                attrs: {
                                  label: _vm.$t("parts.count-sheet-group-name"),
                                  value: _vm.part.countSheetGroupName,
                                  readonly: true,
                                  dense: ""
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "justify-space-between pt-5 pb-1",
                              attrs: { align: "center", cols: "12", sm: "6" }
                            },
                            [
                              _c("fd-text-field", {
                                staticClass:
                                  "fd-readonly-input fd-remove-messages",
                                attrs: {
                                  label: _vm.$t("common.order"),
                                  value: _vm.part.countSheetGroupDisplayOrder,
                                  readonly: true,
                                  dense: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-form",
                        { ref: "form", on: { submit: _vm.onSubmit } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _vm.inlineMessage.message
                                    ? _c(
                                        "v-alert",
                                        {
                                          attrs: {
                                            type: _vm.inlineMessage.type
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "white-space": "pre-line"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.inlineMessage.message
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            { staticClass: "mx-2" },
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    text: "",
                                    link: "",
                                    disabled: _vm.processing,
                                    small: _vm.$vuetify.breakpoint.xsOnly
                                  },
                                  on: { click: _vm.cancel }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common.cancel")) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("fd-menu-save", {
                                attrs: {
                                  disabled: _vm.processing,
                                  loading: _vm.saving,
                                  small: _vm.$vuetify.breakpoint.xsOnly
                                },
                                on: {
                                  "click:save": function($event) {
                                    return _vm.save(false)
                                  },
                                  "click:save-and-close": function($event) {
                                    return _vm.save(true)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }