export function uint8ArraysEqual(value1: Uint8Array, value2: Uint8Array): boolean {
  if (value1.length !== value2.length) return false;
  for (let i = 0; i < value1.length; i++) {
    if (value1[i] !== value2[i]) return false;
  }
  return true;
}

export function pause(milliseconds: number): Promise<void> {
  return new Promise(function(resolve) {
    setTimeout(resolve, milliseconds);
  });
}

export var globals: any = Function("return this")();

export function base64ToBuffer(base64: string): Uint8Array {
  let binaryString = atob(base64);
  let returnValue = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    returnValue[i] = binaryString.charCodeAt(i);
  }
  return returnValue;
}

export function bufferToBase64(buffer: Uint8Array): string {
  let binaryString = "";
  for (let i = 0; i < buffer.byteLength; i++) {
    binaryString += String.fromCharCode(buffer[i]);
  }
  return btoa(binaryString);
}
