import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import {
  WalkdownWithRequestDetails,
  WalkdownStatuses,
  WorkOrderWithAllDetails,
  WorkOrderStatuses
} from "../../../services";
import { walkdownService } from "../../../services";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import userAccess from "../../../dataMixins/userAccess";
import rules from "@fd/lib/vue/rules";

const WalkdownEditDialog = FDVue.extend({
  name: "fd-walkdown-dialog",

  mixins: [dialogSupport, errorHandling, userAccess, rules],

  components: {
    "fd-duration-picker": () => import("@fd/lib/vue/components/DurationPicker.vue"),
    "fd-walkdown-form": () => import("../forms/WalkdownForm.vue")
  },

  props: {
    cy: { type: String, default: "fd-walkdown-form" }
  },

  data: function() {
    return {
      // Store the data for delayed binding to the actual data objects
      walkdownData: {} as WalkdownWithRequestDetails | null,
      workOrderData: {} as WorkOrderWithAllDetails | null,

      walkdown: {} as WalkdownWithRequestDetails,
      workOrder: {} as WorkOrderWithAllDetails,
      walkdownReadonly: false,
      saving: false
    };
  },

  methods: {
    async openWithData(workOrder: WorkOrderWithAllDetails, walkdown: WalkdownWithRequestDetails) {
      // Store the walkdown for delayed binding to the actual walkdown object
      this.workOrderData = workOrder;
      this.walkdownData = walkdown;

      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    preventSubmit(e: Event): boolean {
      e.preventDefault();
      return false;
    },

    cancelDialog() {
      this.closeDialog!(false);
    },
    async saveDialog(submit: boolean) {
      if (submit) {
        if (!((this.$refs.walkdownform as Vue).$refs.fdwalkdownform as HTMLFormElement).validate())
          return;
      }
      this.processing = true;
      this.saving = true;
      var originalWOStatus = this.workOrder.workOrderStatus;
      var originalWDStatus = this.walkdown.walkdownStatus;
      try {
        if (submit) {
          this.walkdown.walkdownStatus = WalkdownStatuses.Submitted;
          this.workOrder.workOrderStatus = WorkOrderStatuses.Estimated;
        } else if (this.walkdown.walkdownStatus == WalkdownStatuses.Pending) {
          this.walkdown.walkdownStatus = WalkdownStatuses.Draft;
        }
        this.walkdown.length = !this.walkdown.length ? null : +this.walkdown.length;
        this.walkdown.width = !this.walkdown.width ? null : +this.walkdown.width;
        this.walkdown.height = !this.walkdown.height ? null : +this.walkdown.height;

        this.walkdown.deckLevels = !this.walkdown.deckLevels ? null : +this.walkdown.deckLevels;
        this.walkdown.barricadeGates = !this.walkdown.barricadeGates
          ? null
          : +this.walkdown.barricadeGates;
        await walkdownService.updateItem(this.walkdown.id!, this.walkdown);
        this.closeDialog!(true);
      } catch (error) {
        this.walkdown.walkdownStatus = originalWDStatus;
        this.workOrder.workOrderStatus = originalWOStatus;
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    }
  },

  mounted: async function() {
    this.workOrder = this.workOrderData!;
    this.workOrderData = null;
    this.walkdown = this.walkdownData!;
    this.walkdownData = null;

    this.walkdownReadonly = !this.walkdown.currentUserPermissions.canPerformWalkdown;
  },

  created: async function() {}
});

export default WalkdownEditDialog;

export async function openWalkdownEditDialog(
  workOrder: WorkOrderWithAllDetails,
  walkdown: WalkdownWithRequestDetails
): Promise<boolean> {
  let dialog = createDialog(WalkdownEditDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.openWithData(workOrder, walkdown);
}

