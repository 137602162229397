var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-btn",
    _vm._g(
      _vm._b(
        {
          staticClass: "mx-1 add-fab-button-primary",
          attrs: {
            "data-cy": _vm.cy,
            fab: "",
            depressed: _vm.disabled,
            disabled: _vm.disabled,
            color: "new"
          }
        },
        "v-btn",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_c("v-icon", [_vm._v("mdi-plus")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }