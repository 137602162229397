import FDVue from "@fd/lib/vue";

export default FDVue.extend({
  props: {
    label: { type: [String] },

    value: { type: [Array, Object] },
    // TODO: Re-implement these properties if we use the string list frequently
    // valueStyle: { type: String, default: "" },
    // valueClass: { type: [String, Array], default: () => [] },

    loading: { type: Boolean, default: false },
    loadingColor: { type: String, default: "#7a7a7a" }

    // labelCols: { type: [String, Number], default: 6 },
    // valueCols: { type: [String, Number], default: 6 },
    // labelSmCols: { type: [String, Number], default: 6 },
    // valueSmCols: { type: [String, Number], default: 6 }

    // hideNoValuePlaceholder: { type: Boolean, default: false },
    // noValueKey: { type: String, default: "common.not-available" },
    // noValueStyle: { type: String, default: "" },
    // noValueClass: { type: [String, Array], default: () => [] }
  }
});

