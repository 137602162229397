var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card-subtitle",
        { staticClass: "pb-sm-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pt-0 pb-0 order-sm-1 order-2",
                  attrs: { cols: "12", sm: "6" }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "pa-0 ma-0",
                      attrs: { align: "center", justify: "center" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-5",
                          attrs: {
                            small: "",
                            rounded: "",
                            outlined: "",
                            disabled: _vm.processing,
                            color: "#7a7a7a"
                          },
                          on: { click: _vm.reloadTableData }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                left: _vm.$vuetify.breakpoint.mdAndUp
                              }
                            },
                            [_vm._v("mdi-reload")]
                          ),
                          _vm._v(" "),
                          _vm.$vuetify.breakpoint.mdAndUp
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("common.reload")))
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-text-field", {
                        class: [
                          _vm.$vuetify.breakpoint.sm
                            ? "fd-restrict-clearable-position"
                            : "",
                          "pt-0",
                          "mt-0",
                          "fd-table-search-input"
                        ],
                        attrs: {
                          "data-cy": "tablesearch",
                          "append-icon": "search",
                          label: _vm.$t("common.search"),
                          "single-line": "",
                          "hide-details": "",
                          clearable: "",
                          disabled: _vm.processing
                        },
                        model: {
                          value: _vm.tablesearch,
                          callback: function($$v) {
                            _vm.tablesearch = $$v
                          },
                          expression: "tablesearch"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "pt-0 pb-0 order-sm-2 order-1",
                  attrs: { cols: "12", sm: "6", md: "6" }
                },
                [
                  _c("fd-date-range-picker", {
                    attrs: {
                      dense: "",
                      "text-field-class": "fd-filter-date-picker-margins",
                      min: _vm.minDate,
                      max: _vm.maxDate,
                      loading: _vm.processing,
                      disabled: _vm.processing,
                      "hide-details": "",
                      outlined: false,
                      presets: _vm.dateRangePresetOptions
                    },
                    on: {
                      "input:fromDate": _vm.fromDateChanged,
                      "input:toDate": _vm.toDateChanged
                    },
                    model: {
                      value: _vm.showArchivedDateRange,
                      callback: function($$v) {
                        _vm.showArchivedDateRange = $$v
                      },
                      expression: "showArchivedDateRange"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-data-table", {
        directives: [
          {
            name: "fd-column",
            rawName: "v-fd-column:id.hide-when-header-text-empty",
            value: "",
            expression: "''",
            arg: "id",
            modifiers: { "hide-when-header-text-empty": true }
          },
          {
            name: "fd-column",
            rawName: "v-fd-column:requestNumber.hide-when-header-text-empty",
            value: "",
            expression: "''",
            arg: "requestNumber",
            modifiers: { "hide-when-header-text-empty": true }
          },
          {
            name: "fd-column",
            rawName:
              "v-fd-column:internalNumber.hide-when-medium.fd-scaffold-number",
            value: _vm.$vuetify.breakpoint.lgAndUp
              ? _vm.$t("scheduler.wo-number-column-header")
              : _vm.$t("scheduler.wo-number-column-header-short"),
            expression:
              "\n      $vuetify.breakpoint.lgAndUp\n        ? $t('scheduler.wo-number-column-header')\n        : $t('scheduler.wo-number-column-header-short')\n    ",
            arg: "internalNumber",
            modifiers: { "hide-when-medium": true, "fd-scaffold-number": true }
          },
          {
            name: "fd-column",
            rawName: "v-fd-column:scaffoldNumber",
            value: _vm.$vuetify.breakpoint.lgAndUp
              ? _vm.$t("scheduler.scaffold-number")
              : _vm.$t("scheduler.scaffold-number-short"),
            expression:
              "\n      $vuetify.breakpoint.lgAndUp\n        ? $t('scheduler.scaffold-number')\n        : $t('scheduler.scaffold-number-short')\n    ",
            arg: "scaffoldNumber"
          },
          {
            name: "fd-column",
            rawName: "v-fd-column:requestTypeName.hide-when-medium",
            value: _vm.$vuetify.breakpoint.lgAndUp
              ? _vm.$t("scheduler.request-type-column-header")
              : _vm.$t("scheduler.request-type-column-header-short"),
            expression:
              "\n      $vuetify.breakpoint.lgAndUp\n        ? $t('scheduler.request-type-column-header')\n        : $t('scheduler.request-type-column-header-short')\n    ",
            arg: "requestTypeName",
            modifiers: { "hide-when-medium": true }
          },
          {
            name: "fd-column",
            rawName: "v-fd-column:workOrderStatus",
            value: _vm.$t("scheduler.status"),
            expression: "$t('scheduler.status')",
            arg: "workOrderStatus"
          },
          {
            name: "fd-column",
            rawName: "v-fd-column:formattedRequiredDate.hide-when-extra-small",
            value: _vm.$vuetify.breakpoint.lgAndUp
              ? _vm.$t("scheduler.required-date-column-header")
              : _vm.$t("scheduler.required-date-column-header-short"),
            expression:
              "\n      $vuetify.breakpoint.lgAndUp\n        ? $t('scheduler.required-date-column-header')\n        : $t('scheduler.required-date-column-header-short')\n    ",
            arg: "formattedRequiredDate",
            modifiers: { "hide-when-extra-small": true }
          },
          {
            name: "fd-column",
            rawName: "v-fd-column:coordinatorName.hide-when-small",
            value: _vm.$t("scheduler.coordinator"),
            expression: "$t('scheduler.coordinator')",
            arg: "coordinatorName",
            modifiers: { "hide-when-small": true }
          },
          {
            name: "fd-column",
            rawName: "v-fd-column:generalForemanName.hide-when-extra-small",
            value: _vm.$vuetify.breakpoint.lgAndUp
              ? _vm.$t("scheduler.general-foreman-short")
              : _vm.$t("scheduler.general-foreman-short"),
            expression:
              "\n      $vuetify.breakpoint.lgAndUp\n        ? $t('scheduler.general-foreman-short')\n        : $t('scheduler.general-foreman-short')\n    ",
            arg: "generalForemanName",
            modifiers: { "hide-when-extra-small": true }
          },
          {
            name: "fd-column",
            rawName:
              "v-fd-column:foremanName.hide-when-header-text-empty.hide-when-extra-small",
            value: !!_vm.foremanID ? "" : _vm.$t("scheduler.foreman-short"),
            expression:
              "\n      !!foremanID ? '' : $t('scheduler.foreman-short')\n    ",
            arg: "foremanName",
            modifiers: {
              "hide-when-header-text-empty": true,
              "hide-when-extra-small": true
            }
          },
          {
            name: "fd-column",
            rawName: "v-fd-column:assignedContractorName.hide-when-medium",
            value: _vm.$t("scheduler.assigned-contractor"),
            expression: "$t('scheduler.assigned-contractor')",
            arg: "assignedContractorName",
            modifiers: { "hide-when-medium": true }
          }
        ],
        ref: "datatable",
        class: ["fd-expanding-table", "fd-scheduler-list"],
        attrs: {
          "data-cy": "list",
          "custom-sort": _vm.customSort,
          "items-per-page": _vm.itemsPerPage,
          "footer-props": { "items-per-page-options": _vm.itemsPerPageOptions },
          items: _vm.filteredWorkOrders,
          search: _vm.tablesearch,
          loading: _vm.processing,
          "loading-text": _vm.$t("common.table-loading-message"),
          "sort-by": ["internalNumber"],
          "sort-desc": true,
          "mobile-breakpoint": "0",
          "show-expand": ""
        },
        scopedSlots: _vm._u(
          [
            _vm.$vuetify.breakpoint.mdAndUp
              ? {
                  key: "item.data-table-expand",
                  fn: function({ isExpanded, expand, item }) {
                    return [
                      _c("sp-inline-scope-icons", { attrs: { item: item } }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { justify: "center", "no-gutters": "" } },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "v-data-table__expand-icon",
                              class: {
                                "v-data-table__expand-icon--active": isExpanded
                              },
                              on: {
                                click: function($event) {
                                  return expand(!isExpanded)
                                }
                              }
                            },
                            [_vm._v("mdi-chevron-down")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              : null,
            {
              key: "item.scaffoldNumber",
              fn: function({ item }) {
                return [
                  _c("fd-scaffold-number-with-badges", {
                    attrs: { value: item.scaffoldNumber }
                  })
                ]
              }
            },
            {
              key: "item.workOrderStatus",
              fn: function({ item }) {
                return [
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-0 fd-status-column",
                      staticStyle: { position: "relative" }
                    },
                    [
                      _vm.workOrderIsOnHold(item)
                        ? _c(
                            "v-icon",
                            {
                              staticClass:
                                "fd-table-cell-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                            },
                            [
                              _vm._v(
                                "\n          fas fa-exclamation-circle\n        "
                              )
                            ]
                          )
                        : _vm.workOrderIsCancelled(item)
                        ? _c(
                            "v-icon",
                            {
                              staticClass:
                                "fd-table-cell-error-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                            },
                            [
                              _vm._v(
                                "\n          fas fa-times-octagon\n        "
                              )
                            ]
                          )
                        : _vm.workOrderIsInScheduling(item)
                        ? _c(
                            "v-icon",
                            {
                              staticClass:
                                "fd-table-cell-inscheduling-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                            },
                            [_vm._v("\n          fas fa-circle\n        ")]
                          )
                        : _vm.workOrderIsStarted(item)
                        ? _c(
                            "v-icon",
                            {
                              staticClass:
                                "fd-table-cell-started-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                            },
                            [_vm._v("\n          fas fa-circle\n        ")]
                          )
                        : _vm._e(),
                      _vm._v(
                        "\n        " +
                          _vm._s(item.workOrderStatusName) +
                          "\n      "
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "expanded-item",
              fn: function({ item }) {
                return [
                  _c(
                    "td",
                    { attrs: { colspan: _vm.expanderColSpan } },
                    [
                      _c("fd-work-order-details", {
                        staticClass:
                          "fd-expanding-table-single-details-section",
                        attrs: {
                          workOrder: item,
                          walkdown: item.walkdown,
                          "show-edit-controls": true,
                          "edit-controls-disabled": true
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }