import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { lemsService, LEMWithDetails } from "../../services";
import { openLemTimesheetSearchDialog } from "../components/dialogs/LemTimesheetSearchDialog.vue";

export default FDVue.extend({
  name: "fd-lem-timesheet-search-test",
  mixins: [serviceErrorHandling],
  data: function() {
    return {
      lemID: "746d8f12-ebff-4631-886c-6fea0ae82a21",
      lem: undefined as LEMWithDetails | undefined,
      isShowingDialog: false
    };
  },
  methods: {
    async showDialog() {
      if (!this.lem) return;
      if (this.isShowingDialog) return;

      this.isShowingDialog = true;
      await openLemTimesheetSearchDialog(this.lem);
      this.isShowingDialog = false;
    },
    async loadLEM() {
      this.processing = true;
      try {
        let lem = await lemsService.getByID(this.lemID);
        this.lem = lem;
        if (!this.isShowingDialog) this.showDialog();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },
  mounted: async function() {
    await this.showDialog();
  },
  created: async function() {
    await this.loadLEM();
  }
});

