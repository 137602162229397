var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { class: _vm.$store.state.themeName, attrs: { "data-cy": "app" } },
    [
      _vm.showAppBar ? _c("fd-app-bar") : _vm._e(),
      _vm._v(" "),
      _vm.showDrawer ? _c("fd-drawer") : _vm._e(),
      _vm._v(" "),
      _c("fd-view"),
      _vm._v(" "),
      _c("fd-snackbar"),
      _vm._v(" "),
      _vm.showBottomBar ? _c("fd-bottom-bar") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }