import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { mapActions } from "vuex";
import { SortParts } from "../../../dataMixins/countSheetGroupSorting";
import {
  BuildDismantleRatio,
  EstimateTotalTimes,
  HoardingModifier,
  InternalModifier,
  PartGenerationType,
  PartWithTags,
  ScaffoldCongestionFactor,
  ScaffoldDistanceModifier,
  ScaffoldElevationModifier,
  ScaffoldHeightModifier,
  ScaffoldSubType,
  ScaffoldType,
  WorkOrderWorkingComponent,
  walkdownReferenceDataService,
  workOrderEstimateSnapshotService,
  workOrderWorkingEstimateService,
  WorkOrderWorkingTakeoff,
  Environment,
  workOrderWorkingTakeoffService
} from "../../../services";
import { buildBCount, parseBCountString } from "../../../utils/bcount";
import { HasName, SortItemsByStringProp } from "../../../utils/person";
import { openBCountPartsListBottomDialog } from "../dialogs/BCountPartsListBottomDialog.vue";
import { SortItemsWithModifier } from "../../../utils/sorting";

export type EstimateComponentNewDialogReturnType = boolean;
const EstimateComponentNewDialog = FDVue.extend({
  name: "sp-estimate-component-dialog",

  mixins: [dialogSupport],

  components: {
    "sp-designed-estimate-form": () => import("../forms/SP.EstimateDesignedForm.vue"),
    "sp-lwh-estimate-form": () => import("../forms/SP.EstimateLWHForm.vue"),
    "sp-estimate-modifiers-form": () => import("../forms/SP.EstimateModifiersForm.vue"),
    "fd-work-order-estimate-summary-panel": () =>
      import("../estimates/SP.WorkOrderEstimateSummaryPanel.vue")
  },

  data: function() {
    return {
      workOrderID: "",
      estimate: {} as WorkOrderWorkingComponent,
      isNew: true,
      isModify: false,
      saving: false,
      loadingEstimateTimes: false,
      isDesigned: false,
      downloadOnSave: false,
      // Variable to hold which expansion panels is currently open
      panels: [0] as number[],

      bCountString: "",

      allScaffoldDistances: [] as ScaffoldDistanceModifier[],
      allScaffoldElevations: [] as ScaffoldElevationModifier[],
      allScaffoldHeights: [] as ScaffoldHeightModifier[],
      allBuildDismantleRatios: [] as BuildDismantleRatio[],
      allScaffoldCongestionFactors: [] as ScaffoldCongestionFactor[],
      allInternalModifiers: [] as InternalModifier[],
      allHoardingModifiers: [] as HoardingModifier[]
    };
  },
  computed: {
    estimateTotalTimes(): EstimateTotalTimes {
      return {
        estimatedTotalErectMinutes: this.estimate.estimatedTotalErectMinutes ?? 0,
        estimatedTotalDismantleMinutes: this.estimate.estimatedTotalDismantleMinutes ?? 0,
        estimatedTotalModifyMinutes: this.estimate.estimatedTotalModifyMinutes ?? 0,
        estimatedTotalMobilizationMinutes: this.estimate.estimatedTotalMobilizationMinutes ?? 0,
        estimatedTotalDemobilizationMinutes: this.estimate.estimatedTotalDemobilizationMinutes ?? 0,
        estimatedTotalHoardingMinutes: this.estimate.estimatedTotalHoardingMinutes ?? 0,
        estimatedTotalWeight: this.estimate.estimatedTotalWeight ?? 0,
        estimatedTotalPartCount: this.estimate.estimatedTotalPartCount ?? 0,
        estimatedTotalTime: this.estimate.estimatedTotalTime ?? 0,
        estimatedErectMPP: this.estimate.estimatedErectMPP ?? 0,
        estimatedDismantleMPP: this.estimate.estimatedDismantleMPP ?? 0
      };
    },
    scaffoldIsHardBarricade: {
      get(): boolean {
        return this.estimate.scaffoldTypeID == ScaffoldType.Barricade;
      }
    },
    modificationPercent: {
      get(): number | null | undefined {
        if (!this.estimate.modificationPercent) return this.estimate.modificationPercent;
        return this.estimate.modificationPercent * 100;
      },
      set(val: number | null | undefined) {
        if (!val) this.estimate.modificationPercent = val;
        else this.estimate.modificationPercent = Number((val / 100).toFixed(2));
      }
    },
    supervisionPercent: {
      get(): number | null | undefined {
        if (!this.estimate.factor1) return this.estimate.factor1;
        return this.estimate.factor1 * 100;
      },
      set(val: number | null | undefined) {
        if (!val) this.estimate.factor1 = val;
        else this.estimate.factor1 = Number((val / 100).toFixed(2));
      }
    },
    logisticsPercent: {
      get(): number | null | undefined {
        if (!this.estimate.factor2) return this.estimate.factor2;
        return this.estimate.factor2 * 100;
      },
      set(val: number | null | undefined) {
        if (!val) this.estimate.factor2 = val;
        else this.estimate.factor2 = Number((val / 100).toFixed(2));
      }
    },
    allParts(): PartWithTags[] {
      return this.$store.state.parts.fullList as PartWithTags[];
    },
    selectableParts(): PartWithTags[] {
      let selectableParts = this.allParts.filter(p => !!p.countSheetGroupID?.length);
      return SortParts(selectableParts);
    },
    bCountPartQuantities(): (WorkOrderWorkingTakeoff & HasName)[] {
      let partQuantities = parseBCountString(this.bCountString);
      let bCountPartQuantities = partQuantities
        .filter(pq => this.allParts.findIndex(p => p.name == pq.code) !== -1)
        .map(pq => {
          let part = this.allParts.find(p => p.name == pq.code)!;
          return {
            partID: part.id,
            name: part.name,
            quantity: pq.quantity
          } as WorkOrderWorkingTakeoff & HasName;
        });

      return bCountPartQuantities;
    }
  },

  methods: {
    ...mapActions({
      loadParts: "LOAD_PARTS"
    }),
    async loadData() {
      await Promise.all([
        this.loadParts(),
        this.loadScaffoldHeights(),
        this.loadScaffoldElevations(),
        this.loadScaffoldDistances(),
        this.loadScaffoldCongestionFactors(),
        this.loadBuildDismantleRatios(),
        this.loadInternalModifiers(),
        this.loadHoardingModifiers()
      ]);

      if (this.isNew == false && this.estimate.generationTypeID == PartGenerationType.Designed) {
        // Existing component is designed, we need to get its BCount from parts
        let workingTakeoff = (
          await workOrderWorkingTakeoffService.getWorkingTakeoffForWorkOrderWorkingComponentWithID(
            this.estimate.id!
          )
        ).map(x => ({
          name: x.partName,
          quantity: x.quantity
        }));
        this.bCountString = buildBCount(workingTakeoff) ?? "";
      }

      this.$nextTick(() => {
        this.recalculateTimes();
      });
    },
    async recalculateTimes() {
      this.inlineMessage.message = null;

      this.processing = true;
      this.loadingEstimateTimes = true;
      try {
        var estimateToCalculate = {
          ...this.estimate,
          length: this.$parse.sanitizedNumber(this.estimate.length),
          width: this.$parse.sanitizedNumber(this.estimate.width),
          height: this.$parse.sanitizedNumber(this.estimate.height),
          deckLevels: this.$parse.sanitizedNumber(this.estimate.deckLevels),
          barricadeGates: this.$parse.sanitizedNumber(this.estimate.barricadeGates),
          crewSize: this.$parse.sanitizedNumber(this.estimate.crewSize),
          modificationPercent: this.$parse.sanitizedNumber(this.estimate.modificationPercent),
          factor1: this.$parse.sanitizedNumber(this.estimate.factor1),
          factor2: this.$parse.sanitizedNumber(this.estimate.factor2)
        };

        if (this.isDesigned) {
          let timeTotals = await workOrderEstimateSnapshotService.getWorkOrderEstimateComponentTotalTimesUsingPartsList(
            estimateToCalculate,
            this.bCountPartQuantities
          );
          this.estimate = {
            ...this.estimate,
            ...timeTotals,
            estimatedTotalTime:
              (timeTotals.estimatedTotalDemobilizationMinutes ?? 0.0) +
              (timeTotals.estimatedTotalDismantleMinutes ?? 0.0) +
              (timeTotals.estimatedTotalErectMinutes ?? 0.0) +
              (timeTotals.estimatedTotalHoardingMinutes ?? 0.0) +
              (timeTotals.estimatedTotalMobilizationMinutes ?? 0.0) +
              (timeTotals.estimatedTotalModifyMinutes ?? 0.0),
            estimatedErectMPP:
              (timeTotals.estimatedTotalErectMinutes ?? 0.0) /
              (timeTotals.estimatedTotalPartCount ?? 1),
            estimatedDismantleMPP:
              (timeTotals.estimatedTotalDismantleMinutes ?? 0.0) /
              (timeTotals.estimatedTotalPartCount ?? 1)
          };
        } else {
          if (!this.$refs.lwhform || !(this.$refs.lwhform as any).validate()) {
            return;
          }

          let timeTotals = await workOrderEstimateSnapshotService.getWorkOrderWorkingComponentTotalTimesUsingLWH(
            estimateToCalculate
          );
          this.estimate = {
            ...this.estimate,
            ...timeTotals,
            estimatedTotalTime:
              (timeTotals.estimatedTotalDemobilizationMinutes ?? 0.0) +
              (timeTotals.estimatedTotalDismantleMinutes ?? 0.0) +
              (timeTotals.estimatedTotalErectMinutes ?? 0.0) +
              (timeTotals.estimatedTotalHoardingMinutes ?? 0.0) +
              (timeTotals.estimatedTotalMobilizationMinutes ?? 0.0) +
              (timeTotals.estimatedTotalModifyMinutes ?? 0.0),
            estimatedErectMPP:
              (timeTotals.estimatedTotalErectMinutes ?? 0.0) /
              (timeTotals.estimatedTotalPartCount ?? 1),
            estimatedDismantleMPP:
              (timeTotals.estimatedTotalDismantleMinutes ?? 0.0) /
              (timeTotals.estimatedTotalPartCount ?? 1)
          };
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.loadingEstimateTimes = false;
      }
    },
    async openForNew(
      workOrderID: string,
      scaffoldTypeModifierID: string,
      scaffoldTypeID: ScaffoldType,
      scaffoldSubTypeID: ScaffoldSubType,
      isModify: boolean
    ): Promise<EstimateComponentNewDialogReturnType> {
      this.isNew = true;
      this.isModify = isModify;

      console.log(
        `EstimateComponentNewDialog.openForNew estimate: ${workOrderID}, scaffoldTypeID: ${scaffoldTypeID}, scaffoldSubTypeID: ${scaffoldSubTypeID}, isNew: ${this.isNew}`
      );

      let environment = this.$store.state.curEnvironment as Environment;
      this.estimate = {
        scaffoldTypeModifierID: scaffoldTypeModifierID,
        scaffoldTypeID: scaffoldTypeID,
        scaffoldSubTypeID: scaffoldSubTypeID,

        scaffoldDistanceModifierID: environment?.defaultScaffoldDistanceModifierID,
        scaffoldElevationModifierID: environment?.defaultScaffoldElevationModifierID,
        scaffoldHeightModifierID: environment?.defaultScaffoldHeightModifierID,
        buildDismantleRatioID: environment?.defaultBuildDismantleRatioID,
        crewSize: environment?.defaultCrewSize,
        factor1: environment?.defaultFactor1,
        factor2: environment?.defaultFactor2,

        generationTypeID: this.isModify ? PartGenerationType.Modify : PartGenerationType.LWH,

        isNew: true
      } as WorkOrderWorkingComponent;

      this.workOrderID = workOrderID;
      this.optOutOfErrorHandling();
      this.loadData();
      return await this.showDialog!();
    },
    async openToEdit(
      workOrderID: string,
      estimate: WorkOrderWorkingComponent,
      isModify: boolean
    ): Promise<EstimateComponentNewDialogReturnType> {
      this.isNew = false;
      this.isModify = isModify;
      // console.log(`EstimateComponentNewDialog.open estimate: ${estimate}, isNew: ${this.isNew}`);
      this.estimate = {
        ...estimate,
        // If this estimate is a new one, then it can't be considered modified as it hasn't previously existed
        isModified: !estimate.isNew
      } as WorkOrderWorkingComponent;
      this.workOrderID = workOrderID;
      this.isDesigned = this.estimate.generationTypeID == PartGenerationType.Designed;

      this.optOutOfErrorHandling();

      this.loadData();
      return await this.showDialog!();
    },

    cancelDialog() {
      this.closeDialog(false);
    },
    async saveDialog() {
      if (this.isDesigned) {
        await this.saveDesignedEstimate();
      } else {
        await this.saveLWHEstimate();
      }
    },
    async saveDesignedEstimate() {
      let quantities = this.bCountPartQuantities;
      if (!quantities.length) {
        this.inlineMessage.message = this.$t("No items.");
        this.inlineMessage.type = "error";
        return;
      }
      this.processing = true;
      this.saving = true;
      try {
        var componentToSave = {
          ...this.estimate,
          isNew: this.isNew,
          isModified: !this.isNew,
          isRemoved: false,
          generationTypeID: PartGenerationType.Designed,
          modificationPercent: this.$parse.sanitizedNumber(this.estimate.modificationPercent),
          factor1: this.$parse.sanitizedNumber(this.estimate.factor1),
          factor2: this.$parse.sanitizedNumber(this.estimate.factor2)
        } as WorkOrderWorkingComponent;

        if (this.isNew) {
          var newID = await workOrderWorkingEstimateService.addNewDesignedWorkingComponentToWorkOrder(
            this.workOrderID,
            this.bCountPartQuantities,
            componentToSave
          );
        } else {
          await workOrderWorkingEstimateService.updateDesignedWorkingComponent(
            componentToSave.id!,
            this.bCountPartQuantities,
            componentToSave
          );
        }

        this.closeDialog(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    async saveLWHEstimate() {
      if (!this.$refs.lwhform) return;

      if (!(this.$refs.lwhform as any).validate()) {
        this.inlineMessage.message = this.$t("work-order-estimates.error-message");
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        var componentToSave = {
          ...this.estimate,
          isNew: this.isNew,
          isModified: !this.isNew,
          isRemoved: false,
          generationTypeID: this.isModify ? PartGenerationType.Modify : PartGenerationType.LWH,
          length: this.$parse.sanitizedNumber(this.estimate.length),
          width: this.$parse.sanitizedNumber(this.estimate.width),
          height: this.$parse.sanitizedNumber(this.estimate.height),
          deckLevels: this.$parse.sanitizedNumber(this.estimate.deckLevels),
          barricadeGates: this.$parse.sanitizedNumber(this.estimate.barricadeGates),
          crewSize: this.$parse.sanitizedNumber(this.estimate.crewSize),
          modificationPercent: this.$parse.sanitizedNumber(this.estimate.modificationPercent),
          factor1: this.$parse.sanitizedNumber(this.estimate.factor1),
          factor2: this.$parse.sanitizedNumber(this.estimate.factor2)
        } as WorkOrderWorkingComponent;

        if (this.isModify) {
          if (this.isNew) {
            var newID = await workOrderWorkingEstimateService.addNewModifyWorkingComponentToWorkOrder(
              this.workOrderID,
              componentToSave
            );
          } else {
            await workOrderWorkingEstimateService.updateWorkingComponentUsingModify(
              componentToSave.id!,
              componentToSave
            );
          }
        } else {
          if (this.isNew) {
            await workOrderWorkingEstimateService.addNewLWHWorkingComponentToWorkOrder(
              this.workOrderID,
              componentToSave
            );
          } else {
            await workOrderWorkingEstimateService.updateWorkingComponentUsingLWH(
              componentToSave.id!,
              componentToSave
            );
          }
        }

        this.closeDialog(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    async showPartsDialog() {
      this.optOutOfErrorHandling();
      let bCount = await openBCountPartsListBottomDialog(
        this.selectableParts,
        this.$refs.content as Vue
      );

      if (!!bCount?.length) {
        this.bCountString = bCount;
      }
      this.recalculateTimes();
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldDistances(): Promise<void> {
      this.allScaffoldDistances = SortItemsWithModifier(
        await walkdownReferenceDataService.getAllScaffoldDistanceModifiers()
      );
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldHeights(): Promise<void> {
      this.allScaffoldHeights = SortItemsWithModifier(
        await walkdownReferenceDataService.getAllScaffoldHeightModifiers()
      );
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldElevations(): Promise<void> {
      this.allScaffoldElevations = SortItemsWithModifier(
        await walkdownReferenceDataService.getAllScaffoldElevationModifiers()
      );
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldCongestionFactors(): Promise<void> {
      this.allScaffoldCongestionFactors = SortItemsWithModifier(
        await walkdownReferenceDataService.getAllScaffoldCongestionFactors()
      );
    },

    // DOES NOT manage processing or error message logic
    async loadBuildDismantleRatios(): Promise<void> {
      this.allBuildDismantleRatios = SortItemsByStringProp(
        await walkdownReferenceDataService.getAllBuildDismantleRatios(),
        "ratio"
      );
    },

    // DOES NOT manage processing or error message logic
    async loadInternalModifiers(): Promise<void> {
      this.allInternalModifiers = SortItemsWithModifier(
        await walkdownReferenceDataService.getAllInternalModifiers()
      );
    },

    // DOES NOT manage processing or error message logic
    async loadHoardingModifiers(): Promise<void> {
      this.allHoardingModifiers = SortItemsWithModifier(
        await walkdownReferenceDataService.getAllHoardingModifiers()
      );
    }
  }
});

export default EstimateComponentNewDialog;

export async function createEstimateComponentNewDialog(
  workOrderID: string,
  scaffoldTypeModifierID: string,
  scaffoldTypeID: ScaffoldType,
  scaffoldSubTypeID: ScaffoldSubType,
  isModify: boolean
): Promise<EstimateComponentNewDialogReturnType> {
  let dialog = createDialog(EstimateComponentNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.openForNew(
    workOrderID,
    scaffoldTypeModifierID,
    scaffoldTypeID,
    scaffoldSubTypeID,
    isModify
  );
}
export async function createEstimateComponentModifyDialog(
  workOrderID: string,
  lastEstimateDetails: WorkOrderWorkingComponent,
  isModify: boolean
): Promise<EstimateComponentNewDialogReturnType> {
  let dialog = createDialog(EstimateComponentNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.openToEdit(workOrderID, lastEstimateDetails, isModify);
}

