import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { PartWithTags, TransferPartDetails, Yard, yardService, YardType } from "../../../services";
import rules from "@fd/lib/vue/rules";
import { mapActions } from "vuex";

type CountSheetYardSelectionOptions = {
  selectSourceYard: boolean;
  selectDestinationYard: boolean;
  partSources: TransferPartDetails[];
};
type CountSheetYardSelectionResults = {
  sourceYardID: string | null;
  destinationYardID: string | null;
};
const CountSheetYardSelectionDialog = FDVue.extend({
  name: "fd-count-sheet-yard-selection-dialog",

  mixins: [dialogSupport, rules],

  components: {
    "fd-count-sheet-yard-selection-form": () => import("../forms/CountSheetYardSelectionForm.vue")
  },

  data: function() {
    return {
      selectSourceYard: false,
      selectDestinationYard: false,
      sourceYardID: null as string | null,
      destinationYardID: null as string | null,
      allYards: [] as Yard[],
      yards: [] as Yard[],
      partSources: [] as TransferPartDetails[]
    };
  },

  computed: {
    sourceYardIDs(): string[] {
      if (!this.partSources?.length) return [];
      return [...new Set(this.partSources.map(x => x.yardID!))];
    },
    differentSourceYardPartIDs(): string[] {
      let differentSources = this.partSources.filter(x => x.yardID != this.destinationYardID);
      return [...new Set(differentSources.map(x => x.partID))];
    },
    differentSourceYardParts(): PartWithTags[] {
      let allParts = this.$store.state.parts.fullList as PartWithTags[];
      let differentSourceYardPartIDs = this.differentSourceYardPartIDs;
      return allParts.filter(x => differentSourceYardPartIDs.includes(x.id!));
    }
  },

  watch: {},

  methods: {
    ...mapActions({
      loadParts: "LOAD_PARTS"
    }),
    async open(
      options: CountSheetYardSelectionOptions
    ): Promise<CountSheetYardSelectionResults | undefined> {
      this.selectSourceYard = options.selectSourceYard;
      this.selectDestinationYard = options.selectDestinationYard;
      this.partSources = options.partSources;

      if (options.selectDestinationYard) {
        await this.loadParts();
      }
      if (this.sourceYardIDs.length == 1) this.destinationYardID = this.sourceYardIDs[0];

      this.loadData();

      this.optOutOfErrorHandling();
      if (await this.showDialog!()) {
        return {
          sourceYardID: this.sourceYardID,
          destinationYardID: this.destinationYardID
        };
      }
    },

    async loadData() {
      this.processing = true;
      try {
        // Load yards here
        this.allYards = await yardService.getAll(false, null, null);
        this.yards = this.allYards
          .filter(x => !x.isSystemYard && x.yardTypeID == YardType.OnRent)
          .sort((a, b) => {
            let aOrder = a.order ?? 0;
            let bOrder = b.order ?? 0;
            if (aOrder != bOrder) return aOrder - bOrder;

            let aName = (a.name ?? "").toLocaleLowerCase();
            let bName = (b.name ?? "").toLocaleLowerCase();
            return aName < bName ? -1 : aName > bName ? 1 : 0;
          });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },

  created: async function() {}
});

export default CountSheetYardSelectionDialog;

export async function showCountSheetYardSelectionDialog(
  options: CountSheetYardSelectionOptions
): Promise<CountSheetYardSelectionResults | undefined> {
  let dialog = createDialog(CountSheetYardSelectionDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(options);
}

