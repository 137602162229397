import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { mapActions, mapMutations } from "vuex";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { ScaffoldType, ScaffoldTypeModifier, scaffoldTypeModifierService } from "../../services";
import estimates from "../../dataMixins/estimates";

export default FDVue.extend({
  name: "sp-estimate-setup-scaffold-type-form",
  props: {
    parentContext: { type: String, default: "" }
  },
  mixins: [serviceErrorHandling, estimates],
  components: {
    "fd-detail-view-header": () => import("@fd/lib/vue/components/layout/DetailViewHeader.vue")
  },
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },
  data: function() {
    return {
      context: "scaffoldtypes",

      filterByScaffoldTypeIDs: [] as ScaffoldType[],
      scaffoldTypeModifiers: [] as ScaffoldTypeModifier[],

      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5
    };
  },
  computed: {
    scaffoldTypeModifierDetails(): ScaffoldTypeModifier[] {
      let allSubTypesWithDetails = [] as ScaffoldTypeModifier[];

      let scaffoldTypeIDs = this.allScaffoldTypes.map(x => x.value);
      if (!!this.filterByScaffoldTypeIDs.length) {
        scaffoldTypeIDs = this.filterByScaffoldTypeIDs;
      }

      for (let scaffoldTypeID of scaffoldTypeIDs) {
        let subTypes = this.allScaffoldSubTypesForType(scaffoldTypeID);
        let subTypesWithDetails = subTypes.map(st => {
          let existingTypeModifier = this.scaffoldTypeModifiers.find(
            x =>
              x.scaffoldTypeID == scaffoldTypeID &&
              x.scaffoldSubTypeID != null &&
              x.scaffoldSubTypeID == st.value
          );
          return {
            ...existingTypeModifier,
            scaffoldTypeID: scaffoldTypeID,
            scaffoldSubTypeID: st.value
          } as ScaffoldTypeModifier;
        });
        allSubTypesWithDetails = allSubTypesWithDetails.concat(subTypesWithDetails);
      }
      return allSubTypesWithDetails;
    },
    tablesearch: {
      get(): string {
        return this.$store.state.filters.find((x: any) => x.context == this.context)!
          .searchStringForFiltering;
      },
      set(val: string) {
        this.$store.state.filters.find(
          (x: any) => x.context == this.context
        )!.searchStringForFiltering = val;
      }
    }
  },
  watch: {},
  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      addFilteringContext: "ADD_CHILD_FILTERING_CONTEXT"
    }),
    ...mapActions({
      updateScaffoldTypeModifier: "UPDATE_SCAFFOLD_TYPE_MODIFIER"
    }),
    async reloadData() {
      this.processing = true;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async loadData() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      this.scaffoldTypeModifiers = await scaffoldTypeModifierService.getAllWithArchivedStatus(
        true,
        false,
        null,
        null
      );

      let didAddAnyModifiers = false;
      try {
        let allTypes = this.allScaffoldTypes;
        for (let scaffoldType of allTypes) {
          let scaffoldTypeID = scaffoldType.value;
          let allSubTypes = this.allScaffoldSubTypesForType(scaffoldTypeID);

          for (let scaffoldSubType of allSubTypes) {
            let scaffoldSubTypeID = scaffoldSubType.value;
            let existingTypeModifier = this.scaffoldTypeModifiers.findIndex(
              x =>
                x.scaffoldTypeID == scaffoldTypeID &&
                x.scaffoldSubTypeID != null &&
                x.scaffoldSubTypeID == scaffoldSubTypeID
            );
            if (existingTypeModifier == -1) {
              // Create and add a new type modifier
              let newModifier = {
                name:
                  this.$t(`work-order-estimates.scaffold-types.${scaffoldTypeID}`) +
                  " - " +
                  this.$t(`work-order-estimates.scaffold-sub-types.${scaffoldSubTypeID}`),
                scaffoldTypeID: scaffoldTypeID,
                scaffoldSubTypeID: scaffoldSubTypeID,
                isActive: true
              } as ScaffoldTypeModifier;
              await scaffoldTypeModifierService.addItem(newModifier);
              didAddAnyModifiers = true;
            }
          }
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        if (didAddAnyModifiers) {
          await this.loadData();
        }
      }

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },
    // async editModifier(item: ScaffoldTypeModifierDetails) {
    //   try {
    //     if (!item.typeModifier) {
    //       this.processing = true;
    //       // Create and add a new type modifier
    //       let newID = await scaffoldTypeModifierService.addItem({
    //         name: this.$t(`work-order-estimates.scaffold-sub-types.${item.scaffoldSubTypeID}`),
    //         scaffoldTypeID: item.scaffoldTypeID,
    //         scaffoldSubTypeID: item.scaffoldSubTypeID
    //       } as ScaffoldTypeModifier);
    //       let newItem = await scaffoldTypeModifierService.getByID(newID);
    //       item = { ...newItem, typeModifier: newItem } as ScaffoldTypeModifierDetails;
    //       this.processing = false;
    //     }
    //     this.$router.push(`/estimatesetup/scaffoldtypemodifier/${item.id}`);
    //   } catch (error) {
    //     this.handleError(error as Error);
    //   } finally {
    //     this.processing = false;
    //   }
    // }
    // async showAddNewDialog(
    //   scaffoldTypeID: ScaffoldType | undefined | null,
    //   scaffoldSubTypeID: ScaffoldSubType
    // ) {
    //   if (!scaffoldTypeID) scaffoldTypeID = this.filterByScaffoldTypeID;
    //   await createNewScaffoldTypeModifier(scaffoldTypeID, scaffoldSubTypeID);
    // },
    async flipActive(item: ScaffoldTypeModifier) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        await this.updateScaffoldTypeModifier({
          id: item.id,
          isActive: !item.isActive,
          name: item.name
        } as ScaffoldTypeModifier);
        item.isActive = !item.isActive;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },
  created: async function() {
    this.addFilteringContext({
      context: this.context,
      parentalContext: this.parentContext,
      searchStringForFiltering: "",
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: DateUtil.minimumDate(),
      showArchivedForFilteringToDate: DateUtil.maximumDate()
    });
  },
  mounted: async function() {
    await this.reloadData();
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  }
});

