var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "configuration.inspections.new-time-segment-dialog-title"
                          )
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-2" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "align-center justify-space-between pb-1 px-0 mx-0",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              "hide-details": "",
                              clearable: "",
                              label: _vm.$t(
                                "configuration.inspections.style-column-header"
                              ),
                              items: _vm.styles
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function({ item: style }) {
                                  return [
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass:
                                          "fd-inspection-time-chip fd-selected-time-segment",
                                        class: style
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "fd-inspection-time-chip-icon",
                                            attrs: { small: "" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$store.getters.icon(
                                                    `inspection-time-${style}`
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              !!_vm.inspectionTime.name
                                                ? _vm.inspectionTime.name
                                                : _vm.$t(
                                                    `inspections.times.${style}-label`
                                                  )
                                            )
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item",
                                fn: function({ item: style, on, attrs }) {
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        _vm._b({}, "v-list-item", attrs, false),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                _c(
                                                  "v-chip",
                                                  {
                                                    staticClass:
                                                      "fd-inspection-time-chip",
                                                    class: style
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "fd-inspection-time-chip-icon"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$store.getters.icon(
                                                              `inspection-time-${style}`
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            `inspections.times.${style}-label`
                                                          )
                                                        )
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.inspectionTime.style,
                              callback: function($$v) {
                                _vm.$set(_vm.inspectionTime, "style", $$v)
                              },
                              expression: "inspectionTime.style"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "align-center justify-space-between pb-1 px-0 mx-0",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "configuration.inspections.segment-name-column-header"
                              ),
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.inspectionTime.name,
                              callback: function($$v) {
                                _vm.$set(_vm.inspectionTime, "name", $$v)
                              },
                              expression: "inspectionTime.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "align-center justify-space-between pb-1 px-0 mx-0",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("fd-time-picker", {
                            attrs: {
                              "data-cy": "dateselect",
                              "hide-details": "",
                              label: _vm.$t(
                                "configuration.inspections.inspection-time-column-header"
                              ),
                              value: _vm.inspectionTime.time
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.inspectionTime,
                                  "time",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "close",
                        text: "",
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.cancelDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.cancel")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "accept",
                        color: "primary",
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.acceptDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.save")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }