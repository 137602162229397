import { notificationService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: notificationService,
  singularStoreName: "NOTIFICATION",
  pluralStoreName: "NOTIFICATIONS",
  localizationPrefix: "notifications",
  // The following is the name of the ID property used by foreign objects.
  consumerRelatedIDProperty: {
    name: "notificationID",
    type: "string"
  }
});

export default storeModule;
