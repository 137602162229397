import FDVue from "@fd/lib/vue";
import { mapActions, mapMutations } from "vuex";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import {
  Part,
  partService,
  PartWithYardBalance,
  reportService,
  Yard,
  YardTotal
} from "../services";
import { TranslateResult } from "vue-i18n";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import printBlob from "@fd/lib/client-util/printBlob";
import { localizedDateTimeString } from "@fd/lib/client-util/datetime";

function compareYards(a: Yard, b: Yard): number {
  let orderA = a.order ?? 0;
  let orderB = b.order ?? 0;
  if (orderA != orderB) return orderA - orderB;

  let nameA = (a.name ?? "").toLowerCase();
  let nameB = (b.name ?? "").toLowerCase();
  if (nameA < nameB) return -1;
  else if (nameA > nameB) return 1;
  else return 0;
}

export default FDVue.extend({
  name: "fd-part-yard-balances",

  mixins: [serviceErrorHandling],

  data: function() {
    return {
      includeUnusedYards: false,
      includeUnusedParts: false,

      tablesearch: "",
      partTotals: [] as YardTotal[],
      visibleYardIDs: [] as string[],

      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5,

      // Table Footer page size options
      itemsPerPage: 25,
      itemsPerPageOptions: [5, 10, 15, 25, 50, -1]
    };
  },

  computed: {
    parts(): Part[] {
      return this.$store.state.parts.fullList as Part[];
    },
    yards(): Yard[] {
      let yards = this.$store.state.yards.fullList.slice() as Yard[];
      return yards.sort(compareYards);
    },
    tableHeaders(): any[] {
      let headers = [
        {
          text: this.$t("yard-balances.name-column-label") as string | TranslateResult | undefined,
          value: "name"
        },
        {
          text: this.$t("yard-balances.public-id-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "publicID"
        },
        {
          text: this.$t("yard-balances.description-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "description"
        }
      ];
      this.visibleYards.forEach(y => {
        headers.push({
          text: y.name,
          value: y.id!
        });
      });
      return headers;
    },
    usedYards(): Yard[] {
      return this.yards
        .slice()
        .sort(compareYards)
        .filter(
          y =>
            this.includeUnusedYards ||
            this.parsedPartTotals.findIndex((x: any) => x.countsByYardID[y.id!] != 0) !== -1
        );
    },
    visibleYards(): Yard[] {
      return this.usedYards.filter(
        y => !this.visibleYardIDs?.length || this.visibleYardIDs.includes(y.id!)
      );
    },
    parsedPartTotals(): {
      publicID: string | undefined;
      name: string | undefined;
      description: string | undefined;
      isUsed: boolean;
      countsByYardID: {};
    }[] {
      return this.parts
        .map(part => {
          let parsedTotal = {
            publicID: part.publicID,
            name: part.name,
            description: part.description,
            isUsed: false,
            countsByYardID: {}
          };

          let partIsUsed = false;
          this.yards.forEach(yard => {
            let yardID = yard.id!;
            // "STANDING" yard isn't a valid yard, but is used in SP1 when transfer to/from a scaffold
            // The service returns scaffold totals with a yard ID of an empty Guid
            if (yard.name?.toLowerCase() == "standing") {
              yardID = "00000000-0000-0000-0000-000000000000";
            }
            let partTotal = this.partTotals.find(t => t.partID == part.id && t.yardID == yardID);
            let actualTotal = partTotal?.total ?? 0;
            (parsedTotal as any)[yard.id!] = actualTotal;
            (parsedTotal.countsByYardID as any)[yard.id!] = actualTotal;
            if (actualTotal != 0) partIsUsed = true;
          });
          parsedTotal.isUsed = partIsUsed;
          return parsedTotal;
        })
        .filter(x => this.includeUnusedParts || x.isUsed);
    }
  },

  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadYards: "LOAD_YARDS",
      loadParts: "LOAD_PARTS"
    }),
    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await this.loadPartTotals();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async loadPartTotals() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }
      this.partTotals = await partService.getAllPartYardBalances();

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },
    async downloadAndPrintYardBalancesReport(reportType: string) {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        let parts = this.parsedPartTotals.map(x => {
          return {
            ...x
          } as any;
        });
        if (parts.length == 0) {
          this.$store.dispatch("SHOW_SNACKBAR", {
            text: this.$t("yard-balances.printing.no-data-message"),
            type: "info"
          });
          return;
        }
        let blob = await reportService.getYardBalancesPrintoutReportContentWithData(
          parts,
          this.visibleYards,
          reportType,
          localizedDateTimeString(new Date())
        );
        if (reportType == "xls") {
          downloadBlob(blob, "yard-balances.xlsx");
        } else {
          printBlob(blob, "yard-balances.pdf", "application/pdf");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },

  created: async function() {
    this.notifyNewBreadcrumb({
      text: this.$t("yard-balances.title"),
      to: "/people",
      resetHistory: true
    });

    this.inlineMessage.message = "";
    this.processing = true;
    try {
      await this.loadYards();
      this.visibleYardIDs = [];

      // this.processing = true;
      await this.loadParts();

      // this.processing = true;
      await this.loadPartTotals();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  }
});

