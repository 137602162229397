var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "countsheetexisting" } },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.preventSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: item =>
                                        _vm.$router.push(
                                          _vm.$store.getters.backBreadcrumb
                                            .to || `/countsheets/`
                                        )
                                    }
                                  }),
                                  _vm._v(" "),
                                  !!_vm.countSheet.reviewStatusName
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "12px",
                                            "line-height": "1.5"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ml-2 fd-status-chip"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm
                                                      .$t(
                                                        `countsheet.status.${_vm.countSheet.reviewStatusName}`
                                                      )
                                                      .toUpperCase()
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tabs",
                    {
                      ref: "tabs",
                      staticClass: "pt-3",
                      attrs: {
                        "icons-and-text": "",
                        "show-arrows": _vm.$vuetify.breakpoint.smAndUp,
                        "next-icon": "fa-arrow-circle-right",
                        "prev-icon": "fa-arrow-circle-left"
                      },
                      model: {
                        value: _vm.active_tab,
                        callback: function($$v) {
                          _vm.active_tab = $$v
                        },
                        expression: "active_tab"
                      }
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          key: _vm.countSheetTab.key,
                          ref: "tab",
                          attrs: { href: `#tab-${_vm.countSheetTab.key}` },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected(_vm.countSheetTab)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.countSheetTab.tabname) +
                              "\n            "
                          ),
                          _c("v-icon", [_vm._v("fas fa-list-ol")])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab",
                        {
                          key: _vm.workOrderTab.key,
                          ref: "tab",
                          attrs: { href: `#tab-${_vm.workOrderTab.key}` },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected(_vm.workOrderTab)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.workOrderTab.tabname) +
                              "\n            "
                          ),
                          _c("v-icon", [_vm._v("fas fa-file-alt")])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.hiddenTabDefinitions.length
                        ? _c(
                            "v-menu",
                            {
                              attrs: { bottom: "", left: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "align-self-center mr-4 d-flex d-sm-none more-tab-button",
                                                staticStyle: {
                                                  position: "relative"
                                                },
                                                attrs: { text: "" }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.$t("common.more")) +
                                                "\n\n                "
                                            ),
                                            _c(
                                              "div",
                                              [
                                                _c("v-icon", [
                                                  _vm._v("fas fa-ellipsis-h")
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3994478926
                              )
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                { staticClass: "grey lighten-3" },
                                _vm._l(_vm.hiddenTabDefinitions, function(
                                  item
                                ) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.tabname,
                                      on: {
                                        click: function($event) {
                                          return _vm.showNewTabFromMoreMenu(
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.tabname) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.active_tab,
                        callback: function($$v) {
                          _vm.active_tab = $$v
                        },
                        expression: "active_tab"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        {
                          key: _vm.countSheetTab.key,
                          attrs: { value: `tab-${_vm.countSheetTab.key}` }
                        },
                        [
                          _c("fd-count-sheet-form", {
                            attrs: {
                              "restrict-content-height": false,
                              workOrder: _vm.countSheet.workOrder,
                              countSheet: _vm.countSheet,
                              countSheetGroups: _vm.countSheetGroups,
                              "force-readonly": !_vm.countSheetCanBeSaved
                            },
                            on: {
                              "override:start": function($event) {
                                _vm.isDeclining = false
                                _vm.isOverriding = true
                              },
                              "override:cancel": function($event) {
                                _vm.isOverriding = false
                              },
                              "decline:start": function($event) {
                                _vm.isDeclining = true
                                _vm.isOverriding = false
                              },
                              "decline:cancel": function($event) {
                                _vm.isDeclining = false
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab-item",
                        {
                          key: _vm.workOrderTab.key,
                          attrs: { value: `tab-${_vm.workOrderTab.key}` }
                        },
                        [
                          _c("fd-work-order-details", {
                            attrs: { workOrder: _vm.countSheet.workOrder }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.inlineMessage.message
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c(
                                    "v-alert",
                                    { attrs: { type: _vm.inlineMessage.type } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-line"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.inlineMessage.message)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-md-flex",
                          attrs: {
                            outlined: "",
                            color: "success",
                            "data-cy": "approve",
                            loading: _vm.approving,
                            disabled:
                              _vm.processing ||
                              !_vm.countSheetCanBeApproved ||
                              _vm.isOverriding,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.approveCountSheet }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$vuetify.breakpoint.lgAndUp
                                  ? _vm.$t("countsheet.approval.accept")
                                  : _vm.$t("countsheet.approval.accept-short")
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-md-flex",
                          attrs: {
                            outlined: "",
                            color: "success",
                            "data-cy": "approve",
                            loading: _vm.approving,
                            disabled:
                              _vm.processing ||
                              !_vm.countSheetCanBeApproved ||
                              !_vm.isOverriding,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.approveCountSheet }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$vuetify.breakpoint.lgAndUp
                                  ? _vm.$t(
                                      "countsheet.approval.accept-with-overrides"
                                    )
                                  : _vm.$t(
                                      "countsheet.approval.accept-with-overrides-short"
                                    )
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-md-flex",
                          attrs: {
                            outlined: "",
                            color: "error",
                            "data-cy": "decline",
                            loading: _vm.declining,
                            disabled:
                              _vm.processing || !_vm.countSheetCanBeDeclined,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.declineCountSheet }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$vuetify.breakpoint.lgAndUp
                                  ? _vm.$t("countsheet.approval.return")
                                  : _vm.$t("countsheet.approval.return-short")
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 mr-3 d-none d-md-flex fd-button-context-separator-hide-when-mobile"
                        },
                        [_vm._v("\n            |\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            "data-cy": "close",
                            text: "",
                            link: "",
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancel }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.cancel")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("fd-menu-save", {
                        attrs: {
                          disabled: !_vm.countSheetCanBeSaved || _vm.processing,
                          loading: _vm.saving,
                          small: _vm.$vuetify.breakpoint.xsOnly
                        },
                        on: {
                          "click:save": function($event) {
                            return _vm.save(false)
                          },
                          "click:save-and-close": function($event) {
                            return _vm.save(true)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }