// TODO: Put all mapping stuff into its own control wrapper
import FDVue from "@fd/lib/vue";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
const iconOptions = {
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
};
L.Icon.Default.mergeOptions(iconOptions);

import { getConfiguration } from "../../../../config";
function getInspectionLocation() {
  // TODO: Replace this with a real service call
  // TODO: Include zoom level?
  return new Promise<{ latitude: number; longitude: number }>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      },
      error => {
        console.error(error);
        reject(error);
      },
      {
        enableHighAccuracy: true
      }
    );
  });
}

export default FDVue.extend({
  name: "fd-my-location",

  props: {
    latitude: { type: Number, default: 0, required: true },
    longitude: { type: Number, default: 0, required: true }
  },

  watch: {
    latitude() {
      this.loadLocationData();
    },
    longitude() {
      this.loadLocationData();
    }
  },

  methods: {
    async loadLocationData() {
      if (!this.latitude || !this.longitude) return;

      let url =
        "/services/FormidableDesigns.Services.V1.MapService.GetMapRoadTile?zoom={z}&x={x}&y={y}";
      // let location = await getInspectionLocation();
      // TODO: Why does $refs.mapContainer not have $el as the actual component? Am I misinterpreting this.$refs?
      let map = L.map(this.$refs.mapContainer as any).setView([this.latitude, this.longitude], 18);
      L.tileLayer(
        url,
        {
          attribution: `© ${new Date().getFullYear()} TomTom, Microsoft`,

          /*
              Tileset ID specifies which data layers to render in the tiles. Can be:
                                    
              'microsoft.base.road',  
              'microsoft.base.darkgrey',
              'microsoft.imagery', 
              'microsoft.weather.infrared.main', 
              'microsoft.weather.radar.main', 
              'microsoft.base.hybrid.road',
              'microsoft.base.labels.road '
          */
          tilesetId: "microsoft.base.road",

          //The language of labels. Supported languages: https://docs.microsoft.com/en-us/azure/azure-maps/supported-languages
          language: "en-US",

          //The regional view of the map. Supported views: https://aka.ms/AzureMapsLocalizationViews
          view: "Auto"
        } as any // Loose typing necessary due to typings file limitations; see comments in typings file for details
      ).addTo(map);
      const marker = L.marker([this.latitude, this.longitude]).addTo(map);
      console.log(marker);
    }
  },

  mounted() {
    (async () => {
      await this.loadLocationData();
    })();
  },

  beforeDestroy() {
    console.log(this.$refs.mapContainer);
  }
});

