type CountSheetGrouplike = {
  name: string | null | undefined;
  order: number | null | undefined;
};
function CompareCountSheetGroups(a: CountSheetGrouplike, b: CountSheetGrouplike) {
  let aOrder = a.order ?? 0;
  let bOrder = b.order ?? 0;
  if (aOrder != bOrder) return aOrder - bOrder;

  let aName = a.name!.toLocaleLowerCase();
  let bName = b.name!.toLocaleLowerCase();
  return aName < bName ? -1 : aName > bName ? 1 : 0;
}
export function SortCountSheetGroups<T extends CountSheetGrouplike>(items: T[] | undefined): T[] {
  if (!items) return [];
  return items.sort(CompareCountSheetGroups);
}

type Partlike = {
  name: string | null | undefined;
  countSheetGroupDisplayOrder: number | null | undefined;
};
function CompareParts(a: Partlike, b: Partlike) {
  let aOrder = a.countSheetGroupDisplayOrder ?? 0;
  let bOrder = b.countSheetGroupDisplayOrder ?? 0;
  if (aOrder != bOrder) return aOrder - bOrder;

  let aName = a.name!.toLocaleLowerCase();
  let bName = b.name!.toLocaleLowerCase();
  return aName < bName ? -1 : aName > bName ? 1 : 0;
}
export function SortParts<T extends Partlike>(items: T[] | undefined): T[] {
  if (!items) return [];
  return items.sort(CompareParts);
}

