// This function will strip out any HTML markup that is contained within the submitted string.
export function stripHtml(html: any): string {
  if (!html) return "";

  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;

  return tmp.textContent || tmp.innerText || "";
}

export function truncateWithEllipsis(
  string: string | null | undefined,
  length: number = 50
): string | null | undefined {
  if (!string?.length || string.length <= length) return string;
  return `${string.substring(0, length)}...`;
}

