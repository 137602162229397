<template>
  <v-app data-cy="app" :class="$store.state.themeName">
    <fd-app-bar v-if="showAppBar" />

    <fd-drawer v-if="showDrawer" />

    <!--    <fd-settings />-->
    <fd-view />
    <fd-snackbar />
    <fd-bottom-bar v-if="showBottomBar" />
  </v-app>
</template>

<script>
import { isoDateString, isoDateTimeString, isoString } from "@fd/lib/client-util/datetime";
import { environmentService } from "../services";
export default {
  name: "fd-index",

  components: {
    "fd-app-bar": () => import("./components/layout/AppBar.vue"),
    "fd-drawer": () => import("./components/layout/Drawer.vue"),
    "fd-bottom-bar": () => import("./components/layout/BottomBar.vue"),
    // "fd-settings": () => import("./components/layout/Settings"),
    "fd-view": () => import("./components/layout/View.vue"),
    "fd-snackbar": () => import("./components/layout/SnackBar.vue")
  },
  data: () => ({
    expandOnHover: false,
    maxInactivityTimeMinutes: 15,
    inactivityTimer: null
  }),

  computed: {
    // Used to store the value of the search bar in the store so that if you go to the existing screen and back,
    // that the users filtering setup they have chosen will be preserved.
    showAppBar: {
      get() {
        return this.$store.state.showAppBar;
      },
      set(val) {
        this.$store.commit("SET_SHOW_APP_BAR", val);
      }
    },
    showDrawer: {
      get() {
        return this.$store.state.showDrawer;
      },
      set(val) {
        this.$store.commit("SET_SHOW_DRAWER", val);
      }
    },
    showBottomBar: {
      get() {
        return this.$store.state.showBottomBar;
      },
      set(val) {
        this.$store.commit("SET_SHOW_BOTTOM_BAR", val);
      }
    }
  },

  created: function() {
    this.loadEnvironmentDetails();
  },

  methods: {
    setBrowserBrandSettings() {
      console.log(`index.setBrowserBrandSettings`);
      let theme = this.$store.state.themeName;
      let subFolder = "";
      let customFileTheme = "";
      let themecolor = "#494A49";

      if (theme == "venture") {
        subFolder = `/${theme}`;
        customFileTheme = theme;
        themecolor = "#B1CADA";
      }

      // We have a specific brand value, setup different assets
      // console.log(`** MANIFEST: ${document.getElementById("linkmanifest").href}`);
      document.getElementById("linkmanifest").href = `/assets/icon${subFolder}/manifest.json`;
      // console.log(`** MANIFEST: ${document.getElementById("linkmanifest").href}`);

      document.getElementById("metathemecolor").content = themecolor;

      let customFileThemeWithLeadingUnderscore = !!customFileTheme.length
        ? "_" + customFileTheme
        : "";

      let nowString = isoString(new Date());
      console.log(`nowString: ${nowString}`);
      // console.log(`customFileThemeWithLeadingUnderscore: ${customFileThemeWithLeadingUnderscore}`);
      // console.log(
      //   `\t sample href: /assets/splash_screens/iPhone_14_Pro_Max_landscape${customFileThemeWithLeadingUnderscore}.png`
      // );
      // <link id="(apple-touch-startup-image[0-9xa-z\-]*)" rel="apple-touch-startup-image" media="screen and \(device-width: [0-9]*px\) and \(device-height: [0-9]*px\) and \(-webkit-device-pixel-ratio: [0-9]\) and \(orientation: [a-z]*\)" href="/assets/splash_screens/([a-zA-Z_0-9\.]*).png">
      // document.getElementById("$1").href = `/assets/splash_screens/$2${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image430x932x3-landscape"
      ).href = `/assets/splash_screens/iPhone_14_Pro_Max_landscape${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image393x852x3-landscape"
      ).href = `/assets/splash_screens/iPhone_14_Pro_landscape${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image428x926x3-landscape"
      ).href = `/assets/splash_screens/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_landscape${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image390x844x3-landscape"
      ).href = `/assets/splash_screens/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_landscape${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image375x812x3-landscape"
      ).href = `/assets/splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_landscape${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image414x896x3-landscape"
      ).href = `/assets/splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_landscape${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image414x896x2-landscape"
      ).href = `/assets/splash_screens/iPhone_11__iPhone_XR_landscape${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image414x736x3-landscape"
      ).href = `/assets/splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_landscape${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image375x667x2-landscape"
      ).href = `/assets/splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_landscape${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image320x568x2-landscape"
      ).href = `/assets/splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_landscape${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image1024x1366x2-landscape"
      ).href = `/assets/splash_screens/12.9__iPad_Pro_landscape${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image834x1194x2-landscape"
      ).href = `/assets/splash_screens/11__iPad_Pro__10.5__iPad_Pro_landscape${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image820x1180x2-landscape"
      ).href = `/assets/splash_screens/10.9__iPad_Air_landscape${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image834x1112x2-landscape"
      ).href = `/assets/splash_screens/10.5__iPad_Air_landscape${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image810x1080x2-landscape"
      ).href = `/assets/splash_screens/10.2__iPad_landscape${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image768x1024x2-landscape"
      ).href = `/assets/splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_landscape${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image744x1133x2-landscape"
      ).href = `/assets/splash_screens/8.3__iPad_Mini_landscape${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image430x932x3-portrait"
      ).href = `/assets/splash_screens/iPhone_14_Pro_Max_portrait${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image393x852x3-portrait"
      ).href = `/assets/splash_screens/iPhone_14_Pro_portrait${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image428x926x3-portrait"
      ).href = `/assets/splash_screens/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_portrait${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image390x844x3-portrait"
      ).href = `/assets/splash_screens/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_portrait${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image375x812x3-portrait"
      ).href = `/assets/splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_portrait${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image414x896x3-portrait"
      ).href = `/assets/splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_portrait${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image414x896x2-portrait"
      ).href = `/assets/splash_screens/iPhone_11__iPhone_XR_portrait${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image414x736x3-portrait"
      ).href = `/assets/splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_portrait${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image375x667x2-portrait"
      ).href = `/assets/splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_portrait${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image320x568x2-portrait"
      ).href = `/assets/splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_portrait${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image1024x1366x2-portrait"
      ).href = `/assets/splash_screens/12.9__iPad_Pro_portrait${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image834x1194x2-portrait"
      ).href = `/assets/splash_screens/11__iPad_Pro__10.5__iPad_Pro_portrait${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image820x1180x2-portrait"
      ).href = `/assets/splash_screens/10.9__iPad_Air_portrait${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image834x1112x2-portrait"
      ).href = `/assets/splash_screens/10.5__iPad_Air_portrait${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image810x1080x2-portrait"
      ).href = `/assets/splash_screens/10.2__iPad_portrait${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image768x1024x2-portrait"
      ).href = `/assets/splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_portrait${customFileThemeWithLeadingUnderscore}.png`;
      document.getElementById(
        "apple-touch-startup-image744x1133x2-portrait"
      ).href = `/assets/splash_screens/8.3__iPad_Mini_portrait${customFileThemeWithLeadingUnderscore}.png`;

      // <link id="(linkappletouchicon[0-9]*)" rel="apple-touch-icon" sizes="[0-9x]*" href="(/assets/icon)(/apple-icon-[0-9x]*.png)" />
      // document.getElementById("$1").href = `$2${subFolder}$3`;
      document.getElementById(
        "linkappletouchicon"
      ).href = `/assets/icon${subFolder}/apple-icon-180x180.png`;
      document.getElementById(
        "linkappletouchicon57"
      ).href = `/assets/icon${subFolder}/apple-icon-57x57.png`;
      document.getElementById(
        "linkappletouchicon60"
      ).href = `/assets/icon${subFolder}/apple-icon-60x60.png`;
      document.getElementById(
        "linkappletouchicon72"
      ).href = `/assets/icon${subFolder}/apple-icon-72x72.png`;
      document.getElementById(
        "linkappletouchicon76"
      ).href = `/assets/icon${subFolder}/apple-icon-76x76.png`;
      document.getElementById(
        "linkappletouchicon114"
      ).href = `/assets/icon${subFolder}/apple-icon-114x114.png`;
      document.getElementById(
        "linkappletouchicon120"
      ).href = `/assets/icon${subFolder}/apple-icon-120x120.png`;
      document.getElementById(
        "linkappletouchicon144"
      ).href = `/assets/icon${subFolder}/apple-icon-144x144.png`;
      document.getElementById(
        "linkappletouchicon152"
      ).href = `/assets/icon${subFolder}/apple-icon-152x152.png`;
      document.getElementById(
        "linkappletouchicon180"
      ).href = `/assets/icon${subFolder}/apple-icon-180x180.png`;

      document.getElementById(
        "linkicon192"
      ).href = `/assets/icon${subFolder}/android-icon-192x192.png?v=${nowString}`;
      document.getElementById(
        "linkicon228"
      ).href = `/assets/icon${subFolder}/android-icon-192x192.png?v=${nowString}`;
      document.getElementById(
        "linkicon32"
      ).href = `/assets/icon${subFolder}/favicon-32x32.png?v=${nowString}`;
      document.getElementById(
        "linkicon48"
      ).href = `/assets/icon${subFolder}/favicon-32x32.png?v=${nowString}`;
      document.getElementById(
        "linkicon96"
      ).href = `/assets/icon${subFolder}/favicon-96x96.png?v=${nowString}`;
      document.getElementById(
        "linkicon16"
      ).href = `/assets/icon${subFolder}/favicon-16x16.png?v=${nowString}`;
      document.getElementById(
        "linkshortcuticon"
      ).href = `/assets/icon${subFolder}/favicon.ico?v=${nowString}`;

      document.getElementById(
        "linkmaskicon"
      ).href = `/assets/icon${subFolder}/apple-icon-precomposed.png?v=${nowString}`;

      document.getElementById(
        "metatileimage"
      ).content = `/assets/icon${subFolder}/ms-icon-144x144.png`;

      window.registerWorker(customFileTheme);
    },
    async loadEnvironmentDetails() {
      let theme = await environmentService.getEnvironmentThemeName();
      this.$store.commit("SET_THEME", theme);

      try {
        let applicationName = await environmentService.getApplicationName();
        this.$store.commit("SET_APPLICATION_NAME", applicationName);
      } catch (error) {}
      // Calling this automatically sets `document.title` to the application name

      this.setBrowserBrandSettings();
      this.setLoginTimeout();
    },
    async setLoginTimeout() {
      let loginTimeout = await environmentService.getEnvironmentLoginTimeoutMinutes();

      if (!!loginTimeout) this.maxInactivityTimeMinutes = loginTimeout;

      window.onload = this.resetInactivityTimer;
      document.onmousemove = this.resetInactivityTimer;
      document.onkeydown = this.resetInactivityTimer;
      document.onscroll = this.resetInactivityTimer;
      document.onclick = this.resetInactivityTimer;
      document.onwheel = this.resetInactivityTimer;
      document.onfocus = this.resetInactivityTimer;
    },
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimer);
      if (this.$route.name == "Login") return;
      this.inactivityTimer = setTimeout(this.logout, this.maxInactivityTimeMinutes * 1000 * 60);
    },
    logout() {
      if (this.$route.name == "Login") return;

      this.$store.commit("SET_LOGIN_RETURN_PATH", this.$route.fullPath);
      this.$router.push("/login");
    }
  }
};
</script>

