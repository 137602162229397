var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "1024px", "data-cy": "usersecuritysummarydialog" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [_vm._v(_vm._s(_vm.$t("people.security.summary-dialog.title")))]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            {
              staticClass: "pa-0",
              staticStyle: {
                "max-height": "calc(100vh - 225px)",
                overflow: "auto"
              },
              attrs: { fluid: "" }
            },
            [
              _c(
                "v-expansion-panels",
                { attrs: { accordion: "" } },
                [
                  _c("details-form", {
                    attrs: { details: _vm.menuVisibilityPermissions }
                  }),
                  _vm._v(" "),
                  _c("details-form", {
                    attrs: { details: _vm.scaffoldRequestPermissions }
                  }),
                  _vm._v(" "),
                  _c("details-form", {
                    attrs: { details: _vm.scaffoldPermissions }
                  }),
                  _vm._v(" "),
                  _c("details-form", {
                    attrs: { details: _vm.walkDownPermissions }
                  }),
                  _vm._v(" "),
                  _c("details-form", {
                    attrs: { details: _vm.workOrderPermissions }
                  }),
                  _vm._v(" "),
                  _c("details-form", {
                    attrs: { details: _vm.materialPermissions }
                  }),
                  _vm._v(" "),
                  _c("details-form", {
                    attrs: { details: _vm.laborPermissions }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: _vm.$vuetify.breakpoint.xsOnly },
                  on: { click: _vm.cancelDialog }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.close")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }