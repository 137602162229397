import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "../../../../../lib/vue/mixins/dialogSupport";
import { InspectionTime } from "../../../services";

type ScaffoldInspectionTimeDialogResult = InspectionTime | undefined;

const ScaffoldInspectionTimeDialog = FDVue.extend({
  name: "sp-scaffold-inspection-time-dialog",

  mixins: [dialogSupport],

  components: {
    "fd-time-picker": () => import("@fd/lib/vue/components/TimePicker.vue")
  },

  data: function() {
    return {
      styles: [] as string[],
      inspectionTime: {
        style: "",
        name: "",
        time: new Date()
      } as InspectionTime
    };
  },

  computed: {},

  watch: {},

  methods: {
    async open(allowedStyles: string[]): Promise<ScaffoldInspectionTimeDialogResult> {
      this.styles = allowedStyles;

      if (await this.showDialog()) return this.inspectionTime;
      else return undefined;
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    async acceptDialog() {
      if (!(this.$refs.form as HTMLFormElement).validate()) return;

      this.closeDialog(true);
    }
  }
});

export default ScaffoldInspectionTimeDialog;

export async function openNewScaffoldInspectionTimeDialog(
  allowedStyles: string[]
): Promise<ScaffoldInspectionTimeDialogResult> {
  let dialog = createDialog(ScaffoldInspectionTimeDialog);
  dialog.optOutOfErrorHandling();
  let result = await dialog.open(allowedStyles);
  return result;
}

