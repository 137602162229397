import { projectService, ProjectWithParts } from "../services/index";
import { projectLocationService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";
import i18n from "../i18n";
import { showDisableInsteadConfirmation } from "../../../common/client/views/components/DisableInsteadDialog.vue";

var storeModule = createAutomaticCrudStoreModule({
  crudService: projectService,
  singularStoreName: "PROJECT",
  pluralStoreName: "PROJECTS",
  localizationPrefix: "projects",
  consumerRelatedIDProperty: {
    name: "projectID",
    type: "string"
  },
  storeExtensions: {
    mutations: {
      // REPLACE_PROJECTS_FOR_CLIENT(state, payload: { projects: ProjectWithParts[]; clientID: string }) {
      //   let projects = state.fullList.filter(x => x.clientID != payload.clientID);
      //   projects = projects.concat(payload.projects);
      //   state.fullList = projects;
      // },
      UPDATE_PROJECT_PARTS(
        state,
        payload: {
          projectID: string;
          partIDs: string[];
          included: boolean;
        }
      ) {
        let project = state.fullList.find(x => x.id == payload.projectID);
        if (!project) return;
        if (payload.included) {
          let combinedPartIDs = new Set(project.partIDs);
          for (let partID of payload.partIDs) {
            combinedPartIDs.add(partID);
          }
          project.partIDs = Array.from(combinedPartIDs.values());
        } else if (project.partIDs) {
          let removedPartIDs = new Set(payload.partIDs);
          project.partIDs = project.partIDs.filter(x => !removedPartIDs.has(x));
        }
      }
    },
    actions: {
      async LOAD_PROJECT_WITH_AREAS(
        context,
        payload: {
          projectID: string;
          forcedArchivedState: boolean;
          archivedFromDate: Date | null;
          archivedToDate: Date | null;
        }
      ) {
        let [project, areas] = await Promise.all([
          projectService.getByID(payload.projectID),
          projectLocationService.getByProjectID(
            payload.projectID,
            payload.forcedArchivedState,
            payload.archivedFromDate,
            payload.archivedToDate
          )
        ]);
        context.commit("SET_PROJECT", project);
        context.commit("REPLACE_LOCATIONS_FOR_PROJECT", {
          projectLocations: areas,
          projectID: payload.projectID
        });
      },
      async UPDATE_PROJECT_PARTS(
        context,
        payload: {
          projectID: string;
          partIDs: string[];
          included: boolean;
        }
      ) {
        await projectService.updateProjectParts(
          payload.projectID,
          payload.partIDs,
          payload.included
        );
        context.commit("UPDATE_PROJECT_PARTS", payload);
        context.dispatch("SHOW_SNACKBAR", {
          text: i18n.t("projects.project-parts-updated"),
          type: "success"
        });
      },
      async DELETE_PROJECT(context, payload): Promise<boolean> {
        // For this one, we need to do a full load before deleting because we don't have a
        // transaction-based undo yet and we need to make sure we have all child records
        // (namely selected parts) so we can properly restore
        await context.dispatch("LOAD_PROJECT", payload.id);

        let deletedItem = context.state.fullList.find(x => x.id == payload.id)!;
        if (await projectService.deleteItem(payload.id)) {
          context.commit("DELETE_PROJECT", payload);
          context.dispatch("SHOW_SNACKBAR", {
            text: i18n.t("projects.snack-bar-delete-message", [payload.name]),
            type: "info",
            undoCallback: async function() {
              context.dispatch("ADD_PROJECT", deletedItem);
            }
          });
          return true;
        } else {
          if (await showDisableInsteadConfirmation(!deletedItem.archivedDate)) {
            await context.dispatch("UPDATE_PROJECT", {
              id: deletedItem.id,
              archivedDate: new Date(new Date().toUTCString()),
              name: deletedItem.name
            });
          }
          return false;
        }
      }
    }
  }
});

export default storeModule;
