import { mapMutations, mapActions, mapState } from "vuex";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import rules from "@fd/lib/vue/rules";
import { System, TestPackage } from "@fd/current/client/services";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import tabbedView, { PageTab, Tab } from "@fd/lib/vue/mixins/tabbedView";
import archivedDataList from "../dataMixins/archivedDataList";
import { showTestPackageNewWithSystemDialog } from "./components/dialogs/SP.TestPackageNewDialog.vue";
import { SortItemsWithName } from "../utils/person";
import { stripHtml, truncateWithEllipsis } from "@fd/lib/vue/utility/helper";

type SystemWithArchived = System & { archived: boolean };
type TestPackageWithArchived = TestPackage & { archived: boolean };

export default FDVue.extend({
  name: "sp-system-existing",

  mixins: [errorHandling, tabbedView, rules, archivedDataList],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      slidein: false,

      firstTabKey: `1`,
      detailsTab: new PageTab({
        nameKey: "systems.existing.tabs.details",
        key: `1`,
        visible: true
      }),
      testPackagesTab: new PageTab({
        nameKey: "systems.existing.tabs.test-packages",
        key: "2",
        visible: false
      }),

      system: {
        name: "",
        code: "",
        description: "",
        archived: false
      } as SystemWithArchived
    };
  },

  computed: {
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.testPackagesTab] as Tab[];
    },

    storeSystem(): System {
      return this.$store.state.systems.fullList.find((x: System) => x.id == this.$route.params.id);
    },

    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    // Get all the test packages that are associated to the current system object.
    testPackages(): {}[] {
      return SortItemsWithName(
        this.$store.state.testPackages.fullList.map((x: TestPackage) => {
          return {
            ...x,
            description: truncateWithEllipsis(stripHtml(x.description)),
            archived: !!x.archivedDate
          };
        })
      );
    }
  },

  methods: {
    backButtonClicked() {
      this.$router.push(`/systems/`);
    },
    cancel() {
      this.$router.push("/systems");
    },
    ...mapMutations({
      setSystem: "SET_SYSTEM",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadSystem: "LOAD_SYSTEM",
      updateSystem: "UPDATE_SYSTEM",
      deleteSystem: "DELETE_SYSTEM",
      loadTestPackagesBySystemID: "LOAD_TEST_PACKAGES_BY_SYSTEM_ID",
      deleteTestPackage: "DELETE_TEST_PACKAGE",
      updateTestPackage: "UPDATE_TEST_PACKAGE"
    }),
    async loadData() {
      await this.loadTestPackagesBySystemID({
        systemID: this.$route.params.id,
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    async openNewTestPackagesDialog() {
      await showTestPackageNewWithSystemDialog(this.$route.params.id);
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.system.archived) {
          this.system.archivedDate = null;
        } else if (this.system.archived && !this.system.archivedDate) {
          this.system.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateSystem({
          ...this.system,
          id: this.$route.params.id
        });

        if (closeOnComplete) {
          this.$router.push("/systems");
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteSystem({ id: this.$route.params.id, name: this.system.name });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
        this.$router.push("/systems");
      }
    },

    navigateToTestPackage(item: TestPackage) {
      this.$router.push(`/system-testpackages/${item.id}`);
    },

    async flipTestPackageArchived(item: TestPackageWithArchived) {
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateTestPackage({ id: item.id, archivedDate: archivedDate, name: item.name });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }
    },

    async deleteTestPackagesTableItem(item: TestPackage) {
      this.processing = true;
      try {
        await this.deleteTestPackage({ id: item.id, name: item.name });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },

  watch: {
    system(newValue) {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/systems") {
        this.notifyNewBreadcrumb({
          text: this.$t("systems.list.title"),
          to: "/systems",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: newValue.name,
        to: `/systems/${this.$route.params.id}`
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    this.setFilteringContext({
      context: "systems-existing",
      parentalContext: "systems",
      searchStringForFiltering: "",
      tagsForFiltering: [],
      suppliersForFiltering: [],
      selectedTab: this.firstTabKey,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: new Date(0),
      showArchivedForFilteringToDate: new Date()
    });
    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/systems") {
      this.notifyNewBreadcrumb({
        text: this.$t("systems.list.title"),
        to: "/systems",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }
    this.notifyNewBreadcrumb({
      text: this.$t("loading-dot-dot-dot"),
      disabled: true
    });

    this.processing = true;
    try {
      await this.loadData();
      await this.loadSystem(this.$route.params.id);
      var system = this.$store.state.systems.fullList.find(
        (x: System) => x.id == this.$route.params.id
      );
      this.system = {
        ...system,
        archived: !!system.archivedDate
      } as SystemWithArchived;

      this.notifyNewBreadcrumb({
        text: this.system.name,
        to: `/systems/${this.$route.params.id}`
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.processing = false;
    }
  }
});

