import { PropType } from "vue";
import FDVue from "../../../../../lib/vue";
import { TimesheetRow, TimesheetRowType } from "../../../utils/timesheet";
import { BasicSelectItem } from "../../../../../lib/vue/utility/select";
import { formatWorkOrderNumber } from "../../../utils/workorder";

export default FDVue.extend({
  name: "sp-timesheet-perdiem-view",
  props: {
    item: { type: Object as PropType<TimesheetRow> },

    disabled: { type: Boolean },
    readonly: { type: Boolean },

    perDiemIsWorkOrderRelated: { type: Boolean },

    otherPerDiemTimesheetOwner: { type: String },

    otherRowOnTimesheetHasPerDiem: { type: Boolean },
    relatedRowHasEffectivePerDiem: { type: Boolean },
    otherPerDiemRowWorkOrderNumber: { type: String }
  },
  computed: {
    hasPerDiemOnOtherTimesheet(): boolean {
      return !!this.otherPerDiemTimesheetOwner?.length;
    },
    // Only indirect work rows can have per diem entry
    isPerDiemApplicable(): boolean {
      if (!this.item) return false;

      if (this.perDiemIsWorkOrderRelated == true)
        return this.item.rowType != TimesheetRowType.Equipment;

      return (
        !this.item.workOrderID &&
        (this.item.rowType == TimesheetRowType.DirectGeneral ||
          this.item.rowType == TimesheetRowType.Indirect)
      );
    },
    addCorrectionItems(): BasicSelectItem[] {
      return [
        { text: this.$t("timesheets.existing.correction-no-per-diem-change"), value: false },
        { text: this.$t("timesheets.existing.correction-add-per-diem"), value: true }
      ];
    },
    removeCorrectionItems(): BasicSelectItem[] {
      return [
        { text: this.$t("timesheets.existing.correction-no-per-diem-change"), value: false },
        { text: this.$t("timesheets.existing.correction-remove-per-diem"), value: true }
      ];
    },
    perDiemCorrectionItems(): BasicSelectItem[] {
      if (this.hasPerDiemOnOtherTimesheet) return [];
      if (!this.item) return [];

      if (this.item.hasPerDiem) {
        return this.addCorrectionItems;
      }

      if (this.otherRowOnTimesheetHasPerDiem) {
        return this.removeCorrectionItems;
      } else {
        return this.addCorrectionItems;
      }
    },
    // Only one row can have a per diem per employee
    canEditPerDiem(): boolean {
      if (!this.item) return false;
      // Per Diem's can ONLY be associated to non-work-order timesheet rows
      if (!!this.item.workOrderID && !this.perDiemIsWorkOrderRelated) return false;
      if (this.hasPerDiemOnOtherTimesheet) return false;
      // Only admins on indirect timesheets can edit per diems
      if (this.item.rowType == TimesheetRowType.Equipment) return false;
      if (this.otherRowOnTimesheetHasPerDiem) return false;
      return true;
    },
    canCorrectPerDiem(): boolean {
      if (!this.item || !this.item.isCorrectionRow) return false;
      // Per Diem's can ONLY be associated to non-work-order timesheet rows
      if (!!this.item.workOrderID && !this.perDiemIsWorkOrderRelated) return false;
      if (this.hasPerDiemOnOtherTimesheet) return false;
      // Only admins on indirect timesheets can edit per diems
      if (this.item.rowType == TimesheetRowType.Equipment) return false;

      // If a correction row has `hasPerDiem` already true, then it's editable
      // There cannot be multiple correction rows, so if this is true then it's the only effective PD row
      if (this.otherRowOnTimesheetHasPerDiem)
        return this.item.hasPerDiem || this.item.relatedCorrectionHasPerDiem;
      return true;
    },
    perDiemReadonlyReason() {
      if (!this.item) return undefined;
      if (this.canEditPerDiem) return undefined;

      if (!!this.hasPerDiemOnOtherTimesheet) {
        return this.$t("timesheets.per-diem-already-applied-chip-label");
      }
      if (!this.otherRowOnTimesheetHasPerDiem) return undefined;

      if (!!this.otherPerDiemRowWorkOrderNumber?.length) {
        return this.$t("timesheets.per-diem-other-work-order-applied-chip-label", [
          formatWorkOrderNumber(this.otherPerDiemRowWorkOrderNumber)
        ]);
      } else {
        return this.$t("timesheets.per-diem-general-applied-chip-label");
      }
    }
  },
  methods: {
    hasPerDiemValueChanged(value: boolean) {
      this.$emit("update:hasPerDiem", value);
    },
    removePerDiemValueChanged(value: boolean) {
      this.$emit("update:removePerDiem", value);
    }
  }
});

