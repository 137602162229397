import { workSubTypeService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: workSubTypeService,
  singularStoreName: "WORK_SUB_TYPE",
  pluralStoreName: "WORK_SUB_TYPES",
  localizationPrefix: "work-types.work-sub-types",
  consumerRelatedIDProperty: {
    name: "workSubTypeID",
    type: "string"
  }
});

export default storeModule;

