import FDVue from "@fd/lib/vue";
import rules from "@fd/lib/vue/rules";
import { PartWithTags } from "../../../services";
import { HasName } from "../../../utils/person";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";
import { parseBCountString } from "../../../utils/bcount";

type TakeoffLike = {
  id: string | undefined;
  partID: string | undefined;
  quantity: number | undefined;
  estimatedErectMinutes: number | null | undefined;
  estimatedDismantleMinutes: number | null | undefined;
  estimatedModifyMinutes: number | null | undefined;
  estimatedMobilizationMinutes: number | null | undefined;
  estimatedHoardingMinutes: number | null | undefined;
  created: Date | undefined;
  createdBy: string | null | undefined;
  updated: Date | null | undefined;
  updatedBy: string | null | undefined;
  archivedDate: Date | null | undefined;
};

const EstimateDesignedForm = FDVue.extend({
  name: "sp-designed-estimate-form",
  mixins: [rules],
  components: {},
  directives: {
    fdColumn: FDColumnDirective
  },
  props: {
    bCountString: { type: String, default: "" }
  },
  data: function() {
    return {
      showingInvalidPartCodes: false
    };
  },
  computed: {
    allParts(): PartWithTags[] {
      return this.$store.state.parts.fullList as PartWithTags[];
    },
    invalidBCountPartCodes(): string[] {
      let partQuantities = parseBCountString(this.bCountString);
      return partQuantities
        .filter(pq => this.allParts.findIndex(p => p.name == pq.code) === -1)
        .map(pq => pq.code);
    },
    bCountPartQuantities(): (TakeoffLike & HasName)[] {
      let partQuantities = parseBCountString(this.bCountString);
      let bCountPartQuantities = partQuantities
        .filter(pq => this.allParts.findIndex(p => p.name == pq.code) !== -1)
        .map(pq => {
          let part = this.allParts.find(p => p.name == pq.code)!;
          return {
            partID: part.id,
            name: part.name,
            quantity: pq.quantity
          } as TakeoffLike & HasName;
        });

      return bCountPartQuantities;
    }
  },
  watch: {},
  methods: {},
  mounted: async function() {}
});

export default EstimateDesignedForm;

