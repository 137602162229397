import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { TranslateResult } from "vue-i18n";

type TextPromptDialogResult = string | undefined;

const TextPromptDialog = FDVue.extend({
  name: "fd-text-prompt-dialog",

  mixins: [dialogSupport],

  components: {},

  data() {
    return {
      title: "" as string | TranslateResult,
      fieldLabel: this.$t("common.text") as string | TranslateResult,
      rules: [] as any[],
      text: "" as string
    };
  },

  computed: {},

  mounted: async function() {},

  methods: {
    async open(
      title?: string | TranslateResult,
      label?: string | TranslateResult,
      rules?: any[],
      text?: string | undefined
    ): Promise<TextPromptDialogResult> {
      if (!!title) this.title = title;
      if (!!label) this.fieldLabel = label;
      if (!!rules) this.rules = rules;
      if (!!text) this.text = text;

      if (await this.showDialog()) return this.text;
      else return undefined;
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    acceptDialog() {
      if (!(this.$refs.form as HTMLFormElement).validate()) return;

      this.closeDialog(true);
    }
  }
});

export default TextPromptDialog;
export type TextPromptDialogOptions = {
  title?: string | TranslateResult;
  label?: string | TranslateResult;
  rules?: any[];
  text?: string | undefined;
};

export async function showTextPromptDialog(
  options?: TextPromptDialogOptions
): Promise<TextPromptDialogResult> {
  let dialog = createDialog(TextPromptDialog);
  dialog.optOutOfErrorHandling();
  let result = await dialog.open(options?.title, options?.label, options?.rules, options?.text);
  console.log("showTextPromptDialog.result: " + JSON.stringify(result));
  return result;
}

