import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";

const WorkOrderNewDialog = FDVue.extend({
  name: "fd-work-order-base-dialog",
  components: {
    "fd-work-order-bulk-upload-form": () => import("./WorkOrderBulkUploadForm.vue"),
    "fd-work-order-new-form": () => import("./WorkOrderNewForm.vue")
  },
  mixins: [dialogSupport, errorHandling],
  data: () => ({
    // Variable to hold which expansion panel is currently open
    panel: 0
  }),
  methods: {
    cancelDialog() {
      this.closeDialog!(false);
    },
    processingUpdated(processing: boolean) {
      this.processing = processing;
    }
  },
  created: async function() {}
});

export default WorkOrderNewDialog;

export async function createNewWorkOrderDialog(): Promise<boolean> {
  let dialog = createDialog(WorkOrderNewDialog);
  return await dialog.showDialog!();
}
