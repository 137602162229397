var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "fieldset",
    {
      staticClass: "fp-grouped-control-fieldset",
      class: { "fp-disabled": _vm.disabled }
    },
    [
      _c("legend", { staticClass: "fp-grouped-control-fieldset--legend" }, [
        _c(
          "div",
          { staticClass: "fp-grouped-control-fieldset--legend-label" },
          [_vm._v("\n      " + _vm._s(_vm.label) + "\n    ")]
        )
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "fp-grouped-control-fieldset--contents",
          attrs: { "data-cy": !!_vm.cy ? _vm.cy : _vm.dataCy }
        },
        [
          _c(
            "v-col",
            { attrs: { cols: _vm.colSpan } },
            [
              _c("v-autocomplete", {
                ref: "hourselect",
                attrs: {
                  outlined: "",
                  name: "hourselect",
                  label: _vm.$t("common.hours"),
                  disabled: _vm.disabled,
                  items: _vm.hourOptions,
                  "hide-details": true,
                  dense: ""
                },
                model: {
                  value: _vm.hoursValue,
                  callback: function($$v) {
                    _vm.hoursValue = $$v
                  },
                  expression: "hoursValue"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              class: { "last-col": !_vm.showSeconds },
              attrs: { cols: _vm.colSpan }
            },
            [
              _c("v-autocomplete", {
                ref: "minuteselect",
                attrs: {
                  outlined: "",
                  name: "minuteselect",
                  label: _vm.$t("common.minutes"),
                  disabled: _vm.disabled,
                  items: _vm.minuteOptions,
                  "hide-details": true,
                  dense: ""
                },
                model: {
                  value: _vm.minutesValue,
                  callback: function($$v) {
                    _vm.minutesValue = $$v
                  },
                  expression: "minutesValue"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.showSeconds
            ? _c(
                "v-col",
                { attrs: { cols: _vm.colSpan } },
                [
                  _c("v-autocomplete", {
                    ref: "secondselect",
                    attrs: {
                      outlined: "",
                      name: "secondselect",
                      label: _vm.$t("common.seconds"),
                      disabled: _vm.disabled,
                      items: _vm.secondOptions,
                      "hide-details": true,
                      dense: ""
                    },
                    model: {
                      value: _vm.secondsValue,
                      callback: function($$v) {
                        _vm.secondsValue = $$v
                      },
                      expression: "secondsValue"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }