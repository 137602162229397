import { dropoffLocationService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: dropoffLocationService,
  singularStoreName: "DROPOFF_LOCATION",
  pluralStoreName: "DROPOFF_LOCATIONS",
  localizationPrefix: "dropoff-locations",
  // The following is the name of the ID property used by foreign objects.
  consumerRelatedIDProperty: {
    name: "dropoffLocationID",
    type: "string"
  }
});

export default storeModule;

