var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      !_vm.item
        ? _c("fd-value-display", { attrs: { value: undefined } })
        : !!_vm.hasPerDiemOnOtherTimesheet
        ? _c(
            "div",
            {
              staticClass: "fd-table-column-text-end-override",
              staticStyle: { position: "relative" }
            },
            [
              _c("fd-checkbox", {
                staticStyle: { display: "inline" },
                attrs: {
                  value: _vm.item.hasPerDiem,
                  disabled: true,
                  readonly: true,
                  "on-text": _vm.item.isCorrectionRow
                    ? _vm.$t("timesheets.existing.correction-add-per-diem")
                    : _vm.$t("timesheets.per-diem-applied-chip-label"),
                  "off-text": _vm.$t(
                    "timesheets.per-diem-already-applied-chip-label"
                  ),
                  "off-color": "#898F9E",
                  "off-text-color": "white",
                  "chip-text-style":
                    !!_vm.item.hasPerDiem && !_vm.item.effectiveHasPerDiem
                      ? "text-decoration: line-through;"
                      : ""
                }
              }),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function({ on }) {
                        return [
                          _c(
                            "v-icon",
                            _vm._g(
                              {
                                staticClass: "ml-1",
                                staticStyle: { position: "absolute" },
                                attrs: { color: "#898F9E", dark: "" }
                              },
                              on
                            ),
                            [_vm._v("info")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "timesheets.per-diem-already-applied-information",
                          [_vm.otherPerDiemTimesheetOwner]
                        )
                      )
                    )
                  ])
                ]
              )
            ],
            1
          )
        : !_vm.isPerDiemApplicable
        ? _c(
            "div",
            { staticClass: "fd-table-column-text-end-override" },
            [
              !!_vm.item.hasPerDiem
                ? _c("fd-checkbox", {
                    staticStyle: { display: "inline" },
                    attrs: {
                      disabled: true,
                      readonly: true,
                      value: true,
                      "on-text": _vm.item.isCorrectionRow
                        ? _vm.$t("timesheets.existing.correction-add-per-diem")
                        : _vm.$t("timesheets.per-diem-applied-chip-label"),
                      "chip-text-style": !_vm.item.effectiveHasPerDiem
                        ? "text-decoration: line-through;"
                        : ""
                    }
                  })
                : !!_vm.item.removePerDiem
                ? _c("fd-checkbox", {
                    staticStyle: { display: "inline" },
                    attrs: {
                      disabled: true,
                      readonly: true,
                      value: false,
                      "off-text": _vm.$t(
                        "timesheets.existing.correction-remove-per-diem"
                      ),
                      "chip-text-style": !_vm.item.effectiveRemovePerDiem
                        ? "text-decoration: line-through;"
                        : ""
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.otherRowOnTimesheetHasPerDiem &&
              !_vm.item.relatedCorrectionHasPerDiem
                ? _c("fd-checkbox", {
                    staticStyle: { display: "inline" },
                    attrs: {
                      value: true,
                      disabled: _vm.disabled,
                      readonly: true,
                      "on-text": _vm.$t(
                        "timesheets.per-diem-applied-chip-label"
                      )
                    }
                  })
                : _c("fd-value-display", { attrs: { value: undefined } })
            ],
            1
          )
        : !_vm.item.isCorrectionRow
        ? _c(
            "div",
            { staticClass: "fd-table-column-text-end-override" },
            [
              _c("fd-checkbox", {
                staticStyle: { display: "inline" },
                attrs: {
                  value: _vm.item.hasPerDiem,
                  disabled: _vm.disabled,
                  readonly: !_vm.canEditPerDiem || _vm.readonly,
                  "off-text": _vm.perDiemReadonlyReason,
                  "chip-text-style":
                    !!_vm.item.hasPerDiem && !_vm.item.effectiveHasPerDiem
                      ? "text-decoration: line-through;"
                      : ""
                },
                on: { input: _vm.hasPerDiemValueChanged }
              })
            ],
            1
          )
        : !!_vm.item.isCorrectionRow
        ? _c(
            "div",
            { staticClass: "fd-table-column-text-end-override" },
            [
              _vm.otherRowOnTimesheetHasPerDiem &&
              !_vm.item.relatedCorrectionHasPerDiem
                ? _c("fd-checkbox", {
                    staticStyle: { display: "inline" },
                    attrs: {
                      value: true,
                      disabled: _vm.disabled,
                      readonly: true,
                      "on-text": _vm.$t(
                        "timesheets.per-diem-applied-chip-label"
                      )
                    }
                  })
                : _vm.canCorrectPerDiem &&
                  (!!_vm.item.removePerDiem ||
                    !!_vm.item.relatedCorrectionHasPerDiem)
                ? _c("v-select", {
                    attrs: {
                      label: "",
                      items: _vm.removeCorrectionItems,
                      disabled: _vm.disabled
                    },
                    on: { input: _vm.removePerDiemValueChanged },
                    model: {
                      value: _vm.item.removePerDiem,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "removePerDiem", $$v)
                      },
                      expression: "item.removePerDiem"
                    }
                  })
                : _vm.canCorrectPerDiem
                ? _c("v-select", {
                    attrs: {
                      label: "",
                      items: _vm.addCorrectionItems,
                      disabled: _vm.disabled
                    },
                    on: { input: _vm.hasPerDiemValueChanged },
                    model: {
                      value: _vm.item.hasPerDiem,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "hasPerDiem", $$v)
                      },
                      expression: "item.hasPerDiem"
                    }
                  })
                : _c("fd-checkbox", {
                    staticStyle: { display: "inline" },
                    attrs: {
                      value: false,
                      disabled: true,
                      readonly: true,
                      "off-text": _vm.$t(
                        "timesheets.existing.correction-no-per-diem-change"
                      ),
                      "off-color": "#898F9E",
                      "off-text-color": "white"
                    }
                  })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }