import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";
import {
  TakeoffPartWithDetails,
  scaffoldDesignWorkingTakeoffService,
  workOrderWorkingTakeoffService
} from "../../../services";
import { buildBCountString } from "../../../utils/bcount";

type WorkingEstimateTakeoffDialogReturnType = boolean;
const WorkingEstimateTakeoffDialog = FDVue.extend({
  name: "sp-work-order-working-estimate-takeoff-dialog",

  mixins: [dialogSupport],
  directives: {
    fdColumn: FDColumnDirective
  },
  data: function() {
    return {
      workOrderID: "",
      scaffoldDesignID: "",
      takeoffParts: [] as TakeoffPartWithDetails[],
      bCountCopied: false
    };
  },
  computed: {
    partsBCount(): string {
      var bCount = "";
      (this.takeoffParts as TakeoffPartWithDetails[]).forEach(part => {
        let partBCount = buildBCountString({ name: part.partName, quantity: part.quantity });
        if (!partBCount.length) return;
        if (!!bCount.length) bCount += "\n";
        bCount += partBCount;
      });
      return bCount;
    }
  },
  methods: {
    async open(
      workOrderID: string | undefined,
      scaffoldDesignID: string | undefined
    ): Promise<WorkingEstimateTakeoffDialogReturnType> {
      this.workOrderID = workOrderID ?? "";
      this.scaffoldDesignID = scaffoldDesignID ?? "";

      this.optOutOfErrorHandling();

      this.loadData();

      return await this.showDialog!();
    },
    async loadData() {
      this.processing = true;
      try {
        if (!!this.workOrderID?.length) {
          var takeoff = await workOrderWorkingTakeoffService.getWorkingTakeoffForWorkOrderWithID(
            this.workOrderID
          );
          this.takeoffParts = takeoff;
        }
        if (!!this.scaffoldDesignID?.length) {
          var takeoff = await scaffoldDesignWorkingTakeoffService.getWorkingTakeoffForScaffoldDesignWithID(
            this.scaffoldDesignID
          );
          this.takeoffParts = takeoff;
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    copyBCount() {
      try {
        if (!!navigator.clipboard) {
          navigator.clipboard.writeText(this.partsBCount);
        } else {
          let bCountField = this.$refs.bcountfield as Vue;
          let bCountElement = bCountField.$el as HTMLInputElement;
          bCountElement.select();
          bCountElement.setSelectionRange(0, 99999); /* For mobile devices */
          /* Copy the text inside the text field */
          document.execCommand("copy");
        }

        this.bCountCopied = true;
        setTimeout(() => {
          this.bCountCopied = false;
        }, 2500);
      } catch (error) {
        console.error(`Unable to copy BCount to clipboard: ${error}`);
        this.bCountCopied = false;
      }
    }
  }
});
export default WorkingEstimateTakeoffDialog;

export async function createWorkingEstimateTakeoffDialogForWorkOrder(
  workOrderID: string
): Promise<WorkingEstimateTakeoffDialogReturnType> {
  let dialog = createDialog(WorkingEstimateTakeoffDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(workOrderID, undefined);
}
export async function createWorkingEstimateTakeoffDialogForScaffoldDesign(
  scaffoldDesignID: string
): Promise<WorkingEstimateTakeoffDialogReturnType> {
  let dialog = createDialog(WorkingEstimateTakeoffDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(undefined, scaffoldDesignID);
}

