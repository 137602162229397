var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      !_vm.sortedStatusLogs || _vm.sortedStatusLogs.length == 0
        ? _c(
            "v-row",
            {
              staticClass: "fd-timesheet-status-log-entry pt-3",
              attrs: { "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "fd-timesheet-status-log-entry-title",
                  attrs: { cols: "12" }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("timesheets.approval.no-history-message")) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.sortedStatusLogs, function(log) {
        return _c(
          "v-row",
          {
            key: log.id,
            staticClass: "fd-timesheet-status-log-entry pt-3",
            attrs: { "no-gutters": "" }
          },
          [
            _c(
              "v-col",
              {
                staticClass: "fd-timesheet-status-log-entry-title",
                attrs: { cols: "12" }
              },
              [
                _c(
                  "v-icon",
                  {
                    attrs: { color: _vm.getLogActionColor(log), "x-small": "" }
                  },
                  [_vm._v(_vm._s(_vm.getLogActionIcon(log)))]
                ),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.summaryStringForStatusLog(log)) +
                    "\n    "
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.showLogComments(log)
              ? _c(
                  "v-col",
                  {
                    staticClass: "fd-timesheet-status-log-entry-reason-text",
                    attrs: { cols: "12" }
                  },
                  [_c("span", [_vm._v(_vm._s(log.comments))])]
                )
              : _vm._e()
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }