import { languageService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: languageService,
  singularStoreName: "LANGUAGE",
  pluralStoreName: "LANGUAGES",
  localizationPrefix: "languages",
  // The following is the name of the ID property used by foreign objects.
  consumerRelatedIDProperty: {
    name: "languageID",
    type: "string"
  }
});

export default storeModule;
