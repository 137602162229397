import { scaffoldBayWidthService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: scaffoldBayWidthService,
  singularStoreName: "SCAFFOLD_BAY_WIDTH",
  pluralStoreName: "SCAFFOLD_BAY_WIDTHS",
  localizationPrefix: "scaffold-bays.bay-widths",
  consumerRelatedIDProperty: {
    name: "scaffoldBayWidthID",
    type: "string"
  }
});

export default storeModule;

