import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import notes, { ParseNoteWithSenderDetails, SortNotesArray } from "../../../dataMixins/notes";
import { noteService, NoteWithSenderDetails } from "../../../services";
import { FormattedWorkOrder } from "../KanbanItem.vue";

const WorkOrderNotesDialog = FDVue.extend({
  name: "",
  mixins: [dialogSupport, notes],
  props: {
    cy: { type: String, default: "fd-work-order-notes-dialog" }
  },
  data: function() {
    return {
      workOrder: {} as FormattedWorkOrder,

      newNoteText: "",
      saving: false,

      currentUserCanAddNote: true,
      newNotes: [] as NoteWithSenderDetails[]
    };
  },
  computed: {},
  watch: {},
  methods: {
    async open(workOrder: FormattedWorkOrder, notes: NoteWithSenderDetails[]) {
      this.workOrder = workOrder;
      this.notes = notes.map(x => ParseNoteWithSenderDetails(x));
      if (!!this.workOrder.notes?.length) {
        this.notes.push({
          isPinned: true,
          isNew: false,
          initials: "",
          name: `${this.$t("scaffold-requests.notes")}`,
          role: "",
          date: "",
          time: "",
          text: this.workOrder.notes,
          sender: undefined,
          id: undefined,
          noteThreadID: undefined,
          personID: undefined,
          sentTime: new Date(0),
          archivedDate: undefined,
          created: undefined,
          createdBy: undefined,
          updated: undefined,
          updatedBy: undefined
        });
      }
      this.notes = SortNotesArray(this.notes);

      return await this.showDialog!();
    },
    cancelDialog() {
      this.closeDialog!(this.newNotes);
    },
    async addNewNote() {
      if (!this.newNoteText.length) return;

      this.processing = true;
      this.saving = true;
      try {
        var newNote = await noteService.addNewNoteForWorkOrder(
          this.newNoteText,
          this.workOrder.id!
        );
        this.inlineMessage.message = "";
        let noteToAdd = ParseNoteWithSenderDetails(newNote);
        noteToAdd.isNew = true;
        this.notes.push(noteToAdd);
        this.notes = SortNotesArray(this.notes);
        this.newNoteText = "";
        this.newNotes.push(noteToAdd);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    }
  }
});

export default WorkOrderNotesDialog;

export async function openWorkOrderNotesDialog(
  workOrder: FormattedWorkOrder,
  notes: NoteWithSenderDetails[]
): Promise<NoteWithSenderDetails[]> {
  let dialog = createDialog(WorkOrderNotesDialog);
  return await dialog.open(workOrder, notes);
}

