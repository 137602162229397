export default {
  "client-work-order": "#bd8202",
  "client-work-order-highlight": "#eec994",
  "client-work-order-background": "#fcf4ea",
  "change-order": "#6f2da8",
  "change-order-highlight": "#c5abdc",
  "change-order-background": "#f3eef8",
  rework: "#267D98",
  "rework-highlight": "#92BECB",
  "rework-background": "#e9f2f5",
  "service-order": "#26580f",
  "service-order-highlight": "#93ac87",
  "service-order-background": "#e9eee7"
} as { [key: string]: string };

