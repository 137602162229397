import { regionService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: regionService,
  singularStoreName: "REGION",
  pluralStoreName: "REGIONS",
  localizationPrefix: "regions",
  consumerRelatedIDProperty: {
    name: "regionID",
    type: "string"
  }
});

export default storeModule;
