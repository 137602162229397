import FDVue from "@fd/lib/vue";
import userAccess from "../dataMixins/userAccess";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { mapMutations } from "vuex";
import {
  MaterialOrder,
  MaterialOrderDirection,
  MaterialOrderWithDetails,
  materialOrderService
} from "../services";
import { showMaterialOrderNewDialog } from "./components/dialogs/SP.MaterialOrderNewDialog.vue";
import archivedDataList from "../dataMixins/archivedDataList";

type FormattedMaterialOrder = MaterialOrderWithDetails & {
  numberString: string;
  supplierID: string;
};

type FilteringContext = "request" | "return";

export default FDVue.extend({
  name: "fd-material-orders-list",

  mixins: [userAccess, archivedDataList],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      materialOrders: [] as FormattedMaterialOrder[],

      // Table Footer page size options
      itemsPerPage: 25,
      itemsPerPageOptions: [5, 10, 15, 25, 50, -1]
    };
  },

  computed: {
    filteredMaterialOrders(): FormattedMaterialOrder[] {
      let materialOrders = this.materialOrders;

      if (this.materialOrderContextIsRequest) {
        materialOrders = materialOrders.filter(
          x => x.direction == MaterialOrderDirection.MaterialRequest
        );
      } else {
        materialOrders = materialOrders.filter(
          x => x.direction == MaterialOrderDirection.MaterialReturn
        );
      }
      return materialOrders;
    },
    materialOrderContext: {
      get(): FilteringContext {
        return (
          this.$store.state.filters.find(
            (x: any) => x.context == this.$store.state.filteringContext
          )!.contextForFiltering ?? "request"
        );
      },
      set(val: FilteringContext) {
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", val);
      }
    },
    materialOrderContextIsRequest(): boolean {
      return this.materialOrderContext == "request";
    },
    materialOrderContextIsReturn(): boolean {
      return this.materialOrderContext == "return";
    },
    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    formatDate(date: Date | string | null | undefined) {
      return DateUtil.stripTimeFromLocalizedDateTime(date);
    },
    async openNewRequestDialog() {
      console.log(`openNewRequestDialog`);
      if (await showMaterialOrderNewDialog({ direction: MaterialOrderDirection.MaterialRequest })) {
        await this.reloadTableData();
      }
    },
    async openNewReturnDialog() {
      console.log(`openNewReturnDialog`);
      if (await showMaterialOrderNewDialog({ direction: MaterialOrderDirection.MaterialReturn })) {
        await this.reloadTableData();
      }
    },
    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await this.loadOrders();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async loadData() {
      await this.loadOrders();
    },
    async loadOrders() {
      let orders = await materialOrderService.getAll(
        this.showArchived,
        this.showArchivedFromDate,
        this.showArchivedToDate
      );
      this.materialOrders = orders.map(
        x =>
          ({
            ...x,
            numberString: `00000${x.number}`.slice(-5),
            supplierID: x.toSupplierID ?? x.fromSupplierID
          } as FormattedMaterialOrder)
      );
    }
  },

  created: async function() {
    var toDate = DateUtil.addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "materialorders",
      parentalContext: null,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: DateUtil.addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate,
      contextForFiltering: "request"
    });

    this.notifyNewBreadcrumb({
      text: this.$t("material-orders.list.title"),
      to: "/materialorders",
      resetHistory: true
    });

    this.processing = true;
    try {
      await this.loadOrders();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

