import { questionService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: questionService,
  singularStoreName: "QUESTION",
  pluralStoreName: "QUESTIONS",
  localizationPrefix: "questions",
  consumerRelatedIDProperty: {
    name: "questionIDs",
    type: "array"
  }
});

export default storeModule;

