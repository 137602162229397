import FDVue from "../../";

export default FDVue.extend({
  name: "fd-subheader",

  props: {
    cy: { type: String, default: undefined },
    dataCy: { type: String, default: "fd-detail-view-header" },
    value: {},
    info: {}
  },

  data: function() {
    return {};
  },

  methods: {},

  computed: {},

  watch: {}
});

