import { PropValidator } from "vue/types/options";
import FDVue from "../../../../lib/vue";

export interface WorkOrderScopeDetails {
  isClientWorkOrder: boolean;
  clientWorkOrderReferenceNumber: string | null;
  isChangeOrder: boolean;
  changeOrderReferenceNumber: string | null;
  isRework: boolean;
  reworkReferenceNumber: string | null;
  isServiceOrder: boolean;
  serviceOrderReferenceNumber: string | null;
}

export default FDVue.extend({
  name: "sp-inline-scope-icons",
  props: {
    item: { type: [Object] } as PropValidator<WorkOrderScopeDetails>,
    cy: { type: String, default: "sp-inline-scope-icons" }
  },
  computed: {
    showClientWorkOrderBadge(): boolean {
      return false;
      return this.item.isClientWorkOrder;
    },
    showServiceOrderBadge(): boolean {
      return false;
      return this.item.isServiceOrder;
    },
    showChangeOrderBadge(): boolean {
      return this.item.isChangeOrder;
    },
    showReworkBadge(): boolean {
      return this.item.isRework;
    },
    badgeColumnSize(): number {
      let items = 0;
      if (this.showClientWorkOrderBadge) items++;
      if (this.showChangeOrderBadge) items++;
      if (this.showReworkBadge) items++;
      if (this.showServiceOrderBadge) items++;
      if (items == 0) return 12;
      return Math.floor(12 / items);
    }
  }
});

