import { mapActions, mapMutations, mapState } from "vuex";
import { filterByTags, filterBySuppliers, getTagsInUse } from "../services/taggableItems";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import rules from "@fd/lib/vue/rules";
import { PartWithTags, SupplierWithTags, Tag } from "../services";
import ServiceError from "@fd/lib/client-util/serviceError";
import archivedDataList from "../dataMixins/archivedDataList";
import { addDaysToDate, addMonthsToDate } from "@fd/lib/client-util/datetime";
import { showPartNewDialog } from "./components/dialogs/PartNewDialog.vue";
import { VDataTable } from "@fd/lib/vue/types";
import userAccess from "../dataMixins/userAccess";

type PartWithTagsAndArchived = PartWithTags & { archived: boolean };

export default FDVue.extend({
  name: "fd-parts-catalog",

  mixins: [errorHandling, rules, archivedDataList, userAccess],

  components: {
    "fd-inline-edit-dialog": () => import("@fd/lib/vue/components/InlineEditDialog.vue")
  },

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: () => ({
    // Used to track the the auto-reload for the table data
    reloadTimer: null as NodeJS.Timeout | null,
    dataReloadMinutes: 5,

    // Used to change pages in the datatable programmatically
    tablepage: 1,

    // Used in conjunction with selected suppliers when filtering
    // If there is only one supplier, it's automatically selected and the filter is hidden
    // This means there's no way to unset the filter, which would make all parts without a supplier impossible to find
    supplierFilterIncludesUnset: false
  }),

  computed: {
    partRules(): any {
      return {
        name: [this.rules.required],
        publicID: [this.rules.required],
        mpp: [this.rules.required, this.rules.numeric],
        weight: [this.rules.required, this.rules.numeric],
        cleatingMPP: [this.rules.numeric],
        lashingMPP: [this.rules.numeric],
        carpentryMPP: [this.rules.numeric],
        otherMPP: [this.rules.numeric],
        rentalRate: [this.rules.numeric],
        costUsed: [this.rules.numeric],
        costNew: [this.rules.numeric],
        designation: []
      };
    },
    partsList(): PartWithTagsAndArchived[] {
      return (this.$store.state.parts.fullList as PartWithTags[]).map(x => {
        return {
          ...x,
          archived: !!x.archivedDate
        } as PartWithTagsAndArchived;
      });
    },
    parts(): PartWithTagsAndArchived[] {
      return filterBySuppliers(
        this.suppliersSelectedForFiltering,
        filterByTags(this.tagsSelectedForFiltering, this.partsList),
        this.supplierFilterIncludesUnset
      );
    },
    suppliersInUse(): SupplierWithTags[] {
      return this.$store.getters.getSortedInUseSuppliers(this.partsList);
    },
    tagsInUse(): Tag[] {
      return this.$store.getters.getSortedInUseTags(this.partsList);
    },

    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    tagsSelectedForFiltering: {
      get() {
        //return this.$store.state.tagsForFiltering
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.tagsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_TAGS_FOR_FILTERING", val);
      }
    },

    suppliersSelectedForFiltering: {
      get() {
        //return this.$store.state.suppliersForFiltering
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.suppliersForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SUPPLIERS_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    focusFieldForVisibleItemAtIndex(fieldName: string, index: number) {
      let visibleParts = (this.$refs.datatable as VDataTable).internalCurrentItems;
      if (!visibleParts.length) return;

      if (index < 0) index = 0;
      if (index >= visibleParts.length) index = visibleParts.length - 1;
      let item = visibleParts[index];

      let itemFieldRef = `${fieldName}${item.id}`;
      let itemField = this.$refs[itemFieldRef] as any;
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    async selectPreviousField(fieldName: string, item: PartWithTagsAndArchived) {
      let datatable = this.$refs.datatable as VDataTable;
      let visibleParts = datatable.internalCurrentItems;
      let currentItemIndex = visibleParts.indexOf(item);
      if (currentItemIndex <= 0) {
        if (this.tablepage <= 1) return;
        this.tablepage -= 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, datatable.computedItemsPerPage);
        });
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, previousIndex);
    },
    async selectNextField(fieldName: string, item: PartWithTagsAndArchived) {
      let datatable = this.$refs.datatable as VDataTable;
      let visibleParts = datatable.internalCurrentItems;
      let currentItemIndex = visibleParts.indexOf(item);
      if (currentItemIndex >= visibleParts.length - 1) {
        let maxPage =
          datatable.computedItemsPerPage <= 0
            ? 1
            : Math.ceil(this.parts.length / datatable.computedItemsPerPage);
        if (this.tablepage >= maxPage) return;

        this.tablepage += 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, 0);
        });
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, nextIndex);
    },
    async enterPressed(e: KeyboardEvent, fieldName: string, item: PartWithTagsAndArchived) {
      if (e.shiftKey) await this.selectPreviousField(fieldName, item);
      else await this.selectNextField(fieldName, item);
    },
    async showInlineTextFieldErrorMessage(fieldName: string, error: string | undefined) {
      var text = this.$t("parts.inline-field-error-generic", [fieldName]);
      if (!!error?.length) {
        text = this.$t("parts.inline-field-error-specified", [fieldName, error]);
      }
      var snackbarPayload = {
        text: text,
        type: "error"
      };
      this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
    },
    async loadData() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }
      await this.loadParts({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },
    async reloadTableData() {
      this.processing = true;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async openNewDialog() {
      this.optOutOfErrorHandling();
      await showPartNewDialog();
    },

    // the following works with the delete "Action" button in the Datatable.
    // async deleteTableItem(item: PartWithTags) {
    //   this.inlineMessage.message = null;
    //   this.processing = true;
    //   try {
    //     await this.deletePart({ id: item.id, name: item.name });
    //   } catch (error) {
    //     this.handleError(error as ServiceError);
    //   } finally {
    //     this.processing = false;
    //   }
    // },

    async flipArchived(item: PartWithTagsAndArchived) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updatePart({ id: item.id, archivedDate: archivedDate, name: item.name });
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async saveField(item: PartWithTagsAndArchived, fieldName: string, value: any) {
      let fieldIsMPP =
        fieldName == "mpp" ||
        fieldName == "cleatingMPP" ||
        fieldName == "lashingMPP" ||
        fieldName == "carpentryMPP" ||
        fieldName == "otherMPP";
      if (fieldIsMPP && !this.currentUserCanConfigurePrivateSettings) {
        (item as any)[fieldName] = undefined;
        return;
      }
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.updatePart({
          id: item.id,
          [fieldName]: value,
          name: item.name
        });
      } catch (error) {
        this.handleError(error as ServiceError);
      } finally {
        this.processing = false;
      }
    },
    lookupSupplier(supplierID: string) {
      if (this.suppliersInUse) {
        let supplier = this.suppliersInUse.find(x => x.id == supplierID);
        if (supplier) {
          return supplier.alias;
        } else {
          return "(unknown)";
        }
      } else {
        return "Loading...";
      }
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadParts: "LOAD_PARTS",
      loadSuppliers: "LOAD_SUPPLIERS",
      loadTags: "LOAD_TAGS",
      updatePart: "UPDATE_PART"
    })
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "parts",
      parentalContext: null,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      suppliersForFiltering: [],
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate
    });

    this.notifyNewBreadcrumb({
      text: this.$t("parts.menu-title"),
      to: "/parts",
      resetHistory: true
    });
    this.processing = true;
    try {
      await Promise.all([this.reloadTableData(), this.loadSuppliers(), this.loadTags()]);

      if (this.suppliersInUse.length == 1) {
        this.suppliersSelectedForFiltering = [this.suppliersInUse[0].id];
        this.supplierFilterIncludesUnset = true;
      }
    } catch (error) {
      this.handleError(error as ServiceError);
    } finally {
      this.processing = false;
    }
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  }
});

