var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-timeline-item",
    {
      staticClass: "fd-new-notes-avatar",
      attrs: { large: _vm.$vuetify.breakpoint.smAndUp },
      scopedSlots: _vm._u([
        {
          key: "icon",
          fn: function() {
            return [
              _c(
                "v-avatar",
                {
                  staticClass: "fd-normal-note",
                  attrs: { size: _vm.$vuetify.breakpoint.smAndUp ? "48" : "34" }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "white--text",
                      attrs: { small: _vm.$vuetify.breakpoint.xsOnly }
                    },
                    [_vm._v("far fa-sticky-note")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "relative" } },
        [
          _c("fd-textarea", {
            attrs: {
              value: _vm.value,
              rows: "1",
              dense: _vm.$vuetify.breakpoint.xs,
              loading: _vm.loading,
              disabled: _vm.disabled,
              "auto-grow": "",
              label: _vm.$vuetify.breakpoint.xs
                ? _vm.$t("notes.new-input-placeholder-short")
                : _vm.$t("notes.new-input-placeholder")
            },
            on: { input: v => _vm.$emit("input", v) }
          }),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              class: _vm.$vuetify.breakpoint.xs
                ? "fd-new-notes-btn-small"
                : "fd-new-notes-btn",
              attrs: {
                "data-cy": "post",
                loading: _vm.loading,
                small: _vm.$vuetify.breakpoint.xsOnly,
                disabled: _vm.disabled
              },
              on: { click: _vm.addNewNote }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("notes.post")) + "\n    ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }