<template>
    <v-container fluid>
        <v-form ref="form">
            <v-slide-x-reverse-transition>
                <v-card v-show="slidein">
                    <v-toolbar v-bind:class="[processing ? 'view-card-top-color-processing' : 'view-card-top-color']">
                        <!--View Title-->
                        <v-row>
                            <v-col cols="12">
                                <v-row align="center">
                                    <v-breadcrumbs
                                        class="d-none d-sm-flex"
                                        :items="$store.state.currentBreadcrumbs"
                                        large
                                        v-bind:class="[processing ? 'breadcrumb-processing-opacity' : '', 'pl-3']"
                                    >
                                        <template v-slot:divider>
                                            <v-icon>fa-chevron-right</v-icon>
                                        </template>
                                    </v-breadcrumbs>
                                    <fd-back-button :route="item => $router.push(`/owners/`)"></fd-back-button>
                                    <v-spacer></v-spacer>
                                    <v-progress-circular
                                            :indeterminate="true"
                                            :rotate="0"
                                            :size="32"
                                            :width="4"
                                            color="white"
                                            class="mr-3"
                                            v-if="processing"
                                    ></v-progress-circular>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-toolbar>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field
                                                v-bind:label="$t('common.name')"
                                                v-model="owner.name"
                                                v-bind:disabled="processing"
                                                :rules="[rules.required]"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field
                                                persistent-hint
                                                v-bind:hint="$t('common.alias-hint')"
                                                v-bind:label="$t('common.alias')"
                                                v-model="owner.alias"
                                                v-bind:disabled="processing"
                                                :rules="[rules.aliascounter, rules.required]"
                                                maxlength="20"
                                                counter
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea v-bind:label="$t('common.description')" v-model="owner.description" v-bind:disabled="processing"></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-switch v-bind:label="$t('common.enabled')" v-model="owner.enabled" v-bind:disabled="processing"></v-switch>
                            </v-col>

                            <v-col cols="12" class="pb-0">
                                <v-alert v-if="inlineMessage.message" v-bind:type="inlineMessage.type" class="mr-0">
                                    {{ inlineMessage.message }}
                                </v-alert>
                                <v-row class="ml-0 mr-0">
                                    <v-btn
                                            link
                                            v-bind:disabled="processing"
                                            color="error"
                                            outlined
                                            @click="deleteItem"
                                    >{{ $t('common.delete') }}</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            text
                                            link
                                            v-bind:disabled="processing"
                                            @click="cancel"
                                    >{{ $t('common.cancel') }}</v-btn>
                                    <v-btn
                                            color="primary"
                                            v-bind:loading="saving"
                                            v-bind:disabled="processing"
                                            @click="save"
                                    >{{ $t('common.save') }}</v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-slide-x-reverse-transition>
        </v-form>
    </v-container>
</template>

<script lang="ts">
    import {mapMutations, mapState, mapActions} from "vuex";
    import i18n from '../i18n'
    import FDVue from "@fd/lib/vue";
    import errorHandling from "@fd/lib/vue/mixins/errorHandling";

    export default FDVue.extend({
        mixins: [errorHandling],

        name: 'fd-Owner-Existing',

        components: {
            "fd-back-button": () => import('@fd/lib/vue/components/BackButton.vue')
        },

        data: function () { return {
            // The following is responsible for the inline messages that may be presented to the user.
            inlineMessage: {
                message: null,
                type: 'error',
            },

            // The following will control whether the controls on screen are disabled while we are conducting any screen
            // wide actions.
            processing: false,

            // The following will control whether or not the save button shows the processing/loading indicator
            saving: false,

            //The following object is used in conjunction with the breadcrumbs that are presented to the user for sub-view navigation.
            breadcrumbs: [
                {
                    text: 'Owners',
                    disabled: false,
                    to: '/owners',
                },
                {
                    text: this.$t("loading-dot-dot-dot"),
                    disabled: true,
                },
            ],

            owner : {
                name: "",
                alias: "",
                description: "",
                enabled: false
            },
            slidein:false,

            rules: {
                required: value => !!value || i18n.t('common.rule-required'),
                aliascounter: value => !value || value.length <= 20 || i18n.t('common.rule-alias-counter', '20'),
            },

        };},

        methods: {
            // Method used in conjunction with the Save button.
            async save() {
                // First reset the inline message if there are any.
                this.inlineMessage.message = '';

                if (!this.$refs.form.validate()) {
                    return;
                }

                this.processing = true;
                this.saving = true;
                try {
                    await this.updateOwner({
                        id: this.$route.params.id,
                        name: this.owner.name,
                        alias: this.owner.alias,
                        description: this.owner.description,
                        enabled: this.owner.enabled
                    });
                    this.$router.push('/owners');
                } catch (error) {
                    this.inlineMessage.message = this.$t("owners.save-network-error");
                    this.inlineMessage.type = "error";
                } finally {
                    this.processing = false;
                    this.saving = false;
                }
            },
            // the following works with the delete "Action" button in the Datatable.
            async deleteItem() {
                this.inlineMessage.message = null;
                this.processing = true
                try {
                    await this.deleteOwner({ id: this.$route.params.id, name: this.owner.name });
                } catch (error) {
                    this.inlineMessage.message = this.$t("unexpected-network-error");
                    this.inlineMessage.type = "error";
                } finally {
                    this.processing = false;
                    this.$router.push('/owners');
                }
            },

            // Method used in conjunction with the Cancel button.
            cancel() {
                // TODO: Should this roll back state rather than rely on requerying?
                this.$router.push('/owners');
            },
            ...mapMutations({
                setOwner: 'SET_OWNER',
                notifyNewBreadcrumb: 'NOTIFY_NEW_BREADCRUMB',
                setFilteringContext: 'SET_FILTERING_CONTEXT'
            }),
            ...mapActions({
                loadOwner: 'LOAD_OWNER',
                updateOwner: 'UPDATE_OWNER',
                deleteOwner: 'DELETE_OWNER'
            })
        },

        created: async function () {
            // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
            setInterval(() => {
                this.slidein = true;
            }, 100);

            this.processing = true;
            try {
                await this.loadOwner(this.$route.params.id);
                var owner = this.$store.state.owners.fullList.find(x => x.id == this.$route.params.id);
                this.owner.name = owner.name;
                this.owner.alias = owner.alias;
                this.owner.description = owner.description;
                this.owner.enabled = owner.enabled;
                this.notifyNewBreadcrumb({
                    text: owner.name,
                    to: `/owners/${this.$route.params.id}`
                });
            } catch (error) {
                this.inlineMessage.message = this.$t("unexpected-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
            }

        }
    })
</script>
