import FDVue from "../../../../lib/vue";

export default FDVue.extend({
  name: "fd-login-select-different-contact-method-form",
  props: {
    value: { type: String, default: undefined },
    contactMethods: { type: Array, default: () => [] },

    processing: { type: Boolean, default: false },
    processingSignInRequest: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },

    show: { type: Boolean, default: false },
    smallishMobileDevice: { type: Boolean, default: false },
    smallWidthBrowser: { type: Boolean, default: false }
  },
  methods: {
    contactMethodLabel(contactMethod: any): string {
      if (!contactMethod.emailAddress?.length && !contactMethod.phoneNumberTail?.length) return "";

      let label = "";
      if (!!contactMethod.emailAddress?.length) label = contactMethod.emailAddress;
      else label = `***-***-*${contactMethod.phoneNumberTail}`;

      if (!contactMethod.allowAccessCodes) label += ` (${this.$t("common.unsubscribed")})`;

      console;
      return label;
    }
  }
});

