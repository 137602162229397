export default {
  name: "sp-part-selection-field",
  props: {
    cy: { type: String, default: undefined },
    dataCy: { type: String, default: "sp-part-selection-field" },
    label: { type: String },
    value: {},
    items: { type: Array }
  }
};

