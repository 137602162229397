import FDVue from "..";

export default FDVue.extend({
  name: "fd-menu-save-button",
  inheritAttrs: false,
  props: {
    canClose: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    icon: { type: String, default: "fas fa-caret-down" },
    label: {
      type: [String],
      default: function() {
        return this.$t("common.save");
      }
    },
    saveText: {
      type: [String],
      default: function() {
        return this.$t("common.save");
      }
    },
    saveAndCloseText: {
      type: [String],
      default: function() {
        return this.$t("common.save-and-close");
      }
    }
  }
});

