import FDVue from "../../../lib/vue";
import { mapActions, mapMutations } from "vuex";
import serviceErrorHandling from "../../../lib/vue/mixins/serviceErrorHandling";
import tabbedView, { PageTab } from "../../../lib/vue/mixins/tabbedView";

type ExpandedPanels = {
  paintPanelIndex?: number;
};
export default FDVue.extend({
  name: "sp-build-sheet-setup",
  mixins: [serviceErrorHandling, tabbedView],
  components: {
    "sp-application-type-list-form": () => import("./components/SP.ApplicationTypeListForm.vue"),
    "sp-paint-code-list-form": () => import("./components/SP.PaintCodeListForm.vue"),
    "sp-set-up-type-list-form": () => import("./components/SP.SetUpTypeListForm.vue"),
    "sp-tsa-list-form": () => import("./components/SP.TsaListForm.vue"),
    "sp-work-category-list-form": () => import("./components/SP.WorkCategoryListForm.vue")
  },
  data: function() {
    return {
      paintPanel: 0,
      firstTabKey: "2",
      maintenanceTab: new PageTab({
        nameKey: "build-sheet-setup.tabs.maintenance.title",
        key: "1",
        visible: true,
        error: false
      }),
      paintTab: new PageTab({
        nameKey: "build-sheet-setup.tabs.paint.title",
        key: "2",
        visible: true,
        error: false
      }),
      insulationTab: new PageTab({
        nameKey: "build-sheet-setup.tabs.insulation.title",
        key: "3",
        visible: true,
        error: false
      })
    };
  },
  computed: {},
  watch: {},
  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT",
      setExpandedPanels: "SET_EXPANDED_PANEL_INDEX_IN_FILTERING_CONTEXT"
    })
  },
  created: async function() {
    this.setFilteringContext({
      context: "buildsheetsetup",
      parentalContext: null,
      selectedTab: this.firstTabKey,
      expandedPanel: {} as ExpandedPanels,
      searchStringForFiltering: ""
    });

    this.notifyNewBreadcrumb({
      text: this.$t("build-sheet-setup.title"),
      to: "/buildsheetsetup",
      resetHistory: true
    });

    this.processing = true;
    try {
    } catch (error) {
      this.handleError(error);
    } finally {
      this.processing = false;
    }
  }
});

