import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { Discipline } from "../../../services";

const DisciplineNewDialog = FDVue.extend({
  name: "fd-discipline-new-dialog",

  mixins: [dialogSupport, errorHandling, rules],

  components: {},

  data: function() {
    return {
      discipline: {
        name: "",
        description: "",
        craftCode: ""
      } as Discipline
    };
  },

  computed: {},

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        await this.addDiscipline({
          ...this.discipline,
          enabled: true
        });
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    ...mapActions({
      addDiscipline: "ADD_DISCIPLINE"
    })
  },

  created: async function() {}
});

export default DisciplineNewDialog;

export async function createNewDiscipline(): Promise<boolean> {
  let dialog = createDialog(DisciplineNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.showDialog!();
}

