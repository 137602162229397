import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { TranslateResult } from "vue-i18n";
import {
  CountSheetGroupWithParts,
  Part,
  PartWithTags,
  MaterialOrderPartWithDetails,
  MaterialOrderPartDetails,
  materialOrderService,
  SupplierWithTags,
  MaterialOrderDirection,
  BackOrderPartDetails
} from "../../../services";
import {
  CountSheetGroupPartFromPart,
  CountSheetGroupWithSortedParts,
  FlattenedPartsList,
  PartWithCounts,
  SummarizeModifiedPartsInGroups
} from "../../../dataMixins/countSheet";
import { SortCountSheetGroups, SortParts } from "../../../dataMixins/countSheetGroupSorting";
import { mapActions } from "vuex";
import rules from "@fd/lib/vue/rules";
import userAccess from "../../../dataMixins/userAccess";
import { openBCountBottomDialog } from "./SP.BCountBottomDialog.vue";
import { stripTimeFromLocalizedDateTime } from "../../../../../lib/client-util/datetime";

const MaterialOrderNewDialog = FDVue.extend({
  name: "sp-materialOrder-new-dialog",

  mixins: [dialogSupport, rules, userAccess],

  components: {},

  data: function() {
    return {
      saving: false,

      direction: MaterialOrderDirection.MaterialRequest,
      materialOrderDate: new Date(),
      expectedDate: new Date(),
      referenceNumber: "",
      contactPerson: "",
      instructions: "",
      parts: [] as MaterialOrderPartWithDetails[],
      allParts: [] as PartWithTags[],

      countSheetGroups: [] as CountSheetGroupWithSortedParts[],
      openCategoryPanels: [] as number[],
      openIndividualPanels: [] as number[],

      partSearch: "",
      partSources: [] as MaterialOrderPartDetails[],
      backorderedParts: [] as BackOrderPartDetails[],

      /*** TO/FROM SUPPLIER ***/
      // availableSuppliers: [] as Supplier[],
      selectedFromSupplier: null as SupplierWithTags | null,
      selectedToSupplier: null as SupplierWithTags | null
    };
  },
  computed: {
    availableSuppliers(): SupplierWithTags[] {
      return this.$store.state.suppliers.fullList as SupplierWithTags[];
    },
    canOpenBCountDialog(): boolean {
      return this.partCountsAreEmpty;
    },
    partCountsAreEmpty(): boolean {
      return !this.partsForSummary.filter(x => !!x.count && x.count > 0).length;
    },
    visibleCountSheetGroups(): CountSheetGroupWithSortedParts[] {
      if (!this.selectedFromSupplier && !this.selectedToSupplier) return [];

      let allowedPartIDs = this.selectedFromSupplier?.partIDs ?? this.selectedToSupplier?.partIDs;
      let visibleCountSheetGroups = this.countSheetGroups
        .map(x => ({
          ...x,
          sortedParts: x.sortedParts.filter(p => !!allowedPartIDs && allowedPartIDs.includes(p.id!))
        }))
        .filter(x => x.sortedParts.length > 0);
      if (!this.partSearch.length) return visibleCountSheetGroups;
      else {
        let lowersearch = this.partSearch.toLowerCase();
        return visibleCountSheetGroups
          .map(x => ({
            ...x,
            sortedParts: x.sortedParts.filter(
              p =>
                p.name?.toLowerCase().includes(lowersearch) ||
                p.publicID?.toLowerCase().includes(lowersearch)
            )
          }))
          .filter(x => x.sortedParts.length > 0);
      }
    },
    unwatchedMethodNames(): string[] {
      return [
        "open",
        "formatDate",
        "fieldRefForPart",
        "selectPreviousField",
        "selectNextField",
        "enterPressed",
        "partCountValueChanged",
        "clearParts"
      ];
    },
    dialogTitle(): string | TranslateResult {
      if (this.directionIsMaterialRequest)
        return this.$t("material-orders.new.dialog-title-request");
      else return this.$t("material-orders.new.dialog-title-return");
    },
    directionIsMaterialRequest(): boolean {
      return this.direction == MaterialOrderDirection.MaterialRequest;
    },
    directionIsMaterialReturn(): boolean {
      return this.direction == MaterialOrderDirection.MaterialReturn;
    },
    partsForSummary(): PartWithCounts[] {
      return SummarizeModifiedPartsInGroups(this.countSheetGroups);
    }
  },
  watch: {
    materialOrderDate() {}
  },
  methods: {
    ...mapActions({
      loadCountSheetGroups: "LOAD_COUNT_SHEET_GROUPS",
      loadSuppliers: "LOAD_SUPPLIERS"
    }),
    formatDate(date: Date | string | null | undefined): string {
      return stripTimeFromLocalizedDateTime(date);
    },
    async open(direction: MaterialOrderDirection | undefined) {
      console.log(`open`);
      if (direction !== undefined) this.direction = direction;

      this.loadScreenData();

      this.optOutOfErrorHandling();
      return await this.showDialog();
    },
    async loadScreenData() {
      this.processing = true;
      try {
        await this.loadSuppliers();
        await this.loadParts();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },
    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    async saveDialog() {
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (await this.saveNewMaterialOrder()) {
          this.closeDialog!(true);
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    async saveNewMaterialOrder(): Promise<boolean> {
      let parts = this.partsForSummary
        .filter(x => !!x.count && x.count > 0)
        .map(
          x =>
            ({
              partID: x.id,
              count: +x.count!,
              currentAssignedCount: +x.assigned
            } as MaterialOrderPartDetails)
        );
      if (parts.length == 0) {
        this.inlineMessage.message = this.$t(
          "material-orders.new.part-selection-required-to-submit-message"
        );
        this.inlineMessage.type = "error";
        return false;
      }

      if (this.direction == MaterialOrderDirection.MaterialRequest) {
        if (!this.selectedFromSupplier) {
          this.inlineMessage.message = this.$t(
            "material-orders.new.from-supplier-required-message"
          );
          this.inlineMessage.type = "error";
          return false;
        }
        await materialOrderService.createRequestMaterialOrder(
          this.selectedFromSupplier.id!,
          this.materialOrderDate,
          this.expectedDate,
          this.referenceNumber,
          this.contactPerson,
          this.instructions,
          parts
        );
      } else if (this.direction == MaterialOrderDirection.MaterialReturn) {
        if (!this.selectedToSupplier) {
          this.inlineMessage.message = this.$t("material-orders.new.to-supplier-required-message");
          this.inlineMessage.type = "error";
          return false;
        }
        await materialOrderService.createReturnMaterialOrder(
          this.selectedToSupplier.id!,
          this.materialOrderDate,
          this.expectedDate,
          this.referenceNumber,
          this.contactPerson,
          this.instructions,
          parts
        );
      }

      return true;
    },

    async loadPartSources() {
      //   this.partSources = [];
      //   if (!this.selectedToSupplier?.id) return;
      //   let partSources = await materialOrderService.getSupplierPartSources(
      //     this.selectedToSupplier.id
      //   );
      //   this.partSources = partSources.filter(
      //     s => this.partsForSummary.findIndex(p => p.id == s.partID) !== -1
      //   );
      // },
      // numberOfPartsSourcedByYardID(yardID: string): number {
      //   return (this.partSources as MaterialOrderPartDetails[]).filter(x => x.yardID == yardID)
      //     .length;
    },

    // *** PARTS & COUNT SHEET ***
    async clearPartCounts() {
      this.partsForSummary.forEach(p => {
        p.count = 0;
        this.partCountValueChanged(p);
      });
    },
    /// Clears and rebuilds all count sheet groups, and reloads part quantities for yards
    async clearParts() {
      console.log(`clearParts`);
      this.inlineMessage.message = "";
      var supplierID = this.selectedFromSupplier?.id ?? this.selectedToSupplier?.id;

      this.parts = this.allParts.map(
        x =>
          ({
            name: x.name,
            description: x.description,
            publicID: x.publicID,
            countSheetGroupID: x.countSheetGroupID,
            partID: x.id,
            count: 0
          } as MaterialOrderPartWithDetails)
      );
      let countSheetGroups = SortCountSheetGroups(
        (this.$store.state.countSheetGroups.fullList as CountSheetGroupWithParts[])
          .filter(group => !!group.parts?.length)
          .map(
            group =>
              ({
                ...group,
                sortedParts: SortParts(
                  group.parts?.map(part => {
                    let backOrderDetails = this.backorderedParts?.find(
                      b => b.partID == part.id
                    ) ?? { backOrderCount: 0, orderDetails: [] };
                    return {
                      ...CountSheetGroupPartFromPart(part),
                      backOrderDetails: backOrderDetails
                    };
                  })
                )
              } as CountSheetGroupWithSortedParts)
          )
      );

      let ungroupedPartsWithDetails = this.parts.filter(x => !x.countSheetGroupID);
      if (!!ungroupedPartsWithDetails?.length) {
        let ungroupedParts = ungroupedPartsWithDetails.map(
          x =>
            ({
              id: x.partID,
              name: x.name,
              description: x.description,
              publicID: x.publicID
            } as Part)
        ) as Part[];
        let ungroupedGroup = {
          name: `${this.$t("common.other")}`,
          order: 999,
          parts: ungroupedParts
        } as CountSheetGroupWithParts;

        let ungroupedGroupWithSortedParts = {
          ...ungroupedGroup,
          sortedParts: SortParts(
            ungroupedGroup.parts?.map(part => {
              let backOrderDetails = this.backorderedParts?.find(b => b.partID == part.id) ?? {
                backOrderCount: 0,
                orderDetails: []
              };
              return {
                ...CountSheetGroupPartFromPart(part),
                backOrderDetails: backOrderDetails
              };
            })
          )
        } as CountSheetGroupWithSortedParts;

        countSheetGroups.push(ungroupedGroupWithSortedParts);
      }

      countSheetGroups.forEach(x => (x.parts = SortParts(x.parts)));

      this.countSheetGroups = countSheetGroups;

      if (this.directionIsMaterialReturn) await this.loadPartSources();
    },
    async loadParts() {
      await this.$store.dispatch("LOAD_PARTS");
      this.allParts = this.$store.state.parts.fullList as PartWithTags[];
      // this.allParts = await partService.getAll(false, null, null);

      await this.loadCountSheetGroups({
        forcedArchivedState: false,
        archivedFromDate: null,
        archivedToDate: null
      });

      this.backorderedParts = await materialOrderService.getBackOrderedPartCounts();

      await this.clearParts();
    },

    // *** SCREEN NAVIGATION ***
    partWithID(id: string): PartWithCounts | undefined {
      return FlattenedPartsList(this.countSheetGroups).find(x => x.id == id);
    },
    async openBCountDialog() {
      let partCounts = await openBCountBottomDialog(this.$refs.content as Vue);
      if (!partCounts?.length) return;

      partCounts.forEach(p => {
        let part = this.partWithID(p.partID);
        if (!!part) {
          part.count = p.quantity;
          this.partCountValueChanged(part);
        }
      });
      if (!this.openCategoryPanels.includes(0)) this.openCategoryPanels.push(0);
    },
    partCountValueChanged(part: PartWithCounts) {
      let addCount = 0;
      let removeCount = 0;
      let count = !!part.count ? +part.count : 0;
      if (this.direction == MaterialOrderDirection.MaterialReturn) {
        removeCount = count;
      } else {
        addCount = count;
      }
      part.total = part.assigned + addCount - removeCount;
    },
    fieldRefForPart(fieldName: string, part: PartWithCounts) {
      let overrideRef = part.overridden == true ? "override" : "";

      let id = part.id!.replace("-", "").replace("-", "");
      return `${fieldName}${overrideRef}_${id}`;
    },
    focusFieldForVisibleItemAtIndex(fieldName: string, groupNumber: number, partIndex: number) {
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.order == groupNumber
      );
      if (!group) return;
      let sortedGroupParts = group.sortedParts;
      if (!sortedGroupParts.length) return;

      let groupPanelNumber = this.countSheetGroups.indexOf(group!);
      if (!this.openIndividualPanels.includes(groupPanelNumber)) {
        this.openIndividualPanels.push(groupPanelNumber);
        let self = this;

        this.$nextTick(() => {
          setTimeout(() => {
            self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, partIndex);
          }, 500);
        });
        return;
      }

      if (partIndex < 0) partIndex = 0;
      if (partIndex >= sortedGroupParts.length) partIndex = sortedGroupParts.length - 1;
      let item = sortedGroupParts[partIndex];

      let itemFieldRef = this.fieldRefForPart(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    selectPreviousField(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      let groupID = item.countSheetGroupID;
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.id == groupID
      );
      if (!group) return;

      let groupNumber = group?.order ?? 0;
      let sortedGroupParts = group?.sortedParts ?? [];
      // console.log(`  current group: ${group?.name}, parts: ${sortedGroupParts.length}`);
      if (!sortedGroupParts.length) return;

      let currentItemIndex = sortedGroupParts.indexOf(item);
      if (currentItemIndex <= 0) {
        if (groupNumber <= 1) return;
        groupNumber -= 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, 999);
        });
        e.preventDefault();
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, previousIndex);
      e.preventDefault();
    },
    selectNextField(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      // console.log(`selectNextField fieldName: ${fieldName}, part: ${item.publicID}`);
      let groupID = item.countSheetGroupID;
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.id == groupID
      );
      let groupNumber = group?.order ?? 0;
      let sortedGroupParts = group?.sortedParts ?? [];
      // console.log(`  current group: ${group?.name}, parts: ${sortedGroupParts.length}`);
      if (!sortedGroupParts.length) return;

      let currentItemIndex = sortedGroupParts.indexOf(item);
      if (currentItemIndex >= sortedGroupParts.length - 1) {
        groupNumber += 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, 0);
        });
        e.preventDefault();
        return;
      }
      let nextIndex = currentItemIndex + 1;
      // console.log(`  current: ${currentItemIndex}, next: ${nextIndex}`);
      this.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, nextIndex);
      e.preventDefault();
    },
    enterPressed(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      if (e.shiftKey) this.selectPreviousField(e, fieldName, item);
      else this.selectNextField(e, fieldName, item);
    }
  }
});

export default MaterialOrderNewDialog;

export async function showMaterialOrderNewDialog(options?: {
  direction?: MaterialOrderDirection;
}): Promise<boolean> {
  console.log(`showMaterialOrderNewDialog`);
  let dialog = createDialog(MaterialOrderNewDialog);
  console.log(`\tdialog created`);
  return await dialog.open(options?.direction);
}

