var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-right": 40,
            transition: "scale-transition",
            "offset-y": "",
            "min-width": "290px",
            "max-width": _vm.$vuetify.breakpoint.smAndUp ? "600px" : undefined,
            "open-on-click": false
          },
          on: { input: _vm.cancel },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on }) {
                return [
                  _c("v-row", { attrs: { "no-gutters": "" } }, [
                    _c(
                      "div",
                      { staticClass: "grow" },
                      [
                        _c(
                          "v-select",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "fd-date-picker-text-field",
                                class: _vm.textFieldClass,
                                style: _vm.textFieldStyle,
                                attrs: {
                                  "data-cy": _vm.cy,
                                  "data-attach": "fd_date_range_picker_select",
                                  label: _vm.$props.label,
                                  rules: _vm.$props.rules,
                                  disabled: _vm.$props.disabled,
                                  outlined: _vm.outlined,
                                  clearable: _vm.clearable,
                                  items: _vm.allVisiblePresets,
                                  "item-value": "key",
                                  "hide-details": false,
                                  "persistent-hint": "",
                                  hint: _vm.dateRangeText,
                                  "menu-props": {
                                    contentClass:
                                      "fd-date-range-picker-preset-menu"
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend-inner",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "v-input__icon v-input__icon--prepend-inner"
                                            },
                                            [
                                              _c(
                                                "v-badge",
                                                {
                                                  attrs: {
                                                    dot: "",
                                                    value: _vm.displayDot,
                                                    color: "red"
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "\n                    event\n                  "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "item",
                                      fn: function({ item }) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "fd-date-range-picker-preset-menu-item-container",
                                              attrs: {
                                                "data-cy": `datarangepickerpreset${item.key}`
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.menuItemSelected(
                                                    item.key
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t(item.labelKey)
                                                    ) +
                                                    "\n                "
                                                )
                                              ])
                                            ]
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "selection",
                                      fn: function({ item }) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t(item.labelKey)) +
                                              "\n            "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.selectedPresetKey,
                                  callback: function($$v) {
                                    _vm.selectedPresetKey = $$v
                                  },
                                  expression: "selectedPresetKey"
                                }
                              },
                              "v-select",
                              _vm.$attrs,
                              false
                            ),
                            on
                          )
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.displayInlineButtons
                      ? _c(
                          "div",
                          { staticClass: "shrink ml-4" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "rgb(122,122,122)",
                                  outlined: "",
                                  disabled:
                                    _vm.disabled ||
                                    _vm.selectedPresetKey == "all"
                                },
                                on: { click: _vm.moveBackCurrentRange }
                              },
                              [_vm._v("Previous")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.displayInlineButtons
                      ? _c(
                          "div",
                          { staticClass: "shrink ml-4" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "rgb(122,122,122)",
                                  outlined: "",
                                  disabled:
                                    _vm.disabled ||
                                    _vm.selectedPresetKey == "all"
                                },
                                on: { click: _vm.moveForwardCurrentRange }
                              },
                              [_vm._v("Next")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]
              }
            }
          ]),
          model: {
            value: _vm.menuOpen,
            callback: function($$v) {
              _vm.menuOpen = $$v
            },
            expression: "menuOpen"
          }
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            { attrs: { flat: "", tile: "" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "fd-date-picker-wrapper",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c("v-row", { staticClass: "fd-date-picker-container" }, [
                    _c(
                      "div",
                      { staticClass: "fd-date-picker-content" },
                      [
                        _c(
                          "v-subheader",
                          {
                            staticClass: "fd-date-picker-content-subheader",
                            staticStyle: {
                              height: "auto",
                              "min-height": "48px",
                              "padding-right": "2px"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "fd-date-picker-content-title",
                                staticStyle: {
                                  width: "48px",
                                  "min-width": "auto"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t(_vm.fromPickerTitleKey)) +
                                    "\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.$vuetify.breakpoint.mdAndUp
                              ? _c("fd-date-picker", {
                                  attrs: {
                                    value: _vm.fromDateISOString,
                                    disabled: _vm.$props.disabled,
                                    locale: _vm.i18nLocale,
                                    "text-field-class": [
                                      "pl-5",
                                      "pt-5",
                                      "fd-date-picker"
                                    ],
                                    events: _vm.activeDates
                                  },
                                  on: { input: _vm.fromDateChanged }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.$vuetify.breakpoint.mdAndUp
                          ? _c("v-date-picker", {
                              staticClass: "fd-date-picker",
                              attrs: {
                                "full-width": "",
                                value: _vm.fromDateISOString,
                                disabled: _vm.$props.disabled,
                                locale: _vm.i18nLocale,
                                events: _vm.activeDates
                              },
                              on: { input: _vm.fromDateChanged }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "fd-date-picker-content" },
                      [
                        _c(
                          "v-subheader",
                          {
                            staticClass: "fd-date-picker-content-subheader",
                            staticStyle: {
                              height: "auto",
                              "min-height": "48px",
                              "padding-right": "2px"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "fd-date-picker-content-title",
                                staticStyle: {
                                  width: "48px",
                                  "min-width": "auto"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t(_vm.toPickerTitleKey)) +
                                    "\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.$vuetify.breakpoint.mdAndUp
                              ? _c("fd-date-picker", {
                                  attrs: {
                                    value: _vm.toDateISOString,
                                    disabled: _vm.$props.disabled,
                                    locale: _vm.i18nLocale,
                                    "text-field-class": [
                                      "pl-5",
                                      "pt-5",
                                      "fd-date-picker"
                                    ],
                                    events: _vm.activeDates
                                  },
                                  on: { input: _vm.toDateChanged }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.$vuetify.breakpoint.mdAndUp
                          ? _c("v-date-picker", {
                              staticClass: "fd-date-picker",
                              attrs: {
                                "full-width": "",
                                value: _vm.toDateISOString,
                                disabled: _vm.$props.disabled,
                                locale: _vm.i18nLocale,
                                events: _vm.activeDates
                              },
                              on: { input: _vm.toDateChanged }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("fd-alert", {
                staticClass: "mx-2",
                attrs: { type: "info", label: _vm.dateRangeInfoMessage }
              }),
              _vm._v(" "),
              _vm.selectedPresetKey == _vm.customPreset.key
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _vm.$vuetify.breakpoint.mdAndUp
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: { outlined: "", color: "secondary" },
                                  on: { click: _vm.moveBack }
                                },
                                [_vm._v("Previous")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$vuetify.breakpoint.mdAndUp
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { outlined: "", color: "secondary" },
                                  on: { click: _vm.moveForward }
                                },
                                [_vm._v("Next")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "primary" },
                              on: { click: _vm.cancel }
                            },
                            [_vm._v(_vm._s(_vm.$t(_vm.cancelButtonLabelKey)))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.done }
                            },
                            [_vm._v(_vm._s(_vm.$t(_vm.doneButtonLabelKey)))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }