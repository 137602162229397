import Vue from "vue";

Vue.config.optionMergeStrategies.twoWayProps = function(toVal: string[], fromVal: string[]) {
  if (!toVal) return fromVal;
  if (!fromVal) return toVal;
  return [...fromVal, ...toVal];
};

declare module "vue/types/options" {
  interface ComponentOptions<V extends Vue> {
    twoWayProps?: string[];
  }
}

export default Vue.extend({
  data() {
    return {
      twoWayProps: {} as { [key: string]: any },
      twoWayPropUnwatches: [] as Array<() => void>
    };
  },

  mounted() {
    // TODO: Error out if twoWayProps doesn't exist?
    let unwatches: Array<() => void> = [];
    for (let propKey of this.$options.twoWayProps!) {
      Vue.set(this.twoWayProps, propKey, (this as any)[propKey]);
      let unwatch = this.$watch(propKey, (newValue: any) => (this.twoWayProps[propKey] = newValue));
      unwatches.push(unwatch);
      unwatch = this.$watch(`twoWayProps.${propKey}`, (newValue: any) => {
        if ((this as any)[propKey] != newValue) {
          this.$emit(`update:${propKey}`, newValue);
        }
      });
      unwatches.push(unwatch);
    }
    this.twoWayPropUnwatches = unwatches;
  },

  destroyed() {
    for (let unwatch of this.twoWayPropUnwatches) {
      unwatch();
    }
  }
});
