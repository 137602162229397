var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "scaffoldrequestapprovalslist" } },
    [
      !_vm.currentUserCanViewScaffoldRequestApprovals
        ? _c("v-alert", { staticClass: "mt-0 mb-3", attrs: { type: "info" } }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t("scaffold-requests.no-permission-to-view-approvals")
                ) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view" },
        [
          _c(
            "fd-toolbar",
            {
              attrs: { loading: _vm.processing },
              scopedSlots: _vm._u([
                {
                  key: "extension",
                  fn: function() {
                    return undefined
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-row",
                { staticClass: "pb-5 pb-sm-0" },
                [
                  _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : ""
                        ],
                        staticStyle: {
                          "font-size": "1.40rem !important",
                          position: "relative"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "0px",
                              left: "0px"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "scaffold-request-approvals.list-title"
                                      )
                                    ) +
                                    "\n                "
                                ),
                                !!_vm.curUserAccess.homeContractorID &&
                                !_vm.curUserAccess.includesAllContractors
                                  ? _c("span", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "hide-when-no-contractor-class"
                                        },
                                        [_vm._v("|")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-view-header-contractor-qualifier-label"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-left": "6px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "contractors.contractor-select"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "fd-view-header-contractor-qualifier-contractor-detail-positioning"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: [
                                                _vm.$vuetify.breakpoint.xs
                                                  ? "fd-view-header-contractor-qualifier-contractor-detail-xs-width-restrictor"
                                                  : "",
                                                "fd-view-header-contractor-qualifier-contractor-detail"
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.curUserContractorName
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "0", sm: "8" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: { justify: "end" }
                        },
                        [
                          _c("v-combobox", {
                            class: [
                              _vm.tagsSelectedForFiltering.length > 0
                                ? "fd-combobox-control-item-selected"
                                : "fd-combobox-control-item-not-selected",
                              "pr-3",
                              "fd-limit-combobox-size-to-content"
                            ],
                            attrs: {
                              items: _vm.tagsInUse,
                              label: _vm.$t("common.filter-by-tags"),
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tagsSelectedForFiltering,
                              callback: function($$v) {
                                _vm.tagsSelectedForFiltering = $$v
                              },
                              expression: "tagsSelectedForFiltering"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass:
                "d-flex align-center justify-end ml-0 mr-0 fd-inline-radio-button-qualifier-background"
            },
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "fd-badge-radio-group",
                  attrs: { row: "", disabled: _vm.processing },
                  model: {
                    value: _vm.selectedViewType,
                    callback: function($$v) {
                      _vm.selectedViewType = $$v
                    },
                    expression: "selectedViewType"
                  }
                },
                [
                  _c(
                    "v-badge",
                    {
                      attrs: { color: "primary", content: _vm.requestedCount }
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$t(
                            "scaffold-request-approvals.requested-radio"
                          ),
                          value: "requested"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-badge",
                    { attrs: { color: "primary", content: _vm.declinedCount } },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$t(
                            "scaffold-request-approvals.declined-radio"
                          ),
                          value: "declined"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.canFilterRequestType
            ? _c(
                "v-row",
                {
                  staticClass:
                    "ml-0 mr-0 fd-secondary-context-qualifier-background",
                  attrs: { align: "center", justify: "end" }
                },
                [
                  _vm.canFilterRequestType
                    ? _c("v-switch", {
                        staticClass: "pr-3",
                        attrs: {
                          "data-cy": "scaffoldSwitch",
                          label: _vm.$t("scaffold-requests.types.scaffold")
                        },
                        model: {
                          value: _vm.showScaffoldRequests,
                          callback: function($$v) {
                            _vm.showScaffoldRequests = $$v
                          },
                          expression: "showScaffoldRequests"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.curUserAccess.canViewMaintenanceJobs
                    ? _c("v-switch", {
                        staticClass: "pr-3",
                        attrs: {
                          "data-cy": "maintenanceSwitch",
                          label: _vm.$t("scaffold-requests.types.maintenance")
                        },
                        model: {
                          value: _vm.showMaintenanceRequests,
                          callback: function($$v) {
                            _vm.showMaintenanceRequests = $$v
                          },
                          expression: "showMaintenanceRequests"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.curUserAccess.canViewPaintJobs
                    ? _c("v-switch", {
                        staticClass: "pr-3",
                        attrs: {
                          "data-cy": "paintSwitch",
                          label: _vm.$t("scaffold-requests.types.paint")
                        },
                        model: {
                          value: _vm.showPaintRequests,
                          callback: function($$v) {
                            _vm.showPaintRequests = $$v
                          },
                          expression: "showPaintRequests"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.curUserAccess.canViewInsulationJobs
                    ? _c("v-switch", {
                        staticClass: "pr-3",
                        attrs: {
                          "data-cy": "insulationSwitch",
                          label: _vm.$t("scaffold-requests.types.insulation")
                        },
                        model: {
                          value: _vm.showInsulationRequests,
                          callback: function($$v) {
                            _vm.showInsulationRequests = $$v
                          },
                          expression: "showInsulationRequests"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { class: [!_vm.canViewContractorFilter ? "mt-3" : "", "pb-0"] },
            [
              _c(
                "v-row",
                [
                  _vm.canViewContractorFilter
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", xs: "12", sm: "6", md: "6" }
                        },
                        [
                          _c("v-select", {
                            staticClass: "pt-0 mt-0",
                            attrs: {
                              label: _vm.$t(
                                "scaffold-requests.filter-by-contractor-label"
                              ),
                              disabled: _vm.processing,
                              items: _vm.selectableContractors,
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.selectedContractors,
                              callback: function($$v) {
                                _vm.selectedContractors = $$v
                              },
                              expression: "selectedContractors"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.canViewDisciplinesFilter
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0",
                          attrs: { cols: "12", xs: "12", sm: "6", md: "6" }
                        },
                        [
                          _c("v-select", {
                            staticClass: "pt-0 mt-0",
                            attrs: {
                              label: _vm.$t(
                                "scaffold-requests.filter-by-discipline-label"
                              ),
                              disabled: _vm.processing,
                              items: _vm.selectableDisciplines,
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.selectedDisciplines,
                              callback: function($$v) {
                                _vm.selectedDisciplines = $$v
                              },
                              expression: "selectedDisciplines"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scheduler.filter-by-area-label"),
                          disabled: _vm.processing,
                          items: _vm.selectableAreas,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.selectedAreas,
                          callback: function($$v) {
                            _vm.selectedAreas = $$v
                          },
                          expression: "selectedAreas"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scheduler.filter-by-sub-area-label"),
                          disabled:
                            !_vm.selectableSubAreas.length || _vm.processing,
                          items: _vm.selectableSubAreas,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.selectedSubAreas,
                          callback: function($$v) {
                            _vm.selectedSubAreas = $$v
                          },
                          expression: "selectedSubAreas"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0 pb-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: { align: "center", justify: "center" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-5",
                              attrs: {
                                "data-cy": "reload",
                                small: "",
                                rounded: "",
                                outlined: "",
                                color: "#7a7a7a"
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              !_vm.$vuetify.breakpoint.sm
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        left: _vm.$vuetify.breakpoint.smAndUp
                                      }
                                    },
                                    [_vm._v("mdi-reload")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            class: [
                              _vm.$vuetify.breakpoint.sm
                                ? "fd-restrict-clearable-position"
                                : "",
                              "pt-0",
                              "mt-0",
                              "fd-table-search-input"
                            ],
                            attrs: {
                              "data-cy": "tablesearch",
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:id.hide-when-header-text-empty",
                value: "",
                expression: "''",
                arg: "id",
                modifiers: { "hide-when-header-text-empty": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:internalRequestNumber",
                value: _vm.$vuetify.breakpoint.lgAndUp
                  ? _vm.$t("scaffold-requests.request-number")
                  : _vm.$t(
                      "scaffold-requests.request-number-column-header-short"
                    ),
                expression:
                  "\n        $vuetify.breakpoint.lgAndUp\n          ? $t('scaffold-requests.request-number')\n          : $t('scaffold-requests.request-number-column-header-short')\n      ",
                arg: "internalRequestNumber"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:scaffoldNumber.hide-when-extra-small",
                value: _vm.$vuetify.breakpoint.lgAndUp
                  ? _vm.$t("scaffold-requests.tag-number-column-header")
                  : _vm.$t("scaffold-requests.tag-number-column-header-short"),
                expression:
                  "\n        $vuetify.breakpoint.lgAndUp\n          ? $t('scaffold-requests.tag-number-column-header')\n          : $t('scaffold-requests.tag-number-column-header-short')\n      ",
                arg: "scaffoldNumber",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:requestTypeName.hide-when-extra-small",
                value: _vm.$t("scaffold-requests.request-type"),
                expression: "$t('scaffold-requests.request-type')",
                arg: "requestTypeName",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:areaName.hide-when-large.hide-when-header-text-empty",
                value: _vm.$t("scaffold-requests.area"),
                expression: "\n        $t('scaffold-requests.area')\n      ",
                arg: "areaName",
                modifiers: {
                  "hide-when-large": true,
                  "hide-when-header-text-empty": true
                }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:subAreaName.hide-when-extra-small",
                value: _vm.$t("scaffold-requests.sub-area"),
                expression: "$t('scaffold-requests.sub-area')",
                arg: "subAreaName",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:requestingContractorName.hide-when-header-text-empty.hide-when-large",
                value:
                  !_vm.curUserAccess.homeContractorID ||
                  _vm.curUserAccess.includesAllContractors
                    ? _vm.$t("scaffold-requests.requesting-contractor")
                    : "",
                expression:
                  "\n        !curUserAccess.homeContractorID || curUserAccess.includesAllContractors\n          ? $t('scaffold-requests.requesting-contractor')\n          : ''\n      ",
                arg: "requestingContractorName",
                modifiers: {
                  "hide-when-header-text-empty": true,
                  "hide-when-large": true
                }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:requestingEmployeeName.hide-when-mobile",
                value: _vm.$t("scaffold-requests.requested-by"),
                expression: "$t('scaffold-requests.requested-by')",
                arg: "requestingEmployeeName",
                modifiers: { "hide-when-mobile": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:disciplineName.hide-when-medium",
                value: _vm.$t("scaffold-requests.discipline"),
                expression: "$t('scaffold-requests.discipline')",
                arg: "disciplineName",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:siteContact.hide-when-medium",
                value: _vm.$t("scaffold-requests.site-contact-column-header"),
                expression:
                  "\n        $t('scaffold-requests.site-contact-column-header')\n      ",
                arg: "siteContact",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:archived.hide-when-mobile.hide-when-header-text-empty",
                value: _vm.showArchived ? _vm.$t("common.archived") : "",
                expression:
                  "\n        showArchived ? $t('common.archived') : ''\n      ",
                arg: "archived",
                modifiers: {
                  "hide-when-mobile": true,
                  "hide-when-header-text-empty": true
                }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                arg: "actions",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "class_fd-actions-cell": true
                }
              },
              {
                name: "fd-row-navigate",
                rawName: "v-fd-row-navigate.show-when-extra-small",
                value: item =>
                  _vm.$router.push(`/scaffoldrequestapprovals/${item.id}`),
                expression:
                  "\n        item => $router.push(`/scaffoldrequestapprovals/${item.id}`)\n      ",
                modifiers: { "show-when-extra-small": true }
              }
            ],
            class: [
              { "fd-archive-bonus-padding-bottom": _vm.showArchived },
              "fd-actions-table",
              "fd-expanding-table",
              "fd-requests-list"
            ],
            attrs: {
              "data-cy": "list",
              "item-key": "id",
              "item-class": item => `${item.id}`,
              "items-per-page": _vm.itemsPerPage,
              "footer-props": {
                "items-per-page-options": _vm.itemsPerPageOptions
              },
              items: _vm.scaffoldRequests,
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "sort-by": ["internalRequestNumber"],
              "sort-desc": true,
              "mobile-breakpoint": "0",
              "show-expand": ""
            },
            scopedSlots: _vm._u([
              {
                key: "item.data-table-expand",
                fn: function({ isExpanded, expand, item }) {
                  return [
                    _c("sp-inline-scope-icons", { attrs: { item: item } }),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { attrs: { justify: "center", "no-gutters": "" } },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "v-data-table__expand-icon",
                            class: {
                              "v-data-table__expand-icon--active": isExpanded
                            },
                            on: {
                              click: function($event) {
                                return expand(!isExpanded)
                              }
                            }
                          },
                          [_vm._v("mdi-chevron-down")]
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "footer.prepend",
                fn: function() {
                  return [
                    _c("fd-archived-data-loader", {
                      staticClass: "ml-2 mr-3",
                      attrs: {
                        showArchived: _vm.showArchived,
                        showArchivedDateRange: _vm.showArchivedDateRange,
                        showArchivedMinDate: _vm.showArchivedMinDate,
                        showArchivedMaxDate: _vm.showArchivedMaxDate,
                        loading: _vm.archivedLoading,
                        disabled: _vm.processing
                      },
                      on: {
                        "input:showArchived": v => (_vm.showArchived = v),
                        "input:showArchivedDateRange": v =>
                          (_vm.showArchivedDateRange = v)
                      }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "item.fd-nav",
                fn: function() {
                  return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
                },
                proxy: true
              },
              {
                key: "item.requestTypeName",
                fn: function({ item }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(`scaffold-requests.types.${item.requestType}`)
                        ) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.scaffoldNumber",
                fn: function({ item }) {
                  return [
                    _c("fd-scaffold-number-with-badges", {
                      attrs: {
                        value: item.scaffoldNumber,
                        otherRequestCount: _vm.requestOtherRequestsBadgeCount(
                          item
                        ),
                        requestCount: _vm.requestRequestsBadgeCount(item),
                        workOrderCount: _vm.requestWorkOrderBadgeCount(item),
                        dismantleCount: _vm.requestDismantleBadgeCount(item)
                      }
                    })
                  ]
                }
              },
              {
                key: "item.archived",
                fn: function({ item }) {
                  return [
                    _vm.showArchived
                      ? _c(
                          "div",
                          [
                            _c("v-simple-checkbox", {
                              attrs: {
                                value: item.archived,
                                disabled: _vm.processing
                              },
                              on: {
                                input: function($event) {
                                  return _vm.flipArchived(item)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "expanded-item",
                fn: function({ item }) {
                  return [
                    _c(
                      "td",
                      {
                        staticClass:
                          "fd-expanding-table-single-details-section",
                        attrs: { colspan: _vm.expanderColSpan }
                      },
                      [
                        _c("fd-work-order-details", {
                          attrs: {
                            workOrder: _vm.workOrderDetailsFromRequest(item),
                            walkdown: item.walkdown
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "table-5-actions-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              disabled:
                                !_vm.$vuetify.breakpoint.lgAndUp ||
                                _vm.processing,
                              left: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            color: "red",
                                            overlap: "",
                                            dot: "",
                                            value: _vm.scaffoldRequestScaffoldHasPotentialConflict(
                                              item
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  icon: "",
                                                  link: "",
                                                  disabled: _vm.processing
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openWorkDetailsForScaffoldDialog(
                                                      item,
                                                      false
                                                    )
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "font-size": "20px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-view-list\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "scaffold-requests.active-dismantle-requests.view-scaffold-active-requests"
                                  )
                                )
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              disabled:
                                !_vm.$vuetify.breakpoint.lgAndUp ||
                                _vm.processing,
                              left: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              "data-cy": "approve",
                                              icon: "",
                                              link: "",
                                              disabled:
                                                !_vm.requestCanBeApproved(
                                                  item
                                                ) || _vm.processing,
                                              loading: _vm.approving
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { small: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.approveRequest(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  far fa-check\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.approve")))
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              disabled:
                                !_vm.$vuetify.breakpoint.lgAndUp ||
                                _vm.processing,
                              left: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              "data-cy": "decline",
                                              icon: "",
                                              link: "",
                                              disabled:
                                                !_vm.requestCanBeDeclined(
                                                  item
                                                ) || _vm.processing,
                                              loading: _vm.declining
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { small: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.declineRequest(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  far fa-times\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.decline")))
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              disabled:
                                !_vm.$vuetify.breakpoint.lgAndUp ||
                                _vm.processing,
                              left: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              "data-cy": "cancel",
                                              icon: "",
                                              link: "",
                                              disabled:
                                                !_vm.requestCanBeCancelled(
                                                  item
                                                ) || _vm.processing,
                                              loading: _vm.cancelling
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { small: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.cancelRequest(item)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  far fa-ban\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.cancel")))
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              disabled:
                                !_vm.$vuetify.breakpoint.lgAndUp ||
                                _vm.processing,
                              top: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              "data-cy": "edit",
                                              icon: "",
                                              link: "",
                                              disabled: _vm.processing,
                                              to: `/scaffoldrequestapprovals/${item.id}`
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-pencil\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }