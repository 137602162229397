import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import rules from "@fd/lib/vue/rules";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import ServiceError from "@fd/lib/client-util/serviceError";
import { SupplierWithTags } from "../../../services";
import userAccess from "../../../dataMixins/userAccess";

const PartNewDialog = FDVue.extend({
  name: "fd-part-new-dialog",

  mixins: [dialogSupport, rules, userAccess],

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-part-bulk-upload-form": () => import("../PartBulkUploadForm.vue")
  },

  data: () => ({
    // The following will control whether the controls on screen are disabled while we are processing.
    downloading: false,
    downloadingerrors: false,
    uploading: false,

    // Variable to hold which expansion panel is currently open
    panel: 0,

    part: {
      publicID: "",
      name: "",
      description: "",
      longDescription: "",
      weight: "",
      mpp: undefined as string | undefined,
      lashingMPP: undefined as string | undefined,
      cleatingMPP: undefined as string | undefined,
      carpentryMPP: undefined as string | undefined,
      otherMPP: undefined as string | undefined,
      rentalRate: "",
      costUsed: "",
      costNew: "",
      designation: "",
      enabled: true
    },

    selectedTags: [] as any[],
    selectedSuppliers: [] as SupplierWithTags[]
  }),

  computed: {
    partRules(): any {
      return {
        fileInput: [this.rules.required],
        publicID: [this.rules.required],
        name: [this.rules.required],
        description: [],
        longDescription: [],
        weight: [this.rules.required, this.rules.numeric],
        mpp: [this.rules.required, this.rules.numeric],
        lashingMPP: [this.rules.numeric],
        cleatingMPP: [this.rules.numeric],
        carpentryMPP: [this.rules.numeric],
        otherMPP: [this.rules.numeric],
        rentalRate: [this.rules.numeric],
        costUsed: [this.rules.numeric],
        costNew: [this.rules.numeric],
        designation: []
      };
    },
    availableSuppliers(): SupplierWithTags[] {
      return this.$store.getters.sortedEnabledSuppliers;
    },
    availableTags(): any[] {
      return this.$store.getters.sortedEnabledTags;
    }
  },

  methods: {
    onItemFormSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    processingUpdated(processing: boolean) {
      this.processing = processing;
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = null;

      if (!(this.$refs.itemForm as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      try {
        if (!this.currentUserCanConfigurePrivateSettings) {
          this.part.mpp = undefined;
          this.part.otherMPP = undefined;
          this.part.lashingMPP = undefined;
          this.part.cleatingMPP = undefined;
          this.part.carpentryMPP = undefined;
        }
        await this.addPart({
          ...this.part,
          cleatingMPP: this.part.cleatingMPP !== "" ? this.part.cleatingMPP : null,
          lashingMPP: this.part.lashingMPP !== "" ? this.part.lashingMPP : null,
          carpentryMPP: this.part.carpentryMPP !== "" ? this.part.carpentryMPP : null,
          otherMPP: this.part.otherMPP !== "" ? this.part.otherMPP : null,
          tagIDs: this.selectedTags.length > 0 ? this.selectedTags.map(x => x.id) : null,
          supplierIDs: this.selectedSuppliers.map(x => x.id)
        });
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as ServiceError, "parts.save-network-error");
      } finally {
        this.processing = false;
      }
    },

    ...mapActions({
      loadSuppliers: "LOAD_SUPPLIERS",
      loadTags: "LOAD_TAGS",
      addPart: "ADD_PART"
    })
  },

  created: async function() {
    this.processing = true;

    try {
      await Promise.all([this.loadSuppliers(), this.loadTags()]);
      if (this.availableSuppliers.length == 1) {
        this.selectedSuppliers = [this.availableSuppliers[0]];
      }
    } catch (error) {
      this.handleError(error as ServiceError);
    } finally {
      this.processing = false;
    }
  }
});

export default PartNewDialog;

export async function showPartNewDialog(options?: {}): Promise<boolean> {
  let dialog = createDialog(PartNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.showDialog!();
}

