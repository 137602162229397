import FDVue from "../../";

export default FDVue.extend({
  name: "fd-subheader",

  props: {
    cy: { type: String, default: undefined },
    dataCy: { type: String, default: "fd-subheader" },

    icon: { type: String },
    title: { type: String },
    label: { type: String },

    onHighlightColor: { type: String },
    alwaysHighlight: { type: [String, Boolean], default: false },
    onColor: { type: String },

    hideSwitch: { type: [String, Boolean], default: false },
    disabled: { type: Boolean, default: false },
    value: { type: Boolean },
    switchOptions: { type: Object }
  },

  data: function() {
    return {};
  },

  methods: {},

  computed: {
    showSwitch(): boolean {
      let hideSwitch = !(
        this.hideSwitch == undefined ||
        this.hideSwitch == "false" ||
        this.hideSwitch === false
      );
      return !hideSwitch;
    },
    showHighlightColor(): boolean {
      let alwaysHighlight = !(
        this.alwaysHighlight == undefined ||
        this.alwaysHighlight == "false" ||
        this.alwaysHighlight === false
      );
      return alwaysHighlight;
    }
  },

  watch: {}
});

