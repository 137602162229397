import { mapActions, mapMutations } from "vuex";
import FDVue from "@fd/lib/vue";
import { Language, TestPackage } from "../services";
import archivedDataList from "../dataMixins/archivedDataList";
import {
  FDColumnDirective,
  FDRowNavigateDirective,
  FDTableSortableDirective
} from "@fd/lib/vue/utility/dataTable";
import userAccess from "../dataMixins/userAccess";
import { SortItemsWithName } from "../utils/person";
import { stripHtml, truncateWithEllipsis } from "@fd/lib/vue/utility/helper";
import { showTestPackageNewDialog } from "./components/dialogs/SP.TestPackageNewDialog.vue";
import { systemDataStore } from "../store";
import { filterBySystemIDs } from "../services/taggableItems";

type TestPackageWithArchived = TestPackage & {
  systemName: string | null | undefined;
  archived: boolean;
};

export default FDVue.extend({
  name: "sp-test-packages-list",

  mixins: [userAccess, archivedDataList],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    fdTableSortable: FDTableSortableDirective
  },

  data: function() {
    return {
      deleting: false,
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5
    };
  },

  computed: {
    languageslist(): Language[] {
      return this.$store.state.languages.fullList as Language[];
    },
    testPackages(): TestPackageWithArchived[] {
      var allTestPackages = (this.$store.state.testPackages
        .fullList as TestPackageWithArchived[]).map(testPackage => {
        return {
          ...testPackage,
          description: truncateWithEllipsis(stripHtml(testPackage.description)),
          systemName: systemDataStore.lookupCaption(testPackage.systemID ?? null),
          archived: !!testPackage.archivedDate
        } as TestPackageWithArchived;
      });
      return SortItemsWithName(
        filterBySystemIDs(this.systemIDsSelectedForFiltering, allTestPackages)
      );
    },

    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },
    systemsInUse() {
      return this.$store.getters.getSortedInUseSystems(this.$store.state.testPackages.fullList);
    },

    systemIDsSelectedForFiltering: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
      },
      set(val) {
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", val);
      }
    }
  },
  methods: {
    navigate(item: TestPackage) {
      this.$router.push(`/testpackages/${item.id}`);
    },
    // *** GLOBAL ***
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadSystems: "LOAD_SYSTEMS",
      loadTestPackages: "LOAD_TEST_PACKAGES",
      updateTestPackage: "UPDATE_TEST_PACKAGE",
      deleteTestPackage: "DELETE_TEST_PACKAGE"
    }),
    async openNewDialog() {
      await showTestPackageNewDialog();
    },
    async loadData() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      await this.loadTestPackages({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },

    async reloadTableData() {
      this.processing = true;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // the following works with the delete "Action" button in the Datatable.
    async deleteTableItem(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteTestPackage({ id: item.id, name: item.name });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async flipArchived(item: TestPackageWithArchived) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateTestPackage({
          id: item.id,
          archivedDate: archivedDate,
          name: item.name
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "testpackages",
      parentalContext: null,
      contextForFiltering: [],
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: new Date(0),
      showArchivedForFilteringToDate: new Date(),
      searchStringForFiltering: ""
    });
    this.notifyNewBreadcrumb({
      text: this.$t("test-packages.list.title"),
      to: "/testpackages",
      resetHistory: true
    });

    this.processing = true;
    try {
      await this.loadSystems();
      await this.loadData();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

