export interface ErrorDetails {
  type?: string;
  message?: string;
  stackTrace?: string;
  rootCause?: Error;
  context?: { [key: string]: string };
}

export default class ServiceError extends Error {
  public statusCode: number;
  public statusMessage: string;
  public details?: ErrorDetails;

  constructor(message?: string, details?: ErrorDetails, statusCode?: number, statusMessage?: string) {
    super(message);
    this.statusCode = statusCode ?? 500;
    this.statusMessage = statusMessage ?? "";
    if (details) this.details = details;
  }
}
