import FDVue from "@fd/lib/vue";
import {
  FDColumnDirective,
  FDHiddenArgumentName,
  FDRowNavigateDirective
} from "@fd/lib/vue/utility/dataTable";

const PeopleList = FDVue.extend({
  name: "fd-people-list",

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {},

  props: {
    items: {
      default: () => [],
      type: Array
    },
    allowDelete: {
      default: true,
      type: Boolean
    },
    hideDateRangePicker: {
      default: false,
      type: Boolean
    },
    tablesearch: {
      default: "",
      type: String
    },
    processing: {
      default: false,
      type: Boolean
    },
    archivedLoading: {
      default: false,
      type: Boolean
    },
    showArchived: {
      default: false,
      type: Boolean
    },
    showArchivedDateRange: {
      default: () => [],
      type: Array
    },
    showArchivedMinDate: {
      default: undefined,
      type: Date
    },
    showArchivedMaxDate: {
      default: undefined,
      type: Date
    },
    anyInactiveUsers: {
      default: false,
      type: Boolean
    },
    anyRequestingAccessUsers: {
      default: false,
      type: Boolean
    },
    anyLockedUsers: {
      default: false,
      type: Boolean
    },
    contractorsInUse: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    iconColumnArgument(): string {
      return this.anyLockedUsers || this.anyInactiveUsers || this.anyRequestingAccessUsers
        ? "icon"
        : FDHiddenArgumentName;
    },
    actionColumnArgument(): string {
      return this.allowDelete ? "actions" : "action";
    }
  },

  methods: {
    itemClassName(item: any) {
      if (!this.$store.state.curEnvironment?.allowSelfSignUp) return "";
      return item.isRequestingEnrolment ? "fd-requesting-user-table-row-background" : "";
    }
  }
});

export default PeopleList;

