import { applicationTypeService } from "../../services/index";
import { createAutomaticCrudStoreModule } from "../automatic";

const storeModule = createAutomaticCrudStoreModule({
  crudService: applicationTypeService,
  singularStoreName: "APPLICATION_TYPE",
  pluralStoreName: "APPLICATION_TYPES",
  localizationPrefix: "build-sheet-setup.tabs.paint.application-types",
  consumerRelatedIDProperty: {
    name: "applicationTypeID",
    type: "string"
  }
});

export default storeModule;

