var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        {},
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "p-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-1 pt-0 pb-0", attrs: { cols: "4" } },
                    [
                      !!_vm.canEditBuildSheet
                        ? _c("fd-text-field", {
                            attrs: {
                              disabled: _vm.processing,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog.length"
                              ),
                              value: _vm.length,
                              "hide-details": "",
                              "hide-spin-buttons": "",
                              type: "number",
                              min: "0",
                              "highlight-on-focus": ""
                            },
                            on: {
                              input: v =>
                                _vm.$emit(
                                  "update:length",
                                  _vm.$parse.sanitizedNumber(v)
                                )
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.canEditBuildSheet
                        ? _c("fd-text-field", {
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog.length"
                              ),
                              value: _vm.$parse.sanitizedNumber(_vm.length),
                              "hide-details": ""
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "px-1 pt-0 pb-0", attrs: { cols: "4" } },
                    [
                      !!_vm.canEditBuildSheet
                        ? _c("fd-text-field", {
                            attrs: {
                              disabled: _vm.processing,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog.width"
                              ),
                              value: _vm.width,
                              "hide-details": "",
                              "hide-spin-buttons": "",
                              type: "number",
                              min: "0",
                              "highlight-on-focus": ""
                            },
                            on: {
                              input: v =>
                                _vm.$emit(
                                  "update:width",
                                  _vm.$parse.sanitizedNumber(v)
                                )
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.canEditBuildSheet
                        ? _c("fd-text-field", {
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog.width"
                              ),
                              value: _vm.$parse.sanitizedNumber(_vm.width),
                              "hide-details": ""
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pl-1 pt-0 pb-0", attrs: { cols: "4" } },
                    [
                      !!_vm.canEditBuildSheet
                        ? _c("fd-text-field", {
                            attrs: {
                              disabled: _vm.processing,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog.height"
                              ),
                              value: _vm.height,
                              "hide-details": "",
                              "hide-spin-buttons": "",
                              type: "number",
                              min: "0",
                              "highlight-on-focus": ""
                            },
                            on: {
                              input: v =>
                                _vm.$emit(
                                  "update:height",
                                  _vm.$parse.sanitizedNumber(v)
                                )
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.canEditBuildSheet
                        ? _c("fd-text-field", {
                            attrs: {
                              disabled: _vm.processing,
                              readonly: true,
                              label: _vm.$t(
                                "norms.work-order-entry-dialog.height"
                              ),
                              value: _vm.$parse.sanitizedNumber(_vm.height),
                              "hide-details": ""
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "p-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-1 pt-0 pb-0", attrs: { cols: "6" } },
                    [
                      _c("fd-text-field", {
                        attrs: {
                          disabled: _vm.processing,
                          readonly: true,
                          label: _vm.$t(
                            "norms.work-order-entry-dialog.total-norm-hours-label"
                          ),
                          value: _vm.$format.number(_vm.totalNormHours, 2),
                          "hide-details": ""
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pl-1 pt-0 pb-0", attrs: { cols: "6" } },
                    [
                      _c("fd-text-field", {
                        attrs: {
                          disabled: _vm.processing,
                          readonly: true,
                          label: _vm.$t(
                            "norms.work-order-entry-dialog.total-tracked-time-label"
                          ),
                          value: _vm.$format.number(_vm.totalTimesheetHours, 2),
                          "hide-details": ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              !!_vm.canEditBuildSheet
                ? _c("v-select", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.processing,
                      label: _vm.$t(
                        "norms.work-order-entry-dialog.scaffold-type"
                      ),
                      items: _vm.scaffoldTypeModifiers,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.scaffoldTypeModifierID,
                      "hide-details": ""
                    },
                    on: {
                      input: v => _vm.$emit("update:scaffoldTypeModifierID", v)
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.canEditBuildSheet
                ? _c("fd-text-field", {
                    attrs: {
                      disabled: _vm.processing,
                      readonly: true,
                      label: _vm.$t(
                        "norms.work-order-entry-dialog.scaffold-type"
                      ),
                      value: _vm.$lookup.scaffoldTypeModifier(
                        _vm.scaffoldTypeModifierID
                      ),
                      "hide-details": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-radio-group",
                {
                  staticClass:
                    "fd-scaffold-hazard-status-radio-group pt-2 mt-0 ml-0 ml-sm-2",
                  attrs: {
                    value: _vm.hazardStatusResult,
                    disabled: _vm.processing,
                    readonly: !_vm.canEditBuildSheet,
                    row: ""
                  },
                  on: { change: v => _vm.$emit("update:hazardStatusResult", v) }
                },
                [
                  _c(
                    "div",
                    [
                      _c("v-radio", {
                        class: [
                          _vm.hazardStatusResult == "green"
                            ? "fd-radio-hazard-status-green"
                            : "",
                          "mr-5"
                        ],
                        attrs: {
                          label: _vm.$t(
                            "norms.work-order-entry-dialog.hazard-status-green"
                          ),
                          value: "green",
                          "data-cy": "hazardstatusgreen"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("v-radio", {
                        class: [
                          _vm.hazardStatusResult == "yellow"
                            ? "fd-radio-hazard-status-yellow"
                            : "",
                          "mr-5"
                        ],
                        attrs: {
                          label: _vm.$t(
                            "norms.work-order-entry-dialog.hazard-status-yellow"
                          ),
                          value: "yellow",
                          "data-cy": "hazardstatusyellow"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("v-radio", {
                        class: [
                          _vm.hazardStatusResult == "red"
                            ? "fd-radio-hazard-status-red"
                            : "",
                          "mr-0"
                        ],
                        attrs: {
                          label: _vm.$t(
                            "norms.work-order-entry-dialog.hazard-status-red"
                          ),
                          value: "red",
                          "data-cy": "hazardstatusred"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              !!_vm.canEditBuildSheet
                ? _c("v-select", {
                    attrs: {
                      outlined: "",
                      disabled: _vm.processing,
                      label: _vm.$t(
                        "work-order-estimates.modifiers.scaffold-elevation"
                      ),
                      items: _vm.scaffoldElevationModifiers,
                      "item-text": "name",
                      "item-value": "id",
                      value: _vm.scaffoldElevationModifierID,
                      "hide-details": ""
                    },
                    on: {
                      input: v =>
                        _vm.$emit("update:scaffoldElevationModifierID", v)
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.canEditBuildSheet
                ? _c("fd-text-field", {
                    attrs: {
                      disabled: _vm.processing,
                      readonly: true,
                      label: _vm.$t(
                        "norms.work-order-entry-dialog.scaffold-elevation"
                      ),
                      value: _vm.$lookup.scaffoldElevationModifier(
                        _vm.scaffoldElevationModifierID
                      ),
                      "hide-details": ""
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "p-0 m-0 pl-1" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        staticClass: "pt-0 mt-1 fd-build-sheet-switch",
                        attrs: {
                          label: _vm.$t(
                            "norms.work-order-entry-dialog.confined-space"
                          ),
                          disabled: _vm.processing,
                          readonly: !_vm.canEditBuildSheet,
                          "input-value": _vm.confinedSpace,
                          "hide-details": ""
                        },
                        on: {
                          change: v => _vm.$emit("update:confinedSpace", v)
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-switch", {
                        staticClass: "pt-0 mt-1 fd-build-sheet-switch",
                        attrs: {
                          label: _vm.$t(
                            "norms.work-order-entry-dialog.ppe-required"
                          ),
                          disabled: _vm.processing,
                          readonly: !_vm.canEditBuildSheet,
                          "input-value": _vm.ppeRequired,
                          "hide-details": ""
                        },
                        on: { change: v => _vm.$emit("update:ppeRequired", v) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("fd-text-field", {
                attrs: {
                  cy: "decklevels",
                  label: _vm.$t("walkdowns.existing-walkdown.deck-levels"),
                  disabled: _vm.processing,
                  readonly: !_vm.canEditBuildSheet,
                  value: _vm.deckLevels,
                  "hide-details": ""
                },
                on: {
                  change: v =>
                    _vm.$emit(
                      "update:deckLevels",
                      _vm.$parse.sanitizedNumber(v)
                    )
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _vm.$store.state.curEnvironment.trackScaffoldVLF
                ? _c("fp-add-remove-field", {
                    attrs: {
                      label: _vm.$t(
                        "walkdowns.existing-walkdown.vertical-length-feet"
                      ),
                      loading: _vm.processing,
                      disabled: _vm.processing,
                      readonly: !_vm.canEditBuildSheet,
                      "before-value": _vm.vlfBeforeValue,
                      "allow-add": _vm.allowVLFAdd,
                      "allow-remove": _vm.allowVLFRemove,
                      value: _vm.vlfChangeAmount,
                      "show-info": false
                    },
                    on: {
                      input: v =>
                        _vm.$emit(
                          "update:vlfChangeAmount",
                          _vm.$parse.sanitizedNumber(v)
                        )
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12" } },
            [
              _c("fd-text-field", {
                staticClass: "mb-3",
                attrs: {
                  disabled: _vm.processing,
                  readonly: !_vm.canEditBuildSheet,
                  label: _vm.$t("norms.work-order-entry-dialog.notes"),
                  value: _vm.notes,
                  "hide-details": ""
                },
                on: { input: v => _vm.$emit("update:notes", v) }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        {
          staticClass: "fd-norm-answer-container",
          attrs: { flat: "", tile: "" }
        },
        [
          !_vm.normsWithResponses.length
            ? _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticStyle: { "justify-content": "center" },
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c("fd-alert", {
                            attrs: {
                              label: _vm.$t(
                                "norms.work-order-entry-dialog.no-norms-configured-message"
                              ),
                              type: "hint"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.normsWithResponses, function(norm) {
            return _c(
              "v-row",
              {
                key: norm.id,
                staticClass: "fd-norm-answer-row",
                attrs: { "no-gutters": "" }
              },
              [
                _c(
                  "v-row",
                  { staticClass: "fd-norm-answer-content" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "py-1", attrs: { cols: "5", sm: "7" } },
                      [
                        !!norm.description
                          ? _c(
                              "span",
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(norm.description) +
                                    "\n            "
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function({ on }) {
                                            return [
                                              !!norm.answeredDescription &&
                                              norm.answeredDescription !=
                                                norm.description
                                                ? _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass: "ml-1",
                                                        attrs: {
                                                          small: "",
                                                          color: "black",
                                                          dark: ""
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v("info")]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      {
                                        attrs: { dense: "", "no-gutters": "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "norms.work-order-entry-dialog.previous-norm-description-message"
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "pl-3",
                                        attrs: { dense: "", "no-gutters": "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(norm.answeredDescription) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !norm.description
                          ? _c(
                              "v-row",
                              { attrs: { "no-gutters": "", dense: "" } },
                              [
                                _c("fd-value-display", {
                                  attrs: { value: norm.answeredDescription }
                                }),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      small: "",
                                                      color: "black",
                                                      dark: ""
                                                    }
                                                  },
                                                  on
                                                ),
                                                [_vm._v("info")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "norms.work-order-entry-dialog.norm-is-no-longer-active-message"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "grow pl-0 py-1" },
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pl-1", attrs: { cols: "6" } },
                              [
                                _c("fd-text-field", {
                                  ref: _vm.getFieldRef("quantity", norm),
                                  refInFor: true,
                                  staticClass: "fd-norm-answer-text-field",
                                  attrs: {
                                    "data-cy": "quantity",
                                    value: norm.quantity,
                                    dense: "",
                                    "hide-details": "",
                                    disabled: _vm.processing,
                                    readonly: !_vm.canEditBuildSheet,
                                    label: _vm.$t(
                                      "norms.work-order-entry-dialog.quantity-label"
                                    ),
                                    "hide-spin-buttons": "",
                                    type: "number",
                                    min: "0",
                                    "highlight-on-focus": ""
                                  },
                                  on: {
                                    input: v =>
                                      _vm.normQuantityUpdated(norm, v),
                                    keydown: [
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "up",
                                            38,
                                            $event.key,
                                            ["Up", "ArrowUp"]
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.selectPreviousField(
                                          "quantity",
                                          norm
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.selectNextField(
                                          "quantity",
                                          norm
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return (e =>
                                          _vm.enterPressed(
                                            e,
                                            "quantity",
                                            norm
                                          )).apply(null, arguments)
                                      }
                                    ]
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { staticClass: "pl-1", attrs: { cols: "6" } },
                              [
                                _c("fd-text-field", {
                                  ref: _vm.getFieldRef("hours", norm),
                                  refInFor: true,
                                  staticClass: "fd-norm-answer-text-field",
                                  attrs: {
                                    "data-cy": "hours",
                                    value: norm.hours,
                                    dense: "",
                                    "hide-details": "",
                                    disabled: _vm.processing,
                                    readonly: !_vm.canEditBuildSheet,
                                    label: _vm.$t(
                                      "norms.work-order-entry-dialog.hours-label"
                                    ),
                                    "hide-spin-buttons": "",
                                    type: "number",
                                    min: "0",
                                    "highlight-on-focus": ""
                                  },
                                  on: {
                                    input: v => _vm.normHoursUpdated(norm, v),
                                    keydown: [
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "up",
                                            38,
                                            $event.key,
                                            ["Up", "ArrowUp"]
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.selectPreviousField(
                                          "hours",
                                          norm
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.selectNextField(
                                          "hours",
                                          norm
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return (e =>
                                          _vm.enterPressed(
                                            e,
                                            "hours",
                                            norm
                                          )).apply(null, arguments)
                                      }
                                    ]
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !!_vm.canEditBuildSheet
                      ? _c(
                          "v-col",
                          { staticClass: "shrink pl-0 pr-2 pt-1 pr-sm-1" },
                          [
                            !_vm.$vuetify.breakpoint.xs
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      text: "",
                                      "x-small": "",
                                      disabled: _vm.processing
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.clearResponses(norm)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("common.clear")))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.$vuetify.breakpoint.xs
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "fd-build-sheet-responsive-clear-button",
                                    attrs: {
                                      "x-small": "",
                                      rounded: "",
                                      outlined: "",
                                      disabled: _vm.processing
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.clearResponses(norm)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticStyle: { "font-size": "14px" },
                                        attrs: { dark: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n              mdi-close\n            "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }