import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import {
  Classification,
  Contractor,
  contractorService,
  Person,
  personService,
  timesheetService,
  TimesheetStatus,
  TimesheetType,
  TimesheetWithDetails,
  userService
} from "@fd/current/client/services";
import { GetPersonName } from "../../../utils/person";
import { TranslateResult } from "vue-i18n";
import userAccess from "../../../dataMixins/userAccess";
import { mapActions } from "vuex";
import { SelectListOption } from "../../../../../lib/vue/utility/select";

const TimesheetNewDialog = FDVue.extend({
  name: "fd-timesheet-new-dialog",

  mixins: [dialogSupport, errorHandling, rules, userAccess],

  components: {},

  data: function() {
    return {
      cachedContractorID: undefined as string | undefined,
      cachedForemanID: undefined as string | undefined,

      ownerHasTimesheetOnDay: false,
      canChangeContractor: true,
      canChangeOwner: true,

      allContractors: [] as Contractor[],

      allForemen: [] as Person[],
      allIndirectTimesheetCreators: [] as Person[],

      timesheet: {
        day: new Date(),
        contractorID: undefined,
        ownerID: undefined,
        timesheetTypeID: TimesheetType.Direct,
        timesheetStatusID: TimesheetStatus.New
      } as TimesheetWithDetails
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return [
        "classificationNameForPerson",
        "loadClassifications",
        "isContractorTypeCombinationEnabled"
      ];
    },
    timesheetTypeOptions(): any[] {
      let options = [];
      if (this.anyDirectContractors) {
        options.push({
          text: this.$t("timesheets.types.direct-label"),
          value: TimesheetType.Direct,
          disabled: !this.isContractorTypeCombinationEnabled(
            this.timesheet.contractorID,
            TimesheetType.Direct
          )
        });
      }
      if (this.anyIndirectContractors) {
        options.push({
          text: this.$t("timesheets.types.indirect-label"),
          value: TimesheetType.Indirect,
          disabled: !this.isContractorTypeCombinationEnabled(
            this.timesheet.contractorID,
            TimesheetType.Indirect
          )
        });
      }
      if (this.anyEquipmentContractors) {
        options.push({
          text: this.$t("timesheets.types.equipment-label"),
          value: TimesheetType.Equipment,
          disabled: !this.isContractorTypeCombinationEnabled(
            this.timesheet.contractorID,
            TimesheetType.Equipment
          )
        });
      }
      return options;
    },
    visibleOwners(): (Person & { name: string })[] {
      if (
        this.timesheet.timesheetTypeID == TimesheetType.Indirect ||
        this.timesheet.timesheetTypeID == TimesheetType.Equipment
      ) {
        let visibleIndirectTimesheetCreators = this.allIndirectTimesheetCreators
          .filter(x => x.contractorID == this.timesheet.contractorID)
          .map(x => ({ ...x, name: GetPersonName(x) }))
          .sort((a, b) => {
            let nameA = (a.name ?? "").toLowerCase();
            let nameB = (b.name ?? "").toLowerCase();
            if (nameA < nameB) return -1;
            else if (nameA > nameB) return 1;
            else return 0;
          });
        return visibleIndirectTimesheetCreators;
      } else {
        let visibleForemen = this.allForemen
          .filter(x => x.contractorID == this.timesheet.contractorID)
          .map(x => ({ ...x, name: GetPersonName(x) }))
          .sort((a, b) => {
            let nameA = (a.name ?? "").toLowerCase();
            let nameB = (b.name ?? "").toLowerCase();
            if (nameA < nameB) return -1;
            else if (nameA > nameB) return 1;
            else return 0;
          });
        return visibleForemen;
      }
    },
    anyDirectContractors(): boolean {
      return this.allContractors.findIndex(x => !!x.allowDirectTimesheets) !== -1;
    },
    anyIndirectContractors(): boolean {
      return this.allContractors.findIndex(x => !!x.allowIndirectTimesheets) !== -1;
    },
    anyEquipmentContractors(): boolean {
      return this.allContractors.findIndex(x => !!x.allowEquipmentTimesheets) !== -1;
    },
    selectableContractors(): SelectListOption<Contractor>[] {
      let allContractors = this.allContractors;
      // let selectableContractorIDs = this.allForemen.map(x => x.contractorID);
      // selectableContractorIDs = [...new Set(selectableContractorIDs)];
      return (
        allContractors
          .map(x => ({
            ...x,
            disabled: !this.isContractorTypeCombinationEnabled(x.id, this.timesheet.timesheetTypeID)
          }))
          // .filter(x => selectableContractorIDs.includes(x.id))
          .sort((a, b) => {
            let nameA = (a.name ?? "").toLowerCase();
            let nameB = (b.name ?? "").toLowerCase();
            if (nameA < nameB) return -1;
            else if (nameA > nameB) return 1;

            return 0;
          })
      );
    },
    timesheetRules(): { [key: string]: Array<Function | boolean | TranslateResult | string> } {
      return {
        day: [this.rules.required],
        contractor: [this.rules.required],
        owner: [this.rules.required]
      };
    }
  },

  watch: {
    "timesheet.contractorID": function() {
      if (this.visibleOwners.length == 1) {
        this.timesheet.ownerID == this.visibleOwners[0].id;
      } else if (this.visibleOwners.findIndex(x => x.id == this.timesheet.ownerID) === -1) {
        this.timesheet.ownerID = undefined;
      }
      this.validateNotDuplicateTimesheet();
    },
    "timesheet.timesheetTypeID": function(newValue, oldValue) {
      let timesheetWasDirect = oldValue == TimesheetType.Direct;
      if (this.timesheet.timesheetTypeID == TimesheetType.Indirect) {
        if (timesheetWasDirect) {
          this.cachedContractorID = this.timesheet.contractorID;
          this.cachedForemanID = this.timesheet.ownerID;
        }

        this.timesheet.contractorID =
          this.cachedContractorID ?? this.curUserAccess.homeContractorID ?? undefined;
        this.timesheet.ownerID = this.curUserID;
      } else if (this.timesheet.timesheetTypeID == TimesheetType.Equipment) {
        if (timesheetWasDirect) {
          this.cachedContractorID = this.timesheet.contractorID;
          this.cachedForemanID = this.timesheet.ownerID;
        }

        this.timesheet.contractorID =
          this.cachedContractorID ?? this.curUserAccess.homeContractorID ?? undefined;
        this.timesheet.ownerID = this.curUserID;
      } else {
        this.timesheet.contractorID = this.cachedContractorID;
        this.timesheet.ownerID = this.cachedForemanID;
      }
      this.validateNotDuplicateTimesheet();
    },
    "timesheet.ownerID": function() {
      this.validateNotDuplicateTimesheet();
    },
    "timesheet.day": function() {
      this.validateNotDuplicateTimesheet();
    }
  },

  methods: {
    isContractorTypeCombinationEnabled(
      contractorID: string | null | undefined,
      type: TimesheetType | undefined
    ): boolean {
      if (type === undefined) return false;
      if (!contractorID) return true;

      let contractor = this.allContractors.find(x => x.id == contractorID);
      if (!contractor) return true;

      let isDirect = type == TimesheetType.Direct;
      let isIndirect = type == TimesheetType.Indirect;
      let isEquipment = type == TimesheetType.Equipment;
      return (
        (isDirect && !!contractor.allowDirectTimesheets) ||
        (isIndirect && !!contractor.allowIndirectTimesheets) ||
        (isEquipment && !!contractor.allowEquipmentTimesheets)
      );
    },
    classificationNameForPerson(person: Person) {
      let classification = (this.$store.state.classifications.fullList as Classification[]).find(
        x => x.id == person.classificationID
      );
      return classification?.alias ?? classification?.name;
    },
    async validateNotDuplicateTimesheet() {
      this.optOutOfErrorHandling();
      if (!this.timesheet.ownerID || !this.timesheet.contractorID || !this.timesheet.day) {
        this.ownerHasTimesheetOnDay = false;
        return;
      }
      this.processing = true;
      try {
        this.ownerHasTimesheetOnDay = await timesheetService.checkOwnerHasTimesheetForContractorOnDay(
          this.timesheet.ownerID,
          this.timesheet.contractorID,
          this.timesheet.day,
          this.timesheet.timesheetTypeID!
        );
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async loadData() {
      this.optOutOfErrorHandling();
      this.processing = true;
      try {
        await this.loadClassifications();
        this.processing = true;
        this.allContractors = (await contractorService.getAll(false, null, null))
          .filter(c => !!c.tracksEmployeeTime)
          .sort((a, b) => {
            let nameA = (a.name ?? "").toLowerCase();
            let nameB = (b.name ?? "").toLowerCase();
            if (nameA < nameB) return -1;
            else if (nameA > nameB) return 1;
            else return 0;
          });

        this.allForemen = await personService.getAllVisibleDirectTimesheetCreators();
        this.allIndirectTimesheetCreators = await personService.getAllVisibleIndirectTimesheetCreators();

        if (this.selectableContractors.length == 1) {
          this.timesheet.contractorID = this.selectableContractors[0].id;
          console.log(
            `Only 1 contractor available.  Setting timesheet contractor to: ${this.selectableContractors[0].name}`
          );
        }

        if (this.visibleOwners.length == 1) {
          this.timesheet.ownerID == this.visibleOwners[0].id;
          console.log(
            `Only 1 owner available.  Setting timesheet owner to: ${this.visibleOwners[0].name}`
          );
        }

        if (!!this.timesheet.ownerID && !this.timesheet.contractorID) {
          let owner = this.allForemen.find(x => x.id == this.timesheet.ownerID);
          this.timesheet.contractorID = owner?.contractorID ?? undefined;
          console.log(
            `Contractor for owned timesheet is empty.  Setting to ${owner?.contractorID ??
              undefined}`
          );
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async open(
      contractorID: string | undefined,
      ownerID: string | undefined
    ): Promise<string | boolean> {
      if (!!contractorID) {
        // this.canChangeContractor = false;
        this.timesheet.contractorID = contractorID;
      }
      if (!!ownerID?.length) {
        // this.canChangeContractor = false;
        // this.canChangeOwner = false;
        this.timesheet.ownerID = ownerID;
      }
      this.loadData();
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      this.optOutOfErrorHandling();
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        var newTimesheetID = await timesheetService.addItem(this.timesheet);
        this.closeDialog!(newTimesheetID);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    ...mapActions({
      loadClassifications: "LOAD_CLASSIFICATIONS"
    })
  },

  created: async function() {}
});

export default TimesheetNewDialog;

export async function createNewTimesheet(
  contractorID: string | undefined,
  ownerID: string | undefined
): Promise<string | boolean> {
  let dialog = createDialog(TimesheetNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(contractorID, ownerID);
}

