import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";

const ContractorNewDialog = FDVue.extend({
  name: "fd-contractor-new-dialog",

  mixins: [dialogSupport, errorHandling, rules],

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue")
  },

  data: function() {
    return {
      contractor: {
        name: "",
        alias: "",
        description: "",
        archived: false
      } as any,

      selectedTags: [] as any[]
    };
  },

  computed: {
    availableTags(): any[] {
      return this.$store.getters.sortedEnabledTags;
    }
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        await this.addContractor({
          ...this.contractor,
          enabled: true,
          tagIDs: this.selectedTags.length > 0 ? this.selectedTags.map(x => x.id) : null
        });
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    ...mapActions({
      addContractor: "ADD_CONTRACTOR",
      loadTags: "LOAD_TAGS"
    })
  },

  created: async function() {
    this.processing = true;
    try {
      await Promise.all([this.loadTags()]);
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

export default ContractorNewDialog;

export async function createNewContractor(): Promise<boolean> {
  let dialog = createDialog(ContractorNewDialog);
  return await dialog.showDialog!();
}

