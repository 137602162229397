import FDVue from "@fd/lib/vue";
import { mapMutations, mapState, mapActions } from "vuex";
import i18n from "../i18n";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { Discipline } from "../services";
import rules from "@fd/lib/vue/rules";
import { addDaysToDate, addMonthsToDate } from "@fd/lib/client-util/datetime";

type DisciplineWithArchived = Discipline & {
  archived: boolean;
};

export default FDVue.extend({
  mixins: [errorHandling, rules],

  name: "fd-discipline-existing",

  components: {},

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      deleting: false,

      slidein: false,

      discipline: {} as DisciplineWithArchived
    };
  },

  computed: {},

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },
    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.discipline.archived) {
          this.discipline.archivedDate = null;
        } else if (this.discipline.archived && !this.discipline.archivedDate) {
          this.discipline.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateDiscipline({
          ...this.discipline
        });
        if (closeOnComplete) {
          this.$router.push("/disciplines");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      this.deleting = true;
      try {
        await this.deleteDiscipline({ id: this.$route.params.id, name: this.discipline.name });
        this.$router.push("/disciplines");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push("/disciplines");
    },

    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadDiscipline: "LOAD_DISCIPLINE",
      updateDiscipline: "UPDATE_DISCIPLINE",
      deleteDiscipline: "DELETE_DISCIPLINE"
    })
  },

  watch: {
    discipline(newValue) {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/disciplines") {
        this.notifyNewBreadcrumb({
          text: this.$t("disciplines.list.title"),
          to: "/disciplines",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: newValue.name,
        to: `/disciplines/${this.$route.params.id}`
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    this.processing = true;
    try {
      await Promise.all([this.loadDiscipline(this.$route.params.id)]);

      let discipline = this.$store.state.disciplines.fullList.find(
        (x: any) => x.id == this.$route.params.id
      );
      this.discipline = {
        ...discipline,
        archived: !!discipline.archivedDate,
        created: undefined,
        createdBy: undefined,
        updated: undefined,
        updatedBy: undefined
      };
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});


