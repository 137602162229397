import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import {
  Contractor,
  contractorService,
  lemsService,
  LemStatus,
  LEMWithDetails
} from "@fd/current/client/services";

const LemNewDialog = FDVue.extend({
  name: "fd-lem-new-dialog",

  mixins: [dialogSupport, errorHandling, rules],

  components: {},

  data: function() {
    return {
      contractors: [] as Contractor[],
      lem: {
        day: new Date(),
        contractorID: undefined,
        lemStatusID: LemStatus.New
      } as LEMWithDetails
    };
  },

  computed: {},

  methods: {
    async loadData() {
      this.processing = true;
      try {
        this.contractors = (await contractorService.getAll(false, null, null))
          .filter(c => !!c.tracksEmployeeTime)
          .sort((a, b) => {
            let nameA = (a.name ?? "").toLowerCase();
            let nameB = (b.name ?? "").toLowerCase();
            if (nameA < nameB) return -1;
            else if (nameA > nameB) return 1;

            return 0;
          });
      } catch (error) {
      } finally {
        this.processing = false;
      }
    },
    async open(): Promise<string | boolean> {
      this.loadData();
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        var newLemID = await lemsService.addItem(this.lem);
        this.closeDialog!(newLemID);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },

  created: async function() {}
});

export default LemNewDialog;

export async function createNewLem(): Promise<string | boolean> {
  let dialog = createDialog(LemNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open();
}

