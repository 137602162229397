var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !!_vm.scaffolds && !!_vm.scaffolds.length
    ? _c(
        "v-container",
        { staticClass: "pa-0 ma-0 sp-scaffold-locations-popup-container" },
        _vm._l(_vm.scaffolds, function(scaffold) {
          return _c(
            "v-card",
            {
              key: scaffold.id,
              staticClass: "pb-1 my-1 sp-scaffold-locations-popup-content",
              staticStyle: { width: "300px !important" },
              attrs: { flat: "", to: `/scaffolds/${scaffold.id}` }
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "sp-scaffold-locations-popup-title",
                  attrs: { dense: "", "no-gutters": "" }
                },
                [
                  _c("h3", [_vm._v("T-" + _vm._s(scaffold.scaffoldNumber))]),
                  _vm._v(" "),
                  !!scaffold.externalReferenceNumber
                    ? _c(
                        "v-chip",
                        {
                          class: [
                            "ml-2",
                            "sp-scaffold-locations-popup-ref-chip"
                          ],
                          attrs: { "x-small": "", outlined: "", color: "white" }
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(scaffold.externalReferenceNumber) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !!scaffold.scaffoldStatus
                    ? _c(
                        "v-chip",
                        {
                          class: [
                            "ml-2",
                            "sp-scaffold-locations-popup-status-indicator",
                            "white--text"
                          ],
                          attrs: {
                            "x-small": "",
                            color: _vm.statusColour(scaffold)
                          }
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  `scaffolds.status.${scaffold.scaffoldStatus}`
                                )
                              ) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-spacer")
                ],
                1
              ),
              _vm._v(" "),
              !!scaffold.areaID
                ? _c(
                    "v-row",
                    {
                      staticClass: "sp-scaffold-locations-popup-detail-row",
                      attrs: { dense: "" }
                    },
                    [
                      _c("v-col", { attrs: { cols: "6", sm: "3" } }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("scaffolds.map.area")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "6", sm: "9" } }, [
                        _vm._v(_vm._s(_vm.$lookup.location(scaffold.areaID)))
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !!scaffold.subAreaID
                ? _c(
                    "v-row",
                    {
                      staticClass: "sp-scaffold-locations-popup-detail-row",
                      attrs: { dense: "" }
                    },
                    [
                      _c("v-col", { attrs: { cols: "6", sm: "3" } }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("scaffolds.map.sub-area")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "6", sm: "9" } }, [
                        _vm._v(_vm._s(_vm.$lookup.location(scaffold.subAreaID)))
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass: "mx-0 sp-scaffold-locations-popup-click-details",
                  attrs: { justify: "end" }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("scaffolds.map.click-to-view-instructions")
                      ) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }