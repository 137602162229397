import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import ServiceError from "@fd/lib/client-util/serviceError";
import { countSheetGroupService, CountSheetGroupWithParts, JobTypes } from "../../../services";
import { SortCountSheetGroups, SortParts } from "../../../dataMixins/countSheetGroupSorting";
import draggable from "vuedraggable";
import { SortableEvent } from "sortablejs";
import { mapActions } from "vuex";

// "All" is not allowed in the dialog
type FilteringContext = "scaffold" | "maintenance" | "paint" | "insulation";

const CountSheetPreviewDialog = FDVue.extend({
  name: "fd-count-sheet-preview-dialog",

  mixins: [dialogSupport, errorHandling, rules],

  components: { draggable },

  data: function() {
    return {
      cachedCountSheetGroups: undefined as CountSheetGroupWithParts[] | undefined,
      openPanels: [] as number[],
      groupsUpdatedAction: undefined as Function | undefined,
      partsUpdatedAction: undefined as Function | undefined,
      jobType: JobTypes.Scaffold
    };
  },

  computed: {
    visibleJobCount(): number {
      return (
        (this.showScaffoldColumn ? 1 : 0) +
        (this.showMaintenanceColumn ? 1 : 0) +
        (this.showPaintColumn ? 1 : 0) +
        (this.showInsulationColumn ? 1 : 0)
      );
    },
    showScaffoldColumn(): boolean {
      return true;
    },
    showMaintenanceColumn(): boolean {
      return this.$store.state.curEnvironment.enableMaintenanceWork ?? false;
    },
    showPaintColumn(): boolean {
      return this.$store.state.curEnvironment.enablePaintWork ?? false;
    },
    showInsulationColumn(): boolean {
      return this.$store.state.curEnvironment.enableInsulationWork ?? false;
    },
    filterContext: {
      get(): FilteringContext {
        if (this.jobType == JobTypes.Insulation) return "insulation";
        else if (this.jobType == JobTypes.Paint) return "paint";
        else if (this.jobType == JobTypes.Maintenance) return "maintenance";
        return "scaffold";
      },
      set(val: FilteringContext) {
        if (val == "insulation") this.jobType = JobTypes.Insulation;
        else if (val == "paint") this.jobType = JobTypes.Paint;
        else if (val == "maintenance") this.jobType = JobTypes.Maintenance;
        else this.jobType = JobTypes.Scaffold;
      }
    },
    countSheetGroups: {
      get(): CountSheetGroupWithParts[] {
        console.log(`countSheetGroups`);
        if (!this.cachedCountSheetGroups) {
          this.cachedCountSheetGroups = SortCountSheetGroups(
            this.$store.state.countSheetGroups.fullList as CountSheetGroupWithParts[]
          );
          this.cachedCountSheetGroups.forEach(group => (group.parts = SortParts(group.parts)));
        }
        var countSheetGroups = this.cachedCountSheetGroups;
        if (this.jobType != null) {
          countSheetGroups = countSheetGroups.filter(
            x =>
              (this.jobType == JobTypes.Insulation && x.isInsulationGroup == true) ||
              (this.jobType == JobTypes.Maintenance && x.isMaintenanceGroup == true) ||
              (this.jobType == JobTypes.Paint && x.isPaintGroup == true) ||
              (this.jobType == JobTypes.Scaffold && x.isScaffoldGroup == true)
          );
        }
        return countSheetGroups;
      },
      set(val: CountSheetGroupWithParts[]) {
        this.cachedCountSheetGroups = val;
      }
    }
  },

  methods: {
    async groupDragEnded(e: SortableEvent) {
      // using the `<draggable` component means the list itself has already been reordered
      // As such, we need to use the new index to find the moved item, and we don't need to move the item ourselves
      console.log(`groupDragEnded e: ${e}`);
      let oldIndex = e.oldIndex ?? 0;
      let newIndex = e.newIndex ?? 0;
      if (oldIndex == newIndex) return;

      // let movedItem = this.countSheetGroups[newIndex];
      // movedItem.order = newIndex + 1;

      this.processing = true;
      try {
        let itemsToUpdate = [] as CountSheetGroupWithParts[];

        this.countSheetGroups.forEach((group, index) => {
          let newOrder = index + 1;
          // console.log(`  ${group.name}: ${group.order} vs ${newOrder}`);
          if (!group.order || group.order != newOrder) {
            console.log(`    ${group.order} --> ${newOrder}`);
            group.order = newOrder;
            itemsToUpdate.push(group);
          }
        });

        if (itemsToUpdate.length > 0) {
          console.log(` updating ${itemsToUpdate.length} groups' ordering`);
          await countSheetGroupService.updateCountSheetGroupOrders(itemsToUpdate);

          if (this.groupsUpdatedAction) this.groupsUpdatedAction();
          var snackbarPayload = {
            text: this.$t("countsheetgroups.snack-bar-order-updated-message"),
            type: "success",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        }
        // let lastOrder = 0;
        // let updatedMovedItem = false;
        // for (var i = 0; i < this.countSheetGroups.length; i++) {
        //   let group = this.countSheetGroups[i];
        //   if (!group.order || group.order <= lastOrder) {
        //     group.order = lastOrder + 1;
        //     await this.updateCountSheetGroup({
        //       id: group.id,
        //       name: group.name,
        //       order: group.order
        //     });
        //     if (group.id == movedItem.id) updatedMovedItem = true;
        //   }
        //   lastOrder = group.order;
        // }
        // if (!updatedMovedItem) {
        //   await this.updateCountSheetGroup({
        //     id: movedItem.id,
        //     name: movedItem.name,
        //     order: movedItem.order
        //   });
        // }
      } catch (error) {
        this.handleError(error as ServiceError);
      } finally {
        this.processing = false;
      }
    },
    async partMovedInList(e: SortableEvent, sourceGroup: CountSheetGroupWithParts) {
      console.log(`partMovedInList e: ${e}, sourceGroup: ${sourceGroup.name}`);
      let oldIndex = e.oldIndex ?? 0;
      let newIndex = e.newIndex ?? 0;
      console.log(`  old: ${oldIndex}, new: ${newIndex}`);

      let parts = sourceGroup.parts ?? [];
      parts.forEach((part, index) => {
        console.log(`  ${part.publicID}: ${part.countSheetGroupDisplayOrder} --> ${index + 1}`);
        part.countSheetGroupDisplayOrder = index + 1;
      });

      this.processing = true;
      try {
        await countSheetGroupService.updateCountSheetGroupParts(sourceGroup.id!, parts);
        if (this.partsUpdatedAction) this.partsUpdatedAction();

        var snackbarPayload = {
          text: this.$t("countsheetgroups.snack-bar-group-parts-order-updated-message"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        await this.loadCountSheetGroups();
        this.cachedCountSheetGroups = undefined;
      } finally {
        this.processing = false;
      }
    },
    async partRemoved(e: SortableEvent, sourceGroup: CountSheetGroupWithParts) {
      console.log(`partRemoved e: ${e}, sourceGroup: ${sourceGroup.name}`);
      // We don't actually care about a part being removed from a group
      // This is because we track when the part is added to a group, and the new group's ID will overwrite the previous group's ID
    },
    async partAdded(e: SortableEvent, targetGroup: CountSheetGroupWithParts) {
      console.log(`partAdded e: ${e}, targetGroup: ${targetGroup.name}`);

      let parts = targetGroup.parts ?? [];
      parts.forEach((part, index) => {
        part.countSheetGroupDisplayOrder = index + 1;
      });

      this.processing = true;
      try {
        await countSheetGroupService.updateCountSheetGroupParts(targetGroup.id!, parts);
        if (this.partsUpdatedAction) this.partsUpdatedAction();

        var snackbarPayload = {
          text: this.$t("countsheetgroups.snack-bar-group-parts-order-updated-message"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        await this.loadCountSheetGroups();
        this.cachedCountSheetGroups = undefined;
      } finally {
        this.processing = false;
      }
    },
    async open(
      groupsUpdatedAction: Function,
      partsUpdatedAction: Function,
      defaultJobType: JobTypes | null
    ) {
      if (defaultJobType != null) this.jobType = defaultJobType;
      this.groupsUpdatedAction = groupsUpdatedAction;
      this.partsUpdatedAction = partsUpdatedAction;
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as ServiceError);
      } finally {
        this.processing = false;
      }
    },

    ...mapActions({
      loadCountSheetGroups: "LOAD_COUNT_SHEET_GROUPS",
      updateCountSheetGroup: "UPDATE_COUNT_SHEET_GROUP"
    })
  },

  created: async function() {
    this.processing = true;
    try {
      await this.loadCountSheetGroups({
        forcedArchivedState: false,
        archivedFromDate: null,
        archivedToDate: null
      });
      this.openPanels = [];
      this.countSheetGroups.forEach((group, index) => {
        // if (!!group.parts?.length) this.openPanels.push(index);
      });
      this.countSheetGroups.forEach(x => (x.parts = SortParts(x.parts)));
    } catch (error) {
      this.handleError(error as ServiceError);
    } finally {
      this.processing = false;
    }
  }
});

export default CountSheetPreviewDialog;

export async function showCountSheetPreviewDialog(
  groupsUpdatedAction: Function,
  partsUpdatedAction: Function,
  defaultJobType: JobTypes | null
): Promise<boolean> {
  let dialog = createDialog(CountSheetPreviewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(groupsUpdatedAction, partsUpdatedAction, defaultJobType);
}

