var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("span", [
    _vm.value
      ? _c(
          "div",
          { class: { "fd-kanban-item-text-width-restriction": _vm.dense } },
          [_vm._v("\n    " + _vm._s(_vm.value) + "\n  ")]
        )
      : _c("div", { staticClass: "fd-item-placeholder" }, [
          _vm._v("\n    " + _vm._s(_vm.$t("common.not-available")) + "\n  ")
        ]),
    _vm._v(" "),
    _c("div", { staticClass: "fd-kanban-item-expander-details-item-label" }, [
      _vm._v("\n    " + _vm._s(_vm.label) + "\n  ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }