import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { Language, Question, QuestionWithText } from "../../../services";
import { mapActions } from "vuex";
import { truncateWithEllipsis } from "../../../../../lib/vue/utility/helper";
import { TranslateResult } from "vue-i18n";

type QuestionTextWithLanguage = { text: string; languageCode: string; languageName: string };

const QuestionNewDialog = FDVue.extend({
  name: "sp-question-new-dialog",
  mixins: [dialogSupport],
  data: function() {
    return {
      saving: false,
      displayOrder: 0,
      questionTextList: [] as QuestionTextWithLanguage[]
    };
  },
  computed: {
    unwatchedMethodNames(): string[] {
      return [];
    },
    languageslist(): Language[] {
      return this.$store.state.languages.fullList as Language[];
    },
    answeredQuestionTextList(): QuestionTextWithLanguage[] {
      return this.questionTextList.filter(x => !!x.text?.length);
    },
    questionText(): string | null | undefined {
      if (!this.answeredQuestionTextList.length) return undefined;

      var languageNumber = this.$store.state.language;
      var selectedLanguage = this.languageslist.find(x => x.number == languageNumber);
      var languageCode = selectedLanguage?.shortCode ?? "en";

      var questionText = this.answeredQuestionTextList.find(x => x.languageCode == languageCode)
        ?.text;
      if (!questionText?.length) {
        questionText = this.answeredQuestionTextList[0].text;
      }
      return truncateWithEllipsis(questionText);
    },
    hasText(): boolean {
      return !!this.questionText?.length;
    }
  },
  methods: {
    hasTextRule(): boolean | string | TranslateResult {
      return !!this.hasText; // || this.$t("questions.new.text-missing-error-message");
    },
    async open(lastDisplayOrder: number | undefined): Promise<boolean> {
      this.optOutOfErrorHandling();

      if (lastDisplayOrder !== undefined) this.displayOrder = lastDisplayOrder + 1;

      for (let lang of this.languageslist) {
        this.questionTextList.push({
          text: "",
          languageCode: lang.shortCode,
          languageName: lang.name
        } as QuestionTextWithLanguage);
      }

      return await this.showDialog!();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        this.inlineMessage.message = this.$t("questions.new.text-missing-error-message");
        return;
      }

      this.saving = true;
      this.processing = true;
      try {
        var textByLanguageCode = {} as { [key: string]: string };
        for (let questionText of this.answeredQuestionTextList) {
          textByLanguageCode[questionText.languageCode!] = questionText.text;
        }
        await this.addQuestion({
          displayOrder: this.displayOrder,
          textByLanguageCode: textByLanguageCode,
          name: this.questionText
        } as QuestionWithText & { name: string | undefined });
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    ...mapActions({
      addQuestion: "ADD_QUESTION"
    })
  }
});
export default QuestionNewDialog;

export async function showQuestionNewDialog(
  lastDisplayOrder: number | undefined
): Promise<boolean> {
  let dialog = createDialog(QuestionNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(lastDisplayOrder);
}

