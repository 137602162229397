import { executeRawHttpRequest, HttpBlobResponse, createQueryString } from "./http";
import { serviceJsonStringifyReplacer, serviceJsonParseReviver } from "./json";
import ServiceError, { ErrorDetails } from "./serviceError";

export async function executeServiceRequest<TResult = any>(
  path: string,
  payload: any
): Promise<TResult> {
  return await _executeServiceRequest(path, JSON.stringify(payload, serviceJsonStringifyReplacer));
}

export async function executeUploadServiceRequest<TResult = any>(
  path: string,
  payload: Blob,
  otherArguments: { [name: string]: string | string[] | number | boolean }
): Promise<TResult> {
  let headers = payload.type ? { "Content-Type": payload.type } : undefined;
  return await _executeServiceRequest(path, payload, otherArguments, headers);
}

export async function _executeServiceRequest<TResult = any>(
  path: string,
  payload: any,
  otherArguments?: { [name: string]: string | string[] | number | boolean },
  headers?: { [name: string]: string | string[] }
): Promise<TResult> {
  let retryCount = 3;
  let response: HttpBlobResponse | undefined = undefined;
  if (otherArguments) {
    path = path + createQueryString(otherArguments);
  }
  while (retryCount-- > 0) {
    response = await executeRawHttpRequest("POST", path, "blob", {
      content: payload,
      headers
    });
    if (response.status === 401) {
      // The user needs to log in; if the user cancels this request or we have no login handler
      // we stop retrying and report the error, otherwise we do a retry once the login is done
      let loginCompleted = await executeLoginHandler();
      if (!loginCompleted) break;
    } else if (response.status >= 400) {
      // We don't currently retry for anything but an authentication error; kick out of our
      // retry cycle and report the error
      break;
    } else if (response.body?.size) {
      let contentType = response.body.type || "application/octet-stream";
      // Use an includes instead of equals, the content type from safari (for example) comes back as 'application/json;charset=utf-8'
      if (contentType.includes("application/json")) {
        var text = "";

        if (!!response.body.text) {
          text = await response.body.text();
        } else {
          text = await new Promise<string>(function(resolve, reject) {
            const reader = new FileReader();
            reader.addEventListener("loadend", e => {
              resolve(reader.result as string);
            });
            reader.addEventListener("error", function(e) {
              reject(reader.error);
            });
            reader.readAsText(response!.body);
          });
        }
        return JSON.parse(text, serviceJsonParseReviver);
      } else {
        return (response.body as unknown) as TResult;
      }
    } else {
      // Because of compile time checks this should never happen unless TResult is actually
      // compatible with TResult
      return (undefined as unknown) as TResult;
    }
  }

  // If we get here, we either failed in a way that doesn't allow a retry or we went through
  // all of our retries; the response will be an error we need to report
  // TODO: Add additional info from the server to this payload
  let details: ErrorDetails | undefined;
  let message: string | undefined;
  const statusText =
    response?.statusText || `HTTP Status ${response?.status || "Unspecified"}})`;
  try {
    let rawDetails = await response?.body?.text();
    if (rawDetails) {
      details = JSON.parse(rawDetails);
      message = details?.message;
    } else {
      message = `Unknown server error (${statusText})`;
    }
  } catch (ignored) {
    message = `Unknown server error (${statusText})`;
  }
  let status = response!.status;
  throw new ServiceError(message, details, status, statusText);
  // throw new Error(`Error executing service call: ${response!.statusText} (${response!.status})`);
}

export function registerLoginHandler(handler: () => Promise<boolean>): void {
  if (loginHandler) {
    throw new Error("Login handler can only be registered once");
  }
  loginHandler = handler;
}

export async function executeLoginHandler(): Promise<boolean> {
  // If we have no login handler, we can't succeed
  if (!loginHandler) return false;

  // If there's a pending login request already on the books, wait for it; if not, start
  // up a new one
  if (pendingLoginRequest) {
    return await pendingLoginRequest;
  } else {
    pendingLoginRequest = loginHandler();
    let returnValue = await pendingLoginRequest;
    pendingLoginRequest = null;
    return returnValue;
  }
}

var pendingLoginRequest: Promise<boolean> | null;

var loginHandler: (() => Promise<boolean>) | null = null;

