import FDVue from "@fd/lib/vue";
import userAccess from "../dataMixins/userAccess";
import archivedDataList from "../dataMixins/archivedDataList";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { mapMutations } from "vuex";
import {
  ScaffoldDesignStatus,
  ScaffoldDesignWithDetails,
  scaffoldDesignService
} from "../services";
import { createNewScaffoldDesign } from "./components/dialogs/SP.ScaffoldDesignNewDialog.vue";

type ScaffoldDesignWithExtraDetails = ScaffoldDesignWithDetails & {
  managerName: String;
  assignedName: String;
  reviewerName: String;
};

export default FDVue.extend({
  name: "fd-scaffold-designs-list",

  // archivedDataList already uses the serviceErrorHandling
  mixins: [archivedDataList, userAccess],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      isReleasing: false,
      // Table Footer page size options
      itemsPerPage: 25,
      itemsPerPageOptions: [5, 10, 15, 25, 50, -1],

      allScaffoldDesigns: [] as ScaffoldDesignWithDetails[]
    };
  },

  computed: {
    scaffoldDesigns(): ScaffoldDesignWithExtraDetails[] {
      return this.allScaffoldDesigns.map(
        d =>
          ({
            ...d,
            managerName: this.$lookup.person(d.managerID),
            assignedName: this.$lookup.person(d.assignedPersonID),
            reviewerName: this.$lookup.person(d.reviewerID)
          } as ScaffoldDesignWithExtraDetails)
      );
    },
    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async loadData() {
      let allDesigns = await scaffoldDesignService.getAll();
      this.allScaffoldDesigns = allDesigns;
    },
    async openNewDialog() {
      if (await createNewScaffoldDesign()) {
        await this.loadData();
      }
    },
    userCanRelease(item: ScaffoldDesignWithExtraDetails): boolean {
      return !!item.currentUserPermissions?.canRelease;
    },
    itemCanBeReleased(item: ScaffoldDesignWithExtraDetails): boolean {
      return (
        item.scaffoldDesignStatusID == ScaffoldDesignStatus.Reviewed && this.userCanRelease(item)
      );
    },
    async releaseDesign(item: ScaffoldDesignWithExtraDetails) {
      if (!this.itemCanBeReleased(item)) {
        return;
      }

      this.processing = true;
      this.isReleasing = true;
      try {
        await scaffoldDesignService.releaseScaffoldDesign(item.id!);
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.isReleasing = false;
      }
    }
  },

  created: async function() {
    this.setFilteringContext({
      context: "scaffold-designs",
      parentalContext: null,
      searchStringForFiltering: "",
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: new Date(0),
      showArchivedForFilteringToDate: new Date()
    });

    this.notifyNewBreadcrumb({
      text: this.$t("scaffold-designs.list.title"),
      to: "/scaffolddesigns",
      resetHistory: true
    });

    this.processing = true;
    try {
      await this.loadData();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

