import FDVue from "@fd/lib/vue";
import { PropValidator } from "vue/types/options";
import { WorkOrderStatuses, WalkdownStatuses } from "../../../../services";
import * as DateUtil from "@fd/lib/client-util/datetime";

export interface WorkOrderAlertDetails {
  isUrgent: boolean;
  isUrgentDetail: string | null;
  lastUrgentDetailChangedDate: Date | null;
  lastUrgentDetailChangedBy: string | null; // TODO: Use lookup?
  workOrderStatus: WorkOrderStatuses;
  workOrderStatusDetail: string | null;
  approvalComments: string | null;
}
export interface WalkdownAlertDetails {
  walkdownStatus: WalkdownStatuses;
  approvalComments: string;
}

export default FDVue.extend({
  name: "fd-work-order-detail-alert",

  components: {
    "fd-work-order-detail-value": () => import("./fields/WorkOrderDetailValue.vue"),
    "fd-work-order-detail-value-gap": () => import("./fields/WorkOrderDetailValueGap.vue"),
    "fd-work-order-detail-date-value": () => import("./fields/WorkOrderDetailDateValue.vue"),
    "fd-work-order-detail-date-time-value": () =>
      import("./fields/WorkOrderDetailDateTimeValue.vue"),
    "fd-work-order-detail-enum-value": () => import("./fields/WorkOrderDetailEnumValue.vue"),
    "fd-work-order-detail-string-list-value": () =>
      import("./fields/WorkOrderDetailStringListValue.vue"),
    "fd-value-display": () => import("@fd/lib/vue/components/FP.ValueDisplay.vue")
  },

  props: {
    workOrder: { type: [Object], default: null } as PropValidator<WorkOrderAlertDetails>,
    walkdown: { type: [Object], default: null } as PropValidator<WalkdownAlertDetails>,
    cy: { type: String, default: "fd-work-order-detail-alert" }
  },

  data: () => ({
    WorkOrderStatuses,
    WalkdownStatuses
  }),

  computed: {
    formattedLastUrgentDetailChangeDate(): string | undefined {
      return !!this.workOrder.lastUrgentDetailChangedDate
        ? DateUtil.localizedDateTimeString(this.workOrder.lastUrgentDetailChangedDate)
        : undefined;
    },
    lastUrgentDetailChangedSummaryString(): string | undefined {
      // If the work order isn't urgent, or there is no log date, return nothing
      if (!this.workOrder.isUrgent || !this.formattedLastUrgentDetailChangeDate) return undefined;
      let summaryValues = [] as string[];

      if (!!this.workOrder.lastUrgentDetailChangedBy?.length) {
        var changeAction = this.$t("scheduler.urgent-detail-last-log-action-description", [
          this.workOrder.lastUrgentDetailChangedBy
        ]);
        summaryValues.push(`${changeAction}`);
      }

      if (!!this.formattedLastUrgentDetailChangeDate?.length)
        summaryValues.push(this.formattedLastUrgentDetailChangeDate);

      return summaryValues.join(" - ");
    }
  }
});

