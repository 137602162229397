import { workTypeService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: workTypeService,
  singularStoreName: "WORK_TYPE",
  pluralStoreName: "WORK_TYPES",
  localizationPrefix: "work-types",
  consumerRelatedIDProperty: {
    name: "workTypeID",
    type: "string"
  }
});

export default storeModule;

