var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-timeline-item",
    {
      staticClass: "fd-previous-message",
      attrs: {
        "data-id": _vm.message.id,
        large: _vm.$vuetify.breakpoint.smAndUp
      },
      scopedSlots: _vm._u([
        {
          key: "icon",
          fn: function() {
            return [
              _c(
                "v-avatar",
                {
                  class: _vm.message.author
                    ? "profile-avatar-background-color"
                    : "admin-avatar-background-color",
                  attrs: { size: _vm.$vuetify.breakpoint.smAndUp ? "48" : "34" }
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        "white--text",
                        "text-h5",
                        _vm.$vuetify.breakpoint.smAndUp
                          ? ""
                          : "fd-work-order-message-avatar-initials"
                      ]
                    },
                    [_vm._v(_vm._s(_vm.message.initials))]
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "elevation-2" },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-col",
                { staticClass: "pa-0 ma-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-0 ma-0 fd-previous-message-name-role" },
                    [
                      _vm._v("\n          " + _vm._s(_vm.message.name) + " | "),
                      _c("span", [_vm._v(_vm._s(_vm.message.role))])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "pa-0 ma-0 fd-previous-message-datetime" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.message.date) +
                          " - " +
                          _vm._s(_vm.message.time) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-text", { staticClass: "fd-previous-message-text" }, [
            _vm._v(_vm._s(_vm.message.text))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }