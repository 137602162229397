import type { DataSortFunction, DataTableCompareFunction } from "vuetify"

export interface StickySortFunction<Item> {
  (
    sortValuesA: any[],
    sortValuesB: any[],
    itemA: Item,
    itemB: Item,
    sortBy: string[],
    sortDesc: boolean[],
    customSorters?: Record<string, DataTableCompareFunction>
  ): number;
}

export function defaultStickySortFunction(
  sortValuesA: any[],
  sortValuesB: any[],
  itemA: any,
  itemB: any,
  sortBy: string[],
  sortDesc: boolean[],
  customSorters?: Record<string, DataTableCompareFunction>
) {
  for (let i = 0; i < sortValuesA.length; i++) {
    let valueA = sortValuesA[i];
    let valueB = sortValuesB[i];
    let customSorter = customSorters?.[sortBy[i]];
    if (customSorter) {
      return customSorter(valueA, valueB);
    } else {
      if (valueA < valueB) {
        return sortDesc[i] ? 1 : -1;
      } else if (valueA > valueB) {
        return sortDesc[i] ? -1 : 1;
      }
    }
  }
  return 0;
}

export function createStickyCustomSort<Item>(customSortFunction?: StickySortFunction<Item>): DataSortFunction<Item> {
  // We maintain "sticky sorting" (i.e. we don't change the sort order of existing items even if
  // sorted column values change) until the user specifies a new sort; if any of the sorting
  // criteria have changed since last sort we blow away our cached sort values; to do that we need
  // to keep a record of what the last observed sort order was
  let lastSortBy = [] as string[];
  let lastSortDesc = [] as boolean[];

  // For each previously observed item we keep a cache of the sort values we observed; this cache
  // is maintained until we change sort order
  let cachedSortedItems = new WeakMap<any, any[]>();

  // Whenever we check the sorted values of an object we store the last observed values until we
  // reset the cache on a sort change; our cached format is basically just an array with the values
  // in order of sort priority
  function getCachedSortedItem(item: any, sortBy: string[]) {
    let returnValue = cachedSortedItems.get(item);
    if (!returnValue) {
      returnValue = sortBy.map(key => item[key]);
      cachedSortedItems.set(item, returnValue);
    }
    return returnValue;
  }

  // This is the function we'll return as our sticky sort function
  return function stickyDataSortFunction(
    items: any[],
    sortBy: string[],
    sortDesc: boolean[],
    locale: string,
    customSorters?: Record<string, DataTableCompareFunction>
  ) {
    // Check for changes to the sort criteria; if any are observed we delete and recreate our
    // cache will effectively resort everything according to current values
    let sortChanged = sortBy.length != lastSortBy.length;
    if (!sortChanged) {
      for (let i = 0; i < lastSortBy.length; i++) {
        let sortByItem = lastSortBy[i];
        let sortDescItem = lastSortDesc[i];
        if (sortByItem != sortBy[i] || sortDescItem != sortDesc[i]) {
          sortChanged = true;
          break;
        }
      }
    }
    if (sortChanged) {
      cachedSortedItems = new WeakMap<any, any[]>();
      lastSortBy = sortBy.slice();
      lastSortDesc = sortDesc.slice();
    }

    // Sort the items, using the cached property value for any objects that we've already seen and
    // caching any values that we haven't
    if (!customSortFunction) {
      customSortFunction = defaultStickySortFunction;
    }
    items.sort((a, b) => {
      let cachedSortedItemA = getCachedSortedItem(a, sortBy);
      let cachedSortedItemB = getCachedSortedItem(b, sortBy);
      return customSortFunction!(cachedSortedItemA, cachedSortedItemB, a, b, sortBy, sortDesc, customSorters);
    });
    return items;
  }
}
