import { countSheetGroupService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: countSheetGroupService,
  singularStoreName: "COUNT_SHEET_GROUP",
  pluralStoreName: "COUNT_SHEET_GROUPS",
  localizationPrefix: "countsheetgroups",
  consumerRelatedIDProperty: {
    name: "countSheetGroupID",
    type: "string"
  }
});

export default storeModule;

