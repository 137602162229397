import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import rules from "@fd/lib/vue/rules";
import {
  personalEmailAddressService,
  PersonalEmailAddress
} from "@fd/current/client/services/index";

const PersonalEmailAddressNewDialog = FDVue.extend({
  name: "fd-user-email-address-new-dialog",
  components: {},
  mixins: [errorHandling, rules],
  data: () => ({
    personalEmailAddress: {} as PersonalEmailAddress
  }),

  props: {
    show: Boolean,
    personID: { default: undefined, type: String }
  },

  computed: {
    dialogRules() {
      return {
        emailAddress: [this.rules.required, this.rules.validEmail]
      };
    }
  },
  methods: {
    // *** GLOBAL ***
    initData() {
      this.personalEmailAddress = {
        emailAddress: "",
        allowNotifications: true,
        isPrimary: false
      } as PersonalEmailAddress;
    },
    onSubmit(e: Event) {
      this.saveDialog();
      e.preventDefault();
    },
    cancelDialog() {
      // this.closeDialog!(false);
      this.$emit("cancel");
    },
    async saveDialog() {
      if (!this.personID?.length) {
        console.log("No person ID supplied to PhoneNumberNewDialog");
        return;
      }
      if (!(this.$refs.detailsform as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      try {
        this.personalEmailAddress.personID = this.personID;
        this.personalEmailAddress.id = await personalEmailAddressService.addItem(
          this.personalEmailAddress
        );
        var snackbarPayload = {
          text: this.$t("user-email-addresses.snack-bar-add-message", [
            this.personalEmailAddress.emailAddress
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.$emit("emailAdded", this.personalEmailAddress);
        this.initData();
      } catch (error) {
        this.handleError(error as Error, "users.save-network-error");
      } finally {
        this.processing = false;
      }
    },
    processingUpdated(processing: boolean) {
      this.processing = processing;
    }
  },
  created: async function() {
    this.initData();
  }
});

export default PersonalEmailAddressNewDialog;

// export async function createNewPersonalEmailAddressDialog(): Promise<boolean> {
//   console.log("PersonalEmailAddressNewDialog.createNewPersonalEmailAddressDialog");
//   let dialog = createDialog(PersonalEmailAddressNewDialog);
//   return await dialog.showDialog!();
// }

