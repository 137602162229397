import FDVue from "../../../lib/vue";
import { mapMutations, mapActions } from "vuex";
import serviceErrorHandling from "../../../lib/vue/mixins/serviceErrorHandling";
import {
  Contractor,
  ContractorPurchaseOrder,
  contractorPurchaseOrderService,
  contractorService
} from "../services";
import rules from "../../../lib/vue/rules";
type FormattedContractorPurchaseOrder = ContractorPurchaseOrder & {
  archived: boolean;
  name: string;
};
export default FDVue.extend({
  name: "sp-contractor-purchase-order-existing",
  mixins: [serviceErrorHandling, rules],
  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      deleting: false,

      slidein: false,

      contractorID: "",
      purchaseOrder: {} as FormattedContractorPurchaseOrder,
      contractor: {} as Contractor
    };
  },
  computed: {},
  watch: {
    purchaseOrder(newValue) {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/contractors") {
        this.notifyNewBreadcrumb({
          text: this.$t("contractors.list-title"),
          to: "/contractors",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: this.contractor.name,
          to: `/contractors/${this.contractorID}`
        });
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      this.notifyNewBreadcrumb({
        text: newValue.name,
        to: `/contractors/${this.contractorID}/purchaseorders/${this.$route.params.id}`
      });
    }
  },
  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadPurchaseOrder: "LOAD_CONTRACTOR_PURCHASE_ORDER",
      updatePurchaseOrder: "UPDATE_CONTRACTOR_PURCHASE_ORDER",
      deletePurchaseOrder: "DELETE_CONTRACTOR_PURCHASE_ORDER"
    }),
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    cancel() {
      this.$router.push(`/contractors/${this.$route.params.contractorid}`);
    },
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      this.deleting = true;
      try {
        await this.deletePurchaseOrder({
          id: this.$route.params.id,
          name: this.purchaseOrder.name
        });
        this.$router.push(`/contractors/${this.$route.params.contractorid}`);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    },
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      this.processing = true;
      this.saving = true;
      try {
        if (!this.purchaseOrder.archived) {
          this.purchaseOrder.archivedDate = null;
        } else if (this.purchaseOrder.archived && !this.purchaseOrder.archivedDate) {
          this.purchaseOrder.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updatePurchaseOrder({
          ...this.purchaseOrder
        });

        if (closeOnComplete) {
          this.$router.push(`/contractors/${this.contractorID}`);
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    }
  },
  created: async function() {
    this.processing = true;

    this.contractorID = this.$route.params.contractorid;
    this.contractor = this.$store.state.contractors.fullList.find(
      (x: any) => x.id == this.$route.params.id
    );
    if (!this.contractor) {
      this.contractor = await contractorService.getByID(this.contractorID);
      contractorPurchaseOrderService.updatePurchaseOrderOrders;
    }

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "contractor-purchaseOrder-existing",
      parentalContext: "contractors-existing"
    });
    try {
      await Promise.all([this.loadPurchaseOrder(this.$route.params.id)]);

      let purchaseOrder = this.$store.state.contractorPurchaseOrders.fullList.find(
        (x: any) => x.id == this.$route.params.id
      );
      this.purchaseOrder = {
        ...purchaseOrder,
        name: purchaseOrder.purchaseOrderNumber,
        archived: !!purchaseOrder?.archivedDate
      };
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

