import { crewService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: crewService,
  singularStoreName: "CREW",
  pluralStoreName: "CREWS",
  localizationPrefix: "contractors.crews",
  // The following is the name of the ID property used by foreign objects.
  consumerRelatedIDProperty: {
    name: "crewID",
    type: "string"
  }
});

export default storeModule;

