var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "1024px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-card",
            { attrs: { "data-cy": "workorderfastforwarddialog" } },
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [_vm._v(_vm._s(_vm.$t("workorders.fast-forward.title")))]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass:
                    "d-flex align-center justify-end ml-0 mr-0 fd-primary-context-qualifier-background py-2 px-3"
                },
                [
                  _c("div", { staticStyle: { "max-width": "70%" } }, [
                    _vm._v(
                      "\n          Choose a date to be the new required date for the selected work orders.\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("fd-date-picker", {
                    attrs: {
                      label: _vm.$t("common.date"),
                      min: new Date(),
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.date,
                      callback: function($$v) {
                        _vm.date = $$v
                      },
                      expression: "date"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                { staticClass: "pa-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-card-subtitle",
                    { staticClass: "pb-sm-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-0 pb-0", attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                class: [
                                  _vm.$vuetify.breakpoint.sm
                                    ? "fd-restrict-clearable-position"
                                    : "",
                                  "fd-table-search-input"
                                ],
                                attrs: {
                                  "data-cy": "tablesearch",
                                  "append-icon": "search",
                                  label: _vm.$t("common.search"),
                                  "single-line": "",
                                  "hide-details": "",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.tablesearch,
                                  callback: function($$v) {
                                    _vm.tablesearch = $$v
                                  },
                                  expression: "tablesearch"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    directives: [
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:id.hidden",
                        value: "",
                        expression: "''",
                        arg: "id",
                        modifiers: { hidden: true }
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:internalNumber",
                        value: _vm.$vuetify.breakpoint.lgAndUp
                          ? _vm.$t("scheduler.wo-number-column-header")
                          : _vm.$t("scheduler.wo-number-column-header-short"),
                        expression:
                          "\n            $vuetify.breakpoint.lgAndUp\n              ? $t('scheduler.wo-number-column-header')\n              : $t('scheduler.wo-number-column-header-short')\n          ",
                        arg: "internalNumber"
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:requestType",
                        value: _vm.$vuetify.breakpoint.lgAndUp
                          ? _vm.$t("scheduler.request-type-column-header")
                          : _vm.$t(
                              "scheduler.request-type-column-header-short"
                            ),
                        expression:
                          "\n            $vuetify.breakpoint.lgAndUp\n              ? $t('scheduler.request-type-column-header')\n              : $t('scheduler.request-type-column-header-short')\n          ",
                        arg: "requestType"
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:workOrderStatus",
                        value: _vm.$t("scheduler.status"),
                        expression: "$t('scheduler.status')",
                        arg: "workOrderStatus"
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:scaffoldNumber",
                        value: _vm.$vuetify.breakpoint.lgAndUp
                          ? _vm.$t("scheduler.scaffold-number")
                          : _vm.$t("scheduler.scaffold-number-short"),
                        expression:
                          "\n            $vuetify.breakpoint.lgAndUp\n              ? $t('scheduler.scaffold-number')\n              : $t('scheduler.scaffold-number-short')\n          ",
                        arg: "scaffoldNumber"
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:requiredDate",
                        value: _vm.$vuetify.breakpoint.lgAndUp
                          ? _vm.$t("scheduler.required-date-column-header")
                          : _vm.$t(
                              "scheduler.required-date-column-header-short"
                            ),
                        expression:
                          "\n            $vuetify.breakpoint.lgAndUp\n              ? $t('scheduler.required-date-column-header')\n              : $t('scheduler.required-date-column-header-short')\n          ",
                        arg: "requiredDate"
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:progress",
                        value: _vm.$t("scheduler.progress-short"),
                        expression: "$t('scheduler.progress-short')",
                        arg: "progress"
                      },
                      {
                        name: "fd-column",
                        rawName:
                          "v-fd-column:include.class_fd-include-cell.no-sort",
                        arg: "include",
                        modifiers: {
                          "class_fd-include-cell": true,
                          "no-sort": true
                        }
                      }
                    ],
                    ref: "datatable",
                    attrs: {
                      search: _vm.tablesearch,
                      items: _vm.workOrders,
                      "sort-by": ["internalNumber"],
                      loading: _vm.processing,
                      "loading-text": _vm.$t("common.table-loading-message"),
                      "mobile-breakpoint": "0"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.requestType",
                        fn: function({ item }) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    `scaffold-requests.types.${item.requestType}`
                                  )
                                ) +
                                "\n          "
                            )
                          ]
                        }
                      },
                      {
                        key: "item.workOrderStatus",
                        fn: function({ item }) {
                          return [
                            _c("fd-value-display", {
                              attrs: {
                                value: _vm.$t(
                                  `workorders.status.${item.workOrderStatus}`
                                )
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "item.scaffoldNumber",
                        fn: function({ item }) {
                          return [
                            _c("fd-value-display", {
                              attrs: { value: item.scaffoldNumber }
                            })
                          ]
                        }
                      },
                      {
                        key: "item.requiredDate",
                        fn: function({ item }) {
                          return [
                            _c("fd-value-display", {
                              attrs: {
                                value: _vm.$format.date(item.requiredDate)
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "header.include",
                        fn: function({ header }) {
                          return [
                            _c("v-checkbox", {
                              ref: "headerinclude",
                              attrs: {
                                value: _vm.allSearchedItemsSelected,
                                indeterminate: _vm.someSearchedItemsSelected,
                                label: _vm.$t("common." + header.value)
                              },
                              on: {
                                change: function($event) {
                                  return _vm.flipSearchedItemsSelected()
                                }
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "item.include",
                        fn: function({ item }) {
                          return [
                            _c("v-simple-checkbox", {
                              attrs: {
                                value: _vm.itemIsSelected(item),
                                disabled: _vm.processing
                              },
                              on: {
                                input: function($event) {
                                  return _vm.flipItemSelected(item)
                                }
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.inlineMessage.message
                ? _c(
                    "v-row",
                    { staticClass: "mx-2", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            { attrs: { type: _vm.inlineMessage.type } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "white-space": "pre-line" } },
                                [_vm._v(_vm._s(_vm.inlineMessage.message))]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        disabled: _vm.processing,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.cancelDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "accept",
                        color: "primary",
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.acceptDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("workorders.fast-forward.move-button")
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }