var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _vm.processing
            ? _c(
                "v-col",
                { staticClass: "px-0 pb-0", attrs: { cols: "12" } },
                [_c("v-progress-linear", { attrs: { indeterminate: "" } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-btn", { on: { click: _vm.showDialog } }, [
                _vm._v("Open Dialog")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }