var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-btn",
    {
      staticClass: "add-fab-button-primary",
      attrs: {
        "data-cy": _vm.cy,
        absolute: _vm.absolute,
        bottom: _vm.bottom,
        left: _vm.left,
        fab: "",
        elevation: "5",
        depressed: _vm.disabled,
        disabled: _vm.disabled,
        color: "new"
      },
      on: { click: _vm.clicked }
    },
    [_c("v-icon", [_vm._v("mdi-plus")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }