import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";
import { PartWithTags } from "../../../services";
import { buildBCount } from "../../../utils/bcount";

type DialogReturnType = string | undefined;
type PartWithQuantity = PartWithTags & { quantity: number };
const BCountPartsListBottomDialog = FDVue.extend({
  name: "",
  mixins: [dialogSupport],
  directives: {
    fdColumn: FDColumnDirective
  },
  data: function() {
    return {
      parts: [] as PartWithQuantity[]
    };
  },
  computed: {
    unwatchedMethodNames(): string[] {
      return [
        "getFieldRef",
        "focusFieldForVisibleItemAtIndex",
        "selectPreviousField",
        "selectNextField",
        "enterPressed"
      ];
    },
    bCount(): string | undefined {
      let parts = this.parts?.filter(x => this.$parse.sanitizedNumber(x.quantity) > 0);
      if (!parts?.length) return undefined;
      return buildBCount(parts);
    }
  },
  methods: {
    async open(parts: PartWithTags[], mountPoint?: Element | undefined): Promise<DialogReturnType> {
      this.parts = parts.map(x => ({
        ...x,
        quantity: 0
      }));
      this.optOutOfErrorHandling();
      return await this.showDialog!(mountPoint);
    },
    cancelDialog() {
      this.closeDialog(undefined);
    },
    saveDialog() {
      return this.closeDialog(this.bCount);
    },
    // *** TEXT FIELD NAVIGATION ***
    getFieldRef(fieldName: string, item: PartWithQuantity) {
      let id = item.id!.replace("-", "").replace("-", "");
      return `${fieldName}_${id}`;
    },
    focusFieldForVisibleItemAtIndex(fieldName: string, index: number) {
      if (!this.parts.length) return;

      if (index < 0) index = 0;
      if (index >= this.parts.length) index = this.parts.length - 1;
      let item = this.parts[index];

      let itemFieldRef = this.getFieldRef(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    async selectPreviousField(fieldName: string, item: PartWithQuantity) {
      let currentItemIndex = this.parts.indexOf(item);
      if (currentItemIndex <= 0) {
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, previousIndex);
    },
    async selectNextField(fieldName: string, item: PartWithQuantity) {
      let currentItemIndex = this.parts.indexOf(item);
      if (currentItemIndex >= this.parts.length - 1) {
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, nextIndex);
    },
    async enterPressed(e: KeyboardEvent, fieldName: string, item: PartWithQuantity) {
      if (e.shiftKey) await this.selectPreviousField(fieldName, item);
      else await this.selectNextField(fieldName, item);
    }
  }
});
export default BCountPartsListBottomDialog;
export async function openBCountPartsListBottomDialog(
  parts: PartWithTags[],
  parent?: Vue | Element | null | undefined
): Promise<DialogReturnType> {
  let dialog = createDialog(BCountPartsListBottomDialog);
  dialog.optOutOfErrorHandling();
  let mountPoint = undefined;
  if (!!parent) {
    mountPoint = document.createElement("div");
    mountPoint.id = "mountPoint";
    if (!!(parent as Vue)) (parent as Vue).$el.appendChild(mountPoint);
    else if (!!(parent as Element)) (parent as Element).appendChild(mountPoint);
  }
  return await dialog.open(parts, mountPoint);
}

