import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { System } from "../../../services";

type SystemNewDialogResult = boolean;
const SystemNewDialog = FDVue.extend({
  name: "sp-system-new-dialog",

  mixins: [dialogSupport, rules],

  data: function() {
    return {
      saving: false,
      system: {
        name: "",
        description: "",
        code: ""
      } as System
    };
  },

  computed: {
    systemRules() {
      return {
        name: [this.rules.required],
        code: [],
        description: []
      };
    }
  },

  methods: {
    async open() {
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.saving = true;
      this.processing = true;
      try {
        await this.addSystem({
          ...this.system
        });
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    ...mapActions({
      addSystem: "ADD_SYSTEM"
    })
  }
});

export default SystemNewDialog;

export async function showSystemNewDialog(): Promise<SystemNewDialogResult> {
  let dialog = createDialog(SystemNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open();
}

