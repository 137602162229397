import { scaffoldBayLengthService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: scaffoldBayLengthService,
  singularStoreName: "SCAFFOLD_BAY_LENGTH",
  pluralStoreName: "SCAFFOLD_BAY_LENGTHS",
  localizationPrefix: "scaffold-bays.bay-lengths",
  consumerRelatedIDProperty: {
    name: "scaffoldBayLengthID",
    type: "string"
  }
});

export default storeModule;

