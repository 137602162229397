var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-badge", {
    staticClass: "fd-progress-badge",
    class: _vm.$attrs.class,
    attrs: {
      "data-cy": !!_vm.cy ? _vm.cy : _vm.dataCy,
      inline: "",
      bordered: _vm.bordered,
      dot: _vm.dot,
      dense: _vm.dense,
      icon: _vm.icon,
      color: _vm.color
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }