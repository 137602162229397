var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("fd-work-order-detail-value", {
    attrs: {
      label: _vm.label,
      value: _vm.formattedValue,
      valueStyle: _vm.valueStyle,
      valueClass: _vm.valueClass,
      loading: _vm.loading,
      labelCols: _vm.labelCols,
      labelSmCols: _vm.labelSmCols,
      valueCols: _vm.valueCols,
      valueSmCols: _vm.valueSmCols,
      hideNoValuePlaceholder: _vm.hideNoValuePlaceholder,
      noValueKey: _vm.noValueKey,
      noValueStyle: _vm.noValueStyle,
      noValueClass: _vm.noValueClass
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }