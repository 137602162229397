import FDVue from "@fd/lib/vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { mapActions } from "vuex";
import { Part, PartWithTags } from "../../../services";

type PartWithSelected = PartWithTags & { selected: boolean };

export default FDVue.extend({
  name: "fd-count-sheet-group-parts-selection-form",

  props: {
    value: { type: Array, default: undefined },
    countSheetGroupID: { type: String, default: undefined },
    loading: { type: Boolean, default: false }
  },

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      allowTakingOtherGroupParts: false,
      showOnlyIncludedParts: false,
      partsTableSearch: "",
      selectableParts: [] as Array<PartWithSelected>
    };
  },
  computed: {
    displayedParts(): Array<PartWithSelected> {
      let displayedParts = this.selectableParts;
      if (this.showOnlyIncludedParts) displayedParts = displayedParts.filter(x => x.selected);
      return displayedParts;
    },
    selectedParts(): Array<PartWithSelected> {
      return this.selectableParts.filter(x => !!x.selected);
    }
  },
  methods: {
    ...mapActions({
      loadParts: "LOAD_PARTS"
    }),

    // *** PARTS ***
    flipPartSelected(item: PartWithSelected) {
      item.selected = !item.selected;
      if (item.selected) this.$emit("item:added", item);
      else this.$emit("item:removed", item);
      this.$emit("input", this.selectedParts);
    },

    itemCanBeSelected(item: PartWithSelected) {
      return (
        item.selected ||
        !item.countSheetGroupID ||
        (!!item.countSheetGroupID &&
          !!this.countSheetGroupID &&
          item.countSheetGroupID == this.countSheetGroupID)
      );
    }
  },
  mounted: async function() {
    await Promise.all([this.loadParts()]);
    let partIDs: string[] = (this.value as Part[] | undefined)?.map(x => x.id!) ?? [];
    this.selectableParts = this.$store.state.parts.fullList.map((x: PartWithTags) => ({
      ...x,
      selected:
        partIDs.includes(x.id!) ||
        (!!x.countSheetGroupID?.length && x.countSheetGroupID == this.countSheetGroupID)
    }));
    if (partIDs.length != this.selectedParts.length) {
      this.$emit("input", this.selectedParts);
    }
  }
});

