import FDVue from "../index";
import twoWayProps from "../mixins/twoWayProps";

export default FDVue.extend({
  name: "fd-search-box",
  mixins: [twoWayProps],

  props: {
    refreshItems: Function,
    cy: { type: String, default: "fd-search-box" },
    items: Array,
    selectedItems: {
      default: function() {
        return [];
      },
      type: [Object, Array]
    },
    label: String,
    hint: { default: "", type: String },
    placeholder: { default: "", type: String },
    itemText: { default: "text", type: String },
    itemDetail: { default: undefined, type: String },
    clearable: { default: true, type: Boolean },
    outlined: { default: true, type: Boolean },
    dense: { default: false, type: Boolean },
    multiple: { default: true, type: Boolean },
    disabled: { default: false, type: Boolean },
    cacheItems: { default: false, type: Boolean },
    useChips: { default: false, type: Boolean },
    chipClass: { default: "", type: String },
    rules: {
      default: function() {
        return [];
      },
      type: [Object, Array]
    }
  },

  twoWayProps: ["selectedItems"],

  data: () => ({
    search: "",
    loading: false,
    hasLoaded: false,
    timer: null as NodeJS.Timeout | null
  }),

  computed: {
    computedClass(): string | Array<string | Object> | undefined {
      let classes: string[] = [];
      if (this.$attrs.class != undefined) {
        if (typeof this.$attrs.class === "string") {
          classes = (this.$attrs.class as string)?.split(" ") ?? [];
        } else {
          classes = (this.$attrs.class as string[]) ?? [];
        }
      }

      let isReadonly = !!this.$attrs.readonly && this.$attrs.readonly != "false";
      if (isReadonly) {
        classes.push(`fd-readonly`);
      }

      return classes;
    }
  },

  methods: {
    removeItem(item: any) {
      if (!!this.twoWayProps.selectedItems.length) {
        const index = this.twoWayProps.selectedItems.indexOf(item);
        if (index >= 0) this.twoWayProps.selectedItems.splice(index, 1);
      } else {
        this.twoWayProps.selectedItems = null;
      }
    }
  },

  watch: {
    search(this: any, newValue: string) {
      this.hasLoaded = false;
      // If the user kept typing/changed the search before the service call, cancel the previous call in preparation of the new one
      if (this.timer) clearTimeout(this.timer);

      if (!newValue?.length) {
        this.searchItems = [];
        return;
      }

      var obj = this;
      // Delay the service call to allow the user to keep typing if they choose before making a server call
      this.timer = setTimeout(async function() {
        obj.loading = true;
        try {
          if (!!obj.refreshItems) await obj.refreshItems(newValue);
          else obj.$emit("refreshItems", newValue);
        } finally {
          obj.timer = null;
          obj.loading = false;
          obj.hasLoaded = true;
        }
      }, 1500);
    }
  }
});

