import FDVue from "@fd/lib/vue";

export default FDVue.extend({
  props: {
    note: { default: undefined, type: Object }
  },
  watch: {
    note: function(newValue, oldValue) {
      if (oldValue == newValue) return;
      if (!!this.note && this.note.isNew) {
        // Set the note's isNew to false to prevent it animating again if another new note is added later
        this.note.isNew = false;

        let wrapper = this.$refs.wrapper as HTMLDivElement;
        let normalAvatar = (this.$refs.normalAvatar as any).$el;
        wrapper.classList.add("fd-new-note-default");
        normalAvatar.classList.add("fd-new-note-default");
        this.$nextTick(() => {
          wrapper.classList.add("fd-new-note-appear-nicely");
          normalAvatar.classList.add("fd-new-note-appear-nicely");
          setInterval(() => {
            wrapper.classList.remove("fd-new-note-default");
            wrapper.classList.remove("fd-new-note-appear-nicely");
            normalAvatar.classList.remove("fd-new-note-default");
            normalAvatar.classList.remove("fd-new-note-appear-nicely");
          }, 1000);
        });
      }
    }
  }
});
