import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { TranslateResult } from "vue-i18n";
import {
  lemsService,
  LEMWithDetails,
  Timesheet,
  timesheetService,
  TimesheetWithDetails
} from "../../../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";

type FormattedTimesheetWithDetails = TimesheetWithDetails & {
  timesheetStatus: string | TranslateResult;
  formattedDay: string;
  formattedTotalRegularTime: string;
  formattedTotalOverTime: string;
  formattedTotalDoubleTime: string;
  formattedTotalUnits: string;
};

const LemTimesheetSearchDialog = FDVue.extend({
  name: "fd-lem-timesheet-search-dialog",
  mixins: [dialogSupport, rules],
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      adding: false,
      lem: undefined as LEMWithDetails | undefined,
      allTimesheets: [] as FormattedTimesheetWithDetails[],
      selectedTimesheetIDs: [] as string[]
    };
  },
  computed: {
    unwatchedMethodNames() {
      return ["isTimesheetSelected", "flipTimesheetSelected", "addSelectedTimesheets"];
    },
    formattedDay(): string {
      return DateUtil.stripTimeFromLocalizedDateTime(this.lem?.day);
    },
    selectableTimesheets(): FormattedTimesheetWithDetails[] {
      return this.allTimesheets.filter(x => !x.lemID?.length);
    },
    /// Used for "Include" header checkbox to determine "checked" state
    allTimesheetsSelected(): boolean {
      return (
        this.selectableTimesheets.findIndex(x => !this.selectedTimesheetIDs.includes(x.id!)) === -1
      );
    },
    someTimesheetsSelected(): boolean {
      if (this.allTimesheetsSelected) return false;
      return this.selectedTimesheetIDs.length > 0;
    }
  },
  methods: {
    cancelDialog() {
      this.closeDialog(false);
    },
    async loadData() {
      if (!this.lem) return;

      this.optOutOfErrorHandling();
      this.processing = true;
      try {
        this.allTimesheets = (
          await timesheetService.getApprovedTimesheetsForLemWithID(this.lem.id!)
        ).map(
          x =>
            ({
              ...x,
              timesheetNumberString: `00000${x.timesheetNumber}`.slice(-5),
              timesheetStatus: this.$t(`timesheets.status.${x.timesheetStatusID}`),
              formattedDay: DateUtil.stripTimeFromLocalizedDateTime(x.day),
              formattedTotalRegularTime:
                !!x.totalRegularTime && x.totalRegularTime > 0
                  ? x.totalRegularTime.toFixed(2)
                  : undefined,
              formattedTotalOverTime:
                !!x.totalOverTime && x.totalOverTime > 0 ? x.totalOverTime.toFixed(2) : undefined,
              formattedTotalDoubleTime:
                !!x.totalDoubleTime && x.totalDoubleTime > 0
                  ? x.totalDoubleTime.toFixed(2)
                  : undefined,
              formattedTotalUnits:
                !!x.totalUnits && x.totalUnits > 0 ? x.totalUnits.toFixed(2) : undefined
            } as FormattedTimesheetWithDetails)
        );
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async open(lem: LEMWithDetails): Promise<boolean> {
      this.lem = lem;
      this.loadData();
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    // *** Timesheet Selection ***
    isTimesheetSelected(item: Timesheet) {
      return this.selectedTimesheetIDs.includes(item.id!);
    },
    flipTimesheetSelected(item: Timesheet) {
      let selected = this.isTimesheetSelected(item);
      if (selected) {
        this.selectedTimesheetIDs.splice(this.selectedTimesheetIDs.indexOf(item.id!), 1);
      } else {
        this.selectedTimesheetIDs.push(item.id!);
      }
    },
    flipAllTimesheetsSelected() {
      let selected = this.allTimesheetsSelected;
      if (selected) {
        // everything is selected, clear selection
        this.selectedTimesheetIDs = [];
      } else {
        // not everything is selected, select everything
        this.selectedTimesheetIDs = this.selectableTimesheets.map(x => x.id!);
      }
    },
    async addSelectedTimesheets() {
      if (!this.lem || !this.selectedTimesheetIDs.length) return;

      this.optOutOfErrorHandling();
      this.inlineMessage.message = "";
      this.adding = true;
      this.processing = true;
      try {
        await lemsService.importTimesheetDetailsIntoLEM(this.lem.id!, this.selectedTimesheetIDs);
        this.closeDialog(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.adding = false;
      }
    }
  }
});
export default LemTimesheetSearchDialog;

export async function openLemTimesheetSearchDialog(lem: LEMWithDetails): Promise<boolean> {
  let dialog = createDialog(LemTimesheetSearchDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(lem);
}

