var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "nudge-right": 40,
        transition: "scale-transition",
        "offset-y": "",
        "min-width": "290px"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function({ on }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "fd-time-picker-text-field",
                      attrs: {
                        "data-cy": !!_vm.cy ? _vm.cy : _vm.dataCy,
                        label: _vm.$props.label,
                        value: _vm.formattedTime,
                        rules: _vm.$props.rules,
                        disabled: _vm.$props.disabled,
                        "prepend-inner-icon": "alarm",
                        readonly: "",
                        outlined: _vm.outlined,
                        clearable: _vm.clearable
                      }
                    },
                    "v-text-field",
                    _vm.$attrs,
                    false
                  ),
                  on
                )
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menuOpen,
        callback: function($$v) {
          _vm.menuOpen = $$v
        },
        expression: "menuOpen"
      }
    },
    [
      _vm._v(" "),
      _c("v-time-picker", {
        attrs: { disabled: _vm.$props.disabled, locale: _vm.i18nLocale },
        model: {
          value: _vm.valueString,
          callback: function($$v) {
            _vm.valueString = $$v
          },
          expression: "valueString"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }