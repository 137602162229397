import FDVue from "@fd/lib/vue";
import { mapState } from "vuex";
import { MessageWithSenderDetails } from "../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import i18n from "../i18n";
import VueI18n from "vue-i18n";

export type MessageWithAdditionalDetails = MessageWithSenderDetails & {
  author?: boolean;
  initials: string;
  name: string;
  role: string;
  date: string;
  time: string;
};

export function ParseMessageWithSenderDetails(
  message: MessageWithSenderDetails,
  currentUserID: string | null | undefined
): MessageWithAdditionalDetails {
  var isAuthor = !currentUserID ? false : currentUserID == message.personID;

  var names = [] as string[];
  var initials = [] as string[];
  if (!!message.sender?.firstName) {
    names.push(message.sender.firstName);
    initials.push(message.sender.firstName.toUpperCase()[0]);
  }
  if (!!message.sender?.lastName) {
    names.push(message.sender.lastName);
    initials.push(message.sender.lastName.toUpperCase()[0]);
  }
  var senderInitials = initials.join("");
  var senderName = names.join(" ");
  var formattedSentDate = DateUtil.stripTimeFromLocalizedDateTime(message.sentTime);
  var formattedSentTime = DateUtil.stripDateFromLocalizedDateTime(message.sentTime);
  var role = "" as string | VueI18n.TranslateResult;
  if (message.sender?.isPlanner) {
    role = i18n.t("people.security.roles.planner");
  } else if (message.sender?.isCoordinator) {
    role = i18n.t("people.security.roles.coordinator");
  } else if (message.sender?.isGeneralForeman) {
    role = i18n.t("people.security.roles.general-foreman");
  } else if (message.sender?.isForeman) {
    role = i18n.t("people.security.roles.foreman");
  }

  return {
    ...message,
    author: isAuthor,
    initials: senderInitials,
    name: senderName,
    role: role,
    date: formattedSentDate,
    time: formattedSentTime
  } as MessageWithAdditionalDetails;
}

function compareMessages(a: MessageWithSenderDetails, b: MessageWithSenderDetails): number {
  // being DTOs instead of DateTimes has these returned as a string instead of a date object
  return new Date(b.sentTime!).getTime() - new Date(a.sentTime!).getTime();
}

export function SortMessagesArray<T extends MessageWithSenderDetails>(messages: T[]): T[] {
  return messages.sort(compareMessages);
}

export default FDVue.extend({
  components: {
    "fd-history-message-timeline-item": () =>
      import("../views/components/HistoryMessageTimelineItem.vue")
  },
  data: function() {
    return {
      messages: [] as MessageWithAdditionalDetails[],
      newMessageText: ""
    };
  },
  computed: {
    ...mapState(["avatarInitials"])
  }
});

