import { mapMutations, mapActions, mapState } from "vuex";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import rules from "@fd/lib/vue/rules";
import { HoardingModifier } from "@fd/current/client/services";

type HoardingModifierWithDetails = HoardingModifier & { archived: boolean };

export default FDVue.extend({
  mixins: [errorHandling, rules],

  name: "fd-hoarding-modifier-existing",

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,

      slidein: false,

      hoardingModifier: {} as HoardingModifierWithDetails
    };
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.hoardingModifier.archived) {
          this.hoardingModifier.archivedDate = null;
        } else if (this.hoardingModifier.archived && !this.hoardingModifier.archivedDate) {
          this.hoardingModifier.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateHoardingModifier({
          ...this.hoardingModifier,
          modifier: !!this.hoardingModifier.modifier ? +this.hoardingModifier.modifier : undefined,
          id: this.$route.params.id
        });

        if (closeOnComplete) {
          this.$router.push("/estimatesetup");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteHoardingModifier({
          id: this.$route.params.id,
          name: this.hoardingModifier.name
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.$router.push("/estimatesetup");
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push("/estimatesetup");
    },
    ...mapMutations({
      setHoardingModifier: "SET_HOARDING_MODIFIER",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadHoardingModifier: "LOAD_HOARDING_MODIFIER",
      updateHoardingModifier: "UPDATE_HOARDING_MODIFIER",
      deleteHoardingModifier: "DELETE_HOARDING_MODIFIER"
    })
  },

  watch: {
    hoardingModifier(newValue) {
      console.log(`watch.hoardingModifier`);
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/estimatesetup") {
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        this.notifyNewBreadcrumb({
          text: this.$t("estimate-setup.title"),
          to: "/estimatesetup",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      this.notifyNewBreadcrumb({
        text: this.hoardingModifier.name,
        to: `/estimatesetup/hoardingmodifiers/${this.$route.params.id}`,
        history: [
          {
            text: this.$t("modifiers.hoarding.title"),
            disabled: true
          }
        ]
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/estimatesetup") {
      this.notifyNewBreadcrumb({
        text: this.$t("estimate-setup.title"),
        to: "/estimatesetup",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }

    this.notifyNewBreadcrumb({
      text: this.$t("loading-dot-dot-dot"),
      disabled: true,
      history: [
        {
          text: this.$t("modifiers.hoarding.title"),
          disabled: true
        }
      ]
    });

    this.processing = true;
    try {
      await this.loadHoardingModifier(this.$route.params.id);
      var hoardingModifier = this.$store.state.hoardingModifiers.fullList.find(
        (x: HoardingModifier) => x.id == this.$route.params.id
      );
      this.hoardingModifier = {
        ...hoardingModifier,
        archived: !!hoardingModifier.archivedDate
      } as HoardingModifierWithDetails;
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

