import FDVue from "../index";
import { mapState, mapMutations } from "vuex";

export default FDVue.extend({
  name: "fd-inline-edit-dialog",

  // TODO: Implement show property as mixin? Figure this out as we explore other optimizations
  props: {
    value: null as any | null, // TODO: Is this the correct type?
    rules: Array, // TODO: Is this the correct type? Is it even in use?
    label: String,
    numeric: Boolean,
    widthRatio: Number
  },

  data: () => ({
    // dialogVisible: false,
    innerDialogVisible: false,
    interimValue: "" as any | null,
    dialogStyle: {
      left: undefined as number | undefined,
      top: undefined as number | undefined,
      right: undefined as number | undefined,
      bottom: undefined as number | undefined,
      width: undefined as number | undefined,
      position: "absolute"
    }
  }),

  computed: {
    displayValue(): any {
      if (this.value === "" || this.value === undefined || this.value === null) {
        return "\u00A0";
      } else {
        return this.value;
      }
    },

    dialogVisible: {
      get(this: any) {
        return this.innerDialogVisible;
      },
      set(this: any, val) {
        this.innerDialogVisible = val;
        if (val === true) {
          this.setInlineDialogRef(this);
        }
      }
    }
  },

  watch: {
    value(newValue) {
      this.interimValue = newValue;
    }
  },

  methods: {
    onValueClicked() {
      this.dialogVisible = true;
      this.interimValue = this.value;
      var targetRect = (this.$refs.valueDiv as Element).getBoundingClientRect();
      var centerScreenX = window.innerWidth / 2;
      var centerScreenY = window.innerHeight / 2;

      // Style right is distance from the right hand side, but Rect class right is
      // distance from the left hand side
      var targetX = (targetRect.left + targetRect.right) / 2;
      var targetY = (targetRect.top + targetRect.bottom) / 2;
      if (targetX < centerScreenX) {
        this.dialogStyle.left = targetRect.left;
        this.dialogStyle.right = undefined;
      } else {
        this.dialogStyle.right = window.innerWidth - targetRect.right;
        this.dialogStyle.left = undefined;
      }
      if (targetY < centerScreenY) {
        this.dialogStyle.top = targetRect.top;
        this.dialogStyle.bottom = undefined;
      } else {
        this.dialogStyle.bottom = window.innerHeight - targetRect.bottom;
        this.dialogStyle.top = undefined;
      }

      if (this.$props.widthRatio != undefined) {
        this.dialogStyle.width = targetRect.width * this.$props.widthRatio;
      }
      // The following was meant to select all the text in the input control but since the vuetify textfield
      // does NOT have this as an available method it does not work. We need to find some other workaround.
      // setTimeout(() => this.$refs.textbox.select());
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.applyUpdates();
    },

    closeDialog() {
      this.dialogVisible = false;
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.interimValue = this.value;
      this.closeDialog();
    },

    applyUpdates() {
      if (!(this.$refs.form as any).validate()) {
        return;
      }
      if (this.interimValue == "") {
        this.interimValue = null;
      } else if (this.numeric && typeof this.interimValue === "string") {
        this.interimValue = parseFloat(this.interimValue);
      }
      this.$emit("update:value", this.interimValue);
      this.closeDialog();
    },

    ...mapMutations({
      setInlineDialogRef: "SET_INLINE_DIALOG_REF"
    })
  }
});
