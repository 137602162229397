import { mapMutations } from "vuex";
import FDVue from "../../../../../lib/vue";
import { DateRangePreset } from "../../../../../lib/vue/components/DateRangePicker.vue";
import dialogSupport, { createDialog } from "../../../../../lib/vue/mixins/dialogSupport";
import errorHandling from "../../../../../lib/vue/mixins/errorHandling";
import rules from "../../../../../lib/vue/rules";
import { FDColumnDirective } from "../../../../../lib/vue/utility/dataTable";
import {
  ScaffoldInspectionTimeRange,
  ScaffoldInspectionWithDetails,
  ScaffoldInspectionWithHistoryData,
  reportService,
  scaffoldInspectionService
} from "../../../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { VDataTable } from "../../../../../lib/vue/types";
import downloadBlob from "../../../../../lib/client-util/downloadBlob";
import printBlob from "../../../../../lib/client-util/printBlob";
import { TranslateResult } from "vue-i18n";

type ScaffoldInspectionWithExtraDetails = ScaffoldInspectionWithDetails & {
  inspectionDay: string;
  timeSegmentName: string | TranslateResult;
};
const ScaffoldInspectionHistoryDialog = FDVue.extend({
  name: "sp-scaffold-inspection-history-dialog",

  mixins: [dialogSupport, errorHandling, rules],
  directives: {
    fdColumn: FDColumnDirective
  },

  data: function() {
    return {
      scaffoldID: "",
      scaffoldNumber: null as number | null | undefined,
      // *** INSPECTIONS ***
      minDate: undefined as Date | undefined,
      maxDate: undefined as Date | undefined,
      inspections: [] as ScaffoldInspectionWithExtraDetails[],
      inspectionstablesearch: ""
    };
  },
  computed: {
    unwatchedMethodNames(): string[] {
      return ["inspectionCreatedAtDifferentTime", "allGroupsExpanded", "toggleTableGroups"];
    },
    // Archived Props for Approved Count Sheets
    dateRangePresetOptions(): DateRangePreset[] {
      return [
        {
          fromDate: DateUtil.addDaysToDate(null, 0),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "today",
          labelKey: "fd-date-range-picker.preset-today-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -6),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-week",
          labelKey: "fd-date-range-picker.preset-previous-week-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -13),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-weeks",
          labelKey: "fd-date-range-picker.preset-previous-two-weeks-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addMonthsToDate(null, -1),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-month",
          labelKey: "fd-date-range-picker.preset-previous-month-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addMonthsToDate(null, -2),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-months",
          labelKey: "fd-date-range-picker.preset-previous-two-months-label"
        } as DateRangePreset
      ];
    },
    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = DateUtil.addMonthsToDate(date, -2);
      return minDate;
    },
    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = DateUtil.addMonthsToDate(date, 2);
      return maxDate;
    },
    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          try {
            await this.loadInspections();
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
          }
        } else this.showArchivedToDate = null;
      }
    },
    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },
    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    }
  },
  methods: {
    async downloadAndPrintReport(reportType: string) {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        let inspections = this.inspections.map(x => {
          return {
            id: x.id,
            scaffoldID: x.scaffoldID,
            inspectionDateTime: x.inspectionDateTime,
            inspectedBy: x.inspectedBy,
            inspectionDidPass: x.inspectionDidPass,
            inspectionDay: x.inspectionDay,
            scaffoldNumber: x.scaffoldNumber,
            timeSegmentName: x.timeSegmentName,
            inspectionResult: x.inspectionDidPass
              ? this.$t("inspections.inspection-result-pass-label")
              : this.$t("inspections.inspection-result-fail-label")
          } as ScaffoldInspectionWithHistoryData;
        });
        if (inspections.length == 0) {
          this.$store.dispatch("SHOW_SNACKBAR", {
            text: this.$t("inspections.printing.no-data-message"),
            type: "info"
          });
          return;
        }
        let blob = await reportService.getScaffoldInspectionHistoryPrintoutWithData(
          inspections,
          reportType,
          DateUtil.localizedDateTimeString(new Date()),
          `${this.scaffoldNumber}`
        );
        if (reportType == "xls") {
          downloadBlob(blob, "scaffold-inspection-history.xlsx");
        } else {
          printBlob(blob, "scaffold-inspection-history.pdf", "application/pdf");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    inspectionCreatedAtDifferentTime(item: ScaffoldInspectionWithDetails): boolean {
      if (!item.inspectionDateTime || !item.created) return false;

      return (
        DateUtil.localizedDateTimeString(item.inspectionDateTime) !=
        DateUtil.localizedDateTimeString(item.created)
      );
    },
    inspectionClicked(item: ScaffoldInspectionWithDetails) {
      this.closeDialog!(false);
      this.$router.push(`/inspections/${item.id}`);
    },
    ...mapMutations({
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },
    async loadData() {
      this.processing = true;
      try {
        await this.loadInspections();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async open(scaffoldID: string, scaffoldNumber: number | null | undefined): Promise<boolean> {
      this.optOutOfErrorHandling();
      this.scaffoldID = scaffoldID;
      this.scaffoldNumber = scaffoldNumber;
      this.loadData();

      return this.showDialog!();
    },
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },
    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
    },

    // *** INSPECTIONS ***

    async loadInspections() {
      this.inspections = (
        await scaffoldInspectionService.getByScaffoldID(
          this.scaffoldID,
          this.showArchivedFromDate!,
          this.showArchivedToDate!
        )
      )
        .sort((a, b) => b.inspectionDateTime!.getTime() - a.inspectionDateTime!.getTime())
        .map(
          x =>
            ({
              ...x,
              timeSegmentName:
                x.timeSegment?.name == "allday"
                  ? this.$t("inspections.times.all-day-label")
                  : x.timeSegment?.name,
              inspectionDay: !!x.timeSegment?.start
                ? DateUtil.isoDateString(
                    new Date(
                      x.timeSegment.start.getUTCFullYear(),
                      x.timeSegment.start.getUTCMonth(),
                      x.timeSegment.start.getUTCDate(),
                      x.timeSegment.start.getUTCHours(),
                      x.timeSegment.start.getUTCMinutes(),
                      x.timeSegment.start.getUTCSeconds()
                    )
                  )
                : DateUtil.isoDateString(x.inspectionDateTime)
            } as ScaffoldInspectionWithExtraDetails)
        );
      this.$nextTick(() => {
        this.toggleTableGroups(true);
      });
    },
    allGroupsExpanded(): boolean {
      let datatableRef = `datatable`;
      let datatable = this.$refs[datatableRef] as VDataTable;
      if (!datatable) {
        // console.log(`datatable "${datatableRef}" not found`);
        return false;
      }
      let toggleRefs = Object.keys(this.$refs).filter(x => x.startsWith(`grouptoggle`));
      let anyGroupsClosed = false;
      for (let ref of toggleRefs) {
        let groupName = ref.replace(`grouptoggle`, "");
        let isOpen = datatable.openCache[groupName];
        if (!isOpen) {
          anyGroupsClosed = true;
          break;
        }
      }
      return !anyGroupsClosed;
    },
    toggleTableGroups(closed: boolean = true) {
      let toggleRefs = Object.keys(this.$refs).filter(x => x.startsWith(`grouptoggle`));
      let datatable = this.$refs[`datatable`] as VDataTable;
      for (let ref of toggleRefs) {
        let groupName = ref.replace(`grouptoggle`, "");
        let isOpen = datatable.openCache[groupName];
        if ((closed && isOpen) || (!closed && !isOpen)) {
          datatable.openCache[groupName] = !datatable.openCache[groupName];
        }
      }
    },
    fromDateChanged(val: Date) {
      this.maxDate = DateUtil.addMonthsToDate(val, 2);
      let now = new Date();
      if (this.maxDate.getTime() > now.getTime()) this.maxDate = now;
      this.showArchivedMinDate;
    },
    toDateChanged(val: Date) {
      this.minDate = DateUtil.addMonthsToDate(val, -2);
    }
  },

  beforeDestroy() {},

  created: async function() {}
});
export default ScaffoldInspectionHistoryDialog;

export async function showScaffoldInspectionHistoryDialog(
  scaffoldID: string,
  scaffoldNumber: number | null | undefined
): Promise<boolean> {
  let dialog = createDialog(ScaffoldInspectionHistoryDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(scaffoldID, scaffoldNumber);
}

