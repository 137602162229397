import Vue from "vue";
import VueI18n from "vue-i18n";
import serviceErrorHandling, { InlineMessage } from "./serviceErrorHandling";

export { MessageType, InlineMessage, ErrorHandlingOverride } from "./serviceErrorHandling";

export interface ErrorTrackingState {
  message: VueI18n.TranslateResult | null;
  errorReportingRequested: boolean;
  processingIncremented: boolean;
}

export default serviceErrorHandling.extend({
  beforeCreate() {
    let methods = this.$options.methods;
    let unwatchedMethods = (this.$options.computed?.unwatchedMethodNames as Function)() ?? [];
    if (methods) {
      for (let key in methods) {
        if (!!unwatchedMethods && unwatchedMethods.includes(key)) continue;
        switch (key) {
          case "optOutOfErrorHandling":
          case "reportErrorOnFailure":
            break;
          default:
            methods[key] = wrapMethodInExceptionHandler(methods[key]);
        }
      }
    }
  },
  data() {
    return {
      // The following will control whether the controls on screen are disabled while we are
      // processing and error states associated with active work
      processingCount: 0,
      activeErrorTrackingState: {
        message: null,
        processingIncremented: false,
        errorReportingRequested: false
      } as ErrorTrackingState
    };
  },
  computed: {
    // Classes making use of errorHandling can identify methods that are NOT to be wrapped in the exception handler
    unwatchedMethodNames(): string[] {
      return [];
    }
  },
  methods: {
    optOutOfErrorHandling() {
      // Record that the current call frame is opting out of error reporting
      let errorState = this.activeErrorTrackingState;
      errorState.errorReportingRequested = false;

      // If this method was called after processing was already recorded, undo that
      if (this.activeErrorTrackingState.processingIncremented) {
        this.processingCount--;
        this.processing = !!this.processingCount;
        this.activeErrorTrackingState.processingIncremented = false;
      }
    },
    reportErrorOnFailure(message: VueI18n.TranslateResult) {
      // This can't happen if we've previously disabled error tracking
      let errorState = this.activeErrorTrackingState;
      if (!errorState.errorReportingRequested) {
        throw new Error("Errors were disabled prior to this call");
      }

      errorState.message = message;
    }
  }
});

function wrapMethodInExceptionHandler(method: Function): (this: Vue, ...args: any[]) => any {
  // This method gets a bit hairy because we need to allow async methods to be properly tracked
  // without making sync methods wait around in a new promise
  return (function(this: {
    $t: typeof VueI18n.prototype.t;
    processing: boolean;
    processingCount: number;
    activeErrorTrackingState: ErrorTrackingState;
    inlineMessage: InlineMessage;
  }) {
    // Back up the last tracking state and create a new one for this funciton; we do this to
    // let functions call other functions while setting their own error tracking status
    let lastErrorTrackingState = this.activeErrorTrackingState;
    let thisErrorTrackingState = (this.activeErrorTrackingState = {
      message: null,
      errorReportingRequested: true,
      processingIncremented: false
    });

    // // We clear any error information on invocation of any new tracked method
    // this.inlineMessage.message = null;

    // Execute the method
    try {
      let returnValue = method.apply(this, arguments);

      // If the method is a promise, invoke the promise wrapper, which handles setting processing
      // states and takes over restoration of the error tracking state on our behalf
      if (returnValue instanceof Promise) {
        returnValue = handleMethodPromise.call(
          this,
          returnValue,
          thisErrorTrackingState,
          lastErrorTrackingState
        );
      } else {
        // If we didn't get a promise we're responsible for restoring error tracking
        this.activeErrorTrackingState = lastErrorTrackingState;
      }
      return returnValue;
    } catch (error) {
      // The promise handler deals with async errors but we need to record any sync errors
      // unless the target function has turned off error reporting
      if (thisErrorTrackingState.errorReportingRequested) {
        this.inlineMessage.message =
          this.activeErrorTrackingState.message || this.$t("unexpected-network-error");
        this.inlineMessage.type = "error";
      }

      throw error;
    }
    // There's some typecasting weirdness, for some reason we can't get this method to conform
    // to the desired signature without an unknown cast first
  } as unknown) as (this: Vue, ...args: any[]) => any;
}

async function handleMethodPromise(
  this: {
    $t: typeof VueI18n.prototype.t;
    processing: boolean;
    processingCount: number;
    activeErrorTrackingState: {
      message: VueI18n.TranslateResult | null;
      errorReportingRequested: boolean;
    };
    inlineMessage: InlineMessage;
  },
  promise: Promise<any>,
  thisErrorTrackingState: ErrorTrackingState,
  lastErrorTrackingState: ErrorTrackingState
): Promise<any> {
  try {
    // If we were asked to report on errors we also enable processing tracking
    if (thisErrorTrackingState.errorReportingRequested) {
      thisErrorTrackingState.processingIncremented = true;
      this.processingCount++;
      this.processing = true;
    }

    // Wait for the promise to complete; all other processing is done in catch/finally
    return await promise;
  } catch (error) {
    // If we're still expected to report errors, report them to the screen before returning;
    // it's possible that within the promise the callee turned this off
    if (thisErrorTrackingState.errorReportingRequested) {
      this.inlineMessage.message =
        this.activeErrorTrackingState.message || this.$t("unexpected-network-error");
      this.inlineMessage.type = "error";
    }
    throw error;
  } finally {
    // If we previously indicated processing we turn that off when we're done
    if (thisErrorTrackingState.processingIncremented) {
      this.processingCount--;
      this.processing = !!this.processingCount;
    }

    // Discard our current error tracking state, checking for possible collisions caused by
    // multiple async methods being called concurrently
    if (this.activeErrorTrackingState == thisErrorTrackingState) {
      // TODO: If this is false do we have an error condition? Will we end up in a race?
      this.activeErrorTrackingState = lastErrorTrackingState;
    }
  }
}
