var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !!_vm.value
    ? _c(
        "v-tooltip",
        {
          attrs: {
            top: "",
            disabled:
              !_vm.value || (!_vm.showRequestBadge && !_vm.showWorkOrderBadge)
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function({ on }) {
                  return [
                    !!_vm.value
                      ? _c(
                          "v-row",
                          _vm._g({ attrs: { "no-gutters": "" } }, on),
                          [
                            _c(
                              "v-badge",
                              {
                                staticClass:
                                  "fd-scheduler-scaffold-work-orders-badge",
                                attrs: {
                                  value: _vm.showRequestBadge,
                                  color: "primary",
                                  content: _vm.requestCount,
                                  tile: ""
                                }
                              },
                              [_c("span", [_vm._v(_vm._s(_vm.value))])]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-badge",
                              {
                                staticClass:
                                  "fd-scheduler-scaffold-work-orders-badge",
                                style: `left: ${_vm.workOrderBadgePadding}px;`,
                                attrs: {
                                  value: _vm.showWorkOrderBadge,
                                  color: "green",
                                  content: _vm.workOrderCount
                                }
                              },
                              [_vm._v(" \n      ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-badge",
                              {
                                staticClass:
                                  "fd-scheduler-scaffold-work-orders-badge fd-triangle-down-badge",
                                style: `left: ${_vm.dismantleBadgePadding}px;`,
                                attrs: {
                                  value: _vm.showDismantleBadge,
                                  color: "red",
                                  content: "D"
                                }
                              },
                              [_vm._v(" \n      ")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ],
            null,
            false,
            1367762935
          )
        },
        [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.tooltip))])]
      )
    : _c("fd-value-display")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }