<template>
    <v-dialog
            v-model="dialogVisible"
            width="800px"
            persistent
    >
        <v-form ref="form" v-on:submit="onSubmit">
            <v-card>
                <v-card-title v-bind:class="[processing ? 'dialog-new-top-color-processing' : 'dialog-new-top-color']">
                    <span v-bind:class="[processing ? 'breadcrumb-processing-opacity' : '']">{{ $t('projects.areas.sub-areas.new-dialog-title') }}</span>
                    <v-spacer></v-spacer>
                    <v-progress-circular
                            :indeterminate="true"
                            :rotate="0"
                            :size="32"
                            :width="4"
                            color="white"
                            v-if="processing"
                    ></v-progress-circular>
                </v-card-title>
                <v-container>
                    <v-row class="mx-2">
                        <v-col cols="12" class="pb-1">
                            <fd-text-field v-bind:label="$t('common.name')" v-model="subArea.name" v-bind:disabled="processing" v-bind:rules="[rules.required]"></fd-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-1">
                            <fd-textarea v-bind:label="$t('common.description')" v-model="subArea.description" v-bind:disabled="processing"></fd-textarea>
                        </v-col>
                        <v-col cols="12">
                            <v-alert v-if="inlineMessage.message" v-bind:type="inlineMessage.type">
                                {{ inlineMessage.message }}
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            text
                            v-bind:disabled="processing"
                            @click="cancelDialog"
                    >{{ $t('common.cancel') }}</v-btn>
                    <v-btn
                            color="primary"
                            v-bind:loading="processing"
                            v-bind:disabled="processing"
                            @click="saveDialog"
                    >{{ $t('common.save-and-close') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import { mapState, mapActions } from "vuex";
    import i18n from '../../i18n'

    export default {
        name: 'fd-sub-area-new-dialog',

        // TODO: Implement show property as mixin? Figure this out as we explore other optimizations
        props: ['show', 'areaID'],
  
        mounted () {
            // Bind the inbound value of the 'show' property to our own handlers so we can act
            // when we're asked to show and hide ourselves
            // TODO: Consider a binding that exposes a callable method; toggling data like this seems to be weird, although it's what I saw online
            this.onShowPropChanged(this.value);
            // this.$watch('show', this.onShowPropChanged);
        },

        data: () => ({
            dialogVisible: false,

            // The following will control whether the controls on screen are disabled while we are processing.
            processing: false,

            // The following is responsible for the inline messages that may be presented to the user.
            // If the message property is null then no message will be shown to the user.
            // The type can be one of the following strings:
            // 'success'
            // 'info'
            // 'warning'
            // 'error'
            inlineMessage: {
                message: null,
                type: 'error'
            },

            subArea: {
                name: "",
                description: ""
            },

            rules: {
                required: value => !!value || i18n.t('common.rule-required')
            }
        }),

        watch: {
            show (val) { this.onShowPropChanged(val); }
        },

        methods :{
            onShowPropChanged(value) {
                this.dialogVisible = value;
            },
            onSubmit(e) {
                e.preventDefault();
                this.saveDialog();
            },

            closeDialog() {
                this.$refs.form.reset();
                this.inlineMessage.message = null;
                this.dialogVisible = false;
                this.$emit("update:show", false);
            },

            // Method used in conjunction with the Cancel dialog.
            cancelDialog() {
                this.closeDialog();
            },

            //Method used in conjunction with new view dialog.
            async saveDialog () {
                this.inlineMessage.message = null;

                if (!this.$refs.form.validate()) {
                    return;
                }

                this.processing = true;
                try {
                    await this.addSubArea({
                        ...this.subArea,
                        parentLocationID: this.areaID, // TODO: Use areaID for local payload?
                        enabled: true
                    });
                    this.closeDialog();
                } catch (error) {
                    this.inlineMessage.message = this.$t("projects.areas.sub-areas.save-network-error");
                    this.inlineMessage.type = "error";
                } finally {
                    this.processing = false;
                }
            },
            ...mapActions({
                addSubArea: 'ADD_SUBAREA'
            })
        }
    }
</script>
