import FDVue from "@fd/lib/vue";
import { BulkImportResults, workPackageService, WorkPackagesImportSettings } from "../../services";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import { VForm } from "@fd/lib/vue/types";
import store from "../../store";
import rules from "@fd/lib/vue/rules";

export default FDVue.extend({
  mixins: [rules],

  props: {
    closeAction: Function
  },

  data: () => ({
    processing: false,
    downloading: false,
    downloadingerrors: false,
    uploading: false,

    inlineMessage: {
      message: null as any,
      type: "error" as "success" | "info" | "warning" | "error"
    },

    file: null as any,

    returnFile: null as Blob | null,
    importResults: null as BulkImportResults | null,

    // Used to map XLS Template Columns to DB Column Names
    // Default values are loaded from the server
    workPackagesImportColumnMapping: {} as WorkPackagesImportSettings
  }),

  computed: {},

  methods: {
    async loadColumnMapping() {
      this.workPackagesImportColumnMapping = await workPackageService.getDefaultImportSettings();
    },
    async uploadFiles() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = null;
      this.importResults = null;
      this.returnFile = null;

      if (!(this.$refs.uploadForm as VForm).validate()) {
        console.log((this.$refs.uploadForm as VForm).validate());
        return;
      }

      this.processing = true;
      this.uploading = true;

      var isSuccessful = true;
      if (isSuccessful) isSuccessful = await this.validateFile();
      if (isSuccessful) isSuccessful = await this.uploadFile();

      this.processing = false;
      this.uploading = false;

      if (isSuccessful) this.showSnackbarAndCloseDialog();
    },
    showSnackbarAndCloseDialog() {
      var snackbarPayload = {
        text: this.$t("common.bulk-upload-results.import-success"),
        type: "success",
        undoCallback: null
      };
      store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      // this.$props.closeAction(true);
    },

    async validateFile(): Promise<boolean> {
      console.log("validateFile()");
      var isSuccessful = false;
      try {
        this.returnFile = await workPackageService.validateCustomizedWorkPackageList(
          this.workPackagesImportColumnMapping.identifyingColumnFriendlyName || "",
          this.workPackagesImportColumnMapping.activityID || "",
          this.workPackagesImportColumnMapping.name || "",
          this.workPackagesImportColumnMapping.startDate || "",
          this.workPackagesImportColumnMapping.finishDate || "",
          this.workPackagesImportColumnMapping.completionPercentage || "",
          this.workPackagesImportColumnMapping.workStatus || "",
          this.workPackagesImportColumnMapping.purpose || "",
          this.workPackagesImportColumnMapping.plannedStartDate || "",
          this.workPackagesImportColumnMapping.plannedFinishDate || "",
          this.workPackagesImportColumnMapping.cwpPlannedStartDate || "",
          this.workPackagesImportColumnMapping.cwpPlannedFinishDate || "",
          this.workPackagesImportColumnMapping.forecastStartDate || "",
          this.workPackagesImportColumnMapping.forecastFinishDate || "",
          this.workPackagesImportColumnMapping.discipline || "",
          this.workPackagesImportColumnMapping.unit || "",
          this.workPackagesImportColumnMapping.constructionWorkArea || "",
          this.workPackagesImportColumnMapping.area || "",
          this.workPackagesImportColumnMapping.longDescription || "",
          this.workPackagesImportColumnMapping.description || "",
          this.workPackagesImportColumnMapping.cwp || "",
          this.workPackagesImportColumnMapping.estimatedHours || "",
          this.workPackagesImportColumnMapping.earnedHours || "",
          this.workPackagesImportColumnMapping.statusDate || "",
          this.file
        );
        isSuccessful = this.returnFile == null;
      } catch (error) {
        console.log("\tError: " + error);
        if ((error as any).statusCode == 422) {
          // Column mismatch error is version agnostic as it's related to the columns on the screen or in the DB
          this.inlineMessage.message = this.$t("common.bulk-upload-results.column-mismatch-error");
        } else {
          // Importing into V2 is the default functionality and is implied unless otherwise specified
          // If the user is trying to also upload into v1, then we need to specify which one failed.
          var importErrorLabel = "common.bulk-upload-results.sp2-import-error";
          this.inlineMessage.message = this.$t(importErrorLabel);
        }
        this.inlineMessage.type = "error";
      } finally {
        console.log("\tisSuccessful: " + isSuccessful);
        return isSuccessful;
      }
    },

    async uploadFile(): Promise<boolean> {
      console.log("uploadFile()");
      var isSuccessful = false;
      try {
        this.importResults = await workPackageService.uploadCustomizedWorkPackageList(
          this.workPackagesImportColumnMapping.identifyingColumnFriendlyName || "",
          this.workPackagesImportColumnMapping.activityID || "",
          this.workPackagesImportColumnMapping.name || "",
          this.workPackagesImportColumnMapping.startDate || "",
          this.workPackagesImportColumnMapping.finishDate || "",
          this.workPackagesImportColumnMapping.completionPercentage || "",
          this.workPackagesImportColumnMapping.workStatus || "",
          this.workPackagesImportColumnMapping.purpose || "",
          this.workPackagesImportColumnMapping.plannedStartDate || "",
          this.workPackagesImportColumnMapping.plannedFinishDate || "",
          this.workPackagesImportColumnMapping.cwpPlannedStartDate || "",
          this.workPackagesImportColumnMapping.cwpPlannedFinishDate || "",
          this.workPackagesImportColumnMapping.forecastStartDate || "",
          this.workPackagesImportColumnMapping.forecastFinishDate || "",
          this.workPackagesImportColumnMapping.discipline || "",
          this.workPackagesImportColumnMapping.unit || "",
          this.workPackagesImportColumnMapping.constructionWorkArea || "",
          this.workPackagesImportColumnMapping.area || "",
          this.workPackagesImportColumnMapping.longDescription || "",
          this.workPackagesImportColumnMapping.description || "",
          this.workPackagesImportColumnMapping.cwp || "",
          this.workPackagesImportColumnMapping.estimatedHours || "",
          this.workPackagesImportColumnMapping.earnedHours || "",
          this.workPackagesImportColumnMapping.statusDate || "",
          this.file
        );
        isSuccessful = this.importResults.successful;
      } catch (error) {
        console.log("\tError: " + error);
        if ((error as any).statusCode == 422) {
          // Column mismatch error is version agnostic as it's related to the columns on the screen or in the DB
          this.inlineMessage.message = this.$t("common.bulk-upload-results.column-mismatch-error");
        } else {
          // Importing into V2 is the default functionality and is implied unless otherwise specified
          // If the user is trying to also upload into v1, then we need to specify which one failed.
          var importErrorLabel = "common.bulk-upload-results.sp2-import-error";
          this.inlineMessage.message = this.$t(importErrorLabel);
        }
        this.inlineMessage.type = "error";
      } finally {
        console.log("\tisSuccessful: " + isSuccessful);
        return isSuccessful;
      }
    },
    async downloadReturnFile() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = null;
      this.importResults = null;

      if (!this.returnFile) {
        this.inlineMessage.message = this.$t("common.bulk-upload-general.file-does-not-exist");
        this.inlineMessage.type = "warning";
        return;
      }

      this.downloadingerrors = true;
      this.processing = true;
      try {
        downloadBlob(this.returnFile!, this.file.name);
      } catch (error) {
        this.inlineMessage.message = this.$t("unexpected-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
        this.downloadingerrors = false;
      }
    },
    async downloadTemplate() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = null;
      this.importResults = null;
      this.returnFile = null;

      this.processing = true;
      this.downloading = true;
      try {
        let workPackageListTemplate = await workPackageService.downloadWorkPackageListTemplate();
        downloadBlob(workPackageListTemplate, "IWPListTemplate.xlsx");
      } catch (error) {
        this.inlineMessage.message = this.$t("unexpected-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
        this.downloading = false;
      }
    }
  },

  watch: {
    processing(value) {
      this.$emit("processingUpdated", value);
    }
  },

  created: async function() {
    this.processing = true;
    await this.loadColumnMapping();
    this.processing = false;
  }
});

