var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { staticClass: "mx-0", staticStyle: { width: "100%" } },
    [
      _c(
        "v-col",
        {
          staticClass: "fd-remove-left-padding-cols-below pl-0",
          attrs: { cols: "12" }
        },
        [
          _c(
            "v-row",
            { staticClass: "mx-0 px-0", attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { align: "center", cols: "12" } },
                [
                  _c(
                    "v-subheader",
                    {
                      staticClass: "pa-0 mt-3",
                      staticStyle: { "font-size": "1em", height: "32px" }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("scheduler.scaffold-request-walk-down"))
                      ),
                      _vm.walkdown.isHardBarricade
                        ? _c("span", [
                            _vm._v(
                              "\n             | " +
                                _vm._s(
                                  _vm.$t("scheduler.walkdown-hard-barricade")
                                ) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0 px-0", attrs: { align: "center" } },
            [_c("v-divider", { staticClass: "mx-0 mt-0 px-0" })],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0 pl-0 pt-2 pb-3" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.walkdown-submitted-by"),
                      value: _vm.$lookup.personBySecurityID(
                        _vm.walkdown.submittedBy
                      ),
                      labelSmCols: "2",
                      valueSmCols: "10"
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value-gap"),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.walkdown-notes"),
                      value: _vm.walkdown.notes,
                      labelSmCols: "2",
                      valueSmCols: "10"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.workOrder.requestType == _vm.ScaffoldRequestTypes.Modify &&
          !!_vm.workOrder.currentUserPermissions &&
          !!_vm.workOrder.currentUserPermissions.canViewEstimateDetails
            ? _c(
                "v-row",
                { staticClass: "mx-0 pl-0 pt-2 pb-3" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t("scheduler.walkdown-crew-size"),
                          value: _vm.walkdown.crewSize
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t(
                            "scheduler.walkdown-modification-hours"
                          ),
                          value: _vm.walkdown.modificationHours
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.workOrder.requestType != _vm.ScaffoldRequestTypes.Modify
            ? _c(
                "v-row",
                { staticClass: "mx-0 pl-0 pt-2" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t("scheduler.walkdown-length"),
                          value: _vm.walkdown.length
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t("scheduler.walkdown-bay-length-name"),
                          value: _vm.$lookup.scaffoldBayLength(
                            _vm.walkdown.scaffoldBayLengthID
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t("scheduler.walkdown-length-bay-count"),
                          value: _vm.walkdown.lengthBayCount
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.workOrder.requestType != _vm.ScaffoldRequestTypes.Modify &&
          !_vm.walkdown.isHardBarricade
            ? _c(
                "v-row",
                { staticClass: "mx-0 pl-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t("scheduler.walkdown-width"),
                          value: _vm.walkdown.width
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t("scheduler.walkdown-bay-width-name"),
                          value: _vm.$lookup.scaffoldBayWidth(
                            _vm.walkdown.scaffoldBayWidthID
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t("scheduler.walkdown-width-bay-count"),
                          value: _vm.walkdown.widthBayCount
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.workOrder.requestType != _vm.ScaffoldRequestTypes.Modify &&
          !_vm.walkdown.isHardBarricade
            ? _c(
                "v-row",
                { staticClass: "mx-0 pl-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t("scheduler.walkdown-height"),
                          value: _vm.walkdown.height
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t("scheduler.walkdown-bay-height-name"),
                          value: _vm.$lookup.scaffoldBayHeight(
                            _vm.walkdown.scaffoldBayHeightID
                          )
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.workOrder.requestType != _vm.ScaffoldRequestTypes.Modify &&
          !_vm.walkdown.isHardBarricade
            ? _c(
                "v-row",
                { staticClass: "mx-0 pl-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t("scheduler.walkdown-access-type"),
                          value: !_vm.walkdown.accessType
                            ? _vm.$t("common.none")
                            : _vm.$t("scheduler.walkdown-ladder-access")
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t("scheduler.walkdown-deck-levels"),
                          value: _vm.walkdown.deckLevels
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.workOrder.requestType != _vm.ScaffoldRequestTypes.Modify
            ? _c(
                "v-row",
                { staticClass: "mx-0 pl-0 pb-3" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t("scheduler.walkdown-barricade-gates"),
                          value: _vm.walkdown.barricadeGates
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0 pl-0 pt-2 pb-3" },
            [
              !_vm.walkdown.isHardBarricade
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t("scheduler.walkdown-type-modifier"),
                          value: _vm.$lookup.scaffoldTypeModifier(
                            _vm.walkdown.scaffoldTypeModifierID
                          )
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.walkdown.isHardBarricade
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t(
                            "scheduler.walkdown-elevation-modifier"
                          ),
                          value: _vm.$lookup.scaffoldElevationModifier(
                            _vm.walkdown.scaffoldElevationModifierID
                          )
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.walkdown.isHardBarricade
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t("scheduler.walkdown-height-modifier"),
                          value: _vm.$lookup.scaffoldHeightModifier(
                            _vm.walkdown.scaffoldHeightModifierID
                          )
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("scheduler.walkdown-congestion-factor"),
                      value: _vm.$lookup.scaffoldCongestionFactor(
                        _vm.walkdown.scaffoldCongestionFactorID
                      )
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              !_vm.walkdown.isHardBarricade
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t("scheduler.walkdown-internal-modifier"),
                          value: _vm.$lookup.internalModifier(
                            _vm.walkdown.internalModifierID
                          )
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.walkdown.isHardBarricade
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t("scheduler.walkdown-hoarding-modifier"),
                          value: _vm.$lookup.hoardingModifier(
                            _vm.walkdown.hoardingModifierID
                          )
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }