<template>
    <v-container fluid >
        <v-form ref="form" v-on:submit="onSubmit">
            <v-slide-x-reverse-transition>
                <v-card v-show="slidein">
                    <v-toolbar v-bind:class="[processing ? 'view-card-top-color-processing' : 'view-card-top-color']">
                        <!--View Title-->
                        <v-row>
                            <v-col cols="12">
                                <v-row align="center">
                                    <v-breadcrumbs
                                        class="d-none d-sm-flex"
                                        :items="$store.state.currentBreadcrumbs"
                                        large
                                        v-bind:class="[processing ? 'breadcrumb-processing-opacity' : '', 'pl-3']"
                                    >
                                        <template v-slot:divider>
                                            <v-icon>fa-chevron-right</v-icon>
                                        </template>
                                    </v-breadcrumbs>
                                    <fd-back-button :route="item => $router.push(`/modifiers/`)"></fd-back-button>
                                    <v-spacer></v-spacer>
                                    <v-progress-circular
                                            :indeterminate="true"
                                            :rotate="0"
                                            :size="32"
                                            :width="4"
                                            color="white"
                                            class="mr-3"
                                            v-if="processing"
                                    ></v-progress-circular>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-toolbar>

                    <v-container fluid class="pb-0 px-0">
                        <!--TABS for Details, Parts and Areas-->
                        <v-tabs
                                icons-and-text
                                ref="tabs"
                                v-model="active_tab"

                        >
                            <!-- Details Tab-->
                            <v-tab
                                    ref="tab"
                                    key="details"
                            >
                                {{ $t('common.details') }}
                                <v-icon>fas fa-edit</v-icon>
                            </v-tab>

                            <v-tab-item
                                    key="details"
                            >
                                <v-row class="pl-3 pr-3 pt-3"> 
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field
                                                v-bind:label="$t('common.name')"
                                                v-model="modifier.name"
                                                v-bind:disabled="processing"
                                                :rules="[rules.required]"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                                v-bind:label="$t('common.description')"
                                                v-model="modifier.description"
                                                v-bind:disabled="processing"
                                        ></v-textarea>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-switch
                                                v-bind:label="$t('common.enabled')"
                                                v-model="modifier.enabled"
                                                v-bind:disabled="processing"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-alert v-if="inlineMessage.message" v-bind:type="inlineMessage.type">
                                            {{ inlineMessage.message }}
                                        </v-alert>
                                        <v-row class="ml-0 mr-0">
                                            <v-btn
                                                    link
                                                    v-bind:disabled="processing"
                                                    color="error"
                                                    outlined
                                                    @click="deleteItem"
                                            >{{ $t('common.delete') }}</v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                    text
                                                    link
                                                    v-bind:disabled="processing"
                                                    @click="cancel"
                                            >{{ $t('common.cancel') }}</v-btn>
                                            <v-btn
                                                    color="primary"
                                                    v-bind:loading="saving"
                                                    v-bind:disabled="processing"
                                                    @click="save"
                                            >{{ $t('common.save') }}</v-btn>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-tab-item>

                            <!-- Values Tab-->
                            <v-tab
                                    key="values"
                                    @click="tabSelected('values')"
                            >
                                {{ $t('modifiers.values.sub-menu-title')}}
                                <v-icon>fas fa-list</v-icon>
                            </v-tab>

                            <v-tab-item
                                    key="values"
                            >
                                <v-card
                                        flat
                                        tile
                                >
                                    <!--Datatable for Values-->
                                    <v-col cols="12" class="pa-0">
                                        <!--Search bar for the view table-->
                                        <v-card-title style="margin-top: 15px;">
                                            <v-btn
                                                    color="primary"
                                                    dark
                                                    fab
                                                    @click="openNewDialog()"
                                                    class="ml-1 mr-1"
                                            >
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                            <v-divider inset vertical class="mx-4"></v-divider>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color="black" dark v-on="on" class="ml-1">info</v-icon>
                                                </template>
                                                <span>{{ $t('modifiers.values.sub-menu-information') }}</span>
                                            </v-tooltip>
                                            <v-spacer></v-spacer>
                                            <v-text-field
                                                    v-model="tablesearch"
                                                    append-icon="search"
                                                    :label="$t('common.search')"
                                                    single-line
                                                    hide-details
                                                    class="pl-0"
                                                    clearable
                                            ></v-text-field>
                                        </v-card-title>

                                        <!--Datatable for the view-->
                                        <v-data-table
                                                v-fd-column:name="$t('common.name')"
                                                v-fd-column:value="$t('common.value')"
                                                v-fd-column:enabled.hide-when-extra-small
                                                v-fd-column:actions.no-sort.hide-when-extra-small
                                                v-fd-row-navigate.show-when-extra-small="item => $router.push(`/modifiervalues/${item.id}`)"
                                                :items="modifiervalues"
                                                :search="tablesearch"
                                                :loading="processing"
                                                :loading-text="$t('common.table-loading-message')"
                                                :sort-by="['name']"
                                                mobile-breakpoint="0"
                                                class="fd-actions-table fd-enabling-table"
                                        >
                                            <template v-slot:item.enabled="{ item }">
                                                <v-simple-checkbox
                                                        v-bind:value="item.enabled"
                                                        v-bind:disabled="processing"
                                                        v-on:input="flipEnabled(item)"
                                                ></v-simple-checkbox>
                                            </template>
                                            <!--Action buttons for the datatable-->
                                            <template v-slot:item.actions="{ item }">
                                                <div class="table-actions-column-min-width">
                                                    <v-tooltip left>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                    icon
                                                                    link
                                                                    v-bind:disabled="processing"
                                                                    v-bind:to="`/modifiervalues/${item.id}`"
                                                                    v-on="on"
                                                            >
                                                                <v-icon
                                                                        small
                                                                >
                                                                    mdi-pencil
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{ $t('common.edit') }}</span>
                                                    </v-tooltip>
                                                    <v-tooltip right>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                    icon
                                                                    v-bind:disabled="processing"
                                                                    v-on="on"
                                                            >
                                                                <v-icon
                                                                        small
                                                                        @click="deleteTableItem(item)"
                                                                >
                                                                    mdi-delete
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{ $t('common.remove') }}</span>
                                                    </v-tooltip>
                                                </div>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-card>
                            </v-tab-item>
                        </v-tabs>
                    </v-container>
                </v-card>
            </v-slide-x-reverse-transition>
        </v-form>
        <fd-modifier-value-new-dialog v-bind:show.sync="showNewDialog" v-bind:modifierID="$route.params.modifierID"/>
    </v-container>
</template>

<script lang="ts">
    import {mapMutations, mapState, mapActions} from "vuex";
    import i18n from '../i18n'
    import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
    import FDVue from "@fd/lib/vue";
    import errorHandling from "@fd/lib/vue/mixins/errorHandling";

    export default FDVue.extend({
        mixins: [errorHandling],

        name: 'fd-Modifier-Existing',

        components: {
            "fd-modifier-value-new-dialog": () => import('./components/ModifierValueNewDialog.vue'),
            "fd-back-button": () => import('@fd/lib/vue/components/BackButton.vue')
        },

        directives: {
            fdColumn: FDColumnDirective,
            fdRowNavigate: FDRowNavigateDirective
        },

        data: function () { return {
            // The following tracks the current width of the browser window. It works in conjunction with a EventListener
            // setup in the "created" hook.
            windowWidth:0,

            showNewDialog: false,

            // The following is responsible for the inline messages that may be presented to the user.
            inlineMessage: {
                message: null,
                type: 'error',
            },

            // The following will control whether the controls on screen are disabled while we are conducting any screen
            // wide actions.
            processing: false,

            // The following will control whether or not the save button shows the processing/loading indicator
            saving: false,

            slidein:false,

            //The following object is used in conjunction with the breadcrumbs that are presented to the user for sub-view navigation.
            breadcrumbs: [
                {
                    text: 'Modifiers',
                    disabled: false,
                    to: '/modifiers',
                },
                {
                    text: this.$t("loading-dot-dot-dot"),
                    disabled: true,
                },
            ],

            modifier : {
                name: "",
                description: "",
                enabled: ""
            },

            rules: {
                required: value => !!value || i18n.t('common.rule-required')
            },

        };},

        computed: {
            modifiervalues () { return this.$store.state.modifiervalues.fullList.filter(x => x.modifierID == this.$route.params.modifierID); },

            tablesearch: {
                get () {
                    //return this.$store.state.searchStringForFiltering;
                    return this.$store.state.filters.find(x => x.context == this.$store.state.filteringContext)!.searchStringForFiltering;
                },
                set (val) {
                    this.$store.commit('SET_SEARCH_STRING_FOR_FILTERING', val)
                },
            },

            active_tab: {
                get () {
                    return this.$store.state.filters.find(x => x.context == this.$store.state.filteringContext)!.selectedTab;
                },
                set (val) {
                    this.$store.commit('SET_SELECTED_TAB_INDEX_IN_FILTERING_CONTEXT', val)
                },
            },
        },

        mounted () {

            // The following is used to compensate for the apparent bug in vuetify that is preventing the underline from appearing
            // on the initial tab that is in focus within the view. This kicks it in the butt to cause it to render appropriately.
            // Without it the line under the initial tab that has focus will NOT show up until you resize the screen which then
            // causes it to re-render and show up.
            const initial = (this.$refs.tab as any).$el.offsetWidth;
            const interval = setInterval(() => {
                if (this.$refs.tab) {
                    if ((this.$refs.tab as any).$el.offsetWidth !== initial) {
                        clearInterval(interval);
                        (this.$refs.tabs as any).callSlider();
                    }
                }
            }, 100)
            
        },

        methods: {
            onSubmit(e) {
                e.preventDefault();
                this.save();
            },

            // Method used in conjunction with the Save button.
            async save() {
                // First reset the inline message if there are any.
                this.inlineMessage.message = '';

                if (!this.$refs.form.validate()) {
                    return;
                }

                this.processing = true;
                this.saving = true;

                try {
                    await this.updateModifier({
                        id: this.$route.params.modifierID,
                        name: this.modifier.name,
                        description: this.modifier.description,
                        enabled: this.modifier.enabled
                    });
                    this.$router.push('/modifiers');
                } catch (error) {
                    this.inlineMessage.message = this.$t("modifiers.save-network-error");
                    this.inlineMessage.type = "error";
                } finally {
                    this.processing = false;
                    this.saving = false;
                }
            },
            // the following works with the delete "Action" button in the Datatable.
            async deleteItem() {
                this.inlineMessage.message = null;
                this.processing = true
                try {
                    await this.deleteModifier({ id: this.$route.params.modifierID, name: this.modifier.name });
                } catch (error) {
                    this.inlineMessage.message = this.$t("unexpected-network-error");
                    this.inlineMessage.type = "error";
                } finally {
                    this.processing = false;
                    this.$router.push('/modifiers');
                }
            },

            tabSelected(selectedTabKey) {
                this.setSelectedTab(selectedTabKey)
            },

            // Method used in conjunction with the Cancel button.
            cancel() {
                // TODO: Should this roll back state rather than rely on requerying?
                this.$router.push('/modifiers');
            },
            ...mapMutations({
                setModifier: 'SET_MODIFIER',
                notifyNewBreadcrumb: 'NOTIFY_NEW_BREADCRUMB',
                setFilteringContext: 'SET_FILTERING_CONTEXT',
                setSelectedTab: 'SET_SELECTED_TAB_INDEX_IN_FILTERING_CONTEXT'
            }),
            ...mapActions({
                loadModifier: 'LOAD_MODIFIER',
                updateModifier: 'UPDATE_MODIFIER',
                deleteModifier: 'DELETE_MODIFIER',
                loadModifierWithModifierValues: 'LOAD_MODIFIER_WITH_MODIFIER_VALUES',
                updateModifierValue: 'UPDATE_MODIFIER_VALUE',
                deleteModifierValue: 'DELETE_MODIFIER_VALUE'
            }),

            openNewDialog () {
                this.showNewDialog = true;
            },

            // the following works with the delete "Action" button in the Datatable.
            async deleteTableItem (item) {
                this.processing = true;
                try {
                    await this.deleteModifierValue({ id: item.id, name: item.name });
                } catch (error) {
                    this.inlineMessage.message = this.$t("unexpected-network-error");
                    this.inlineMessage.type = "error";
                } finally {
                    this.processing = false;
                }
            },
            async flipEnabled (item) {
                this.processing = true;
                try {
                    await this.updateModifierValue({ id: item.id, enabled: !item.enabled, name: item.name });
                } catch (error) {
                    this.inlineMessage.message = this.$t("unexpected-network-error");
                    this.inlineMessage.type = "error";
                } finally {
                    this.processing = false;
                }
            },

        },

        created: async function () {
            this.processing = true;

            // Listen to the "resize" even for the browser so we always know the width and can use that
            // knowledge for various responsive layout reasons.
            window.addEventListener('resize', () => {
                this.windowWidth = window.innerWidth
            });
            this.windowWidth = window.innerWidth;

            // Set the context for the Tags Filtering in the store so that if the user navigates to a screen that is
            // a sub screen of something that is currently filtered by these tags that the tags selected will be
            // preserved as they move between the two screens.
            this.setFilteringContext({
                context: "modifiervalues",
                parentalContext: "modifiers",
                searchStringForFiltering: "",
                tagsForFiltering: [],
                suppliersForFiltering: []
            });

            // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
            setInterval(() => {
                this.slidein = true;
            }, 100);


            try {
                //await this.loadModifier(this.$route.params.modifierID);
                await this.loadModifierWithModifierValues(this.$route.params.modifierID);
                var modifier = this.$store.state.modifiers.fullList.find(x => x.id == this.$route.params.modifierID);
                this.modifier = {...modifier};
                this.notifyNewBreadcrumb({
                    text: modifier.name,
                    to: `/modifiers/${this.$route.params.modifierID}`
                });
            } catch (error) {
                this.inlineMessage.message = this.$t("unexpected-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
            }
        }

    })
</script>
