var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "950px", persistent: "", "data-cy": "transfernewdialog" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c("fd-image-viewer", {
        attrs: { name: _vm.imageName },
        model: {
          value: _vm.imageSource,
          callback: function($$v) {
            _vm.imageSource = $$v
          },
          expression: "imageSource"
        }
      }),
      _vm._v(" "),
      _c("fd-image-editor", {
        attrs: {
          "complete-button-label-key": "common.done",
          name: _vm.imageName
        },
        on: { edit: _vm.handleEdit },
        model: {
          value: _vm.editImageSource,
          callback: function($$v) {
            _vm.editImageSource = $$v
          },
          expression: "editImageSource"
        }
      }),
      _vm._v(" "),
      _c(
        "v-card",
        { ref: "content", attrs: { "data-cy": "transfernew" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [_vm._v(_vm._s(_vm.dialogTitle))]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          !_vm.currentUserCanCreateTransfers
            ? _c("fd-alert", {
                staticClass: "mx-3",
                attrs: {
                  type: "info",
                  label: _vm.$t("transfers.no-permission-to-create")
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-stepper",
            {
              attrs: { "non-linear": "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-stepper-header",
                {
                  staticClass: "fd-responsive-stepper",
                  attrs: { height: "60" }
                },
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.basicStep.number,
                        step: _vm.basicStep.number,
                        editable: "",
                        rules: [() => !_vm.basicStep.error]
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.basicStep.name) +
                          "\n          "
                      ),
                      _vm.basicStepError
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "transfers.new.steps.details-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.photosStep.number,
                        step: _vm.photosStep.number,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.photosStep.name) +
                          "\n          "
                      ),
                      _vm.photosStep.error
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "transfers.new.steps.photos-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pt-0 pl-0 pr-0 pb-0",
                      attrs: { step: _vm.basicStep.number }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "basicform",
                          staticClass: "request-form",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "pt-1",
                              attrs: { "no-gutters": "", align: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between px-3 pb-0 pt-5",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("fd-date-picker", {
                                    attrs: {
                                      label: _vm.$t(
                                        "transfers.transfer-date-label"
                                      ),
                                      disabled: _vm.processing,
                                      rules: [_vm.rules.required]
                                    },
                                    model: {
                                      value: _vm.transferDate,
                                      callback: function($$v) {
                                        _vm.transferDate = $$v
                                      },
                                      expression: "transferDate"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between px-3 pb-0",
                                  class: {
                                    "pt-5": _vm.$vuetify.breakpoint.smAndUp
                                  },
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _c("fd-text-field", {
                                    attrs: {
                                      label: _vm.$t(
                                        "transfers.transaction-number-label"
                                      ),
                                      disabled: _vm.processing
                                    },
                                    model: {
                                      value: _vm.transactionNumber,
                                      callback: function($$v) {
                                        _vm.transactionNumber = $$v
                                      },
                                      expression: "transactionNumber"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between px-3 py-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _vm.directionIsSupplierRequest
                                    ? _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "material-orders.from-supplier-label"
                                          ),
                                          value: _vm.$lookup.supplier(
                                            _vm.materialOrder.fromSupplierID
                                          ),
                                          disabled: _vm.processing,
                                          readonly: true
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.directionIsSupplierReturn
                                    ? _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          items: _vm.fromYards,
                                          "item-text": "name",
                                          "item-value": "id",
                                          label: _vm.$t(
                                            "transfers.from-yard-label"
                                          ),
                                          clearable: "",
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.selectedYardID,
                                          callback: function($$v) {
                                            _vm.selectedYardID = $$v
                                          },
                                          expression: "selectedYardID"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "justify-space-between px-3 py-0",
                                  attrs: {
                                    align: "center",
                                    cols: "12",
                                    sm: "6"
                                  }
                                },
                                [
                                  _vm.directionIsSupplierRequest
                                    ? _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          items: _vm.toYards,
                                          "item-text": "name",
                                          "item-value": "id",
                                          label: _vm.$t(
                                            "transfers.to-yard-label"
                                          ),
                                          clearable: "",
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.selectedYardID,
                                          callback: function($$v) {
                                            _vm.selectedYardID = $$v
                                          },
                                          expression: "selectedYardID"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.directionIsSupplierReturn
                                    ? _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "material-orders.to-supplier-label"
                                          ),
                                          value: _vm.$lookup.supplier(
                                            _vm.materialOrder.toSupplierID
                                          ),
                                          disabled: _vm.processing,
                                          readonly: true
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panels",
                            {
                              staticStyle: { "border-radius": "0" },
                              attrs: { multiple: "", accordion: "" },
                              model: {
                                value: _vm.openCategoryPanels,
                                callback: function($$v) {
                                  _vm.openCategoryPanels = $$v
                                },
                                expression: "openCategoryPanels"
                              }
                            },
                            [
                              _c(
                                "v-expansion-panel",
                                {
                                  staticClass:
                                    "fd-count-sheet-group-panel-summary"
                                },
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticStyle: {
                                        "padding-left": "24px !important"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "countsheet.dialog.summary-panel-title"
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      !_vm.partsForSummary ||
                                      _vm.partsForSummary.length == 0
                                        ? _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticStyle: {
                                                        "justify-content":
                                                          "center"
                                                      },
                                                      attrs: {
                                                        "no-gutters": ""
                                                      }
                                                    },
                                                    [
                                                      _c("fd-alert", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "transfers.summary-has-no-parts-message"
                                                          ),
                                                          type: "hint"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm._l(_vm.partsForSummary, function(
                                        part
                                      ) {
                                        return _c(
                                          "v-row",
                                          {
                                            key: part.id,
                                            staticClass: "fd-part-row",
                                            attrs: { "no-gutters": "" }
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticClass:
                                                  "fd-group-part-content"
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "py-1",
                                                    attrs: { cols: "7" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      {
                                                        attrs: {
                                                          "no-gutters": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "fd-count-sheet-part-code-label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      part.publicID
                                                                    ) +
                                                                    "\n                            "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "fd-count-sheet-part-description-label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      part.description
                                                                    ) +
                                                                    "\n                            "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            !!part.groupName
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "fd-count-sheet-part-group-label"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              " +
                                                                        _vm._s(
                                                                          part.groupName.toUpperCase()
                                                                        ) +
                                                                        "\n                            "
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pl-0 py-1",
                                                    attrs: { cols: "5" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      {
                                                        attrs: {
                                                          "no-gutters": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "pl-1 order-1 order-sm-2",
                                                            attrs: { cols: "4" }
                                                          },
                                                          [
                                                            _c(
                                                              "fd-text-field",
                                                              {
                                                                staticClass:
                                                                  "fd-count-sheet-text-field",
                                                                attrs: {
                                                                  "data-cy":
                                                                    "summaryassigned",
                                                                  dense: "",
                                                                  "hide-details":
                                                                    "",
                                                                  disabled:
                                                                    _vm.processing,
                                                                  readonly: true,
                                                                  label: _vm.$t(
                                                                    "transfers.material-order-remaining-label"
                                                                  ),
                                                                  numeric: "",
                                                                  "highlight-on-focus":
                                                                    ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    part.assigned,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      part,
                                                                      "assigned",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "part.assigned"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "pl-1 order-1 order-sm-2",
                                                            attrs: { cols: "4" }
                                                          },
                                                          [
                                                            _c(
                                                              "fd-text-field",
                                                              {
                                                                staticClass:
                                                                  "fd-count-sheet-text-field",
                                                                attrs: {
                                                                  "data-cy":
                                                                    "summarycount",
                                                                  dense: "",
                                                                  "hide-details":
                                                                    "",
                                                                  disabled:
                                                                    _vm.processing,
                                                                  label: _vm.$t(
                                                                    "transfers.count-label"
                                                                  ),
                                                                  numeric: "",
                                                                  "highlight-on-focus":
                                                                    ""
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.partCountValueChanged(
                                                                      part
                                                                    )
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    part.count,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      part,
                                                                      "count",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "part.count"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "pl-1 order-1 order-sm-2",
                                                            attrs: { cols: "4" }
                                                          },
                                                          [
                                                            _c(
                                                              "fd-text-field",
                                                              {
                                                                staticClass:
                                                                  "fd-count-sheet-text-field",
                                                                attrs: {
                                                                  "data-cy":
                                                                    "summarytotal",
                                                                  dense: "",
                                                                  "hide-details":
                                                                    "",
                                                                  disabled:
                                                                    _vm.processing,
                                                                  readonly: true,
                                                                  label: _vm.$t(
                                                                    "transfers.total-label"
                                                                  ),
                                                                  numeric: "",
                                                                  "highlight-on-focus":
                                                                    ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    part.total,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      part,
                                                                      "total",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "part.total"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel",
                                {
                                  staticClass:
                                    "fd-count-sheet-group-panel-individual"
                                },
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticStyle: {
                                        "padding-left": "24px !important"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "countsheet.dialog.individual-panel-title"
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-container",
                                        {
                                          staticClass:
                                            "pt-0 fd-embedded-table-container",
                                          attrs: { fluid: "" }
                                        },
                                        [
                                          _c(
                                            "v-expansion-panels",
                                            {
                                              staticStyle: {
                                                "border-radius": "0"
                                              },
                                              attrs: {
                                                multiple: "",
                                                accordion: ""
                                              },
                                              model: {
                                                value: _vm.openIndividualPanels,
                                                callback: function($$v) {
                                                  _vm.openIndividualPanels = $$v
                                                },
                                                expression:
                                                  "openIndividualPanels"
                                              }
                                            },
                                            _vm._l(
                                              _vm.visibleCountSheetGroups,
                                              function(group, index) {
                                                return _c(
                                                  "v-expansion-panel",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "fd-count-sheet-group-panel"
                                                  },
                                                  [
                                                    _c(
                                                      "v-expansion-panel-header",
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(group.name)
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-expansion-panel-content",
                                                      [
                                                        !group.parts ||
                                                        group.parts.length == 0
                                                          ? _c(
                                                              "v-row",
                                                              {
                                                                attrs: {
                                                                  "no-gutters":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "12"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "center"
                                                                        },
                                                                        attrs: {
                                                                          "no-gutters":
                                                                            ""
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          group.sortedParts,
                                                          function(part) {
                                                            return _c(
                                                              "v-row",
                                                              {
                                                                key: part.id,
                                                                staticClass:
                                                                  "fd-part-row",
                                                                attrs: {
                                                                  "no-gutters":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  {
                                                                    staticClass:
                                                                      "fd-group-part-content"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        staticClass:
                                                                          "py-1",
                                                                        attrs: {
                                                                          cols:
                                                                            "7"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-row",
                                                                          {
                                                                            attrs: {
                                                                              "no-gutters":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs: {
                                                                                  cols:
                                                                                    "12"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "fd-count-sheet-part-code-label"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                      " +
                                                                                        _vm._s(
                                                                                          part.publicID
                                                                                        ) +
                                                                                        "\n                                    "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "fd-count-sheet-part-description-label"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                      " +
                                                                                        _vm._s(
                                                                                          part.description
                                                                                        ) +
                                                                                        "\n                                    "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        staticClass:
                                                                          "pl-0 py-1",
                                                                        attrs: {
                                                                          cols:
                                                                            "5"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-row",
                                                                          {
                                                                            attrs: {
                                                                              "no-gutters":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                staticClass:
                                                                                  "pl-1 order-1 order-sm-2",
                                                                                attrs: {
                                                                                  cols:
                                                                                    "4"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "fd-text-field",
                                                                                  {
                                                                                    staticClass:
                                                                                      "fd-count-sheet-text-field",
                                                                                    attrs: {
                                                                                      "data-cy":
                                                                                        "assigned",
                                                                                      dense:
                                                                                        "",
                                                                                      "hide-details":
                                                                                        "",
                                                                                      disabled:
                                                                                        _vm.processing,
                                                                                      readonly: true,
                                                                                      label: _vm.$t(
                                                                                        "transfers.material-order-remaining-label"
                                                                                      ),
                                                                                      numeric:
                                                                                        "",
                                                                                      "highlight-on-focus":
                                                                                        ""
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        part.assigned,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          part,
                                                                                          "assigned",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "part.assigned"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                staticClass:
                                                                                  "pl-1 order-1 order-sm-2",
                                                                                attrs: {
                                                                                  cols:
                                                                                    "4"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "fd-text-field",
                                                                                  {
                                                                                    ref: _vm.fieldRefForPart(
                                                                                      "count",
                                                                                      part
                                                                                    ),
                                                                                    refInFor: true,
                                                                                    staticClass:
                                                                                      "fd-count-sheet-text-field",
                                                                                    attrs: {
                                                                                      "data-cy":
                                                                                        "count",
                                                                                      dense:
                                                                                        "",
                                                                                      "hide-details":
                                                                                        "",
                                                                                      disabled:
                                                                                        _vm.processing,
                                                                                      label: _vm.$t(
                                                                                        "transfers.count-label"
                                                                                      ),
                                                                                      numeric:
                                                                                        "",
                                                                                      "highlight-on-focus":
                                                                                        ""
                                                                                    },
                                                                                    on: {
                                                                                      keydown: [
                                                                                        function(
                                                                                          $event
                                                                                        ) {
                                                                                          if (
                                                                                            !$event.type.indexOf(
                                                                                              "key"
                                                                                            ) &&
                                                                                            _vm._k(
                                                                                              $event.keyCode,
                                                                                              "up",
                                                                                              38,
                                                                                              $event.key,
                                                                                              [
                                                                                                "Up",
                                                                                                "ArrowUp"
                                                                                              ]
                                                                                            )
                                                                                          )
                                                                                            return null
                                                                                          return (e =>
                                                                                            _vm.selectPreviousField(
                                                                                              e,
                                                                                              "count",
                                                                                              part
                                                                                            )).apply(
                                                                                            null,
                                                                                            arguments
                                                                                          )
                                                                                        },
                                                                                        function(
                                                                                          $event
                                                                                        ) {
                                                                                          if (
                                                                                            !$event.type.indexOf(
                                                                                              "key"
                                                                                            ) &&
                                                                                            _vm._k(
                                                                                              $event.keyCode,
                                                                                              "down",
                                                                                              40,
                                                                                              $event.key,
                                                                                              [
                                                                                                "Down",
                                                                                                "ArrowDown"
                                                                                              ]
                                                                                            )
                                                                                          )
                                                                                            return null
                                                                                          return (e =>
                                                                                            _vm.selectNextField(
                                                                                              e,
                                                                                              "count",
                                                                                              part
                                                                                            )).apply(
                                                                                            null,
                                                                                            arguments
                                                                                          )
                                                                                        },
                                                                                        function(
                                                                                          $event
                                                                                        ) {
                                                                                          if (
                                                                                            !$event.type.indexOf(
                                                                                              "key"
                                                                                            ) &&
                                                                                            _vm._k(
                                                                                              $event.keyCode,
                                                                                              "enter",
                                                                                              13,
                                                                                              $event.key,
                                                                                              "Enter"
                                                                                            )
                                                                                          )
                                                                                            return null
                                                                                          return (e =>
                                                                                            _vm.enterPressed(
                                                                                              e,
                                                                                              "count",
                                                                                              part
                                                                                            )).apply(
                                                                                            null,
                                                                                            arguments
                                                                                          )
                                                                                        }
                                                                                      ],
                                                                                      change: function(
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.partCountValueChanged(
                                                                                          part
                                                                                        )
                                                                                      }
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        part.count,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          part,
                                                                                          "count",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "part.count"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                staticClass:
                                                                                  "pl-1 order-1 order-sm-2",
                                                                                attrs: {
                                                                                  cols:
                                                                                    "4"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "fd-text-field",
                                                                                  {
                                                                                    staticClass:
                                                                                      "fd-count-sheet-text-field",
                                                                                    attrs: {
                                                                                      "data-cy":
                                                                                        "total",
                                                                                      dense:
                                                                                        "",
                                                                                      "hide-details":
                                                                                        "",
                                                                                      disabled:
                                                                                        _vm.processing,
                                                                                      readonly: true,
                                                                                      label: _vm.$t(
                                                                                        "transfers.total-label"
                                                                                      ),
                                                                                      numeric:
                                                                                        "",
                                                                                      "highlight-on-focus":
                                                                                        ""
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        part.total,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          part,
                                                                                          "total",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "part.total"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pt-0 pl-0 pr-0 pb-0",
                      attrs: { step: _vm.photosStep.number }
                    },
                    [
                      _c(
                        "v-form",
                        { ref: "filesform", on: { submit: _vm.preventSubmit } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-card-title",
                                    [
                                      _c("fd-add-file-button", {
                                        attrs: { disabled: _vm.processing },
                                        on: { change: _vm.selectNewFile }
                                      }),
                                      _vm._v(" "),
                                      _c("v-divider", {
                                        staticClass: "mx-4",
                                        attrs: { inset: "", vertical: "" }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function({ on }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass: "ml-1",
                                                        attrs: {
                                                          color: "black",
                                                          dark: ""
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v("info")]
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "transfers.photos-table-listing-tooltip"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        staticClass: "pl-0",
                                        attrs: {
                                          "append-icon": "search",
                                          label: _vm.$t("common.search"),
                                          "single-line": "",
                                          "hide-details": "",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.tablesearchfiles,
                                          callback: function($$v) {
                                            _vm.tablesearchfiles = $$v
                                          },
                                          expression: "tablesearchfiles"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-data-table", {
                                    directives: [
                                      {
                                        name: "fd-column",
                                        rawName: "v-fd-column:name",
                                        value: _vm.$t("common.name"),
                                        expression: "$t('common.name')",
                                        arg: "name"
                                      },
                                      {
                                        name: "fd-column",
                                        rawName: "v-fd-column:actions.no-sort",
                                        arg: "actions",
                                        modifiers: { "no-sort": true }
                                      }
                                    ],
                                    staticClass: "fd-actions-table",
                                    attrs: {
                                      items: _vm.files,
                                      search: _vm.tablesearchfiles,
                                      loading: _vm.processing,
                                      "loading-text": _vm.$t(
                                        "common.table-loading-message"
                                      ),
                                      "sort-by": ["name"],
                                      "mobile-breakpoint": "0"
                                    },
                                    on: {
                                      "dblclick:row": (e, data) =>
                                        _vm.viewFile(data.item)
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item.actions",
                                        fn: function({ item }) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "table-3-actions-column-min-width"
                                              },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      disabled: !_vm.$vuetify
                                                        .breakpoint.lgAndUp,
                                                      left: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      disabled:
                                                                        !item.isPreviewable ||
                                                                        _vm.processing
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.viewFile(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              mdi-eye\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("common.view")
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      disabled: !_vm.$vuetify
                                                        .breakpoint.lgAndUp,
                                                      top: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      disabled:
                                                                        !item.isPreviewable ||
                                                                        _vm.processing
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.editFile(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              mdi-pencil\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("common.edit")
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      disabled: !_vm.$vuetify
                                                        .breakpoint.lgAndUp,
                                                      top: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      disabled:
                                                                        _vm.processing
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeFile(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              mdi-delete\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common.remove"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.onSubmit } },
                [
                  !_vm.canOpenBCountDialog
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mx-0", attrs: { cols: "12" } },
                            [
                              !_vm.partCountsAreEmpty
                                ? _c("fd-alert", {
                                    attrs: {
                                      type: "info",
                                      label: _vm.$t(
                                        "transfers.new.paste-bcounts-button-disabled-clear-counts-message"
                                      )
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.inlineMessage.message
                    ? _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c(
                                    "v-alert",
                                    { attrs: { type: _vm.inlineMessage.type } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-line"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.inlineMessage.message)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mx-0 mb-3 d-flex d-md-none justify-end align-center v-card__actions"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "clear-small",
                            text: "",
                            disabled: _vm.processing || _vm.partCountsAreEmpty,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.clearPartCounts }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "transfers.new.clear-counts-button-label"
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "bcount-small",
                            outlined: "",
                            color: "primary",
                            disabled:
                              _vm.processing || !_vm.canOpenBCountDialog,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.openBCountDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "transfers.new.paste-bcounts-button-label"
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-md-flex",
                          attrs: {
                            "data-cy": "clear",
                            text: "",
                            disabled: _vm.processing || _vm.partCountsAreEmpty
                          },
                          on: { click: _vm.clearPartCounts }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "transfers.new.clear-counts-button-label"
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-md-flex",
                          attrs: {
                            "data-cy": "bcount",
                            outlined: "",
                            color: "primary",
                            disabled: _vm.processing || !_vm.canOpenBCountDialog
                          },
                          on: { click: _vm.openBCountDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "transfers.new.paste-bcounts-button-label"
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "save",
                            outlined: _vm.step != _vm.lastStep,
                            color: "primary",
                            disabled: _vm.processing,
                            loading: _vm.saving,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.saveDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.save-and-close")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 mr-3 d-none d-md-flex fd-button-context-separator-hide-when-mobile"
                        },
                        [_vm._v("\n            |\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            "data-cy": "cancel",
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancelDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.cancel")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "continue",
                            disabled: _vm.step == _vm.lastStep,
                            color: "primary",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: {
                            click: function($event) {
                              _vm.step = Number(_vm.step) + 1
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.continue")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }