var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-autocomplete",
    _vm._b(
      {
        attrs: {
          "data-cy": !!_vm.cy ? _vm.cy : _vm.dataCy,
          outlined: "",
          label: _vm.label,
          items: _vm.items,
          value: _vm.value,
          "item-text": "searchableData",
          "item-value": "id",
          clearable: "",
          "hide-details": ""
        },
        on: { input: v => _vm.$emit("input", v) },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function({ item }) {
              return [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _vm._v(_vm._s(item.name))
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      {
                        staticClass: "fd-item-detail-light",
                        attrs: { cols: "12" }
                      },
                      [_vm._v(_vm._s(item.description))]
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "item",
            fn: function({ item, on, attrs }) {
              return [
                _c(
                  "v-list-item",
                  _vm._g(_vm._b({}, "v-list-item", attrs, false), on),
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c("v-col", { attrs: { cols: "12" } }, [
                              _vm._v(_vm._s(item.name))
                            ]),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "12" } }, [
                              _c(
                                "span",
                                { staticClass: "fd-item-detail-light" },
                                [_vm._v(_vm._s(item.description))]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      },
      "v-autocomplete",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }