import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { TranslateResult } from "vue-i18n";
import { Timesheet, timesheetService, TimesheetType } from "../../../services";

type TimesheetEditDateDialogResult = Date | undefined;
type ValidationFunction = ($0: Date) => Promise<boolean>;

const TimesheetEditDateDialog = FDVue.extend({
  name: "fd-date-selection-dialog",

  mixins: [dialogSupport],

  components: {},

  data() {
    return {
      ownerHasTimesheetOnDay: false,
      selectedValue: new Date(),
      timesheet: {} as Timesheet
    };
  },

  computed: {},

  watch: {
    selectedValue() {
      this.validateNotDuplicateTimesheet();
    }
  },

  mounted: async function() {},

  methods: {
    async validateNotDuplicateTimesheet() {
      this.optOutOfErrorHandling();
      if (!this.timesheet.ownerID || !this.timesheet.contractorID || !this.timesheet.day) {
        this.ownerHasTimesheetOnDay = false;
        return;
      }
      this.processing = true;
      try {
        this.ownerHasTimesheetOnDay = await timesheetService.checkOwnerHasTimesheetForContractorOnDay(
          this.timesheet.ownerID,
          this.timesheet.contractorID,
          this.selectedValue,
          this.timesheet.timesheetTypeID!
        );
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async open(timesheet: Timesheet): Promise<TimesheetEditDateDialogResult> {
      this.timesheet = timesheet;
      this.selectedValue = timesheet.day!;

      if (await this.showDialog()) return this.selectedValue;
      else return undefined;
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    async acceptDialog() {
      if (!(this.$refs.form as HTMLFormElement).validate()) return;

      if (this.selectedValue.getTime() == this.timesheet.day!.getTime()) {
        this.closeDialog(false);
        return;
      }

      this.closeDialog(true);
    }
  }
});

export default TimesheetEditDateDialog;

export async function showTimesheetEditDateDialog(
  timesheet: Timesheet
): Promise<TimesheetEditDateDialogResult> {
  let dialog = createDialog(TimesheetEditDateDialog);
  dialog.optOutOfErrorHandling();
  let result = await dialog.open(timesheet);
  console.log("showTimesheetEditDateDialog.result: " + JSON.stringify(result));
  return result;
}

