import FDVue from "@fd/lib/vue";
import { compareStringArrays } from "@fd/lib/client-util/array";
import {
  LocationFilterWithLocations,
  ProjectLocation,
  projectLocationService
} from "../../services";

type AreaWithSelected = ProjectLocation & { selected: boolean };
type SubAreaWithSelected = ProjectLocation & { selected: boolean };
type AreaSelectionType = "entire" | "selection" | "match";

export const areaSubAreaSelection = FDVue.extend({
  data: function() {
    return {
      locationFilter: {} as LocationFilterWithLocations,
      showOnlyIncludedAreas: false,
      areasTableSearch: "",

      selectableAreas: [] as Array<AreaWithSelected>,
      allSelectableSubAreas: [] as Array<SubAreaWithSelected>
    };
  },

  computed: {
    areasUsageType: {
      get(): AreaSelectionType {
        return this.locationFilter?.includesAllLocations ? "entire" : "selection";
      },
      set(val: AreaSelectionType) {
        this.locationFilter.includesAllLocations = val == "entire";
      }
    },
    locationFilterLocationIDs(): Array<string> {
      if (this.locationFilter?.includesAllLocations == true) {
        return this.selectableAreas
          .map(x => x.id!)
          .concat(this.allSelectableSubAreas.map(y => y.id!));
      }
      let ids = [] as string[];

      if (!!this.locationFilter?.areas?.length) {
        for (let area of this.locationFilter.areas) {
          ids.push(area.id!);
          if (!!area.subAreas?.length) {
            for (let subarea of area.subAreas) {
              ids.push(subarea.id!);
            }
          }
        }
      }

      return [...new Set(ids)];
    },
    allSelectedAreaAndSubAreaIDs(): Array<string> {
      return this.selectedAreaIDs.concat(this.selectedSubAreaIDs);
    },
    areaSelectionModified(): boolean {
      let includeAll = this.areasUsageType == "entire";
      if (this.locationFilter?.includesAllLocations != includeAll) return true;

      return !compareStringArrays(
        this.locationFilterLocationIDs,
        this.allSelectedAreaAndSubAreaIDs
      );
    },
    // *** AREAS ***
    areas(): Array<AreaWithSelected> {
      let returnValue = this.selectableAreas;
      if (this.showOnlyIncludedAreas) returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedAreas(): AreaWithSelected[] {
      let selectedAreas = [] as AreaWithSelected[];
      if (this.areasUsageType == "entire") selectedAreas = this.selectableAreas;
      else if (this.areasUsageType == "selection")
        selectedAreas = this.selectableAreas.filter(x => x.selected);
      return selectedAreas;
    },
    selectedAreaIDs(): string[] {
      return this.selectedAreas.map(x => x.id!);
    },

    searchedAreas(): Array<AreaWithSelected> {
      // This is a hack because the areas list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .areasDataTable as any)?.customFilter;
      if (this.areasTableSearch && customFilter) {
        return this.areas.filter(x => customFilter(x.name!, this.areasTableSearch, x));
      } else {
        return this.areas;
      }
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedAreasSelected(): boolean {
      return this.searchedAreas.findIndex(x => !x.selected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedAreasSelected(): boolean {
      var searchedAreas = this.searchedAreas;
      return (
        searchedAreas.findIndex(x => x.selected) !== -1 &&
        searchedAreas.findIndex(x => !x.selected) !== -1
      );
    },

    /// *** WORK SUB TYPES ***
    subAreas(): Array<SubAreaWithSelected> {
      let returnValue = this.allSelectableSubAreas;
      if (this.showOnlyIncludedAreas) returnValue = returnValue.filter(x => x.selected);
      return returnValue;
    },

    selectedSubAreas(): SubAreaWithSelected[] {
      let allowedSubAreaIDs = this.selectedAreas
        .map(x => this.subAreasForArea(x))
        .reduce((a, b) => {
          a = a.concat(b);
          return a;
        }, [])
        .map(x => x.id!);
      let selectedSubAreas = [] as SubAreaWithSelected[];
      if (this.areasUsageType == "entire") selectedSubAreas = this.allSelectableSubAreas;
      else if (this.areasUsageType == "selection")
        selectedSubAreas = this.allSelectableSubAreas.filter(x => x.selected);
      // console.log(`selectedSubAreas: ${selectedSubAreas}`);
      return selectedSubAreas.filter(x => allowedSubAreaIDs.includes(x.id!));
    },

    selectedSubAreaIDs(): string[] {
      return this.selectedSubAreas.map(x => x.id!);
    },

    searchedSubAreas(): Array<SubAreaWithSelected> {
      // This is a hack because the subAreas list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .subAreasDataTable as any)?.customFilter;
      if (this.areasTableSearch && customFilter) {
        return this.subAreas.filter(x => customFilter(x.name!, this.areasTableSearch, x));
      } else {
        return this.subAreas;
      }
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedSubAreasSelected(): boolean {
      return this.searchedSubAreas.findIndex(x => !x.selected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedSubAreasSelected(): boolean {
      var searchedSubAreas = this.searchedSubAreas;
      return (
        searchedSubAreas.findIndex(x => x.selected) !== -1 &&
        searchedSubAreas.findIndex(x => !x.selected) !== -1
      );
    }
  },

  methods: {
    // *** GLOBAL ***
    async loadAreas() {
      var selectedAreas = this.locationFilter?.areas ?? [];
      var selectedSubAreas = selectedAreas.reduce((a, b) => {
        if (!!b?.subAreas?.length) a = a.concat(b.subAreas);
        return a;
      }, [] as Array<ProjectLocation>);
      this.selectableAreas = (await projectLocationService.getAllActiveAreas()).map(x => {
        return {
          ...x,
          selected: selectedAreas.findIndex(a => a.id == x.id) !== -1
        } as AreaWithSelected;
      });
      this.allSelectableSubAreas = (await projectLocationService.getAllActiveSubAreas()).map(x => {
        return {
          ...x,
          selected: selectedSubAreas.findIndex(a => a.id == x.id) !== -1
        } as AreaWithSelected;
      });
      // console.log(`selectedSubAreas: ${this.selectedSubAreas}`);
    },

    areasFilter(value: any, search: string | null, item: any): boolean {
      if (value == null || search == null || typeof value === "boolean") return false;

      if (this.areaHasSubAreasFilter(search, item)) return true;

      value = value.toLocaleLowerCase();
      let searchArray = search.split(" ").map(x => x.toLocaleLowerCase());

      // If any search terms are NOT found in the value, return false
      return !(searchArray.findIndex(x => value.indexOf(x) === -1) !== -1);
    },
    areaHasSubAreasFilter(search: string | null, item: any): boolean {
      return (
        this.subAreasForArea(item).findIndex(
          sa =>
            this.subAreasFilter(sa.name, search, sa) ||
            this.subAreasFilter(sa.description, search, sa)
        ) !== -1
      );
    },
    subAreasFilter(value: any, search: string | null, item: any): boolean {
      if (value == null || search == null || typeof value === "boolean") return false;

      value = value.toLocaleLowerCase();
      let searchArray = search.split(" ").map(x => x.toLocaleLowerCase());

      // If any search terms are NOT found in the value, return false
      return !(searchArray.findIndex(x => value.indexOf(x) === -1) !== -1);
    },

    // *** AREAS ***
    flipAreaSelected(item: AreaWithSelected) {
      item.selected = !item.selected;
      if (item.selected) {
        this.selectSubAreasForArea(item);
      } else {
        this.deselectSubAreasForArea(item);
      }
    },

    flipSearchedAreasSelected() {
      let selected = !this.allSearchedAreasSelected;
      for (let area of this.searchedAreas) {
        if (area.selected !== selected) {
          area.selected = selected;
          if (selected) {
            this.selectSubAreasForArea(area);
          } else {
            this.deselectSubAreasForArea(area);
          }
        }
      }
    },

    // *** SUB AREAS ***
    subAreasForArea(item: AreaWithSelected): Array<SubAreaWithSelected> {
      if (!item || !this.subAreas?.length) return [];
      return this.subAreas.filter(x => x.parentLocationID == item.id);
    },
    selectSubAreasForArea(item: AreaWithSelected) {
      for (let subArea of this.subAreasForArea(item)) {
        if (!subArea.selected) subArea.selected = true;
      }
    },
    deselectSubAreasForArea(item: AreaWithSelected) {
      for (let subArea of this.subAreasForArea(item)) {
        if (subArea.selected) subArea.selected = false;
      }
    },
    flipSubAreaSelected(item: SubAreaWithSelected) {
      item.selected = !item.selected;
    },

    flipSearchedSubAreasSelected() {
      let selected = !this.allSearchedSubAreasSelected;
      for (let subArea of this.searchedSubAreas) {
        if (subArea.selected !== selected) {
          subArea.selected = selected;
        }
      }
    }
  }
});

