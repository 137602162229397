import FDVue from "@fd/lib/vue";
import { PropValidator } from "vue/types/options";

import WorkOrderDetailAlertComponent, {
  WorkOrderAlertDetails,
  WalkdownAlertDetails
} from "./WorkOrderDetailAlert.vue";
import WorkOrderRequestDetailsComponent, {
  WorkOrderRequestDetails
} from "./WorkOrderRequestDetails.vue";
import WorkOrderLocationDetailsComponent, {
  WorkOrderLocationDetails
} from "./WorkOrderLocationDetails.vue";
import WorkOrderNotesComponent, { WorkOrderWithNotes } from "./WorkOrderNotes.vue";
import WorkOrderScopeDetailsComponent, { WorkOrderScopeDetails } from "./WorkOrderScopeDetails.vue";
import WalkdownWorkOrderDetailsComponent, {
  WalkdownWorkOrderDetails,
  WalkdownDetails
} from "./WorkOrderWalkdownDetails.vue";

export type WorkOrderDetailRecord = WorkOrderAlertDetails &
  WorkOrderRequestDetails &
  WorkOrderLocationDetails &
  WorkOrderWithNotes &
  WorkOrderScopeDetails &
  WalkdownWorkOrderDetails;
export type WalkdownDetailRecord = WalkdownDetails & WalkdownAlertDetails;

export default FDVue.extend({
  name: "fd-work-order-details",

  components: {
    "fd-work-order-detail-alert": WorkOrderDetailAlertComponent,
    "fd-work-order-request-details": WorkOrderRequestDetailsComponent,
    "fd-work-order-location-details": WorkOrderLocationDetailsComponent,
    "fd-work-order-notes": WorkOrderNotesComponent,
    "fd-work-order-scope-details": WorkOrderScopeDetailsComponent,
    "fd-work-order-walkdown-details": WalkdownWorkOrderDetailsComponent
  },

  props: {
    workOrder: { type: [Object] } as PropValidator<WorkOrderDetailRecord>,
    walkdown: { type: [Object] } as PropValidator<WalkdownDetailRecord>,
    showEditControls: { type: Boolean, default: false },
    editControlsDisabled: { type: Boolean, default: false },
    hideScopeValues: { type: Boolean, default: false },
    cy: { type: String, default: "fd-work-order-details" }
  }
});

