import { mapMutations, mapActions } from "vuex";
import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { WorkCategory } from "../services";
import rules from "@fd/lib/vue/rules";

type WorkCategoryWithDetails = WorkCategory & { archived: boolean };

export default FDVue.extend({
  name: "sp-work-category-existing",
  mixins: [serviceErrorHandling, rules],
  data: function() {
    return {
      saving: false,
      slidein: false,
      workCategory: {} as WorkCategoryWithDetails
    };
  },
  computed: {},
  watch: {
    workCategory(newValue) {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/buildsheetsetup") {
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        this.notifyNewBreadcrumb({
          text: this.$t("build-sheet-setup.title"),
          to: "/buildsheetsetup",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      this.notifyNewBreadcrumb({
        text: this.workCategory.name,
        to: `/buildsheetsetup/workcategories/${this.$route.params.id}`,
        history: [
          {
            text: this.$t("build-sheet-setup.tabs.paint.work-categories.title"),
            disabled: true
          }
        ]
      });
    }
  },
  methods: {
    backButtonClicked() {
      this.close();
    },
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.workCategory.archived) {
          this.workCategory.archivedDate = null;
        } else if (this.workCategory.archived && !this.workCategory.archivedDate) {
          this.workCategory.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateWorkCategory({
          ...this.workCategory,
          id: this.$route.params.id
        });

        if (closeOnComplete) {
          this.close();
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteWorkCategory({
          id: this.$route.params.id,
          name: this.workCategory.name
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.close();
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.close();
    },
    close() {
      this.$router.push(`/buildsheetsetup/`);
    },
    ...mapMutations({
      setWorkCategory: "SET_WORK_CATEGORY",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadWorkCategory: "LOAD_WORK_CATEGORY",
      updateWorkCategory: "UPDATE_WORK_CATEGORY",
      deleteWorkCategory: "DELETE_WORK_CATEGORY"
    })
  },
  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/buildsheetsetup") {
      this.notifyNewBreadcrumb({
        text: this.$t("build-sheet-setup.title"),
        to: "/buildsheetsetup",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }

    this.notifyNewBreadcrumb({
      text: this.$t("loading-dot-dot-dot"),
      disabled: true,
      history: [
        {
          text: this.$t("build-sheet-setup.tabs.paint.work-categories.title"),
          disabled: true
        }
      ]
    });

    this.processing = true;
    try {
      await this.loadWorkCategory(this.$route.params.id);
      var workCategory = this.$store.state.workCategories.fullList.find(
        (x: WorkCategory) => x.id == this.$route.params.id
      );
      this.workCategory = {
        ...workCategory,
        archived: !!workCategory.archivedDate
      } as WorkCategoryWithDetails;
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

