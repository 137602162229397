import FDVue from "..";
import { mapMutations } from "vuex";
import errorHandling from "../mixins/errorHandling";
import { FDColumnDirective, FDRowNavigateDirective } from "../utility/dataTable";
import { addDaysToDate, addMonthsToDate } from "../../client-util/datetime";

/**
 * Provides basic logic for an item list table.
 *
 * Update the following to configure your specific screen:
 * - data.listRouteUrl
 * - data.title
 * - computed.items
 */
export default FDVue.extend({
  mixins: [errorHandling],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-add-button": () => import("./AddButton.vue")
  },

  data: () => ({
    listRouteUrl: "",
    title: "",
    defaultSearchStringForFiltering: "",
    defaultTagsForFiltering: [],
    defaultContractorsForFiltering: [],
    defaultStatusesForFiltering: [],
    suppressInitialDataLoad: false,
    archivedLoading: false
  }),

  computed: {
    items(): any[] {
      return [];
    },
    /**
     * Final list of items displayed in the table.
     */
    displayedItems(): any[] {
      return this.filterItems(this.items);
    },
    // The following is used to define the breadcrumbs navigation for the view.
    breadcrumbs() {
      return [
        {
          text: this.title,
          disabled: true
        }
      ];
    },
    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    showArchived: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFiltering;
      },
      async set(val) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING", val);
        this.processing = true;
        this.archivedLoading = true;
        await this.refreshItemList();
        this.processing = false;
        this.archivedLoading = false;
      }
    },

    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = addMonthsToDate(date, -2);
      return minDate;
    },

    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = addMonthsToDate(date, 2);
      return maxDate;
    },

    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          this.archivedLoading = true;
          try {
            await this.refreshItemList();
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
            this.archivedLoading = false;
          }
        } else this.showArchivedToDate = null;
      }
    },

    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },

    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    }
  },

  methods: {
    async refreshItemList() {},
    async deleteItem(item: any) {},
    async deleteTableItem(item: any) {
      await this.deleteItem(item);
    },
    filterItems(items: any[]) {
      return this.items;
    },

    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    })
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = addDaysToDate(null, 0);
    await this.setFilteringContext({
      context: this.title,
      parentalContext: null,
      searchStringForFiltering: this.defaultSearchStringForFiltering,
      tagsForFiltering: this.defaultTagsForFiltering,
      contractorsForFiltering: this.defaultContractorsForFiltering,
      statusesForFiltering: this.defaultStatusesForFiltering,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate
    });
    this.notifyNewBreadcrumb({
      text: this.title,
      to: "/" + this.listRouteUrl,
      resetHistory: true
    });

    if (!this.suppressInitialDataLoad) this.refreshItemList();
  }
});

