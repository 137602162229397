var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-container",
        { staticClass: "d-none" },
        [
          _c("sp-scaffold-locations-popup", {
            ref: "currentpopup",
            attrs: { scaffolds: _vm.selectedScaffolds }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "pa-0", attrs: { cols: "12" } },
        [
          _c("fp-map", {
            staticClass: "sp-scaffold-locations-map",
            attrs: {
              center: _vm.environmentLocation,
              locations: _vm.locations,
              popupHtmlGenerator: _vm.getLocationHtml,
              height: _vm.height,
              zoom: _vm.zoom,
              "min-zoom": _vm.minZoom,
              "max-zoom": _vm.maxZoom
            },
            on: {
              "click:marker": _vm.locationSelected,
              "close:marker": _vm.locationDeselected
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }