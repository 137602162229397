var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "600px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-disable-instead-top-color-processing"
                  : "dialog-disable-instead-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [_vm._v(_vm._s(_vm.$t("reauthenticate.dialog-title")))]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-form",
            { ref: "form", on: { submit: _vm.signin } },
            [
              _vm.showPasswordEntry
                ? _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { staticClass: "mx-2" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "align-center justify-space-between",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "1.0em" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "reauthenticate.dialog-explanation"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "align-center justify-space-between pb-0",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-weight": "Bold",
                                    "font-size": "1.0em"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.username))]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "align-center justify-space-between pt-0",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("v-text-field", {
                                ref: "passwordinput",
                                staticClass: "mt-3",
                                attrs: {
                                  label: _vm.$t("login.password-label"),
                                  "data-cy": "password",
                                  "append-icon": _vm.showPassword
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  type: _vm.showPassword ? "text" : "password",
                                  autocomplete: "current-password",
                                  outlined: "",
                                  disabled: _vm.processing,
                                  rules: [_vm.rules.required]
                                },
                                on: {
                                  "click:append": function($event) {
                                    _vm.showPassword = !_vm.showPassword
                                  }
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showAccessCodeEntry
                ? _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { staticClass: "mx-2" },
                        [
                          _vm.accessCodeSentToAnyEmailAddresses
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "align-center justify-space-between",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _vm.accessCodeSentToExaclyOneEmailAddress
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: { "font-size": "1.0em" }
                                        },
                                        [
                                          _vm.accessCodeIsOriginal
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "reauthenticate.access-code-inline-email-statement"
                                                    )
                                                  )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.accessCodeIsResent
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "reauthenticate.access-code-inline-email-resent-statement"
                                                    )
                                                  )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "login-call-out-text-style"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fd-restrict-contact-text-width"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        !!_vm.accessCodeChallenge &&
                                                          _vm
                                                            .accessCodeChallenge
                                                            .sentToEmailAddress
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticStyle: { "font-size": "1.0em" }
                                        },
                                        [
                                          _vm.accessCodeIsOriginal
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "reauthenticate.access-code-inline-multiple-emails-statement"
                                                    )
                                                  )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.accessCodeIsResent
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "reauthenticate.access-code-inline-multiple-emails-resent-statement"
                                                    )
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.accessCodeSentToAnyPhoneNumbers
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "align-center justify-space-between",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _vm.accessCodeSentToExaclyOnePhoneNumber
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: { "font-size": "1.0em" }
                                        },
                                        [
                                          !_vm.accessCodeSentToAnyEmailAddresses &&
                                          _vm.accessCodeIsOriginal
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "reauthenticate.access-code-inline-phone-statement"
                                                    )
                                                  )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !!_vm.accessCodeSentToAnyEmailAddresses &&
                                          _vm.accessCodeIsOriginal
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "reauthenticate.access-code-inline-phone-same-statement"
                                                    )
                                                  )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.accessCodeIsResent
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "reauthenticate.access-code-inline-phone-resent-statement"
                                                    )
                                                  )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "login-call-out-text-style"
                                            },
                                            [
                                              _vm._v(
                                                "***-***-*" +
                                                  _vm._s(
                                                    !!_vm.accessCodeChallenge &&
                                                      _vm.accessCodeChallenge
                                                        .sentToPhoneNumberTail
                                                  )
                                              )
                                            ]
                                          ),
                                          _vm._v(".\n            ")
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticStyle: { "font-size": "1.0em" }
                                        },
                                        [
                                          !_vm.accessCodeSentToAnyEmailAddresses &&
                                          _vm.accessCodeIsOriginal
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "reauthenticate.access-code-inline-multiple-phones-statement"
                                                    )
                                                  )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !!_vm.accessCodeSentToAnyEmailAddresses &&
                                          _vm.accessCodeIsOriginal
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "reauthenticate.access-code-inline-multiple-phones-same-statement"
                                                    )
                                                  )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.accessCodeIsResent
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "reauthenticate.access-code-inline-multiple-phones-resent-statement"
                                                    )
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "align-center justify-space-between",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("fd-code-entry", {
                                ref: "accesscodeentry",
                                class: ["pt-5"],
                                staticStyle: { "margin-left": "-6px" },
                                attrs: {
                                  disabled: _vm.processing,
                                  value: _vm.accesscode,
                                  autocomplete: "one-time-code"
                                },
                                on: { codeEntered: _vm.codeEntered }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.inlineMessage.message
                ? _c(
                    "v-row",
                    { staticClass: "mx-0", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.inlineMessage.message
                            ? _c(
                                "v-alert",
                                { attrs: { type: _vm.inlineMessage.type } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { "white-space": "pre-line" }
                                    },
                                    [_vm._v(_vm._s(_vm.inlineMessage.message))]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.showUseAccessCodeButton
                    ? _c(
                        "v-btn",
                        {
                          attrs: { outlined: "", disabled: _vm.processing },
                          on: { click: _vm.sendAccessCode }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("reauthenticate.use-access-code"))
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showUsePasswordButton
                    ? _c(
                        "v-btn",
                        {
                          attrs: { outlined: "", disabled: _vm.processing },
                          on: { click: _vm.goBackToPassword }
                        },
                        [_vm._v(_vm._s(_vm.$t("reauthenticate.use-password")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showGoBackToPasswordButton
                    ? _c(
                        "v-btn",
                        {
                          attrs: { outlined: "", disabled: _vm.processing },
                          on: { click: _vm.goBackToPassword }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("reauthenticate.go-back-to-password"))
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showAccessCodeEntry || _vm.allowAccessCodeSignIn
                    ? _c("div", {
                        staticClass: "ml-4 mr-3 fd-button-context-separator",
                        domProps: { textContent: _vm._s("|") }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.quitBackToLogin } },
                    [_vm._v(_vm._s(_vm.$t("common.quit")))]
                  ),
                  _vm._v(" "),
                  _vm.showPasswordEntry
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.processing,
                            disabled: _vm.processing
                          },
                          on: { click: _vm.signInWithPassword }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.login")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showAccessCodeEntry
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.processing,
                            disabled: _vm.processing
                          },
                          on: { click: _vm.resendAccessCode }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("reauthenticate.resend-access-code"))
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }