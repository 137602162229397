var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-data-table", {
    directives: [
      {
        name: "fd-column",
        rawName: "v-fd-column:dateTimeString.hidden",
        value: "",
        expression: "''",
        arg: "dateTimeString",
        modifiers: { hidden: true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:created",
        value: _vm.$t("common.date"),
        expression: "$t('common.date')",
        arg: "created"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:creatorName.hide-when-extra-small",
        value: _vm.$t("scheduler.estimates.generated-by-column-label"),
        expression:
          "\n    $t('scheduler.estimates.generated-by-column-label')\n  ",
        arg: "creatorName",
        modifiers: { "hide-when-extra-small": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedTotalMobilizationMinutes",
        value: _vm.$t(
          "scheduler.estimates.components-panel.mobilization-label"
        ),
        expression:
          "\n    $t('scheduler.estimates.components-panel.mobilization-label')\n  ",
        arg: "estimatedTotalMobilizationMinutes"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedTotalErectMinutes",
        value: _vm.$t("scheduler.estimates.components-panel.erect-label"),
        expression: "$t('scheduler.estimates.components-panel.erect-label')",
        arg: "estimatedTotalErectMinutes"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedTotalDismantleMinutes",
        value: _vm.$t("scheduler.estimates.components-panel.dismantle-label"),
        expression:
          "\n    $t('scheduler.estimates.components-panel.dismantle-label')\n  ",
        arg: "estimatedTotalDismantleMinutes"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedTotalDemobilizationMinutes",
        value: _vm.$t(
          "scheduler.estimates.components-panel.demobilization-label"
        ),
        expression:
          "\n    $t('scheduler.estimates.components-panel.demobilization-label')\n  ",
        arg: "estimatedTotalDemobilizationMinutes"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedTotalHoardingMinutes",
        value: _vm.$t("scheduler.estimates.components-panel.hoarding-label"),
        expression:
          "\n    $t('scheduler.estimates.components-panel.hoarding-label')\n  ",
        arg: "estimatedTotalHoardingMinutes"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedTotalModifyMinutes",
        value: _vm.$t("scheduler.estimates.components-panel.modify-label"),
        expression:
          "\n    $t('scheduler.estimates.components-panel.modify-label')\n  ",
        arg: "estimatedTotalModifyMinutes"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedTotalTime",
        value: _vm.$t("scheduler.estimates.components-panel.total-label"),
        expression: "$t('scheduler.estimates.components-panel.total-label')",
        arg: "estimatedTotalTime"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedTotalWeight",
        value: _vm.$t("scheduler.estimates.components-panel.weight-label"),
        expression: "$t('scheduler.estimates.components-panel.weight-label')",
        arg: "estimatedTotalWeight"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedTotalPartCount",
        value: _vm.$t("scheduler.estimates.components-panel.pieces-label"),
        expression: "$t('scheduler.estimates.components-panel.pieces-label')",
        arg: "estimatedTotalPartCount"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedErectMPP",
        value: _vm.$t("scheduler.estimates.components-panel.erect-mpp-label"),
        expression:
          "$t('scheduler.estimates.components-panel.erect-mpp-label')",
        arg: "estimatedErectMPP"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:estimatedDismantleMPP",
        value: _vm.$t(
          "scheduler.estimates.components-panel.dismantle-mpp-label"
        ),
        expression:
          "\n    $t('scheduler.estimates.components-panel.dismantle-mpp-label')\n  ",
        arg: "estimatedDismantleMPP"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:action.no-sort",
        arg: "action",
        modifiers: { "no-sort": true }
      }
    ],
    attrs: {
      items: _vm.estimates,
      search: _vm.tablesearchestimates,
      loading: _vm.loading,
      "loading-text": _vm.$t("common.table-loading-message"),
      "sort-by": "created",
      "sort-desc": true,
      "mobile-breakpoint": "0",
      "show-expand": ""
    },
    scopedSlots: _vm._u([
      {
        key: "item.estimatedTotalMobilizationMinutes",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                value: _vm.getTimeDisplay(
                  item.estimatedTotalMobilizationMinutes
                ),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedTotalErectMinutes",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                value: _vm.getTimeDisplay(item.estimatedTotalErectMinutes),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedTotalDismantleMinutes",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                value: _vm.getTimeDisplay(item.estimatedTotalDismantleMinutes),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedTotalDemobilizationMinutes",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                value: _vm.getTimeDisplay(
                  item.estimatedTotalDemobilizationMinutes
                ),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedTotalHoardingMinutes",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                value: _vm.getTimeDisplay(item.estimatedTotalHoardingMinutes),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedTotalModifyMinutes",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                value: _vm.getTimeDisplay(item.estimatedTotalModifyMinutes),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedTotalTime",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                value: _vm.getTimeDisplay(item.estimatedTotalTime),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedTotalWeight",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                value: _vm.$format.number(item.estimatedTotalWeight, 0),
                "no-value-text": "0"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedTotalPartCount",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                value: _vm.$format.number(item.estimatedTotalPartCount, 0),
                "no-value-text": "0"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedErectMPP",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                value: _vm.$format.number(item.estimatedErectMPP),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.estimatedDismantleMPP",
        fn: function({ item }) {
          return [
            _c("fd-value-display", {
              attrs: {
                value: _vm.$format.number(item.estimatedDismantleMPP),
                "no-value-text": "0.00"
              }
            })
          ]
        }
      },
      {
        key: "item.action",
        fn: function({ item }) {
          return [
            _c(
              "div",
              { staticClass: "table-1-action-column-min-width" },
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: {
                      disabled: !_vm.$vuetify.breakpoint.lgAndUp || _vm.loading,
                      left: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      icon: "",
                                      disabled:
                                        _vm.loading ||
                                        !_vm.canDownloadEstimateExcelFile(item)
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.downloadEstimateExcelFile(
                                          item.id
                                        )
                                      }
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(
                                      "\n              mdi-download\n            "
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "scheduler.estimates.estimates-panel.download-template"
                          )
                        )
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ]
        }
      },
      {
        key: "item.created",
        fn: function({ item }) {
          return [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$vuetify.breakpoint.smAndUp
                    ? item.dateTimeString
                    : item.dateString
                ) +
                "\n  "
            )
          ]
        }
      },
      {
        key: "expanded-item",
        fn: function({ item }) {
          return [
            _c(
              "td",
              {
                staticClass: "fd-embedded-table-container",
                attrs: { colspan: "99" }
              },
              [
                !!item.components.length
                  ? _c("v-subheader", { staticClass: "white--text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "scheduler.estimates.estimates-panel.components-heading"
                          )
                        )
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("sp-work-order-estimate-summary-table", {
                  attrs: {
                    "estimate-components": item.components,
                    summaryPanelTimeUnitDivider:
                      _vm.summaryPanelTimeUnitDivider,
                    loading: false,
                    "hide-actions": true,
                    "hide-unchanged": false,
                    "hide-total-row": true
                  }
                })
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }