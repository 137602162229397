import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { mapActions, mapMutations } from "vuex";
import {
  TransferWithDetails,
  transferService,
  CountSheetGroupWithParts,
  Part,
  TransferDirection,
  Yard,
  yardService,
  TransferPartDetails,
  TransferType
} from "../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import {
  CountSheetGroupPartFromTransferPart,
  CountSheetGroupWithSortedParts,
  PartWithCounts,
  SummarizeModifiedPartsInGroups
} from "../dataMixins/countSheet";
import { SortCountSheetGroups, SortParts } from "../dataMixins/countSheetGroupSorting";
import userAccess from "@fd/current/client/dataMixins/userAccess";
import rules from "@fd/lib/vue/rules";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import fileHandling, {
  FileData,
  componentsFromFileName,
  confirmUniqueName,
  isFilePhoto,
  isFilePreviewable
} from "@fd/lib/vue/mixins/fileHandling";
import { showTextPromptDialog } from "../../../common/client/views/components/TextPromptDialog.vue";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import { FDColumnDirective } from "../../../lib/vue/utility/dataTable";

type FormattedTransferWithDetails = TransferWithDetails & {
  formattedTransferDate: string;
};
export default FDVue.extend({
  name: "fd-transfer-existing",
  mixins: [serviceErrorHandling, userAccess, rules, tabbedView, fileHandling],

  directives: {
    fdColumn: FDColumnDirective
  },

  components: {
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue")
  },

  data: function() {
    return {
      slidein: false,
      openPanels: [0] as number[],

      firstTabKey: `0`,
      detailsTab: {
        tabname: this.$t("transfers.existing.tabs.details"),
        key: "0",
        visible: true
      } as Tab,
      photosTab: {
        tabname: this.$t("transfers.existing.tabs.photos"),
        key: "1",
        visible: true
      } as Tab,

      saving: false,
      unlocked: false,

      countSheetGroups: [] as CountSheetGroupWithSortedParts[],
      transfer: {
        currentUserPermissions: {}
      } as FormattedTransferWithDetails,
      /*** TO/FROM YARD ***/
      allYards: [] as Yard[],

      /*** FILES ***/
      touchedFileName: "",
      tablesearchfiles: "",
      allFiles: [] as FileData[],

      /*** IMAGE EDIT ****/
      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as FileData | undefined
    };
  },

  computed: {
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.photosTab] as Tab[];
    },
    transferIsCountSheet(): boolean {
      return this.transfer.transferTypeID == TransferType.CountSheet;
    },
    transferIsReversal(): boolean {
      return this.transfer.transferTypeID == TransferType.Reversal;
    },
    showCountSheetTransferFullyLockedExplanation(): boolean {
      return (
        this.transferIsCountSheet &&
        !this.canReverseTransfer &&
        !this.canUnlockTransfer &&
        this.transfer.currentUserPermissions.canOverrideTransferValues
      );
    },
    showCountSheetTransferEditLockedExplanation(): boolean {
      return (
        this.transferIsCountSheet &&
        !!this.canReverseTransfer &&
        !this.canUnlockTransfer &&
        this.transfer.currentUserPermissions.canOverrideTransferValues
      );
    },
    showReversalTransferLockedExplanation(): boolean {
      return (
        this.transferIsReversal && this.transfer.currentUserPermissions.canOverrideTransferValues
      );
    },
    showAutomaticReturnTransferLockedExplanation(): boolean {
      return (
        !this.transferIsCountSheet &&
        !!this.transfer.isAutomaticReturnToSource &&
        this.transfer.currentUserPermissions.canOverrideTransferValues
      );
    },
    canReverseTransfer(): boolean {
      if (!this.transfer.currentUserPermissions.canOverrideTransferValues) return false;

      return (
        this.transfer.transferTypeID != TransferType.Reversal &&
        // Countsheet transfers can be reversed if is only a transfer
        (this.transfer.transferTypeID != TransferType.CountSheet ||
          (!this.transfer.fromScaffoldID && !this.transfer.toScaffoldID)) &&
        !this.transfer.reversedByTransferID &&
        !this.transfer.isAutomaticReturnToSource
      );
    },
    canUnlockTransfer(): boolean {
      if (!this.transfer.currentUserPermissions.canOverrideTransferValues) return false;

      return (
        this.transfer.transferTypeID != TransferType.CountSheet &&
        this.transfer.transferTypeID != TransferType.Reversal &&
        !this.transfer.reversedByTransferID &&
        !this.transfer.isAutomaticReturnToSource
      );
    },
    fromYards(): (Yard & { disabled: boolean })[] {
      return this.allYards.map(x => ({
        ...x,
        disabled: x.id == this.transfer.toYardID
      }));
    },
    toYards(): (Yard & { disabled: boolean })[] {
      return this.allYards.map(x => ({
        ...x,
        disabled: x.id == this.transfer.fromYardID
      }));
    },
    directionIsScaffoldDelivery(): boolean {
      return this.transfer.direction == TransferDirection.ScaffoldDelivery;
    },
    directionIsScaffoldReturn(): boolean {
      return this.transfer.direction == TransferDirection.ScaffoldReturn;
    },
    directionIsYardTransfer(): boolean {
      return this.transfer.direction == TransferDirection.YardTransfer;
    },
    directionIsSupplierRequest(): boolean {
      return this.transfer.direction == TransferDirection.SupplierRequest;
    },
    directionIsSupplierReturn(): boolean {
      return this.transfer.direction == TransferDirection.SupplierReturn;
    },
    visibleCountSheetGroups(): CountSheetGroupWithSortedParts[] {
      if (this.unlocked) return this.countSheetGroups;
      // if (!!this.transfer?.currentUserPermissions?.canOverrideTransferValues)
      //   return this.countSheetGroups;

      return this.countSheetGroups
        .map(x => {
          return {
            ...x,
            sortedParts: x.sortedParts.filter(x => !!x.count && x.count > 0)
          };
        })
        .filter(x => x.sortedParts.length > 0);
    },
    partsForSummary(): PartWithCounts[] {
      return SummarizeModifiedPartsInGroups(this.countSheetGroups);
    },
    // nonPhotoAttachments(): Attachment[] {
    //   let attachments = [] as Attachment[];

    //   this.allFiles.forEach(file => {
    //     attachments.push({
    //       type: "file",
    //       name: file.name,
    //       isPhoto: file.isPreviewable ?? false,
    //       isPreviewable: file.isPreviewable ?? false,
    //       canOpenInNew: canOpenFileInNewWindow(file.name),
    //       file: file
    //     });
    //   });

    //   this.externalLinks.forEach(link => {
    //     attachments.push({
    //       type: "link",
    //       name: link.name!,
    //       isPhoto: false,
    //       isPreviewable: false,
    //       canOpenInNew: true,
    //       link: link
    //     });
    //   });

    //   return attachments.filter(x => !x.isPhoto);
    // },
    photoFiles(): FileData[] {
      return this.allFiles.filter(x => x.isPhoto);
    }
  },

  watch: {
    transfer() {
      console.log(`transfer - breadcrumb: ${this.$store.state.lastBreadcrumbs[0]?.to}`);
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") == "/materialorders") {
        console.log(`transfer received.  Navigation from material order.`);
      } else if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/transfers") {
        this.notifyNewBreadcrumb({
          text: this.$t("transfers.list.title"),
          to: "/transfers",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      let directionText = this.$t(`transfers.direction.${this.transfer.direction}`);
      // if (this.transfer.direction == TransferDirection.ScaffoldDelivery) {
      //   directionText = this.$t("transfers.direction.scaffolddelivery");
      // } else if (this.transfer.direction == TransferDirection.ScaffoldReturn) {
      //   directionText = this.$t("transfers.direction.scaffoldreturn");
      // }
      let summary = `${directionText} #${this.transfer.internalNumber}`;
      this.notifyNewBreadcrumb({
        text: summary,
        to: `/transfers/${this.$route.params.id}`
      });
    }
  },

  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB"
    }),
    ...mapActions({
      loadCountSheetGroups: "LOAD_COUNT_SHEET_GROUPS"
    }),
    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    async save(closeOnComplete: boolean) {
      if (!this.unlocked) return;

      // First reset the inline message if there are any.
      this.inlineMessage.message = null;

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        let parts = this.partsForSummary
          .filter(x => !!x.count && x.count > 0)
          .map(
            x =>
              ({
                partID: x.id,
                count: +x.count!,
                currentAssignedCount: +x.assigned
              } as TransferPartDetails)
          );

        this.partsForSummary.forEach(x => {
          let part = this.transfer.parts?.find(p => p.partID == x.id);
          if (!part) return;
          part.count = +x.count! ?? 0;
        });
        await transferService.overrideTransferDetails(
          this.transfer.id!,
          undefined, // this.transfer.fromYardID,
          undefined, // this.transfer.toYardID,
          this.transfer.transferDate,
          this.transfer.transactionNumber,
          parts
        );

        let directionText = this.$t(`transfers.direction.${this.transfer.direction}`);
        // let directionText = this.$t("transfers.direction.yardtransfer");
        // if (this.transfer.direction == TransferDirection.ScaffoldDelivery) {
        //   directionText = this.$t("transfers.direction.scaffolddelivery");
        // } else if (this.transfer.direction == TransferDirection.ScaffoldReturn) {
        //   directionText = this.$t("transfers.direction.scaffoldreturn");
        // }
        var snackbarPayload = {
          text: this.$t("transfers.existing.save-success", [
            directionText,
            this.transfer.internalNumber
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        if (closeOnComplete) {
          this.$router.push("/transfers");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    async reverseTransfer() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await transferService.reverseTransfer(this.transfer.id!);
        this.loadTransferDetails();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.back();
    },

    async loadTransferDetails() {
      let transfer = await transferService.getByID(this.$route.params.id);
      this.transfer = {
        ...transfer,
        formattedTransferDate: DateUtil.stripTimeFromLocalizedDateTime(transfer.transferDate)
      } as FormattedTransferWithDetails;
    },

    async loadScreenData() {
      await this.loadCountSheetGroups({
        forcedArchivedState: false,
        archivedFromDate: null,
        archivedToDate: null
      });

      this.allYards = (await yardService.getAll(false, null, null))
        .filter(x => !x.isSystemYard)
        .sort((a, b) => {
          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;
          else return 0;
        });

      await this.loadTransferDetails();

      let countSheetGroups = SortCountSheetGroups(
        (this.$store.state.countSheetGroups.fullList as CountSheetGroupWithParts[])
          .filter(group => !!group.parts?.length)
          .map(
            group =>
              ({
                ...group,
                sortedParts: SortParts(
                  group.parts?.map(part =>
                    CountSheetGroupPartFromTransferPart(
                      part,
                      this.transfer.parts,
                      this.transfer.direction
                    )
                  )
                )
              } as CountSheetGroupWithSortedParts)
          )
      );

      let ungroupedPartsWithDetails = this.transfer.parts?.filter(x => !x.countSheetGroupID);
      if (!!ungroupedPartsWithDetails?.length) {
        let ungroupedParts = ungroupedPartsWithDetails.map(
          x =>
            ({
              id: x.partID,
              name: x.name,
              description: x.description,
              publicID: x.publicID
            } as Part)
        ) as Part[];
        let ungroupedGroup = {
          name: `${this.$t("common.other")}`,
          order: 999,
          parts: ungroupedParts
        } as CountSheetGroupWithParts;

        let ungroupedGroupWithSortedParts = {
          ...ungroupedGroup,
          sortedParts: SortParts(
            ungroupedGroup.parts?.map(part =>
              CountSheetGroupPartFromTransferPart(
                part,
                this.transfer.parts,
                this.transfer.direction
              )
            )
          )
        } as CountSheetGroupWithSortedParts;

        countSheetGroups.push(ungroupedGroupWithSortedParts);
      }

      countSheetGroups.forEach(x => (x.parts = SortParts(x.parts)));

      this.countSheetGroups = countSheetGroups;

      await this.loadFiles();
    },

    toggleUnlocked() {
      this.unlocked = !this.unlocked;
    },

    // *** SCREEN NAVIGATION ***
    partCountValueChanged(part: PartWithCounts) {
      let addCount = 0;
      let removeCount = 0;
      let count = !!part.count ? +part.count : 0;
      part.overridden = part.overridden || count != (part.originalCount ?? 0);
      if (this.transfer.direction == TransferDirection.ScaffoldReturn) {
        removeCount = count;
      } else {
        addCount = count;
      }
      part.total = part.assigned + addCount - removeCount;
    },
    fieldRefForPart(fieldName: string, part: PartWithCounts) {
      let overrideRef = part.overridden == true ? "override" : "";

      let id = part.id!.replace("-", "").replace("-", "");
      return `${fieldName}${overrideRef}_${id}`;
    },
    focusFieldForVisibleItemAtIndex(fieldName: string, groupNumber: number, partIndex: number) {
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.order == groupNumber
      );
      if (!group) return;
      let sortedGroupParts = group.sortedParts;
      if (!sortedGroupParts.length) return;

      let groupPanelNumber = this.countSheetGroups.indexOf(group!) + 1;
      if (!this.openPanels.includes(groupPanelNumber)) {
        this.openPanels.push(groupPanelNumber);
        let self = this;

        this.$nextTick(() => {
          setTimeout(() => {
            self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, partIndex);
          }, 500);
        });
        return;
      }

      if (partIndex < 0) partIndex = 0;
      if (partIndex >= sortedGroupParts.length) partIndex = sortedGroupParts.length - 1;
      let item = sortedGroupParts[partIndex];

      let itemFieldRef = this.fieldRefForPart(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    selectPreviousField(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      let groupID = item.countSheetGroupID;
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.id == groupID
      );
      if (!group) return;

      let groupNumber = group?.order ?? 0;
      let sortedGroupParts = group?.sortedParts ?? [];
      // console.log(`  current group: ${group?.name}, parts: ${sortedGroupParts.length}`);
      if (!sortedGroupParts.length) return;

      let currentItemIndex = sortedGroupParts.indexOf(item);
      if (currentItemIndex <= 0) {
        if (groupNumber <= 1) return;
        groupNumber -= 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, 999);
        });
        e.preventDefault();
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, previousIndex);
      e.preventDefault();
    },
    selectNextField(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      // console.log(`selectNextField fieldName: ${fieldName}, part: ${item.publicID}`);
      let groupID = item.countSheetGroupID;
      let group = (this.countSheetGroups as CountSheetGroupWithSortedParts[]).find(
        x => x.id == groupID
      );
      let groupNumber = group?.order ?? 0;
      let sortedGroupParts = group?.sortedParts ?? [];
      // console.log(`  current group: ${group?.name}, parts: ${sortedGroupParts.length}`);
      if (!sortedGroupParts.length) return;

      let currentItemIndex = sortedGroupParts.indexOf(item);
      if (currentItemIndex >= sortedGroupParts.length - 1) {
        groupNumber += 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, 0);
        });
        e.preventDefault();
        return;
      }
      let nextIndex = currentItemIndex + 1;
      // console.log(`  current: ${currentItemIndex}, next: ${nextIndex}`);
      this.focusFieldForVisibleItemAtIndex(fieldName, groupNumber, nextIndex);
      e.preventDefault();
    },
    enterPressed(e: KeyboardEvent, fieldName: string, item: PartWithCounts) {
      if (e.shiftKey) this.selectPreviousField(e, fieldName, item);
      else this.selectNextField(e, fieldName, item);
    },

    // *** PHOTOS & FILES ***
    async loadFiles() {
      var fileNames = await transferService.getMaterialFileList(this.transfer.id!);
      this.allFiles = fileNames.map(function(fileName) {
        return {
          name: fileName,
          isPreviewable: isFilePreviewable(fileName),
          isPhoto: isFilePhoto(fileName)
        } as FileData;
      });
    },
    async selectFile() {
      (this.$refs.addFileButton as any).click();
    },
    async selectNewFile(originalFile: File) {
      console.log(`selectNewFile: ${originalFile}`);
      var fileData = await this.optimizedFileDataForUpload(originalFile, this.allFiles);
      if (!fileData) return;

      // GIF files with animations will lose their animation during this process
      // Both due to the quality compression done above (resizing the dimensions of an animated GIF does nothing), and also going through the edit image process
      // This is OK as we shouldn't need animations for any reason
      console.log(`\t isPreviewable: ${fileData.isPreviewable}`);
      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        await this.saveNewFileData(fileData);
      }
    },
    async handleEdit(res: File, fileName: string | undefined) {
      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;
        if (!!fileName) this.newFileData.name = confirmUniqueName(fileName, this.allFiles);

        await this.saveNewFileData(this.newFileData);

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.allFiles.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.saveEditedFileData(this.editingFileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    async saveEditedFileData(fileData: FileData, originalFileName: string) {
      if (!fileData) return;

      this.processing = true;
      try {
        if (!fileData.file) {
          // If we're only renaming the file, the data may not be downloaded yet
          let fileNameToDownload = originalFileName ?? fileData.name;
          fileData.file = await transferService.downloadMaterialFile(
            this.transfer.id!,
            fileNameToDownload
          );
        }
        await transferService.uploadMaterialFile(
          this.transfer.id!,
          fileData.name,
          fileData.file as Blob
        );

        if (!!originalFileName && originalFileName != fileData.name) {
          // File has been renamed.  The file in the list has already been updated with all relevant data, but we need to delete the file with the old name
          // We don't call the delete method here because we don't care about its data, an undo, or a delete snackbar
          await transferService.deleteMaterialFile(this.transfer.id!, originalFileName);
        }
        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.update-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.update-file-success", [
              fileData.name
            ]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async saveNewFileData(fileData: FileData | undefined) {
      console.log(`saveNewFileData: ${fileData}`);
      if (!fileData) return;

      this.processing = true;
      try {
        await transferService.uploadMaterialFile(
          this.transfer.id!,
          fileData.name,
          fileData.file as Blob
        );

        this.allFiles.push(fileData);

        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.save-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.save-file-success", [
              fileData.name
            ]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.touchedFileName = fileData.name;
        // this.showPhotoTabAttachmentAlert = this.selectedTab == this.photosTab && !fileData.isPhoto;
        // this.showAttachmentTabPhotoAlert =
        //   this.selectedTab == this.attachmentsTab && fileData.isPhoto == true;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async editNameForFile(fileData: FileData) {
      let components = componentsFromFileName(fileData.name);
      let newName = await showTextPromptDialog({
        title: this.$t("attachments.edit-file-name-title"),
        label: this.$t("common.name"),
        rules: [this.rules.required],
        text: components.name
      });
      if (!!newName?.length && newName.toLowerCase() != components.name.toLowerCase()) {
        let newFileName = `${newName}.${components.extension}`;
        var originalFileName = fileData.name;
        if (newFileName.toLowerCase() == originalFileName.toLowerCase()) return;

        var uniqueFileName = confirmUniqueName(newFileName, this.allFiles);

        fileData.name = uniqueFileName;
        this.saveEditedFileData(fileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    editFile(fileData: FileData) {
      if (!fileData.isPhoto) return;

      this.editingFileData = fileData;
      this.imageName = fileData.name;
      if (!!fileData.file) {
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.editImageSource = `/services/FormidableDesigns.Services.V1.TransferService.DownloadMaterialFile?requestId=${this.transfer.id}&fileName=${fileData.name}`;
      }
    },
    async downloadFile(fileData: FileData) {
      if (!!fileData.file) {
        downloadBlob(fileData.file, fileData.name);
        return;
      }

      let fileName = fileData.name;
      this.processing = true;
      try {
        var file = await transferService.downloadMaterialFile(this.transfer.id!, fileName);
        downloadBlob(file, fileName);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async openFileInNewWindow(fileData: FileData) {
      let currentProcessing = this.processing;
      this.processing = true;
      if (!fileData.file) {
        // the data probably hasn't been downloaded yet
        fileData.file = await transferService.downloadMaterialFile(
          this.transfer.id!,
          fileData.name
        );
      }
      let url = URL.createObjectURL(fileData.file);
      window.open(url, "_blank");
      this.processing = currentProcessing;
    },
    async viewFile(fileData: FileData) {
      if (!fileData.isPreviewable) return;

      this.imageName = fileData.name;
      if (!fileData.file) {
        // Cache the file data to avoid having to download it multiple times
        var file = await transferService.downloadMaterialFile(this.transfer.id!, fileData.name);
        fileData.file = file;
      }
      if (!!fileData.file) {
        this.imageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.imageSource = `/services/FormidableDesigns.Services.V1.TransferService.DownloadMaterialFile?requestId=${this.transfer.id}&fileName=${fileData.name}`;
      }
    },
    async deleteFile(fileData: FileData) {
      this.processing = true;
      try {
        if (!fileData.file) {
          // When deleting from the table, the data probably hasn't been downloaded yet
          // So we can't do an undo unless we get the file data to re-save first
          fileData.file = await transferService.downloadMaterialFile(
            this.transfer.id!,
            fileData.name
          );
        }
        await transferService.deleteMaterialFile(this.transfer.id!, fileData.name);

        this.allFiles.splice(this.allFiles.indexOf(fileData), 1);

        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-requests.existing-scaffold-request.delete-photo-success", [
              fileData.name
            ])
          : this.$t("scaffold-requests.existing-scaffold-request.delete-file-success", [
              fileData.name
            ]);
        var snackbarPayload = {
          text: snackbarText,
          type: "info",
          undoCallback: async () => {
            await this.saveNewFileData(fileData);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  },

  created: async function() {
    var parentalContext = undefined;
    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") == "/materialorders") {
      parentalContext = "materialorder-existing";
    } else {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/transfers") {
        this.notifyNewBreadcrumb({
          text: this.$t("transfers.list.title"),
          to: "/transfers",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      parentalContext = "transfers";
    }
    this.notifyNewBreadcrumb({
      text: "",
      to: `/transfers/${this.$route.params.id}`
    });
    this.setFilteringContext({
      context: "transfers-existing",
      parentalContext: parentalContext,
      selectedTab: this.firstTabKey
    });

    this.processing = true;

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    try {
      await this.loadScreenData();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

