var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: [
        {
          "appear-nicely": _vm.show,
          "disappear-nicely": !_vm.show,
          "login-screen-floating-placement-mobile-override":
            _vm.smallishMobileDevice || _vm.smallWidthBrowser
        },
        "login-screen-floating-placement",
        "sign-in-controls-container"
      ],
      staticStyle: { opacity: "0", "pointer-events": "none" }
    },
    [
      _c(
        "v-form",
        { ref: "termsAndConditionsForm" },
        [
          _c(
            "v-row",
            { staticClass: "ml-0" },
            [
              _c(
                "v-col",
                { staticClass: "pl-0 pb-0", attrs: { cols: "10", md: "12" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "ml-0", attrs: { justify: "start" } },
                    [
                      _c(
                        "span",
                        {
                          class: {
                            "update-password-title-text-regular":
                              !_vm.smallishMobileDevice &&
                              !_vm.smallWidthBrowser,
                            "update-password-title-text-smaller":
                              _vm.smallishMobileDevice || _vm.smallWidthBrowser
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("terms.consent-form.title")))]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col", { staticClass: "grow" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("terms.consent-form.line-1")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.disabled,
                        loading: _vm.loading,
                        outlined: ""
                      },
                      on: { click: _vm.openTermsDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("terms.link-label-open")))]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-switch", {
                    attrs: {
                      "input-value": _vm.value,
                      disabled: _vm.disabled || !_vm.didOpenTermsDialog,
                      label: _vm.$t("terms.consent-form.accept-switch-label")
                    },
                    on: { change: v => _vm.$emit("input", v) }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "marketing-main-color-button-style width-100 mt-0",
                      attrs: {
                        loading: _vm.loading,
                        disabled:
                          _vm.disabled || !_vm.didOpenTermsDialog || !_vm.value,
                        depressed: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("done")
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("terms.consent-form.accept-button-title"))
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }