import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { ExternalLink } from "../../services";
import rules from "@fd/lib/vue/rules";

const ExternalLinkDialog = FDVue.extend({
  name: "fd-external-link-dialog",

  mixins: [dialogSupport, rules],

  components: {
    "fd-text-field": () => import("@fd/lib/vue/components/TextField.vue")
  },

  data: function() {
    return {
      isEditing: false,
      // Main entity
      name: "" as string | undefined,
      address: "" as string | undefined
      // name: "Scaffold AutoCad Layout" as string | undefined,
      // address: "https://autode.sk/3qXB5Qf" as string | undefined
    };
  },

  computed: {},

  methods: {
    async open(
      existingLink?: ExternalLink | null | undefined
    ): Promise<ExternalLink | null | undefined> {
      this.optOutOfErrorHandling();
      if (!!existingLink) {
        this.isEditing = true;
        this.name = existingLink.name;
        this.address = existingLink.address;
      }
      return this.showDialog!();
    },
    onSubmit(e: Event) {
      this.saveDialog();
      e.preventDefault();
    },
    saveDialog() {
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      let newLink = {
        name: this.name,
        address: this.address
      } as ExternalLink;
      this.closeDialog!(newLink);
    }
  }
});

export default ExternalLinkDialog;

export async function openExternalLinkDetails(
  existingLink?: ExternalLink | null | undefined
): Promise<ExternalLink | null | undefined> {
  let dialog = createDialog(ExternalLinkDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(existingLink);
}

