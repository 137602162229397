import { buildDismantleRatioService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: buildDismantleRatioService,
  singularStoreName: "BUILD_DISMANTLE_RATIO",
  pluralStoreName: "BUILD_DISMANTLE_RATIOS",
  localizationPrefix: "modifiers.build-dismantle-ratio",
  consumerRelatedIDProperty: {
    name: "buildDismantleRatioID",
    type: "string"
  }
});

export default storeModule;

