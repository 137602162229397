var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "1440px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { ref: "content", attrs: { "data-cy": "activeworkforscaffolddialog" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  !!_vm.scaffoldNumber && Number(_vm.scaffoldNumber) > 0
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$vuetify.breakpoint.mdAndUp
                              ? _vm.$t("scaffolds.work-summary.title", [
                                  _vm.scaffoldNumber
                                ])
                              : _vm.$t("scaffolds.work-summary.title-short", [
                                  _vm.scaffoldNumber
                                ])
                          )
                        )
                      ])
                    : _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$vuetify.breakpoint.mdAndUp
                              ? _vm.$t(
                                  "scaffolds.work-summary.unknown-scaffold-title"
                                )
                              : _vm.$t(
                                  "scaffolds.work-summary.unknown-scaffold-title-short"
                                )
                          )
                        )
                      ])
                ]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("v-subheader", { staticClass: "pl-5 mt-3 mb-1" }, [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "scaffolds.work-summary.active-scaffold-work-item-explanation"
                  )
                )
              )
            ])
          ]),
          _vm._v(" "),
          _vm.isConfirmingAction || _vm.hasConflict
            ? _c(
                "v-col",
                { staticClass: "pt-0", attrs: { cols: "12" } },
                [
                  _vm.startingDismantleWorkOrder
                    ? _c("fd-alert", {
                        attrs: {
                          label:
                            _vm.startDismantleWorkOrderWithOtherActiveItemsMessage,
                          type: "warning"
                        }
                      })
                    : _vm.approvingDismantleRequest
                    ? _c("fd-alert", {
                        attrs: {
                          label:
                            _vm.approveRequestWithOtherDismantleItemsMessage,
                          type: "warning"
                        }
                      })
                    : _vm.hasMultipleDismantleWorkItems
                    ? _c("fd-alert", {
                        attrs: {
                          label: _vm.multipleDismantleWorkItemsMessage,
                          type: "warning"
                        }
                      })
                    : _vm.hasActiveDismantleWithOtherWork
                    ? _c("fd-alert", {
                        attrs: {
                          label: _vm.$t(
                            "scaffolds.work-summary.conflict-active-dismantle-and-other-work"
                          ),
                          type: "warning"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:internalNumber.hide-when-header-text-empty",
                value: "",
                expression: "''",
                arg: "internalNumber",
                modifiers: { "hide-when-header-text-empty": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:icon.no-sort.class_fd-scaffold-work-type-cell.hide-when-extra-small",
                value: "",
                expression: "''",
                arg: "icon",
                modifiers: {
                  "no-sort": true,
                  "class_fd-scaffold-work-type-cell": true,
                  "hide-when-extra-small": true
                }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:priority.hide-when-extra-small",
                value: _vm.$t("scheduler.priority"),
                expression: "$t('scheduler.priority')",
                arg: "priority",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:displayIdentifier",
                value: _vm.$vuetify.breakpoint.lgAndUp
                  ? _vm.$t("scaffolds.work-summary.identifier-column-header")
                  : _vm.$t(
                      "scaffolds.work-summary.identifier-column-header-short"
                    ),
                expression:
                  "\n        $vuetify.breakpoint.lgAndUp\n          ? $t('scaffolds.work-summary.identifier-column-header')\n          : $t('scaffolds.work-summary.identifier-column-header-short')\n      ",
                arg: "displayIdentifier"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:requestTypeName",
                value: _vm.$vuetify.breakpoint.lgAndUp
                  ? _vm.$t("scheduler.request-type-column-header")
                  : _vm.$t("scheduler.request-type-column-header-short"),
                expression:
                  "\n        $vuetify.breakpoint.lgAndUp\n          ? $t('scheduler.request-type-column-header')\n          : $t('scheduler.request-type-column-header-short')\n      ",
                arg: "requestTypeName"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:workOrderStatusName",
                value: _vm.$t("scheduler.status"),
                expression: "$t('scheduler.status')",
                arg: "workOrderStatusName"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:formattedRequiredDate.hide-when-small",
                value: _vm.$vuetify.breakpoint.lgAndUp
                  ? _vm.$t("scheduler.required-date-column-header")
                  : _vm.$t("scheduler.required-date-column-header-short"),
                expression:
                  "\n        $vuetify.breakpoint.lgAndUp\n          ? $t('scheduler.required-date-column-header')\n          : $t('scheduler.required-date-column-header-short')\n      ",
                arg: "formattedRequiredDate",
                modifiers: { "hide-when-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:disciplineName.hide-when-small",
                value: _vm.$t("scheduler.discipline"),
                expression: "$t('scheduler.discipline')",
                arg: "disciplineName",
                modifiers: { "hide-when-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:coordinatorName.hide-when-small",
                value: _vm.$t("scheduler.coordinator"),
                expression: "$t('scheduler.coordinator')",
                arg: "coordinatorName",
                modifiers: { "hide-when-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:generalForemanName.hide-when-extra-small",
                value: _vm.$t("scheduler.general-foreman-short"),
                expression: "$t('scheduler.general-foreman-short')",
                arg: "generalForemanName",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:assignedContractorName.hide-when-medium",
                value: _vm.$t("scheduler.assigned-contractor"),
                expression: "$t('scheduler.assigned-contractor')",
                arg: "assignedContractorName",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:actions.hide-when-extra-small.class_fd-actions-cell.hide-when-header-text-empty",
                value: _vm.isConfirmingAction ? "" : _vm.$t("common.actions"),
                expression:
                  "\n        isConfirmingAction ? '' : $t('common.actions')\n      ",
                arg: "actions",
                modifiers: {
                  "hide-when-extra-small": true,
                  "class_fd-actions-cell": true,
                  "hide-when-header-text-empty": true
                }
              }
            ],
            ref: "datatable",
            staticClass:
              "fd-active-work-orders-list fd-expanding-table fd-actions-table",
            class: { "mb-3": _vm.hideTableFooter },
            attrs: {
              "data-cy": "activeworkorderslist",
              items: _vm.scaffoldWorkList,
              "custom-sort": _vm.customSort,
              "sort-by": ["displayIdentifier"],
              "sort-desc": true,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "mobile-breakpoint": "0",
              search: _vm.tablesearch,
              "show-expand": "",
              expanded: _vm.expanded,
              "hide-default-footer": _vm.hideTableFooter,
              "item-class": item => _vm.itemRowClass(item)
            },
            on: {
              "update:expanded": function($event) {
                _vm.expanded = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "item.icon",
                fn: function({ item }) {
                  return [
                    item.workType == "request"
                      ? _c("v-badge", {
                          staticClass: "fd-scaffold-work-type-badge",
                          attrs: {
                            color: "scaffoldrequest",
                            content: "R",
                            tile: ""
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.workType == "workorder"
                      ? _c("v-badge", {
                          staticClass: "fd-scaffold-work-type-badge",
                          attrs: { color: "workorder", content: "W" }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.itemIsDismantle(item)
                      ? _c("v-badge", {
                          staticClass:
                            "fd-scaffold-work-type-badge fd-triangle-down-badge",
                          style: `left: 16px;`,
                          attrs: { color: "red", content: "D" }
                        })
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.priority",
                fn: function({ item }) {
                  return [
                    !item.isUrgent
                      ? _c("span", [_vm._v(_vm._s(item.priority))])
                      : _c("v-icon", { staticClass: "fd-urgent-icon" }, [
                          _vm._v("fa-fire-alt")
                        ])
                  ]
                }
              },
              {
                key: "item.workOrderStatusName",
                fn: function({ item }) {
                  return [
                    _c(
                      "v-row",
                      {
                        staticClass: "mx-0 fd-status-column",
                        staticStyle: { position: "relative" }
                      },
                      [
                        _vm.requestIsDeclined(item)
                          ? _c(
                              "v-icon",
                              {
                                staticClass:
                                  "fd-table-cell-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                              },
                              [
                                _vm._v(
                                  "\n            fas fa-exclamation-circle\n          "
                                )
                              ]
                            )
                          : _vm.workOrderIsOnHold(item)
                          ? _c(
                              "v-icon",
                              {
                                staticClass:
                                  "fd-table-cell-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                              },
                              [
                                _vm._v(
                                  "\n            fas fa-exclamation-circle\n          "
                                )
                              ]
                            )
                          : _vm.workOrderIsCancelled(item)
                          ? _c(
                              "v-icon",
                              {
                                staticClass:
                                  "fd-table-cell-error-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                              },
                              [
                                _vm._v(
                                  "\n            fas fa-times-octagon\n          "
                                )
                              ]
                            )
                          : _vm.workOrderIsInScheduling(item)
                          ? _c(
                              "v-icon",
                              {
                                staticClass:
                                  "fd-table-cell-inscheduling-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                              },
                              [
                                _vm._v(
                                  "\n            fas fa-circle\n          "
                                )
                              ]
                            )
                          : _vm.workOrderIsStarted(item)
                          ? _c(
                              "v-icon",
                              {
                                staticClass:
                                  "fd-table-cell-started-icon pr-0 pb-1 fd-status-icon-placement-readonly"
                              },
                              [
                                _vm._v(
                                  "\n            fas fa-circle\n          "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(
                          "\n          " +
                            _vm._s(item.workOrderStatusName) +
                            "\n        "
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "table-3-actions-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              disabled:
                                !_vm.$vuetify.breakpoint.lgAndUp ||
                                _vm.processing,
                              left: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              "data-cy": "approve",
                                              icon: "",
                                              link: "",
                                              disabled:
                                                !_vm.requestCanBeApproved(
                                                  item
                                                ) || _vm.processing,
                                              loading: _vm.approving
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { small: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.approveRequest(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  far fa-check\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.approve")))
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              disabled:
                                !_vm.$vuetify.breakpoint.lgAndUp ||
                                _vm.processing,
                              left: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              "data-cy": "decline",
                                              icon: "",
                                              link: "",
                                              disabled:
                                                !_vm.requestCanBeDeclined(
                                                  item
                                                ) || _vm.processing,
                                              loading: _vm.declining
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { small: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.declineRequest(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  far fa-times\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.decline")))
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              disabled:
                                !_vm.$vuetify.breakpoint.lgAndUp ||
                                _vm.processing,
                              left: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              "data-cy": "cancel",
                                              icon: "",
                                              link: "",
                                              disabled:
                                                !_vm.itemCanBeCancelled(item) ||
                                                _vm.processing,
                                              loading: _vm.cancelling
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { small: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.cancelWork(item)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  far fa-ban\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.cancel")))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "expanded-item",
                fn: function({ item }) {
                  return [
                    _c(
                      "td",
                      { attrs: { colspan: _vm.expanderColSpan } },
                      [
                        _c("fd-work-order-details", {
                          staticClass:
                            "fd-expanding-table-single-details-section",
                          attrs: { workOrder: item, walkdown: item.walkdown }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _vm.isConfirmingAction
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.declineDialog }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("common.cancel")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isConfirmingAction
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.acceptDialog }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("common.accept-and-continue")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isConfirmingAction
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.completeDialog }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("common.close")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }