import { systemService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: systemService,
  singularStoreName: "SYSTEM",
  pluralStoreName: "SYSTEMS",
  localizationPrefix: "systems",
  consumerRelatedIDProperty: {
    name: "systemID",
    type: "string"
  }
});

export default storeModule;

