// Slim (place slim CSS and slim.module.js file in same folder as this file)
import { defineComponent, onMounted, onBeforeUnmount, ref, watch } from "../";
const Slim = require("../../slim-cropper/slim.module.js").default;

export default defineComponent({
  props: {
    image: {
      type: [File, Blob],
      required: false
    },
    label: {
      type: String,
      required: true
    }
  },
  name: "slim-cropper",
  setup(props, context) {
    const root = ref<HTMLDivElement>();
    const image = ref<File | Blob | null>(null);
    var slimInstance: any = null;

    onMounted(() => {
      const options = {
        ratio: "1:1",
        label: props.label,
        size: "300,300",
        serviceFormat: "file",
        service: (
          formdata: any,
          progress: (current: number, maximum: number) => void,
          success: (result: string | Response) => void,
          failure: (reason: any) => void,
          slim: any
        ) => {
          image.value = formdata[0];
          context.emit("update:image", formdata[0]);
          success(""); // TODO: Should this have a message?
        },
        push: true,
        willRemove: (formdata: any, remove: () => void) => {
          image.value = null;
          context.emit("update:image", null);
          remove();
        }
      };
      slimInstance = new Slim(root.value, options);
    });
    onBeforeUnmount(() => {
      slimInstance.destroy();
    });

    watch(
      () => props.image,
      value => {
        if (value != image.value) {
          image.value = value || null;
          if (value) {
            slimInstance.load(value, { blockPush: true });
          } else {
            slimInstance.remove();
          }
        }
      }
    );

    return {
      root
    };
  }
});
