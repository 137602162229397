import FDVue from "@fd/lib/vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { mapActions, mapMutations } from "vuex";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { PaintCode } from "../../services";
import { createNewPaintCode } from "./dialogs/SP.PaintCodeNewDialog.vue";
import archivedDataList from "../../dataMixins/archivedDataList";
import { stripHtml, truncateWithEllipsis } from "../../../../lib/vue/utility/helper";

type PaintCodeWithArchived = PaintCode & { archived: boolean };
export default FDVue.extend({
  name: "sp-build-sheet-setup-paint-code-form",
  props: {
    parentContext: { type: String, default: "" }
  },
  mixins: [archivedDataList],
  components: {
    "fd-detail-view-header": () => import("@fd/lib/vue/components/layout/DetailViewHeader.vue")
  },
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },
  data: function() {
    return {
      context: "paintcodes",

      archivedLoading: false,
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5
    };
  },
  computed: {
    paintCodes(): PaintCodeWithArchived[] {
      return (this.$store.state.paintCodes.fullList as PaintCode[]).map(
        x =>
          ({
            ...x,
            description: truncateWithEllipsis(stripHtml(x.description)),
            archived: !!x.archivedDate
          } as PaintCodeWithArchived)
      );
    },
    tablesearch: {
      get(): string {
        return this.$store.state.filters.find((x: any) => x.context == this.context)!
          .searchStringForFiltering;
      },
      set(val: string) {
        this.$store.state.filters.find(
          (x: any) => x.context == this.context
        )!.searchStringForFiltering = val;
      }
    }
  },
  watch: {},
  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      addFilteringContext: "ADD_CHILD_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadPaintCodes: "LOAD_PAINT_CODES",
      updatePaintCode: "UPDATE_PAINT_CODE",
      deletePaintCode: "DELETE_PAINT_CODE"
    }),
    async reloadData() {
      this.processing = true;
      this.archivedLoading = this.showArchived;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.archivedLoading = false;
      }
    },
    async loadData() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      await this.loadPaintCodes({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },
    async showAddNewDialog() {
      await createNewPaintCode();
    },
    async deleteTableItem(item: PaintCode) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deletePaintCode({ id: item.id, name: item.name });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async flipArchived(item: PaintCodeWithArchived) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updatePaintCode({
          id: item.id,
          archivedDate: archivedDate,
          name: item.name
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    itemNavigationPath(item: PaintCodeWithArchived): string {
      return `/buildsheetsetup/paintcodes/${item.id}`;
    },
    navigateToItem(item: PaintCodeWithArchived) {
      this.$router.push(this.itemNavigationPath(item));
    }
  },
  created: async function() {
    this.addFilteringContext({
      context: this.context,
      parentalContext: this.parentContext,
      searchStringForFiltering: "",
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: DateUtil.minimumDate(),
      showArchivedForFilteringToDate: DateUtil.maximumDate()
    });
  },
  mounted: async function() {
    this.processing = true;
    await this.reloadData();
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  }
});

