var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "fd-kanban-item-details-text-size fd-kanban-item-expander-details-item"
    },
    [
      _c("fd-kanban-item-value-display", {
        attrs: { value: _vm.value, label: _vm.label, dense: _vm.dense }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }