import FDVue from "@fd/lib/vue";

export default FDVue.extend({
  props: {
    value: { type: String, default: "" },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  inheritAttrs: false,
  data: () => ({}),
  methods: {
    addNewNote() {
      this.$emit("addNewNote");
    }
  }
});
