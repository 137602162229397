import { mapMutations, mapState, mapActions } from "vuex";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { Tag } from "@fd/current/client/services";
import { stripHtml, truncateWithEllipsis } from "@fd/lib/vue/utility/helper";
import { addDaysToDate, addMonthsToDate } from "@fd/lib/client-util/datetime";

type TagWithArchived = Tag & { archived: boolean };
export default FDVue.extend({
  mixins: [errorHandling],

  name: "fd-Tags",

  components: {
    "fd-tag-new-dialog": () => import("./components/TagNewDialog.vue")
  },

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      showNewDialog: false,
      archivedLoading: false,
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5
    };
  },

  computed: {
    tags(): TagWithArchived[] {
      return this.$store.state.tags.fullList.map((x: Tag) => {
        return {
          ...x,
          description: truncateWithEllipsis(stripHtml(x.description)),
          archived: !!x.archivedDate
        } as TagWithArchived;
      });
    },

    tablesearch: {
      get() {
        //return this.$store.state.searchStringForFiltering;
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    showArchived: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFiltering;
      },
      async set(val) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING", val);
        this.processing = true;
        this.archivedLoading = true;
        try {
          await this.reloadTags();
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = false;
          this.archivedLoading = false;
        }
      }
    },

    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = addMonthsToDate(date, -2);
      return minDate;
    },

    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = addMonthsToDate(date, 2);
      return maxDate;
    },

    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          this.archivedLoading = true;
          try {
            await this.reloadTags();
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
            this.archivedLoading = false;
          }
        } else this.showArchivedToDate = null;
      }
    },

    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },

    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    }
  },

  methods: {
    openTagNewDialog() {
      this.showNewDialog = true;
    },

    // the following works with the delete "Action" button in the Datatable.
    async deleteTableItem(item: Tag) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteTag({ id: item.id, name: item.name });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async flipArchived(item: TagWithArchived) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateTag({ id: item.id, archivedDate: archivedDate, name: item.name });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async reloadTableData() {
      this.processing = true;
      try {
        await this.reloadTags();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async reloadTags() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      await this.loadTags({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },

    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadTags: "LOAD_TAGS",
      updateTag: "UPDATE_TAG",
      deleteTag: "DELETE_TAG"
    })
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "tags",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      suppliersForFiltering: []
    });

    this.notifyNewBreadcrumb({
      text: this.$t("tags.menu-title"),
      to: "/tags",
      resetHistory: true
    });

    this.processing = true;
    try {
      await this.reloadTags();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

