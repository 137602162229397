import FDVue from "@fd/lib/vue";
import { mapState, mapMutations, mapActions } from "vuex";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import rules from "@fd/lib/vue/rules";

export default FDVue.extend({
  name: "fd-tag-new-dialog",

  mixins: [errorHandling, rules],

  // TODO: Implement show property as mixin? Figure this out as we explore other optimizations
  props: ["show"],

  mounted() {
    // Bind the inbound value of the 'show' property to our own handlers so we can act
    // when we're asked to show and hide ourselves
    // TODO: Consider a binding that exposes a callable method; toggling data like this seems to be weird, although it's what I saw online
    this.onShowPropChanged(this.show);
    // this.$watch('show', this.onShowPropChanged);
  },

  data: () => ({
    dialogVisible: false,

    // The following will control whether the controls on screen are disabled while we are processing.
    processing: false,

    tag: {
      name: "",
      description: "",
      enabled: true
    }
  }),

  watch: {
    show(val) {
      this.onShowPropChanged(val);
    }
  },

  methods: {
    onShowPropChanged(value: any) {
      this.dialogVisible = value;
    },
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    closeDialog() {
      (this.$refs.form as HTMLFormElement).reset();
      this.tag.description = "";
      this.inlineMessage.message = null;
      this.dialogVisible = false;
      this.$emit("update:show", false);
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog();
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      try {
        await this.addTag(this.tag);
        this.closeDialog();
      } catch (error) {
        this.handleError(error as Error, "tags.save-network-error");
      } finally {
        this.processing = false;
      }
    },
    ...mapActions({
      addTag: "ADD_TAG"
    })
  }
});
