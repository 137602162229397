var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-card",
            { attrs: { "data-cy": "itemselectiondialog" } },
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-subtitle",
                { staticClass: "mt-3" },
                [
                  _c(
                    "v-row",
                    [
                      !!_vm.details
                        ? _c("v-col", { attrs: { cols: "12" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.details) +
                                "\n          "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            class: [
                              _vm.$vuetify.breakpoint.sm
                                ? "fd-restrict-clearable-position"
                                : "",
                              "pt-0",
                              "mt-1",
                              "fd-table-search-input"
                            ],
                            attrs: {
                              "data-cy": "tablesearch",
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-1", attrs: { cols: "12" } },
                        [
                          _c("v-data-table", {
                            directives: [
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:[itemText]",
                                value: _vm.dataLabel,
                                expression: "dataLabel",
                                arg: _vm.itemText
                              },
                              {
                                name: "fd-column",
                                rawName:
                                  "v-fd-column:include.class_fd-include-cell.no-sort",
                                arg: "include",
                                modifiers: {
                                  "class_fd-include-cell": true,
                                  "no-sort": true
                                }
                              }
                            ],
                            ref: "datatable",
                            attrs: {
                              search: _vm.tablesearch,
                              items: _vm.items,
                              loading: _vm.processing,
                              "loading-text": _vm.$t(
                                "common.table-loading-message"
                              ),
                              "mobile-breakpoint": "0"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "header.include",
                                fn: function({ header }) {
                                  return [
                                    _c("v-checkbox", {
                                      ref: "headerinclude",
                                      attrs: {
                                        value: _vm.allSearchedItemsSelected,
                                        indeterminate:
                                          _vm.someSearchedItemsSelected,
                                        label: _vm.$t("common." + header.value)
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.flipSearchedItemsSelected()
                                        }
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "item.include",
                                fn: function({ item }) {
                                  return [
                                    _c("v-simple-checkbox", {
                                      attrs: {
                                        value: _vm.itemIsSelected(item),
                                        disabled: _vm.processing
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.flipItemSelected(item)
                                        }
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "close",
                        text: "",
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.cancelDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.cancel")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "accept",
                        color: "primary",
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.acceptDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.save")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }