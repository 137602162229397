import Vue from "vue";

Vue.config.optionMergeStrategies.dataBackedProps = function(toVal: string[], fromVal: string[]) {
  if (!toVal) return fromVal;
  if (!fromVal) return toVal;
  return [...fromVal, ...toVal];
};

declare module "vue/types/options" {
  interface ComponentOptions<V extends Vue> {
    dataBackedProps?: { [propKey: string]: string };
  }
}

export default Vue.extend({
  data() {
    return {
      dataBackedPropUnwatches: [] as Array<() => void>
    };
  },

  mounted() {
    // TODO: Error out if dataBackedProps doesn't exist?
    let unwatches: Array<() => void> = [];
    for (let propKey in this.$options.dataBackedProps!) {
      let dataKey = this.$options.dataBackedProps[propKey];
      let unwatch = this.$watch(propKey, (newValue: any) => ((this as any)[dataKey] = newValue));
      unwatches.push(unwatch);
      unwatch = this.$watch(dataKey, (newValue: any) => {
        if ((this as any)[propKey] != newValue) {
          this.$emit(`update:${propKey}`, newValue);
        }
      });
      unwatches.push(unwatch);
    }
    this.dataBackedPropUnwatches = unwatches;
  },

  destroyed(this: any) {
    if (!this.dataBackedPropsUnwatches?.length) return;
    for (let unwatch of this.dataBackedPropsUnwatches) {
      unwatch();
    }
  }
});
