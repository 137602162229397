import { contractorService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: contractorService,
  singularStoreName: "CONTRACTOR",
  pluralStoreName: "CONTRACTORS",
  localizationPrefix: "contractors",
  // The following is the name of the ID property used by foreign objects.
  consumerRelatedIDProperty: {
    name: "contractorID",
    type: "string"
  }
});

export default storeModule;
