import FDVue from "..";
import { mapActions } from "vuex";
import dataBackedProps from "../mixins/dataBackedProps";

export default FDVue.extend({
  name: "fd-chip-selector",
  mixins: [dataBackedProps],

  props: {
    availableItems: Array,
    cy: { type: String, default: "fd-chip-selector" },
    selectedItems: Array,
    itemLabel: [Function, String],
    controlLabel: String,
    controlHint: String,
    disabled: Boolean,
    allowEdit: Boolean,
    allowCreate: Boolean,
    chipClass: String,
    inputClass: String,
    outlined: { default: true, type: Boolean },
    smallChips: { default: true, type: Boolean },
    dark: { default: false, type: Boolean },
    persistentHint: { default: true, type: Boolean },
    soloInverted: { default: false, type: Boolean },
    flat: { default: true, type: Boolean },
    dense: { default: false, type: Boolean },
    filled: { default: false, type: Boolean }
  },

  dataBackedProps: {
    selectedItems: "publicSelectedItems"
  },

  data: () => ({
    publicSelectedItems: [] as any[], // Always objects mapped to the selected items set
    internalSelectedItems: [] as any[], // May contain strings for incomplete/uncreated items

    // The following is responsible for the Tags Selection in the new Part Dialog
    editedItem: null,
    tagsSearch: null,
    index: -1
  }),

  watch: {
    publicSelectedItems(this: any, newValue: any[]) {
      if (newValue != this.internalSelectedItems) {
        this.internalSelectedItems = newValue;
      }
    },
    async internalSelectedItems(this: any, newValue: any[] | undefined) {
      if (!newValue) newValue = [];
      // Newly created items will be strings in our array; we'll want to remap those items once
      // they are successfully created, but we'll need to signal our owner to do that on our
      // behalf; if we don't allow new items we'll have to suppress the item we've been given
      for (let i = 0; i < newValue.length; i++) {
        let item = newValue[i];
        if (typeof item == "string") {
          if (this.allowCreate) {
            await this.createNewItem(item, i);
          } else {
            newValue.splice(i--, 1);
          }
        }
      }

      // Once we've massaged everything, make the public view the same as our internal view
      this.publicSelectedItems = newValue;
    }
  },

  methods: {
    getItemLabel(this: any, item: any) {
      if (typeof this.itemLabel === "function") {
        return this.itemLabel(item);
      } else {
        return item[this.itemLabel];
      }
    },

    async createNewItem(this: any, stringValue: string, internalItemIndex: number) {
      alert("Unimplemented");
    },

    // The following two methods have to do with the Tags selection control.
    editTags(this: any, index: any, item: any) {
      if (!this.editedItem) {
        this.editedItem = { ...item };
        this.index = index;
      } else {
        this.editedItem = null;
        this.index = -1;
      }
    },
    itemFilter(this: any, item: any, queryText: string) {
      if (!queryText) return true;
      queryText = queryText.toLowerCase();
      let itemText = this.getItemLabel(item).toLowerCase();
      return itemText.indexOf(queryText) > -1;
    },
    ...mapActions({
      loadTags: "LOAD_TAGS",
      updateTag: "UPDATE_TAG"
    })
  },

  mounted: function() {
    this.internalSelectedItems = this.selectedItems;
  }
});

