import FDVue from "@fd/lib/vue";

export type Permission = {
  text: string | undefined;
  value: boolean | undefined;
  group?: string | undefined;
  moduleCheck?: string | undefined;
};
export type PermissionDetails = {
  title: string | undefined;
  groups?: string[] | undefined;
  permissions: Permission[];
};
export default FDVue.extend({
  name: "fd-user-security-summary-details-form",
  props: {
    details: { type: Object, default: () => ({} as PermissionDetails) }
  },
  data: function() {
    return {};
  },
  methods: {
    getPermissionsInGroup(groupName: string): Permission[] {
      return this.moduleVisiblePermissions(this.details as PermissionDetails).filter(
        x => !!x.group && x.group == groupName
      );
    },
    moduleVisiblePermissions(details: PermissionDetails): Permission[] {
      return (details.permissions ?? []).filter(
        x => !x?.moduleCheck?.length || this.$store.state.curEnvironment[x.moduleCheck] == true
      );
    }
  }
});

