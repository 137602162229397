var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    _vm._b(
      {
        attrs: {
          value:
            (!!_vm.value && _vm.value.length > 0) ||
            (!!_vm.imageList && _vm.allowAddImage) ||
            (!!_vm.imageList && _vm.imageList.length > 0),
          fullscreen: "",
          "data-cy": _vm.cy
        },
        on: { input: v => _vm.$emit("input", v), "click:outside": _vm.close }
      },
      "v-dialog",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "v-card",
        {
          staticClass: "m-5",
          staticStyle: { "background-color": "rgba(30,30,30,.9)" }
        },
        [
          !!_vm.value
            ? _c(
                "div",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", dark: "" } },
                    [
                      !!_vm.name
                        ? _c("v-toolbar-title", [_vm._v(_vm._s(_vm.name))])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: { click: _vm.close }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass: "ma-0",
                      attrs: { align: "center", justify: "center" }
                    },
                    [
                      _c("v-col", { attrs: { cols: "1" } }),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticStyle: { height: "calc(100vh - 64px)" },
                          attrs: { cols: "10" }
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              src: _vm.value,
                              "max-width": "100%",
                              "max-height": "90%",
                              contain: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "placeholder",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "fill-height ma-0",
                                          attrs: {
                                            align: "center",
                                            justify: "center"
                                          }
                                        },
                                        [
                                          _c("v-progress-circular", {
                                            attrs: {
                                              indeterminate: "",
                                              color: "grey lighten-5"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              4034393411
                            )
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "1" } })
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", dark: "" } },
                    [
                      !!_vm.currentImageName
                        ? _c("v-toolbar-title", [
                            _vm._v(_vm._s(_vm.currentImageName))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _vm.allowAddImage
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                "data-cy": "add",
                                color: "primary",
                                loading: _vm.loading
                              },
                              on: { click: _vm.addImage }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("common.add")) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: { click: _vm.close }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("fd-alert", {
                    staticClass: "mx-3",
                    attrs: {
                      type: "info",
                      label: _vm.infoMessage,
                      dismissible: ""
                    },
                    model: {
                      value: _vm.showInfoMessage,
                      callback: function($$v) {
                        _vm.showInfoMessage = $$v
                      },
                      expression: "showInfoMessage"
                    }
                  }),
                  _vm._v(" "),
                  !!_vm.imageList && _vm.imageList.length > 0
                    ? _c(
                        "v-carousel",
                        {
                          attrs: { touchless: "", height: "100%" },
                          on: { change: _vm.caroueslChanged }
                        },
                        _vm._l(_vm.imageList, function(image) {
                          return _c(
                            "v-carousel-item",
                            { key: image.name },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { align: "center", justify: "center" }
                                },
                                [
                                  _c("v-col", { attrs: { cols: "1" } }),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticStyle: {
                                        height: "calc(100vh - 64px)"
                                      },
                                      attrs: { cols: "10" }
                                    },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          src: image.source,
                                          "max-width": "100%",
                                          "max-height": "90%",
                                          contain: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "placeholder",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "fill-height ma-0",
                                                      attrs: {
                                                        align: "center",
                                                        justify: "center"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-progress-circular",
                                                        {
                                                          attrs: {
                                                            indeterminate: "",
                                                            color:
                                                              "grey lighten-5"
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-col", { attrs: { cols: "1" } })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            { staticStyle: { "justify-content": "center" } },
                            [
                              _c("fd-alert", {
                                staticClass: "mb-5",
                                staticStyle: {
                                  "border-width": "0px !important"
                                },
                                attrs: {
                                  label: _vm.$t("fd-image-viewer.no-photos"),
                                  "comment-hint": _vm.$t(
                                    "fd-image-viewer.add-photo-instructions"
                                  ),
                                  outlined: "",
                                  color: "white",
                                  "comment-hint-style": "color: white;"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }