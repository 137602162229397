var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { staticClass: "mx-0", staticStyle: { width: "100%" } },
    [
      _c(
        "v-col",
        {
          staticClass: "fd-remove-left-padding-cols-below pl-0",
          attrs: { cols: "12" }
        },
        [
          _c(
            "v-row",
            { staticClass: "mx-0 px-0", attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { align: "center", cols: "12" } },
                [
                  _c(
                    "v-subheader",
                    {
                      staticClass: "pa-0 mt-3",
                      staticStyle: { "font-size": "1em", height: "32px" }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("workorders.details.scope-change-header"))
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0 pl-0", attrs: { align: "center" } },
            [_c("v-divider", { staticClass: "mx-0 mt-0 px-0" })],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0 pl-0 pt-2 pb-3" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: _vm.cols } },
                [
                  _c("fd-work-order-detail-flag-value", {
                    attrs: {
                      label: _vm.$t("workorders.details.is-client-work-order"),
                      value: _vm.workOrder.isClientWorkOrder,
                      color: _vm.$store.getters.colour("client-work-order")
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t(
                        "workorders.details.client-work-order-reference-number"
                      ),
                      value: _vm.workOrder.clientWorkOrderReferenceNumber
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: _vm.cols } },
                [
                  _c("fd-work-order-detail-flag-value", {
                    attrs: {
                      label: _vm.$t("workorders.details.is-service-order"),
                      value: _vm.workOrder.isServiceOrder,
                      color: _vm.$store.getters.colour("service-order")
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t(
                        "workorders.details.service-order-reference-number"
                      ),
                      value: _vm.workOrder.serviceOrderReferenceNumber
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: _vm.cols } },
                [
                  _c("fd-work-order-detail-flag-value", {
                    attrs: {
                      label: _vm.$t("workorders.details.is-change-order"),
                      value: _vm.workOrder.isChangeOrder,
                      color: _vm.$store.getters.colour("change-order")
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t(
                        "workorders.details.change-order-reference-number"
                      ),
                      value: _vm.workOrder.changeOrderReferenceNumber
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: _vm.cols } },
                [
                  _c("fd-work-order-detail-flag-value", {
                    attrs: {
                      label: _vm.$t("workorders.details.is-rework"),
                      value: _vm.workOrder.isRework,
                      color: _vm.$store.getters.colour("rework")
                    }
                  }),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t(
                        "workorders.details.rework-reference-number"
                      ),
                      value: _vm.workOrder.reworkReferenceNumber
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "2" } },
                [
                  _vm.showPurchaseOrderDetails
                    ? _c("fd-work-order-detail-value", {
                        attrs: {
                          label: _vm.$t(
                            "workorders.details.purchase-order-number"
                          ),
                          value: _vm.$lookup.purchaseOrder(
                            _vm.workOrder.purchaseOrderID
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("fd-work-order-detail-value", {
                    attrs: {
                      label: _vm.$t("workorders.details.existing-tag-number"),
                      value: _vm.workOrder.existingTagNumber
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }