import FDVue from "@fd/lib/vue";

import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";

const DisableInsteadDialog = FDVue.extend({
  name: "fd-deletion-confirmation-dialog",
  mixins: [dialogSupport],

  props: {
    processing: Boolean // TODO: This shouldn't be necessary anymore as we don't respond to external processing
  },

  data() {
    return {
      itemEnabled: false
    };
  },

  methods: {
    async getConfirmation(itemEnabled: boolean): Promise<boolean> {
      this.optOutOfErrorHandling();
      this.itemEnabled = itemEnabled;
      return await this.showDialog();
    }
  }
});

export default DisableInsteadDialog;

export async function showDisableInsteadConfirmation(
  itemAlreadyEnabled: boolean
): Promise<boolean> {
  let dialog = createDialog(DisableInsteadDialog);
  return await dialog.getConfirmation(itemAlreadyEnabled);
}
