import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import userAccess from "../dataMixins/userAccess";
import { mapMutations, mapActions } from "vuex";
import {
  FDColumnDirective,
  FDHiddenArgumentName,
  FDRowNavigateDirective,
  FDTableSortableDirective,
  SortableEvent
} from "@fd/lib/vue/utility/dataTable";
import { createNewWorkType } from "./components/dialogs/WorkTypeNewDialog.vue";
import { WorkType, WorkTypeWithDetails, workTypeService } from "../services";
import archivedDataList from "../dataMixins/archivedDataList";

function CompareWorkTypes<T extends WorkType>(a: T, b: T): number {
  let aOrder = a.order ?? 0;
  let bOrder = b.order ?? 0;
  if (aOrder != bOrder) return aOrder - bOrder;

  let aName = a.name?.toLocaleLowerCase() ?? "";
  let bName = b.name?.toLocaleLowerCase() ?? "";
  if (aName < bName) return -1;
  else if (aName > bName) return 1;
  return 0;
}
function SortWorkTypes<T extends WorkType>(items: T[] | null | undefined): T[] {
  if (!items) return [];
  return items.sort(CompareWorkTypes);
}
type WorkTypeWithArchived = WorkTypeWithDetails & { archived: boolean };
export default FDVue.extend({
  mixins: [errorHandling, userAccess, archivedDataList],

  name: "fd-work-types",

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    fdTableSortable: FDTableSortableDirective
  },

  components: {
    "fd-archived-data-loader": () => import("@fd/lib/vue/components/ArchivedDataLoader.vue")
  },

  data: function() {
    return {
      cachedWorkTypes: undefined as WorkTypeWithArchived[] | undefined,
      archivedLoading: false,
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5
    };
  },

  computed: {
    iconColumnArgument(): string {
      return this.anyWorkTypesWithMisconfiguredSubTypes
        ? "hasMisconfiguredSubTypes"
        : FDHiddenArgumentName;
    },
    anyWorkTypesWithMisconfiguredSubTypes(): boolean {
      return this.workTypes.findIndex(x => !!x.hasMisconfiguredSubTypes) !== -1;
    },
    workTypes(): WorkTypeWithArchived[] {
      if (!this.cachedWorkTypes) {
        this.cachedWorkTypes = SortWorkTypes(
          (this.$store.state.workTypes.fullList as WorkTypeWithDetails[]).map(x => {
            return {
              ...x,
              archived: !!x.archivedDate
            } as WorkTypeWithArchived;
          })
        );
      }
      return this.cachedWorkTypes;
    },

    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    async openNewDialog() {
      this.optOutOfErrorHandling();
      let lastOrder = this.workTypes?.slice(-1)[0]?.order ?? 0;
      // let lastOrder = this.workTypes.slice(-1)[0].order ?? 0;
      await createNewWorkType(lastOrder);
      this.cachedWorkTypes = undefined;
    },

    // the following works with the delete "Action" button in the Datatable.
    async deleteTableItem(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteWorkType({ id: item.id, name: item.name });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async flipArchived(item: WorkTypeWithArchived) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateWorkType({ id: item.id, archivedDate: archivedDate, name: item.name });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadData() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      await this.loadWorkTypes({
        forcedArchivedState: this.showArchived,
        archivedFromDate: this.showArchivedFromDate,
        archivedToDate: this.showArchivedToDate
      });
      this.cachedWorkTypes = undefined;

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },
    async confirmItemOrdering() {
      console.log(`confirmItemOrdering total types: ${this.workTypes.length}`);

      let itemsToUpdate = [] as WorkTypeWithArchived[];

      this.workTypes.forEach((type, index) => {
        let newOrder = index + 1;
        if (!type.order || type.order != newOrder) {
          console.log(`    ${type.order} --> ${newOrder}`);
          type.order = newOrder;
          itemsToUpdate.push(type);
        }
      });

      if (itemsToUpdate.length > 0) {
        console.log(` updating ${itemsToUpdate.length} types' ordering`);
        await workTypeService.updateWorkTypeOrders(itemsToUpdate);

        var snackbarPayload = {
          text: this.$t("work-types.snack-bar-order-updated-message"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      }
    },

    async dragEnded(e: SortableEvent) {
      console.log(`dragEnded`);
      let oldIndex = e.oldIndex ?? 0;
      let newIndex = e.newIndex ?? 0;
      if (oldIndex == newIndex) return;

      this.workTypes.splice(newIndex, 0, ...this.workTypes.splice(oldIndex, 1));

      this.processing = true;
      try {
        await this.confirmItemOrdering();
      } catch (error) {
        this.handleError(error as Error);
        await this.reloadTableData();
      } finally {
        this.processing = false;
      }
    },

    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    ...mapActions({
      loadWorkTypes: "LOAD_WORK_TYPES",
      updateWorkType: "UPDATE_WORK_TYPE",
      deleteWorkType: "DELETE_WORK_TYPE"
    })
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.

    this.setFilteringContext({
      context: "worktypes",
      parentalContext: null,
      searchStringForFiltering: "",
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: new Date(0),
      showArchivedForFilteringToDate: new Date()
    });

    this.notifyNewBreadcrumb({
      text: this.$t("work-types.list.title"),
      to: "/worktypes",
      resetHistory: true
    });

    this.processing = true;
    try {
      await Promise.all([this.loadData()]);
      this.workTypes.forEach(x => console.log(`${JSON.stringify(x)}`));
      this.processing = true;
      await this.confirmItemOrdering();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

