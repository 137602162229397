var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "1000px" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        {
          ref: "content",
          attrs: { "data-cy": "workingestimatetakeoffdialog" }
        },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "scheduler.estimates.estimate-takeoff-dialog.title"
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:partName",
                value: _vm.$t("common.name"),
                expression: "$t('common.name')",
                arg: "partName"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:partPublicID",
                value: _vm.$t("common.code"),
                expression: "$t('common.code')",
                arg: "partPublicID"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:partDescription",
                value: _vm.$t("common.description"),
                expression: "$t('common.description')",
                arg: "partDescription"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:quantity",
                arg: "quantity"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:totalWeight",
                value: _vm.$t("parts.weight"),
                expression: "$t('parts.weight')",
                arg: "totalWeight"
              }
            ],
            attrs: {
              items: _vm.takeoffParts,
              loading: _vm.processing,
              "mobile-breakpoint": "0",
              "sort-by": "partName"
            },
            scopedSlots: _vm._u([
              {
                key: "item.quantity",
                fn: function({ item }) {
                  return [
                    _c("fd-value-display", {
                      attrs: { value: _vm.$format.number(item.quantity, 0) }
                    })
                  ]
                }
              },
              {
                key: "item.totalWeight",
                fn: function({ item }) {
                  return [
                    _c("fd-value-display", {
                      attrs: { value: _vm.$format.number(item.totalWeight, 1) }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "v-expansion-panels",
            [
              _c(
                "v-expansion-panel",
                { staticClass: "secondary-section-style" },
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "scheduler.estimates.estimate-takeoff-dialog.bcount-title"
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("v-container", [
                        _c(
                          "div",
                          {
                            staticClass: "pa-3",
                            staticStyle: {
                              width: "100%",
                              border: "1px dashed",
                              "border-radius": "4px",
                              overflow: "auto",
                              "max-height": "calc(90vh - 150px)"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "white-space": "pre-line" } },
                              [_vm._v(_vm._s(_vm.partsBCount))]
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("fd-alert", {
            staticClass: "mx-3",
            attrs: {
              type: "info",
              label: _vm.$t(
                "scheduler.estimates.takeoff-panel.bcount-copied-message"
              ),
              dismissible: ""
            },
            model: {
              value: _vm.bCountCopied,
              callback: function($$v) {
                _vm.bCountCopied = $$v
              },
              expression: "bCountCopied"
            }
          }),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", color: "primary" },
                  on: { click: _vm.copyBCount }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "scheduler.estimates.estimate-takeoff-dialog.copy-bcount-button-label"
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog(false)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("common.close")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }