var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-data-table", {
    directives: [
      {
        name: "fd-column",
        rawName: "v-fd-column:id.hide-when-header-text-empty",
        value: "",
        expression: "''",
        arg: "id",
        modifiers: { "hide-when-header-text-empty": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:externalReferenceNumber.hidden",
        value: "",
        expression: "''",
        arg: "externalReferenceNumber",
        modifiers: { hidden: true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:prefixedTagNumber.hidden",
        value: "",
        expression: "''",
        arg: "prefixedTagNumber",
        modifiers: { hidden: true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:scaffoldNumber",
        value: _vm.$vuetify.breakpoint.lgAndUp
          ? _vm.$t("scaffolds.tag-number-label")
          : _vm.$t("scaffolds.tag-number-label-short"),
        expression:
          "\n    $vuetify.breakpoint.lgAndUp\n      ? $t('scaffolds.tag-number-label')\n      : $t('scaffolds.tag-number-label-short')\n  ",
        arg: "scaffoldNumber"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:scaffoldStatusName.show-when-extra-small",
        value: _vm.$t("scaffolds.status-label"),
        expression: "$t('scaffolds.status-label')",
        arg: "scaffoldStatusName",
        modifiers: { "show-when-extra-small": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:daysStanding.hide-when-extra-small",
        value: _vm.$t("scaffolds.days-standing-label"),
        expression: "$t('scaffolds.days-standing-label')",
        arg: "daysStanding",
        modifiers: { "hide-when-extra-small": true }
      },
      {
        name: "fd-column",
        rawName:
          "v-fd-column:areaName.hide-when-extra-small.hide-when-header-text-empty",
        value: _vm.$t("scaffolds.area-label"),
        expression: "\n    $t('scaffolds.area-label')\n  ",
        arg: "areaName",
        modifiers: {
          "hide-when-extra-small": true,
          "hide-when-header-text-empty": true
        }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:subAreaName.hide-when-extra-small",
        value: _vm.$t("scaffolds.sub-area-label"),
        expression: "$t('scaffolds.sub-area-label')",
        arg: "subAreaName",
        modifiers: { "hide-when-extra-small": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:specificWorkLocation.hide-when-medium",
        value: _vm.$t("scaffolds.location-label"),
        expression: "$t('scaffolds.location-label')",
        arg: "specificWorkLocation",
        modifiers: { "hide-when-medium": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:scaffoldContractorName.hide-when-medium",
        value: _vm.$t("scaffolds.contractor-label"),
        expression: "$t('scaffolds.contractor-label')",
        arg: "scaffoldContractorName",
        modifiers: { "hide-when-medium": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:plannedErect.hide-when-medium",
        value: _vm.$t("scaffolds.planned-erect-date-label"),
        expression: "$t('scaffolds.planned-erect-date-label')",
        arg: "plannedErect",
        modifiers: { "hide-when-medium": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:actualErect.hide-when-medium",
        value: _vm.$t("scaffolds.actual-erect-date-label"),
        expression: "$t('scaffolds.actual-erect-date-label')",
        arg: "actualErect",
        modifiers: { "hide-when-medium": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:plannedDismantle.hide-when-medium",
        value: _vm.$t("scaffolds.planned-dismantle-date-label"),
        expression: "$t('scaffolds.planned-dismantle-date-label')",
        arg: "plannedDismantle",
        modifiers: { "hide-when-medium": true }
      },
      {
        name: "fd-column",
        rawName:
          "v-fd-column:archived.hide-when-mobile.hide-when-header-text-empty",
        value: _vm.showArchived ? _vm.$t("common.archived") : "",
        expression: "\n    showArchived ? $t('common.archived') : ''\n  ",
        arg: "archived",
        modifiers: {
          "hide-when-mobile": true,
          "hide-when-header-text-empty": true
        }
      },
      {
        name: "fd-column",
        rawName:
          "v-fd-column:action.no-sort.hide-when-extra-small.class_fd-actions-cell-edit-only",
        arg: "action",
        modifiers: {
          "no-sort": true,
          "hide-when-extra-small": true,
          "class_fd-actions-cell-edit-only": true
        }
      },
      {
        name: "fd-row-navigate",
        rawName: "v-fd-row-navigate.show-when-extra-small",
        value: _vm.navigatePressed,
        expression: "navigatePressed",
        modifiers: { "show-when-extra-small": true }
      }
    ],
    ref: "datatable",
    class: [
      { "fd-archive-bonus-padding-bottom": _vm.showArchived },
      "fd-actions-table",
      "fd-expanding-table",
      "fd-requests-list"
    ],
    attrs: {
      "data-cy": "list",
      "custom-sort": _vm.customSort,
      "items-per-page": _vm.itemsPerPage,
      "footer-props": { "items-per-page-options": _vm.itemsPerPageOptions },
      items: _vm.scaffolds,
      search: _vm.tablesearch,
      loading: _vm.processing,
      "loading-text": _vm.$t("common.table-loading-message"),
      "sort-by": ["scaffoldNumber"],
      "sort-desc": true,
      "mobile-breakpoint": "0",
      "show-expand": ""
    },
    scopedSlots: _vm._u([
      {
        key: "item.scaffoldNumber",
        fn: function({ item }) {
          return [
            _vm._v("\n    " + _vm._s(item.scaffoldNumber) + "\n    "),
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function({ on }) {
                        return [
                          !!item.externalReferenceNumber
                            ? _c(
                                "v-icon",
                                _vm._g(
                                  {
                                    staticClass: "ml-1",
                                    staticStyle: { "margin-top": "-2px" },
                                    attrs: {
                                      small: "",
                                      color: "black",
                                      dark: ""
                                    }
                                  },
                                  on
                                ),
                                [_vm._v("info")]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm._v(
                  "\n      " + _vm._s(item.externalReferenceNumber) + "\n    "
                )
              ]
            )
          ]
        }
      },
      {
        key: "footer.prepend",
        fn: function() {
          return [
            _c("fd-archived-data-loader", {
              staticClass: "ml-2 mr-3",
              attrs: {
                showArchived: _vm.showArchived,
                showArchivedDateRange: _vm.showArchivedDateRange,
                showArchivedMinDate: _vm.showArchivedMinDate,
                showArchivedMaxDate: _vm.showArchivedMaxDate,
                loading: _vm.archivedLoading,
                disabled: _vm.processing
              },
              on: {
                "input:showArchived": _vm.showArchivedChanged,
                "input:showArchivedDateRange": _vm.showArchivedDateRangeChanged
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "item.fd-nav",
        fn: function() {
          return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
        },
        proxy: true
      },
      {
        key: "item.archived",
        fn: function({ item }) {
          return [
            _vm.showArchived
              ? _c(
                  "div",
                  [
                    _c("v-simple-checkbox", {
                      attrs: { value: item.archived, disabled: true }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        }
      },
      {
        key: "expanded-item",
        fn: function({ item }) {
          return [
            _c(
              "td",
              {
                staticClass: "fd-expanding-table-single-details-section",
                attrs: { colspan: _vm.expanderColSpan }
              },
              [
                true
                  ? _c(
                      "div",
                      { staticClass: "fd-details-section pt-3" },
                      [
                        _c(
                          "v-row",
                          {
                            staticClass: "mx-0 pl-0 pt-2 pb-3",
                            attrs: { align: "center" }
                          },
                          [
                            _c("v-col", { attrs: { cols: "6", sm: "3" } }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.$t("scaffolds.tag-number-label"))
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { attrs: { cols: "6", sm: "3" } },
                              [
                                _c("fd-value-display", {
                                  attrs: { value: item.scaffoldNumber }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "6", sm: "3" } }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.$t("scaffolds.status-label")))
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { attrs: { cols: "6", sm: "3" } },
                              [
                                _c("fd-value-display", {
                                  attrs: { value: item.scaffoldStatusName }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "6", sm: "3" } }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("scaffolds.planned-erect-date-label")
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { attrs: { cols: "6", sm: "3" } },
                              [
                                _c("fd-value-display", {
                                  attrs: { value: item.plannedErect }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "6", sm: "3" } }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "scaffolds.planned-dismantle-date-label"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { attrs: { cols: "6", sm: "3" } },
                              [
                                _c("fd-value-display", {
                                  attrs: { value: item.plannedDismantle }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "6", sm: "3" } }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("scaffolds.actual-erect-date-label")
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { attrs: { cols: "6", sm: "3" } },
                              [
                                _c("fd-value-display", {
                                  attrs: { value: item.actualErect }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "6", sm: "3" } }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "scaffolds.actual-dismantle-date-label"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { attrs: { cols: "6", sm: "3" } },
                              [
                                _c("fd-value-display", {
                                  attrs: { value: item.actualDismantle }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "6", sm: "3" } }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.$t("scaffolds.iwp-label")))
                              ])
                            ]),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "6", sm: "3" } }, [
                              !!item.workPackageNames &&
                              item.workPackageNames.length > 0
                                ? _c(
                                    "div",
                                    _vm._l(item.workPackageNames, function(
                                      iwp,
                                      key
                                    ) {
                                      return _c("div", { key: key }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(iwp) +
                                            "\n              "
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "fd-item-placeholder" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("common.not-available"))
                                      )
                                    ]
                                  )
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          {
                            staticClass: "mx-0",
                            staticStyle: { width: "100%" }
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "fd-remove-left-padding-cols-below pl-0",
                                attrs: { cols: "12" }
                              },
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mx-0 px-0",
                                    attrs: { align: "center" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-0",
                                        attrs: { align: "center", cols: "12" }
                                      },
                                      [
                                        _c(
                                          "v-subheader",
                                          {
                                            staticClass: "pa-0 mt-3",
                                            staticStyle: {
                                              "font-size": "1em",
                                              height: "32px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "scaffolds.location-sub-header"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mx-0 pl-0",
                                    attrs: { align: "center" }
                                  },
                                  [
                                    _c("v-divider", {
                                      staticClass: "mx-0 mt-0 px-0"
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mx-0 pl-0 pt-2 pb-3",
                                    attrs: { align: "center" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "3" } },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("scaffolds.area-label")
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "9" } },
                                      [
                                        _c("fd-value-display", {
                                          attrs: { value: item.areaName }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "3" } },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("scaffolds.sub-area-label")
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "9" } },
                                      [
                                        _c("fd-value-display", {
                                          attrs: { value: item.subAreaName }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "3" } },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("scaffolds.location-label")
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6", sm: "9" } },
                                      [
                                        _c("fd-value-display", {
                                          attrs: {
                                            value: item.specificWorkLocation
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          ]
        }
      },
      {
        key: "item.action",
        fn: function({ item }) {
          return [
            _c(
              "div",
              { staticClass: "table-1-action-column-min-width" },
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      icon: "",
                                      link: "",
                                      disabled: _vm.processing,
                                      to: `/scaffolds/${item.id}`
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(
                                      "\n              mdi-pencil\n            "
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                  ]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }