var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { "data-cy": "questionnew" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [_vm._v(_vm._s(_vm.$t("questions.new.dialog-title")))]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-container",
                [
                  _c("v-subheader", [
                    _vm._v(
                      _vm._s(_vm.$t("questions.new.enter-question-text-title"))
                    )
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.questionTextList, function(questionText) {
                    return _c(
                      "v-row",
                      { key: questionText.languageCode },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "12" } },
                          [
                            _c("fd-text-field", {
                              attrs: {
                                label: questionText.languageName,
                                disabled: _vm.processing,
                                rules: [_vm.hasTextRule]
                              },
                              model: {
                                value: questionText.text,
                                callback: function($$v) {
                                  _vm.$set(questionText, "text", $$v)
                                },
                                expression: "questionText.text"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.inlineMessage.message
            ? _c(
                "v-row",
                { staticClass: "mx-2", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        { attrs: { type: _vm.inlineMessage.type } },
                        [
                          _c(
                            "span",
                            { staticStyle: { "white-space": "pre-line" } },
                            [_vm._v(_vm._s(_vm.inlineMessage.message))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: _vm.$vuetify.breakpoint.xsOnly },
                  on: { click: _vm.cancelDialog }
                },
                [_vm._v(_vm._s(_vm.$t("common.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    loading: _vm.saving,
                    disabled: _vm.processing,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.saveDialog }
                },
                [_vm._v(_vm._s(_vm.$t("common.save-and-close")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }