var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "li",
    {
      staticClass: "fd-kanban-item drag-item",
      class: _vm.statusClass,
      attrs: { "data-id": _vm.item.id }
    },
    [
      _vm.itemNeedsStatusCallout && _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "v-row",
            {
              staticClass:
                "fd-kanban-item-secondary-title fd-priority-margin-compensation fd-kanban-item-status-marker-placement"
            },
            [
              _c(
                "div",
                { class: ["py-1", "px-3", "my-2", "fd-status-indicator"] },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.statusIndicatorText) + "\n    "
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "draggable" },
            [
              _c(
                "v-row",
                {
                  class: [
                    "fd-kanban-item-title",
                    {
                      "fd-priority-done-compensation": _vm.itemIsDone,
                      "ml-1": _vm.itemIsDone,
                      "fd-priority-margin-compensation": !_vm.itemIsDone
                    }
                  ]
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$vuetify.breakpoint.mdAndUp
                          ? _vm.cardTitle
                          : _vm.cardTitleShort
                      )
                  ),
                  _c("br"),
                  _vm._v(_vm._s(_vm.tagNumberText) + "\n      ")
                ]
              ),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  class: [
                    "fd-kanban-item-secondary-title",
                    {
                      "fd-priority-done-compensation": _vm.itemIsDone,
                      "ml-1": _vm.itemIsDone,
                      "fd-priority-margin-compensation": !_vm.itemIsDone
                    }
                  ]
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.item.requestTypeName +
                          " (" +
                          _vm.item.requestSubTypeName +
                          ")"
                      ) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _vm.itemNeedsStatusCallout && !_vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "v-row",
                    {
                      class: [
                        "fd-kanban-item-secondary-title",
                        {
                          "ml-1": _vm.itemIsDone,
                          "fd-priority-margin-compensation": !_vm.itemIsDone
                        }
                      ]
                    },
                    [
                      _c(
                        "div",
                        {
                          class: ["py-1", "px-3", "my-2", "fd-status-indicator"]
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.statusIndicatorText) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  class: [
                    "fd-kanban-item-tertiary-title",
                    "mt-1",
                    {
                      "fd-priority-done-compensation": _vm.itemIsDone,
                      "ml-1": _vm.itemIsDone,
                      "fd-priority-margin-compensation": !_vm.itemIsDone
                    }
                  ]
                },
                [
                  _vm.item.fullAreaName
                    ? _c("div", [
                        _vm.$vuetify.breakpoint.mdAndUp
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.item.fullAreaName) +
                                  "\n          "
                              )
                            ])
                          : _c("span", [_vm._v(_vm._s(_vm.item.subAreaName))])
                      ])
                    : _c("div", [_vm._v("\n           \n        ")])
                ]
              ),
              _vm._v(" "),
              !_vm.itemIsDone && _vm.item.isUrgent
                ? _c("div", { staticClass: "fd-kanban-item-order-indicator" }, [
                    _c("div", [
                      _c(
                        "div",
                        [
                          _c(
                            "v-icon",
                            { staticClass: "fd-urgent-to-do-icon" },
                            [_vm._v("fas fa-fire-alt")]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                : !_vm.itemIsDone &&
                  !_vm.item.currentUserPermissions.canEditPriority
                ? _c("div", { staticClass: "fd-kanban-item-order-indicator" }, [
                    _c("div", [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.item.priority) +
                            "\n          "
                        )
                      ])
                    ])
                  ])
                : !_vm.itemIsDone
                ? _c("v-select", {
                    staticClass: "fd-kanban-item-edit-order-selector",
                    attrs: {
                      value: _vm.item.priority,
                      items: [1, 2, 3, 4, 5],
                      dense: _vm.$vuetify.breakpoint.xsOnly
                    },
                    on: {
                      input: value =>
                        _vm.$emit(
                          "saveWorkOrderField",
                          _vm.item,
                          "priority",
                          value,
                          false
                        )
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-expansion-panels",
                { attrs: { accordion: "", tile: "", flat: "" } },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _c(
                          "span",
                          { staticClass: "v-btn__content" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("common.details")) +
                                "\n              "
                            ),
                            _vm.itemTracksAnyAdminWork
                              ? _c("fd-progress-badge", {
                                  staticClass: "pl-1",
                                  attrs: {
                                    disabled: _vm.loading,
                                    success: _vm.cardStatusIsSuccess,
                                    warning: _vm.cardStatusIsWarning,
                                    error: _vm.cardStatusIsError,
                                    "hide-icon":
                                      _vm.$vuetify.breakpoint.xsOnly ||
                                      _vm.cardStatusIsWarning
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel-content",
                        [
                          !_vm.itemIsDone &&
                          _vm.item.type == "walkdown" &&
                          _vm.inProgress
                            ? _c(
                                "v-row",
                                { staticClass: "px-0 pt-2 pb-3 ma-0" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "white--text width-100 px-0 px-sm-3",
                                      attrs: {
                                        loading: _vm.loading,
                                        disabled: _vm.disabled,
                                        color: "primary",
                                        "x-small": _vm.$vuetify.breakpoint.xs
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "openWalkdownDialog",
                                            _vm.item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm.$vuetify.breakpoint.smAndUp
                                        ? _c(
                                            "v-icon",
                                            { attrs: { left: "", dark: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-pencil\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.$vuetify.breakpoint.smAndUp
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "to-do-list.walk-down-label"
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ])
                                        : _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "to-do-list.walk-down-label-short"
                                                )
                                              )
                                            )
                                          ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.itemIsDone && _vm.item.type == "workOrder"
                            ? _c(
                                "v-row",
                                { staticClass: "px-0 pb-2 pt-5 ma-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "pa-0 ma-0 d-flex align-center",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "px-0 py-2 ma-0" },
                                        [
                                          _c("v-switch", {
                                            class: [
                                              _vm.$vuetify.lgAndUp
                                                ? ""
                                                : "fd-raise-control-10",
                                              "pr-0",
                                              "pr-sm-4",
                                              "pr-lg-0",
                                              "pb-5",
                                              "pb-sm-0"
                                            ],
                                            attrs: {
                                              label: _vm.$t(
                                                "to-do-list.on-hold-label"
                                              ),
                                              "input-value":
                                                _vm.itemIsOnHoldWorkOrder,
                                              disabled: !_vm.item
                                                .currentUserPermissions
                                                .canEditStatus
                                            },
                                            on: {
                                              change: v =>
                                                _vm.$emit(
                                                  "toggleWorkOrderOnHold",
                                                  _vm.item,
                                                  v
                                                )
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.item.type == "workOrder"
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 ma-0",
                                          attrs: { cols: "12", sm: "6" }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "pa-0 ma-0" },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  outlined: "",
                                                  dense: "",
                                                  label: _vm.$t(
                                                    "to-do-list.progress-label-explained"
                                                  ),
                                                  value: _vm.item.progress,
                                                  items: _vm.inProgressValues,
                                                  disabled:
                                                    _vm.itemIsOnHoldWorkOrder ||
                                                    !_vm.item
                                                      .currentUserPermissions
                                                      .canEditProgress,
                                                  readonly: !_vm.item
                                                    .currentUserPermissions
                                                    .canEditProgress
                                                },
                                                on: {
                                                  input: value =>
                                                    _vm.$emit(
                                                      "updateWorkOrderProgress",
                                                      _vm.item,
                                                      value
                                                    )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.itemNeedsStatusCallout
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "fd-todo-list-status-message-container fd-kanban-item-details-text-size fd-kanban-item-expander-details-item",
                                  class: [
                                    {
                                      "fd-todo-list-onhold-reason-container":
                                        _vm.itemIsOnHoldWorkOrder ||
                                        _vm.itemIsDeclinedWalkdown,
                                      "fd-todo-list-cancelled-reason-container":
                                        _vm.itemIsCancelled,
                                      "fd-todo-list-urgency-reason-container":
                                        !(
                                          _vm.itemIsOnHoldWorkOrder ||
                                          _vm.itemIsDeclinedWalkdown ||
                                          _vm.itemIsCancelled
                                        ) && _vm.item.isUrgent
                                    }
                                  ]
                                },
                                [
                                  _vm.itemIsCancelled ||
                                  _vm.itemIsOnHoldWorkOrder
                                    ? _c("fd-kanban-item-value-display", {
                                        attrs: {
                                          value: _vm.item.workOrderStatusDetail,
                                          label: _vm.itemIsCancelled
                                            ? _vm.$t("to-do-list.cancel-reason")
                                            : _vm.$t(
                                                "to-do-list.on-hold-reason"
                                              ),
                                          dense: _vm.$vuetify.breakpoint.xs
                                        }
                                      })
                                    : _vm.itemIsDeclinedWalkdown
                                    ? _c("fd-kanban-item-value-display", {
                                        attrs: {
                                          value:
                                            _vm.item.walkdown.approvalComments,
                                          label: _vm.$t(
                                            "to-do-list.decline-reason"
                                          ),
                                          dense: _vm.$vuetify.breakpoint.xs
                                        }
                                      })
                                    : _vm.item.isUrgent
                                    ? _c("fd-kanban-item-value-display", {
                                        attrs: {
                                          value: _vm.item.isUrgentDetail,
                                          label: _vm.$t(
                                            "to-do-list.hot-job-reason"
                                          ),
                                          dense: _vm.$vuetify.breakpoint.xs
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !!_vm.externalTagNumberText
                            ? _c("fd-kanban-item-expander-details-item", {
                                attrs: {
                                  value: _vm.internalTagNumberText,
                                  label: _vm.$t("to-do-list.scaffold-label"),
                                  dense: _vm.$vuetify.breakpoint.xs
                                }
                              })
                            : !!_vm.internalTagNumberText
                            ? _c("fd-kanban-item-expander-details-item", {
                                attrs: {
                                  value: _vm.externalTagNumberText,
                                  label: _vm.$t(
                                    "to-do-list.external-scaffold-label"
                                  ),
                                  dense: _vm.$vuetify.breakpoint.xs
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("fd-kanban-item-expander-details-item", {
                            attrs: {
                              value: _vm.item.disciplineName,
                              label: _vm.$t("to-do-list.discipline-label"),
                              dense: _vm.$vuetify.breakpoint.xs
                            }
                          }),
                          _vm._v(" "),
                          _c("fd-kanban-item-expander-details-item", {
                            attrs: {
                              value: _vm.item.detailedWorkDescription,
                              label: _vm.$t(
                                "to-do-list.work-description-label"
                              ),
                              dense: _vm.$vuetify.breakpoint.xs
                            }
                          }),
                          _vm._v(" "),
                          _c("fd-kanban-item-expander-details-item", {
                            attrs: {
                              value: _vm.item.specificWorkLocation,
                              label: _vm.$t("to-do-list.work-location-label"),
                              dense: _vm.$vuetify.breakpoint.xs
                            }
                          }),
                          _vm._v(" "),
                          _c("fd-kanban-item-expander-details-item", {
                            attrs: {
                              value: _vm.item.siteContact,
                              label: _vm.$t("to-do-list.site-contact-label"),
                              dense: _vm.$vuetify.breakpoint.xs
                            }
                          }),
                          _vm._v(" "),
                          _c("fd-kanban-item-expander-details-item", {
                            attrs: {
                              value: _vm.item.requestingContractorName,
                              label: _vm.$vuetify.breakpoint.xs
                                ? _vm.$t(
                                    "to-do-list.requesting-contractor-label-short"
                                  )
                                : _vm.$t(
                                    "to-do-list.requesting-contractor-label"
                                  ),
                              dense: _vm.$vuetify.breakpoint.xs
                            }
                          }),
                          _vm._v(" "),
                          _c("fd-kanban-item-expander-details-item", {
                            attrs: {
                              value: _vm.item.requestingEmployeeName,
                              label: _vm.$t("to-do-list.requested-by-label"),
                              dense: _vm.$vuetify.breakpoint.xs
                            }
                          }),
                          _vm._v(" "),
                          _c("fd-kanban-item-expander-details-item", {
                            attrs: {
                              value: _vm.item.workOrderStatusName,
                              label: _vm.$vuetify.breakpoint.xs
                                ? _vm.$t("to-do-list.work-order-status-short")
                                : _vm.$t("to-do-list.work-order-status"),
                              dense: _vm.$vuetify.breakpoint.xs
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "px-0 pt-2 pb-0 ma-0" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "white--text width-100 px-0 px-sm-3",
                                  attrs: {
                                    loading: _vm.loading,
                                    disabled: _vm.disabled,
                                    color: "blue-grey",
                                    "x-small": _vm.$vuetify.breakpoint.xs
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit(
                                        "viewNotesForItem",
                                        _vm.item
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c(
                                        "v-icon",
                                        { attrs: { left: "", dark: "" } },
                                        [
                                          _vm._v(
                                            "\n                  fa-clipboard\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "scaffold-requests.existing-scaffold-request.tabs.notes"
                                          )
                                        ) +
                                        "\n                "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("v-badge", {
                                    class: _vm.$vuetify.breakpoint.xs
                                      ? [
                                          "fd-kanban-item-pictures-button-badge-xsmall",
                                          "v-badge--dense"
                                        ]
                                      : "fd-kanban-item-pictures-button-badge",
                                    attrs: {
                                      small: "",
                                      color: !_vm.notesCount
                                        ? "rgba(175,175,175,1)"
                                        : "primary",
                                      content: `${_vm.notesCount}`,
                                      bordered: !!_vm.notesCount
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "px-0 pt-2 pb-0 ma-0" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "white--text width-100 px-0 px-sm-3",
                                  attrs: {
                                    loading: _vm.loading,
                                    disabled: _vm.disabled,
                                    color: "blue-grey",
                                    "x-small": _vm.$vuetify.breakpoint.xs
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit(
                                        "viewAttachmentsForItem",
                                        _vm.item
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c(
                                        "v-icon",
                                        { attrs: { left: "", dark: "" } },
                                        [
                                          _vm._v(
                                            "\n                  fa-paperclip\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c("div", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t("common.attachments")
                                            ) +
                                            "\n                "
                                        )
                                      ])
                                    : _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("common.attachments-short")
                                          )
                                        )
                                      ]),
                                  _vm._v(" "),
                                  _c("v-badge", {
                                    class: _vm.$vuetify.breakpoint.xs
                                      ? [
                                          "fd-kanban-item-pictures-button-badge-xsmall",
                                          "v-badge--dense"
                                        ]
                                      : "fd-kanban-item-pictures-button-badge",
                                    attrs: {
                                      small: "",
                                      color: !_vm.itemAttachments.length
                                        ? "rgba(175,175,175,1)"
                                        : "primary",
                                      content: `${
                                        !!_vm.item.attachments
                                          ? _vm.item.attachments.length
                                          : 0
                                      }`,
                                      bordered: _vm.itemAttachments.length > 0
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "px-0 pt-3 pb-0 ma-0" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "white--text width-100 px-0 px-sm-3",
                                  attrs: {
                                    loading: _vm.loading,
                                    disabled: _vm.disabled,
                                    color: "blue-grey",
                                    "x-small": _vm.$vuetify.breakpoint.xs
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit(
                                        "viewImagesForItem",
                                        _vm.item
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c(
                                        "v-icon",
                                        { attrs: { left: "", dark: "" } },
                                        [
                                          _vm._v(
                                            "\n                  mdi-image-multiple\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c("div", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.$t("common.pictures")) +
                                            "\n                "
                                        )
                                      ])
                                    : _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("common.pictures-short")
                                          )
                                        )
                                      ]),
                                  _vm._v(" "),
                                  _c("v-badge", {
                                    class: _vm.$vuetify.breakpoint.xs
                                      ? [
                                          "fd-kanban-item-pictures-button-badge-xsmall",
                                          "v-badge--dense"
                                        ]
                                      : "fd-kanban-item-pictures-button-badge",
                                    attrs: {
                                      dense: "",
                                      color: !_vm.itemPhotos.length
                                        ? "rgba(175,175,175,1)"
                                        : "primary",
                                      content: `${
                                        !!_vm.item.photos
                                          ? _vm.item.photos.length
                                          : 0
                                      }`,
                                      bordered: _vm.itemPhotos.length > 0
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "px-0 pt-3 pb-0 ma-0" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "white--text width-100 px-0 px-sm-3",
                                  attrs: {
                                    loading: _vm.loading,
                                    disabled: _vm.disabled,
                                    color: "blue-grey",
                                    "x-small": _vm.$vuetify.breakpoint.xs
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit(
                                        "showItemSummary",
                                        _vm.item
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c(
                                        "v-icon",
                                        { attrs: { left: "", dark: "" } },
                                        [
                                          _vm._v(
                                            "\n                  fa-comment-alt-lines\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? _c("div", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t("to-do-list.full-summary")
                                            ) +
                                            "\n                "
                                        )
                                      ])
                                    : _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "to-do-list.full-summary-short"
                                            )
                                          )
                                        )
                                      ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.itemTracksCountSheetEntry
                            ? _c(
                                "v-row",
                                { staticClass: "px-0 pt-3 pb-0 ma-0" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "white--text width-100 px-0 px-sm-3",
                                      attrs: {
                                        loading: _vm.loading,
                                        disabled:
                                          _vm.disabled || !_vm.canEnterMaterial,
                                        color: "blue-grey",
                                        "x-small": _vm.$vuetify.breakpoint.xs
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "showWorkOrderCountSheetDialog",
                                            _vm.item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm.$vuetify.breakpoint.smAndUp
                                        ? _c(
                                            "v-icon",
                                            { attrs: { left: "", dark: "" } },
                                            [
                                              _vm._v(
                                                "\n                  fa-list-ol\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.$vuetify.breakpoint.smAndUp
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t("common.material")
                                                ) +
                                                "\n                "
                                            )
                                          ])
                                        : _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("common.material-short")
                                              )
                                            )
                                          ]),
                                      _vm._v(" "),
                                      _vm.adminTracksCountSheetEntry
                                        ? _c("fd-progress-badge", {
                                            staticClass: "pl-1",
                                            attrs: {
                                              disabled: _vm.loading,
                                              success:
                                                _vm.requiresCountSheetEntry &&
                                                !_vm.missingCountSheetEntry,
                                              error:
                                                _vm.requiresCountSheetEntry &&
                                                _vm.missingCountSheetEntry
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.itemTracksTimeEntry
                            ? _c(
                                "v-row",
                                { staticClass: "px-0 pt-3 pb-0 ma-0" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "white--text width-100 px-0 px-sm-3",
                                      attrs: {
                                        loading: _vm.loading,
                                        disabled:
                                          !_vm.canEnterManHours || _vm.disabled,
                                        color: "blue-grey",
                                        "x-small": _vm.$vuetify.breakpoint.xs
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "showLabourEntryDialog",
                                            _vm.item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm.$vuetify.breakpoint.smAndUp
                                        ? _c(
                                            "v-icon",
                                            { attrs: { left: "", dark: "" } },
                                            [
                                              _vm._v(
                                                "\n                  fas fa-clock\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.$vuetify.breakpoint.smAndUp
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t("common.man-hours")
                                                ) +
                                                "\n                "
                                            )
                                          ])
                                        : _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("common.man-hours-short")
                                              )
                                            )
                                          ]),
                                      _vm._v(" "),
                                      _vm.adminTracksTimeEntry
                                        ? _c("fd-progress-badge", {
                                            staticClass: "pl-1",
                                            attrs: {
                                              disabled: _vm.loading,
                                              success:
                                                _vm.manHoursEntryIsComplete,
                                              warning:
                                                _vm.manHoursEntryIsPartial,
                                              error: _vm.manHoursEntryIsMissing,
                                              "hide-icon":
                                                _vm.$vuetify.breakpoint
                                                  .xsOnly ||
                                                _vm.manHoursEntryIsPartial
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.itemTracksNormsEntry
                            ? _c(
                                "v-row",
                                { staticClass: "px-0 pt-3 pb-0 ma-0" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "white--text width-100 px-0 px-sm-3",
                                      attrs: {
                                        loading: _vm.loading,
                                        disabled:
                                          !_vm.canEnterNorms || _vm.disabled,
                                        color: "blue-grey",
                                        "x-small": _vm.$vuetify.breakpoint.xs
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "showNormsEntryDialog",
                                            _vm.item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm.$vuetify.breakpoint.smAndUp
                                        ? _c(
                                            "v-icon",
                                            { attrs: { left: "", dark: "" } },
                                            [
                                              _vm._v(
                                                "\n                  fas " +
                                                  _vm._s(
                                                    _vm.$store.getters.icon(
                                                      "contractor-norms"
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.$vuetify.breakpoint.smAndUp
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "scheduler.to-do-norms-entry-button"
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ])
                                        : _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduler.to-do-norms-entry-button-short"
                                                )
                                              )
                                            )
                                          ]),
                                      _vm._v(" "),
                                      _vm.adminTracksNormsEntry
                                        ? _c("fd-progress-badge", {
                                            staticClass: "pl-1",
                                            attrs: {
                                              disabled: _vm.loading,
                                              success:
                                                _vm.requiresNormsEntry &&
                                                !_vm.missingNormsEntry,
                                              error:
                                                _vm.requiresNormsEntry &&
                                                _vm.missingNormsEntry
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.itemTracksScaffoldLocationEntry
                            ? _c(
                                "v-row",
                                { staticClass: "px-0 pt-3 pb-0 ma-0" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "white--text width-100 px-0 px-sm-3",
                                      attrs: {
                                        loading: _vm.loading,
                                        disabled:
                                          !_vm.canEnterScaffoldLocation ||
                                          _vm.disabled,
                                        color: "blue-grey",
                                        "x-small": _vm.$vuetify.breakpoint.xs
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "showScaffoldLocationEntryDialog",
                                            _vm.item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm.$vuetify.breakpoint.smAndUp
                                        ? _c(
                                            "v-icon",
                                            { attrs: { left: "", dark: "" } },
                                            [
                                              _vm._v(
                                                "\n                  fas " +
                                                  _vm._s(
                                                    _vm.$store.getters.icon(
                                                      "scaffold-location"
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.$vuetify.breakpoint.smAndUp
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "scheduler.to-do-scaffold-location-entry-button"
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ])
                                        : _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "scheduler.to-do-scaffold-location-entry-button-short"
                                                )
                                              )
                                            )
                                          ]),
                                      _vm._v(" "),
                                      _vm.adminTracksScaffoldLocationEntry
                                        ? _c("fd-progress-badge", {
                                            staticClass: "pl-1",
                                            attrs: {
                                              disabled: _vm.loading,
                                              success:
                                                _vm.requiresScaffoldLocationEntry &&
                                                !_vm.missingScaffoldLocationEntry,
                                              error:
                                                _vm.requiresScaffoldLocationEntry &&
                                                _vm.missingScaffoldLocationEntry
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }