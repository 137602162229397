import { scaffoldCongestionFactorService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: scaffoldCongestionFactorService,
  singularStoreName: "SCAFFOLD_CONGESTION_FACTOR",
  pluralStoreName: "SCAFFOLD_CONGESTION_FACTORS",
  localizationPrefix: "modifiers.scaffold-congestion-factor",
  consumerRelatedIDProperty: {
    name: "scaffoldCongestionFactorID",
    type: "string"
  }
});

export default storeModule;

