var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: {
        width: "1024px",
        persistent: "",
        "data-cy": "addtimesheetentriesdialog"
      },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [_vm._v(_vm._s(_vm.$t("timesheets.entries.dialog-title")))]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-form",
            { ref: "addform", on: { submit: _vm.addFormOnSubmit } },
            [
              _c(
                "v-container",
                { staticClass: "pt-0" },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-2 pt-3" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "align-center justify-space-between pb-0 pt-2",
                          attrs: { cols: "12", sm: "4" }
                        },
                        [
                          _c("fd-async-search-box", {
                            attrs: {
                              dense: "",
                              refreshItems: _vm.loadWorkOrders,
                              items: _vm.availableWorkOrders,
                              selectedItems: _vm.selectedWorkOrder,
                              label: _vm.$t(
                                "timesheets.entries.work-order-label"
                              ),
                              multiple: false,
                              itemText: "description",
                              itemDetail: "details",
                              chipClass:
                                "keywords-selection-chip-colors-secondary",
                              disabled:
                                _vm.processing || !_vm.canSelectWorkOrder,
                              "persistent-hint": !!_vm.scaffoldNumber,
                              hint: !!_vm.scaffoldNumber
                                ? _vm.$t(
                                    "timesheets.entries.scaffold-with-number-label",
                                    [_vm.scaffoldNumber]
                                  )
                                : ""
                            },
                            on: {
                              "update:selectedItems": function($event) {
                                _vm.selectedWorkOrder = $event
                              },
                              "update:selected-items": function($event) {
                                _vm.selectedWorkOrder = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "align-center justify-space-between pb-0 pt-2",
                          attrs: { cols: "12", sm: "4" }
                        },
                        [
                          _c("v-select", {
                            staticClass: "fd-readonly",
                            attrs: {
                              "data-cy": "areas",
                              dense: "",
                              outlined: "",
                              items: _vm.selectableAreas,
                              "item-text": "name",
                              "item-value": "id",
                              label: _vm.$t("timesheets.entries.area-label"),
                              disabled: _vm.processing,
                              readonly: true
                            },
                            model: {
                              value: _vm.selectedAreaID,
                              callback: function($$v) {
                                _vm.selectedAreaID = $$v
                              },
                              expression: "selectedAreaID"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "align-center justify-space-between pb-0 pt-2",
                          attrs: { cols: "12", sm: "4" }
                        },
                        [
                          _c("v-select", {
                            staticClass: "fd-readonly",
                            attrs: {
                              "data-cy": "subareas",
                              dense: "",
                              outlined: "",
                              items: _vm.selectableSubAreas,
                              "item-text": "name",
                              "item-value": "id",
                              label: _vm.$t(
                                "timesheets.entries.sub-area-label"
                              ),
                              disabled: _vm.processing,
                              readonly: true
                            },
                            model: {
                              value: _vm.selectedSubAreaID,
                              callback: function($$v) {
                                _vm.selectedSubAreaID = $$v
                              },
                              expression: "selectedSubAreaID"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "align-center justify-space-between pb-0 pt-2",
                          attrs: { cols: "12", sm: "4" }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              "data-cy": "worktypes",
                              dense: "",
                              outlined: "",
                              items: _vm.selectableWorkTypes,
                              "item-text": "name",
                              "item-value": "id",
                              label: _vm.$t(
                                "timesheets.entries.work-type-label"
                              ),
                              clearable: "",
                              disabled: _vm.processing,
                              rules: [_vm.rules.required]
                            },
                            model: {
                              value: _vm.selectedWorkTypeID,
                              callback: function($$v) {
                                _vm.selectedWorkTypeID = $$v
                              },
                              expression: "selectedWorkTypeID"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "align-center justify-space-between pb-0 pt-2",
                          attrs: { cols: "12", sm: "4" }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              "data-cy": "worksubtypes",
                              dense: "",
                              outlined: "",
                              items: _vm.selectableWorkSubTypes,
                              "item-text": "name",
                              "item-value": "id",
                              label: _vm.$t(
                                "timesheets.entries.work-sub-type-label"
                              ),
                              clearable: "",
                              disabled:
                                _vm.processing || !_vm.selectedWorkTypeID,
                              rules: [_vm.rules.required]
                            },
                            model: {
                              value: _vm.selectedWorkSubTypeID,
                              callback: function($$v) {
                                _vm.selectedWorkSubTypeID = $$v
                              },
                              expression: "selectedWorkSubTypeID"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.timesheet.currentUserPermissions.canEditCostCode
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "align-center justify-space-between pb-0 pt-2",
                              attrs: { cols: "12", sm: "4" }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  "data-cy": "costcodes",
                                  dense: "",
                                  outlined: "",
                                  items: _vm.selectableCostCodes,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: _vm.$t(
                                    "timesheets.entries.cost-code-label"
                                  ),
                                  clearable: "",
                                  disabled: _vm.processing,
                                  rules: [_vm.rules.required]
                                },
                                model: {
                                  value: _vm.selectedCostCodeID,
                                  callback: function($$v) {
                                    _vm.selectedCostCodeID = $$v
                                  },
                                  expression: "selectedCostCodeID"
                                }
                              })
                            ],
                            1
                          )
                        : _c("v-col", {
                            staticClass:
                              "align-center justify-space-between pb-0 pt-2",
                            attrs: { cols: "12", sm: "4" }
                          }),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "align-center justify-space-between pb-0 pt-2",
                          attrs: { cols: "12", sm: "4" }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              "data-cy": "employees",
                              dense: "",
                              outlined: "",
                              items: _vm.selectableEmployees,
                              "item-text": "name",
                              "item-value": "id",
                              label: _vm.currentTimesheetIsEquipment
                                ? _vm.$t("timesheets.entries.equipment-label")
                                : _vm.$t("timesheets.entries.employee-label"),
                              clearable: "",
                              disabled: _vm.processing || !!_vm.selectedCrewID,
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.selectedEmployeeID,
                              callback: function($$v) {
                                _vm.selectedEmployeeID = $$v
                              },
                              expression: "selectedEmployeeID"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "align-center justify-space-between pb-0 pt-2",
                          attrs: { cols: "12", sm: "4" }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              "data-cy": "crews",
                              dense: "",
                              outlined: "",
                              items: _vm.selectableCrews,
                              "item-text": "name",
                              "item-value": "id",
                              label: _vm.$t("timesheets.entries.crew-label"),
                              clearable: "",
                              disabled:
                                _vm.processing || !!_vm.selectedEmployeeID,
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.selectedCrewID,
                              callback: function($$v) {
                                _vm.selectedCrewID = $$v
                              },
                              expression: "selectedCrewID"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "justify-space-between pb-0 pt-2",
                          attrs: { align: "center", cols: "12", sm: "4" }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "py-2",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    outlined: "",
                                    disabled: _vm.processing,
                                    small: _vm.$vuetify.breakpoint.xsOnly
                                  },
                                  on: { click: _vm.clearValues }
                                },
                                [_vm._v(_vm._s(_vm.$t("common.clear-all")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.processing,
                                    disabled:
                                      _vm.processing ||
                                      !_vm.canAddEntriesWithCurrentSelections,
                                    small: _vm.$vuetify.breakpoint.xsOnly
                                  },
                                  on: { click: _vm.addEntriesToWorkspace }
                                },
                                [_vm._v(_vm._s(_vm.$t("common.add")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-form",
            { ref: "form", on: { submit: _vm.onSubmit } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c("v-data-table", {
                    directives: [
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:employeeName",
                        value: _vm.$t(
                          "timesheets.existing.employee-column-label"
                        ),
                        expression:
                          "$t('timesheets.existing.employee-column-label')",
                        arg: "employeeName"
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:workOrderNumber",
                        value: _vm.$t(
                          "timesheets.existing.work-order-column-label"
                        ),
                        expression:
                          "$t('timesheets.existing.work-order-column-label')",
                        arg: "workOrderNumber"
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:workTypeName",
                        value: _vm.$t(
                          "timesheets.existing.work-type-column-label"
                        ),
                        expression:
                          "$t('timesheets.existing.work-type-column-label')",
                        arg: "workTypeName"
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:regularTime",
                        value: _vm.$t(
                          "timesheets.existing.regular-time-column-label"
                        ),
                        expression:
                          "$t('timesheets.existing.regular-time-column-label')",
                        arg: "regularTime"
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:perDiemUnits",
                        value: _vm.$t(
                          "timesheets.existing.per-diem-units-column-label"
                        ),
                        expression:
                          "$t('timesheets.existing.per-diem-units-column-label')",
                        arg: "perDiemUnits"
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:doubleTime",
                        value: _vm.$t(
                          "timesheets.existing.double-time-column-label"
                        ),
                        expression:
                          "$t('timesheets.existing.double-time-column-label')",
                        arg: "doubleTime"
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:equipmentUnits",
                        value: _vm.$t(
                          "timesheets.existing.equipment-units-column-label"
                        ),
                        expression:
                          "$t('timesheets.existing.equipment-units-column-label')",
                        arg: "equipmentUnits"
                      },
                      {
                        name: "fd-column",
                        rawName:
                          "v-fd-column:action.class_fd-action-cell.no-sort",
                        arg: "action",
                        modifiers: {
                          "class_fd-action-cell": true,
                          "no-sort": true
                        }
                      }
                    ],
                    ref: "datatable",
                    staticClass: "fd-timesheets-list",
                    attrs: {
                      page: _vm.tablepage,
                      loading: _vm.processing,
                      "loading-text": _vm.$t("common.table-loading-message"),
                      items: _vm.timesheet.entries,
                      "mobile-breakpoint": "0",
                      "show-expand": ""
                    },
                    on: {
                      "update:page": function($event) {
                        _vm.tablepage = $event
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "expanded-item",
                        fn: function({ item }) {
                          return [
                            _c("td", { attrs: { colspan: "99" } }, [
                              _c(
                                "div",
                                { staticClass: "fd-details-section pt-3" },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-0",
                                      staticStyle: { width: "100%" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pl-0 pr-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mx-0",
                                              staticStyle: { width: "100%" }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "timesheets.existing.work-order-column-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("fd-value-display", {
                                                    attrs: {
                                                      value:
                                                        item.workOrderNumber
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "timesheets.existing.scaffold-column-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("fd-value-display", {
                                                    attrs: {
                                                      value: item.scaffoldNumber
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "timesheets.existing.area-column-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("fd-value-display", {
                                                    attrs: {
                                                      value: item.areaName
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "timesheets.existing.sub-area-column-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("fd-value-display", {
                                                    attrs: {
                                                      value: item.subAreaName
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "timesheets.existing.work-type-column-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("fd-value-display", {
                                                    attrs: {
                                                      value: item.workTypeName
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "timesheets.existing.work-sub-type-column-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("fd-value-display", {
                                                    attrs: {
                                                      value:
                                                        item.workSubTypeName
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "timesheets.existing.classification-column-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("fd-value-display", {
                                                    attrs: {
                                                      value:
                                                        item.classificationName
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "timesheets.existing.cost-code-column-label"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "6", sm: "3" }
                                                },
                                                [
                                                  _c("fd-value-display", {
                                                    attrs: {
                                                      value: item.costCodeName
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        }
                      },
                      {
                        key: "item.regularTime",
                        fn: function({ item }) {
                          return [
                            _vm.timesheet.timesheetTypeID == 2
                              ? _c("fd-value-display", {
                                  attrs: {
                                    "no-value-key":
                                      "timesheets.list.no-time-entry-value"
                                  }
                                })
                              : !_vm.canEditRegularTimeHoursForItem(item)
                              ? _c("fd-value-display", {
                                  attrs: {
                                    value: item.formattedRegularTime,
                                    "no-value-key":
                                      "timesheets.list.no-time-value"
                                  }
                                })
                              : _c("fd-text-field", {
                                  ref: _vm.getFieldRef("regularTime", item),
                                  staticClass: "fd-short-table-input",
                                  attrs: {
                                    "data-id": item.id,
                                    "data-cy": "regularTime",
                                    disabled: _vm.processing,
                                    numeric: "",
                                    outlined: false,
                                    "highlight-on-focus": ""
                                  },
                                  on: {
                                    keydown: [
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "up",
                                            38,
                                            $event.key,
                                            ["Up", "ArrowUp"]
                                          )
                                        )
                                          return null
                                        return _vm.selectPreviousField(
                                          "regularTime",
                                          item
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        )
                                          return null
                                        return _vm.selectNextField(
                                          "regularTime",
                                          item
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return (e =>
                                          _vm.enterPressed(
                                            e,
                                            "regularTime",
                                            item
                                          )).apply(null, arguments)
                                      }
                                    ]
                                  },
                                  model: {
                                    value: item.regularTime,
                                    callback: function($$v) {
                                      _vm.$set(item, "regularTime", $$v)
                                    },
                                    expression: "item.regularTime"
                                  }
                                })
                          ]
                        }
                      },
                      {
                        key: "item.perDiemUnits",
                        fn: function({ item }) {
                          return [
                            _vm.timesheet.timesheetTypeID == 2
                              ? _c("fd-value-display", {
                                  attrs: {
                                    "no-value-key":
                                      "timesheets.list.no-time-entry-value"
                                  }
                                })
                              : !_vm.canEditPerDiemUnitsForItem(item)
                              ? _c("fd-value-display", {
                                  attrs: {
                                    value: item.formattedUnits,
                                    "no-value-key":
                                      "timesheets.list.no-time-value"
                                  }
                                })
                              : _c("fd-text-field", {
                                  ref: _vm.getFieldRef("units", item),
                                  staticClass: "fd-short-table-input",
                                  attrs: {
                                    "data-id": item.id,
                                    "data-cy": "units",
                                    disabled: _vm.processing,
                                    numeric: "",
                                    outlined: false,
                                    "highlight-on-focus": ""
                                  },
                                  on: {
                                    keydown: [
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "up",
                                            38,
                                            $event.key,
                                            ["Up", "ArrowUp"]
                                          )
                                        )
                                          return null
                                        return _vm.selectPreviousField(
                                          "units",
                                          item
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        )
                                          return null
                                        return _vm.selectNextField(
                                          "units",
                                          item
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return (e =>
                                          _vm.enterPressed(
                                            e,
                                            "units",
                                            item
                                          )).apply(null, arguments)
                                      }
                                    ]
                                  },
                                  model: {
                                    value: item.units,
                                    callback: function($$v) {
                                      _vm.$set(item, "units", $$v)
                                    },
                                    expression: "item.units"
                                  }
                                })
                          ]
                        }
                      },
                      {
                        key: "item.doubleTime",
                        fn: function({ item }) {
                          return [
                            _vm.timesheet.timesheetTypeID != 2
                              ? _c("fd-value-display", {
                                  attrs: {
                                    "no-value-key":
                                      "timesheets.list.no-time-entry-value"
                                  }
                                })
                              : !_vm.canEditDoubleTimeHoursForItem(item)
                              ? _c("fd-value-display", {
                                  attrs: {
                                    value: item.formattedDoubleTime,
                                    "no-value-key":
                                      "timesheets.list.no-time-value"
                                  }
                                })
                              : _c("fd-text-field", {
                                  ref: _vm.getFieldRef("doubleTime", item),
                                  staticClass: "fd-short-table-input",
                                  attrs: {
                                    "data-id": item.id,
                                    "data-cy": "doubleTime",
                                    disabled: _vm.processing,
                                    numeric: "",
                                    outlined: false,
                                    "highlight-on-focus": ""
                                  },
                                  on: {
                                    keydown: [
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "up",
                                            38,
                                            $event.key,
                                            ["Up", "ArrowUp"]
                                          )
                                        )
                                          return null
                                        return _vm.selectPreviousField(
                                          "doubleTime",
                                          item
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        )
                                          return null
                                        return _vm.selectNextField(
                                          "doubleTime",
                                          item
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return (e =>
                                          _vm.enterPressed(
                                            e,
                                            "doubleTime",
                                            item
                                          )).apply(null, arguments)
                                      }
                                    ]
                                  },
                                  model: {
                                    value: item.doubleTime,
                                    callback: function($$v) {
                                      _vm.$set(item, "doubleTime", $$v)
                                    },
                                    expression: "item.doubleTime"
                                  }
                                })
                          ]
                        }
                      },
                      {
                        key: "item.equipmentUnits",
                        fn: function({ item }) {
                          return [
                            _vm.timesheet.timesheetTypeID != 2
                              ? _c("fd-value-display", {
                                  attrs: {
                                    "no-value-key":
                                      "timesheets.list.no-time-entry-value"
                                  }
                                })
                              : !_vm.canEditEquipmentUnitsForItem(item)
                              ? _c("fd-value-display", {
                                  attrs: {
                                    value: item.formattedUnits,
                                    "no-value-key":
                                      "timesheets.list.no-time-value"
                                  }
                                })
                              : _c("fd-text-field", {
                                  ref: _vm.getFieldRef("units", item),
                                  staticClass: "fd-short-table-input",
                                  attrs: {
                                    "data-id": item.id,
                                    "data-cy": "units",
                                    disabled: _vm.processing,
                                    numeric: "",
                                    outlined: false,
                                    "highlight-on-focus": ""
                                  },
                                  on: {
                                    keydown: [
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "up",
                                            38,
                                            $event.key,
                                            ["Up", "ArrowUp"]
                                          )
                                        )
                                          return null
                                        return _vm.selectPreviousField(
                                          "units",
                                          item
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "down",
                                            40,
                                            $event.key,
                                            ["Down", "ArrowDown"]
                                          )
                                        )
                                          return null
                                        return _vm.selectNextField(
                                          "units",
                                          item
                                        )
                                      },
                                      function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return (e =>
                                          _vm.enterPressed(
                                            e,
                                            "units",
                                            item
                                          )).apply(null, arguments)
                                      }
                                    ]
                                  },
                                  model: {
                                    value: item.units,
                                    callback: function($$v) {
                                      _vm.$set(item, "units", $$v)
                                    },
                                    expression: "item.units"
                                  }
                                })
                          ]
                        }
                      },
                      {
                        key: "item.action",
                        fn: function({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "table-1-action-column-min-width"
                              },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      disabled:
                                        !_vm.$vuetify.breakpoint.lgAndUp ||
                                        _vm.processing,
                                      left: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function({ on }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      disabled: _vm.processing
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { small: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.removeEntry(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      mdi-delete\n                    "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("common.remove")))
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.inlineMessage.message
                ? _c(
                    "v-row",
                    { staticClass: "mx-2", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            { attrs: { type: _vm.inlineMessage.type } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "white-space": "pre-line" } },
                                [_vm._v(_vm._s(_vm.inlineMessage.message))]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        disabled: _vm.processing,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.cancelDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.processing,
                        disabled: _vm.processing,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.saveDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.save")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }