import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import {
  ExternalLink,
  ISO,
  System,
  TestPackage,
  blobFileService,
  externalLinkService
} from "../../../services";
import { SortItemsWithName } from "../../../utils/person";
import fileHandling, {
  FileData,
  canOpenFileInNewWindow,
  componentsFromFileName,
  confirmUniqueName
} from "@fd/lib/vue/mixins/fileHandling";
import { Attachment } from "../../../dataMixins/attachment";
import { showTextPromptDialog } from "../../../../../common/client/views/components/TextPromptDialog.vue";
import { openExternalLinkDetails } from "../ExternalLinkDialog.vue";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";

type ISONewDialogResult = boolean;
const ISONewDialog = FDVue.extend({
  name: "sp-iso-new-dialog",

  mixins: [dialogSupport, rules, fileHandling],

  directives: {
    fdColumn: FDColumnDirective
  },

  data: function() {
    return {
      chooseTestPackage: false,
      systemID: null as string | null,
      saving: false,

      step: 1,
      lastStep: 2,

      detailsStep: {
        name: this.$t("systems.test-packages.isos.new.steps.details"),
        number: 1,
        error: false
      },
      attachmentsStep: {
        name: this.$t("systems.test-packages.isos.new.steps.attachments"),
        number: 2,
        error: false
      },

      iso: {
        testPackageID: "",
        name: "",
        description: "",
        code: ""
      } as ISO,

      // *** PHOTOS & ATTACHMENTS ***
      touchedFileName: "",
      tablesearchfiles: "",
      allFiles: [] as FileData[],
      externalLinks: [] as ExternalLink[],

      /*** IMAGE EDIT ****/
      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as FileData | undefined
    };
  },

  computed: {
    unwatchedMethodNames(): string[] {
      return [
        "open",
        "setupData",
        "testPackagesForSelectedSystem",
        "fileInputChanged",
        "fileRowClassName"
      ];
    },
    systems(): System[] {
      return SortItemsWithName(this.$store.state.systems.fullList as System[]);
    },
    testPackages(): TestPackage[] {
      return this.$store.state.testPackages.fullList as TestPackage[];
    },
    testPackagesForSelectedSystem(): TestPackage[] {
      return SortItemsWithName(this.testPackages.filter(x => x.systemID == this.systemID));
    },
    isoRules() {
      return {
        testPackageID: [],
        name: [this.rules.required],
        code: [],
        description: []
      };
    },
    //#region "Attachments"
    attachments(): Attachment[] {
      let attachments = [] as Attachment[];

      this.allFiles.forEach(file => {
        attachments.push({
          type: "file",
          name: file.name,
          isPhoto: file.isPreviewable ?? false,
          isPreviewable: file.isPreviewable ?? false,
          canOpenInNew: canOpenFileInNewWindow(file.name),
          file: file
        });
      });

      this.externalLinks.forEach(link => {
        attachments.push({
          type: "link",
          name: link.name!,
          isPhoto: false,
          isPreviewable: false,
          canOpenInNew: true,
          link: link
        });
      });

      return attachments;
    }
    //#endregion
  },
  watch: {
    selectedSystemID(newValue, oldvalue) {
      console.log(`selectedSystemID changed: ${oldvalue}->${newValue}`);
    }
  },

  methods: {
    ...mapActions({
      loadTestPackages: "LOAD_TEST_PACKAGES",
      loadSystems: "LOAD_SYSTEMS"
    }),
    async setupData() {
      this.processing = true;
      try {
        var calls = [];
        if (!this.systems.length) calls.push(this.loadSystems());
        if (!this.testPackages.length) calls.push(this.loadTestPackages());
        await Promise.all(calls);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }

      if (!!this.iso.testPackageID) {
        var selectedTestPackage = this.testPackages.find(x => x.id == this.iso.testPackageID);
        this.systemID = selectedTestPackage?.systemID ?? null;
      }
    },
    async open(testPackageID?: string | null | undefined) {
      if (!!testPackageID) this.iso.testPackageID = testPackageID;
      else this.chooseTestPackage = true;

      this.setupData();

      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },
    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.saving = true;
      this.processing = true;
      try {
        let newIsoID = await this.addISO({
          ...this.iso
        });
        this.iso.id = newIsoID;

        if (this.allFiles.length) {
          for (let index = 0; index < this.allFiles.length; index++) {
            const file = this.allFiles[index];
            await blobFileService.uploadIsoFile(newIsoID, file.name, file.file as Blob);
          }
        }
        if (this.externalLinks.length) {
          for (let index = 0; index < this.externalLinks.length; index++) {
            const newLink = this.externalLinks[index];
            await externalLinkService.addItem({
              isoID: newIsoID,
              name: newLink.name,
              address: newLink.address
            } as ExternalLink);
          }
        }
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    ...mapActions({
      addISO: "ADD_ISO"
    }),

    //#region "Attachments"
    // Attachments - Catch the generic "Attachment" objects and pass along to link or file-specific actions
    async openAttachment(item: Attachment) {
      if (!item.canOpenInNew) return;

      if (!!item.file && item.canOpenInNew) {
        await this.openFileInNewWindow(item.file);
      } else if (!!item.link) {
        let url = item.link.address;
        window.open(url, "_blank");
      }
    },
    async openFileInNewWindow(fileData: FileData) {
      let currentProcessing = this.processing;
      this.processing = true;
      if (!fileData.file) {
        return;
      }
      let url = URL.createObjectURL(fileData.file);
      window.open(url, "_blank");
      this.processing = currentProcessing;
    },
    async editAttachment(item: Attachment) {
      if (!!item.link) {
        await this.editLink(item.link);
      } else if (!!item.file && item.file.isPreviewable) {
        await this.editFile(item.file);
      } else if (!!item.file) {
        await this.editNameForFile(item.file);
      }
    },
    attachmentRowClicked(e: Event, data: any) {
      this.openAttachment(data.item);
    },

    async editNameForFile(fileData: FileData) {
      let components = componentsFromFileName(fileData.name);
      let newName = await showTextPromptDialog({
        title: this.$t("attachments.edit-file-name-title"),
        label: this.$t("common.name"),
        rules: [this.rules.required],
        text: components.name
      });
      if (!!newName?.length && newName.toLowerCase() != components.name.toLowerCase()) {
        let newFileName = `${newName}.${components.extension}`;
        var originalFileName = fileData.name;
        if (newFileName.toLowerCase() == originalFileName.toLowerCase()) return;

        var uniqueFileName = confirmUniqueName(newFileName, this.allFiles);

        fileData.name = uniqueFileName;
        this.touchedFileName = fileData.name;

        this.editingFileData = undefined;
      }
    },
    async deleteAttachment(item: Attachment) {
      if (!!item.link) {
        await this.deleteLink(item.link);
      } else if (!!item.file) {
        await this.removeFile(item.file);
      }
    },

    // Links
    // Method to open the dialog for when the user wishes to add a new External Link.
    async openNewExternalLinkDialog() {
      let newLink = await openExternalLinkDetails();
      if (!!newLink) {
        await this.saveNewExternalLink(newLink);
      }
    },
    async saveNewExternalLink(newLink: ExternalLink) {
      newLink.isoID = this.iso.id;
      this.externalLinks.push(newLink);

      this.touchedFileName = newLink.name ?? "";
    },
    async editLink(link: ExternalLink) {
      let editedLink = await openExternalLinkDetails(link);
      if (!!editedLink) {
        link.name = editedLink.name;
        link.address = editedLink.address;

        this.touchedFileName = link.name ?? "";
      }
    },
    async deleteLink(link: ExternalLink) {
      this.externalLinks.splice(this.externalLinks.indexOf(link), 1);

      this.touchedFileName = link.name ?? "";
    },

    // Files & Photos
    fileRowClassName(item: any): string {
      return item.name == this.touchedFileName ? "fd-selected-table-row-background" : "";
    },
    async selectFile() {
      (this.$refs.addFileButton as any).click();
    },
    async fileInputChanged(v: any) {
      if (!v.target?.files.length) return;
      await this.selectNewFile(v.target.files[0]);
    },
    async selectNewFile(originalFile: File | null | undefined) {
      if (!originalFile) return;
      var fileData = await this.optimizedFileDataForUpload(originalFile, this.allFiles);
      if (!fileData) return;

      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.allFiles.push(fileData);

        this.touchedFileName = fileData.name;
      }
    },
    editFile(fileData: FileData | null | undefined) {
      if (!fileData || !fileData.isPreviewable) return;

      this.editingFileData = fileData;
      this.imageName = fileData.name;
      this.editImageSource = this.covertFileToDataURL(fileData.file);
    },
    async handleEdit(res: File, fileName: string | undefined) {
      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;
        if (!!fileName) this.newFileData.name = confirmUniqueName(fileName, this.allFiles);

        this.allFiles.push(this.newFileData);

        this.touchedFileName = this.newFileData.name;

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.allFiles.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.touchedFileName = this.editingFileData.name;

        this.editingFileData = undefined;
      }
    },
    viewPhoto(fileData: FileData) {
      this.imageName = fileData.name;
      this.imageSource = this.covertFileToDataURL(fileData.file);
    },
    removeFile(fileData: FileData) {
      var fileIndex = this.allFiles.indexOf(fileData);
      if (fileIndex == undefined) return;

      this.allFiles.splice(fileIndex, 1);
      this.touchedFileName = fileData.name;
    }

    //#endregion
  }
});

export default ISONewDialog;

export async function showISONewWithTestPackageDialog(
  testPackageID: string
): Promise<ISONewDialogResult> {
  let dialog = createDialog(ISONewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(testPackageID);
}
export async function showISONewDialog(): Promise<ISONewDialogResult> {
  let dialog = createDialog(ISONewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open();
}

