import { mapMutations, mapActions, mapState } from "vuex";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import rules from "@fd/lib/vue/rules";
import { BuildDismantleRatio } from "@fd/current/client/services";

type BuildDismantleRatioWithDetails = BuildDismantleRatio & { archived: boolean };

export default FDVue.extend({
  mixins: [errorHandling, rules],

  name: "sp-build-dismantle-ratio-existing",

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,

      slidein: false,

      buildDismantleRatio: {} as BuildDismantleRatioWithDetails
    };
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.buildDismantleRatio.archived) {
          this.buildDismantleRatio.archivedDate = null;
        } else if (this.buildDismantleRatio.archived && !this.buildDismantleRatio.archivedDate) {
          this.buildDismantleRatio.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateBuildDismantleRatio({
          ...this.buildDismantleRatio,
          erectMultiplier: !!this.buildDismantleRatio.erectMultiplier
            ? +this.buildDismantleRatio.erectMultiplier
            : undefined,
          dismantleMultiplier: !!this.buildDismantleRatio.dismantleMultiplier
            ? +this.buildDismantleRatio.dismantleMultiplier
            : undefined,
          id: this.$route.params.id
        });

        if (closeOnComplete) {
          this.$router.push("/estimatesetup");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteBuildDismantleRatio({
          id: this.$route.params.id,
          name: this.buildDismantleRatio.ratio
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.$router.push("/estimatesetup");
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push("/estimatesetup");
    },
    ...mapMutations({
      setBuildDismantleRatio: "SET_BUILD_DISMANTLE_RATIO",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadBuildDismantleRatio: "LOAD_BUILD_DISMANTLE_RATIO",
      updateBuildDismantleRatio: "UPDATE_BUILD_DISMANTLE_RATIO",
      deleteBuildDismantleRatio: "DELETE_BUILD_DISMANTLE_RATIO"
    })
  },

  watch: {
    buildDismantleRatio(newValue) {
      console.log(`watch.buildDismantleRatio`);
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/estimatesetup") {
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        this.notifyNewBreadcrumb({
          text: this.$t("estimate-setup.title"),
          to: "/estimatesetup",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      this.notifyNewBreadcrumb({
        text: this.buildDismantleRatio.ratio,
        to: `/estimatesetup/builddismantleratios/${this.$route.params.id}`,
        history: [
          {
            text: this.$t("modifiers.build-dismantle-ratio.title"),
            disabled: true
          }
        ]
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/estimatesetup") {
      this.notifyNewBreadcrumb({
        text: this.$t("estimate-setup.title"),
        to: "/estimatesetup",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }

    this.notifyNewBreadcrumb({
      text: this.$t("loading-dot-dot-dot"),
      disabled: true,
      history: [
        {
          text: this.$t("modifiers.build-dismantle-ratio.title"),
          disabled: true
        }
      ]
    });

    this.processing = true;
    try {
      await this.loadBuildDismantleRatio(this.$route.params.id);
      var buildDismantleRatio = this.$store.state.buildDismantleRatios.fullList.find(
        (x: BuildDismantleRatio) => x.id == this.$route.params.id
      );
      this.buildDismantleRatio = {
        ...buildDismantleRatio,
        archived: !!buildDismantleRatio.archivedDate
      } as BuildDismantleRatioWithDetails;
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

