import FDVue from "@fd/lib/vue";
import { mapMutations, mapState, mapActions } from "vuex";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { Classification } from "../services";
import rules from "@fd/lib/vue/rules";

type ClassificationWithArchived = Classification & {
  archived: boolean;
};

export default FDVue.extend({
  mixins: [errorHandling, rules],

  name: "fd-classification-existing",

  components: {},

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      saving: false,
      deleting: false,

      slidein: false,

      classification: {} as ClassificationWithArchived
    };
  },

  computed: {},

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },
    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.classification.archived) {
          this.classification.archivedDate = null;
        } else if (this.classification.archived && !this.classification.archivedDate) {
          this.classification.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateClassification({
          ...this.classification
        });
        if (closeOnComplete) {
          this.$router.push("/classifications");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      this.deleting = true;
      try {
        await this.deleteClassification({
          id: this.$route.params.id,
          name: this.classification.name
        });
        this.$router.push("/classifications");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push("/classifications");
    },

    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadClassification: "LOAD_CLASSIFICATION",
      updateClassification: "UPDATE_CLASSIFICATION",
      deleteClassification: "DELETE_CLASSIFICATION"
    })
  },

  watch: {
    classification(newValue) {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/classifications") {
        this.notifyNewBreadcrumb({
          text: this.$t("classifications.list.title"),
          to: "/classifications",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: newValue.name,
        to: `/classifications/${this.$route.params.id}`
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    this.processing = true;
    try {
      await Promise.all([this.loadClassification(this.$route.params.id)]);

      let classification = this.$store.state.classifications.fullList.find(
        (x: any) => x.id == this.$route.params.id
      );
      this.classification = { ...classification, archived: !!classification.archivedDate };
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});


