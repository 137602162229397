// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.PinturaRoot {
  --color-background: 30, 30, 30;
  --color-foreground: 255, 255, 255;
  background-color: rgba(30, 30, 30, 0.9);
}
`, "",{"version":3,"sources":["webpack://./src/lib/vue/components/ImageEditor.vue"],"names":[],"mappings":";AACA;EACA,8BAAA;EACA,iCAAA;EACA,uCAAA;AACA","sourcesContent":["<style>\r\n.PinturaRoot {\r\n  --color-background: 30, 30, 30;\r\n  --color-foreground: 255, 255, 255;\r\n  background-color: rgba(30, 30, 30, 0.9);\r\n}\r\n</style>\r\n<template>\r\n  <v-dialog\r\n    :value=\"!!value && value.length > 0\"\r\n    @input=\"v => $emit('input', v)\"\r\n    v-bind=\"$attrs\"\r\n    :data-cy=\"cy\"\r\n    fullscreen\r\n    dark\r\n  >\r\n    <div class=\"fd-image-editor-height\">\r\n      <v-toolbar flat>\r\n        <fd-text-field style=\"max-width: 320px\" v-model=\"fileName\" hide-details />\r\n        <!-- <fd-text-field v-model=\"fileName\" hide-details /> -->\r\n        <div v-if=\"!!fileExtension\">.{{ fileExtension }}</div>\r\n        <v-spacer></v-spacer>\r\n        <v-btn text link :small=\"$vuetify.breakpoint.xs\" @click=\"close\" class=\"mr-1\">\r\n          {{ $t(\"common.cancel\") }}\r\n        </v-btn>\r\n        <v-btn color=\"primary\" :small=\"$vuetify.breakpoint.xs\" @click=\"save\">\r\n          {{ $t(completeButtonLabelKey) }}\r\n        </v-btn>\r\n      </v-toolbar>\r\n      <PinturaEditor\r\n        ref=\"inline\"\r\n        v-bind=\"editorProps\"\r\n        :utils=\"enabledUtils\"\r\n        :util=\"activeUtil\"\r\n        :src=\"value\"\r\n        style=\"--color-background: 255, 255, 255;\"\r\n        @pintura:process=\"handleProcess($event)\"\r\n        :enableButtonClose=\"false\"\r\n        :enableButtonExport=\"false\"\r\n      />\r\n    </div>\r\n  </v-dialog>\r\n  <!-- <PinturaEditorModal\r\n    v-if=\"!!value\"\r\n    v-bind=\"editorProps\"\r\n    :src=\"value\"\r\n    :utils=\"enabledUtils\"\r\n    :util=\"activeUtil\"\r\n    @pintura:hide=\"close\"\r\n    @pintura:process=\"handleProcess($event)\"\r\n  /> -->\r\n</template>\r\n\r\n<script lang=\"ts\" src=\"./ImageEditor.vue.ts\" />\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
