var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-disable-instead-top-color-processing"
                  : "dialog-disable-instead-top-color"
              ]
            },
            [
              _vm.itemEnabled
                ? _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("common.disable-instead-dialog-title"))
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.itemEnabled
                ? _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "common.disable-instead-dialog-title-already-disabled"
                          )
                        )
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "mx-2" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "align-center justify-space-between",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mr-0 ml-0",
                          attrs: { align: "center" }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "common.disable-instead-dialog-info-cant-remove"
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.itemEnabled
                        ? _c(
                            "v-row",
                            {
                              staticClass: "mr-0 ml-0 mt-4",
                              attrs: { align: "center" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "common.disable-instead-dialog-info-try-disable"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.itemEnabled
                        ? _c(
                            "v-row",
                            {
                              staticClass: "mr-0 ml-0 mt-4",
                              attrs: { align: "center" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "common.disable-instead-dialog-info-already-disabled"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              !_vm.itemEnabled
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", disabled: _vm.processing },
                      on: {
                        click: function($event) {
                          return _vm.closeDialog(false)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.close")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.itemEnabled
                ? _c(
                    "v-btn",
                    {
                      attrs: { text: "", disabled: _vm.processing },
                      on: {
                        click: function($event) {
                          return _vm.closeDialog(false)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.itemEnabled
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.processing,
                        disabled: _vm.processing
                      },
                      on: {
                        click: function($event) {
                          return _vm.closeDialog(true)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.disable")))]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }