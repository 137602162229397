import FDVue from "@fd/lib/vue";
import { TranslateResult } from "vue-i18n";
import { mapActions, mapMutations } from "vuex";
import { addDaysToDate, localizedDateTimeString } from "@fd/lib/client-util/datetime";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { VDataTable } from "@fd/lib/vue/types";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";

import tabbedView, { PageTab, Tab } from "@fd/lib/vue/mixins/tabbedView";
import { EstimateSettings, estimateSettingsService } from "../services";
import userAccess from "../dataMixins/userAccess";

type ExpandedPanels = {
  scaffoldBaysPanelIndex?: number;
};
export default FDVue.extend({
  name: "fd-estimate-setup",

  mixins: [serviceErrorHandling, tabbedView, userAccess],
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },
  components: {
    "fd-estimate-general-settings-form": () =>
      import("./components/forms/SP.EstimateGeneralSettingsForm.vue"),
    "fd-estimate-setup-scaffold-type-modifier-form": () =>
      import("./components/ScaffoldTypeModifierListForm.vue"),
    "sp-estimate-setup-scaffold-type-form": () =>
      import("./components/SP.ScaffoldTypeListForm.vue"),
    "fd-estimate-setup-scaffold-distance-modifier-form": () =>
      import("./components/ScaffoldDistanceModifierListForm.vue"),
    "fd-estimate-setup-scaffold-elevation-modifier-form": () =>
      import("./components/SP.ScaffoldElevationModifierListForm.vue"),
    "fd-estimate-setup-scaffold-height-modifier-form": () =>
      import("./components/SP.ScaffoldHeightModifierListForm.vue"),
    "fd-estimate-setup-build-dismantle-ratio-form": () =>
      import("./components/SP.BuildDismantleRatioListForm.vue"),
    "fd-estimate-setup-scaffold-congestion-factor-form": () =>
      import("./components/SP.ScaffoldCongestionFactorListForm.vue"),
    "fd-estimate-setup-scaffold-bay-length-form": () =>
      import("./components/ScaffoldBayLengthListForm.vue"),
    "fd-estimate-setup-scaffold-bay-width-form": () =>
      import("./components/ScaffoldBayWidthListForm.vue"),
    "fd-estimate-setup-scaffold-bay-height-form": () =>
      import("./components/ScaffoldBayHeightListForm.vue"),
    "fd-estimate-setup-hoarding-modifier-form": () =>
      import("./components/HoardingModifierListForm.vue"),
    "fd-estimate-setup-internal-modifier-form": () =>
      import("./components/InternalModifierListForm.vue")
  },

  data: function() {
    return {
      saving: false,

      firstTabKey: `0`,
      estimateSettings: {} as EstimateSettings,
      generalTab: {
        tabname: this.$t("estimate-setup.tabs.general.title"),
        key: "0",
        visible: true
      } as Tab,
      scaffoldTypeModifiersTab: {
        tabname: this.$t("estimate-setup.tabs.scaffold-type-modifiers.title"),
        key: "1",
        visible: false
      } as Tab,
      scaffoldTypesTab: new PageTab({
        nameKey: "estimate-setup.tabs.scaffold-types.title",
        key: "12",
        visible: true
      }),
      mobilizationDistanceTab: {
        tabname: this.$t("estimate-setup.tabs.mobilization-distance.title"),
        key: "2",
        visible: false
      } as Tab,
      scaffoldElevationTab: {
        tabname: this.$t("estimate-setup.tabs.scaffold-elevation.title"),
        key: "3",
        visible: false
      } as Tab,
      scaffoldHeightTab: {
        tabname: this.$t("estimate-setup.tabs.scaffold-height.title"),
        key: "4",
        visible: false
      } as Tab,
      buildDismantleRatioTab: {
        tabname: this.$t("estimate-setup.tabs.build-dismantle-ratio.title"),
        key: "5",
        visible: false
      } as Tab,
      congestionFactorTab: {
        tabname: this.$t("estimate-setup.tabs.congestion-factor.title"),
        key: "6",
        visible: false
      } as Tab,
      scaffoldBaysTab: {
        tabname: this.$t("estimate-setup.tabs.scaffold-bays.title"),
        key: "7",
        visible: false
      } as Tab,
      internalTab: {
        tabname: this.$t("estimate-setup.tabs.internal.title"),
        key: "10",
        visible: false
      } as Tab,
      hoardingTab: {
        tabname: this.$t("estimate-setup.tabs.hoarding.title"),
        key: "11",
        visible: false
      } as Tab
    };
  },
  computed: {
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [
        this.generalTab,
        // this.scaffoldTypeModifiersTab,
        this.scaffoldTypesTab,
        this.mobilizationDistanceTab,
        this.scaffoldElevationTab,
        this.scaffoldHeightTab,
        this.buildDismantleRatioTab,
        this.congestionFactorTab,
        this.scaffoldBaysTab,
        this.internalTab,
        this.hoardingTab
      ] as Tab[];
    },
    currentFilter(): any {
      return this.$store.state.filters.find(
        (x: any) => x.context == this.$store.state.filteringContext
      )!;
    },
    expandedPanelsObject(): ExpandedPanels {
      return { ...this.currentFilter.expandedPanel };
    },
    bayspanel: {
      get(): number {
        return this.expandedPanelsObject.scaffoldBaysPanelIndex ?? -1;
      },
      set(val: number) {
        let expandedPanels = this.expandedPanelsObject;
        expandedPanels.scaffoldBaysPanelIndex = val;
        this.setExpandedPanels(expandedPanels);
      }
    }
  },
  watch: {},
  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT",
      setExpandedPanels: "SET_EXPANDED_PANEL_INDEX_IN_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadParts: "LOAD_PARTS"
    }),
    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.save();
    },
    async save() {
      this.processing = true;
      this.saving = true;
      try {
        await estimateSettingsService.updateCurrentEnvironmentEstimateSettings(
          this.estimateSettings
        );
        var snackbarPayload = {
          text: this.$t("estimate-setup.general.snack-bar-updated-message"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    }
  },
  created: async function() {
    this.setFilteringContext({
      context: "estimatesetup",
      parentalContext: null,
      selectedTab: this.firstTabKey,
      expandedPanel: {} as ExpandedPanels,
      contractorsForFiltering: [],
      searchStringForFiltering: "",
      contextForFiltering: "all",
      peopleForFiltering: [],
      statusesForFiltering: []
    });

    this.notifyNewBreadcrumb({
      text: this.$t("estimate-setup.title"),
      to: "/estimatesetup",
      resetHistory: true
    });

    this.processing = true;
    try {
      await this.loadParts();
      this.estimateSettings =
        (await estimateSettingsService.getCurrentEnvironmentEstimateSettings()) ??
        ({
          environmentID: this.$store.state.curEnvironment.id
        } as EstimateSettings);
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

