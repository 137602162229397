import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { ScaffoldCongestionFactor } from "../../../services";

const ScaffoldCongestionFactorNewDialog = FDVue.extend({
  name: "fd-scaffold-congestion-factor-new-dialog",

  mixins: [dialogSupport, rules],

  components: {},

  data: function() {
    return {
      saving: false,
      scaffoldCongestionFactor: {
        name: "",
        modifier: 0
      } as ScaffoldCongestionFactor
    };
  },

  computed: {},

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        await this.addScaffoldCongestionFactor({
          ...this.scaffoldCongestionFactor,
          modifier: !!this.scaffoldCongestionFactor.modifier
            ? +this.scaffoldCongestionFactor.modifier
            : undefined
        });
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    ...mapActions({
      addScaffoldCongestionFactor: "ADD_SCAFFOLD_CONGESTION_FACTOR"
    })
  }
});

export default ScaffoldCongestionFactorNewDialog;

export async function createNewScaffoldCongestionFactor(): Promise<boolean> {
  let dialog = createDialog(ScaffoldCongestionFactorNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.showDialog!();
}

