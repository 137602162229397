import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { WorkType } from "@fd/current/client/services";

const WorkTypeNewDialog = FDVue.extend({
  name: "fd-work-type-new-dialog",

  mixins: [dialogSupport, errorHandling, rules],

  components: {},

  data: function() {
    return {
      workType: {
        name: "",
        code: "",
        order: 0
      } as WorkType
    };
  },

  computed: {},

  methods: {
    async open(lastDisplayOrder: number) {
      this.workType.order = lastDisplayOrder + 1;
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.processing = true;
      try {
        await this.addWorkType(this.workType);
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    ...mapActions({
      addWorkType: "ADD_WORK_TYPE"
    })
  }
});

export default WorkTypeNewDialog;

export async function createNewWorkType(lastDisplayOrder: number): Promise<boolean> {
  let dialog = createDialog(WorkTypeNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(lastDisplayOrder);
}

