import FDVue from "@fd/lib/vue";
import * as DateUtil from "@fd/lib/client-util/datetime";

type Log = { changed: Date | string | null | undefined };

function compareLogs<T extends Log>(a: T, b: T): number {
  // being DTOs instead of DateTimes has these returned as a string instead of a date object
  return new Date(b.changed!).getTime() - new Date(a.changed!).getTime();
}

export default FDVue.extend({
  name: "fd-log-history-form",
  props: {
    noHistoryMessage: {
      type: String,
      default: function() {
        return this.$t("log-history.default-no-history-message");
      }
    },
    statusLogs: { type: Array },
    colorForItem: { type: Function },
    iconForItem: { type: Function },
    summaryStringForItem: { type: Function },
    detailStringForItem: { type: Function },
    itemDate: { type: String, default: "changed" }
  },
  computed: {
    sortedStatusLogs(): Log[] {
      let statusLogs = (this.statusLogs ?? []) as Log[];
      return statusLogs.sort(compareLogs);
    }
  },
  methods: {
    formatLogChangedDate(log: Log): string {
      if (!this.itemDate) return "";
      let changed = (log as any)[this.itemDate];
      if (!changed) return "";
      var changedDate = DateUtil.localizedDateTimeString(changed);
      return changedDate;
    },
    summaryStringForItemWithDate(log: Log): string {
      let parts = [];
      if (!!this.summaryStringForItem && !!this.summaryStringForItem(log)) {
        parts.push(this.summaryStringForItem(log));
      }
      parts.push(this.formatLogChangedDate(log));
      return parts.join(" - ");
    }
  }
});

