import FDVue from "@fd/lib/vue";
import {
  Contractor,
  personService,
  PersonWithDetails,
  timesheetService,
  TimesheetStatus,
  TimesheetType,
  TimesheetWithDetails
} from "../services";
import {
  FDColumnDirective,
  FDHiddenArgumentName,
  FDRowNavigateDirective
} from "@fd/lib/vue/utility/dataTable";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { mapActions, mapMutations } from "vuex";
import { DateRangePreset } from "@fd/lib/vue/components/DateRangePicker.vue";
import { createNewTimesheet } from "./components/dialogs/TimesheetNewDialog.vue";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";
import rules from "@fd/lib/vue/rules";
import { TranslateResult } from "vue-i18n";
import {
  GetPersonName,
  HasContractorName,
  HasName,
  JoinNameValues,
  SelectablePerson,
  SortItemsWithName
} from "../utils/person";
import { valueInArray } from "@fd/lib/client-util/array";
import { showItemSelectionDialog } from "./components/ItemSelectionDialog.vue";

type FormattedTimesheetWithDetails = TimesheetWithDetails & {
  classification: string;
  timesheetNumberString: string;
  timesheetStatus: string | TranslateResult;
  formattedDay: string;
  formattedTotalRegularTime: string;
  formattedTotalOverTime: string;
  formattedTotalDoubleTime: string;
  formattedTotalUnits: string;
  totalPerDiemUnits: number | null | undefined;
  totalEquipmentUnits: number | null | undefined;
};
export default FDVue.extend({
  name: "fd-timesheets",

  mixins: [serviceErrorHandling, rules],

  directives: {
    "fd-column": FDColumnDirective,
    "fd-row-navigate": FDRowNavigateDirective
  },

  data: function() {
    return {
      minDate: undefined as Date | undefined,
      maxDate: undefined as Date | undefined,
      selectedOwnerID: undefined as string | undefined,
      allTimesheets: [] as TimesheetWithDetails[],
      allPendingTimesheets: [] as TimesheetWithDetails[],

      submitting: false,
      approving: false,
      declining: false,

      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5,

      // Table Footer page size options
      itemsPerPage: 25,
      itemsPerPageOptions: [5, 10, 15, 25, 50, -1]
    };
  },
  computed: {
    iconColumnArgument(): string {
      return this.anyTimesheetsMissingCostCode ? "hasEntriesMissingCostCode" : FDHiddenArgumentName;
    },
    anyTimesheetsMissingCostCode(): boolean {
      return this.timesheets.findIndex(x => !!x.hasEntriesMissingCostCode) !== -1;
    },
    selectionType: {
      get(): "pending" | "all" {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
      },
      set(val: "pending" | "all") {
        var current = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
        if (val == current) return;

        this.$store.commit("SET_CONTEXT_FOR_FILTERING", val);
      }
    },
    timesheets(): TimesheetWithDetails[] {
      let timesheets = this.allTimesheets;
      if (this.selectionType == "pending") timesheets = this.allPendingTimesheets;
      if (this.contractorsSelectedForFiltering.length) {
        //If the items that has a contractorID associated to it is within the selected Contractors return it
        timesheets = timesheets.filter(x => {
          if (!x.contractorID) return false;
          for (let contractor of this.contractorsSelectedForFiltering) {
            if (x.contractorID == contractor) return true;
          }
          return false;
        });
      }
      if (!!this.selectedOwnerID) {
        timesheets = timesheets.filter(x => x.ownerID == this.selectedOwnerID);
      }
      return timesheets;
    },
    contractorsInUse(): Contractor[] {
      let timesheets = this.allTimesheets;
      if (!!this.selectedOwnerID)
        timesheets = timesheets.filter(x => x.ownerID == this.selectedOwnerID);
      return this.$store.getters.getSortedInUseContractors(timesheets);
    },

    // *** FILTERING ***
    tablesearch: {
      get(): string {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    contractorsSelectedForFiltering: {
      get(): string[] {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contractorsForFiltering;
      },
      set(val: string[]) {
        this.$store.commit("SET_CONTRACTORS_FOR_FILTERING", val);
      }
    },

    visiblePeople(): SelectablePerson[] {
      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      let ownerIDs = [...new Set(this.allTimesheets.map(x => x.ownerID))];
      let owners = allPeople.filter(x => ownerIDs.includes(x.id));
      let visiblePeopleWithoutDetails = !this.contractorsSelectedForFiltering?.length
        ? owners
        : owners.filter(x => valueInArray(x.contractorID, this.contractorsSelectedForFiltering));
      let visiblePeopleWithDetails = visiblePeopleWithoutDetails
        .map(x => {
          return {
            ...x,
            name: JoinNameValues(x.firstName, x.lastName),
            contractorName: this.contractorsInUse.find(c => c.id == x.contractorID)?.name
          } as PersonWithDetails & HasName & HasContractorName;
        })
        .sort((a, b) => {
          let contractorA = (a.contractorName ?? "").toLowerCase();
          let contractorB = (b.contractorName ?? "").toLowerCase();
          if (contractorA < contractorB) return -1;
          else if (contractorA > contractorB) return 1;

          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;

          return 0;
        });

      var contractorNames = visiblePeopleWithDetails
        .map(x => x.contractorName)
        .reduce((distinctNames: string[], contractorName: string) => {
          if (!distinctNames.includes(contractorName)) distinctNames.push(contractorName);

          return distinctNames;
        }, []);

      if (contractorNames.length > 1) {
        let contractorGroupedSelectableItems = [] as SelectablePerson[];
        contractorNames.forEach((name, index) => {
          let peopleForContractor = visiblePeopleWithDetails.filter(x => x.contractorName == name);
          if (!peopleForContractor?.length) return;

          if (index > 0) {
            contractorGroupedSelectableItems.push({
              divider: true
            });
          }
          if (!!name) {
            contractorGroupedSelectableItems.push({
              header: name
            });
          }

          contractorGroupedSelectableItems = contractorGroupedSelectableItems.concat(
            peopleForContractor
          );
        });
        return contractorGroupedSelectableItems;
      } else {
        return visiblePeopleWithDetails;
      }
    },

    // Archived Props for Approved Count Sheets
    dateRangePresetOptions(): DateRangePreset[] {
      return [
        {
          fromDate: DateUtil.addDaysToDate(null, 0),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "today",
          labelKey: "fd-date-range-picker.preset-today-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -6),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-week",
          labelKey: "fd-date-range-picker.preset-previous-week-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -13),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-weeks",
          labelKey: "fd-date-range-picker.preset-previous-two-weeks-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addMonthsToDate(null, -1),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-month",
          labelKey: "fd-date-range-picker.preset-previous-month-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addMonthsToDate(null, -2),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-months",
          labelKey: "fd-date-range-picker.preset-previous-two-months-label"
        } as DateRangePreset
      ];
    },
    showArchivedMinDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let minDate = DateUtil.addMonthsToDate(date, -2);
      return minDate;
    },
    showArchivedMaxDate(): Date | null {
      // If we have neither dates, or both dates, we're starting a new range so we don't need any restrictions
      if (
        (!this.showArchivedFromDate && !this.showArchivedToDate) ||
        (!!this.showArchivedFromDate && !!this.showArchivedToDate)
      )
        return null;

      var date = this.showArchivedFromDate ?? this.showArchivedToDate;
      let maxDate = DateUtil.addMonthsToDate(date, 2);
      return maxDate;
    },
    showArchivedDateRange: {
      get(): Date[] {
        var dates = [];
        if (!!this.showArchivedFromDate) dates.push(this.showArchivedFromDate);
        if (!!this.showArchivedToDate) dates.push(this.showArchivedToDate);
        return dates;
      },
      async set(val: any[]) {
        if (val.length > 0) this.showArchivedFromDate = new Date(val[0]);
        else this.showArchivedFromDate = null;

        if (val.length > 1) {
          this.showArchivedToDate = new Date(val[1]);
          this.processing = true;
          try {
            await this.reloadTableData();
          } catch (error) {
            this.handleError(error as Error);
          } finally {
            this.processing = false;
          }
        } else this.showArchivedToDate = null;
      }
    },
    showArchivedFromDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringFromDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE", val);
      }
    },
    showArchivedToDate: {
      get(): Date | null {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.showArchivedForFilteringToDate;
      },
      async set(val: Date | null) {
        this.$store.commit("SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE", val);
      }
    }
  },
  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS",
      loadPeople: "LOAD_USERS"
    }),

    async openNewDialog() {
      let timesheetID = await createNewTimesheet(undefined, undefined);
      if (!!timesheetID) {
        this.$router.push(`/timesheetapproval/${timesheetID}`);
      }
    },

    async loadTimesheets() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      this.allTimesheets = (
        await timesheetService.getAll(this.showArchivedFromDate, this.showArchivedToDate)
      ).map(
        x =>
          ({
            ...x,
            classification: !!x.ownerClassificationAlias
              ? x.ownerClassificationAlias
              : x.ownerClassificationName,
            timesheetNumberString: `00000${x.timesheetNumber}`.slice(-5),
            timesheetStatus: this.$t(`timesheets.status.${x.timesheetStatusID}`),
            formattedDay: DateUtil.stripTimeFromLocalizedDateTime(x.day),
            formattedTotalRegularTime:
              !!x.totalRegularTime && x.totalRegularTime > 0
                ? x.totalRegularTime.toFixed(2)
                : undefined,
            formattedTotalOverTime:
              !!x.totalOverTime && x.totalOverTime > 0 ? x.totalOverTime.toFixed(2) : undefined,
            formattedTotalDoubleTime:
              !!x.totalDoubleTime && x.totalDoubleTime > 0
                ? x.totalDoubleTime.toFixed(2)
                : undefined,
            formattedTotalUnits:
              !!x.totalUnits && x.totalUnits > 0 ? x.totalUnits.toFixed(2) : undefined,
            totalPerDiemUnits:
              x.timesheetTypeID != TimesheetType.Equipment ? x.totalUnits : undefined,
            totalEquipmentUnits:
              x.timesheetTypeID == TimesheetType.Equipment ? x.totalUnits : undefined
          } as FormattedTimesheetWithDetails)
      );

      this.allPendingTimesheets = (await timesheetService.getAllPending()).map(
        x =>
          ({
            ...x,
            classification: !!x.ownerClassificationAlias
              ? x.ownerClassificationAlias
              : x.ownerClassificationName,
            timesheetNumberString: `00000${x.timesheetNumber}`.slice(-5),
            timesheetStatus: this.$t(`timesheets.status.${x.timesheetStatusID}`),
            formattedDay: DateUtil.stripTimeFromLocalizedDateTime(x.day),
            formattedTotalRegularTime:
              !!x.totalRegularTime && x.totalRegularTime > 0
                ? x.totalRegularTime.toFixed(2)
                : undefined,
            formattedTotalOverTime:
              !!x.totalOverTime && x.totalOverTime > 0 ? x.totalOverTime.toFixed(2) : undefined,
            formattedTotalDoubleTime:
              !!x.totalDoubleTime && x.totalDoubleTime > 0
                ? x.totalDoubleTime.toFixed(2)
                : undefined,
            formattedTotalUnits:
              !!x.totalUnits && x.totalUnits > 0 ? x.totalUnits.toFixed(2) : undefined,
            totalPerDiemUnits:
              x.timesheetTypeID != TimesheetType.Equipment ? x.totalUnits : undefined,
            totalEquipmentUnits:
              x.timesheetTypeID == TimesheetType.Equipment ? x.totalUnits : undefined
          } as FormattedTimesheetWithDetails)
      );

      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },
    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await this.loadTimesheets();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    timesheetIsDeclined(item: FormattedTimesheetWithDetails) {
      return item.timesheetStatusID == TimesheetStatus.Declined;
    },
    timesheetIsSubmitted(item: FormattedTimesheetWithDetails) {
      return item.timesheetStatusID == TimesheetStatus.Submitted;
    },
    timesheetCanBeSubmitted(item: FormattedTimesheetWithDetails) {
      return (
        (item.timesheetStatusID == TimesheetStatus.New ||
          item.timesheetStatusID == TimesheetStatus.Declined) &&
        item.currentUserPermissions.canSubmit
      );
    },
    timesheetCanBeApproved(item: FormattedTimesheetWithDetails) {
      return (
        item.timesheetStatusID == TimesheetStatus.Submitted &&
        item.currentUserPermissions.canApprove
      );
    },
    timesheetCanBeDeclined(item: FormattedTimesheetWithDetails) {
      return (
        item.timesheetStatusID == TimesheetStatus.Submitted &&
        item.currentUserPermissions.canApprove
      );
    },
    async submitTimesheet(item: FormattedTimesheetWithDetails) {
      if (!item.currentUserPermissions.canSubmit) return;
      this.processing = true;
      this.submitting = true;
      try {
        let submittedToID = undefined as string | undefined;

        if (
          item.timesheetTypeID == TimesheetType.Indirect ||
          item.timesheetTypeID == TimesheetType.Equipment
        ) {
          let visibleTimeManagers = SortItemsWithName(
            (await personService.getVisibleTimeManagers())
              .filter(x => x.contractorID == item.contractorID)
              .map(x => ({
                ...x,
                name: GetPersonName(x)
              }))
          );

          var title = this.$t("timesheets.entries.submit-to-time-manager-label");
          submittedToID = await showItemSelectionDialog(
            title,
            this.$t("timesheets.entries.time-manager-label"),
            [this.rules.required],
            visibleTimeManagers,
            "name",
            "id"
          );
          // If details is undefined the dialog was cancelled, if empty we somehow bypassed selecting a TM
          if (!submittedToID) {
            return false;
          }
        } else {
          let visibleGeneralForeman = SortItemsWithName(
            (await personService.getVisibleGeneralForemen())
              .filter(x => x.contractorID == item.contractorID)
              .map(x => ({
                ...x,
                name: GetPersonName(x)
              }))
          );

          let title = this.$t("timesheets.entries.submit-to-gen-foreman-label");
          submittedToID = await showItemSelectionDialog(
            title,
            this.$t("timesheets.entries.general-foreman-label"),
            [this.rules.required],
            visibleGeneralForeman,
            "name",
            "id"
          );
        }

        // If details is undefined the dialog was cancelled, if empty we somehow bypassed selecting a GF
        if (!submittedToID) {
          this.processing = false;
          this.submitting = false;
          return false;
        }

        item.submittedTo = submittedToID;

        await timesheetService.submitTimesheet(item.id!, submittedToID);
        item.timesheetStatusID = TimesheetStatus.Submitted;
        item.timesheetStatus = this.$t(`timesheets.status.${item.timesheetStatusID}`);

        var snackbarPayload = {
          text: this.$t("timesheets.list.submit-success", [item.ownerName, item.formattedDay]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        await this.reloadTableData();
      } catch (error) {
        if ((error as any).statusCode == 422) {
          var snackbarPayload = {
            text: this.$t("timesheets.list.submit-validation-failed", [
              item.ownerName,
              item.formattedDay
            ]),
            type: "error",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        } else {
          this.handleError(error as Error);
        }
      } finally {
        this.submitting = false;
        this.processing = false;
      }
    },
    async approveTimesheet(item: FormattedTimesheetWithDetails) {
      if (!item.currentUserPermissions.canApprove) return;
      this.processing = true;
      this.approving = true;
      try {
        await timesheetService.approvePendingTimesheet(item.id!);
        item.timesheetStatusID = TimesheetStatus.Approved;
        item.timesheetStatus = this.$t(`timesheets.status.${item.timesheetStatusID}`);

        var snackbarPayload = {
          text: this.$t("timesheets.approval.approve-success", [item.ownerName, item.formattedDay]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        await this.reloadTableData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.approving = false;
        this.processing = false;
      }
    },
    async declineTimesheet(item: FormattedTimesheetWithDetails) {
      if (!item.currentUserPermissions.canApprove) return;
      this.processing = true;
      this.declining = true;
      try {
        // get reason
        var title = this.$t("timesheets.approval.decline-reason");
        var reason = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
          this.rules.required
        ]);

        // If details is undefined the dialog was cancelled
        if (!reason) {
          this.declining = false;
          this.processing = false;
          return false;
        }

        await timesheetService.declinePendingTimesheet(item.id!, reason);
        item.timesheetStatusID = TimesheetStatus.Declined;
        item.timesheetStatus = this.$t(`timesheets.status.${item.timesheetStatusID}`);

        var snackbarPayload = {
          text: this.$t("timesheets.approval.decline-success", [item.ownerName, item.formattedDay]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        await this.reloadTableData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.declining = false;
        this.processing = false;
      }
    },

    fromDateChanged(val: Date) {
      this.maxDate = DateUtil.addMonthsToDate(val, 2);
      let now = new Date();
      if (this.maxDate.getTime() > now.getTime()) this.maxDate = now;
      this.showArchivedMinDate;
    },
    toDateChanged(val: Date) {
      this.minDate = DateUtil.addMonthsToDate(val, -2);
    }
  },
  created: async function() {
    var toDate = DateUtil.addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "timesheets",
      parentalContext: null,
      contractorsForFiltering: [],
      showArchivedForFilteringFromDate: DateUtil.addMonthsToDate(toDate, -1),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: "",
      contextForFiltering: "pending"
    });

    this.notifyNewBreadcrumb({
      text: this.$t("timesheets.list.approval-title"),
      to: "/timesheetapproval",
      resetHistory: true
    });

    this.processing = true;
    try {
      await Promise.all([this.loadContractors(), this.loadPeople()]);
      await this.loadTimesheets();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  },
  beforeDestroy: function() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  }
});

