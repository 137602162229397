var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "fd-back-button pl-3 d-sm-none",
      attrs: { "data-cy": _vm.cy },
      on: { click: _vm.routeme }
    },
    [
      _c("v-icon", [_vm._v("fa-chevron-left")]),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.$t("common.back")))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }