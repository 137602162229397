import { scaffoldDistanceModifierService } from "../services/index";
import { createAutomaticCrudStoreModule } from "./automatic";

var storeModule = createAutomaticCrudStoreModule({
  crudService: scaffoldDistanceModifierService,
  singularStoreName: "SCAFFOLD_DISTANCE_MODIFIER",
  pluralStoreName: "SCAFFOLD_DISTANCE_MODIFIERS",
  localizationPrefix: "modifiers.scaffold-distance",
  consumerRelatedIDProperty: {
    name: "scaffoldDistanceModifierID",
    type: "string"
  }
});

export default storeModule;

