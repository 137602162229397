var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "isoexisting" } },
    [
      _c("fd-image-viewer", {
        attrs: { name: _vm.imageName },
        model: {
          value: _vm.imageSource,
          callback: function($$v) {
            _vm.imageSource = $$v
          },
          expression: "imageSource"
        }
      }),
      _vm._v(" "),
      _c("fd-image-editor", {
        attrs: { name: _vm.imageName },
        on: { edit: _vm.handleEdit },
        model: {
          value: _vm.editImageSource,
          callback: function($$v) {
            _vm.editImageSource = $$v
          },
          expression: "editImageSource"
        }
      }),
      _vm._v(" "),
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ],
                      attrs: { "data-cy": "toolbar" }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: { route: _vm.backButtonClicked }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          attrs: { "icons-and-text": "" },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              key: _vm.detailsTab.key,
                              ref: "tab",
                              attrs: { href: `#tab-${_vm.detailsTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.detailsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.detailsTab.tabname) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-edit")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.detailsTab.key,
                              attrs: { value: `tab-${_vm.detailsTab.key}` }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          "data-cy": "systems",
                                          label: _vm.$t("isos.system-name"),
                                          items: _vm.systems,
                                          "item-text": "name",
                                          "item-value": "id",
                                          disabled:
                                            !_vm.canChangeTestPackage ||
                                            _vm.processing,
                                          "hide-details": !_vm.canChangeTestPackage,
                                          dense: !_vm.canChangeTestPackage
                                        },
                                        model: {
                                          value: _vm.systemID,
                                          callback: function($$v) {
                                            _vm.systemID = $$v
                                          },
                                          expression: "systemID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          "data-cy": "testpackages",
                                          label: _vm.$t(
                                            "isos.test-package-name"
                                          ),
                                          items:
                                            _vm.testPackagesForSelectedSystem,
                                          "item-text": "name",
                                          "item-value": "id",
                                          disabled:
                                            !_vm.canChangeTestPackage ||
                                            _vm.processing,
                                          "hide-details": !_vm.canChangeTestPackage,
                                          dense: !_vm.canChangeTestPackage
                                        },
                                        model: {
                                          value: _vm.iso.testPackageID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.iso,
                                              "testPackageID",
                                              $$v
                                            )
                                          },
                                          expression: "iso.testPackageID"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1",
                                      attrs: { cols: "12", sm: "12", md: "12" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.name"),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.iso.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.iso, "name", $$v)
                                          },
                                          expression: "iso.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.code"),
                                          disabled: _vm.processing,
                                          rules: []
                                        },
                                        model: {
                                          value: _vm.iso.code,
                                          callback: function($$v) {
                                            _vm.$set(_vm.iso, "code", $$v)
                                          },
                                          expression: "iso.code"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("fd-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "systems.test-packages.isos.estimated-work-hours"
                                          ),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.numeric],
                                          type: "number",
                                          "hide-spin-buttons": ""
                                        },
                                        model: {
                                          value: _vm.iso.estimatedWorkHours,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.iso,
                                              "estimatedWorkHours",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "iso.estimatedWorkHours"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("fd-rich-textarea", {
                                        attrs: {
                                          disabled: _vm.processing,
                                          label: _vm.$t("common.description"),
                                          allowImages: false
                                        },
                                        model: {
                                          value: _vm.iso.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.iso,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "iso.description"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t("common.archived"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value: _vm.iso.archived,
                                          callback: function($$v) {
                                            _vm.$set(_vm.iso, "archived", $$v)
                                          },
                                          expression: "iso.archived"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab",
                            {
                              key: _vm.attachmentsTab.key,
                              attrs: { href: `#tab-${_vm.attachmentsTab.key}` },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.attachmentsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.attachmentsTab.tabname) +
                                  "\n              "
                              ),
                              _c("v-icon", [
                                _vm._v(
                                  "fas " +
                                    _vm._s(
                                      _vm.$store.getters.icon("attachments")
                                    )
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.attachmentsTab.key,
                              attrs: { value: `tab-${_vm.attachmentsTab.key}` }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "attachmentsform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mx-2",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                [
                                                  _c("input", {
                                                    ref: "addFileButton",
                                                    staticClass: "d-none",
                                                    attrs: {
                                                      type: "file",
                                                      onclick:
                                                        "this.value=null;"
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.fileInputChanged
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: { "offset-y": "" },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "add-fab-button-primary",
                                                                    attrs: {
                                                                      fab: "",
                                                                      elevation:
                                                                        "5",
                                                                      depressed:
                                                                        _vm.processing,
                                                                      disabled:
                                                                        _vm.processing,
                                                                      color:
                                                                        "new"
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-plus"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-list",
                                                        [
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  _vm.openNewExternalLinkDialog
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "attachments.external-link.menu-title"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  _vm.selectFile
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.local-file"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-divider", {
                                                    staticClass: "mx-4",
                                                    attrs: {
                                                      inset: "",
                                                      vertical: ""
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function({ on }) {
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "ml-1",
                                                                    attrs: {
                                                                      color:
                                                                        "black",
                                                                      dark: ""
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [_vm._v("info")]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "job.files-table-listing-tooltip"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-spacer"),
                                                  _vm._v(" "),
                                                  _c("v-text-field", {
                                                    staticClass: "pl-0",
                                                    attrs: {
                                                      "append-icon": "search",
                                                      label: _vm.$t(
                                                        "common.search"
                                                      ),
                                                      "single-line": "",
                                                      "hide-details": "",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.tablesearchfiles,
                                                      callback: function($$v) {
                                                        _vm.tablesearchfiles = $$v
                                                      },
                                                      expression:
                                                        "tablesearchfiles"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-data-table", {
                                                directives: [
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:icon.no-sort.hide-when-extra-small.class_fd-table-icon-cell",
                                                    value: "",
                                                    expression:
                                                      "\n                          ''\n                        ",
                                                    arg: "icon",
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "hide-when-extra-small": true,
                                                      "class_fd-table-icon-cell": true
                                                    }
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName: "v-fd-column:name",
                                                    value: _vm.$t(
                                                      "common.name"
                                                    ),
                                                    expression:
                                                      "$t('common.name')",
                                                    arg: "name"
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                                    arg: "actions",
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "hide-when-extra-small": true,
                                                      "class_fd-actions-cell": true
                                                    }
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:action.no-sort.show-when-extra-small.class_fd-actions-cell-edit-only",
                                                    arg: "action",
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "show-when-extra-small": true,
                                                      "class_fd-actions-cell-edit-only": true
                                                    }
                                                  }
                                                ],
                                                class: _vm.$vuetify.breakpoint
                                                  .xsOnly
                                                  ? "fd-action-table"
                                                  : "fd-actions-table",
                                                attrs: {
                                                  items: _vm.attachments,
                                                  search: _vm.tablesearchfiles,
                                                  loading: _vm.processing,
                                                  "loading-text": _vm.$t(
                                                    "common.table-loading-message"
                                                  ),
                                                  "sort-by": ["name"],
                                                  "item-class":
                                                    _vm.fileRowClassName,
                                                  "mobile-breakpoint": "0"
                                                },
                                                on: {
                                                  "dblclick:row":
                                                    _vm.attachmentRowClicked
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "item.icon",
                                                    fn: function({ item }) {
                                                      return [
                                                        item.type == "link"
                                                          ? _c("v-icon", [
                                                              _vm._v("fa-link")
                                                            ])
                                                          : item.type ==
                                                              "file" &&
                                                            item.isPhoto
                                                          ? _c("v-icon", [
                                                              _vm._v(
                                                                "fa-camera"
                                                              )
                                                            ])
                                                          : _c("v-icon", [
                                                              _vm._v("fa-file")
                                                            ])
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item.action",
                                                    fn: function({ item }) {
                                                      return [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp ||
                                                                _vm.processing,
                                                              left: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              disabled:
                                                                                !item.canOpenInNew ||
                                                                                _vm.processing
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.openAttachment(
                                                                                    item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  mdi-open-in-new\n                                "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.open-in-new-tab"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item.actions",
                                                    fn: function({ item }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            class: [
                                                              {
                                                                "table-5-actions-column-min-width":
                                                                  _vm.$vuetify
                                                                    .breakpoint
                                                                    .mdAndUp,
                                                                "table-4-actions-column-min-width": !_vm
                                                                  .$vuetify
                                                                  .breakpoint
                                                                  .mdAndUp
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  left: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    !item.canOpenInNew ||
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.openAttachment(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-open-in-new\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.open-in-new-tab"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  left: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    !item.isPreviewable ||
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.viewPhoto(
                                                                                        item.file
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-eye\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.view"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  link:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editAttachment(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-pencil\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.edit"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.$vuetify
                                                              .breakpoint
                                                              .mdAndUp
                                                              ? _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        !_vm
                                                                          .$vuetify
                                                                          .breakpoint
                                                                          .lgAndUp ||
                                                                        _vm.processing,
                                                                      top: ""
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function({
                                                                            on
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  {
                                                                                    attrs: {
                                                                                      icon:
                                                                                        "",
                                                                                      link:
                                                                                        "",
                                                                                      disabled:
                                                                                        !item.file ||
                                                                                        _vm.processing
                                                                                    }
                                                                                  },
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      attrs: {
                                                                                        small:
                                                                                          ""
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.downloadFile(
                                                                                            item.file
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                    mdi-cloud-download\n                                  "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  },
                                                                  [
                                                                    _vm._v(" "),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "common.download"
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp ||
                                                                    _vm.processing,
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function({
                                                                        on
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deleteAttachment(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-delete\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.remove"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.inlineMessage.message
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _vm.inlineMessage.message
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "mr-0",
                                      attrs: { type: _vm.inlineMessage.type }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.inlineMessage.message) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "mx-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            link: "",
                            disabled: _vm.processing,
                            color: "error",
                            outlined: ""
                          },
                          on: { click: _vm.deleteItem }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.delete")))]
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            text: "",
                            link: "",
                            disabled: _vm.processing
                          },
                          on: { click: _vm.cancel }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                      ),
                      _vm._v(" "),
                      _c("fd-menu-save", {
                        attrs: {
                          disabled: _vm.processing,
                          loading: _vm.saving,
                          small: _vm.$vuetify.breakpoint.xsOnly
                        },
                        on: {
                          "click:save": function($event) {
                            return _vm.save(false)
                          },
                          "click:save-and-close": function($event) {
                            return _vm.save(true)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }