var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0 fp-map-container", attrs: { fluid: "" } },
    [_c("div", { ref: "mapContainer", style: `height: ${_vm.height};` })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }