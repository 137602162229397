import FDVue from "@fd/lib/vue";
import { mapMutations, mapState, mapActions } from "vuex";
import i18n from "../i18n";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { ContractorWithTags, PersonWithDetails, ProjectCostCode } from "../services";
import rules from "@fd/lib/vue/rules";
import { addDaysToDate, addMonthsToDate } from "@fd/lib/client-util/datetime";

type ProjectCostCodeWithArchived = ProjectCostCode & {
  archived: boolean;
};

export default FDVue.extend({
  mixins: [errorHandling, rules],

  name: "fd-project-cost-code-existing",

  components: {},

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,

      slidein: false,

      costcode: {} as ProjectCostCodeWithArchived
    };
  },

  computed: {},

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.save();
    },
    // Method used in conjunction with the Save button.
    async save() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.costcode.archived) {
          this.costcode.archivedDate = null;
        } else if (this.costcode.archived && !this.costcode.archivedDate) {
          this.costcode.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateProjectCostCode({
          ...this.costcode
        });
        this.$router.push(`/projects/${this.costcode.projectID}`);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteProjectCostCode({
          id: this.$route.params.costCodeID,
          name: this.costcode.name
        });
        this.$router.push(`/projects/${this.costcode.projectID}`);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push(`/projects/${this.costcode.projectID}`);
    },

    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadProject: "LOAD_PROJECT",
      loadProjectCostCode: "LOAD_PROJECT_COST_CODE",
      updateProjectCostCode: "UPDATE_PROJECT_COST_CODE",
      deleteProjectCostCode: "DELETE_PROJECT_COST_CODE"
    })
  },

  watch: {
    costcode: async function(newValue) {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/projects") {
        this.notifyNewBreadcrumb({
          text: this.$t("projects.list-title"),
          to: "/projects",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        await this.loadProject(newValue.projectID);

        this.notifyNewBreadcrumb({
          text: this.$store.state.project.name,
          to: `/projects/${this.$store.state.project.id}`
        });

        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      this.notifyNewBreadcrumb({
        text: newValue.name,
        to: `/costcodes/${this.$route.params.costCodeID}`
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "project-cost-codes",
      parentalContext: "projects-existing",
      searchStringForFiltering: ""
    });

    this.processing = true;
    try {
      await Promise.all([this.loadProjectCostCode(this.$route.params.costCodeID)]);

      let costcode = (this.$store.state.projectCostCodes.fullList as ProjectCostCode[]).find(
        (x: any) => x.id == this.$route.params.costCodeID
      )!;
      this.costcode = { ...costcode, archived: !!costcode.archivedDate };
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

