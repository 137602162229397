import {
  CountSheet,
  CountSheetGroupWithParts,
  CountSheetPartWithDetails,
  CountSheetWithParts,
  MaterialOrderDirection,
  MaterialOrderPartWithDetails,
  Part,
  TransferDirection,
  TransferPartWithDetails,
  TransferWithDetails
} from "../services";

export type PartWithCounts = Part & {
  assigned: number;
  addCount: number;
  addCountOverride?: number | null | undefined;
  loadedRemoveCount?: number;
  removeCount: number;
  removeCountOverride?: number | null | undefined;
  total: number;
  totalOverride?: number | null | undefined;
  overridden?: boolean | undefined;
  rejected?: boolean | undefined;
  rejectedReason?: string | undefined;
  // Used for Transfer parts
  count?: number | null | undefined;
  originalCount?: number | null | undefined;
  loadedAddCount?: number;
  originalTotal?: number | null | undefined;
};
export function CountSheetPartFromGroupPart(
  part: PartWithCounts,
  countSheet: CountSheet
): CountSheetPartWithDetails {
  return {
    countSheetID: countSheet.id,
    partID: part.id,
    standingScaffoldPartCount: !!part.assigned ? +part.assigned : null,
    addCount: +part.addCount,
    addCountOverride: !!part.addCountOverride ? +part.addCountOverride : undefined,
    removeCount: +part.removeCount,
    removeCountOverride: !!part.removeCountOverride ? +part.removeCountOverride : undefined,
    overridden: part.overridden,
    rejected: part.rejected,
    rejectedReason: part.rejectedReason,
    name: part.name,
    publicID: part.publicID,
    currentAssignedCount: part.assigned
  } as CountSheetPartWithDetails;
}
export function CountSheetGroupPartFromCountSheetPart(
  part: Part,
  countSheet: CountSheetWithParts | undefined
): PartWithCounts {
  let countSheetPart = countSheet?.parts?.find(x => x.partID == part.id);
  let assigned = countSheetPart?.standingScaffoldPartCount ?? 0;
  let addCount = countSheetPart?.addCount ?? 0;
  let addCountOverride = countSheetPart?.addCountOverride;
  let removeCount = countSheetPart?.removeCount ?? 0;
  let removeCountOverride = countSheetPart?.removeCountOverride;
  return {
    ...part,
    assigned: assigned,
    loadedAddCount: addCount,
    addCount: addCount,
    addCountOverride: addCountOverride,
    loadedRemoveCount: removeCount,
    removeCount: removeCount,
    removeCountOverride: removeCountOverride,
    total: assigned + addCount - removeCount,
    totalOverride: assigned + (addCountOverride ?? 0) - (removeCountOverride ?? 0),
    overridden: countSheetPart?.overridden,
    rejected: countSheetPart?.rejected,
    rejectedReason: countSheetPart?.rejectedReason
  };
}
export function CountSheetGroupPartFromTransferPart(
  part: Part,
  transferParts: TransferPartWithDetails[] | null | undefined,
  direction?: TransferDirection | null | undefined
): PartWithCounts {
  let transferPart = transferParts?.find(x => x.partID == part.id);
  var assigned = transferPart?.currentAssignedCount ?? 0;

  var addCount = 0;
  var addOriginal = 0;
  var removeCount = 0;
  var removeOriginal = 0;

  var total = undefined;
  var originalTotal = undefined;
  var overridden = transferPart?.isOverridden;

  var originalCount = transferPart?.originalCount ?? transferPart?.count;

  if (direction == TransferDirection.ScaffoldReturn) {
    removeCount = transferPart?.count ?? 0;
    removeOriginal = originalCount ?? 0;
  } else {
    addCount = transferPart?.count ?? 0;
    addOriginal = originalCount ?? 0;
  }
  // If the transfer part doesn't have a current assigned count value (0 is OK), don't calculate a total as it would be wrong
  total = transferPart?.currentAssignedCount !== undefined ? assigned + addCount - removeCount : 0;
  originalTotal =
    transferPart?.currentAssignedCount !== undefined ? assigned + addOriginal - removeOriginal : 0;
  return {
    ...part,
    assigned: assigned,
    count: transferPart?.count ?? 0,
    originalCount: originalCount ?? 0,
    addCount: 0,
    removeCount: 0,
    total: total,
    originalTotal: originalTotal,
    overridden: overridden
  };
}
export function CountSheetGroupPartFromMaterialOrderPart(
  part: Part,
  materialorderParts: MaterialOrderPartWithDetails[] | null | undefined
): PartWithCounts {
  let materialorderPart = materialorderParts?.find(x => x.partID == part.id);

  // This CountSheetGroupPart is displayed on the MaterialOrderExisting screen only
  // On this screen, we want to track completion of the order, as such.
  // The Fulfilled amount will be the "Assigned" amount (since assigned never changes)
  // The Count will be displayed as the "Ordered" amount (this needs to stay in the `Count` property in case we allow overrides)
  // The Outstanding will the total
  var fulfilled = materialorderPart?.fulfilled ?? 0;

  let count = materialorderPart?.count ?? 0;
  var originalCount =
    !!materialorderPart?.isOverridden && !!materialorderPart?.originalCount
      ? materialorderPart?.originalCount
      : count;

  let remaining = count - fulfilled;
  let originalRemaining = originalCount - fulfilled;

  let result = {
    ...part,
    assigned: fulfilled,
    count: count,
    originalCount: originalCount,
    addCount: 0,
    removeCount: 0,
    total: remaining,
    originalTotal: originalRemaining,
    overridden: false //!!materialorderPart?.isOverridden
  };
  // console.log(`CountSheetGroupPartFromMaterialOrderPart: ${part.publicID}`);
  // console.log(`\t count: ${result.count}`);
  // console.log(`\t originalCount: ${result.originalCount}`);
  // console.log(`\t isOverridden: ${materialorderPart?.isOverridden}`);
  // console.log(`\t overridden: ${result.overridden}`);
  return result;
}
export function CountSheetGroupPartFromPart(part: Part): PartWithCounts {
  return {
    ...part,
    assigned: 0,
    count: 0,
    originalCount: 0,
    addCount: 0,
    removeCount: 0,
    total: 0,
    originalTotal: 0,
    overridden: false
  };
}
export type CountSheetGroupWithSortedParts = CountSheetGroupWithParts & {
  sortedParts: PartWithCounts[];
};
export type CountSheetSelectionType = "individual" | "removeall" | "notapplicable";
export function FlattenedPartsList(
  countSheetGroups: CountSheetGroupWithSortedParts[]
): PartWithCounts[] {
  let partsForSummary = [] as PartWithCounts[];

  countSheetGroups.forEach(group => {
    group.sortedParts.forEach(part => {
      partsForSummary.push(part);
    });
  });

  return partsForSummary;
}
export function SummarizeModifiedPartsInGroups(
  countSheetGroups: CountSheetGroupWithSortedParts[]
): PartWithCounts[] {
  let partsForSummary = [] as PartWithCounts[];

  countSheetGroups.forEach(group => {
    group.sortedParts.forEach(part => {
      if (
        part.assigned != 0 ||
        part.addCount != 0 ||
        (!!part.addCountOverride && part.addCountOverride != 0) ||
        part.removeCount != 0 ||
        (!!part.removeCountOverride && part.removeCountOverride != 0) ||
        part.rejected == true ||
        part.overridden == true ||
        (!!part.count && part.count != 0)
      ) {
        partsForSummary.push(part);
      }
    });
  });

  return partsForSummary;
}

