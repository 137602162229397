import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import rules from "@fd/lib/vue/rules";
import { PersonalPhoneNumber, personalPhoneNumberService } from "@fd/current/client/services/index";

const PersonalPhoneNumberNewDialog = FDVue.extend({
  name: "fd-user-phone-number-new-dialog",
  components: {},
  mixins: [errorHandling, rules],
  data: () => ({
    personalPhoneNumber: {} as PersonalPhoneNumber
  }),

  props: {
    show: Boolean,
    personID: { default: undefined, type: String }
  },

  computed: {
    dialogRules() {
      return {
        phoneNumber: [this.rules.required, this.rules.validPhoneNumber]
      };
    }
  },
  methods: {
    // *** GLOBAL ***
    initData() {
      this.personalPhoneNumber = {
        phoneNumber: "",
        allowNotifications: true,
        isPrimary: false
      } as PersonalPhoneNumber;
    },
    onSubmit(e: Event) {
      this.saveDialog();
      e.preventDefault();
    },
    cancelDialog() {
      // this.closeDialog!(false);
      this.$emit("cancel");
    },
    async saveDialog() {
      if (!this.personID?.length) {
        console.log("No person ID supplied to PhoneNumberNewDialog");
        return;
      }
      if (!(this.$refs.detailsform as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      try {
        this.personalPhoneNumber.personID = this.personID;
        this.personalPhoneNumber.id = await personalPhoneNumberService.addItem(
          this.personalPhoneNumber
        );
        var snackbarPayload = {
          text: this.$t("user-phone-numbers.snack-bar-add-message", [
            this.personalPhoneNumber.phoneNumber
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.$emit("phoneNumberAdded", this.personalPhoneNumber);
        this.initData();
      } catch (error) {
        this.handleError(error as Error, "users.save-network-error");
      } finally {
        this.processing = false;
      }
    },
    processingUpdated(processing: boolean) {
      this.processing = processing;
    }
  },
  created: async function() {
    this.initData();
  }
});

export default PersonalPhoneNumberNewDialog;

// export async function createNewPersonalPhoneNumberDialog(): Promise<boolean> {
//   let dialog = createDialog(PersonalPhoneNumberNewDialog);
//   return await dialog.showDialog!();
// }

