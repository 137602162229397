import FDVue from "@fd/lib/vue";
import { PropValidator } from "vue/types/options";
import { ScaffoldRequestTypes } from "../../../../services";

export interface WalkdownDetails {
  isHardBarricade: boolean;
  submittedBy: string | null;
  notes: string;
  crewSize: number | null;
  modificationHours: number | null;
  length: number | null;
  width: number | null;
  height: number | null;
  scaffoldBayLengthID: string | null;
  scaffoldBayWidthID: string | null;
  scaffoldBayHeightID: string | null;
  lengthBayCount: number;
  widthBayCount: number;
  accessType: number | null;
  deckLevels: number | null;
  barricadeGates: number | null;
  scaffoldTypeModifierID: string | null;
  scaffoldElevationModifierID: string | null;
  scaffoldHeightModifierID: string | null;
  scaffoldCongestionFactorID: string | null;
  internalModifierID: string | null;
  hoardingModifierID: string | null;
}
export interface WalkdownWorkOrderDetails {
  currentUserPermissions: { canViewEstimateDetails: boolean | undefined } | undefined;
  requestType: ScaffoldRequestTypes;
}

export default FDVue.extend({
  name: "fd-work-order-walkdown-details",

  components: {
    "fd-work-order-detail-value": () => import("./fields/WorkOrderDetailValue.vue"),
    "fd-work-order-detail-value-gap": () => import("./fields/WorkOrderDetailValueGap.vue"),
    "fd-value-display": () => import("@fd/lib/vue/components/FP.ValueDisplay.vue")
  },

  props: {
    workOrder: { type: [Object] } as PropValidator<WalkdownWorkOrderDetails>,
    walkdown: { type: [Object] } as PropValidator<WalkdownDetails>,
    cy: { type: String, default: "fd-work-order-walkdown-details" }
  },
  data: () => ({
    ScaffoldRequestTypes
  })
});

