var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { "data-cy": _vm.cy } },
    [
      _c("fd-work-order-detail-alert", { attrs: { workOrder: _vm.workOrder } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "fd-details-section pt-3" },
        [
          _vm.workOrder.isLoading
            ? _c(
                "v-row",
                { staticClass: "mx-0", staticStyle: { width: "100%" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pl-0 pr-0", attrs: { cols: "12" } },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "black"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.workOrder.isLoading
            ? _c(
                "v-row",
                { staticClass: "mx-0", staticStyle: { width: "100%" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pl-0 pr-0", attrs: { cols: "12" } },
                    [
                      _c("fd-work-order-request-details", {
                        staticClass:
                          "fd-expanding-table-single-details-section",
                        attrs: {
                          workOrder: _vm.workOrder,
                          "show-edit-controls": _vm.showEditControls,
                          "edit-controls-disabled": _vm.editControlsDisabled
                        },
                        on: {
                          "change:isurgent": v =>
                            _vm.$emit("change:isurgent", v)
                        }
                      }),
                      _vm._v(" "),
                      _c("fd-work-order-location-details", {
                        staticClass:
                          "fd-expanding-table-single-details-section",
                        attrs: { workOrder: _vm.workOrder }
                      }),
                      _vm._v(" "),
                      _c("fd-work-order-notes", {
                        staticClass:
                          "fd-expanding-table-single-details-section",
                        attrs: { workOrder: _vm.workOrder }
                      }),
                      _vm._v(" "),
                      !_vm.hideScopeValues || _vm.hideScopeValues == "false"
                        ? _c("fd-work-order-scope-details", {
                            staticClass:
                              "fd-expanding-table-single-details-section",
                            attrs: { workOrder: _vm.workOrder }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !!_vm.walkdown
                        ? _c("fd-work-order-walkdown-details", {
                            staticClass:
                              "fd-expanding-table-single-details-section",
                            attrs: {
                              workOrder: _vm.workOrder,
                              walkdown: _vm.walkdown
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }